export default (
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.3665 16.1077H4.69987V4.44108H10.5332V2.77441H4.69987C3.77487 2.77441 3.0332 3.52441 3.0332 4.44108V16.1077C3.0332 17.0244 3.77487 17.7744 4.69987 17.7744H16.3665C17.2832 17.7744 18.0332 17.0244 18.0332 16.1077V10.2744H16.3665V16.1077ZM12.1999 2.77441V4.44108H15.1915L6.99987 12.6327L8.17487 13.8077L16.3665 5.61608V8.60775H18.0332V2.77441H12.1999Z'
      fill='#337AB7'
    />
  </svg>
)
