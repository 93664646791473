import { Route, Switch } from 'react-router-dom'

import CircuitListFormPage from './CircuitListFormPage'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import AuthorizedFeatures from '../../authorization/features'
import MeterDetail from '../../components/MeterDetail'
import type { FTRouterMatch } from '../../types'

const MeterDetailPage = ({ match: { path, url } }: FTRouterMatch) => (
  <div>
    <UnauthorizedRedirect
      from={`${path}/circuits/edit(.*)`}
      to={url}
      feature={AuthorizedFeatures.editMeters}
    />
    <UnauthorizedRedirect
      from={`${path}/configuration/edit(.*)`}
      to={url}
      feature={AuthorizedFeatures.editMeterConfig}
    />

    <Switch>
      <Route path={`${path}/circuits/edit`} component={CircuitListFormPage} />
      <Route component={MeterDetail} />
    </Switch>
  </div>
)

export default MeterDetailPage
