import Tippy from '@tippyjs/react'
import moment from 'moment'
import * as momenttz from 'moment-timezone'
import { useRef, useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import type { FTModalKfactorProps } from './commons'
import {
  BorderedContainerStyled,
  CALCULATION_TYPE,
  CenteredContainerStyled,
  ClearBtnStyled,
  CloseIconStyled,
  DividerStyled,
  DoubleColumnContainerStyled,
  ErrorMessageStyled,
  ErrorStyled,
  FieldContainerStyled,
  HeaderLabelStyled,
  HeaderStyled,
  HiddenInputStyled,
  ImageActionBtnContainerStyled,
  ImagePrevieweStyled,
  InputStyled,
  LabelStyled,
  ManualToggleContainerStyled,
  ModalBodyStyled,
  ModalContentStyled,
  ModalFooterStyled,
  READING_UNIT_OPTIONS,
  RequiredMarkStyled,
  SubHeaderLableStyled,
  UploadPhotoButtonStyled,
} from './commons'
import ConfirmationMessage from './ConfirmationMessage'
import {
  actions as meterActions,
  selectKFactorHistoryItems,
  selectMeasurementMeta,
  selectMeterEntity,
} from '../../../ducks/meters'
import { ACCEPTED_IMAGE_EXTENSIONS } from '../../../utils/constants'
import Button2 from '../../Button2'
import Clock from '../../Icons/svg/Clock'
import CloseOutline from '../../Icons/svg/CloseOutline'
import DownloadOutline from '../../Icons/svg/DownloadOutline'
import ExclamationOutline from '../../Icons/svg/ExclamationOutline'
import SyncFilled from '../../Icons/svg/SyncFilled'
import ViewEye from '../../Icons/svg/ViewEye'
import ListSelector from '../../ListSelector'
import Spinner from '../../Spinner'

const DateTimePickerContainerStyled = styled.div`
  width: 100%;
  height: 34px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  // disabled
  ${({ disabled }) => disabled && 'background-color: #f0f0f0;'}
`
const DateTimePickerStyled = styled(DateTimePicker)`
  height: 100%;
  color: #162447;
  > div {
    border: none;
    input {
      font-size: 14px;
    }
    input:not(.react-datetime-picker__inputGroup__input--hasLeadingZero) {
      padding: 0;
    }
    .react-datetime-picker__inputGroup__input--hasLeadingZero {
      width: 18px !important;
    }
  }
`
const CalculationBoxStyled = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #485DA0;
  background: #FFF;
  }
`
const CalcDataWrapperStyled = styled.div`
  padding: 24px 25px;
  border-radius: 8px;
`
const CalculationBoxHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(108, 109, 110, 0.32);
  h2 {
    color: #485da0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6c6d6e;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    gap: 8px;
    span {
      line-height: 0;
    }
  }
`
const CalculationsDataStyled = styled.div`
  .row {
    display: flex;
    margin-top: 26px;
    .col {
      width: 50%;
      margin: 0;
    }
  }
  .row:first-child {
    margin: 20px 0 0;
  }
`
const CalculatedKFactorContainerStyled = styled.div`
  background: linear-gradient(0deg, #485DA0 0%, #485DA0 100%), #FFF;
  padding 24px 25px;
  border-radius: 4px;
  h4 {
    font-size: 14px;
    font-weight: 600;
    color: #E0E0E0;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    margin: 0;
  }
  .k {
    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    margin: 16px 0 0;
  }
  &.row {
    display: flex;
    background: #fff;
    padding: 0;
    border: 1px solid #485DA0;
    border-radius: 4px;
    .col {
      h4 {
        color: #6C6D6E;
      }
      .k {
        color: #485DA0;
      }
      flex: 1;
      padding: 24px 40px;
      &.new {
        background: linear-gradient(0deg, #485DA0 0%, #485DA0 100%), #FFF;
        h4 {
          color: #E0E0E0;
        }
        p {
          color: #FFF;
        }
      }
  }
  
`
const TzPickerstyled = styled.select`
  border: none;
  color: #162447;
  font-size: 14px;
  height: 22px;
  ${(props) =>
    props.disabled &&
    `
    background-color: #f0f0f0;
  `}
`
const CommentContainerStyled = styled.div`
  margin-top: 24px;
  font-size: 12px;
  label {
    color: #162447;
    font-size: 12px;
    font-weight: 500;
    textarea {
      width: 100%;
      padding: 13px 16px;
      color: #162447;
      font-weight: 500;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      background: #fff;
      height: 60px;
    }
  }
`

const CalculatedKFactorForm = (props: FTModalKfactorProps) => {
  const {
    closeModal,
    meter,
    updateMode,
    setManualMode,
    measurementMeta,
    meterEntity,
    kFactorHistoryItems,
    actions: {
      calculateMeasurements,
      addCalculatedKFactor,
      resetMeasurementMeta,
    },
  } = props
  const timeZones = momenttz.tz.names()
  const currentShortTimeZone = momenttz.tz(momenttz.tz.guess()).format('z')
  const shortTimeZones = timeZones
    .map((tz) => momenttz.tz(tz).format('z'))
    .filter((tz) => !tz.startsWith('+') && !tz.startsWith('-'))
  const loading = meterEntity?.meta?.loading
  const errorMsg = meterEntity?.meta?.error
  const uniqueTimeZones = [...new Set(shortTimeZones)]
  const [meterReading1, setMeterReading1] = useState('')
  const [meterReading2, setMeterReading2] = useState('')
  const [readingUnit, setReadingUnit] = useState(READING_UNIT_OPTIONS[0])
  const [readingTime1, setReadingTime1] = useState(new Date())
  const [readingTime2, setReadingTime2] = useState(new Date())
  const [imageFile1, setImageFile1] = useState(null)
  const [imageFile2, setImageFile2] = useState(null)
  const [timeZone, setTimeZone] = useState(currentShortTimeZone)
  const [comment, setComment] = useState('')
  const currentKFactor = kFactorHistoryItems?.[0]?.kFactor
  const [isConfirming, setIsConfirming] = useState(false)
  const hiddenFileInput1 = useRef(null)
  const hiddenFileInput2 = useRef(null)
  const selectedReadingUnit = readingUnit || READING_UNIT_OPTIONS[0]
  const revCounts = measurementMeta?.revolutionCount
  const isCalculated = !!revCounts
  const isValidForCalculation =
    meterReading1 &&
    meterReading2 &&
    Number(meterReading1) < Number(meterReading2) &&
    imageFile1 &&
    imageFile2
  let kFactor = null
  let meterReadingDifference
  let meterReadingDifferenceRounded

  if (revCounts) {
    meterReadingDifference = Number(meterReading2) - Number(meterReading1)
    meterReadingDifferenceRounded = meterReadingDifference.toFixed(2)
    kFactor = Number(revCounts) / meterReadingDifferenceRounded
  }

  const submitKfactor = () => {
    addCalculatedKFactor({
      hardwareId: meter.hardwareId,
      meterId: meter.id,
      body: {
        photos: [imageFile1, imageFile2],
        kFactorData: {
          kFactor,
          fromReading: meterReading1,
          toReading: meterReading2,
          readingUnit: selectedReadingUnit.name,
          fromReadingTime: `${moment(readingTime1)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss')}Z`,
          toReadingTime: `${moment(readingTime2)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss')}Z`,
          calculationType: CALCULATION_TYPE.CALCULATED,
          timeZone,
          comment,
        },
      },
    })
  }

  const calculateKFactor = () => {
    const fromReadingTime = `${moment(readingTime1)
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')}Z`
    const toReadingTime = `${moment(readingTime2)
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')}Z`
    calculateMeasurements({
      meterId: meter.id,
      data: {
        fromReadingTime,
        toReadingTime,
        timeZone: 'UTC',
      },
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const fileExtension = file.name.split('.').pop()

    if (!ACCEPTED_IMAGE_EXTENSIONS.includes(fileExtension)) {
      return
    }

    const readingNumber = e.currentTarget.dataset.id

    switch (readingNumber) {
      case '1':
        setImageFile1(file)
        break

      case '2':
        setImageFile2(file)
        break

      default:
        break
    }
  }

  const handleUploadBtnClick = (e) => {
    const photoReadingNumber = e.currentTarget.dataset.id

    if (photoReadingNumber === '1') {
      hiddenFileInput1.current.click()
    }

    if (photoReadingNumber === '2') {
      hiddenFileInput2.current.click()
    }
  }

  const viewFile = (e) => {
    const photoReadingNumber = e.currentTarget.dataset.id

    if (photoReadingNumber === '1') {
      window.open(URL.createObjectURL(imageFile1))
    }

    if (photoReadingNumber === '2') {
      window.open(URL.createObjectURL(imageFile2))
    }
  }

  const handleFileRemove = (e) => {
    const readingNumber = e.currentTarget.dataset.id

    switch (readingNumber) {
      case '1':
        setImageFile1(null)
        hiddenFileInput1.current.value = null
        break

      case '2':
        setImageFile2(null)
        hiddenFileInput2.current.value = null
        break

      default:
        break
    }
  }

  const handleFileUpdate = (e) => {
    const readingNumber = e.currentTarget.dataset.id

    switch (readingNumber) {
      case '1':
        setImageFile1(null)
        hiddenFileInput1.current.value = null
        hiddenFileInput1.current.click()
        break

      case '2':
        setImageFile2(null)
        hiddenFileInput2.current.value = null
        hiddenFileInput2.current.click()
        break

      default:
        break
    }
  }

  const resetForm = () => {
    setMeterReading1('')
    setMeterReading2('')
    setReadingUnit('CCF')
    setReadingTime1(new Date())
    setReadingTime2(new Date())
    setImageFile1(null)
    setImageFile2(null)
    hiddenFileInput1.current.value = null
    hiddenFileInput2.current.value = null
    setTimeZone(currentShortTimeZone)
    resetMeasurementMeta()
  }

  const handleOnClose = () => {
    resetForm()
    closeModal()
  }

  const howToCalculate =
    () => `K-Factor is Rev Count Between Reading Times/Difference Between Meter Reading
      e.g., 210 revs/0.05 CCF = 4,200 K-Factor`

  return (
    <ModalContentStyled>
      <CloseIconStyled onClick={handleOnClose}>
        <span className='ion-android-close' />
      </CloseIconStyled>
      {!isConfirming && (
        <>
          <ModalBodyStyled>
            <HeaderStyled>
              <div>
                <HeaderLabelStyled>Add A New K-Factor</HeaderLabelStyled>
              </div>
            </HeaderStyled>
            <DoubleColumnContainerStyled>
              <BorderedContainerStyled>
                <SubHeaderLableStyled>Reading 1</SubHeaderLableStyled>
                <DividerStyled />
                <FieldContainerStyled>
                  <LabelStyled>
                    Meter Reading 1<RequiredMarkStyled>*</RequiredMarkStyled>
                  </LabelStyled>
                  <InputStyled
                    disabled={isCalculated}
                    type='text'
                    value={meterReading1}
                    onChange={(e) => setMeterReading1(e.target.value)}
                  />
                </FieldContainerStyled>
                <FieldContainerStyled>
                  <LabelStyled>
                    Reading Unit
                    <RequiredMarkStyled>*</RequiredMarkStyled>
                  </LabelStyled>
                  <ListSelector
                    selectedItem={selectedReadingUnit}
                    notSetLabelText='Select reading unit'
                    items={READING_UNIT_OPTIONS || []}
                    fieldName='meterReading1Unit'
                    searchable={false}
                    disabled={isCalculated}
                    unsettable={false}
                    z={isCalculated}
                    updateValue={({ value: updateValue }) => {
                      setReadingUnit(
                        READING_UNIT_OPTIONS.find(
                          (option) => option.id === updateValue,
                        ),
                      )
                    }}
                  />
                </FieldContainerStyled>
                <FieldContainerStyled>
                  <LabelStyled>
                    Reading Time 1<RequiredMarkStyled>*</RequiredMarkStyled>
                  </LabelStyled>
                  <DateTimePickerContainerStyled disabled={isCalculated}>
                    <div>
                      <DateTimePickerStyled
                        disabled={isCalculated}
                        format='dd-M-y HH:mm'
                        clearIcon={null}
                        calendarIcon={null}
                        disableClock
                        onChange={setReadingTime1}
                        value={readingTime1}
                        maxDate={new Date()}
                      />
                      <TzPickerstyled
                        disabled={isCalculated}
                        onChange={(e) => setTimeZone(e.target.value)}
                        value={timeZone}
                      >
                        {uniqueTimeZones.map((tz) => (
                          <option key={tz} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </TzPickerstyled>
                    </div>
                    <Clock />
                  </DateTimePickerContainerStyled>
                </FieldContainerStyled>
                <FieldContainerStyled>
                  {!isCalculated && (
                    <LabelStyled>
                      Meter Reading Photo
                      <RequiredMarkStyled>*</RequiredMarkStyled>
                    </LabelStyled>
                  )}
                  {!isCalculated && !imageFile1 && (
                    <UploadPhotoButtonStyled
                      data-id='1'
                      onClick={handleUploadBtnClick}
                    >
                      <DownloadOutline />
                      <span>Upload Photo</span>
                    </UploadPhotoButtonStyled>
                  )}
                  <HiddenInputStyled
                    data-id='1'
                    type='file'
                    ref={hiddenFileInput1}
                    onChange={handleFileChange}
                    accept='image/*'
                  />
                  {imageFile1 && (
                    <div>
                      <ImagePrevieweStyled
                        src={URL.createObjectURL(imageFile1)}
                      />
                      {!isCalculated && (
                        <ImageActionBtnContainerStyled>
                          <ClearBtnStyled
                            data-id='1'
                            onClick={handleFileUpdate}
                          >
                            <SyncFilled />
                            <span>Update</span>
                          </ClearBtnStyled>
                          <ClearBtnStyled
                            data-id='1'
                            onClick={handleFileRemove}
                          >
                            <CloseOutline />
                            <span>Remove</span>
                          </ClearBtnStyled>
                        </ImageActionBtnContainerStyled>
                      )}
                      {isCalculated && (
                        <ImageActionBtnContainerStyled>
                          <ClearBtnStyled data-id='1' onClick={viewFile}>
                            <ViewEye />
                            <span>View</span>
                          </ClearBtnStyled>
                        </ImageActionBtnContainerStyled>
                      )}
                    </div>
                  )}
                </FieldContainerStyled>
              </BorderedContainerStyled>
              <BorderedContainerStyled>
                <SubHeaderLableStyled>Reading 2</SubHeaderLableStyled>
                <DividerStyled />
                <FieldContainerStyled>
                  <LabelStyled>
                    Meter Reading 2<RequiredMarkStyled>*</RequiredMarkStyled>
                  </LabelStyled>
                  <InputStyled
                    className={
                      meterReading1.length &&
                      meterReading2.length &&
                      Number(meterReading1) > Number(meterReading2) &&
                      'error'
                    }
                    disabled={isCalculated}
                    type='text'
                    value={meterReading2}
                    onChange={(e) => setMeterReading2(e.target.value)}
                  />
                  {meterReading1.length > 0 &&
                    meterReading2.length > 0 &&
                    Number(meterReading1) > Number(meterReading2) && (
                      <ErrorStyled>
                        Reading 2 cannot be lower than Reading 1
                      </ErrorStyled>
                    )}
                </FieldContainerStyled>
                <FieldContainerStyled>
                  <LabelStyled>
                    Reading Unit
                    <RequiredMarkStyled>*</RequiredMarkStyled>
                  </LabelStyled>
                  <ListSelector
                    selectedItem={selectedReadingUnit}
                    notSetLabelText='Select reading unit'
                    items={READING_UNIT_OPTIONS || []}
                    fieldName='meterReading1Unit'
                    searchable={false}
                    unsettable={false}
                    disabled={isCalculated}
                    updateValue={({ value: updateValue }) => {
                      setReadingUnit(
                        READING_UNIT_OPTIONS.find(
                          (option) => option.id === updateValue,
                        ),
                      )
                    }}
                  />
                </FieldContainerStyled>
                <FieldContainerStyled>
                  <LabelStyled>
                    Reading Time 2<RequiredMarkStyled>*</RequiredMarkStyled>
                  </LabelStyled>
                  <DateTimePickerContainerStyled disabled={isCalculated}>
                    <div>
                      <DateTimePickerStyled
                        disabled={isCalculated}
                        format='dd-M-y HH:mm'
                        clearIcon={null}
                        calendarIcon={null}
                        disableClock
                        minDate={new Date(readingTime1.getTime() + 60 * 60000)}
                        maxDate={new Date()}
                        onChange={setReadingTime2}
                        value={readingTime2}
                      />
                      <TzPickerstyled
                        onChange={(e) => setTimeZone(e.target.value)}
                        disabled={isCalculated}
                        value={timeZone}
                      >
                        {uniqueTimeZones.map((tz) => (
                          <option key={tz} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </TzPickerstyled>
                    </div>
                    <Clock />
                  </DateTimePickerContainerStyled>
                </FieldContainerStyled>

                <FieldContainerStyled>
                  {!isCalculated && (
                    <LabelStyled>
                      Meter Reading Photo
                      <RequiredMarkStyled>*</RequiredMarkStyled>
                    </LabelStyled>
                  )}
                  {!isCalculated && !imageFile2 && (
                    <UploadPhotoButtonStyled
                      data-id='2'
                      onClick={handleUploadBtnClick}
                    >
                      <DownloadOutline />
                      <span>Upload Photo</span>
                    </UploadPhotoButtonStyled>
                  )}
                  <HiddenInputStyled
                    data-id='2'
                    type='file'
                    ref={hiddenFileInput2}
                    onChange={handleFileChange}
                    accept='image/*'
                  />
                  {imageFile2 && (
                    <div>
                      <ImagePrevieweStyled
                        src={URL.createObjectURL(imageFile2)}
                      />
                      {!isCalculated && (
                        <ImageActionBtnContainerStyled>
                          <ClearBtnStyled
                            data-id='2'
                            onClick={handleFileUpdate}
                          >
                            <SyncFilled />
                            <span>Update</span>
                          </ClearBtnStyled>
                          <ClearBtnStyled
                            data-id='2'
                            onClick={handleFileRemove}
                          >
                            <CloseOutline />
                            <span>Remove</span>
                          </ClearBtnStyled>
                        </ImageActionBtnContainerStyled>
                      )}
                      {isCalculated && (
                        <ImageActionBtnContainerStyled>
                          <ClearBtnStyled data-id='2' onClick={viewFile}>
                            <ViewEye />
                            <span>View</span>
                          </ClearBtnStyled>
                        </ImageActionBtnContainerStyled>
                      )}
                    </div>
                  )}
                </FieldContainerStyled>
              </BorderedContainerStyled>
            </DoubleColumnContainerStyled>
            {errorMsg && (
              <CenteredContainerStyled>
                <ErrorMessageStyled>{errorMsg}</ErrorMessageStyled>
              </CenteredContainerStyled>
            )}
            {loading && (
              <CenteredContainerStyled>
                <Spinner size='tiny' />
              </CenteredContainerStyled>
            )}
            {!isCalculated && updateMode && (
              <ManualToggleContainerStyled>
                <p>ALREADY HAVE K-FACTOR?</p>
                <Button2 onClick={() => setManualMode(true)} type='outlined'>
                  Enter K-Factor Manually
                </Button2>
              </ManualToggleContainerStyled>
            )}
          </ModalBodyStyled>

          {kFactor && (
            <CalculationBoxStyled>
              <CalcDataWrapperStyled>
                <CalculationBoxHeaderStyled>
                  <h2>K-Factor Calculation</h2>
                  <div>
                    <Tippy content={howToCalculate()}>
                      <span>
                        <ExclamationOutline />
                      </span>
                    </Tippy>
                    <span>How this is calculated?</span>
                  </div>
                </CalculationBoxHeaderStyled>
                <CalculationsDataStyled>
                  <div className='row'>
                    <p className='col'>Rev Count Between Reading Times:</p>
                    <p className='col'>{revCounts}</p>
                  </div>
                  <div className='row'>
                    <p className='col'>Difference Between Meter Readings:</p>
                    <p className='col'>
                      {meterReadingDifferenceRounded} {selectedReadingUnit.name}
                    </p>
                  </div>
                </CalculationsDataStyled>
              </CalcDataWrapperStyled>
              {!updateMode && (
                <CalculatedKFactorContainerStyled>
                  <h4>New K-Factor</h4>
                  <p className='k'>
                    {kFactor.toFixed(2)} revs / {selectedReadingUnit.name}
                  </p>
                </CalculatedKFactorContainerStyled>
              )}
              {updateMode && (
                <CalculatedKFactorContainerStyled className='row'>
                  <div className='col'>
                    <h4>Current K-Factor</h4>
                    <p className='k'>
                      {currentKFactor.toFixed(2)} revs /{' '}
                      {selectedReadingUnit.name}
                    </p>
                  </div>
                  <div className='col new'>
                    <h4>New Calculated K-Factor</h4>
                    <p className='k'>
                      {kFactor.toFixed(2)} revs / {selectedReadingUnit.name}
                    </p>
                  </div>
                </CalculatedKFactorContainerStyled>
              )}
            </CalculationBoxStyled>
          )}
          {kFactor && (
            <CommentContainerStyled>
              <label htmlFor='comment'>
                Comment
                <textarea
                  id='comment'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </label>
            </CommentContainerStyled>
          )}
          {!isCalculated && (
            <ModalFooterStyled>
              <ClearBtnStyled onClick={resetForm}>
                <SyncFilled />
                <span>Reset</span>
              </ClearBtnStyled>
              <Button2
                type='secondary'
                disabled={!isValidForCalculation || loading}
                onClick={calculateKFactor}
              >
                Calculate K-Factor
              </Button2>
            </ModalFooterStyled>
          )}
          {isCalculated && (
            <ModalFooterStyled>
              <ClearBtnStyled onClick={handleOnClose}>
                <span>Cancel</span>
              </ClearBtnStyled>
              <Button2
                type='primary'
                disabled={loading}
                onClick={() => setIsConfirming(true)}
              >
                Save & Mark as active
              </Button2>
            </ModalFooterStyled>
          )}
        </>
      )}
      {isConfirming && (
        <ConfirmationMessage
          handleSaveKFactor={submitKfactor}
          handleCancel={() => setIsConfirming(false)}
          loading={loading}
          error={errorMsg}
        />
      )}
    </ModalContentStyled>
  )
}

const mapStateToProps = (state, props) => ({
  measurementMeta: selectMeasurementMeta(state),
  meterEntity: selectMeterEntity(state, props.meter.id),
  kFactorHistoryItems: selectKFactorHistoryItems(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(meterActions, dispatch) },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalculatedKFactorForm),
)
