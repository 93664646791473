import { combineReducers } from 'redux'

import fetchUtilityData from './fetchUtilityData'
import utilityProfileData from './uploadUtilityProfileData'
import utilityUsageData from './uploadUtilityUsageData'

const uploadUtilityData = combineReducers({
  utilityProfileData,
  utilityUsageData,
  fetchUtilityData,
})
export default uploadUtilityData
