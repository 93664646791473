import { useRollbarContext } from '@rollbar/react'
import Tippy from '@tippyjs/react'
import moment from 'moment'
import React, { ComponentType, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { SpinnerStyles } from './CustomerListingPage'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../../authorization/features'
import ActionPaneView from '../../../components/ActionPaneView'
import Breadcrumbs2 from '../../../components/Breadcrumbs2'
import ColorIndicator from '../../../components/ColorIndicator'
import DropdownMenuNew, {
  DropdownMenuStyles,
} from '../../../components/DropdownMenuNew'
import ErrorMessage from '../../../components/ErrorMessage'
import AccountBalanceIcon from '../../../components/Icons/AccountBalanceIcon'
import AddCircleOutlineIcon from '../../../components/Icons/AddCircleOutlineIcon'
import ArrowBackIcon from '../../../components/Icons/ArrowBackIcon'
import LockIconOutlined from '../../../components/Icons/LockIconOutlined'
import LockOpenIcon from '../../../components/Icons/LockOpenIcon'
import GlobalInputs from '../../../components/ProposalsEngine/GlobalInputs'
import TableStyledLink from '../../../components/ProposalsEngine/TableStyledLink'
import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import Spinner from '../../../components/Spinner'
import TabPane2, {
  TabPaneSelectorStyled,
} from '../../../components/Tabs/TabPane2'
import type { FTFetchProposalCustomerGlobalInputsAction } from '../../../ducks/proposals/customerGlobalInputs'
import { actions as customerGlobalInputsActions } from '../../../ducks/proposals/customerGlobalInputs'
import type {
  FTFetchProposalCustomerMetricsAction,
  FTProposalCustomerMetrics,
} from '../../../ducks/proposals/customerMetrics'
import {
  actions as customerMetricsActions,
  selectProposalCustomerMetricsEntity,
} from '../../../ducks/proposals/customerMetrics'
import type {
  FTFetchProposalCustomerSummariesAction,
  FTProposalCustomerSummary,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as customerSummariesActions,
  selectProposalCustomerSummariesEntity,
} from '../../../ducks/proposals/customerSummaries'
import {
  type FTFetchProposalBatchSummariesAction,
  type FTProposalBatchSummary,
  actions as proposalBatchSummariesActions,
  batchStatuses,
  selectProposalBatchSummariesEntity,
} from '../../../ducks/proposals/multisite/batchSummaries'
import type {
  FTFetchProposalOpportunitySummariesAction,
  FTProposalOpportunitySummary,
} from '../../../ducks/proposals/opportunitySummaries'
import {
  actions as opportunitySummariesActions,
  opportunityStatuses,
  selectProposalOpportunitySummariesEntity,
} from '../../../ducks/proposals/opportunitySummaries'
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_FORMAT_TIMESTAMP,
} from '../../../ducks/utils'
import type { FTWithRouter } from '../../../types'
import { getNameFromEMail, getProjectTypeFormatted } from '../../../utils'
import { colors } from '../../../utils/themes'

type FTProps = {
  actions: {
    fetchProposalCustomerGlobalInputs: (
      params: FTFetchProposalCustomerGlobalInputsAction,
    ) => null
    fetchProposalCustomerSummaries: (
      params: FTFetchProposalCustomerSummariesAction | null | undefined,
    ) => null
    fetchProposalCustomerMetrics: (
      params: FTFetchProposalCustomerMetricsAction,
    ) => null
    fetchProposalOpportunitySummaries: (
      params: FTFetchProposalOpportunitySummariesAction,
    ) => null
    fetchProposalBatchSummaries: (
      params: FTFetchProposalBatchSummariesAction,
    ) => null
  }
  batchSummariesError: string
  batchSummariesLoading: boolean
  customerMetrics: FTProposalCustomerMetrics | null | undefined
  customerSummariesById: Record<string, FTProposalCustomerSummary>
  customerMetricsLoading: boolean
  customerSummariesLoading: boolean
  opportunitySummariesLoading: boolean
  opportunitySummaries: Array<FTProposalOpportunitySummary>
} & FTWithRouter
type FTRow = {
  original: FTProposalBatchSummary
}
type FTCell = {
  // eslint-disable-next-line react/no-unused-prop-types
  row: FTRow
  // eslint-disable-next-line react/no-unused-prop-types
  value: string
}
const CustomerOpportunitiesStyled = styled.div`
  font-family: 'Public Sans', sans-serif;
  margin-top: 36px;
  padding-bottom: 80px;

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }

  ${TabPaneSelectorStyled} {
    padding-left: 22px;
  }
`
const MainSectionsStyled = styled.div`
  display: flex;
`
const SectionFirstStyled = styled.div`
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  flex-shrink: 0;
  padding: 32px;
  width: 360px;
`
const SectionSecondStyled = styled.div`
  flex-grow: 1;
`
const SectionSecondTopStyled = styled.div`
  margin-bottom: 24px;
  padding: 25px 0 0 20px;
`
const BreadcrumbsStyled = styled.div`
  margin-bottom: 24px;
`
const TitleStyled = styled.span`
  display: inline-block;
  font-size: 18px;
  padding-left: 6px;
`
const TitleWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  line-height: 1;
  margin-bottom: 24px;
  position: relative;

  ${ArrowBackIcon} {
    cursor: pointer;
    font-size: 18px;
  }
`
const MetricsStyled = styled.div``
const MetricStyled = styled.div`
  border: 1px solid ${colors.blue12};
  border-radius: 4px;
  box-shadow: 0 0 5px 1px rgba(105, 105, 105, 0.15);
  display: inline-block;
  margin: 0 20px 20px 0;

  &:last-child {
    margin-right: 0;
  }
`

const CustomDropdownMenuNewStyles = styled.div`
  position: relative;
  z-index: 390;
  ${DropdownMenuStyles} {
    width: 180px;
  }
`

const MetricInnerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  padding: 12px;
  width: 213px;
`
const MetricTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
const MetricBottomStyled = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: right;
`
const MetricIconStyled = styled.div`
  margin-right: 30px;
`
export const MetricTitleStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
`
const AccountBalanceIconStyled: ComponentType<{
  color: string
}> = styled(AccountBalanceIcon)`
  color: ${({ color }) => color};
`
const StatusIndicatorStyled = styled(ColorIndicator)`
  margin-right: 5px;
  width: 8px;
`
const CreateBatchButtonStyled = styled.a`
  align-items: center;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 4px;
  color: ${colors.blue2};
  display: flex;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  padding: 0 16px;
`
const AddCircleOutlineIconStyled = styled(AddCircleOutlineIcon)`
  font-size: 22px;
  margin-right: 3px;
`
const TabStyled = styled.div``
const CountIndicatorStyled = styled.span`
  align-items: center;
  background: ${colors.blue5};
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  margin-left: 5px;
  width: 24px;
`

const OpportunityStatusCell = ({ value = '' }: FTCell) =>
  (value &&
    opportunityStatuses[value] &&
    opportunityStatuses[value].displayName && (
      <>
        <StatusIndicatorStyled color={opportunityStatuses[value].color} />
        {opportunityStatuses[value].displayName}
      </>
    )) ||
  ''

const OpportunityLockedUnlockedCell = ({
  value = false,
}: {
  value: boolean
}) => {
  if (value) {
    return (
      <LockIconOutlined
        style={{
          color: colors.gray2,
        }}
      />
    )
  }

  return (
    <LockOpenIcon
      style={{
        color: colors.blue2,
      }}
    />
  )
}

const BatchStatusCell = ({ value = '' }: FTCell) =>
  (value && batchStatuses[value] && batchStatuses[value].displayName && (
    <>
      <StatusIndicatorStyled color={batchStatuses[value].color} />
      {batchStatuses[value].displayName}
    </>
  )) ||
  ''

const CellWithToolTip = ({ value = '' }: FTCell) => (
  <Tippy content={value} delay={500}>
    <span>{value}</span>
  </Tippy>
)

export const Metric = ({
  color,
  metric,
  title,
}: {
  color: string
  metric: number
  title: string
}) => (
  <MetricStyled>
    <MetricInnerStyled>
      <MetricTopStyled>
        <MetricIconStyled>
          <AccountBalanceIconStyled color={color} />
        </MetricIconStyled>
        <MetricTitleStyled>{title}</MetricTitleStyled>
      </MetricTopStyled>
      <MetricBottomStyled>{metric}</MetricBottomStyled>
    </MetricInnerStyled>
  </MetricStyled>
)

const getLastModifiedDateFormatted = (date: string) => {
  const dateMoment = moment(date, DATE_FORMAT_DATA_API_RESPONSE)

  if (dateMoment.isValid()) {
    return dateMoment.format(DATE_FORMAT_TIMESTAMP)
  }

  return '-'
}

const CustomerOpportunitiesPage = (props: FTProps) => {
  const {
    actions,
    customerMetrics,
    customerMetricsLoading,
    customerSummariesById,
    customerSummariesLoading,
    history,
    opportunitySummaries,
    opportunitySummariesLoading,
    batchSummariesError,
    batchSummariesLoading,
    batchSummaries,
    match: {
      params: { salesforceCustomerId },
    },
  } = props
  const customerSummary = customerSummariesById[salesforceCustomerId] || {}
  const { name: customerName = '' } = customerSummary || {}
  const {
    batchAnalysesInProgressCount,
    opportunitiesBillingAndMonitoring,
    pendingOpportunities,
    totalFixtures,
  } = customerMetrics || {}

  const [filteredOpportunitySummaries, setFilteredOpportunitySummaries] =
    React.useState(opportunitySummaries)
  const [filterOppertunities, setFilterOppertunities] =
    React.useState('scoping')
  useRollbarContext('Customer opportunities Page')

  React.useEffect(() => {
    actions.fetchProposalCustomerGlobalInputs({ salesforceCustomerId })
    actions.fetchProposalOpportunitySummaries({
      salesforceCustomerId,
      stageNames: ['SCOPING', 'INSTALLING', 'BILLING_AND_MONITORING'],
    })
    actions.fetchProposalCustomerMetrics({ salesforceCustomerId })
    actions.fetchProposalBatchSummaries({ salesforceCustomerId })
  }, [])

  React.useEffect(() => {
    if (!Object.keys(customerSummariesById).length) {
      actions.fetchProposalCustomerSummaries()
    }
  }, [])
  useEffect(() => {
    if (filterOppertunities === 'scoping') {
      setFilteredOpportunitySummaries(
        opportunitySummaries.filter(({ stageName }) => stageName === 'Scoping'),
      )
    } else if (filterOppertunities === 'installing') {
      setFilteredOpportunitySummaries(
        opportunitySummaries.filter(
          ({ stageName }) => stageName === 'Installing',
        ),
      )
    } else {
      setFilteredOpportunitySummaries(
        opportunitySummaries.filter(
          ({ stageName }) => stageName === 'Billing & Monitoring',
        ),
      )
    }
  }, [opportunitySummaries, filterOppertunities])

  const navigateBack = () =>
    history.push('/proposal-operations/proposals-engine')

  const breadcrumbs = [
    {
      href: '/proposal-operations/proposals-engine',
      text: 'Proposal Engine',
    },
    {
      href: '/proposal-operations/proposals-engine/',
      text: 'Customers',
    },
    {
      href: `/proposal-operations/proposals-engine/${salesforceCustomerId}`,
      text: customerName,
    },
  ]
  const columns = [
    {
      accessor: 'redaptiveOpportunityId',
      Cell: (cellProps) => {
        const {
          row: {
            original: { externalId },
          },
          value = '',
        }: {
          row: {
            original: FTProposalOpportunitySummary
          }
          value: string
        } = cellProps
        return (
          <TableStyledLink
            key={value}
            href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${externalId}`}
          >
            {value}
          </TableStyledLink>
        )
      },
      Header: 'Opportunity ID',
    },
    {
      accessor: 'name',
      Cell: CellWithToolTip,
      Header: 'Opportunity Name',
    },
    {
      accessor: 'opportunityType',
      Header: 'Project Type',
    },
    {
      accessor: 'scenarioTotalCount',
      Header: '# Scenarios',
    },
    {
      accessor: 'batchAnalysisTotalCount',
      Header: '# Batch Analyses',
    },
    {
      accessor: 'modified',
      Cell: ({
        row: {
          original: { modified },
        },
      }: FTCell) => getLastModifiedDateFormatted(modified),
      Header: 'Last modified (UTC)',
    },
    {
      accessor: 'opportunityStatus',
      Cell: OpportunityStatusCell,
      Header: 'Status',
    },
  ]

  if (filterOppertunities === 'installing') {
    columns.push({
      accessor: 'isLocked',
      Cell: OpportunityLockedUnlockedCell,
      disableSortBy: true,
    })
  }

  const batchColumns = [
    {
      accessor: 'redaptiveOpportunityId',
      Cell: ({
        row: {
          original: { id, name },
        },
      }: FTCell) => (
        <TableStyledLink
          key={name}
          href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/batch-analysis/${id}`}
        >
          {name}
        </TableStyledLink>
      ),
      Header: 'Analysis Name',
    },
    {
      accessor: 'redaptiveOpportunityIds',
      Cell: ({
        row: {
          original: { redaptiveOpportunityIds },
        },
      }: FTCell) => (
        <>
          {redaptiveOpportunityIds.slice(0, 2).join(', ')}
          {redaptiveOpportunityIds.length > 2 ?
            <CountIndicatorStyled>
              <div>{`+${redaptiveOpportunityIds.length - 2}`}</div>
            </CountIndicatorStyled>
          : null}
        </>
      ),
      Header: "Opportunity ID's",
    },
    {
      accessor: 'projectType',
      Cell: ({
        row: {
          original: { projectType },
        },
      }: FTCell) => getProjectTypeFormatted(projectType),
      Header: 'Project Type',
    },
    {
      accessor: 'createdBy',
      Cell: ({
        row: {
          original: { createdBy },
        },
      }: FTCell) => getNameFromEMail(createdBy),
      Header: 'Created By',
    },
    {
      accessor: 'lastModified',
      Cell: ({
        row: {
          original: { lastModified },
        },
      }: FTCell) => getLastModifiedDateFormatted(lastModified),
      Header: 'Last modified (UTC)',
    },
    {
      accessor: 'status',
      Cell: BatchStatusCell,
      Header: 'Status',
    },
  ]

  const renderMetrics = () => (
    <>
      {customerMetricsLoading && (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )}
      {!customerMetricsLoading && (
        <MetricsStyled>
          <Metric
            color={colors.blue2}
            metric={totalFixtures}
            title='Total Asset Count'
          />
          <Metric
            color={colors.green}
            metric={opportunitiesBillingAndMonitoring}
            title='Opportunities Billing & Monitoring'
          />
          <Metric
            color='#E7AC4C'
            metric={pendingOpportunities}
            title='Opportunities Pending'
          />
          <Metric
            color='#4A90E2'
            metric={batchAnalysesInProgressCount}
            title='Batch Analyses In Progress'
          />
        </MetricsStyled>
      )}
    </>
  )

  const HeaderActions = useCallback(
    () => (
      <FeatureValidator feature={AuthorizedFeatures.createProposalBatch}>
        <CreateBatchButtonStyled
          href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/create-new-batch`}
        >
          <AddCircleOutlineIconStyled />
          Create Batch
        </CreateBatchButtonStyled>
      </FeatureValidator>
    ),
    [],
  )

  const getDefaultValue = () => {
    if (filterOppertunities === 'scoping') return 1
    if (filterOppertunities === 'installing') return 2
    return 3
  }

  const OpportunitiesTabHeaderActions = React.useCallback(
    () => (
      <CustomDropdownMenuNewStyles>
        <DropdownMenuNew
          actions={[
            {
              label: 'Scoping',
              onClick: () => setFilterOppertunities('scoping'),
              value: 1,
            },
            {
              label: 'Installing',
              onClick: () => setFilterOppertunities('installing'),
              value: 2,
            },
            {
              label: 'Billing & Monitoring',
              onClick: () => setFilterOppertunities('billing_and_monitoring'),
              value: 3,
            },
          ]}
          thisBorderColor='#000000'
          borderOnClose
          textColor='#3E5CAA'
          defaultValue={getDefaultValue}
          showSelected
        />
      </CustomDropdownMenuNewStyles>
    ),
    [filterOppertunities],
  )

  const renderOpportunitySummaries = () => (
    <>
      {opportunitySummariesLoading && (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )}
      {!opportunitySummariesLoading && (
        <RedaptiveReactTable7
          HeaderActions={OpportunitiesTabHeaderActions}
          columns={columns}
          data={filteredOpportunitySummaries}
          defaultSort={[
            {
              id: 'redaptiveOpportunityId',
              desc: false,
            },
          ]}
          sideMarginWidth={24}
        />
      )}
    </>
  )

  const renderBatchSummaries = () => (
    <>
      {batchSummariesLoading && (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )}
      {!!batchSummariesError && <ErrorMessage message={batchSummariesError} />}
      {!batchSummariesLoading && !batchSummariesError && (
        <RedaptiveReactTable7
          columns={batchColumns}
          data={batchSummaries}
          defaultSort={[
            {
              id: 'lastModified',
              desc: true,
            },
          ]}
          sideMarginWidth={24}
          HeaderActions={HeaderActions}
        />
      )}
    </>
  )

  const renderMain = () => {
    const tabs = [
      {
        text: 'Opportunities',
        tabId: 'opportunities',
        render: renderOpportunitySummaries,
      },
      {
        text: 'Batch Analyses',
        tabId: 'batches',
        render: renderBatchSummaries,
      },
    ]
    return (
      <TabStyled>
        <TabPane2 tabs={tabs} />
      </TabStyled>
    )
  }

  return (
    <CustomerOpportunitiesStyled>
      <BreadcrumbsStyled>
        <Breadcrumbs2 items={breadcrumbs} />
      </BreadcrumbsStyled>
      {customerSummariesLoading && (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )}
      {!customerSummariesLoading && (
        <MainSectionsStyled>
          <SectionFirstStyled>
            <GlobalInputs />
          </SectionFirstStyled>
          <SectionSecondStyled>
            <SectionSecondTopStyled>
              {!!customerName && (
                <TitleWrapperStyled>
                  <ArrowBackIcon onClick={navigateBack}>Back</ArrowBackIcon>
                  <TitleStyled>{customerName}</TitleStyled>
                </TitleWrapperStyled>
              )}
              {renderMetrics()}
            </SectionSecondTopStyled>
            <ActionPaneView renderMain={renderMain} actions={[]} />
          </SectionSecondStyled>
        </MainSectionsStyled>
      )}
    </CustomerOpportunitiesStyled>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerGlobalInputsActions, dispatch),
    ...bindActionCreators(customerMetricsActions, dispatch),
    ...bindActionCreators(customerSummariesActions, dispatch),
    ...bindActionCreators(opportunitySummariesActions, dispatch),
    ...bindActionCreators(proposalBatchSummariesActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const customerMetricsEntity = selectProposalCustomerMetricsEntity(state)
  const batchSummariesEntity = selectProposalBatchSummariesEntity(state)
  const customerSummariesEntity = selectProposalCustomerSummariesEntity(state)
  const opportunitySummariesEntity =
    selectProposalOpportunitySummariesEntity(state)
  const {
    meta: { loading: customerMetricsLoading },
    items: customerMetrics,
  } = customerMetricsEntity
  const {
    meta: { loading: customerSummariesLoading },
    byId: customerSummariesById,
  } = customerSummariesEntity
  const {
    meta: { error: batchSummariesError, loading: batchSummariesLoading },
    items: batchSummaries,
  } = batchSummariesEntity
  const {
    meta: { loading: opportunitySummariesLoading },
    items: opportunitySummaries,
  } = opportunitySummariesEntity
  return {
    customerMetrics: customerMetrics[0],
    customerMetricsLoading,
    customerSummariesById,
    customerSummariesLoading,
    opportunitySummaries,
    opportunitySummariesLoading,
    batchSummaries,
    batchSummariesError,
    batchSummariesLoading,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerOpportunitiesPage),
)
