export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_REQUEST_ERROR = 'FORGOT_PASSWORD_REQUEST_ERROR'
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS'
export const SET_LOGIN_FORM_EMAIL_FIELD_VALUE =
  'SET_LOGIN_FORM_EMAIL_FIELD_VALUE'
export const HIDE_MODAL = 'HIDE_MODAL'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED'
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT'
export const SHOW_MODAL = 'SHOW_MODAL'
export const UPDATE_STATUS = 'UPDATE_STATUS'
