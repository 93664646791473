import moment from 'moment'

import * as consts from '../constants'

const getYearToDateRange = ({ lastMonth, programStartDate }: any) => {
  const startOfYear = moment().startOf('year')
  const start =
    programStartDate.isBefore(startOfYear) ? startOfYear : programStartDate
  let end

  if (
    moment().isSame(start, 'month') ||
    moment().isSame(moment().startOf('year').add(1, 'month'), 'month')
  ) {
    end = moment().startOf('year').endOf('month')
  } else {
    end = lastMonth
  }

  return [start, end]
}

const getProgramToDateRange = ({ lastMonth, programStartDate }: any) => {
  let start
  const end = lastMonth

  if (
    !programStartDate ||
    (programStartDate && moment().isSame(programStartDate, 'month')) ||
    (programStartDate && programStartDate.isAfter(moment()))
  ) {
    start = moment().subtract(12, 'months').startOf('month')
  } else {
    start = programStartDate
  }

  return [start, end]
}

const optionsDates = ({ programStartDate }: any): any => {
  const yesterday = moment().endOf('day').subtract(1, 'day').startOf('minute')
  const lastMonth = moment()
    .subtract(1, 'months')
    .endOf('month')
    .startOf('minute')
  return {
    [consts.LAST_24_HOURS]: [moment().subtract(24, 'hours'), yesterday],
    [consts.LAST_3_DAYS]: [
      moment().startOf('day').subtract(3, 'days'),
      yesterday,
    ],
    [consts.LAST_7_DAYS]: [
      moment().startOf('day').subtract(7, 'days'),
      yesterday,
    ],
    [consts.LAST_7_DAYS]: [
      moment().startOf('day').subtract(7, 'days'),
      yesterday,
    ],
    [consts.THIS_MONTH]: [
      moment().startOf('month'),
      moment().endOf('month').startOf('minute'),
    ],
    [consts.LAST_MONTH]: [
      moment().subtract(1, 'months').startOf('month'),
      lastMonth,
    ],
    [consts.LAST_3_MONTHS]: [
      moment().subtract(3, 'months').startOf('month'),
      lastMonth,
    ],
    [consts.LAST_6_MONTHS]: [
      moment().subtract(6, 'months').startOf('month'),
      lastMonth,
    ],
    [consts.LAST_12_MONTHS]: [
      moment().subtract(12, 'months').startOf('month'),
      lastMonth,
    ],
    [consts.YEAR_TO_DATE]: getYearToDateRange({
      lastMonth,
      programStartDate,
    }),
    [consts.PROGRAM_TO_DATE]: getProgramToDateRange({
      yesterday,
      programStartDate,
    }),
  }
}

export default optionsDates
