/* eslint-disable max-classes-per-file */
import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import type { FTUser } from './users'
import { makeActionTypes } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import './users'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
// Action Types
const typePrefix = 'AUTHZ_PLAYGROUND/'
export const types = {
  ...makeActionTypes('INSPECT_RESOURCE', typePrefix),
  ...makeActionTypes('CREATE_RESOURCE', typePrefix),
  ...makeActionTypes('PARTIAL_UPDATE_RESOURCE', typePrefix),
  ...makeActionTypes('FULL_UPDATE_RESOURCE', typePrefix),
  ...makeActionTypes('DELETE_RESOURCE', typePrefix),
  ...makeActionTypes('FETCH_USER', typePrefix),
  ...makeActionTypes('ADD_USER_GROUP', typePrefix),
  ...makeActionTypes('DELETE_USER_GROUP', typePrefix),
}
export type FTResourceEntity = {
  role: string
  param?: string
  loading: boolean
  error: string
}
export type FTUserEntity = FTUser & {
  loading: boolean
  error: string
}
const submitInitialState = {
  role: '',
  param: '',
  meta: {
    loading: false,
    error: '',
  },
}
// Reducers
export const initialState = {
  inspect: {
    role: '',
    meta: {
      loading: false,
      error: '',
    },
  },
  create: submitInitialState,
  partialUpdate: submitInitialState,
  fullUpdate: submitInitialState,
  delete: submitInitialState,
  user: {
    email: '',
    groups: [],
    loading: false,
    error: '',
  },
}
const reducerTemplates = [
  ['inspect', 'INSPECT_RESOURCE'],
  ['create', 'CREATE_RESOURCE'],
  ['partialUpdate', 'PARTIAL_UPDATE_RESOURCE'],
  ['fullUpdate', 'FULL_UPDATE_RESOURCE'],
  ['delete', 'DELETE_RESOURCE'],
].reduce(
  (reducers, [key, actionType]) => ({
    ...reducers,
    [key]: function reducer(state = initialState[key], action) {
      switch (action.type) {
        case types[actionType]:
          return { ...state, role: '', param: '', loading: true, error: '' }

        case types[`${actionType}_SUCCESS`]:
          return {
            ...state,
            role: action.payload.role,
            param: action.payload.param,
            loading: false,
            error: '',
          }

        case types[`${actionType}_ERROR`]:
          return {
            ...state,
            role: '',
            param: '',
            loading: false,
            error: action.error,
          }

        case types.ADD_USER_GROUP_SUCCESS:
        case types.DELETE_USER_GROUP_SUCCESS:
          return { ...state, role: '', param: '', error: '' }

        default:
          return state
      }
    },
  }),
  {},
)

function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.FETCH_USER:
    case types.ADD_USER_GROUP:
    case types.DELETE_USER_GROUP:
      return { ...state, loading: true, error: '' }

    case types.FETCH_USER_SUCCESS:
    case types.ADD_USER_GROUP_SUCCESS:
    case types.DELETE_USER_GROUP_SUCCESS:
      return { ...state, ...action.payload, loading: false, error: '' }

    case types.FETCH_USER_ERROR:
    case types.ADD_USER_GROUP_ERROR:
    case types.DELETE_USER_GROUP_ERROR:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default combineReducers({ ...reducerTemplates, user: userReducer })
type FTResourceAction = {
  param?: string
}
type FTFetchUserAction = {
  email: string
}
type FTGroupAction = {
  userId: string
  group: string
  email: string
}
// Action Creators
export const actions = {
  inspectResource: (params: FTResourceAction) => ({
    type: types.INSPECT_RESOURCE,
    ...params,
  }),
  createResource: (params: FTResourceAction) => ({
    type: types.CREATE_RESOURCE,
    ...params,
  }),
  partialUpdateResource: (params: FTResourceAction) => ({
    type: types.PARTIAL_UPDATE_RESOURCE,
    ...params,
  }),
  fullUpdateResource: (params: FTResourceAction) => ({
    type: types.FULL_UPDATE_RESOURCE,
    ...params,
  }),
  deleteResource: (params: FTResourceAction) => ({
    type: types.DELETE_RESOURCE,
    ...params,
  }),
  fetchUser: (params: FTFetchUserAction) => ({
    type: types.FETCH_USER,
    ...params,
  }),
  addUserGroup: (params: FTGroupAction) => ({
    type: types.ADD_USER_GROUP,
    ...params,
  }),
  deleteUserGroup: (params: FTGroupAction) => ({
    type: types.DELETE_USER_GROUP,
    ...params,
  }),
}
// API
export class API {
  static invokeMethodOnResource(
    method: string,
    body: Record<string, any> | null | undefined = null,
  ) {
    const url = `${consoleApiUrl()}/authorization-playground`
    let promise

    if (!body) {
      promise = axios[method](url, {
        headers: defaultHeaders(),
      })
    } else {
      promise = axios[method](url, body, {
        headers: defaultHeaders(),
      })
    }

    return promise.then(({ data }) => data).catch(handleAxiosError)
  }

  static inspectResource() {
    return API.invokeMethodOnResource('get')
  }

  static createResource(params: FTResourceAction) {
    return API.invokeMethodOnResource('post', {
      param: params.param,
    })
  }

  static partialUpdateResource(params: FTResourceAction) {
    return API.invokeMethodOnResource('patch', {
      param: params.param,
    })
  }

  static fullUpdateResource(params: FTResourceAction) {
    return API.invokeMethodOnResource('put', {
      param: params.param,
    })
  }

  static deleteResource() {
    return API.invokeMethodOnResource('delete')
  }
}

const mapSagas: any = ([apiCall, actionType]) => {
  function* sagaFunc(params: FTResourceAction): Generator<any, void, any> {
    try {
      const payload = yield call(apiCall, params)
      yield put({
        type: types[`${actionType}_SUCCESS`],
        payload,
      })
    } catch (e) {
      yield handleSagaError(types[`${actionType}_ERROR`], e)
    }
  }

  return takeLatest(types[actionType], sagaFunc)
}

export const resourceSagas = [
  [API.inspectResource, 'INSPECT_RESOURCE'],
  [API.createResource, 'CREATE_RESOURCE'],
  [API.partialUpdateResource, 'PARTIAL_UPDATE_RESOURCE'],
  [API.fullUpdateResource, 'FULL_UPDATE_RESOURCE'],
  [API.deleteResource, 'DELETE_RESOURCE'],
].map<[(...args: Array<any>) => any, string]>(mapSagas)

class UserAPI {
  static fetchUser(params: FTFetchUserAction) {
    const { email } = params
    return axios
      .get(`${consoleApiUrl()}/admin/users?email=${email}`, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static addGroup(params: FTGroupAction) {
    const { userId, group } = params
    const body = [group]
    return axios
      .post(`${consoleApiUrl()}/admin/users/${userId}/groups`, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static deleteGroup(params: FTGroupAction) {
    const { userId, group } = params
    return axios
      .delete(`${consoleApiUrl()}/admin/users/${userId}/groups/${group}`, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* fetchUserSaga(params: FTFetchUserAction): Generator<any, void, any> {
  try {
    const { results } = yield call(UserAPI.fetchUser, params)
    const payload = results[0]
    yield put({
      type: types.FETCH_USER_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_USER_ERROR, e)
  }
}

function* addUserGroupSaga(params: FTGroupAction): Generator<any, void, any> {
  const { email } = params

  try {
    const payload = yield call(UserAPI.addGroup, params)
    yield put({
      type: types.ADD_USER_GROUP_SUCCESS,
      payload,
    })
    yield fetchUserSaga({
      email,
    })
  } catch (e) {
    yield handleSagaError(types.ADD_USER_GROUP_ERROR, e)
  }
}

function* deleteUserGroupSaga(
  params: FTGroupAction,
): Generator<any, void, any> {
  const { email } = params

  try {
    const payload = yield call(UserAPI.deleteGroup, params)
    yield put({
      type: types.DELETE_USER_GROUP_SUCCESS,
      payload,
    })
    yield fetchUserSaga({
      email,
    })
  } catch (e) {
    yield handleSagaError(types.DELETE_USER_GROUP_ERROR, e)
  }
}

export const sagas = [
  ...resourceSagas,
  takeLatest(types.FETCH_USER, fetchUserSaga),
  takeLatest(types.ADD_USER_GROUP, addUserGroupSaga),
  takeLatest(types.DELETE_USER_GROUP, deleteUserGroupSaga),
]
