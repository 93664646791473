import { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTBillingReSendToVerificationModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import Button2 from '../Button2'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import TextArea, { TextAreaInnerStyled } from '../TextArea'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
} & FTBillingReSendToVerificationModal
const ModalBody = styled.div`
  padding: 20px;

  ${TextAreaInnerStyled} {
    max-width: 100%;
  }
`

const billingSendToVerificationModal = ({
  actions,
  savingsMonth,
  opportunityIds,
  loading,
  setResendToVerificationSubmitted,
}: FTProps) => {
  const [comment, setComment] = useState('')

  const handleReSendToVerificationCommentBlur = (
    event: React.SyntheticEvent,
  ) => {
    const {
      target: { value },
    } = event
    setComment(value)
  }

  const handleConfirm = () => {
    actions.resendToVerification({
      savingsMonth,
      comment,
      opportunityList: opportunityIds,
    })
    setResendToVerificationSubmitted(true)
  }

  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled>
        <ModalConfirmTitleStyled>
          Are you sure you want to send this Opportunity back to Performance
          Verification?
        </ModalConfirmTitleStyled>
        <ModalConfirmBodyStyled>
          <ModalBody>
            <TextArea
              onBlur={handleReSendToVerificationCommentBlur}
              placeholder='Comment'
            />
          </ModalBody>
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2 onClick={actions.hideModal} type='transparent'>
            Cancel
          </Button2>
          <Button2 onClick={handleConfirm} loading={loading} type='primary'>
            Confirm
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const {
    resendToVerificationMeta: { loading },
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(billingSendToVerificationModal)
