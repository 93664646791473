import { PureComponent } from 'react'

type FTProps = {
  icon: string
}
export default class FontAwesomeIcon extends PureComponent<FTProps> {
  render() {
    const { icon } = this.props
    const classStr = `fas ${icon}`
    return <i className={classStr} />
  }
}
