import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import CustomerFormPage from './CustomerFormPage'
import EditCustomerLogoPage from './EditCustomerLogoPage'
import ResetPasswordFormPage from './ResetPasswordFormPage'
import SignupFormPage from './SignupFormPage'
import SiteDetailPage from './SiteDetailPage'
import FeatureValidator from '../../authorization/components/FeatureValidator'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import features from '../../authorization/features'
import ActionPaneView from '../../components/ActionPaneView'
import Breadcrumbs from '../../components/Breadcrumbs'
import CustomerSitesTable from '../../components/CustomerSitesTable'
import { NoResults } from '../../components/EntityList'
import ESCustomerStatus from '../../components/ESCustomerStatus'
import StyledLink from '../../components/StyledLink'
import TabPane from '../../components/Tabs/TabPane'
import Title from '../../components/Title'
import UsersTable from '../../components/UsersTable'
import VerticalTable from '../../components/VerticalTable'
import { selectors as authSelectors } from '../../ducks/auth'
import {
  actions as customerActions,
  selectCustomerEntity,
} from '../../ducks/customers'
import {
  actions as energyStarIntegrationActions,
  energyStarSubmissionsEntity,
} from '../../ducks/energyStarIntegration'
import { actions as modalActions } from '../../ducks/modal'
import { actions as siteActions, selectSiteListEntity } from '../../ducks/sites'
import {
  actions as userSummaryActions,
  getUserSummaryListEntity,
} from '../../ducks/userSummaries'
import '../../types'
import type { FTRouterLocation } from '../../types'
import PanelDetailsPage from '../PanelConfiguration/PanelDetailsPage'
import SiteFormPage from './SiteFormPage'
import UserDetailPage from './UserDetailPage'
import UserFormPage from './UserFormPage'

type FTProps = FTRouterLocation
type FTState = {
  isESPMEditMode: boolean
}
const Component = styled.div``

class CustomerDetailPage extends PureComponent<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      isESPMEditMode: true,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { customerId },
      },
      actions,
      permissions,
    } = this.props
    actions.clearSites()
    actions.clearUserSummaries()
    actions.fetchCustomer({
      customerId,
    })

    if (features.energyStarIntegration.allMatchWithPermissions(permissions)) {
      actions.getESCustomerIDStatus({
        customerId,
      })
    }
  }

  componentDidUpdate({
    history,
    location: { pathname },
    match: { url },
    energyStarEntity: {
      meta: { loading },
    },
  }) {
    if (
      features.energyStarIntegration.allMatchWithPermissions(
        this.props.permissions,
      )
    ) {
      const isCurrEditPage = pathname.split('/').reverse()[0] === 'edit'

      if (
        isCurrEditPage &&
        loading &&
        !this.props.loading &&
        !pathname.includes('/sites/') &&
        !this.props.energyStarEntity?.meta?.error
      ) {
        history.push(`${url}/energyStar`)
      }
    }
  }

  renderSites = () => <CustomerSitesTable />

  renderUsers = () => {
    const {
      actions: { fetchUserSummaries },
      userSummaryListEntity,
      match: {
        url,
        params: { customerId },
      },
      customerEntity: { item: customer },
    } = this.props
    const customerName = customer.validName || customer.name
    const signupUrl =
      url.includes('/users') ? `${url}/signup` : `${url}/users/signup`
    return (
      <UsersTable
        customerId={customerId}
        fetchListEntity={fetchUserSummaries}
        listEntity={userSummaryListEntity}
        showCheckboxOption
        customerName={customerName}
        showDownload
        onNoResults={() => (
          <NoResults>
            <div>No users found.</div>
            <FeatureValidator
              feature={features.createUsers}
              requireAll
              onInvalid={() => (
                <div>
                  Contact the Redaptive Administrator to get permission to add
                  users.
                </div>
              )}
            >
              <StyledLink href={signupUrl}>Add a user</StyledLink>
            </FeatureValidator>
          </NoResults>
        )}
      />
    )
  }

  renderESPM = () => {
    const { isESPMEditMode } = this.state
    const {
      energyStarEntity,
      actions,
      match: {
        params: { customerId },
      },
    } = this.props

    const onSubmit = (values) => {
      actions.updateESCustomerIDStatus({
        customerId,
        reqData: values,
      })
    }

    return (
      <ESCustomerStatus
        onSubmit={onSubmit}
        isEditMode={isESPMEditMode}
        energyStarEntity={energyStarEntity}
      />
    )
  }

  renderTabPane = () => {
    const {
      match: { url },
      energyStarEntity: {
        meta: { loading },
      },
      permissions,
    } = this.props
    const editESCustomerStatusLink = {
      href: `${url}/energyStar/edit`,
      text: 'Edit',
      withDefaultTab: true,
    }
    const tabs = [
      {
        text: 'Sites',
        tab: 'sites',
        render: this.renderSites,
      },
      {
        text: 'Users',
        tab: 'users',
        render: this.renderUsers,
      },
    ]

    if (features.energyStarIntegration.allMatchWithPermissions(permissions)) {
      tabs.push({
        tab: 'energyStar',
        text: 'Energy Star',
        render: this.renderESPM,
        rightHandLink: !loading && editESCustomerStatusLink,
      })
    }

    return <TabPane tabs={tabs} />
  }

  renderMain = () => {
    const {
      customerEntity: { item: customer },
      match: { url },
    } = this.props

    if (!customer) {
      return <div>Loading...</div>
    }

    const customerName = customer.validName || customer.name
    const tableFields = [
      {
        label: 'Customer Name',
        value: customerName,
        editable: false,
      },
    ]
    const breadcrumbs = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      {
        href: '/account-management/customers',
        text: 'Customers',
      },
      {
        href: url,
        text: customerName,
      },
    ]
    return (
      <Component>
        <Breadcrumbs items={breadcrumbs} />
        <Title>Customer Detail</Title>
        <VerticalTable.Basic fields={tableFields} columned={false} />
        {this.renderTabPane()}
      </Component>
    )
  }

  renderCustomerDetail = () => {
    const {
      match: { url },
      permissions,
      groups,
    } = this.props
    const isAdmin = groups.includes('admin')

    const actions = [
      {
        href: `${url}/sites/create`,
        label: 'Add Site',
      },
    ]

    if (isAdmin) {
      actions.unshift({
        href: `${url}/edit-logo`,
        label: 'Edit Customer',
      })
    }

    if (features.createUsers.allMatchWithPermissions(permissions)) {
      actions.push({
        href: `${url}/users/create`,
        label: 'Add User',
      })
    }

    return (
      <div className='CustomerDetailPage'>
        <ActionPaneView renderMain={this.renderMain} actions={actions} />
      </div>
    )
  }

  render() {
    const {
      match: { path, url },
    } = this.props
    return (
      <div>
        <UnauthorizedRedirect
          feature={features.createUsers}
          requireAll
          from={`${path}/users/signup(.*)`}
          to={url}
        />
        <UnauthorizedRedirect
          from={`${path}/users/create(.*)`}
          to='/not-authorized'
          feature={features.createUsers}
        />

        <Switch>
          <Route path={`${path}/edit`} component={CustomerFormPage} />
          <Route path={`${path}/edit-logo`} component={EditCustomerLogoPage} />
          <Route path={`${path}/users/signup`} component={SignupFormPage} />
          <Route
            path={`${path}/users/create/:step?`}
            component={UserFormPage}
          />
          <Route
            path={`${path}/users/reset`}
            component={ResetPasswordFormPage}
          />
          <Route path={`${path}/users/:userId`} component={UserDetailPage} />
          <Route path={`${path}/sites/create`} component={SiteFormPage} />
          <Route
            path={`${path}/sites/:siteId/panels/create`}
            component={PanelDetailsPage}
          />
          <Route
            path={`${path}/sites/:siteId/panels/:panelId`}
            component={PanelDetailsPage}
          />
          <Route path={`${path}/sites/:siteId`} component={SiteDetailPage} />
          <Route render={this.renderCustomerDetail} />
        </Switch>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(userSummaryActions, dispatch),
    ...bindActionCreators(energyStarIntegrationActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state, props) => ({
  customerEntity: selectCustomerEntity(state, props.match.params.customerId),
  siteListEntity: selectSiteListEntity(state),
  userSummaryListEntity: getUserSummaryListEntity(state),
  permissions: authSelectors.selectPermissions(state),
  groups: authSelectors.selectGroups(state),
  energyStarEntity: energyStarSubmissionsEntity(state),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerDetailPage),
)
