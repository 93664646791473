export default {
  results: [
    {
      id: '578d1acf-cdc5-4980-8b49-dd8a7f950f6b',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP01',
      number: '01',
      description: 'Case Lights P-6-9',
      externalId: '01',
      breakerNumber: '1',
      meterChannel: 'A1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '525e93a0-b61a-45f6-b805-2b2bef03481e',
        name: 'Case Lights P-6-9',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '86c47e20-c8d8-4da4-bd46-951dfa6d9e56',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP02',
      number: '02',
      description: 'Case Lights P-10-13',
      externalId: '02',
      breakerNumber: '3',
      meterChannel: 'A2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '9e278c2e-fc53-4cf1-a6d0-11fdc38ba17e',
        name: 'Case Lights P-10-13',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '37738f01-b245-4dac-b9b5-03dfd0123bb1',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP03',
      number: '03',
      description: 'Case Lights P-1-2',
      externalId: '03',
      breakerNumber: '5',
      meterChannel: 'A3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'ff0c54d3-fc3a-42af-8d6f-6db3b7cc1776',
        name: 'Case Lights P-1-2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '9ce04a1c-1710-4164-8afc-89887f1360c8',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP04',
      number: '04',
      description: 'Case Lights IS-1-3',
      externalId: '04',
      breakerNumber: '7',
      meterChannel: 'A4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'b2b6ec2a-ed18-402a-aa71-cb97a45f8e4d',
        name: 'Case Lights IS-1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'c7cd87e1-2a62-4388-a0b1-bd4090be6cdf',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP05',
      number: '05',
      description: 'Case Lights IS-4-6',
      externalId: '05',
      breakerNumber: '9',
      meterChannel: 'B1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'f4959c18-c799-4901-8918-91154d321950',
        name: 'Case Lights IS-4-6',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'ccb12f10-a0f6-417e-ab2c-4a158cab2b97',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP06',
      number: '06',
      description: 'Case Lights SB-1',
      externalId: '06',
      breakerNumber: '11',
      meterChannel: 'B2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'f525dcb3-64d0-4d2b-bd04-6f67593d02e9',
        name: 'Case Lights SB-1',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '20b5ec21-4fe3-4e8c-954c-a8b944528988',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP07',
      number: '07',
      description: 'Case Lights M-1-3',
      externalId: '07',
      breakerNumber: '13',
      meterChannel: 'B3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'e721f19c-e761-44f2-b248-5b44e0f2175d',
        name: 'Case Lights M-1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'f75cdff4-61e8-4f1a-8f45-487442d44b6c',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP08',
      number: '08',
      description: 'Case Lights M-4-6',
      externalId: '08',
      breakerNumber: '15',
      meterChannel: 'B4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '59c8b470-b35a-4abc-9578-5baa77ec0890',
        name: 'Case Lights M-4-6',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '3dfce3dc-5a59-41d1-b089-4143e74d2875',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP09',
      number: '09',
      description: 'Case Lights M-7, SF-1',
      externalId: '09',
      breakerNumber: '17',
      meterChannel: 'C1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'eced43ac-1930-410c-b2ee-5d830b6f3b4e',
        name: 'Case Lights M-7, SF-1',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '665ddd1b-1411-49cb-b3ad-2cdec09dbfac',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP10',
      number: '10',
      description: 'Case Lights D-13',
      externalId: '10',
      breakerNumber: '19',
      meterChannel: 'C2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'ef65d8ba-28ad-4887-9240-2419a0eba594',
        name: 'Case Lights D-13',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'bd9ba1d3-b0a7-4f76-a8f3-2e6ddf40e94c',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP11',
      number: '11',
      description: 'Case Lights FF-16-18',
      externalId: '11',
      breakerNumber: '21',
      meterChannel: 'C3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '8123b61f-a2cb-4e52-91f3-7890c833d028',
        name: 'Case Lights FF-16-18',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'bde0ce95-4a9a-44ac-baba-3645ecfde386',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP12',
      number: '12',
      description: 'Case Lights FF-13-5',
      externalId: '12',
      breakerNumber: '23',
      meterChannel: 'C4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '6f798528-cbeb-4b6f-acd4-9dec29775822',
        name: ' Case Lights FF-13-5',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '22b12d48-496f-4f63-a677-6c7031291af5',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP13',
      number: '13',
      description: 'Case Lights FP-2',
      externalId: '13',
      breakerNumber: '25',
      meterChannel: 'D1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '7bd263d2-ad0b-4f5b-9ddd-89295696a376',
        name: 'Case Lights FP-2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '9526d4b4-38c3-4925-947d-45745c3f31f9',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP14',
      number: '14',
      description: 'Case Lights FF-5-8',
      externalId: '14',
      breakerNumber: '27',
      meterChannel: 'D2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '19580901-3c31-4bb2-9e60-93a1e1cbd111',
        name: 'Case Lights FF-5-8',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '7faf7ee6-8402-4bf5-8177-67029282a16d',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP15',
      number: '15',
      description: 'Case Lights FP-1',
      externalId: '15',
      breakerNumber: '29',
      meterChannel: 'D3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'ef0c13ad-5240-48b7-87a3-9936f90d58ce',
        name: 'Case Lights FP-1',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'f1902c1a-33c1-4596-bd27-3035df170e36',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP16',
      number: '16',
      description: 'Case Lights FP-9-12',
      externalId: '16',
      breakerNumber: '31',
      meterChannel: 'D4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'a19c3e13-90f8-4f8a-9b00-a1c5c6fcae3c',
        name: 'Case Lights FP-9-12',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '1bf5fd05-3a05-4224-aba4-86071dc61d70',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP17',
      number: '17',
      description: 'Case Lights IC-5-9',
      externalId: '17',
      breakerNumber: '33',
      meterChannel: 'E1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '5163e8c9-d2ac-40b0-85db-8c9f560f0d28',
        name: 'Case Lights IC-5-9',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '3af8fb59-a153-4412-a7ac-9cce29ef2db3',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP18',
      number: '18',
      description: 'Case Lights FF-1-4',
      externalId: '18',
      breakerNumber: '35',
      meterChannel: 'E2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '270495e4-e9f0-4748-ac4e-cc8af4996d97',
        name: ' Case Lights FF-1-4',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '825ece16-eb84-44c5-bdf2-fcfcc5ed5cdb',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP19',
      number: '19',
      description: 'Wild Harvest Lights',
      externalId: '19',
      breakerNumber: '37',
      meterChannel: 'E3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'b1558779-dc5c-4f1f-b017-5f35c489c80a',
        name: 'Wild Harvest Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'f9596f09-6537-4eda-b875-d5890db85287',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP20',
      number: '20',
      description: 'Wild Harvest Lights',
      externalId: '20',
      breakerNumber: '39',
      meterChannel: 'E4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'b1558779-dc5c-4f1f-b017-5f35c489c80a',
        name: 'Wild Harvest Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '0d074544-87b6-4001-9e03-bcfc4c24675a',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP21',
      number: '21',
      description: 'Case Lights FN-1-3',
      externalId: '21',
      breakerNumber: '2',
      meterChannel: 'F1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '3615f9ae-cc9e-4148-ab92-4ff274681dd7',
        name: 'Case Lights FN-1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '3bf0cffb-5ae8-4e77-b026-9a0a6cf192c8',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP22',
      number: '22',
      description: 'Case Lights P-4-5',
      externalId: '22',
      breakerNumber: '4',
      meterChannel: 'F2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '52139244-5288-4bf3-9173-14f95b07d887',
        name: 'Case Lights P-4-5',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'be5d8bba-2a2a-475c-aa3f-c24e05ea4b69',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP23',
      number: '23',
      description: 'Case Lights C-1-3',
      externalId: '23',
      breakerNumber: '6',
      meterChannel: 'F3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'a531d22f-138b-4850-b2c3-8c690b6eeef0',
        name: 'Case Lights C-1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '20f708ec-5bef-4588-8ff2-98ee1cb998d3',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP24',
      number: '24',
      description: 'Case Lights PZ-1, SW-1',
      externalId: '24',
      breakerNumber: '8',
      meterChannel: 'F4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '78783e4c-c0e0-4025-8ae0-e99ae5d7cfe3',
        name: 'Case Lights PZ-1, SW-1',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'f07ae325-c06a-44c0-91d3-487bbd90ff25',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP25',
      number: '25',
      description: 'Case Lights SD-1-2',
      externalId: '25',
      breakerNumber: '10',
      meterChannel: 'G1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '910d045d-dea4-4152-86a9-6500c062f81a',
        name: 'Case Lights SD-1-2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '55c0e669-a691-4d80-bd80-b4b4f2d6e5c3',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP26',
      number: '26',
      description: 'Case Lights SD-3-4',
      externalId: '26',
      breakerNumber: '12',
      meterChannel: 'G2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '75816d83-80f3-44d3-9b1e-56df6ea34ddb',
        name: 'Case Lights SD-3-4',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '3626ed6a-45c7-4fe7-bef5-a498c52a3598',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP27',
      number: '27',
      description: 'Case Lights FS-1 Sushi',
      externalId: '27',
      breakerNumber: '14',
      meterChannel: 'G3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '93459c3f-2f11-43cb-af43-7f712201525c',
        name: 'Case Lights FS-1 Sushi',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'c02125a0-1029-41ac-a3bf-96880669e5ef',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP28',
      number: '28',
      description: 'Case Lights FC-1',
      externalId: '28',
      breakerNumber: '16',
      meterChannel: 'G4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'f37e6f7e-e1ce-48ee-b885-34ea32dcfdad',
        name: 'Case Lights FC-1',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '5e3ff7d0-253b-4092-866e-9ea68bc942c9',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP29',
      number: '29',
      description: 'Case Lights CC-1-3',
      externalId: '29',
      breakerNumber: '18',
      meterChannel: 'H1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'd549d42f-8a63-4177-a914-39be263bbef2',
        name: 'Case Lights CC-1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '0d6488d5-672a-463b-adfa-d2f7264a1ba8',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP30',
      number: '30',
      description: 'Case Lights D-11-12',
      externalId: '30',
      breakerNumber: '20',
      meterChannel: 'H2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '15920b5a-4dfa-4c53-b5da-513d06cf6b8d',
        name: 'Case Lights D-11-12',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '9ae079ed-4f40-4e05-8a6a-45c548a3f61f',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP31',
      number: '31',
      description: 'Case Lights D-8-10',
      externalId: '31',
      breakerNumber: '22',
      meterChannel: 'H3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '5ad3e228-787b-487b-a9ea-35a84d59f3de',
        name: ' Case Lights D-8-10',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'fc5bbb6c-deed-413c-86db-35b1a6239f07',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP32',
      number: '32',
      description: 'Case Lights FM-1-2',
      externalId: '32',
      breakerNumber: '24',
      meterChannel: 'H4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'c8705915-a968-4300-8b21-b668af89c0ec',
        name: 'Case Lights FM-1-2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '567347d0-287f-44b9-80a4-5c4003327e91',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP33',
      number: '33',
      description: 'Case Lights Deli 1-3',
      externalId: '33',
      breakerNumber: '26',
      meterChannel: 'I1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '9ea55d21-f64e-458e-bd36-4e25b61212c3',
        name: 'Case Lights Deli 1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'aff0170b-182f-40c6-bf5d-e94b32e7ca5c',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP34',
      number: '34',
      description: 'Case Lights D-1-3',
      externalId: '34',
      breakerNumber: '28',
      meterChannel: 'I2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '64397669-eda0-4ace-b9a3-bd1b3d940ad1',
        name: 'Case Lights D-1-3',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '6b001bd0-1588-4d3c-831d-ac61ee62bbbf',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP35',
      number: '35',
      description: 'Case Lights D-4-6',
      externalId: '35',
      breakerNumber: '30',
      meterChannel: 'I3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'fe820c6d-badc-4286-968f-7170ffb52152',
        name: ' Case Lights D-4-6',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '99d98978-9c76-4c76-9247-ca18c024e20f',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP36',
      number: '36',
      description: 'Case Lights D-7',
      externalId: '36',
      breakerNumber: '32',
      meterChannel: 'I4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '3d52bab1-33d6-401f-8984-a34508c6daf3',
        name: 'Case Lights D-7',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '5cc97f5e-8264-411c-a0c5-0eda5755a53f',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP37',
      number: '37',
      description: 'Case Lights IC-1-4',
      externalId: '37',
      breakerNumber: '34',
      meterChannel: 'J1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '95a2cbe1-5210-440b-90f8-09f460c37f38',
        name: 'Case Lights IC-1-4',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '75242e57-b4e3-491c-a856-40f9aa52c992',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP38',
      number: '38',
      description: 'Roll-in Dairy Door Lights',
      externalId: '38',
      breakerNumber: '36',
      meterChannel: 'J2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'c4ef3025-fca5-455a-8a4d-5554a52d60b2',
        name: 'Roll-in Dairy Door Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '10c99fdb-ba55-4d27-b6bf-1dabaae8b40c',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP39',
      number: '39',
      description: 'Roll-in Dairy Door Lights',
      externalId: '39',
      breakerNumber: '38',
      meterChannel: 'J3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: 'c4ef3025-fca5-455a-8a4d-5554a52d60b2',
        name: 'Roll-in Dairy Door Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '134ad8fa-0707-4d73-b6d7-a9904945f8f9',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP40',
      number: '40',
      description: 'Pharmacy Counter Lights',
      externalId: '40',
      breakerNumber: '40',
      meterChannel: 'J4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '4f8168c5-4acf-4a06-b69a-550335fe3c8d',
        name: 'Pharmacy Counter Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'f18c2669-4e96-497d-af8b-8e766ebe07c0',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: '325365fc-1630-44de-a248-54e9b9e742c1',
        name: 'Other Submetered',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP41',
      number: '41',
      description: 'Circuit 40',
      externalId: '41',
      breakerNumber: '42',
      meterChannel: 'K1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: 'a276cd80-7b77-47b5-b756-0aaa0e96543e',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP42',
      number: '42',
      description: 'Gondola Lights',
      externalId: '42',
      breakerNumber: '44',
      meterChannel: 'K2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '04bf8f6a-28c3-403b-aa3c-5ef1aec85f7e',
        name: 'Gondola Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '1e89f6b9-efc0-47be-880c-998d4eb7107b',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP43',
      number: '43',
      description: 'Gondola Lights',
      externalId: '43',
      breakerNumber: '46',
      meterChannel: 'K3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '04bf8f6a-28c3-403b-aa3c-5ef1aec85f7e',
        name: 'Gondola Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '50b60ac4-53e7-4db6-bab2-4b33b472cee4',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
        name: 'Lighting',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP44',
      number: '44',
      description: 'Gondola Lights',
      externalId: '44',
      breakerNumber: '48',
      meterChannel: 'K4',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      equipmentSummary: {
        id: '04bf8f6a-28c3-403b-aa3c-5ef1aec85f7e',
        name: 'Gondola Lights',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '756c6fc3-5b6a-4a4d-95ed-39d5c00f85b8',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: '325365fc-1630-44de-a248-54e9b9e742c1',
        name: 'Other Submetered',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP45',
      number: '45',
      description: 'Temp Pizza Oven',
      externalId: '45',
      breakerNumber: '50',
      meterChannel: 'L1',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '5bde1865-ff91-4de4-87f9-f6e18da0a974',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: '325365fc-1630-44de-a248-54e9b9e742c1',
        name: 'Other Submetered',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP46',
      number: '46',
      description: 'Temp Pizza Oven',
      externalId: '46',
      breakerNumber: '52',
      meterChannel: 'L2',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '837729d0-8073-4196-b402-e6c883f41896',
      inUse: true,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      buildingSystemSummary: {
        id: '325365fc-1630-44de-a248-54e9b9e742c1',
        name: 'Other Submetered',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP47',
      number: '47',
      description: 'Temp Pizza Oven',
      externalId: '47',
      breakerNumber: '54',
      meterChannel: 'L3',
      panelSummary: {
        id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
        name: 'CL SEC 1&2',
        siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
        type: 'THREE_PHASE_3_WIRE_WYE',
        voltage: '_600V',
      },
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
    {
      id: '0c63bb04-9567-4fc8-a67d-5b5603f02256',
      inUse: false,
      meterSummary: {
        id: '15caf3fd-3690-4484-b419-384f5660f8b8',
        source: 'REDAPTIVE',
        generation: 'OR',
        name: '0035FF9A3D3D',
        description: '0035FF9A3D3D',
        externalId: '0035FF9A3D3D',
      },
      source: 'REDAPTIVE',
      name: '0035FF9A3D3DP48',
      number: '48',
      externalId: '48',
      meterChannel: 'L4',
      flippedCT: {
        status: 'NOT_FLIPPED_CT',
        defaultStatus: 'NOT_FLIPPED_CT',
        manuallySet: false,
      },
    },
  ],
  totalCount: 48,
}
