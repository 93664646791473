import classNames from 'classnames'
import { PureComponent } from 'react'
import styled from 'styled-components'

import './Spinner.css'

type FTProps = {
  inline?: boolean
  size?: 'tiny' | 'micro' | 'small' | 'medium'
}
export const SpinnerStyles = styled.div`
  ${({ inline }) =>
    inline &&
    `
    display: inline-block;
    position: relative;
    top: 2px;
  `}
`
const defaultSize = 'medium'
export default class Spinner extends PureComponent<FTProps> {
  static defaultProps = {
    inline: false,
    size: defaultSize,
  }

  render() {
    const { inline, size = defaultSize } = this.props
    const classname = classNames({
      Spinner: true,
      loader: true,
      [size]: true,
    })
    return <SpinnerStyles className={classname} inline={inline} />
  }
}
