import './Footer.css'
import { COMPANY_URL, PRIVACY_URL, TERMS_URL } from '../constants'
import {
  COMPANY_ADDRESS,
  FOOTER_HOME,
  FOOTER_PRIVACY,
  FOOTER_TERMS,
} from '../constants/strings'
import logo from '../redaptive-logo-reverse.svg'

const Footer = () => (
  <div className='Footer dark'>
    <div>
      <img className='Footer-logo' src={logo} alt='Redaptive' />
    </div>
    <div className='address'>
      {COMPANY_ADDRESS.map((line) => (
        <div key={line}>{line}</div>
      ))}
    </div>
    <div className='links'>
      <a target='_blank' rel='noopener noreferrer' href={COMPANY_URL}>
        {FOOTER_HOME}
      </a>
      <a target='_blank' rel='noopener noreferrer' href={TERMS_URL}>
        {FOOTER_TERMS}
      </a>
      <a target='_blank' rel='noopener noreferrer' href={PRIVACY_URL}>
        {FOOTER_PRIVACY}
      </a>
    </div>
  </div>
)

export default Footer
