import { TSEnergyStarSubmissions } from '../../../../ducks/energyStarIntegration/types'
import { TabUrlEnum, TabUrlWithSubmissionStage } from '../../helpers/utils'

const getDataByTabs = (rowData: TSEnergyStarSubmissions[]) => {
  const tabsResults = Object.values(TabUrlEnum).reduce<{
    [key: string]: { total: number; items: TSEnergyStarSubmissions[] }
  }>((acc, tab) => {
    acc[tab] = { total: 0, items: [] }
    return acc
  }, {})

  rowData?.forEach((item) => {
    tabsResults[TabUrlWithSubmissionStage[item.currentStage]].items.push(item)
    tabsResults[TabUrlWithSubmissionStage[item.currentStage]].total += 1
  })
  return tabsResults
}

export default getDataByTabs
