import Checkbox from 'rc-checkbox'
import { ComponentType, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { zIndices } from '../../utils'
import IonIcon from '../IonIcon'

const DropdownWrapper = styled.div`
  min-width: 206px;
`
const DropdownMenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff5c;
  z-index: -1;
`
const DropdownStyled = styled.div`
  user-select: none;
  height: 36px;
  line-height: 36px;
  padding-left: 12px;
  padding-right: 32px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  width: max-content;
  background-color: #fff;

  & .IonIcon {
    width: 25px;
    height: 18px;
    line-height: unset;

    & > span {
      left: 8px;
      top: -5px;
    }

    font-size: 18px;
  }
`
const DropdownIndicatorStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const DropdownIndicatorLabel = styled.div`
  font-weight: 600;
  margin-right: 6px;
`
const DropdownMenuStyled: ComponentType<{
  open: boolean
}> = styled.div`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  padding: 16px;
  position: absolute;
  margin-left: 60px;
  margin-top: 5px;
  top: 40px;
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
  min-width: 206px;
`
const DropdownMenuItemStyled = styled.div`
  padding: 5px;
`
const DropdownMenuItemHeaderStyled = styled.div`
  font-weight: 600;
  margin-bottom: 9px;
`
const DropdownMenuItemBodyStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;
  column-gap: 9px;
  padding-left: 1px;
  align-items: end;
`
type FTOptions = Record<
  string,
  {
    displayName: string
    apiName: string
  }
>
type FTGroupsMap = Record<
  string,
  {
    displayName: string
    roles: FTOptions
  }
>
type FTProps = {
  onSelect: (...args: Array<any>) => any
  previous: Record<string, any>
  filterField: string
}
// TODO: Add rest of the Groups here
const usersMap: FTGroupsMap = {
  redaptiveMobileApp: {
    displayName: 'Redaptive Mobile App',
    roles: {
      installer: {
        displayName: 'Installer',
        apiName: 'meter-install-app-access',
      },
      administrator: {
        displayName: 'Administrator',
        apiName: 'meter-install-app-admin-access',
      },
    },
  },
}

const RolesDropdown = (props: FTProps) => {
  const { onSelect, filterField = '' } = props
  const [selectedItems, setSelectedItems] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  useEffect(() => {
    const { previous } = props
    const { roles = [] } = previous || {}
    setSelectedItems(roles)
  }, [])
  const handleChange = useCallback(
    (e, role) => {
      let newSelectedItems = []

      if (e.target.checked) {
        newSelectedItems = [...selectedItems, role]
      } else {
        newSelectedItems = [...selectedItems.filter((item) => item !== role)]
      }

      onSelect({
        field: filterField,
        roles: newSelectedItems,
      })
      setSelectedItems(newSelectedItems)
    },
    [selectedItems, onSelect],
  )
  return (
    <DropdownWrapper>
      <DropdownIndicatorStyled>
        <DropdownIndicatorLabel>Filter by:</DropdownIndicatorLabel>
        <DropdownStyled
          onClick={() => setIsDropdownOpen((prevState) => !prevState)}
        >
          {selectedItems.length ?
            `- Roles (${selectedItems.length}) -`
          : '-- Roles --'}
          <IonIcon iconClass='ion-android-arrow-dropdown' />
        </DropdownStyled>
      </DropdownIndicatorStyled>
      <DropdownMenuStyled open={isDropdownOpen}>
        <DropdownMenuWrapper
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        {Object.keys(usersMap).map((userCategory) => (
          <DropdownMenuItemStyled key={userCategory}>
            <DropdownMenuItemHeaderStyled>
              {usersMap[userCategory].displayName}
            </DropdownMenuItemHeaderStyled>
            {Object.keys(usersMap[userCategory].roles).map((role) => (
              <DropdownMenuItemBodyStyled key={role}>
                <Checkbox
                  checked={selectedItems.includes(
                    usersMap[userCategory].roles[role].apiName,
                  )}
                  onChange={(e) =>
                    handleChange(e, usersMap[userCategory].roles[role].apiName)
                  }
                />
                <div>{usersMap[userCategory].roles[role].displayName}</div>
              </DropdownMenuItemBodyStyled>
            ))}
          </DropdownMenuItemStyled>
        ))}
      </DropdownMenuStyled>
    </DropdownWrapper>
  )
}

export default RolesDropdown
