import {
  ComponentType,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import {
  HeaderStyled,
  SortIconAsc,
  SortIconDesc,
  SortIconsStyled,
  SortIconsWrapperStyled,
  TableHeadStyled,
  ThInnerStyled,
  ThStyled,
} from './index'
import Checkbox from '../Checkbox'

export type FTTableHeaderRow = {
  id: string
  headers: Array<{
    align?: 'center' | 'left' | 'right'
    colspan?: number
    enableSelection?: boolean
    Filter?: any
    filterValue?: any
    handleSortClick?: (...args: Array<any>) => any
    initialSelectionValue?: boolean
    id: string
    label?: string
    maxWidth?: number
    minWidth?: number
    selectionHandler?: (...args: Array<any>) => any
    setFilter?: (...args: Array<any>) => any
    sideMarginWidth?: number
    sortable?: boolean
    sorted?: boolean
    sortDesc?: boolean
    thStyles?: typeof css
  }>
}
type FTProps = {
  rows: Array<FTTableHeaderRow>
  lockedColumns?: Array<string>
}
const HeaderStyledStyled = styled(HeaderStyled)`
  cursor: pointer;
`
const ThInnerStyledWithSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ThStyledStyled: ComponentType<{
  sideMarginWidth: number
  thStyles: typeof css
}> = styled(ThStyled)`
  ${({ thStyles }) => thStyles};
`
export default ({ rows, lockedColumns }: FTProps) => {
  const tableHeaderRefs = useMemo(
    () =>
      rows[0].headers.reduce(
        (acc, { id }) => ({ ...acc, [id]: createRef() }),
        {},
      ),
    [],
  )
  const getColumnsLeftOffset = useCallback(() => {
    let previousLeftOffset = 0
    const columnsOffset = rows[0].headers.reduce((acc, { id }) => {
      const clientWidth = // $FlowFixMe
        (tableHeaderRefs[id] &&
          tableHeaderRefs[id].current &&
          tableHeaderRefs[id].current.clientWidth) ||
        150
      const leftOffset = id !== 'actions' ? previousLeftOffset : 'auto'
      previousLeftOffset += clientWidth
      return { ...acc, [id]: leftOffset }
    }, {})
    return columnsOffset
  }, [rows, lockedColumns])
  const [columnsLeftOffset, setColumnsLeftOffset] = useState(
    getColumnsLeftOffset(),
  )
  useEffect(() => {
    setColumnsLeftOffset(getColumnsLeftOffset())
  }, [rows, lockedColumns])
  return (
    <TableHeadStyled>
      {rows.map(({ headers, id: rowId }) => (
        <tr key={rowId}>
          {headers.map(
            ({
              align,
              colspan = 1,
              enableSelection = false,
              initialSelectionValue = false,
              selectionHandler = () => {},
              Filter = () => '',
              filterValue = '',
              handleSortClick = () => {},
              id: headerId,
              label = '',
              maxWidth = 9999999,
              minWidth = 0,
              sideMarginWidth = 0,
              setFilter = () => {},
              sortable = true,
              sorted = false,
              sortDesc = false,
              thStyles = css``,
            }) => (
              <ThStyledStyled
                colSpan={colspan}
                left={columnsLeftOffset[headerId]}
                locked={
                  (lockedColumns && lockedColumns.includes(headerId)) || false
                }
                key={headerId}
                ref={tableHeaderRefs[headerId]}
                sideMarginWidth={sideMarginWidth}
                thStyles={thStyles}
              >
                <ThInnerStyled
                  align={align}
                  columnMaxWidth={maxWidth}
                  columnMinWidth={minWidth}
                >
                  {!!(label || sortable) && (
                    <ThInnerStyledWithSelection>
                      {enableSelection && (
                        <Checkbox
                          checked={initialSelectionValue}
                          onChange={selectionHandler}
                        />
                      )}
                      <HeaderStyledStyled
                        data-id={headerId}
                        onClick={handleSortClick}
                      >
                        {label}
                        {sortable && (
                          <SortIconsWrapperStyled>
                            <SortIconsStyled>
                              {!sorted && (
                                <>
                                  <SortIconAsc />
                                  <SortIconDesc />
                                </>
                              )}
                              {sorted && (
                                <>
                                  {!sortDesc && <SortIconAsc sorted />}
                                  {sortDesc && <SortIconDesc sorted />}
                                </>
                              )}
                            </SortIconsStyled>
                          </SortIconsWrapperStyled>
                        )}
                      </HeaderStyledStyled>
                    </ThInnerStyledWithSelection>
                  )}
                  {Filter && (
                    <Filter
                      column={{
                        filterValue,
                        setFilter,
                      }}
                    />
                  )}
                </ThInnerStyled>
              </ThStyledStyled>
            ),
          )}
        </tr>
      ))}
    </TableHeadStyled>
  )
}
