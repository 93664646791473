// ACTION TYPES
export const types = {
  UPLOAD_REQUEST: 'UPLOAD_REQUEST',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'UPLOAD_FAILURE',
  CLEAR_UPLOAD: 'CLEAR_UPLOAD',
}
export const resources = {
  SITE: 'SITE',
  SAVINGS: 'SAVINGS',
}
// ACTION CREATORS
export const actions = {
  uploadRequest: (customerId: string, resource: string, file: File) => ({
    type: types.UPLOAD_REQUEST,
    payload: {
      customerId,
      resource,
      file,
    },
  }),
  uploadProgress: (file: File, progress: number) => ({
    type: types.UPLOAD_PROGRESS,
    payload: progress,
    meta: {
      file,
    },
  }),
  uploadSuccess: (file: File) => ({
    type: types.UPLOAD_SUCCESS,
    meta: {
      file,
    },
  }),
  uploadFailure: (file: File, err: Error) => ({
    type: types.UPLOAD_FAILURE,
    payload: err,
    error: true,
    meta: {
      file,
    },
  }),
  clearUpload: () => ({
    type: types.CLEAR_UPLOAD,
  }),
}
// SELECTORS
export const getUploadProgress = (state) => state.upload.progress
// REDUCERS
export const initialState = {
  progress: 0,
  success: null,
  error: null,
  loading: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_REQUEST:
      return {
        ...state,
        progress: 0,
        success: null,
        error: null,
        loading: true,
      }

    case types.UPLOAD_PROGRESS:
      return { ...state, progress: action.payload }

    case types.UPLOAD_SUCCESS:
      return { ...state, progress: 100, success: true, loading: false }

    case types.UPLOAD_FAILURE:
      return {
        ...state,
        progress: -1,
        success: false,
        loading: false,
        error: action.payload,
      }

    case types.CLEAR_UPLOAD:
      return initialState

    default:
      return state
  }
}
