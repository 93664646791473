import * as React from 'react'
import styled from 'styled-components'

const Block = styled.div`
  background-color: #f7f7f7;
  padding: 20px;

  &:nth-child(even) {
    background-color: #ffffff;
  }

  input {
    transition: 500ms;
  }

  &.enter-done,
  &.exit-active,
  &.enter-done.initiallyRequired,
  &.exit-active.initiallyRequired {
    input {
      border-color: #d0021b;
    }
  }

  &.enter-active {
    input {
      box-shadow: 0 0 3px #d0021b;
      transform: scale(1.05);
      transition: 50ms;
    }
  }
`
type FTProps = {
  children: React.ReactNode
  className?: string
}

const EditBlock = ({ children, className, ...rest }: FTProps) => (
  <Block className={className} {...rest}>
    {children}
  </Block>
)

EditBlock.defaultProps = {
  className: '',
}
export default EditBlock
