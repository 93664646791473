import { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'

import { TabUrlEnum } from './helpers/utils'
import { BillingThisMonthMainPageStyled, HeaderStyled } from './styles'
import EnergyStarSubmissionPageNew from './submitSubmissionsNew'
import SummaryPage from './summaryPage'
import ListSelector from '../../components/ListSelector'
import PageHeader from '../../components/PageHeader'
import useRequirePermission from '../../hooks/useRequirePermission'
import { GROUPS } from '../../utils/constants'

const ListSelectorStyled = styled(ListSelector)<{ width?: string }>`
  ${({ width }) => `width:${width};`}
  .Select .Select__control {
    border: none;
    background-color: transparent;
    font-size: 18px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
`
const dropdownItems = [
  {
    name: 'Energy Star Summary',
    id: 'summary',
  },
  {
    name: 'Energy Star Submissions',
    id: 'submissions',
  },
]

const Submissions = (props: RouteComponentProps<{ id: string }>) => {
  useRequirePermission(GROUPS.ENERGY_STAR_ADMIN)

  const {
    history,
    match: { params },
  } = props
  const selectedPageId = params.id

  const handleBackNavigation = useCallback(() => {
    history.push('/reports')
  }, [history])

  useEffect(() => {
    if (selectedPageId !== 'summary' && selectedPageId !== 'submissions') {
      history.push(
        `/integration/energy-star/submissions/${TabUrlEnum.UNDER_MAINTENANCE}`,
      )
    }
  }, [history, selectedPageId])

  const renderDropdown = () => (
    <ListSelectorStyled
      width={selectedPageId === 'summary' ? '220px' : '250px'}
      edited={false}
      updateValue={({ value }) => {
        if (value === 'submissions') {
          history.push(
            `/integration/energy-star/${value}/${TabUrlEnum.UNDER_MAINTENANCE}`,
          )
          return
        }
        history.push(`/integration/energy-star/${value}`)
      }}
      items={dropdownItems}
      notSetLabelText='Select'
      selectedItem={dropdownItems.find(({ id }) => id === selectedPageId)}
      searchable={false}
      unsettable={false}
    />
  )

  return (
    <BillingThisMonthMainPageStyled>
      <HeaderStyled>
        <PageHeader
          Title={renderDropdown}
          handleBackNavigation={handleBackNavigation}
        />
      </HeaderStyled>
      {selectedPageId === 'summary' ?
        <SummaryPage />
      : <EnergyStarSubmissionPageNew />}
    </BillingThisMonthMainPageStyled>
  )
}

export default Submissions
