import * as Yup from 'yup'

export default Yup.object().shape({
  email: Yup.string().label('Email').email().required(),
  phone: Yup.string().matches(/^$|^\d{10}$/, 'Must be exactly 10 digits'),
  firstName: Yup.string()
    .label('First Name')
    .matches(/^[aA-zZ\s]*$/, 'Please enter valid name')
    .max(30),
  lastName: Yup.string()
    .label('Last Name')
    .matches(/^[aA-zZ\s]*$/, 'Please enter valid name')
    .max(30),
  company: Yup.string().label('Company').max(30),
})
