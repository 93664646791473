import { PureComponent } from 'react'
import styled from 'styled-components'

import FontAwesomeIcon from './FontAwesomeIcon'

type FTProps = {
  status: boolean
  labelText?: string
}
const Styled = styled.span`
  white-space: nowrap;
  margin-left: 1px;

  i {
    color: ${(props) => (props.status ? '#7BCC76' : '#C70D08')};
    font-size: 13px;
  }
  & span {
    padding-left: 6px;
  }
`
export default class Component extends PureComponent<FTProps> {
  render() {
    const { status } = this.props
    const { labelText = status ? 'Online' : 'Offline' } = this.props
    const icon = status ? 'fa-check-circle' : 'fa-exclamation-triangle'
    return (
      <Styled className='statusIcon' status={status}>
        <FontAwesomeIcon icon={icon} />
        <span>{labelText}</span>
      </Styled>
    )
  }
}
