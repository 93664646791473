import { UPDATE_SALESFORCE_STATES } from '../../../utils/constants'
import CheckOutline from '../../Icons/svg/CheckOutline'
import ExclamationOutline from '../../Icons/svg/ExclamationOutline'
import types from '../Banner/constants'
import { Heading, Icon, Text, Wrapper } from '../Banner/styles'

const { SUCCESS, LOADING } = UPDATE_SALESFORCE_STATES
type BannerProps = {
  text: string
  type: string
  heading: string
}
export default function Banner({ type, text, heading }: BannerProps) {
  const { border, background } = types[type]
  const icon =
    type === SUCCESS ?
      <CheckOutline fill={border} />
    : <ExclamationOutline fill={border} />
  return (
    <Wrapper border={border} background={background}>
      {type !== LOADING && <Icon>{icon}</Icon>}
      {(type === SUCCESS && (
        <>
          <Heading>{heading}</Heading>
          <Text>
            Contract object was created for{' '}
            <span style={{ fontWeight: 700 }}>{text}</span> on Salesforce.
          </Text>
        </>
      )) || (
        <>
          <Heading>{heading}</Heading>
          <Text>{text}</Text>
        </>
      )}
    </Wrapper>
  )
}
