export const meterInstallSubmissionDetails = {
  customerName: 'Default',
  siteAddress: 'US',
  installerDetails: {
    installerId: 'c1656e87-a4b2-49ee-ba20-7a4eb1331600',
    company: 'Redaptive ',
    email: 'jsaumya16@gmail.com',
    firstName: 'Saumya ',
    lastName: 'Jain',
    phone: '1234567890',
  },
  submissionTime: '2022-11-24T09:47:24.628299',
  opportunityId: 'E00013439',
  meterInstallationDetails: {
    macAddress: '883F4A30EC85',
    jobCode: 'E00013439',
    roid: 'E00013439',
    siteAddress: '',
    panels: [
      {
        id: '7f133555-a2e1-48f1-a3b4-952435662c5f',
        redaptiveId: 'bec49fb6-e14f-4193-8124-164ddb087455',
        name: 'Panel1',
        type: 'SINGLE_PHASE_3_WIRE',
        panelType: 'PANEL_BOARD',
        isDeEnergizedPanelTimeInMS: 1669282861328,
        isEnergizedPanel: false,
        isEnergizedPanelTimeStamp:
          'Thu Nov 24 2022 15:11:01 GMT+0530 (India Standard Time)',
        isEnergizedPanelTimeInMS: 1669282861328,
        isAuthorizedForEnergizedPanel: false,
        isAuthorizedForEnergizedPanelTimeStamp: '',
        isAuthorizedForEnergizedPanelTimeInMS: 0,
        voltage: '_120_240V',
        leftSideBreaker: {
          breakerOrder: 'ODD_NUMBERS',
          firstBreakerNumber: 1,
          lastBreakerNumber: 9,
          phasingPattern: 'TOP_TO_BOTTOM',
          breakerAndPhaseMapping: [
            {
              breakerNumber: 1,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 3,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 5,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 7,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 9,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
          ],
        },
        rightSideBreaker: {
          breakerOrder: 'EVEN_NUMBERS',
          firstBreakerNumber: 2,
          lastBreakerNumber: 10,
          phasingPattern: 'TOP_TO_BOTTOM',
          breakerAndPhaseMapping: [
            {
              breakerNumber: 2,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 4,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 6,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 8,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 10,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
          ],
        },
      },
      {
        id: 'dcfb1287-94d2-42bf-916b-ad2cbbeb5e98',
        redaptiveId: '9cbfafec-3b9e-41a6-91c2-de4f662c5919',
        name: 'Panel2',
        type: 'SINGLE_PHASE_3_WIRE',
        panelType: 'PANEL_BOARD',
        isDeEnergizedPanelTimeInMS: 1669282938214,
        isEnergizedPanel: false,
        isEnergizedPanelTimeStamp:
          'Thu Nov 24 2022 15:12:18 GMT+0530 (India Standard Time)',
        isEnergizedPanelTimeInMS: 1669282938214,
        isAuthorizedForEnergizedPanel: false,
        isAuthorizedForEnergizedPanelTimeStamp: '',
        isAuthorizedForEnergizedPanelTimeInMS: 0,
        voltage: '_120_240V',
        leftSideBreaker: {
          breakerOrder: 'ODD_NUMBERS',
          firstBreakerNumber: 3,
          lastBreakerNumber: 11,
          phasingPattern: 'BOTTOM_TO_TOP',
          breakerAndPhaseMapping: [
            {
              breakerNumber: 3,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 5,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 7,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 9,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 11,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
          ],
        },
        rightSideBreaker: {
          breakerOrder: 'EVEN_NUMBERS',
          firstBreakerNumber: 4,
          lastBreakerNumber: 12,
          phasingPattern: 'BOTTOM_TO_TOP',
          breakerAndPhaseMapping: [
            {
              breakerNumber: 4,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 6,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 8,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
            {
              breakerNumber: 10,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'A',
            },
            {
              breakerNumber: 12,
              breakerType: 'SINGLE_POLE_BREAKER',
              phase: 'B',
            },
          ],
        },
      },
      {
        id: 'd4f73b5d-b4cf-4bcf-ba56-5cee73c3f5c3',
        redaptiveId: '280801ba-c0c9-4c66-aa76-a7354e8d9185',
        name: 'Panel3',
        type: 'SINGLE_PHASE_3_WIRE',
        panelType: 'SWITCH_BOARD',
        isDeEnergizedPanelTimeInMS: 1669282992103,
        isEnergizedPanel: false,
        isEnergizedPanelTimeStamp:
          'Thu Nov 24 2022 15:13:12 GMT+0530 (India Standard Time)',
        isEnergizedPanelTimeInMS: 1669282992103,
        isAuthorizedForEnergizedPanel: false,
        isAuthorizedForEnergizedPanelTimeStamp: '',
        isAuthorizedForEnergizedPanelTimeInMS: 0,
        voltage: '_120_240V',
        switches: [
          {
            id: '3d670b27-99fb-49d9-a3be-1a4c6d91c4e9',
            switchName: 'Switch1',
            electricPhaseInUse: ['A', 'B'],
          },
          {
            id: 'f8422481-567a-4a08-b6c8-e068709d86c7',
            switchName: 'Switch2',
            electricPhaseInUse: ['A', 'B'],
          },
        ],
      },
    ],
    powerSource: {
      panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
      vtapL1PhaseA: '1',
      vtapL2PhaseB: '7',
      vtapL3PhaseC: '',
      isSuppleAndMeterPanelVoltageSame: false,
      supplyLineVoltage: '',
      describePowerSource: '',
    },
    currentTransformer: {
      reasonForNotConfiguredCircuit: 'Na',
      ctSizes: [
        {
          id: '3853b89d-e9f6-42b0-8dcc-ca10e4391423',
          meterPort: 'A',
          ctType: 30,
        },
        {
          id: '79ba384b-95dc-477e-856c-35d88ad36e4c',
          meterPort: 'B',
          ctType: 30,
        },
        {
          id: '2c363e24-92c2-4c36-9829-d6f9fc543983',
          meterPort: 'C',
          ctType: 30,
        },
        {
          id: 'a65c79cd-65bc-4e64-b5ca-1aad8ca666c5',
          meterPort: 'D',
          ctType: 50,
        },
      ],
      ctChannels: [
        {
          meterChannel: 'A1',
          ctSizeId: '3853b89d-e9f6-42b0-8dcc-ca10e4391423',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 1,
          phase: 'A',
        },
        {
          meterChannel: 'A2',
          ctSizeId: '3853b89d-e9f6-42b0-8dcc-ca10e4391423',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 3,
          phase: 'B',
        },
        {
          meterChannel: 'A3',
          ctSizeId: '3853b89d-e9f6-42b0-8dcc-ca10e4391423',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 5,
          phase: 'A',
        },
        {
          meterChannel: 'A4',
          ctSizeId: '3853b89d-e9f6-42b0-8dcc-ca10e4391423',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 7,
          phase: 'B',
        },
        {
          meterChannel: 'B1',
          ctSizeId: '79ba384b-95dc-477e-856c-35d88ad36e4c',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 9,
          phase: 'A',
        },
        {
          meterChannel: 'B2',
          ctSizeId: '79ba384b-95dc-477e-856c-35d88ad36e4c',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 2,
          phase: 'A',
        },
        {
          meterChannel: 'B3',
          ctSizeId: '79ba384b-95dc-477e-856c-35d88ad36e4c',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 4,
          phase: 'B',
        },
        {
          meterChannel: 'B4',
          ctSizeId: '79ba384b-95dc-477e-856c-35d88ad36e4c',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 6,
          phase: 'A',
        },
        {
          meterChannel: 'C1',
          ctSizeId: '2c363e24-92c2-4c36-9829-d6f9fc543983',
          panelId: 'd4f73b5d-b4cf-4bcf-ba56-5cee73c3f5c3',
          switchId: '3d670b27-99fb-49d9-a3be-1a4c6d91c4e9',
          phase: 'A',
        },
        {
          meterChannel: 'C2',
          ctSizeId: '2c363e24-92c2-4c36-9829-d6f9fc543983',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 8,
          phase: 'B',
        },
        {
          meterChannel: 'C3',
          ctSizeId: '2c363e24-92c2-4c36-9829-d6f9fc543983',
          panelId: 'd4f73b5d-b4cf-4bcf-ba56-5cee73c3f5c3',
          switchId: 'f8422481-567a-4a08-b6c8-e068709d86c7',
          phase: 'A',
        },
        {
          meterChannel: 'C4',
          ctSizeId: '2c363e24-92c2-4c36-9829-d6f9fc543983',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          mainFeed: 'B',
          phaseGroup: 'Panel Feed Input',
        },
        {
          meterChannel: 'D1',
          ctSizeId: 'a65c79cd-65bc-4e64-b5ca-1aad8ca666c5',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          breakerNumber: 10,
          phase: 'A',
        },
        {
          meterChannel: 'D2',
          ctSizeId: 'a65c79cd-65bc-4e64-b5ca-1aad8ca666c5',
          panelId: 'dcfb1287-94d2-42bf-916b-ad2cbbeb5e98',
          breakerNumber: 3,
          phase: 'B',
        },
        {
          meterChannel: 'D3',
          ctSizeId: 'a65c79cd-65bc-4e64-b5ca-1aad8ca666c5',
          panelId: 'd4f73b5d-b4cf-4bcf-ba56-5cee73c3f5c3',
          mainFeed: 'A',
          phaseGroup: 'Panel Feed Input',
        },
        {
          meterChannel: 'D4',
          ctSizeId: 'a65c79cd-65bc-4e64-b5ca-1aad8ca666c5',
          panelId: '7f133555-a2e1-48f1-a3b4-952435662c5f',
          mainFeed: 'B',
          phaseGroup: 'Panel Feed Input',
        },
        {
          meterChannel: 'E1',
        },
        {
          meterChannel: 'E2',
        },
        {
          meterChannel: 'E3',
        },
        {
          meterChannel: 'E4',
        },
        {
          meterChannel: 'F1',
        },
        {
          meterChannel: 'F2',
        },
        {
          meterChannel: 'F3',
        },
        {
          meterChannel: 'F4',
        },
        {
          meterChannel: 'G1',
        },
        {
          meterChannel: 'G2',
        },
        {
          meterChannel: 'G3',
        },
        {
          meterChannel: 'G4',
        },
        {
          meterChannel: 'H1',
        },
        {
          meterChannel: 'H2',
        },
        {
          meterChannel: 'H3',
        },
        {
          meterChannel: 'H4',
        },
        {
          meterChannel: 'I1',
        },
        {
          meterChannel: 'I2',
        },
        {
          meterChannel: 'I3',
        },
        {
          meterChannel: 'I4',
        },
        {
          meterChannel: 'J1',
        },
        {
          meterChannel: 'J2',
        },
        {
          meterChannel: 'J3',
        },
        {
          meterChannel: 'J4',
        },
        {
          meterChannel: 'K1',
        },
        {
          meterChannel: 'K2',
        },
        {
          meterChannel: 'K3',
        },
        {
          meterChannel: 'K4',
        },
        {
          meterChannel: 'L1',
        },
        {
          meterChannel: 'L2',
        },
        {
          meterChannel: 'L3',
        },
        {
          meterChannel: 'L4',
        },
      ],
    },
    cellRouter: {
      id: '80db8973-e04f-45bf-9a4f-04498a44421c',
      redaptiveId: 'ac823365-eeaa-4d34-bbb1-fd08fe1b4f60',
      macAddress: '883f4a30ec85',
      powerDescription: '',
    },
    wrapUp: {
      isLeftOverEquipment: true,
      excessMeterQuantity: 1,
      excessCellRouterQuantity: 1,
      excessCtHarnessQuantity: 1,
      excessWireTapQuantity: 1,
      notes: '',
    },
    isMeterPanelClosedTimeInMS: 1669283163444,
    isMeterPanelPowerRestoredTimeInMS: 1669283164655,
    testResults: {
      netWorkConnectionTest: {
        connectivityTestPass: true,
        lastMeasurement: '11/24/22 09:46:10 AM UTC',
      },
      signalStrengthTest: {
        signalStrengthTestPass: true,
        latency: '1m 09s',
        strength: '-82.00dBm',
        quality: '-7.67dB',
      },
      voltageTest: {
        minimumVoltage: '118.17',
        maximumVoltage: '120.16',
      },
    },
  },
}
// TODO: Confirm the data
export const meterInstallSubmissionPhotos = [
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbd',
    objectType: 'PANEL',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbe',
    objectType: 'PANEL',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbde',
    objectType: 'PANEL',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbdf',
    objectType: 'PANEL',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbeg',
    objectType: 'PANEL',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbdeh',
    objectType: 'PANEL',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbf',
    objectType: 'CT',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbg',
    objectType: 'CT',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbh',
    objectType: 'ROUTER',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbi',
    objectType: 'ROUTER',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbj',
    objectType: 'SAFETY_WARNING',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbk',
    objectType: 'SAFETY_WARNING',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
  {
    id: '4d6a214a-50c2-490e-856c-285e728a4dbl',
    objectType: 'WRAP_UP',
    objectId: '7cd3d223-ccd2-4101-86e9-2f0bd8d3f111',
    path: 'meter/38D2697B5718',
    fileName: 'abc.jpeg',
    fileSize: 21233,
    preSignedUrl: '',
    preSignedThumbnailUrl: '',
  },
]
