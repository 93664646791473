import InlinePendingIcon from './InlinePendingIcon'

type FTProps = {
  className?: string
  value: string
}

const CircuitPending = ({ className, value }: FTProps) => (
  <span className={className}>
    {` (${value} pending `}
    <InlinePendingIcon />)
  </span>
)

CircuitPending.defaultProps = {
  className: '',
}
export default CircuitPending
