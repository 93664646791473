import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Breadcrumbs'
import Spinner from '../../components/Spinner'
import Title from '../../components/Title'
import type { FTUserFormValues } from '../../components/UserForm'
import UserForm from '../../components/UserForm'
import { LOADING } from '../../constants/status'
import { selectors as authSelectors } from '../../ducks/auth'
import {
  actions as customerActions,
  naturallySortCustomers,
  selectCustomerIds,
  selectCustomerListEntity,
  selectCustomersById,
} from '../../ducks/customers'
import type { FTCustomer } from '../../ducks/customers'
import {
  actions as siteActions,
  selectSites,
  selectSitesById,
} from '../../ducks/sites'
import {
  actions as userActions,
  denaliUserGroupsMap,
  gcUserGroupsMap,
  maUserGroupsMap,
  selectUser,
  selectUserEntity,
  selectUserListEntityMeta,
  userGroupsMap,
} from '../../ducks/users'
import type {
  FTCreateUserAction,
  FTUpdateUserAction,
  FTUser,
} from '../../ducks/users'
import {
  actions as userSummaryActions,
  getUserSummaryListEntity,
} from '../../ducks/userSummaries'
import type { FTUserSummary } from '../../ducks/userSummaries'
import type { FTSite, FTWithRouter } from '../../types'

const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
`
type FTProps = {
  actions: any
  createLoading: boolean
  customerId: string | null | undefined
  customerIds: Array<string>
  customerIdUrlParam: string | null | undefined
  customers: Array<FTCustomer>
  customersById: Record<string, FTCustomer>
  isAccountManager: boolean
  isAdmin: boolean
  isEditForm: boolean
  loading: boolean
  newUser: FTUser | null | undefined
  sites: Array<FTSite>
  sitesById: Record<string, FTSite>
  step: number
  updateLoading: boolean
  user: FTUser | null | undefined
  userError: string
  userId?: string
  userSummaries: Array<FTUserSummary>
} & FTWithRouter

const UserFormPage = (props: FTProps) => {
  const {
    actions,
    createLoading,
    customerId,
    customerIds,
    customerIdUrlParam,
    customers,
    customersById,
    history,
    isAccountManager,
    isAdmin,
    isEditForm,
    loading,
    newUser,
    sites,
    sitesById,
    step,
    updateLoading,
    user,
    userError,
    userId,
    userSummaries,
  } = props
  const [loadingStarted, setLoadingStarted]: [
    boolean,
    (...args: Array<any>) => any,
  ] = React.useState(false)

  const unmountAction = () => {
    actions.clearCreateUserId()
  }

  React.useEffect(() => {
    if (userId) {
      actions.loadUser(userId)
    }

    actions.fetchAllSites({
      pageSize: 10000,
      pageNumber: 1,
    })

    actions.fetchAllCustomers({
      pageNumber: 1,
      pageSize: 10000,
    })
    actions.fetchUserSummaries({
      pageNumber: 1,
      pageSize: 10000,
    })
    return unmountAction
  }, [])
  React.useEffect(() => {
    if (loading) {
      setLoadingStarted(true)
    }
  }, [loading])
  const customerSubPath =
    customerIdUrlParam ? `/customers/${customerIdUrlParam}` : ''
  const customerPath =
    customerIdUrlParam ?
      `/account-management/customers/${customerIdUrlParam}/users`
    : ''
  const basePath =
    isEditForm && userId ?
      `/account-management${customerSubPath}/users/${userId}`
    : `/account-management${customerSubPath}/users`
  const baseUserFormPath =
    isEditForm && userId ?
      `/account-management${customerSubPath}/users/${userId}/edit`
    : `/account-management${customerSubPath}/users/create`

  const getBreadCrumbItems = () => {
    const breadCrumbItems = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      {
        href: '/account-management/users',
        text: 'Users',
      },
    ]

    if (user && userId) {
      breadCrumbItems.push({
        href: `/account-management/users/${userId}`,
        text: user.username,
      })
    }

    return breadCrumbItems
  }

  const setStep = (destinationStep) => {
    history.push(`${baseUserFormPath}/${destinationStep}`)
  }

  const getUserCreateRequestValues = (
    values: FTUserFormValues,
  ): FTCreateUserAction => {
    const denaliGroups =
      values.systemAccessDenaliUser ?
        Object.keys(values.groups)
          .filter(
            (key) =>
              values.groups[key] &&
              Object.keys(denaliUserGroupsMap).includes(key),
          )
          .map((fieldName) => userGroupsMap[fieldName].apiName)
      : []
    const gcGroups =
      values.systemAccessGcUser ?
        Object.keys(values.groups)
          .filter(
            (key) =>
              values.groups[key] && Object.keys(gcUserGroupsMap).includes(key),
          )
          .map((fieldName) => userGroupsMap[fieldName].apiName)
      : []
    const maGroups =
      values.systemAccessMaUser ?
        Object.keys(values.groups)
          .filter(
            (key) =>
              values.groups[key] && Object.keys(maUserGroupsMap).includes(key),
          )
          .map((fieldName) => userGroupsMap[fieldName].apiName)
      : []
    const allUserGroups = Object.keys(userGroupsMap).map(
      (entry) => userGroupsMap[entry].apiName,
    )
    const groupsAddedFromBE =
      user?.groups.filter((group) => !allUserGroups.includes(group)) || []
    let groups = [
      ...denaliGroups,
      ...gcGroups,
      ...maGroups,
      ...groupsAddedFromBE,
    ]
    if (values.systemAccessDashboardUser && !groups.includes('user')) {
      groups.push('user')
    }

    if (!values.systemAccessDashboardUser) {
      groups = groups.filter((groupName) => groupName !== 'user')
    }

    if (values.systemAccessDenaliUser && !groups.includes('denali-user')) {
      groups.push('denali-user')
    }
    if (
      values.systemAccessPerformanceInvoiceUser &&
      !groups.includes('r1-performance-invoice-views')
    ) {
      groups.push('r1-performance-invoice-views')
    }
    if (values.isrProcessorTool && !groups.includes('isr-processor-tool')) {
      groups.push('isr-processor-tool')
    }

    if (!values.systemAccessDenaliUser) {
      groups = groups.filter((groupName) => groupName !== 'denali-user')
    }

    if (
      values.systemAccessGcUser &&
      groups.includes('admin') &&
      !groups.includes('denali-admin')
    ) {
      groups.push('denali-admin')
    }

    const customerIdsWithSelectedSites = Object.keys(values.customers).filter(
      (key) => values.customers[key] === 'selectSites',
    )
    const associatedCustomers =
      (
        values.groups.admin ||
        !(values.systemAccessDashboardUser || values.systemAccessDenaliUser)
      ) ?
        []
      : Object.keys(values.customers).filter(
          (key) => values.customers[key] === 'allSites',
        )
    const associatedSites =
      (
        values.groups.admin ||
        !(values.systemAccessDashboardUser || values.systemAccessDenaliUser)
      ) ?
        []
      : values.sites.filter((siteId) =>
          customerIdsWithSelectedSites.includes(sitesById[siteId].customerId),
        )
    return {
      associatedCustomers,
      associatedSites,
      groups,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      company: values.company,
      source: 'REDAPTIVE',
      sendWelcomeEmail: values.sendWelcomeEmail,
      sendWelcomeEmailR1: values.sendWelcomeEmailR1,
    }
  }

  const getUserUpdateRequestValues = (
    values: FTUserFormValues,
  ): FTUpdateUserAction => ({
    ...getUserCreateRequestValues(values),
    source: values.source,
    id: values.userId,
  })

  const handleSubmit = (values: FTUserFormValues) => {
    if (values.userId) {
      actions.updateUser(getUserUpdateRequestValues(values))
    } else {
      actions.createUser(getUserCreateRequestValues(values))
    }
  }

  const handleCancel = () => {
    if (customerIdUrlParam) {
      history.push(customerPath)
    } else {
      history.push(basePath)
    }
  }

  const navigateToCreateUser = () => {
    history.push('/account-management/users/create')
  }

  const renderMain = () => (
    <UserForm
      cancelAction={handleCancel}
      createLoading={createLoading}
      customerId={customerId}
      customerIds={customerIds}
      customerIdUrlParam={customerIdUrlParam}
      customers={customers.sort(naturallySortCustomers)}
      customersById={customersById}
      isAccountManager={isAccountManager}
      isAdmin={isAdmin}
      navigateToCreateUser={navigateToCreateUser}
      newUser={newUser}
      sites={sites}
      sitesById={sitesById}
      submitAction={handleSubmit}
      setStep={setStep}
      step={step}
      updateLoading={updateLoading}
      user={user}
      userError={userError}
      userSummaries={userSummaries}
    />
  )

  return (
    <>
      <Breadcrumbs items={getBreadCrumbItems()} />
      <Title>{isEditForm ? 'Edit User' : 'Add New User'}</Title>
      {(loading || !loadingStarted) && (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )}
      {!loading && loadingStarted && renderMain()}
    </>
  )
}

UserFormPage.defaultProps = {
  userId: '',
}

const mapStateToProps = (state, props: FTProps) => {
  const {
    match: { params },
  } = props
  const { customerId: customerIdUrlParam, step, userId } = params
  const {
    item: user,
    meta: { loading: userLoading },
  } = selectUserEntity(state, userId)
  const { items: customers } = selectCustomerListEntity(state)
  const {
    createLoading,
    createUserId,
    error: userError,
    updateLoading,
  } = selectUserListEntityMeta(state)
  const { associatedCustomers = [] } = user || {}
  const customerId =
    customerIdUrlParam ||
    (associatedCustomers[0] && associatedCustomers[0].id) ||
    undefined
  const customerIds = selectCustomerIds(state)
  const newUser = selectUser(state, createUserId)
  const customersById = selectCustomersById(state)
  const sites = selectSites(state).filter((site) => site.active)
  const sitesById = selectSitesById(state)
  const {
    requestStatus: {
      customers: { status: customersStatus },
      sites: { status: sitesStatus },
    },
  } = state
  const isEditForm = !!userId
  const authGroups = authSelectors.selectGroups(state)
  const isAdmin = authGroups && authGroups.includes('admin')
  const isAccountManager = authGroups && authGroups.includes('account-managers')
  const {
    items: userSummaries,
    meta: { loading: userSummariesLoading },
  } = getUserSummaryListEntity(state)
  return {
    createLoading,
    customerId,
    customerIds,
    customerIdUrlParam,
    customers,
    customersById,
    isAccountManager,
    isAdmin,
    isEditForm,
    loading: !!(
      userLoading ||
      userSummariesLoading ||
      customersStatus === LOADING ||
      sitesStatus === LOADING
    ),
    newUser,
    sites,
    sitesById,
    step: Number.parseInt(step, 10) || 1,
    updateLoading,
    user,
    userError,
    userId,
    userSummaries,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(userSummaryActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFormPage)
