import FormField from './FormField'
import { inputIntegerOnInput, inputIntegerOnKeyDown } from './InputInteger'

const FormFieldInteger = (props: any) => (
  <FormField
    onInput={inputIntegerOnInput}
    onKeyDown={inputIntegerOnKeyDown}
    {...props}
  />
)

export default FormFieldInteger
