import styled from 'styled-components'

import { Step } from './models'
import { colors } from '../../utils/themes'

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    text-align: left;
    height: 30px;
    width: 150px;
    font-weight: 500;
    color: #6c6d6e;
    font-family: 'Public Sans';
    font-size: 14px;
    &.active {
      color: ${colors.redaptiveBlue};
      font-weight: 700;
      color: #337ab7;
      position: relative;
      &:before {
        position: absolute;
        content: ' ';
        display: block;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        width: 7px;
        height: 7px;
        background-color: #337ab7;
        border-radius: 50%;
        margin: 0 auto 10px;
      }
    }
    &.error {
      color: #ff0000;
      font-weight: 700;
      position: relative;
      &:before {
        position: absolute;
        content: ' ';
        display: block;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        width: 7px;
        height: 7px;
        background-color: #ff0000;
        border-radius: 50%;
        margin: 0 auto 10px;
      }
    }
    &:disabled {
      color: #b3b3b3;
      cursor: not-allowed;
    }
  }
`

type PanelCreationStepperProps = {
  steps: Step[]
  activeStepIndex: number
  disabled?: boolean
  showStepperError?: boolean
  setActiveStep: (stepIndex: number) => void
}

const PanelCreationStepper = (props: PanelCreationStepperProps) => {
  const { steps, setActiveStep, activeStepIndex, showStepperError, disabled } =
    props
  const getButtonClassName = (index: number, step: Step) => {
    if (index === activeStepIndex) return 'active'
    if (step.hasValidationErrors && showStepperError) return 'error'
    return ''
  }

  return (
    <StepsContainer>
      {steps.map((step, i) => (
        <button
          disabled={
            disabled || steps.slice(0, i).some((s) => s.hasValidationErrors)
          }
          type='button'
          key={step.label}
          className={getButtonClassName(i, step)}
          onClick={() => setActiveStep(i)}
        >
          {step.label}
        </button>
      ))}
    </StepsContainer>
  )
}

export default PanelCreationStepper
