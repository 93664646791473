export default [
  {
    id: '1',
    name: 'Lighting - Iron Mountain - Damastown Ave Damastown IE - Dublin - IE',
    activeMonth: '2021-12-08T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00004235',
    dataCapture: 98.0,
    category: 'Greater of Actuals',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ESTIMATE',
    calculatedActual: 4567,
    contractEstimate: 3000,
    reviewed: '2018-02-01T00:00:00.000Z',
    reviewedBy: 'raysa.aurrecochea@redaptiveinc.com',
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain UK',
    customerInternalId: 'd2dd236b-abe6-408c-8dfd-bf9bd0cf505c',
    oppInternalId: '169230',
    locale: 'en_GB',
    loadDate: '2021-12-08T09:49:44.269',
    codDate: '2021-12-08T10:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Business Intelligence',
    invoiceCreationStatus: null,
  },
  {
    id: '2',
    name: '330 Northpark Central Drive - Houston - TX',
    activeMonth: '2021-12-08T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00005901',
    dataCapture: 68.0,
    category: 'Insufficient Inputs',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ESTIMATE',
    calculatedActual: 2500,
    contractEstimate: 2500,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: true,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '160230',
    locale: 'en_US',
    loadDate: '2021-12-08T09:49:44.169',
    codDate: '2021-12-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Meter Maintenace',
    invoiceCreationStatus: null,
  },
  {
    id: '3',
    name: 'Lighting - 100 Main St - St. Helena - CA',
    activeMonth: '2021-12-03T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00010520',
    dataCapture: 75.0,
    category: 'Performance In Band',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ACTUAL',
    calculatedActual: 1200,
    contractEstimate: 1000,
    reviewed: '2019-06-05T00:00:00.000Z',
    reviewedBy: 'aaron.mellinger@redaptiveinc.com',
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Trinchero Family Estates',
    customerInternalId: 'ccb0034d-02d1-4ecf-baf2-a6d6cfab4ab6',
    oppInternalId: '168230',
    locale: 'en_US',
    loadDate: '2022-12-08T09:49:44.169',
    codDate: '2021-12-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Meter Maintenace',
    invoiceCreationStatus: null,
  },
  {
    id: '4',
    name: '355 Forest Avenue - Staten Island - NY',
    activeMonth: '2021-12-08T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00010525',
    dataCapture: 38.0,
    category: 'Performance Not In Band',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ESTIMATE',
    calculatedActual: 1500,
    contractEstimate: 1200,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: true,
    externalConversationLink: 'https://example.com',
    customer: 'Verizon',
    customerInternalId: '2d3cd92f-21cd-4eda-98f0-f489e091f5ca',
    oppInternalId: '169289',
    locale: 'en_US',
    loadDate: '2021-11-08T09:49:44.169',
    codDate: '2021-12-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Business Intelligence',
    invoiceCreationStatus: null,
  },
  {
    id: '5',
    name: '101 Lindbergh Drive - Santa Teresa - NM',
    activeMonth: '2021-12-05T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00010517',
    dataCapture: 58.0,
    category: 'Greater of Estimates',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ACTUAL',
    calculatedActual: 1000,
    contractEstimate: 1000,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: true,
    externalConversationLink: 'https://example.com',
    customer: 'CommScope',
    customerInternalId: '289840',
    oppInternalId: 'a41a55de-3d5c-4507-86ee-0c13610ea81f',
    locale: 'en_US',
    loadDate: '2021-12-09T09:49:44.169',
    codDate: '2021-12-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Accounts Receivables',
    invoiceCreationStatus: 'FAILED',
  },
  {
    id: '6',
    name: '103 Funston Road - Kansas City - KS',
    activeMonth: '2021-12-05T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00005833',
    dataCapture: 78.0,
    category: 'Insufficient Inputs',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ACTUAL',
    calculatedActual: 1300,
    contractEstimate: 1200,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Saint-Gobain',
    customerInternalId: 'f997d990-392c-4609-a37c-598a0d164cee',
    oppInternalId: '169229',
    locale: 'en_US',
    loadDate: '2021-05-08T09:30:44.169',
    codDate: '2021-05-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Other',
    invoiceCreationStatus: 'FAILED',
  },
  {
    id: '7',
    name: '10001 Fannin Road - Houston - TX',
    activeMonth: '2021-12-05T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00005330',
    dataCapture: 90.0,
    category: 'Greater of Actuals',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ACTUAL',
    calculatedActual: 1500,
    contractEstimate: 1200,
    reviewed: '2021-05-05T00:00:00.000Z',
    reviewedBy: 'brooke.mahoney@redaptiveinc.com',
    approved: '2021-05-10T00:00:00.000Z',
    approvedBy: 'brooke.mahoney@redaptiveinc.com',
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '169286',
    locale: 'en_US',
    loadDate: '2021-03-08T09:49:14.169',
    codDate: '2021-03-08T09:49:44.169',
    invoiceCreatedDateTime: '2021-03-08T09:49:14.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Technology',
    invoiceCreationStatus: 'FAILED',
  },
  {
    id: '8',
    name: '#0201 at 7730 Eastgate Road - Henderson - NV',
    activeMonth: '2021-12-04T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'E00009429',
    dataCapture: 87.0,
    category: 'Greater of Actuals',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ESTIMATE',
    calculatedActual: 1300,
    contractEstimate: 1200,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Caliber Collision',
    customerInternalId: '8e38a970-ab3b-4089-ac66-756b84392ac6',
    oppInternalId: '187229',
    locale: 'en_US',
    loadDate: '2021-04-08T09:49:44.169',
    codDate: '2021-04-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Sales',
    invoiceCreationStatus: 'FAILED',
  },
  {
    id: '9',
    name: '65 Egerton Road - Birmingham - UK',
    activeMonth: '2021-12-08T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00004221',
    dataCapture: 95.0,
    category: 'Greater of Actuals',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ESTIMATE',
    calculatedActual: 3478,
    contractEstimate: 3000,
    reviewed: '2018-02-01T00:00:00.000Z',
    reviewedBy: 'raysa.aurrecochea@redaptiveinc.com',
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain UK',
    customerInternalId: 'd2dd236b-abe6-408c-8dfd-bf9bd0cf505c',
    oppInternalId: '187230',
    locale: 'en_GB',
    loadDate: '2021-07-08T09:49:44.169',
    codDate: '2021-07-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Proposal Operations',
    invoiceCreationStatus: 'IN_PROGRESS',
  },
  {
    id: '10',
    name: '562 Gateway Drive - Napa - CA',
    activeMonth: '2021-12-03T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00003954',
    dataCapture: 70.0,
    category: 'Greater of Actuals',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ACTUAL',
    calculatedActual: 1200,
    contractEstimate: 1000,
    reviewed: '2019-06-05T00:00:00.000Z',
    reviewedBy: 'aaron.mellinger@redaptiveinc.com',
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Trinchero Family Estates',
    customerInternalId: 'ccb0034d-02d1-4ecf-baf2-a6d6cfab4ab6',
    oppInternalId: '168230',
    locale: 'en_US',
    loadDate: '2021-05-08T09:49:44.169',
    codDate: '2021-05-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Performance Management',
    invoiceCreationStatus: 'IN_PROGRESS',
  },
  {
    id: '11',
    name: '2202 South 7th Street - Phoenix - AZ',
    activeMonth: '2021-12-08T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00002310',
    dataCapture: 84.0,
    category: 'Performance In Band',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ESTIMATE',
    calculatedActual: 2000,
    contractEstimate: 2000,
    reviewed: '2021-05-05T00:00:00.000Z',
    reviewedBy: 'brooke.mahoney@redaptiveinc.com',
    approved: '2021-05-10T00:00:00.000Z',
    approvedBy: 'brooke.mahoney@redaptiveinc.com',
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '160230',
    locale: 'en_US',
    loadDate: '2021-02-08T09:49:44.169',
    codDate: '2021-06-08T09:49:44.169',
    invoiceCreatedDateTime: '2021-02-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Project Management',
    invoiceCreationStatus: 'IN_PROGRESS',
  },
  {
    id: '12',
    name: '341 Ari Court - Addison - IL',
    activeMonth: '2022-01-08T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00002622',
    dataCapture: 81.0,
    category: 'Performance In Band',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ACTUAL',
    calculatedActual: 2000,
    contractEstimate: 2000,
    reviewed: '2021-05-05T00:00:00.000Z',
    reviewedBy: 'brooke.mahoney@redaptiveinc.com',
    approved: '2021-05-10T00:00:00.000Z',
    approvedBy: 'brooke.mahoney@redaptiveinc.com',
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '160230',
    locale: 'en_US',
    loadDate: '2021-08-08T09:49:44.169',
    codDate: '2021-01-08T09:49:44.169',
    invoiceCreatedDateTime: '2021-08-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Meter Design',
    invoiceCreationStatus: 'IN_PROGRESS',
  },
  {
    id: '13',
    name: 'One Old Forge Hill Road - Franklin - MA',
    activeMonth: '2022-01-05T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00002630',
    dataCapture: 60.0,
    category: 'Insufficient Inputs',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ESTIMATE',
    calculatedActual: 100,
    contractEstimate: 1000,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: true,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '160230',
    locale: 'en_US',
    loadDate: '2021-12-08T09:49:44.200',
    codDate: '2021-11-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Meter Maintenace ',
    invoiceCreationStatus: 'IN_PROGRESS',
  },
  {
    id: '14',
    name: '6 Dock View Drive - New Castle - DE',
    activeMonth: '2022-01-02T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00007088',
    dataCapture: 75.0,
    category: 'Insufficient Inputs',
    suggestedAction: 'ESTIMATE',
    selectedAction: 'ESTIMATE',
    calculatedActual: 100,
    contractEstimate: 1000,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: false,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '160230',
    locale: 'en_US',
    loadDate: '2022-01-08T19:49:44.169',
    codDate: '2021-10-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Business Intelligence ',
    invoiceCreationStatus: 'SUCCESS',
  },
  {
    id: '15',
    name: '520 East Easy Street - Simi Valley - CA',
    activeMonth: '2022-01-09T09:49:44.169',
    reasons: [
      {
        id: 2,
      },
      {
        id: 1,
      },
    ],
    opportunityId: 'R00002594',
    dataCapture: 85.0,
    category: 'Performance In Band',
    suggestedAction: 'ACTUAL',
    selectedAction: 'ACTUAL',
    calculatedActual: 100,
    contractEstimate: 1000,
    reviewed: null,
    reviewedBy: null,
    approved: null,
    approvedBy: null,
    pendingInvestigation: true,
    externalConversationLink: 'https://example.com',
    customer: 'Iron Mountain',
    customerInternalId: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    oppInternalId: '160230',
    locale: 'en_US',
    loadDate: '2021-01-08T09:49:44.169',
    codDate: '2021-03-08T09:49:44.169',
    owner: '225840',
    billingStructure: 'Actual / Est. when Actual not available',
    actualPreRetrofit: '',
    actualPostRetrofit: '',
    historical1Mo: 12345,
    historical2Mo: 12345,
    historical3Mo: 12345,
    firstActualBill: true,
    issueOwners: 'Accounts Receivables',
    invoiceCreationStatus: 'SUCCESS',
  },
]
