import { faker } from '@faker-js/faker'

const items: Array<any> = new Array(200).fill(null).map(() => ({
  location: faker.hacker.noun(),
  preRetrofitQuantity: Math.round(Math.random() * 100),
  preRetrofitWattage: faker.helpers.replaceSymbols('##W'),
  preRetrofitHoursWithControls: Math.round(Math.random() * 1000),
  postRetrofitQuantity: Math.round(Math.random() * 100),
  postRetrofitWattage: faker.helpers.replaceSymbols('##W'),
  postRetrofitHoursWithControls: Math.round(Math.random() * 1000),
}))
export default items
