import { useEffect } from 'react'

type FTProps = {
  dependencies: Array<any>
}
export default (
  callback: (...args: Array<any>) => any,
  { dependencies = [] }: FTProps = {},
) => {
  useEffect(() => {
    const onKeyPress = (event) => event.keyCode === 27 && callback()

    window.document.addEventListener('keydown', onKeyPress)
    return () => {
      window.document.removeEventListener('keydown', onKeyPress)
    }
  }, dependencies)
}
