export const errors = {
  minLength: 'MAC addresses must be 12 characters',
  maxLength: 'Enter one MAC address per line',
  validMAC: 'MAC addresses can only contain numbers 0-9, letters A-F.',
}
export const normalizeMACAddress = (input) =>
  input ? input.toUpperCase().replace(/:/g, '') : ''
export const validateMACAddress = (input) => {
  const macAddress = normalizeMACAddress(input)

  if (macAddress.length < 12) {
    return errors.minLength
  }

  if (macAddress.length > 12) {
    return errors.maxLength
  }

  if (!/^(?:[0-9A-Fa-f]{2}){6}$/.exec(macAddress)) {
    return errors.validMAC
  }

  return ''
}
