import type { FTRole } from './roles'
import Roles from './roles'

/* An Ability describes the roles required to perform an action on a resource.

Example:
A user can perform the Abilities.CREATE ability if they are in any of the
following roles:
- Roles.CREATOR
- Roles.MANAGER
- Roles.ADMIN

Similarly, the user can only perform the Abilities.ADMINISTRATE ability if they
are in the Roles.ADMIN role.
*/
export type FTAbility = Array<FTRole>
export default class Abilities {
  static INSPECT = [
    Roles.INSPECTOR,
    Roles.CREATOR,
    Roles.EDITOR,
    Roles.MANAGER,
    Roles.ADMIN,
  ]

  static CREATE = [Roles.CREATOR, Roles.MANAGER, Roles.ADMIN]

  static EDIT = [Roles.EDITOR, Roles.MANAGER, Roles.ADMIN]

  static MANAGE = [Roles.MANAGER, Roles.ADMIN]

  static ADMINISTRATE = [Roles.ADMIN]
}
