export default {
  id: '1234',
  name: 'Mock Site Name',
  customerLocationCode1: 'Mock Customer Location Code1',
  customerLocationCode2: 'Mock Customer Location Code2',
  shippingStreet: '123 Fake St.',
  shippingState: 'CA',
  shippingCity: 'Springfield',
  shippingPostalCode: '99911',
  shippingCountry: 'US',
  squareFeet: 15984,
  customerId: '1234',
}
