export default () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.2321 7.06946C14.869 7.21476 14.7239 7.65044 14.869 8.01336C15.3046 8.88471 15.5225 9.8286 15.5225 10.8453C15.5225 14.476 12.6181 17.3804 8.98735 17.3804C5.35665 17.3804 2.45222 14.476 2.45222 10.8453C2.45222 7.21455 5.35665 4.31011 8.98735 4.31011C10.004 4.31011 10.9479 4.52795 11.8192 4.96362C12.1824 5.10892 12.6181 4.96362 12.7631 4.60049C12.9084 4.23735 12.7631 3.80168 12.4 3.6566C11.3838 3.22092 10.2218 2.93054 8.98757 2.93054C4.55827 2.93054 1 6.48849 1 10.9181C1 15.3474 4.55794 18.9057 8.98757 18.9057C13.4169 18.9057 16.9751 15.3477 16.9751 10.9181C16.9751 9.68363 16.6848 8.5219 16.1763 7.43271C16.031 7.06957 15.5954 6.92428 15.2322 7.06957L15.2321 7.06946Z'
      fill='#162447'
    />
    <path
      d='M8.98784 7.2874C9.42352 7.2874 9.78665 7.35994 10.2223 7.50524C10.5855 7.65054 11.0211 7.4327 11.1662 7.06957C11.3115 6.70643 11.0937 6.27075 10.7305 6.12567C10.1496 5.90762 9.5686 5.83508 8.98784 5.83508C6.15595 5.83508 3.90503 8.086 3.90503 10.9179C3.90503 13.7498 6.15595 16.0007 8.98784 16.0007C11.8197 16.0007 14.0707 13.7498 14.0707 10.9179C14.0707 10.3369 13.9981 9.75617 13.7803 9.24773C13.635 8.8846 13.1993 8.66676 12.8364 8.81206C12.4732 8.95736 12.2554 9.39303 12.4007 9.75595C12.546 10.1191 12.6185 10.5548 12.6185 10.9904C12.6185 13.0235 11.0211 14.6211 8.98784 14.6211C6.95476 14.6211 5.35714 13.0237 5.35714 10.9904C5.35714 8.95736 6.95454 7.28741 8.98784 7.28741V7.2874Z'
      fill='#162447'
    />
    <path
      d='M9.52558 9.72057C9.3415 9.63465 9.15861 9.6024 8.97627 9.60598C8.21074 9.621 7.60298 10.2415 7.61773 10.9931C7.63248 11.7446 8.2641 12.3408 9.02964 12.3258C9.79518 12.3108 10.4029 11.6903 10.3882 10.9387C10.3843 10.7419 10.3447 10.5816 10.2683 10.404L15.4523 5.111L16.853 4.9403L18.8191 2.91471L17.1377 2.71495L16.8495 1.03788L14.8831 3.04564L14.7639 4.40842L9.52558 9.72057Z'
      fill='#162447'
    />
  </svg>
)
