export default () => (
  <svg
    width='10'
    height='6'
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.62204 0.436435C4.82142 0.206205 5.17858 0.206205 5.37796 0.436436L9.47967 5.17267C9.76011 5.49649 9.53008 6 9.1017 6L0.898299 6C0.469923 6 0.239896 5.49649 0.520334 5.17267L4.62204 0.436435Z'
      fill='#6C6D6E'
    />
  </svg>
)
