export default () => (
  <svg
    width='10'
    height='6'
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.37796 5.56356C5.17858 5.79379 4.82142 5.79379 4.62204 5.56356L0.520333 0.827327C0.239895 0.503505 0.469922 3.31056e-08 0.898298 7.05554e-08L9.1017 7.8772e-07C9.53008 8.2517e-07 9.7601 0.503506 9.47967 0.827328L5.37796 5.56356Z'
      fill='#6C6D6E'
    />
  </svg>
)
