interface TSProps {
  height: number
  width: number
}
const UploadIcon = ({ height = 16, width = 16 }: TSProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={`${width}`}
    height={`${height}`}
    viewBox='0 0 17 17'
    fill='none'
  >
    <path
      d='M8.75948 2.62272C8.67206 2.52712 8.54852 2.47266 8.41895 2.47266C8.28938 2.47266 8.16584 2.52712 8.07842 2.62272L5.61747 5.31437C5.44551 5.50245 5.45858 5.79432 5.64665 5.96628C5.83474 6.13824 6.1266 6.12517 6.29856 5.93709L7.95752 4.12259V10.9321C7.95752 11.187 8.16412 11.3936 8.41895 11.3936C8.67378 11.3936 8.88037 11.187 8.88037 10.9321V4.12259L10.5394 5.93709C10.7113 6.12517 11.0032 6.13824 11.1913 5.96628C11.3793 5.79432 11.3924 5.50245 11.2204 5.31437L8.75948 2.62272Z'
      fill='#3E5CAA'
    />
    <path
      d='M8.3648 11.3347V2.85327M8.3648 2.85327L5.68115 5.91023M8.3648 2.85327L11.3196 5.80809'
      stroke='#3E5CAA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.34229 10.3169C3.34229 10.0621 3.1357 9.85547 2.88086 9.85547C2.62602 9.85547 2.41943 10.0621 2.41943 10.3169V10.3507C2.41942 11.1921 2.41941 11.8702 2.49112 12.4036C2.56558 12.9574 2.72485 13.4236 3.09518 13.794C3.4655 14.1643 3.93177 14.3236 4.48554 14.3981C5.01893 14.4697 5.69712 14.4697 6.53851 14.4697H10.2974C11.1388 14.4697 11.817 14.4697 12.3504 14.3981C12.9042 14.3236 13.3704 14.1643 13.7408 13.794C14.1111 13.4236 14.2704 12.9574 14.3448 12.4036C14.4165 11.8702 14.4165 11.1921 14.4165 10.3507V10.3169C14.4165 10.0621 14.2099 9.85547 13.9551 9.85547C13.7002 9.85547 13.4937 10.0621 13.4937 10.3169C13.4937 11.2 13.4927 11.8159 13.4302 12.2807C13.3695 12.7321 13.2585 12.9711 13.0882 13.1414C12.9179 13.3117 12.6789 13.4227 12.2274 13.4834C11.7627 13.5459 11.1468 13.5469 10.2637 13.5469H6.57227C5.68915 13.5469 5.07323 13.5459 4.60851 13.4834C4.15707 13.4227 3.918 13.3117 3.74773 13.1414C3.57746 12.9711 3.46644 12.7321 3.40575 12.2807C3.34326 11.8159 3.34229 11.2 3.34229 10.3169Z'
      fill='#3E5CAA'
    />
    <path
      d='M2.84521 9.92188C2.84521 9.92188 2.84529 12.8341 2.84521 14.0015H13.9401V9.92188'
      stroke='#3E5CAA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default UploadIcon
