import { PureComponent } from 'react'
import styled from 'styled-components'

const Component = styled.div`
  height: 100%;
  user-select: none;
`
const Card = styled.div`
  width: 478px;
  background-color: #ffffff;
  padding: 20px 35px;
  border: 1px solid #dcdcdc;
  text-align: center;
  margin: 60px auto auto;
`
const Title = styled.div`
  font-size: 20px;
`
const VersionNumber = styled.div`
  font-size: 15px;
  user-select: text;
`
type FTProps = {}
/* eslint-disable react/prefer-stateless-function */

export default class VersionPage extends PureComponent<FTProps> {
  render() {
    const { VITE_TAG, VITE_SHA1, VITE_BRANCH } = import.meta.env
    const tagBranch = VITE_BRANCH || VITE_TAG
    const version =
      VITE_SHA1 && tagBranch ? `${tagBranch}:${VITE_SHA1}` : 'local'
    return (
      <Component className='VersionPage old-ui'>
        <Card>
          <Title>Version</Title>
          <VersionNumber id='version'>{version}</VersionNumber>
        </Card>
      </Component>
    )
  }
}
