import axios from 'axios'
import { combineReducers } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { makeActionTypes } from '../utils'

type FTMeterConfigHistoryFetchParams = {
  meterId: string
  onlyMeterDataImpacted: boolean
  pageSize?: number
  pageNumber?: number
}
type FTMeterConfigComparisonFetchParams = {
  meterId: string
  activeVersion: number
}
export const types = {
  ...makeActionTypes('FETCH_METER_CONFIG_HISTORY'),
  ...makeActionTypes('FETCH_METER_CONFIG_COMPARISON'),
  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',
}

const initialState = {
  loading: false,
  error: null,
  data: {
    next: null,
    metadata: {
      macAddress: '',
      siteName: '',
    },
    results: [],
    currentVersionDetails: {},
    previousVersionDetails: {},
    totalCount: 0,
  },
  queryParams: {
    pageSize: 20,
    pageNumber: 1,
    onlyMeterDataImpacted: false,
  },
}

// reducer functions to use in combineReducers
const loadingReducer = (state = initialState.loading, action) => {
  switch (action.type) {
    case types.FETCH_METER_CONFIG_HISTORY:
      return true

    case types.FETCH_METER_CONFIG_HISTORY_SUCCESS:
    case types.FETCH_METER_CONFIG_HISTORY_ERROR:
      return false

    case types.FETCH_METER_CONFIG_COMPARISON:
      return true

    case types.FETCH_METER_CONFIG_COMPARISON_SUCCESS:
    case types.FETCH_METER_CONFIG_COMPARISON_ERROR:
      return false

    default:
      return state
  }
}

const queryParamsReducer = (state = initialState.queryParams, action) => {
  switch (action.type) {
    case types.UPDATE_QUERY_PARAMS:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case types.FETCH_METER_CONFIG_HISTORY:
    case types.FETCH_METER_CONFIG_HISTORY_SUCCESS:
      return null

    case types.FETCH_METER_CONFIG_HISTORY_ERROR:
      return action.payload.error

    case types.FETCH_METER_CONFIG_COMPARISON:
    case types.FETCH_METER_CONFIG_COMPARISON_SUCCESS:
      return null

    case types.FETCH_METER_CONFIG_COMPARISON_ERROR:
      return action.payload.error

    default:
      return state
  }
}

const dataReducer = (state = initialState.data, action) => {
  switch (action.type) {
    case types.FETCH_METER_CONFIG_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case types.FETCH_METER_CONFIG_HISTORY:
    case types.FETCH_METER_CONFIG_HISTORY_ERROR:
      return {
        ...initialState.data,
        pageSize: state.pageSize,
        pageNumber: state.pageNumber,
      }

    case types.FETCH_METER_CONFIG_COMPARISON_SUCCESS:
      return {
        ...state,
        currentVersionDetails: action.payload.config1,
        previousVersionDetails: action.payload.config2,
      }

    case types.FETCH_METER_CONFIG_COMPARISON:
    case types.FETCH_METER_CONFIG_COMPARISON_ERROR:
      return { ...state, currentVersionDetails: {}, previousVersionDetails: {} }

    default:
      return state
  }
}

export default combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  data: dataReducer,
  queryParams: queryParamsReducer,
}) // selectors

export const selectors = {
  getLoading: (state) => state.meterConfigHistory.loading,
  getError: (state) => state.meterConfigHistory.error,
  getData: (state) => state.meterConfigHistory.data,
  getQueryParams: (state) => state.meterConfigHistory.queryParams,
  getCurrentVersionDetails: (state) =>
    state.meterConfigHistory.data.currentVersionDetails,
  getPreviousVersionDetails: (state) =>
    state.meterConfigHistory.data.previousVersionDetails,
}
// actions
export const actions = {
  fetchMeterConfigHistory: (payload: FTMeterConfigHistoryFetchParams) => ({
    type: types.FETCH_METER_CONFIG_HISTORY,
    payload: { ...payload },
  }),
  fetchMeterConfigComparison: (
    payload: FTMeterConfigComparisonFetchParams,
  ) => ({
    type: types.FETCH_METER_CONFIG_COMPARISON,
    payload: { ...payload },
  }),
  updatePageSize: (pageSize: number) => ({
    type: types.UPDATE_PAGE_SIZE,
    payload: { pageSize },
  }),
  updatePageNumber: (pageNumber: number) => ({
    type: types.UPDATE_PAGE_NUMBER,
    payload: { pageNumber },
  }),
  updateQueryParams: (queryParams: any) => ({
    type: types.UPDATE_QUERY_PARAMS,
    payload: { ...queryParams },
  }),
}
// API class using axios
export class API {
  static fetchMeterConfigHistory = (
    params: FTMeterConfigHistoryFetchParams,
  ) => {
    const url =
      `${consoleApiUrl()}/meters/${
        params.meterId
      }/meter-config-history?pageSize=${params.pageSize}&pageNumber=${
        params.pageNumber
      }` + `${params.onlyMeterDataImpacted ? '&meterDataImpacted=true' : ''}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => response)
      .catch(handleAxiosError)
  }

  static fetchMeterConfigComparison = (
    params: FTMeterConfigComparisonFetchParams,
  ) => {
    const url =
      `${consoleApiUrl()}/meters/${
        params.meterId
      }/meter-config-history/compare` +
      `?currentVersion=${params.activeVersion}&previousVersion=${
        params.activeVersion - 1
      }`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => response)
      .catch(handleAxiosError)
  }
}
// saga functions for sagas array
export function* fetchMeterConfigHistorySaga({
  payload,
}: {
  payload: FTMeterConfigHistoryFetchParams
}) {
  try {
    const response = yield API.fetchMeterConfigHistory(payload)
    yield put({
      type: types.FETCH_METER_CONFIG_HISTORY_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    yield put(actions.fetchMeterConfigHistoryError(error))
  }
}
export function* fetchMeterConfigComparisonSaga({
  payload,
}: {
  payload: FTMeterConfigComparisonFetchParams
}) {
  try {
    const response = yield API.fetchMeterConfigComparison(payload)
    yield put({
      type: types.FETCH_METER_CONFIG_COMPARISON_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    yield put(actions.fetchMeterConfigComparisonError(error))
  }
}
export const sagas = [
  takeLatest(types.FETCH_METER_CONFIG_HISTORY, fetchMeterConfigHistorySaga),
  takeLatest(
    types.FETCH_METER_CONFIG_COMPARISON,
    fetchMeterConfigComparisonSaga,
  ),
]
