import axios from 'axios'
import FileSaver from 'file-saver'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'

export type FTProposalOpportunitySummaryDownloadAction = {
  filename: string
  opportunityId: string
}
export type FTProposalOpportunitySummaryDownloadMetaState = {
  error: string
  loading: boolean
}
export type FTProposalOpportunitySummaryDownloadState = {
  meta: FTProposalOpportunitySummaryDownloadMetaState
}
type FTState = {
  proposalOpportunitySummaryDownload: FTProposalOpportunitySummaryDownloadState
}
// Action Types
export const types = {
  DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY:
    'DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY',
  DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_SUCCESS:
    'DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_SUCCESS',
  DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_ERROR:
    'DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_ERROR',
}
export const actions = {
  downloadProposalOpportunitySummary: (
    props: FTProposalOpportunitySummaryDownloadAction,
  ) => ({
    type: types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY,
    ...props,
  }),
}
export const initialState = {
  meta: {
    error: '',
    loading: false,
  },
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY:
      return { ...state, error: '', loading: true }

    case types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  meta,
})
export const selectProposalOpportunitySummaryDownloadMeta = (
  state: FTState,
): FTProposalOpportunitySummaryDownloadMetaState =>
  state.proposalOpportunitySummaryDownload.meta
export const API = {
  downloadProposalOpportunitySummary: ({
    filename,
    opportunityId,
  }: FTProposalOpportunitySummaryDownloadAction) => {
    const baseUrl = `${consoleBaseUrl()}/proposal/api/opportunity/summary/download/zip`
    const url = `${baseUrl}?opportunityId=${opportunityId}`
    return axios
      .get(url, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => FileSaver.saveAs(response.data, filename))
      .catch(handleAxiosError)
  },
}

function* downloadProposalOpportunitySummarySaga({
  type,
  ...params
}: FTProposalOpportunitySummaryDownloadAction & {
  type: string
}): Generator<any, void, any> {
  try {
    yield call(API.downloadProposalOpportunitySummary, params)
    yield put({
      type: types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.DOWNLOAD_PROPOSAL_OPPORTUNITY_SUMMARY,
    downloadProposalOpportunitySummarySaga,
  ),
]
