export default {
  results: [
    {
      id: '3cb3d5a3-80b9-48a0-9fcb-07b9501b458a',
      name: 'P',
      siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      type: 'THREE_PHASE_4_WIRE_WYE',
      voltage: '_208Y_120V',
    },
    {
      id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
      name: 'CL SEC 1&2',
      siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      type: 'THREE_PHASE_3_WIRE_WYE',
      voltage: '_600V',
    },
    {
      id: '1915d975-b8e9-4481-a757-38710ad29d1a',
      name: 'LP',
      siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      type: 'THREE_PHASE_3_WIRE_DELTA',
      voltage: '_480V',
    },
    {
      id: '7b5c77b5-e5a8-4e5b-a73a-0246cf97cca9',
      name: 'EH',
      siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      type: 'THREE_PHASE_4_WIRE_WYE',
      voltage: '_480Y_277V',
    },
  ],
  totalCount: 4,
}
