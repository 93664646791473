import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import IonIcon from './IonIcon'
import type { FTMessageIconLookup, FTMessages } from '../ducks/messageFlasher'
import { actions as messageFlasherActions } from '../ducks/messageFlasher'

const MessageIcon = styled.div`
  height: 20px;
  position: relative;
  left: 2px;
  top: -7px;
  width: 20px;
  .icon.mi {
    left: -3px;
    top: 6px;
  }
`
const MessageIconContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex: none;
  justify-content: center;
  position: relative;
  width: 62px;
`
const MessageText = styled.div`
  font-weight: 500;
  padding: 12px 25px;
`
const MessageTextContainer = styled.div`
  flex-grow: 1;
`
const MessageCloseIcon = styled.div`
  cursor: pointer;
  height: 20px;
  left: 5px;
  position: relative;
  top: -4px;
  width: 20px;
  &:hover {
    opacity: 0.7;
  }
`
const MessageCloseIconContainer = styled.div`
  align-items: center;
  flex: none;
  justify-content: center;
  position: relative;
  display: flex;
  width: 62px;
`
const Message = styled.div`
  align-content: space-between;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  box-shadow: 0 0 9px 0 rgba(52, 65, 89, 0.26);
  display: flex;
  font-size: 14px;
  user-select: none;
  width: 100%;
`
const Messages = styled.div`
  margin: 30px 0;
  ${Message} + ${Message} {
    margin-top: 10px;
  }
`
type FTProps = {
  messages: FTMessages
  actions: Record<string, any>
}
const icons: FTMessageIconLookup = {
  info: {
    className: 'icon mi mi-info-outline',
    title: 'Info',
    backgroundColor: '#0193D7',
  },
  error: {
    className: 'ion-alert-circled',
    title: 'Error',
    backgroundColor: '#C70D08',
  },
  success: {
    className: 'ion-checkmark',
    title: 'Success',
    backgroundColor: '#7BCC76',
  },
  warning: {
    className: 'icon mi mi-info-outline',
    title: 'Warning',
    backgroundColor: '#F5B300',
  },
}

class MessageFlasher extends PureComponent<FTProps> {
  componentDidUpdate(prevProps: FTProps) {
    const { messages: messagesPrev } = prevProps
    const { actions, messages } = this.props
    const newMessage =
      messages.length > messagesPrev.length ?
        messages[messages.length - 1]
      : null

    if (!newMessage) {
      return
    }

    const { enableTimeout = true } = newMessage

    if (enableTimeout) {
      setTimeout(() => {
        actions.hideMessage(newMessage.messageId)
      }, 10000)
    }
  }

  componentWillUnmount() {
    this.props.actions.hideMessages()
  }

  hideMessage = (messageId: string) => () => {
    const { actions } = this.props
    actions.hideMessage(messageId)
  }

  render() {
    const { messages } = this.props

    if (messages.length === 0) {
      return ''
    }

    return (
      <Messages>
        {messages.map((value) => (
          <Message key={value.messageId}>
            <MessageIconContainer
              backgroundColor={icons[value.type].backgroundColor}
            >
              <MessageIcon>
                <IonIcon
                  iconClass={icons[value.type].className}
                  color='#FFF'
                  fontSize='20px'
                  title={icons[value.type].title}
                />
              </MessageIcon>
            </MessageIconContainer>
            <MessageTextContainer>
              <MessageText>
                <div>
                  <b>{value.title}</b>
                </div>
                {value.description && <div>{value.description}</div>}
              </MessageText>
            </MessageTextContainer>
            <MessageCloseIconContainer>
              <MessageCloseIcon onClick={this.hideMessage(value.messageId)}>
                <IonIcon
                  iconClass='ion-close'
                  color='#4A4A4A'
                  fontSize='17px'
                  title='Close Message'
                />
              </MessageCloseIcon>
            </MessageCloseIconContainer>
          </Message>
        ))}
      </Messages>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(messageFlasherActions, dispatch),
})

const mapStateToProps = ({ messageFlasher: { messages } }) => ({
  messages,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessageFlasher),
)
