import { colors } from '../../../../utils/themes'

const getColorForDelta = (value: number) => {
  if (!value) return ''
  if (Math.abs(value) > 10) return colors.red2
  if (Math.abs(value) > 5 && Math.abs(value) <= 10) return colors.orange
  if (Math.abs(value) <= 5) return colors.green2
  return ''
}

export default getColorForDelta
