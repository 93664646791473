import { capitalCase } from 'capital-case'
import styled from 'styled-components'

import Button2, { buttonColors } from '../../../components/Button2'
import DesignLibraryPageWrapper from '../DesignLibraryPageWrapper'

const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/indicator-card',
    text: 'Buttons',
  },
]
const MainContentStyled = styled.div`
  margin: 0 32px;
`
const ButtonsStyled = styled.div`
  > * {
    padding: 0 8px;
  }
`
export default () => (
  <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Buttons'>
    <MainContentStyled>
      {Object.keys(buttonColors).map((buttonType) => (
        <ButtonsStyled key={buttonType}>
          <Button2 type={buttonType}>{buttonType}</Button2>
          <Button2 type={buttonType} loading>{`${capitalCase(
            buttonType,
          )} Loading`}</Button2>
          <Button2 type={buttonType} disabled>{`${capitalCase(
            buttonType,
          )} Disabled`}</Button2>
        </ButtonsStyled>
      ))}
    </MainContentStyled>
  </DesignLibraryPageWrapper>
)
