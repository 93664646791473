import { useEffect, useState } from 'react'

import useEscapeKey from './useEscapeKey'
import type { FTRef } from '../types'

export default ({
  componentRef,
  initialVisible = false,
  toggleRef,
}: {
  componentRef: FTRef
  initialVisible?: boolean
  toggleRef: FTRef
}): {
  isComponentVisible: boolean
  setIsComponentVisible: (...args: Array<any>) => any
} => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialVisible)

  const handleClick = (event: MouseEvent) => {
    if (toggleRef.current && toggleRef.current.contains(event.target)) {
      setIsComponentVisible(!isComponentVisible)
    } else if (
      componentRef.current &&
      !componentRef.current.contains(event.target)
    ) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  })
  useEscapeKey(() => {
    setIsComponentVisible(false)
  })
  return {
    isComponentVisible,
    setIsComponentVisible,
  }
}
