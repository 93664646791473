import { PureComponent } from 'react'
import styled from 'styled-components'

import type { FTPanel } from '../../ducks/panels'
import PanelSelectorItem from '../PanelSelectorItem'

const StyledListItem = styled.div`
  background-color: ${({ editing }) => (editing ? '#f3f3f3' : '#ffffff')};
  height: 40px;
  padding: 10px;
  width: 400px;

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  input {
    margin-left: 11px;
    padding-left: 11px;
    border-radius: 3px;
    background-color: ${({ editing }) => (editing ? '#ffffff' : '#f3f3f3')};
    border: ${({ editing }) => (editing ? '1px solid #c7c7c7' : 'none')};
    width: 300px;
    height: 34px;
    margin-top: 1px;
  }
`
type FTProps = {
  onClick: (...args: Array<any>) => any
  onClickDelete: (...args: Array<any>) => any
  onClickEdit: (...args: Array<any>) => any
  panel: FTPanel
}
export default class PanelListEditorItem extends PureComponent<FTProps> {
  render() {
    const { onClick, onClickDelete, onClickEdit, panel } = this.props
    return (
      <StyledListItem onClick={onClick}>
        <PanelSelectorItem
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          panel={panel}
        />
      </StyledListItem>
    )
  }
}
