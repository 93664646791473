import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import BillingThisMonthMainPage from './BillingThisMonthMainPage'

const MeteredSitesStyled = styled.div`
  * {
    box-sizing: border-box;
  }
`
export default () => (
  <MeteredSitesStyled>
    <Switch>
      <Route component={BillingThisMonthMainPage} />
    </Switch>
  </MeteredSitesStyled>
)
