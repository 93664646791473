import styled from 'styled-components'

import Button from '../Button'

const WrapperStyles = styled.div`
  align-items: center;
  background-color: rgba(74, 74, 74, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
`
const ModalStyles = styled.div`
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 3px;
  padding: 30px 40px;
  text-align: center;
  width: 390px;
`
const MessageStyles = styled.div`
  margin-bottom: 30px;
`
type FTProps = {
  message: string
  goBack: (...args: Array<any>) => any
}

const PlotViewModal = (props: FTProps) => {
  const { goBack, message } = props
  return (
    <WrapperStyles>
      <ModalStyles>
        <MessageStyles>{message}</MessageStyles>
        <Button primary onClick={goBack}>
          OK
        </Button>
      </ModalStyles>
    </WrapperStyles>
  )
}

export default PlotViewModal
