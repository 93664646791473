import axios from 'axios'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { $Shape } from 'utility-types'

import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError, queryStringify } from '../../api/utils'
import customerGlobalInputsMockData from '../../mockData/proposals/customerGlobalInputs'
import { handleSagaError } from '../../sagas/utils'
import { isValueSet, isVariantActive } from '../../utils'

export type FTCreateProposalCustomerGlobalInputsAction = {
  annualTransferRate: number
  capexDealMarginTarget: number
  contingency: number
  annualInsurancePremium: number
  measurementAndVerificationCostAsPerProjectCosts: number
  measurementAndVerificationCostPerMeter: number
  measurementAndVerificationMethodology: string
  operationsAndMaintenanceBasis: string
  operationsAndMaintenanceRate: number
  projectManagementLaborAndFee: number
  rebateHaircut: number
  referralFeeBasis: string
  referralFeeInPercentage: number
  salesforceCustomerId: string
  term: number
  measurementAndVerificationCostAsPerProjectCostsInPercentage: number
  contractType: string
  maintenanceObligation: string
  maintenanceRetainedSavings: number
  energyRetainedSavings: number
}
type FTPutProposalCustomerGlobalInputsRequestBody = {
  annualTransferRate: number | null | undefined
  capexDealMarginTarget: number | null | undefined
  contingency: number
  annualInsurancePremium: number
  measurementAndVerificationCostAsPerProjectCosts: number | null | undefined
  measurementAndVerificationCostPerMeter: number | null | undefined
  measurementAndVerificationMethodology: string | null | undefined
  operationsAndMaintenanceBasis: string | null | undefined
  operationsAndMaintenanceRate: number
  projectManagementLaborAndFee: number
  rebateHaircut: number
  referralFeeBasis: string | null | undefined
  referralFeeInPercentage: number
  salesforceCustomerId: string | null | undefined
  term: number | null | undefined
  measurementAndVerificationCostAsPerProjectCostsInPercentage: number
  contractType: string
  maintenanceObligation: string
  maintenanceRetainedSavings: number
  energyRetainedSavings: number
}
type FTProposalCustomerGlobalInputsResponse =
  FTCreateProposalCustomerGlobalInputsAction & {
    currencyCode: string
    id: string | null | undefined
    locale: string
  }
export type FTProposalCustomerGlobalInputs =
  FTProposalCustomerGlobalInputsResponse
export type FTPutProposalCustomerGlobalInputsAction =
  $Shape<FTCreateProposalCustomerGlobalInputsAction> & {
    id: string
  }
export type FTFetchProposalCustomerGlobalInputsAction = {
  salesforceCustomerId: string
}
export type FTProposalCustomerGlobalInputsMetaState = {
  error: string
  loading: boolean
}
export type FTProposalCustomerGlobalInputsEntityState = {
  byId: Record<string, FTProposalCustomerGlobalInputs>
  items: Array<FTProposalCustomerGlobalInputs>
  meta: FTProposalCustomerGlobalInputsMetaState
}
type FTState = {
  entities: {
    proposalCustomerGlobalInputs: FTProposalCustomerGlobalInputsEntityState
  }
}
// Action Types
export const types = {
  FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
    'FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS',
  FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    'FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS',
  FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR:
    'FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR',
  CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
    'CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS',
  CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    'CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS',
  CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR:
    'CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR',
  PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS: 'PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS',
  PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    'PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS',
  PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR:
    'PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR',
}
export const actions = {
  fetchProposalCustomerGlobalInputs: (
    props: FTFetchProposalCustomerGlobalInputsAction,
  ) => ({
    type: types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS,
    ...props,
  }),
  createProposalCustomerGlobalInputs: (
    props: FTCreateProposalCustomerGlobalInputsAction,
  ) => ({
    type: types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS,
    ...props,
  }),
  putProposalCustomerGlobalInputs: (
    props: FTPutProposalCustomerGlobalInputsAction,
  ) => ({
    type: types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('proposalCustomerGlobalInputs')

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalCustomerGlobalInputs }
}

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalCustomerGlobalInputs> = Object.values(
    action.payload.entities.proposalCustomerGlobalInputs,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
      return initialState.byId

    case types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    case types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
      return initialState.items

    case types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    case types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
    case types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS:
      return { ...state, error: '', loading: true }

    case types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR:
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR:
    case types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    case types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
    case types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectProposalCustomerGlobalInputsEntity = (
  state: FTState,
): FTProposalCustomerGlobalInputsEntityState =>
  state.entities.proposalCustomerGlobalInputs
export const utils = {
  enhanceProposalCustomerGlobalInputs: (
    globalInputs: FTProposalCustomerGlobalInputsResponse,
  ): FTProposalCustomerGlobalInputs => ({
    ...globalInputs,
    locale: globalInputs.locale.replace('_', '-'),
  }),
}
export const proposalCustomerGlobalInputsDefaults: FTPutProposalCustomerGlobalInputsRequestBody =
  {
    annualInsurancePremium: 0.0,
    annualTransferRate: null,
    capexDealMarginTarget: null,
    contingency: 0.0,
    currencyCode: 'USD',
    id: null,
    locale: 'en_US',
    measurementAndVerificationCostAsPerProjectCosts: null,
    measurementAndVerificationCostPerMeter: null,
    measurementAndVerificationMethodology: null,
    operationsAndMaintenanceBasis: null,
    operationsAndMaintenanceRate: 0.0,
    opportunityType: null,
    projectManagementLaborAndFee: 0.0,
    rebateHaircut: 0.0,
    referralFeeBasis: null,
    referralFeeInPercentage: 0.0,
    salesforceCustomerId: null,
    term: null,
    measurementAndVerificationCostAsPerProjectCostsInPercentage: 0,
    contractType: 'EAAS',
    maintenanceObligation: 'No Maintenance',
    maintenanceRetainedSavings: 0,
    energyRetainedSavings: 0,
  }
export const API = {
  createProposalCustomerGlobalInputs: (
    params: FTCreateProposalCustomerGlobalInputsAction,
  ) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({
        ...params,
        currencyCode: 'USD',
        locale: 'en_US',
        id: 1234,
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/customer/global-input`
    return axios
      .post(url, params, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTProposalCustomerGlobalInputsResponse }) => data,
      )
      .catch(handleAxiosError)
  },
  fetchProposalCustomerGlobalInputs: (
    params: FTFetchProposalCustomerGlobalInputsAction,
  ) => {
    if (isVariantActive('3300mockGlobalInputsDefault')) {
      return Promise.resolve(proposalCustomerGlobalInputsDefaults).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    if (isVariantActive('3300mock')) {
      return Promise.resolve(customerGlobalInputsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const query = queryStringify(params)
    const url = `${consoleBaseUrl()}/proposal/api/customer/global-input?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTProposalCustomerGlobalInputsResponse }) => data,
      )
      .catch(handleAxiosError)
  },
  putProposalCustomerGlobalInputs: ({
    type,
    id,
    ...params
  }: FTPutProposalCustomerGlobalInputsAction & {
    type: string
  }) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({
        ...customerGlobalInputsMockData,
        ...params,
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/customer/global-input/${id}`
    return axios
      .put(url, params, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTProposalCustomerGlobalInputsResponse }) => data,
      )
      .catch(handleAxiosError)
  },
}

function* createProposalCustomerGlobalInputsSaga({
  type,
  ...params
}: FTCreateProposalCustomerGlobalInputsAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalCustomerGlobalInputsResponse = yield call(
      API.createProposalCustomerGlobalInputs,
      params,
    )
    const normalized = normalize(
      [utils.enhanceProposalCustomerGlobalInputs(response)],
      [entitySchema],
    )
    yield put({
      type: types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR, e)
  }
}

function* fetchProposalCustomerGlobalInputsSaga({
  type,
  ...params
}: FTFetchProposalCustomerGlobalInputsAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalCustomerGlobalInputsResponse = yield call(
      API.fetchProposalCustomerGlobalInputs,
      params,
    )
    const normalized =
      response.id ?
        normalize(
          [utils.enhanceProposalCustomerGlobalInputs(response)],
          [entitySchema],
        )
      : normalize([], [entitySchema])
    yield put({
      type: types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR, e)
  }
}

function* putProposalCustomerGlobalInputsSaga({
  type,
  ...params
}: FTPutProposalCustomerGlobalInputsAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const filteredParams = Object.keys(params).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]:
          isValueSet(params[cur]) ?
            params[cur]
          : proposalCustomerGlobalInputsDefaults[cur],
      }),
      {},
    )
    const response: FTProposalCustomerGlobalInputsResponse = yield call(
      API.putProposalCustomerGlobalInputs,
      filteredParams,
    )
    const normalized = normalize(
      [utils.enhanceProposalCustomerGlobalInputs(response)],
      [entitySchema],
    )
    yield put({
      type: types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.CREATE_PROPOSAL_CUSTOMER_GLOBAL_INPUTS,
    createProposalCustomerGlobalInputsSaga,
  ),
  takeLatest(
    types.FETCH_PROPOSAL_CUSTOMER_GLOBAL_INPUTS,
    fetchProposalCustomerGlobalInputsSaga,
  ),
  takeLatest(
    types.PUT_PROPOSAL_CUSTOMER_GLOBAL_INPUTS,
    putProposalCustomerGlobalInputsSaga,
  ),
]
