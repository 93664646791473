import styled from 'styled-components'

import Icons from './index'

const MenuIcon = styled(Icons.MaterialIcon).attrs({
  children: 'menu',
})`
  color: #a7a7a8;
`
export default MenuIcon
