import { withFormik } from 'formik'
import moment from 'moment'

import Form from './Form'

export default withFormik({
  handleSubmit(values, formikBag) {
    const {
      props: { submitAction },
    } = formikBag
    submitAction(values)
  },

  mapPropsToValues(props) {
    const {
      id,
      active,
      deactivationDate: deactivationDateProp,
      deactivationReason,
    } = props
    const deactivationDate =
      deactivationDateProp ? moment(deactivationDateProp) : null
    return {
      hour: deactivationDate ? deactivationDate.format('h') : '',
      minute: deactivationDate ? deactivationDate.minute() : '',
      second: deactivationDate ? deactivationDate.second() : '',
      ampm: deactivationDate ? deactivationDate.format('A') : '',
      id,
      active,
      deactivationDate,
      deactivationReason,
    }
  },
})(Form)
