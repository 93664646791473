import axios from 'axios'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import type { FTProposalOpportunity } from './opportunities'
import { consoleBaseUrl, defaultHeaders } from '../../../api'
import { handleAxiosError } from '../../../api/utils'
import mockBatchData from '../../../mockData/proposals/batch'
import { handleSagaError } from '../../../sagas/utils'
import type { FTProjectType } from '../../../utils'
import { isVariantActive } from '../../../utils'
import { makeActionTypes } from '../../utils'
import fetchProposalSite from '../sites'

type FTProposalBatchResponse = {
  created: string
  createdBy: string
  id: string
  lastModified: string
  modified: string
  modifiedBy: string
  name: string
  opportunityIds: Array<string>
  projectType: string
  redaptiveOpportunityIds: Array<string>
  salesforceCustomerId: string
  status: string
  opportunities: Array<FTProposalOpportunity>
}
type FTProposalSiteResponse = {
  id: string
  name: string
  customerLocationCode1: string
  customerLocationCode2: string | null | undefined
  shippingStreet: string
  shippingState: string
  shippingCity: string
  shippingPostalCode: string
  shippingCountry: string
  squareFeet: number
}
export type FTProposalSite = FTProposalSiteResponse
export type FTProposalSiteMetaState = {
  error: string
  loading: boolean
}
export type FTProposalSiteEntityState = {
  byId: Record<string, FTProposalSite>
  items: Array<FTProposalSite>
  meta: FTProposalSiteMetaState
}
export type FTProposalBatch = FTProposalBatchResponse
export type FTCreateProposalBatchAction = {
  name: string
  projectType: FTProjectType
  redaptiveOpportunityIds?: Array<string>
  opportunities?: Array<FTProposalOpportunity>
  salesforceCustomerId: string
}
export type FTUpdateProposalBatchAction = FTCreateProposalBatchAction & {
  id: string
}
export type FTUpdateProposalBatchStatusAction = {
  batchId: string
  status: string
}
export type FTFetchProposalBatchesAction = {
  batchId: string
}
export type FTProposalBatchMetaState = {
  error: string
  loading: boolean
}
export type FTFieldType = {
  fieldName: string
  sequenceNumber: number
  locked: boolean
  visible: boolean
}
export type FTProposalBatchEntityState = {
  byId: Record<string, FTProposalBatch>
  items: Array<FTProposalBatch>
  meta: FTProposalBatchMetaState
  updateMeta: {
    error: string
    loading: boolean
  }
  fieldMappings: Array<FTFieldType>
}
type FTState = {
  entities: {
    proposalBatches: FTProposalBatchEntityState
    proposalSites: FTProposalSiteEntityState
  }
}
type FTFetchProposalBatchFieldMappingResponse = {
  opportunityId?: string
  opportunityBatchAnalysisId?: string
  fieldMappings?: Array<FTFieldType>
}
type FTUpdateProposalBatchFieldMappingResponse = {
  opportunityId?: string
  opportunityBatchAnalysisId?: string
  fieldMappings: Array<FTFieldType>
}
export type FTFetchProposalBatchFieldMappingAction = {
  batchId: string
} & FTFetchProposalBatchFieldMappingResponse
export type FTUpdateProposalBatchFieldMappingAction = {
  batchId: string
} & FTUpdateProposalBatchFieldMappingResponse
export type FTFetchProposalSiteAction = {
  id: string
}
// Action Types
export const types = {
  ...makeActionTypes('CREATE_PROPOSAL_BATCH'),
  ...makeActionTypes('FETCH_PROPOSAL_BATCHES'),
  ...makeActionTypes('UPDATE_PROPOSAL_BATCHES'),
  ...makeActionTypes('UPDATE_PROPOSAL_BATCH_STATUS'),
  ...makeActionTypes('FETCH_PROPOSAL_BATCH_FIELD_MAPPINGS'),
  ...makeActionTypes('UPDATE_PROPOSAL_BATCH_FIELD_MAPPINGS'),
  ...makeActionTypes('FETCH_PROPOSAL_SITE'),
}
export const actions = {
  createProposalBatch: (props: FTCreateProposalBatchAction) => ({
    type: types.CREATE_PROPOSAL_BATCH,
    ...props,
  }),
  fetchProposalBatches: (props: FTFetchProposalBatchesAction) => ({
    type: types.FETCH_PROPOSAL_BATCHES,
    ...props,
  }),
  fetchProposalBatchFieldMappings: (
    props: FTFetchProposalBatchFieldMappingAction,
  ) => ({
    type: types.FETCH_PROPOSAL_BATCH_FIELD_MAPPINGS,
    ...props,
  }),
  updateProposalBatch: (props: FTUpdateProposalBatchAction) => ({
    type: types.UPDATE_PROPOSAL_BATCHES,
    ...props,
  }),
  updateProposalBatchStatus: (props: FTUpdateProposalBatchStatusAction) => ({
    type: types.UPDATE_PROPOSAL_BATCH_STATUS,
    ...props,
  }),
  updateProposalBatchFieldMappings: (
    props: FTUpdateProposalBatchFieldMappingAction,
  ) => ({
    type: types.UPDATE_PROPOSAL_BATCH_FIELD_MAPPINGS,
    ...props,
  }),
  fetchProposalSite: (props: FTFetchProposalSiteAction) => ({
    type: types.FETCH_PROPOSAL_SITE,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
  updateMeta: {
    error: '',
    loading: false,
  },
  fieldMappings: [],
}
export const entitySchema = new schema.Entity('proposalBatches')

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.CREATE_PROPOSAL_BATCH:
    case types.FETCH_PROPOSAL_BATCHES:
      return initialState.byId

    case types.CREATE_PROPOSAL_BATCH_SUCCESS:
    case types.FETCH_PROPOSAL_BATCHES_SUCCESS:
      return state

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_BATCHES:
    case types.CREATE_PROPOSAL_BATCH:
      return initialState.items

    case types.CREATE_PROPOSAL_BATCH_SUCCESS:
    case types.FETCH_PROPOSAL_BATCHES_SUCCESS:
      return { ...action.payload }

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.CREATE_PROPOSAL_BATCH:
    case types.FETCH_PROPOSAL_BATCHES:
      return { ...state, error: '', loading: true }

    case types.CREATE_PROPOSAL_BATCH_ERROR:
    case types.FETCH_PROPOSAL_BATCHES_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.CREATE_PROPOSAL_BATCH_SUCCESS:
    case types.FETCH_PROPOSAL_BATCHES_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function updateMeta(state = initialState.updateMeta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_BATCHES:
      return { ...state, error: '' }

    case types.UPDATE_PROPOSAL_BATCHES:
    case types.UPDATE_PROPOSAL_BATCH_STATUS:
      return { ...state, error: '', loading: true }

    case types.UPDATE_PROPOSAL_BATCHES_ERROR:
    case types.UPDATE_PROPOSAL_BATCH_STATUS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.UPDATE_PROPOSAL_BATCHES_SUCCESS:
    case types.UPDATE_PROPOSAL_BATCH_STATUS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function fieldMappings(state = initialState.fieldMappings, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_BATCH_FIELD_MAPPINGS_SUCCESS:
    case types.UPDATE_PROPOSAL_BATCH_FIELD_MAPPINGS_SUCCESS:
      return action.fieldMappings

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  updateMeta,
  fieldMappings,
})
export const selectProposalBatchesEntity = (
  state: FTState,
): FTProposalBatchEntityState => state.entities.proposalBatches
export const selectProposalSiteEntity = (
  state: FTState,
): FTProposalSiteEntityState => state.entities.proposalSites
export const API = {
  createProposalBatch: (params: FTCreateProposalBatchAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(mockBatchData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/batch-analysis`
    return axios
      .post(url, params, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalBatchResponse }) => data)
      .catch(handleAxiosError)
  },
  fetchProposalBatches: ({ batchId }: FTFetchProposalBatchesAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(mockBatchData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/batch-analysis/${batchId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalBatchResponse }) => data)
      .catch(handleAxiosError)
  },
  updateProposalBatches: (params: FTUpdateProposalBatchAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(mockBatchData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const { id: batchId } = params
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/batch-analysis/${batchId}`
    return axios
      .put(url, params, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalBatchResponse }) => data)
      .catch(handleAxiosError)
  },
  updateProposalBatchStatus: ({
    batchId,
    status,
  }: FTUpdateProposalBatchStatusAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(mockBatchData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/batch-analysis/${batchId}/status`
    return axios
      .patch(
        url,
        {
          status,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }: { data: FTProposalBatchResponse }) => data)
      .catch(handleAxiosError)
  },
  fetchProposalBatchFieldMappings: ({
    batchId,
  }: FTFetchProposalBatchFieldMappingAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({}).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/field-mapping?opportunityBatchAnalysisId=${batchId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTFetchProposalBatchFieldMappingResponse }) => data,
      )
      .catch(handleAxiosError)
  },
  updateProposalBatchFieldMappings: ({
    batchId,
    fieldMappings: fieldMappingsNew,
  }: FTUpdateProposalBatchFieldMappingAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({
        fieldName: '',
        sequence: 1,
        fieldMappings: [],
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/field-mapping`
    return axios
      .post(
        url,
        {
          opportunityBatchAnalysisId: batchId,
          fieldMappings: [...fieldMappingsNew],
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(
        ({ data }: { data: FTUpdateProposalBatchFieldMappingResponse }) => data,
      )
      .catch(handleAxiosError)
  },
}

function* createProposalBatchSaga(
  params: FTCreateProposalBatchAction,
): Generator<any, void, any> {
  try {
    const response: FTProposalBatchResponse = yield call(
      API.createProposalBatch,
      params,
    )
    yield put({
      type: types.CREATE_PROPOSAL_BATCH_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.CREATE_PROPOSAL_BATCH_ERROR, e)
  }
}

function* fetchProposalBatchesSaga(
  params: FTCreateProposalBatchAction,
): Generator<any, void, any> {
  try {
    const response: FTProposalBatchResponse = yield call(
      API.fetchProposalBatches,
      params,
    )
    yield put({
      type: types.FETCH_PROPOSAL_BATCHES_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_BATCHES_ERROR, e)
  }
}

function* updateProposalBatchesSaga(
  params: FTUpdateProposalBatchAction,
): Generator<any, void, any> {
  try {
    const response: FTProposalBatchResponse = yield call(
      API.updateProposalBatches,
      params,
    )
    yield put({
      type: types.UPDATE_PROPOSAL_BATCHES_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PROPOSAL_BATCHES_ERROR, e)
  }
}

function* updateProposalBatchStatusSaga(
  params: FTUpdateProposalBatchAction,
): Generator<any, void, any> {
  try {
    const response: FTProposalBatchResponse = yield call(
      API.updateProposalBatchStatus,
      params,
    )
    yield put({
      type: types.UPDATE_PROPOSAL_BATCH_STATUS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PROPOSAL_BATCH_STATUS_ERROR, e)
  }
}

function* fetchProposalBatchFieldMappingsSaga({
  type,
  ...params
}: FTFetchProposalBatchFieldMappingAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const payload: FTFetchProposalBatchFieldMappingResponse = yield call(
      API.fetchProposalBatchFieldMappings,
      params,
    )
    yield put({
      type: types.FETCH_PROPOSAL_BATCH_FIELD_MAPPINGS_SUCCESS,
      ...payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_BATCH_FIELD_MAPPINGS_ERROR, e)
  }
}

function* updateProposalBatchFieldMappingsSaga({
  type,
  ...params
}: FTUpdateProposalBatchFieldMappingAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const payload: FTUpdateProposalBatchFieldMappingResponse = yield call(
      API.updateProposalBatchFieldMappings,
      params,
    )
    yield put({
      type: types.UPDATE_PROPOSAL_BATCH_FIELD_MAPPINGS_SUCCESS,
      ...payload,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PROPOSAL_BATCH_FIELD_MAPPINGS_ERROR, e)
  }
}

function* fetchProposalSiteSaga(
  params: FTFetchProposalSiteAction,
): Generator<any, void, any> {
  try {
    const response: FTProposalSiteResponse = yield call(
      fetchProposalSite,
      params,
    )
    const normalized = normalize([response], [entitySchema])
    yield put({
      type: types.FETCH_PROPOSAL_SITE_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_SITE_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.CREATE_PROPOSAL_BATCH, createProposalBatchSaga),
  takeLatest(types.FETCH_PROPOSAL_BATCHES, fetchProposalBatchesSaga),
  takeLatest(
    types.FETCH_PROPOSAL_BATCH_FIELD_MAPPINGS,
    fetchProposalBatchFieldMappingsSaga,
  ),
  takeLatest(types.UPDATE_PROPOSAL_BATCHES, updateProposalBatchesSaga),
  takeLatest(
    types.UPDATE_PROPOSAL_BATCH_FIELD_MAPPINGS,
    updateProposalBatchFieldMappingsSaga,
  ),
  takeLatest(types.UPDATE_PROPOSAL_BATCH_STATUS, updateProposalBatchStatusSaga),
  takeLatest(types.FETCH_PROPOSAL_SITE, fetchProposalSiteSaga),
]
