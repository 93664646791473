import { FormikErrors } from 'formik'
import moment from 'moment'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { DropdownValues, TableDataArr, TSDropdownValues } from './staticData'
import {
  TSEnergyStarSiteMeter,
  TSEnergyStarWaterMeterResponse,
} from '../../ducks/energyStarIntegration/types'
import useOutsideAlerter from '../../hooks/useOutsideClick'
import { zIndices } from '../../utils'
import { colors } from '../../utils/themes'
import StyledCheckbox from '../Checkbox'
import { FormFieldTitleSection } from '../FormField'
import FormFieldInteger from '../FormFieldInteger'
import AddCircleOutlineIcon from '../Icons/AddCircleOutlineIcon'
import DeleteIcon from '../Icons/DeleteIcon'
import IonIcon, { IonIconStyles } from '../IonIcon'
import ListSelector, { FTItem } from '../ListSelector'

export const METER_BOILERPLATE = {
  enabled: false,
  energyStarId: '',
  resource: '',
  dataAggregation: '',
  positive: '',
  unit: '',
  dataSource: '',
  rdpMeterId: '',
  meterAutoCreation: true,
}

export interface TSESElectricMeters {
  name: string
  id: string
  rdpMeterId: string
}

export interface TSESGasMeters {
  name: string
  id: string
  rdpMeterId: string
}

const MeterConfigWrapper = styled.div`
  margin-top: 50px;
`
const FormFieldStyled = styled(FormFieldInteger)`
  margin: 0px;
  margin-bottom: 0px !important;
  ${FormFieldTitleSection} {
    margin-bottom: 0px;
  }
  input {
    ${({ isError }) => (isError ? 'border-color:red;' : null)}
  }
`
const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 15px;
  margin-bottom: 15px;
`
const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray2};
  vertical-align: middle;
`
const AddMeterDiv = styled.div`
  color: ${colors.blue15};
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`
const MeterTableSection = styled.div`
  margin-bottom: 40px;
`
const MeterTableHeader = styled.div<{ isEditMode: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 10px;
  gap: 5px;
  & > :first-child {
    flex: 1;
  }
  ${({ isEditMode }) =>
    isEditMode &&
    `
    & > :last-child {
      flex: 0.2;
    }
  `}
  & > div {
    flex: 2;
  }
  .header-title-selection {
    flex: 3;
  }
`
const TableTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray2};
`
const MeterTableValuesWrapper = styled.div`
  > :nth-child(odd) {
    background-color: #f5f5f5;
  }
`
const MeterTableValue = styled.div<{
  addPadding?: boolean
  isEditMode?: boolean
}>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
  ${({ addPadding }) => addPadding && 'padding: 5px 10px 20px;'}
  &> :first-child {
    flex: 1;
  }
  ${({ isEditMode }) =>
    isEditMode &&
    `
    & > :last-child {
      flex: 0.2;
    }
  `}

  & > div {
    flex: 2;
  }
  .list-selector-selection {
    flex: 3;
  }
  .selection-value-field {
    flex: 3;
  }
`
const ReadOnlyValue = styled.div`
  padding: 10px 0px;
`
const ActionsCellWrapperStyled = styled.div`
  position: relative;
  display: flex;
`
const ActionsIconStyled = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;

  ${IonIconStyles} {
    & > span {
      left: 8px;
      position: absolute;
      top: -7px;
    }
  }
`
const ActionsPopupStyled = styled.div<{ open?: boolean }>`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  right: 30px;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 100px;
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
`
const ActionStyled = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #c02f2f;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:last-child {
    margin-bottom: 0;
  }
`
const StyledError = styled.p`
  font-size: 10px;
  position: absolute;
  margin: 0px;
  margin-top: 2px;
  color: #c25353;
`

export interface TSMeterConfigProps {
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) => void
  values: TSEnergyStarSiteMeter[]
  errors: string | FormikErrors<TSEnergyStarSiteMeter>[] | string[] | undefined
  isEditMode: boolean
  loading: boolean
  onDeleteClick: (index: number) => void
  errorMeterId?: string
  errorMessage?: string
  waterMeterList: TSEnergyStarWaterMeterResponse[]
  electricMeters: TSESElectricMeters[]
  gasMeters: TSESGasMeters[]
}

const ActionsPopup = ({
  open,
  index,
  setActionsPopupId,
  onDeleteClick,
  wrapperRef,
}: {
  open: boolean
  index: number
  setActionsPopupId: Dispatch<SetStateAction<null | number>>
  onDeleteClick: (index: number) => void
  wrapperRef: React.MutableRefObject<null>
}) => {
  if (!open) return null
  return (
    <ActionsPopupStyled open ref={wrapperRef}>
      <ActionStyled
        onClick={() => {
          setActionsPopupId(null)
          onDeleteClick(index)
        }}
      >
        <DeleteIcon
          color='#C02F2F'
          style={{
            width: '30px',
          }}
        />
        Delete
      </ActionStyled>
    </ActionsPopupStyled>
  )
}

const MeterConfig = (props: TSMeterConfigProps) => {
  const [actionsPopupId, setActionsPopupId] = useState<null | number>(null)
  const {
    setFieldValue,
    values,
    isEditMode,
    loading,
    errors,
    onDeleteClick,
    errorMeterId,
    errorMessage,
    waterMeterList,
    electricMeters,
    gasMeters,
  } = props
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => {
    setActionsPopupId(null)
  })

  const filteredTableArr = useMemo(
    () =>
      TableDataArr.filter(
        (item) =>
          item.fieldIsSpecificTo === undefined ||
          item.fieldIsSpecificTo === (isEditMode ? 'edit' : 'read'),
      ),
    [isEditMode],
  )

  const handleKeydown = useCallback(
    (event) => {
      if (event.key === 'Escape' && actionsPopupId) {
        setActionsPopupId(null)
      }
    },
    [actionsPopupId],
  )
  useEffect(() => {
    if (actionsPopupId) {
      document.addEventListener('keydown', handleKeydown, true)
    } else {
      document.removeEventListener('keydown', handleKeydown, true)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown, true)
    }
  }, [actionsPopupId])

  const handleDataAggregationChange = async ({
    outerIndex,
  }: {
    outerIndex: number
  }) => {
    const resources = DropdownValues.resource.map(({ id }) => id)
    let areValuesToBeCleared = false
    resources.forEach((res) => {
      const firstMeterIndex = values.findIndex(
        ({ resource }) => resource === res,
      )
      if (firstMeterIndex === -1) return

      if (outerIndex === firstMeterIndex) {
        areValuesToBeCleared = true
      }
    })

    if (!areValuesToBeCleared) return

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promiseArr: any = []

    values.forEach((item, i) => {
      if (item.resource === values[outerIndex].resource) {
        promiseArr.push(
          setFieldValue(`meters.${i}.selection`, null),
          setFieldValue(`meters.${i}.dataAggregation`, null),
          setFieldValue(`meters.${i}.meterName`, null),
          setFieldValue(`meters.${i}.rdpMeterId`, null),
        )
      }
    })
    await Promise.all(promiseArr)
  }

  const handleFieldUpdate = async ({
    outerIndex,
    id,
    value,
  }: {
    outerIndex: number
    id: string
    value: string
  }) => {
    if (id === 'resource') {
      await Promise.all(
        filteredTableArr
          .filter(({ isDependentOnResource }) => isDependentOnResource)
          .map((item) =>
            setFieldValue(`meters.${outerIndex}.${item.id}`, null),
          ),
      )
    }

    if (id === 'dataAggregation') {
      await handleDataAggregationChange({
        outerIndex,
      })
    }

    if (id === 'selection' && values[outerIndex].dataAggregation === 'meter') {
      if (values[outerIndex].resource === 'water') {
        const foundMeter = waterMeterList.find(
          (waterMeter) => waterMeter.id === value,
        )
        await setFieldValue(`meters.${outerIndex}.meterName`, foundMeter?.name)
      }

      if (values[outerIndex].resource === 'electricity') {
        const foundMeter = electricMeters.find(({ name }) => name === value)
        await setFieldValue(
          `meters.${outerIndex}.rdpMeterId`,
          foundMeter?.rdpMeterId,
        )
        await setFieldValue(`meters.${outerIndex}.meterName`, foundMeter?.id)
      }
    }
    await setFieldValue(`meters.${outerIndex}.${id}`, value)
  }

  const getDataAggregationDropdownValues = ({
    outerIndex,
    dropdownValues,
  }: {
    outerIndex: number
    dropdownValues: TSDropdownValues[] | undefined
  }) => {
    const currentMeterResource = values[outerIndex].resource
    const firstResourceMeterIndex = values.findIndex(
      ({ resource }) => resource === currentMeterResource,
    )
    const isFirstResourceMeter = firstResourceMeterIndex === outerIndex

    if (!isFirstResourceMeter) {
      return dropdownValues?.filter(
        (value) => value.id === values[firstResourceMeterIndex].dataAggregation,
      )
    }

    return false
  }

  const getSelectionDropdownValues = ({
    outerIndex,
  }: {
    outerIndex: number
  }) => {
    const currentMeterResource = values[outerIndex].resource
    const allOtherSelectedValues = values.reduce<(string | null)[]>(
      (acc, cur, i) => {
        if (i !== outerIndex && cur.resource === currentMeterResource) {
          acc.push(cur.selection)
        }

        return acc
      },
      [],
    )

    if (currentMeterResource === 'water') {
      return waterMeterList.filter(
        (m) => !allOtherSelectedValues.includes(m.id),
      )
    }

    if (currentMeterResource === 'electricity') {
      return electricMeters.filter(
        (m) => !allOtherSelectedValues.includes(m.id),
      )
    }

    return []
  }

  const getDropdownItems = ({
    isDependentOnResource,
    dropdownValues,
    outerIndex,
    id,
  }: {
    isDependentOnResource: boolean | undefined
    dropdownValues: TSDropdownValues[] | undefined
    outerIndex: number
    id: string
  }) => {
    if (id === 'dataAggregation') {
      const list = getDataAggregationDropdownValues({
        outerIndex,
        dropdownValues,
      })
      if (list) return list
    }

    if (id === 'selection' && !values[outerIndex].dataAggregation) return []

    if (
      id === 'selection' &&
      values[outerIndex].dataAggregation !== 'wholeSite'
    ) {
      return getSelectionDropdownValues({
        outerIndex,
      })
    }
    if (id === 'resource') {
      return dropdownValues?.filter((item) => {
        if (item.id === 'electricity' && !electricMeters.length) return false
        if (item.id === 'water' && !waterMeterList.length) return false
        if (item.id === 'naturalGas' && !gasMeters.length) return false
        return true
      })
    }

    return isDependentOnResource ?
        dropdownValues?.filter(
          ({ resource }) => resource?.includes(values[outerIndex].resource),
        )
      : dropdownValues
  }

  const renderEditableTable = () =>
    values &&
    values.map((meterValue, outerIndex) => (
      <MeterTableValue
        isEditMode={isEditMode}
        addPadding={
          errorMeterId === values[outerIndex].energyStarId?.toString()
        }
      >
        {filteredTableArr.map(
          ({ id, dropdownValues, isDependentOnResource, type }) => {
            if (type === 'checkbox') {
              if (id === 'meterAutoCreation' && !!meterValue.id)
                return <div>Not Applicable</div>
              return (
                <div>
                  <StyledCheckbox
                    checked={!!meterValue[id]}
                    disabled={loading}
                    onChange={({ target }) => {
                      const { checked } = target as HTMLInputElement
                      if (id === 'meterAutoCreation' && checked) {
                        setFieldValue(`meters.${outerIndex}.energyStarId`, '')
                      }
                      setFieldValue(`meters.${outerIndex}.${id}`, checked)
                    }}
                  />
                </div>
              )
            }

            if (type === 'actions') {
              if (meterValue.enabled || values.length < 2) return <div />
              return (
                <ActionsCellWrapperStyled>
                  <ActionsIconStyled
                    onClick={() => {
                      setActionsPopupId(
                        actionsPopupId !== outerIndex ? outerIndex : null,
                      )
                    }}
                  >
                    <IonIcon
                      fontSize='24px'
                      iconClass='ion-android-more-vertical'
                    />
                  </ActionsIconStyled>
                  <ActionsPopup
                    open={actionsPopupId === outerIndex}
                    index={outerIndex}
                    setActionsPopupId={setActionsPopupId}
                    onDeleteClick={onDeleteClick}
                    wrapperRef={wrapperRef}
                  />
                </ActionsCellWrapperStyled>
              )
            }

            if (type === 'input') {
              return (
                <div>
                  {!values[outerIndex].meterAutoCreation && (
                    <>
                      <FormFieldStyled
                        isReadOnly={!values[outerIndex].resource || loading}
                        isError={
                          errors &&
                          errors[outerIndex] &&
                          (errors as FormikErrors<TSEnergyStarSiteMeter>[])[
                            outerIndex
                          ][id]
                        }
                        name={`meters.${outerIndex}.${id}`}
                      />
                      {errorMeterId ===
                        values[outerIndex].energyStarId?.toString() && (
                        <StyledError className='error-text'>
                          {errorMessage}
                        </StyledError>
                      )}
                    </>
                  )}
                </div>
              )
            }

            const items = getDropdownItems({
              isDependentOnResource,
              dropdownValues,
              outerIndex,
              id,
            })
            return (
              <ListSelector
                className={`list-selector-${id}`}
                edited={false}
                updateValue={({ value }) =>
                  handleFieldUpdate({
                    outerIndex,
                    id,
                    value,
                  })
                }
                items={items as FTItem[]}
                notSetLabelText='Select'
                selectedItem={items?.find(
                  ({ id: value }) => values[outerIndex][id] === value,
                )}
                disabled={
                  (id !== 'resource' && !values[outerIndex].resource) || loading
                }
                searchable={false}
                unsettable={false}
              />
            )
          },
        )}
      </MeterTableValue>
    ))

  const getSelectionValueForMeter = ({
    outerIndex,
    id,
  }: {
    outerIndex: number
    id: keyof TSEnergyStarSiteMeter
  }) => {
    if (values[outerIndex].resource === 'water') {
      return (
        <ReadOnlyValue className={`${id}-value-field`}>
          {values[outerIndex].meterName}
        </ReadOnlyValue>
      )
    }

    return (
      <ReadOnlyValue className={`${id}-value-field`}>
        {(values as TSEnergyStarSiteMeter[])[outerIndex][id]}
      </ReadOnlyValue>
    )
  }

  const formatESPMDate = (date: string | null | undefined) => {
    if (!date) return 'NA'
    return moment(date.split(' ')[0], 'YYYY-MM-DD').format('MM/DD/YYYY')
  }

  const renderReadOnly = () =>
    values.map((meterValue, outerIndex) => (
      <MeterTableValue>
        {filteredTableArr.map(({ id }, i) => {
          if (i === filteredTableArr.length - 1) {
            return <div>{formatESPMDate(values[outerIndex].espmStartDate)}</div>
          }

          if (i === 0) {
            return <StyledCheckbox checked={meterValue.enabled} disabled />
          }

          if (i === filteredTableArr.length - 2)
            return <ReadOnlyValue>{values[outerIndex][id]}</ReadOnlyValue>

          if (
            i === filteredTableArr.length - 5 &&
            values[outerIndex].dataAggregation === 'meter'
          ) {
            return getSelectionValueForMeter({
              outerIndex,
              id,
            })
          }

          return (
            <ReadOnlyValue className={`${id}-value-field`}>
              {DropdownValues[id] &&
                DropdownValues[id].find((v) => v.id === values[outerIndex][id])
                  ?.name}
            </ReadOnlyValue>
          )
        })}
      </MeterTableValue>
    ))

  if (!isEditMode && !values?.length) return null
  return (
    <MeterConfigWrapper>
      <HeaderSection>
        <HeaderTitle>Meter Information</HeaderTitle>
        {isEditMode && !loading && (
          <AddMeterDiv
            onClick={() =>
              setFieldValue('meters', [...values, METER_BOILERPLATE])
            }
          >
            <AddCircleOutlineIcon />
            Add Meter
          </AddMeterDiv>
        )}
      </HeaderSection>
      <MeterTableSection>
        <MeterTableHeader isEditMode={isEditMode}>
          {filteredTableArr.map(({ title }) => (
            <TableTitle
              key={title}
              className={`header-title-${title?.toLocaleLowerCase()}`}
            >
              {title}
            </TableTitle>
          ))}
        </MeterTableHeader>
        <MeterTableValuesWrapper>
          {isEditMode ? renderEditableTable() : renderReadOnly()}
        </MeterTableValuesWrapper>
      </MeterTableSection>
    </MeterConfigWrapper>
  )
}

export default MeterConfig
