import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'

import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTBillingSendToInvestigationModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import { defaultIssueOwnerList } from '../../pages/Billing/helpers/utils'
import { colors } from '../../utils/themes'
import Button2 from '../Button2'
import KeyboardDoubleArrowDownIcon from '../Icons/KeyboardDoubleArrowDownIcon'
import KeyboardDoubleArrowUpIcon from '../Icons/KeyboardDoubleArrowUpIcon'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import MultiSelectOptions from '../MultiSelectOptions'
import RedaptiveReactTable7, {
  ReactTableWrapperStyles,
  RedaptiveReactTableStyles,
} from '../RedaptiveReactTable7'
import TextArea, { TextAreaInnerStyled, TextAreaStyled } from '../TextArea'
import type { ComponentType, SyntheticEvent } from 'react'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
  billingSites: Array<Record<string, any>>
  isMandV: boolean
} & FTBillingSendToInvestigationModal
const TextAreaContent = css`
  &:after {
    content: '*';
    position: absolute;
    top: 10px;
    left: 82px;
    color: ${colors.red5};
    animation: ${({ textAreaError }) =>
      !textAreaError ? 'shake 0.2s ease-in-out 0s 2' : 'none'};
  }
`
const TextAreaError = css`
  & {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 5px red;
  }
  @keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.5rem;
    }
    75% {
      margin-left: -0.5rem;
    }
    100% {
      margin-left: 0rem;
    }
  }
`
const IssueOwnerSelectionError = css`
  box-shadow: 0 0 5px red;
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
  animation: shake 0.2s ease-in-out 0s 2;

  @keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.5rem;
    }
    75% {
      margin-left: -0.5rem;
    }
    100% {
      margin-left: 0rem;
    }
  }
`
const ModalBody: ComponentType<{
  isContentVisible: boolean
  textAreaError: boolean
  issueOwnerSelectionError: boolean
}> = styled.div`
  padding: 0 20px;

  ${TextAreaStyled} {
    ${({ isContentVisible }) => isContentVisible && TextAreaContent}
  }
  ${TextAreaInnerStyled} {
    max-width: 100%;
    resize: none;
    ${({ textAreaError }) => !textAreaError && TextAreaError}
  }
  .modal-issue-owner-dropdown {
    ${({ issueOwnerSelectionError }) =>
      !issueOwnerSelectionError && IssueOwnerSelectionError}
  }
`
const tableMarginBottom = 130
const itemsToggleHeight = 43
const TableWrapperStyled = styled.div`
  font-size: 12px;

  ${RedaptiveReactTableStyles} {
    margin: 0 20px;
  }
  ${ReactTableWrapperStyles} {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    max-height: 195px;
  }
`
const ItemsToggleStyled: ComponentType<{
  itemsVisible: boolean
}> = styled.div`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    #ffffff 100%
  );
  bottom: ${({ itemsVisible }) =>
    itemsVisible ?
      `${tableMarginBottom - itemsToggleHeight}px`
    : `${tableMarginBottom}px`};
  color: ${colors.blue2};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${itemsToggleHeight}px;
  justify-content: center;
  letter-spacing: 0.02em;
  width: 100%;
`
const ItemsToggleIconStyled = styled.div`
  margin-right: 6px;
  position: relative;
  top: 2px;
`

const billingSendToInvestigationModal = ({
  actions,
  billIds,
  billingSites,
  isMandV,
  reassigned,
  issueOwners,
  loading,
  setSendToInvestigationSubmitted,
}: FTProps) => {
  const isReassigned = reassigned
  const [comment, setComment] = useState('')
  const [contentVisible, setContentVisible] = useState(true)
  const [isTextAreaValid, setIsTextAreaValid] = useState(true)
  const [isIssueOwnerSelectionValid, setIsIssueOwnerSelectionValid] =
    useState(true)
  const [issueOwnerOptions, setIssueOwnerOptions] = useState(
    defaultIssueOwnerList,
  )
  const [isToggleItemsActive, setIsToggleItemsActive] = useState(true)
  const columns = [
    {
      accessor: 'opportunityId',
      Header: 'Opportunity ID',
    },
    {
      accessor: 'name',
      Header: 'Opportunity Name',
    },
  ]
  const tableData = useMemo(
    () => (isToggleItemsActive ? billingSites : billingSites.slice(0, 3)),
    [isToggleItemsActive],
  )
  const handleItemsToggleClick = useCallback(() => {
    setIsToggleItemsActive(!isToggleItemsActive)
  }, [isToggleItemsActive])
  useEffect(() => {
    if (comment) {
      setContentVisible(false)
    } else {
      setContentVisible(true)
    }
  }, [comment, contentVisible])
  useEffect(() => {
    const updatedIssueOwnerOptions = defaultIssueOwnerList.map((a) => ({
      ...a,
    }))
    issueOwnerOptions.forEach((item, index) => {
      // $FlowFixMe
      if (issueOwners?.includes(item.name)) {
        updatedIssueOwnerOptions[index].selected = true
      }
    })
    setIssueOwnerOptions([...updatedIssueOwnerOptions])
  }, [issueOwners])

  const handleFlagForInvestigationCommentBlur = (event: SyntheticEvent) => {
    const {
      target: { value },
    } = event
    setComment(value)

    if (value) {
      setIsTextAreaValid(true)
    }
  }

  const getSelectedIssueOwners = () =>
    issueOwnerOptions.filter((item) => item.selected).map((item) => item.name)

  const resetModalErrorStates = () => {
    setIsIssueOwnerSelectionValid(true)
    setIsTextAreaValid(true)
  }

  const handleConfirm = () => {
    resetModalErrorStates()
    const selectedIssueOwners = getSelectedIssueOwners()

    if (!selectedIssueOwners.length) {
      setIsIssueOwnerSelectionValid(false)
      return
    }

    if (!comment.length) {
      setIsTextAreaValid(false)
      return
    }

    if (comment.length && selectedIssueOwners.length) {
      actions.sendToInvestigation({
        comment,
        billIds,
        issueOwners: selectedIssueOwners,
      })
      setSendToInvestigationSubmitted(true)
    }
  }

  const handleIssueOwnerOptionSelect = (index) => {
    // to remove validation error on option selection
    if (!issueOwnerOptions[index]?.selected && !isIssueOwnerSelectionValid) {
      setIsIssueOwnerSelectionValid(true)
    }

    issueOwnerOptions[index].selected = !issueOwnerOptions[index].selected
    setIssueOwnerOptions([...issueOwnerOptions])
  }

  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled
        style={{
          width: '510px',
        }}
      >
        {!isReassigned && (
          <ModalConfirmTitleStyled
            style={{
              fontWeight: 700,
              letterSpacing: '0.02em',
            }}
          >
            {isMandV ?
              `M&V Groups must be selected as a whole. Would you like to continue
            with moving all projects in the M&V Group?`
            : 'Are you sure you want to Flag this Opportunity for Investigation?'
            }
          </ModalConfirmTitleStyled>
        )}
        {isReassigned && (
          <ModalConfirmTitleStyled
            style={{
              fontWeight: 700,
              letterSpacing: '0.02em',
            }}
          >
            {isMandV ?
              `M&V Groups must be selected as a whole. Would you like to continue
            with reassigning Issue Owner for all projects in the M&V Group?`
            : 'Are you sure you want to Reassign the issue Owner?.'}
          </ModalConfirmTitleStyled>
        )}
        <ModalConfirmBodyStyled>
          {!!billingSites && billingSites.length > 1 && (
            <TableWrapperStyled>
              <RedaptiveReactTable7
                columns={columns}
                data={tableData}
                globalFilterable={false}
              />
              {billingSites.length > 2 && (
                <ItemsToggleStyled
                  itemsVisible={isToggleItemsActive}
                  onClick={handleItemsToggleClick}
                >
                  {isToggleItemsActive ?
                    <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowUpIcon />
                      </ItemsToggleIconStyled>
                      Hide
                    </>
                  : <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowDownIcon />
                      </ItemsToggleIconStyled>
                      Show All
                    </>
                  }
                </ItemsToggleStyled>
              )}
            </TableWrapperStyled>
          )}
          <ModalBody
            isContentVisible={contentVisible}
            textAreaError={isTextAreaValid}
            issueOwnerSelectionError={isIssueOwnerSelectionValid}
          >
            <MultiSelectOptions
              optionsList={issueOwnerOptions}
              onSelectOption={handleIssueOwnerOptionSelect}
            />
            <TextArea
              onChange={handleFlagForInvestigationCommentBlur}
              placeholder='Comments'
            />
          </ModalBody>
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2 onClick={actions.hideModal} type='transparent'>
            Cancel
          </Button2>
          <Button2 onClick={handleConfirm} loading={loading} type='primary'>
            Confirm
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const {
    sendToInvestigationMeta: { loading },
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(billingSendToInvestigationModal)
