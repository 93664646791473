import { PureComponent } from 'react'
import { connect } from 'react-redux'

import DeprecatedModalStyles from './DeprecatedModalStyles'
import Modal from './Modal'
import {
  CLOSE,
  MODAL_ERROR_ACTION,
  MODAL_ERROR_BODY,
  MODAL_ERROR_TITLE,
} from '../constants/strings'
import { actions as modalActions } from '../ducks/modal'

type FTProps = {
  dispatch: (...args: Array<any>) => any
  customizedErrorBody?: string
  noErrorAction?: boolean
}
class ServerErrorModal extends PureComponent<FTProps> {
  onAccept = () => {
    window.location.reload()
  }

  onClose = () => {
    this.props.dispatch(modalActions.hideModal())
  }

  render() {
    const { customizedErrorBody = '', noErrorAction = true } = this.props
    return (
      <DeprecatedModalStyles>
        <Modal
          modalWidth='418px'
          title={MODAL_ERROR_TITLE}
          content={
            customizedErrorBody ? [customizedErrorBody] : MODAL_ERROR_BODY
          }
          acceptText={noErrorAction ? MODAL_ERROR_ACTION : CLOSE}
          onAccept={noErrorAction ? this.onAccept : this.onClose}
          onClose={this.onClose}
        />
      </DeprecatedModalStyles>
    )
  }
}
export default connect(() => ({}))(ServerErrorModal)
