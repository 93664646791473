import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import {
  CTOrientationCellStyles,
  ExpandedMeterChannelLabelStyles,
  ExpandedMeterChannelStyles,
  PlainCellStyles,
  ReportProblemIconStyled,
  TableStyles,
} from './style'
import { FTCell, FTProps, PhaseType, TypeCell } from './types'
import type { FTCircuit } from '../../ducks/circuits'
import { phaseLabels } from '../../ducks/meters/config'
import type { FTContractSummary } from '../../types'
import ChannelTagsPlotView from '../ChannelTagsPlotView'
import {
  MeteredUpstreamHelpContentStyled,
  MeteredUpstreamInfoStyled,
} from '../CircuitListForm/styles'
import CloseButtonTableSubComponent from '../CloseButtonTableSubComponent'
import FieldWithPending, { FTField } from '../FieldWithPending'
import HelpIcon from '../Icons/HelpIcon'
import RedaptiveReactTable, { exactFilter } from '../RedaptiveReactTable'
import {
  CellWithToolTip,
  getBillableContractLabel,
  MeteredUpstreamCell,
} from './components/Cells'

const CircuitTable = (props: FTProps) => {
  const { circuitListEntity, meter } = props
  const { items: rows } = circuitListEntity
  const { isBigBang, siteTimezone, source } = meter
  const isRedaptive = source === 'REDAPTIVE'
  let circuitConfigurationsById: any = {}

  if (meter.hybridConfig && meter.hybridConfig.circuitConfigurationsById) {
    ;({
      hybridConfig: { circuitConfigurationsById },
    } = meter)
  }

  const getPendingPhaseField = ({
    id,
    phase,
  }: PhaseType): Record<string, any> =>
    (circuitConfigurationsById[id] && circuitConfigurationsById[id].phase) ||
    phase ||
    {}

  const getCurrentPhase = ({ id, phase }: PhaseType) => {
    const pendingField = getPendingPhaseField({
      id,
      phase,
    })
    return (
      (pendingField.current && phaseLabels.get(pendingField.current)) || 'A'
    )
  }

  const getPhaseField = ({ id, phase }: PhaseType) => {
    const pendingField = getPendingPhaseField({
      id,
      phase,
    })
    const pendingFieldFormatted: FTField = {
      current:
        (pendingField.current && phaseLabels.get(pendingField.current)) || 'A',
      pending:
        (pendingField.pending && phaseLabels.get(pendingField.pending)) ||
        undefined,
    }
    return <FieldWithPending field={pendingFieldFormatted} />
  }

  const getMeteredUpstreamHelpContent = () => (
    <MeteredUpstreamHelpContentStyled>
      <div>
        Check this box to exclude the load from the total consumption calculated
        at the site.{' '}
      </div>
      <div>
        For use when the load is accounted for by another metered circuit.
      </div>
    </MeteredUpstreamHelpContentStyled>
  )

  const nebulaColumns =
    isRedaptive && !isBigBang ?
      [
        {
          accessor: 'flippedCTStatusCurrent',
          Cell: ({
            isExpanded,
            row: {
              _original: { flippedCTLabel },
            },
            value,
          }: FTCell) => {
            if (isExpanded) {
              return ''
            }

            const labelWarning = ['NOT_LABELED', 'NEEDS_REVIEW'].includes(value)
            return (
              <CTOrientationCellStyles warning={labelWarning}>
                {labelWarning && <ReportProblemIconStyled />}
                {flippedCTLabel}
              </CTOrientationCellStyles>
            )
          },
          Header: 'CT Orientation',
          minWidth: 170,
        },
      ]
    : []
  const columns = [
    {
      accessor: 'meterChannel',
      Cell: ({ value, isExpanded }: { value: string; isExpanded: boolean }) =>
        isExpanded ?
          <ExpandedMeterChannelStyles>
            <ExpandedMeterChannelLabelStyles>
              {'Meter Channel: '}
            </ExpandedMeterChannelLabelStyles>
            {value}
          </ExpandedMeterChannelStyles>
        : <PlainCellStyles>{value}</PlainCellStyles>,
      Header: 'Meter Channel',
      minWidth: 80,
    },
    {
      accessor: 'panelSummary.name',
      filterMethod: exactFilter,
      Cell: ({ isExpanded, value = '' }: TypeCell) => (isExpanded ? '' : value),
      Header: 'Panel',
      id: 'panelId',
    },
    {
      accessor: 'breakerNumber',
      Cell: ({ isExpanded, value = '' }: TypeCell) => (isExpanded ? '' : value),
      filterMethod: exactFilter,
      Header: 'Breaker Number',
      minWidth: 74,
    },
    {
      accessor: 'phase',
      Cell: ({
        isExpanded,
        row,
      }: {
        isExpanded: boolean
        row: {
          phase: string
          _original: FTCircuit
        }
      }) => {
        if (isExpanded) {
          return ''
        }

        const {
          phase,
          _original: { id },
        } = row
        return getPhaseField({
          id,
          phase,
        })
      },
      Header: 'Phase',
      minWidth: 60,
      show: isRedaptive,
    },
    {
      accessor: 'phaseGroupSummary.name',
      Cell: CellWithToolTip,
      Header: 'Phase Group',
      show: isRedaptive,
    },
    {
      accessor: 'ctTypeAmps',
      Cell: ({
        isExpanded,
        row,
      }: {
        isExpanded: boolean
        row: {
          ctTypeAmps: string
          _original: FTCircuit
        }
      }) => {
        if (isExpanded) {
          return ''
        }

        const pendingField =
          circuitConfigurationsById[row._original.id] ?
            circuitConfigurationsById[row._original.id].ctTypeAmps
          : row.ctTypeAmps
        return <FieldWithPending field={pendingField} />
      },
      Header: 'CT Type',
      minWidth: 120,
    },
    {
      accessor: 'description',
      Cell: CellWithToolTip,
      Header: (
        <>
          Circuit Description
          <br />
          (from Panel Label)
        </>
      ),
      minWidth: 162,
    },
    {
      accessor: 'panelFeedName',
      Cell: CellWithToolTip,
      Header: <>Panel Feed</>,
      minWidth: 162,
    },
    {
      accessor: 'meteredUpstream',
      Cell: MeteredUpstreamCell,
      Header: (
        <>
          Exclude from Site-Level Total Consumption
          <br />
          <MeteredUpstreamInfoStyled>
            (Metered Upstream)
            <Tippy content={getMeteredUpstreamHelpContent()} delay={500}>
              <HelpIcon />
            </Tippy>
          </MeteredUpstreamInfoStyled>
        </>
      ),
      minWidth: 162,
    },
    {
      accessor: 'buildingSystemSummary.name',
      Cell: CellWithToolTip,
      Header: (
        <>
          Building System
          <br />
          (In Energy Dashboard)
        </>
      ),
    },
    {
      accessor: 'equipmentSummary.name',
      Cell: CellWithToolTip,
      Header: (
        <>
          Equipment Name
          <br />
          (In Energy Dashboard)
        </>
      ),
      minWidth: 162,
    },
    {
      accessor: 'buildingArea',
      Cell: CellWithToolTip,
      Header: 'Building Area',
    },
    {
      accessor: 'contractSummary',
      Cell: ({
        isExpanded,
        row,
      }: {
        isExpanded: boolean
        row: {
          contractSummary: FTContractSummary
        }
      }) => {
        if (isExpanded) {
          return ''
        }

        // eslint-disable-next-line react/prop-types
        const { contractSummary } = row
        return getBillableContractLabel(contractSummary)
      },
      Header: 'Billable Contract',
      minWidth: 180,
    },
    ...nebulaColumns,
    {
      expander: true,
      // eslint-disable-next-line react/prop-types
      Expander: ({ isExpanded }: TypeCell) =>
        isExpanded ? <CloseButtonTableSubComponent /> : <span>Plot View</span>,
      Header: 'Plot View',
      resizable: true,
      style: {
        color: '#337ab7',
        cursor: 'pointer',
      },
      width: 100,
    },
  ]

  const getCTTypeAmps = (circuit: FTCircuit) => {
    const { ctTypeAmps = '' } = circuitConfigurationsById[circuit.id] || {}
    return <FieldWithPending field={ctTypeAmps} />
  }

  return (
    <TableStyles>
      <RedaptiveReactTable
        columns={columns}
        data={rows}
        SubComponent={({
          original: circuit,
        }: {
          // eslint-disable-next-line react/no-unused-prop-types
          original: FTCircuit
        }) => (
          <ChannelTagsPlotView
            data={{
              contractId: getBillableContractLabel(circuit.contractSummary),
              breakerNumber: circuit.breakerNumber,
              buildingArea: circuit.buildingArea,
              buildingSystem:
                (circuit.buildingSystemSummary &&
                  circuit.buildingSystemSummary.name) ||
                '',
              circuitDescription: circuit.description,
              circuitId: circuit.id,
              ctTypeAmps: getCTTypeAmps(circuit),
              equipment:
                (circuit.equipmentSummary && circuit.equipmentSummary.name) ||
                '',
              flippedCTLabel: circuit.flippedCTLabel,
              isRedaptive,
              isBigBang,
              meterChannel:
                (isRedaptive && circuit.meterChannel) || circuit.name,
              meterId: meter.id,
              meterName: meter.name,
              meterStartDate: 'Dec 1, 2019',
              panelDescription:
                (circuit.panelSummary && circuit.panelSummary.description) ||
                '',
              panelName:
                (circuit.panelSummary && circuit.panelSummary.name) || '',
              phase: getCurrentPhase({
                id: circuit.id,
                phase: circuit.phase,
              }),
              phaseField: getPhaseField({
                id: circuit.id,
                phase: circuit.phase,
              }),
              phaseGroup:
                (circuit.phaseGroupSummary && circuit.phaseGroupSummary.name) ||
                '',
              siteTimezone,
            }}
          />
        )}
      />
    </TableStyles>
  )
}

export default CircuitTable
