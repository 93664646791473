import styled from 'styled-components'

const StyledDeletePanelModalBody = styled.p`
  padding-bottom: 16px;
  text-align: center;
`
export const StyledModalBody = styled.div`
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 20px;
  text-align: center;
`
export const UNSAVED_CHANGES_MESSAGE =
  'This page has unsaved changes. Are you sure you want to exit?'
export const UNSAVED_PANEL_MESSAGE =
  'The panel has not been created yet. Are you sure you want to exit?'
export const renderResetCircuitBreakersConfirm = () => (
  <StyledModalBody>
    Are you sure you want to reset circuit breakers?
    <br />
  </StyledModalBody>
)

export const renderResetSwitchesConfirm = () => (
  <StyledModalBody>
    Are you sure you want to reset switches?
    <br />
  </StyledModalBody>
)
export const renderRemoveCircuitConfirm = () => (
  <StyledModalBody>
    Are you sure you want to remove this circuit breaker?
    <br />
  </StyledModalBody>
)

export const renderRemoveSwitchConfirm = () => (
  <StyledModalBody>
    Are you sure you want to remove this switch?
    <br />
  </StyledModalBody>
)

export const renderDeletePanelConfirm = (panelName) => (
  <StyledDeletePanelModalBody>
    Are you sure you want to delete panel
    <br />
    <b>{panelName}</b>?
  </StyledDeletePanelModalBody>
)
export const renderCreatePanelConfirm = () => (
  <StyledModalBody>
    Are you sure you want to create the panel with these circuits?
    <br />
  </StyledModalBody>
)

export const renderCreateSwitchBoardConfirm = () => (
  <StyledModalBody>
    Are you sure you want to create the panel with these switches?
    <br />
  </StyledModalBody>
)

export const renderUpdateCircuitsConfirm = () => (
  <StyledModalBody>
    Are you sure you want to update the circuits?
    <br />
  </StyledModalBody>
)

export const renderUpdateSwitchesConfirm = () => (
  <StyledModalBody>
    Are you sure you want to update the switches?
    <br />
  </StyledModalBody>
)
