import styled from 'styled-components'

import CloseButton from './CloseButton'

const CloseButtonTableSubComponent = styled(CloseButton)`
  display: flex;
  justify-content: center;
  line-height: 1;
`
export default CloseButtonTableSubComponent
