import moment from 'moment'
import React, { useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { SpinnerStyles } from './CustomerListingPage'
import {
  contryCodeByCountryName,
  defaultFieldsMapping,
  defaultGlobalsMapping,
} from './utils'
import Breadcrumbs2 from '../../../components/Breadcrumbs2'
import ArrowBackIcon from '../../../components/Icons/ArrowBackIcon'
import ScenarioForm from '../../../components/ProposalsEngine/ScenarioForm'
import Spinner from '../../../components/Spinner'
import type { FTMessageInput } from '../../../ducks/messages'
import { actions as messagesActions } from '../../../ducks/messages'
import { actions as modalActions } from '../../../ducks/modal'
import type {
  FTFetchProposalCustomerGlobalInputsAction,
  FTProposalCustomerGlobalInputs,
} from '../../../ducks/proposals/customerGlobalInputs'
import {
  actions as customerGlobalInputsActions,
  selectProposalCustomerGlobalInputsEntity,
} from '../../../ducks/proposals/customerGlobalInputs'
import type {
  FTFetchProposalCustomerMetricsAction,
  FTProposalCustomerMetrics,
} from '../../../ducks/proposals/customerMetrics'
import {
  actions as customerMetricsActions,
  selectProposalCustomerMetricsEntity,
} from '../../../ducks/proposals/customerMetrics'
import type {
  FTFetchProposalCustomerSummariesAction,
  FTProposalCustomerSummary,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as customerSummariesActions,
  selectProposalCustomerSummariesEntity,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as emissionActions,
  FTFetchEmissionRateAction,
  selectEmissionRateEntity,
} from '../../../ducks/proposals/emissionRate'
import type { FTImportedScenarioPayload } from '../../../ducks/proposals/importedScenario'
import {
  actions as importedScenarioActions,
  selectProposalImportedScenario,
} from '../../../ducks/proposals/importedScenario'
import type {
  FTFetchProposalOpportunitySummariesAction,
  FTProposalOpportunitySummary,
} from '../../../ducks/proposals/opportunitySummaries'
import {
  actions as opportunitySummariesActions,
  selectProposalOpportunitySummariesEntity,
} from '../../../ducks/proposals/opportunitySummaries'
import { actions as recActions } from '../../../ducks/proposals/recData'
import type {
  FTCreateProposalScenarioAction,
  FTFetchProposalScenariosAction,
  FTFetchSalesTaxRateAction,
  FTProposalScenario,
} from '../../../ducks/proposals/scenarios'
import {
  actions as scenarioActions,
  defaultScenario,
  selectProposalScenarioEntity,
} from '../../../ducks/proposals/scenarios'
import type {
  FTFetchProposalSiteAction,
  FTProposalSite,
} from '../../../ducks/proposals/sites'
import {
  actions as siteActions,
  selectProposalSiteEntity,
} from '../../../ducks/proposals/sites'
import {
  actions as utilityInflationRateActions,
  selectUtilityInflationRateDataEntity,
} from '../../../ducks/proposals/utilityInflationRateData'
import countryNameLookup from '../../../ducks/sites/countryNameLookup'
import type { FTRouterLocation, FTWithRouter } from '../../../types'
import { valueSetOrDefault, zIndices } from '../../../utils'
import { colors } from '../../../utils/themes'
import {
  getUtilityInflationRate,
  hasUtilityInflationRateData,
  UTILITY_INFLATION_RATE_DATA,
  UTILITY_INFLATION_RATE_DATE,
  YYYY_MM_DD,
} from '../../../utils/utilityInflationRateData'
import FileContext from '../../FileContext'

const AddScenarioPageStyled = styled.div`
  font-family: 'Public Sans', sans-serif;
  margin-top: 36px;
  padding-bottom: 80px;

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }
`
type FTProps = {
  actions: {
    createProposalScenario: (params: FTCreateProposalScenarioAction) => null
    fetchProposalCustomerSummaries: (
      params: FTFetchProposalCustomerSummariesAction | null | undefined,
    ) => null
    fetchProposalCustomerGlobalInputs: (
      params: FTFetchProposalCustomerGlobalInputsAction | null | undefined,
    ) => null
    fetchProposalOpportunitySummaries: (
      params: FTFetchProposalOpportunitySummariesAction,
    ) => null
    fetchProposalScenarios: (params: FTFetchProposalScenariosAction) => null
    fetchSalesTaxRate: (props: FTFetchSalesTaxRateAction) => null
    fetchProposalSite: (props: FTFetchProposalSiteAction) => null
    hideMessage: (value: string) => null
    showMessage: (props: FTMessageInput) => null
    saveImportedScenarioDetails: (props: FTImportedScenarioPayload) => null
    fetchProposalCustomerMetrics: (
      params: FTFetchProposalCustomerMetricsAction,
    ) => null
    fetchEmissionRate: (
      params: FTFetchEmissionRateAction | null | undefined,
    ) => null
    fetchRECData: () => null
    fetchUtilityInflationRateData: () => null
  }
  customerMetrics: FTProposalCustomerMetrics | null | undefined
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  customerSummariesById: Record<string, FTProposalCustomerSummary>
  // customerSummariesLoading: boolean,
  opportunitySummaries: Array<FTProposalOpportunitySummary>
  scenarios: Array<FTProposalScenario>
  scenariosError: string
  scenariosLoading: boolean
  salesTaxFromAvalara: number
  sites: Array<FTProposalSite>
} & FTWithRouter &
  FTRouterLocation
const BreadcrumbsStyles = styled.div`
  margin-bottom: 24px;
`
const FixedNavigationStyled = styled.div`
  position: sticky;
  top: 86px;
  z-index: ${zIndices.ScenarioFormStickyHeader};
`
const TitleWrapperStyles = styled.div`
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  display: flex;
  height: 60px;
  line-height: 1;
  padding: 0 32px;
  position: relative;

  ${ArrowBackIcon} {
    cursor: pointer;
    font-size: 18px;
  }
`
const TitleStyles = styled.span`
  display: inline-block;
  padding-left: 6px;
  font-size: 18px;
  font-weight: 300;
`
export function checkEmissionPresent(emission) {
  return Boolean(emission?.country || emission?.postalCode)
}
const AddScenarioPage = (props: FTProps) => {
  const {
    actions,
    customerGlobalInputs,
    customerSummariesById,
    history,
    location,
    match: {
      params: { salesforceCustomerId, opportunityId },
    },
    opportunitySummaries,
    scenarios,
    scenariosError,
    scenariosLoading,
    salesTaxFromAvalara,
    sites,
    importedScenarioData,
    customerMetrics,
    emission,
    utilityInflationRateData,
    utilityInflationRateMeta,
    scenariosErrorType,
  } = props
  const customerSummary = customerSummariesById[salesforceCustomerId] || {}
  const { name: customerName = '' } = customerSummary || {}
  const opportunitySummary = React.useMemo(
    () =>
      opportunitySummaries.find(
        (opportunity) => opportunity.externalId === opportunityId,
      ),
    [opportunitySummaries, opportunityId],
  )
  const { currencyCode = 'USD' } = opportunitySummary || {}
  const { redaptiveOpportunityId } = opportunitySummary || {}
  const { shippingCountry } = sites[0] || {}
  const site = sites[0]
  const baseUrl = React.useMemo(
    () =>
      `/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${opportunityId}`,
    [opportunityId, salesforceCustomerId],
  )
  const isMountedProposalSite = useRef(false)
  const isMountedEmissionRate = useRef(false)
  const navigateBack = React.useCallback(() => history.push(baseUrl), [])
  const [submitted, setSubmitted] = React.useState(false)
  const [retryCount, setRetryCount] = React.useState(0)
  const [formValues, setFormValues] = React.useState({})

  React.useEffect(
    (values: FTCreateProposalScenarioAction) => {
      if (submitted && !scenariosLoading) {
        setSubmitted(false)
        if (retryCount <= 2) {
          setRetryCount(retryCount + 1)
          /* when we get network error, the scenario is creted but we getting netwrok error and didn't show any message
           thats why we retry 2 times for the create scenario */
          if (
            scenariosError !== '304' &&
            scenariosErrorType === 'CREATE_PROPOSAL_SCENARIO_ERROR'
          ) {
            const updatedValues =
              isImportedScenario ?
                {
                  ...formValues,
                  file: vendorIngestedFile,
                  vendorProposalScenarioId:
                    importedScenarioData?.vendorProposalScenarioId,
                }
              : formValues
            actions.createProposalScenario(updatedValues)
            setSubmitted(true)
          }
          // when we get any other error, then show error message
          else if (scenariosError && scenariosError !== '304') {
            actions.showMessage({
              messageId: 'scenarioCreateError',
              title: scenariosError,
              type: 'error',
            })
            setTimeout(() => actions.hideMessage('scenarioCreateError'), 8000)
          }
          // when we get empty error or 304 status, we show success message and navigate back
          // 304 status is returned when we retry to create a scenario with same values
          else if (
            scenariosError === '' ||
            scenariosError.toString() === '304'
          ) {
            actions.showMessage({
              messageId: 'scenarioAdded',
              title: 'A new scenario has been successfully created.',
              type: 'success',
            })
            setTimeout(() => actions.hideMessage('scenarioAdded'), 8000)
            navigateBack()
          }
        }
      }
    },
    [scenariosError, scenariosLoading, submitted],
  )

  const { vendorIngestedFile, setVendorIngestedFile } = useContext(FileContext)

  const clearScenarioDetails = () => {
    setVendorIngestedFile(null)
    actions.saveImportedScenarioDetails({})
  }

  React.useEffect(
    () => () => {
      // When we move away from the page, this hook gets triggered.
      clearScenarioDetails()
    },
    [],
  )

  useEffect(() => {
    if (!utilityInflationRateMeta.loading && utilityInflationRateData.length) {
      localStorage.setItem(
        UTILITY_INFLATION_RATE_DATA,
        JSON.stringify(utilityInflationRateData),
      )
      localStorage.setItem(
        UTILITY_INFLATION_RATE_DATE,
        moment().format(YYYY_MM_DD),
      )
    }
  }, [utilityInflationRateMeta, utilityInflationRateData])

  React.useEffect(() => {
    actions.fetchSalesTaxRate({
      opportunityId,
    })
    actions.fetchProposalCustomerMetrics({
      salesforceCustomerId,
    })

    if (!Object.keys(customerSummariesById).length) {
      actions.fetchProposalCustomerSummaries()
    }

    actions.fetchProposalCustomerGlobalInputs({
      salesforceCustomerId,
    })
    actions.fetchProposalOpportunitySummaries({
      salesforceCustomerId,
      stageNames: ['SCOPING', 'INSTALLING', 'BILLING_AND_MONITORING'],
    })
    actions.fetchProposalScenarios({
      opportunityId,
    })
    if (!hasUtilityInflationRateData()) {
      actions.fetchUtilityInflationRateData()
    }
  }, [])

  React.useEffect(() => {
    if (opportunitySummary && isMountedProposalSite.current) {
      actions.fetchProposalSite({
        id: opportunitySummary.salesforceSiteId,
      })
    } else {
      isMountedProposalSite.current = true
    }
  }, [opportunitySummary])
  React.useEffect(() => {
    if (site && isMountedEmissionRate.current) {
      actions.fetchEmissionRate({
        countryCode: contryCodeByCountryName(
          countryNameLookup,
          site.shippingCountry,
        ),
        postalCode: site.shippingPostalCode,
        latitude: site.latitude,
        longitude: site.longitude,
        state: site.shippingStateCode,
      })
    } else {
      isMountedEmissionRate.current = true
    }
  }, [site])
  const breadcrumbs = React.useMemo(
    () => [
      {
        href: '/proposal-operations/proposals-engine',
        text: 'Proposal Engine',
      },
      {
        href: '/proposal-operations/proposals-engine/',
        text: 'Customers',
      },
      {
        href: `/proposal-operations/proposals-engine/${salesforceCustomerId}`,
        text: customerName,
      },
      {
        href: `/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${opportunityId}`,
        text: `Scenario Analysis - ${redaptiveOpportunityId}`,
      },
      {
        href: `/proposal-operations/proposals-engine/${salesforceCustomerId}/${opportunityId}/add-scenario`,
        text: 'Add Scenario',
      },
    ],
    [
      salesforceCustomerId,
      customerName,
      opportunityId,
      location,
      redaptiveOpportunityId,
    ],
  )
  const isImportedScenario = Object.keys(importedScenarioData || {}).length > 0
  const handleSubmit = React.useCallback(
    (values: FTCreateProposalScenarioAction) => {
      const updatedValues =
        isImportedScenario ?
          {
            ...values,
            file: vendorIngestedFile,
            vendorProposalScenarioId:
              importedScenarioData?.vendorProposalScenarioId,
          }
        : values
      setFormValues(values)
      actions.createProposalScenario(updatedValues)
      setSubmitted(true)
    },
    [],
  )
  const { state: { isClone } = {} } = location
  const { state: { cloneScenario } = {} } = location

  const getCapexMarginSeekDefault = () => {
    if (customerGlobalInputs.contractType) {
      if (customerGlobalInputs.contractType === 'EAAS')
        return defaultFieldsMapping.capexMarginSeekEaas
      return defaultFieldsMapping.capexMarginSeek
    }

    if (defaultGlobalsMapping.contractType === 'EAAS')
      return defaultFieldsMapping.capexMarginSeekEaas
    return defaultFieldsMapping.capexMarginSeek
  }

  const newScenario =
    isClone ? cloneScenario : (
      {
        ...Object.keys(defaultScenario).reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: valueSetOrDefault(
              customerGlobalInputs[cur],
              defaultScenario[cur],
            ),
          }),
          {},
        ),
        dealType: valueSetOrDefault(
          customerGlobalInputs.contractType,
          defaultGlobalsMapping.contractType,
        ),
        contingencyInPercentage: valueSetOrDefault(
          customerGlobalInputs.contingency,
          defaultGlobalsMapping.contingency,
        ),
        costPerMeter:
          currencyCode === 'USD' ?
            valueSetOrDefault(
              customerGlobalInputs.measurementAndVerificationCostPerMeter,
              defaultGlobalsMapping.measurementAndVerificationCostPerMeter,
            )
          : '',
        contractTermMonths: valueSetOrDefault(
          customerGlobalInputs.term,
          defaultGlobalsMapping.term,
        ),
        transferRate: valueSetOrDefault(
          customerGlobalInputs.annualTransferRate,
        ),
        operationsAndMaintenance: valueSetOrDefault(
          customerGlobalInputs.operationsAndMaintenanceRate,
          defaultGlobalsMapping.operationsAndMaintenanceRate,
        ),
        partnerFeeInPercentage: valueSetOrDefault(
          customerGlobalInputs.projectManagementLaborAndFee,
          defaultGlobalsMapping.projectManagementLaborAndFee,
        ),
        referralFeeInPercentage: valueSetOrDefault(
          customerGlobalInputs.referralFeeInPercentage,
          defaultGlobalsMapping.referralFeeInPercentage,
        ),
        insuranceForEveryHundredDollarsOfProjectCost: valueSetOrDefault(
          customerGlobalInputs.annualInsurancePremium,
          defaultGlobalsMapping.annualInsurancePremium,
        ),
        measurementAndVerificationCostEstimateInPercentage: valueSetOrDefault(
          customerGlobalInputs.measurementAndVerificationCostAsPerProjectCostsInPercentage,
          defaultGlobalsMapping.measurementAndVerificationCostAsPerProjectCostsInPercentage,
        ),
        energyRetainedSavingsInPercentage: valueSetOrDefault(
          customerGlobalInputs.energyRetainedSavings,
          defaultGlobalsMapping.energyRetainedSavings,
        ),
        maintenanceRetainedSavingsInPercentage: valueSetOrDefault(
          customerGlobalInputs.maintenanceRetainedSavings,
          defaultGlobalsMapping.maintenanceRetainedSavings,
        ),
        maintenanceObligation: valueSetOrDefault(
          customerGlobalInputs.maintenanceObligation,
          defaultGlobalsMapping.maintenanceObligation,
        ),
        estimatedSalesTaxInPercentage:
          shippingCountry === 'United States' ? salesTaxFromAvalara : 0,
        ...defaultFieldsMapping,
        ...(customerGlobalInputs?.id ?
          {}
        : {
            ...defaultGlobalsMapping,
            transferRate: customerMetrics?.[0]?.finalTransferRate,
          }),
        capexMarginSeek: getCapexMarginSeekDefault(),
        utilityInflationRate: getUtilityInflationRate(
          site?.shippingStateCode ?? '',
        ),
      }
    )

  const getImportedScenarioValues = () => {
    const importedValues = {}
    Object.entries(importedScenarioData).forEach(([key, value]) => {
      if (value || value === 0) {
        importedValues[key] = value
      }
    })
    return importedValues
  }

  const updatedNewScenario = {
    ...newScenario,
    emissionRate: emission?.egridRate ? emission.egridRate : 0,
    ...getImportedScenarioValues(),
  }
  return (
    <AddScenarioPageStyled>
      {!!customerName && !!opportunitySummary && (
        <>
          <BreadcrumbsStyles>
            <Breadcrumbs2 items={breadcrumbs} />
          </BreadcrumbsStyles>
          <FixedNavigationStyled>
            <TitleWrapperStyles>
              <ArrowBackIcon onClick={navigateBack}>Back</ArrowBackIcon>
              <TitleStyles>Add Scenario</TitleStyles>
            </TitleWrapperStyles>
          </FixedNavigationStyled>
          {(
            customerGlobalInputs &&
            opportunitySummary &&
            site &&
            checkEmissionPresent(emission)
          ) ?
            <ScenarioForm
              customerGlobalInputs={customerGlobalInputs}
              loading={scenariosLoading}
              onCancel={navigateBack}
              onSubmit={handleSubmit}
              opportunity={opportunitySummary}
              scenario={updatedNewScenario}
              scenarios={scenarios}
              site={site}
              isClone={isClone}
              areValuesPreFilled={isImportedScenario || isClone}
              source='add'
              actions={actions}
            />
          : <SpinnerStyles>
              <Spinner />
            </SpinnerStyles>
          }
        </>
      )}
    </AddScenarioPageStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerSummariesActions, dispatch),
    ...bindActionCreators(customerGlobalInputsActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(opportunitySummariesActions, dispatch),
    ...bindActionCreators(scenarioActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(importedScenarioActions, dispatch),
    ...bindActionCreators(customerMetricsActions, dispatch),
    ...bindActionCreators(emissionActions, dispatch),
    ...bindActionCreators(recActions, dispatch),
    ...bindActionCreators(utilityInflationRateActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const customerSummariesEntity = selectProposalCustomerSummariesEntity(state)
  const customerGlobalInputsEntity =
    selectProposalCustomerGlobalInputsEntity(state)
  const opportunitySummariesEntity =
    selectProposalOpportunitySummariesEntity(state)
  const scenariosEntity = selectProposalScenarioEntity(state)
  const siteEntity = selectProposalSiteEntity(state)
  const customerMetricsEntity = selectProposalCustomerMetricsEntity(state)
  const importedScenario = selectProposalImportedScenario(state)
  const emissionRate = selectEmissionRateEntity(state)
  const { items: customerMetrics } = customerMetricsEntity
  const { items: customerGlobalInputs } = customerGlobalInputsEntity
  const {
    meta: { loading: customerSummariesLoading },
    byId: customerSummariesById,
  } = customerSummariesEntity
  const { items: opportunitySummaries } = opportunitySummariesEntity
  const {
    items: scenarios,
    meta: {
      error: scenariosError,
      loading: scenariosLoading,
      salesTaxFromAvalara,
      type: scenariosErrorType,
    },
  } = scenariosEntity
  const { items: sites } = siteEntity
  const { data: importedScenarioData } = importedScenario
  const { emissionRateData: emission } = emissionRate
  const { utilityInflationRateData, meta: utilityInflationRateMeta } =
    selectUtilityInflationRateDataEntity(state)
  return {
    customerGlobalInputs: customerGlobalInputs[0] || {},
    customerSummariesById,
    customerSummariesLoading,
    opportunitySummaries,
    scenarios,
    scenariosError,
    scenariosLoading,
    salesTaxFromAvalara,
    sites,
    importedScenario,
    importedScenarioData,
    customerMetrics,
    emission,
    utilityInflationRateData,
    utilityInflationRateMeta,
    scenariosErrorType,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddScenarioPage),
)
