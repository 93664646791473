import { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Modal from './Modal'
import type { FTModalGeneric } from '../ducks/modal'
import { actions as modalActions } from '../ducks/modal'

const ModalStyles = styled.div`
  .Modal .content {
    padding-bottom: 0;
  }
`
type FTProps = {
  dispatch: (...args: Array<any>) => any
} & FTModalGeneric

class ModalGeneric extends PureComponent<FTProps> {
  onClose = () => {
    const { handleClose } = this.props
    if (handleClose) {
      handleClose()
    }
    this.props.dispatch(modalActions.hideModal())
  }

  render() {
    const { body = '', title, acceptText } = this.props
    return (
      <ModalStyles>
        <Modal
          acceptText={acceptText || 'OK'}
          hideClose
          modalWidth='470px'
          onAccept={this.onClose}
          onClose={this.onClose}
          renderContent={() => body}
          title={title}
        />
      </ModalStyles>
    )
  }
}

export default connect(() => ({}))(ModalGeneric)
