import styled from 'styled-components'

export default styled.div`
  & .Modal .box {
    .content {
      padding-left: 0;
    }

    .buttons {
      text-align: left;
    }
  }
`
