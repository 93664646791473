type ErrorMessageDetailType = {
  title: string
  message: string
}

export const ERROR_CODE_MESSAGES: { [key: string]: ErrorMessageDetailType } = {
  EV01: {
    title: 'Meter MAC not found',
    message: 'Enter an existing Meter MAC',
  },
  EV02: {
    title: 'Meter has a pending configuration',
    message:
      'Wait for the configuration to be applied and data to be collected before retrying',
  },
  EV03: {
    title: 'Primary Panel not found for the meter',
    message: 'Add a primary panel to the meter before running the tests again',
  },
  EV04: {
    title: 'Unable to fetch data',
    message: 'Meter Data could not be fetched, try again later',
  },
  EV05: {
    title: 'We don’t have enough data to run “Full EV”',
    message: 'You can still run “Label Check"',
  },
  EV06: { title: 'Unable to run EV tests', message: 'Please try again later' },
  EV07: { title: 'Something went wrong', message: 'Please try again later' },
}
