export default () => (
  <svg
    width='12'
    height='9'
    viewBox='0 0 12 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.228 7.29333L6.11401 1.31333L0.999999 7.29333'
      stroke='#3E5CAA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
