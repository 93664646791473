import styled from 'styled-components'

import type { FTPanelCircuit } from '../../ducks/panelCircuits'
import RedaptiveReactTable from '../RedaptiveReactTable'
import Spinner from '../Spinner'

type FTProps = {
  leftCircuits: Array<FTPanelCircuit>
  rightCircuits: Array<FTPanelCircuit>
  isNumbered: boolean
  circuitsLoading: string
  columns: Array
}
const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
`
const CircuitContainerStyled = styled.div`
  overflow: scroll;
  .ReactTable .rt-tr {
    align-items: center;
  }
  .ReactTable .rt-tbody .rt-td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-right: none;
    overflow: visible;
  }
  .ReactTable .rt-table {
    overflow: visible;
  }
  .ReactTable .rt-tbody {
    overflow: visible;
  }
  .ReactTable {
    overflow-x: visible;
  }
`
const TableHeaderStyled = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #4a4a4a;
  padding-bottom: 15px;
  margin-top: 35px;
`

const PanelCircuitsTable = (props: FTProps) => {
  const { leftCircuits, rightCircuits, circuitsLoading, columns, isNumbered } =
    props

  const renderForNumberedPanel = () => (
    <CircuitContainerStyled>
      <TableHeaderStyled>Left Side</TableHeaderStyled>
      <RedaptiveReactTable
        data={leftCircuits}
        columns={columns}
        sortable={false}
        filterable={false}
        resizable={false}
      />
      <TableHeaderStyled>Right Side</TableHeaderStyled>
      <RedaptiveReactTable
        data={rightCircuits}
        columns={columns}
        sortable={false}
        filterable={false}
        resizable={false}
      />
    </CircuitContainerStyled>
  )

  const renderForNonNumberedPanel = () => (
    <CircuitContainerStyled>
      {isNumbered ?
        <>
          <TableHeaderStyled>Left Side</TableHeaderStyled>
          <RedaptiveReactTable
            data={leftCircuits}
            columns={columns}
            sortable={false}
            filterable={false}
            resizable={false}
          />
        </>
      : null}

      <TableHeaderStyled>
        {isNumbered ? 'Right Side' : 'Switches'}
      </TableHeaderStyled>
      <RedaptiveReactTable
        data={rightCircuits}
        columns={columns}
        sortable={false}
        filterable={false}
        resizable={false}
      />
    </CircuitContainerStyled>
  )

  if (circuitsLoading) {
    return (
      <SpinnerStyles>
        <Spinner size='medium' />
      </SpinnerStyles>
    )
  }

  if (isNumbered) {
    return renderForNumberedPanel()
  }

  return renderForNonNumberedPanel()
}

export default PanelCircuitsTable
