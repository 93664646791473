import type { FTRow } from '../BillingThisMonth/types'
import '../BillingThisMonth/types'
import { BILLING_METRIC } from './labels'

export const BILLING_METRIC_FIELD = {
  accessor: 'billingMetric',
  Header: BILLING_METRIC,
  Cell: ({
    row: {
      original: { billingMetric },
    },
  }: FTRow) => billingMetric,
  hideSettings: false,
  id: 'billingMetric',
}

export const BILLING_COMMENTS_SHEET_HEADERS = [
  {
    key: 'opportunityId',
    label: 'Opportunity',
  },
  {
    key: 'postedTime',
    label: 'Date',
  },
  {
    key: 'comment',
    label: 'Comment',
  },
  {
    key: 'userEmail',
    label: 'Commenter',
  },
]
