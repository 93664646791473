import React from 'react'
import styled from 'styled-components'

import type { FTAddScenarioWithoutSalesTaxModal } from '../../../ducks/modal'
import Button2 from '../../Button2'
import '../../../ducks/modal'
import { colors } from '../../../utils/themes'
import InfoCircleIcon from '../../Icons/InfoCircleIcon'
import { zIndices } from '../../../utils'

const ModalWrapperStyles = styled.div`
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(200, 200, 200, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.SalesTaxConfirmModal};

  * {
    box-sizing: border-box;
  }
`
const ModalContainerStyles = styled.div`
  width: 462px;
  height: 350px;
  position: fixed;
  left: 40%;
  top: 40%;
  z-index: 9999;
  border: 1px solid #eee;
  color: ${colors.blue5};
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  justify-content: space-between;
`
export const WarningMsgContainer = styled.div`
  padding: 10px 20px 0px;
  border: 2px solid ${colors.orange2};
  border-radius: 4px;
  background-color: #f2a91c29;
  margin: 15px;
`
export const WarningIconContainerStyles = styled.div`
  text-align: center;
  color: #f2a91c99;
`
export const WarningTextStyles = styled.div`
  padding: 0 10px;
`
export const WarningBodyStyles = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 0px 20px 15px;
`
const ModalActionContainerStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #e0e0e052;
  padding: 0 5px;
`

const ModalAddScenarioWithoutSalesTax = (
  props: FTAddScenarioWithoutSalesTaxModal,
) => {
  const { closeModal, warningMsg, body, onSubmit } = props
  const renderContent = (
    <ModalWrapperStyles>
      <ModalContainerStyles>
        <WarningMsgContainer>
          <WarningIconContainerStyles>
            <InfoCircleIcon />
          </WarningIconContainerStyles>
          <WarningTextStyles>{warningMsg}</WarningTextStyles>
        </WarningMsgContainer>
        <WarningBodyStyles>{body}</WarningBodyStyles>
        <ModalActionContainerStyles>
          <Button2 onClick={closeModal} type='transparent'>
            Cancel
          </Button2>
          <Button2 onClick={onSubmit} type='primary'>
            Confirm
          </Button2>
        </ModalActionContainerStyles>
      </ModalContainerStyles>
    </ModalWrapperStyles>
  )
  return renderContent
}

export default ModalAddScenarioWithoutSalesTax
