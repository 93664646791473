export default {
  annualTransferRate: 8,
  capexDealMarginTarget: 15,
  contingency: 9,
  currencyCode: 'USD',
  id: '3311e155-4995-4366-877f-9460aa1cf618',
  annualInsurancePremium: 12186.94,
  locale: 'en_US',
  measurementAndVerificationCostAsPerProjectCosts: 3,
  measurementAndVerificationCostPerMeter: 9.87,
  measurementAndVerificationMethodology: 'METER_OPS',
  operationsAndMaintenanceBasis: 'NPV_REVENUE',
  operationsAndMaintenanceRate: 5,
  projectManagementLaborAndFee: 2.9,
  rebateHaircut: 10,
  referralFeeBasis: 'CONTRACT_VALUE',
  referralFeeInPercentage: 2,
  salesforceCustomerId: '0014600000thPZqAAM',
  term: 90,
}
