import * as Yup from 'yup'

export default Yup.object().shape({
  contractType: Yup.string().required('Required'),
  energyRetainedSavings: Yup.string().required('Required'),
  maintenanceRetainedSavings: Yup.string().required('Required'),
  maintenanceObligation: Yup.string().required('Required'),
  annualTransferRate: Yup.number().required('Required'),
  contingency: Yup.number().required('Required'),
  annualInsurancePremium: Yup.number().required('Required'),
  measurementAndVerificationCostPerMeter: Yup.number().required('Required'),
  measurementAndVerificationMethodology: Yup.string().required('Required'),
  operationsAndMaintenanceBasis: Yup.string().required('Required'),
  operationsAndMaintenanceRate: Yup.number().required('Required'),
  projectManagementLaborAndFee: Yup.number().required('Required'),
  measurementAndVerificationCostAsPerProjectCostsInPercentage:
    Yup.string().required('Required'),
  referralFeeBasis: Yup.string(),
  referralFeeInPercentage: Yup.number().required('Required'),
  term: Yup.number().required('Required'),
})
