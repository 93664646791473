import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CreateLinkForm from '../../components/CreateLinkForm'
import OneTimeLink from '../../components/OneTimeLink'
import * as status from '../../constants/status'
import { selectors as authSelectors } from '../../ducks/auth'
import { actions as customerActions } from '../../ducks/customers'
import {
  actions as userActions,
  selectUserReset,
  USER_RESET_REQUEST_KEY,
} from '../../ducks/users'

type FTProps = {
  error: Record<string, any>
  loading?: boolean
  notice: string
  customer: Record<string, any> | null | undefined
  customerId: string | null | undefined
  actions: Record<string, any>
  resetId: string | null | undefined
  resetEmail: string | null | undefined
}

class ResetPasswordFormPage extends Component<FTProps> {
  static defaultProps = {
    loading: false,
  }

  componentDidMount() {
    const { customerId } = this.props

    if (customerId) {
      this.props.actions.fetchCustomer({
        customerId,
      })
    }

    this.props.actions.clearUserResetForm()
  }

  clearReset = () => {
    this.props.actions.clearUserReset()
  }

  handleSubmit = (body) => {
    const { actions } = this.props
    actions.resetUser(body)
  }

  render() {
    const { error, loading, notice, customer, resetId, resetEmail } = this.props

    if (resetId && resetEmail) {
      return (
        <div className='old-ui'>
          <div className='ResetPasswordFormPage'>
            <OneTimeLink
              email={resetEmail}
              resetId={resetId}
              goBack={this.clearReset}
            />
          </div>
        </div>
      )
    }

    return (
      <div className='old-ui'>
        <div className='ResetPasswordFormPage'>
          <CreateLinkForm
            customer={customer}
            notice={notice}
            onSubmit={this.handleSubmit}
            errorMsg={error}
            loading={loading}
            isSignup={false}
          />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
  },
})

const mapStateToProps = (state, props) => {
  const { requestStatus } = state
  const { status: formStatus, error } = requestStatus[USER_RESET_REQUEST_KEY]
  const { customerId } = props.match.params
  let customer

  if (customerId) {
    customer = state.entities.customers.byId[customerId]
  }

  const { resetId, email: resetEmail } = selectUserReset(state)
  return {
    error,
    customerId,
    customer,
    resetId,
    resetEmail,
    permissions: authSelectors.selectPermissions(state),
    loading: formStatus === status.LOADING,
    success: formStatus === status.LOADED,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordFormPage)
