export const POPUP_STATES = {
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE',
  ADD: 'ADD',
  NONE: 'NONE',
}
export const VIEWS = {
  NONE: 'NONE',
  SEARCH: 'SEARCH',
  CLEAR: 'CLEAR',
}
