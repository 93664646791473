import classNames from 'classnames'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { logoutUser as logoutUserAction } from '../actions/login'
import Navbar from '../components/Navbar'
import { AUTH_SIGN_IN } from '../constants/strings'
import FooterContainer from '../containers/FooterContainer'
import SimpleFooterContainer from '../containers/SimpleFooterContainer'
import logo from '../redaptive-logo.svg'
// TODO: Convert HTML to use styled-components
const Styles = styled.div`
  height: 100%;
  background-color: #f3f3f3;

  & > :first-child {
    margin-bottom: -60px;
    min-height: 100%;
  }

  & .NotFoundContainer {
    .Card {
      width: 476px;
    }

    &.authenticated .Card {
      width: 666px;
    }

    .NotFound {
      padding-top: 96px;
      padding-bottom: 60px;
      border-top: 1px solid #dcdcdc;
    }
  }

  .NotFound * {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.38;
  }

  .NotFound .box {
    margin: auto;
    width: 478px;
    background-color: #ffffff;
    padding: 20px 30px;
    border: 1px solid #dcdcdc;
    text-align: center;
  }

  .NotFound .box.authenticated {
    width: 666px;
  }

  .NotFound .logo {
    width: 197.6px;
    margin-bottom: 40px;
  }

  .NotFound .title {
    font-size: 28px;
    color: #0193d7;
    font-weight: 200;
  }

  .NotFound .subject {
    font-weight: 500;
    margin-top: 11px;
    color: #464a54;
  }

  .NotFound .body {
    margin-top: 16px;
    margin-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .NotFound .body a[href] {
    color: #2965a8;
  }

  .NotFound .login {
    width: 408px;
    padding: 12px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 4px;
    border: 0;
    background-color: #0193d7;
    color: white;
    cursor: pointer;
  }
`
type FTProps = {
  logoutUser: (...args: Array<any>) => any
  isAuthenticated: boolean
}

class NotFoundPage extends PureComponent<FTProps> {
  render() {
    const { isAuthenticated, logoutUser } = this.props
    return (
      <Styles className='NotFoundPage'>
        <div
          className={classNames({
            NotFoundContainer: true,
            authenticated: isAuthenticated,
          })}
        >
          {isAuthenticated && <Navbar logoutUser={logoutUser} />}
          <div className='NotFound'>
            <div
              className={classNames({
                box: true,
                authenticated: isAuthenticated,
              })}
            >
              {!isAuthenticated && (
                <img className='logo' src={logo} alt='logo' />
              )}
              <div className='title'>404</div>
              <div className='subject'>
                The page you’re looking for does not exist.
              </div>
              <div className='body'>
                Please make sure you have entered the correct URL, or go back to
                the&nbsp;
                <Link to='/' href='/'>
                  main page
                </Link>
              </div>
              {!isAuthenticated && (
                <Link to='/login' href='/login'>
                  <button type='button' className='login'>
                    {AUTH_SIGN_IN}
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        {isAuthenticated && <FooterContainer />}
        {!isAuthenticated && <SimpleFooterContainer />}
      </Styles>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUserAction()),
})

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
})

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage)
