export default {
  created: '2021-09-21T13:52:37.671016',
  createdBy: 'admin@redaptiveinc.com',
  modified: null,
  modifiedBy: null,
  id: '315c61fe-708e-472b-89f3-1b0992351428',
  salesforceCustomerId: '0014600001JJq6LAAT',
  name: 'Batch-6',
  status: 'PENDING',
  projectType: 'LIGHTING',
  opportunityIds: null,
  opportunities: [
    {
      externalId: '0064o00000PkaAzAAJ',
      redaptiveOpportunityId: 'R00010883',
      name: 'Lighting - Berry Global - 463 Harding Industrial Drive - Nashville - TN CHANGE ORDER (Bldg 9 + CO)',
      opportunityType: 'Lighting',
      stageName: 'Evaluating',
      programOpportunity: '0064600000DAJhGAAX',
      scenarioTotalCount: 1,
      opportunityStatus: 'SCOPING',
      batchAnalysisTotalCount: 0,
      salesforceSiteId: '0014600001TrLv2AAF',
      currencyCode: 'USD',
      selectedInBatchAnalysis: true,
      selectedScenarioIdInBatch: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd2',
      scenarios: [
        {
          id: '98512c88-5d41-4347-be15-00ab5bb8bb29ab',
          opportunityId: '0064o00000PkaAzAAJ',
          salesforceSiteId: '0014600001TrLv2AAF',
          name: 'Test1',
          contractTermMonths: 3,
          dealType: 'EAAS',
          status: 'APPROVED',
          transferRate: 6,
          operationsAndMaintenance: 0,
          operationsAndMaintenanceBasis: 'NPV_REVENUE',
          partnerFeeInPercentage: 0,
          referralFeeInPercentage: 0,
          referralFeeBasis: 'CONTRACT_VALUE',
          contingencyInPercentage: 0,
          costPerMeter: 0,
          measurementAndVerificationSource: 'ASSET_BASED',
          measurementAndVerificationCostAsPerProjectCosts: 0,
          maintenanceSavingsContemplated: true,
          maintenanceLightingSavings: 8,
          insuranceForEveryHundredDollarsOfProjectCost: 0,
          capexMarginSeek: 0,
          totalProposedFixtureCount: 180,
          lightingAddFixtureCount: 0,
          lightingRelampBypassBallastCount: 0,
          lightingReplaceFixtureCount: 0,
          lightingRelampCount: 0,
          lightingRetrofitKitCount: 0,
          lightingNoChange: 0,
          lightingSensorsOnly: 0,
          lightingRemoveFixtures: 0,
          materialCosts: 5.6,
          laborCosts: 0,
          otherCost: 0,
          baseCost: 0,
          partnerFee: 0,
          vendorSalesTax: 0,
          contingencyCost: 0,
          upFrontMeasurementAndVerification: 0,
          insuranceFee: 0,
          referralCost: 0,
          initialRebateAmount: 10,
          rebateHcInPercentage: 0,
          finalRebateAmount: 0,
          incentives: 0,
          netCost: 0,
          totalNte: 56,
          ttmUtilityUsage: 0,
          trailing12MonthsUtilityBill: 0,
          utilityRate: 0,
          contractRate: 0,
          energyRetainedSavingsInPercentage: 10,
          annualPreKwh: 0,
          annualPostKwh: 0,
          annualSavedKwh: 0,
          annualEnergyPayment: 0,
          annualPreBurnHours: 0,
          annualPostBurnHours: 0,
          maintenanceSavingsPerFixture: 0,
          maintenanceRetainedSavingsInPercentage: 0,
          maintenanceRepairAndOperationsAnnualPayment: 0,
          annualEnergySavingsInDollars: 0,
          totalOngoingContractValue: 0,
          upFrontPayment: 0,
          preTaxContractValue: 110,
          npvRevenue: 0,
          npvOngoingCosts: 0,
          npvNetRevenue: 0,
          netMarginInDollars: 0,
          netMarginInPercentage: 0,
          internalRateOfReturn: 0,
          grossMarginInPercentage: 10,
          grossMargin: 500,
          ttmBillReductionInPercentage: 0,
          costReduction: null,
          auditVendor: '',
        },
        {
          id: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd',
          opportunityId: '0064o0PkaAzAAJ',
          salesforceSiteId: '0014600001TrLv2AAF',
          name: 'Test2',
          contractTermMonths: 3,
          dealType: 'EAAS',
          status: 'PENDING',
          transferRate: 6,
          operationsAndMaintenance: 0,
          operationsAndMaintenanceBasis: 'NPV_REVENUE',
          partnerFeeInPercentage: 0,
          referralFeeInPercentage: 0,
          referralFeeBasis: 'CONTRACT_VALUE',
          contingencyInPercentage: 0,
          costPerMeter: 0,
          measurementAndVerificationSource: 'ASSET_BASED',
          measurementAndVerificationCostAsPerProjectCosts: 0,
          maintenanceSavingsContemplated: true,
          maintenanceLightingSavings: 8,
          insuranceForEveryHundredDollarsOfProjectCost: 0,
          capexMarginSeek: 0,
          totalProposedFixtureCount: 0,
          lightingAddFixtureCount: 0,
          lightingRelampBypassBallastCount: 0,
          lightingReplaceFixtureCount: 0,
          lightingRelampCount: 0,
          lightingRetrofitKitCount: 0,
          lightingNoChange: 0,
          lightingSensorsOnly: 0,
          lightingRemoveFixtures: 0,
          materialCosts: 5.6,
          laborCosts: 0,
          otherCost: 0,
          baseCost: 0,
          partnerFee: 0,
          vendorSalesTax: 0,
          contingencyCost: 0,
          upFrontMeasurementAndVerification: 0,
          insuranceFee: 0,
          referralCost: 0,
          initialRebateAmount: 0,
          rebateHcInPercentage: 0,
          finalRebateAmount: 0,
          incentives: 0,
          netCost: 0,
          totalNte: 56,
          ttmUtilityUsage: 0,
          trailing12MonthsUtilityBill: 0,
          utilityRate: 0,
          contractRate: 0,
          energyRetainedSavingsInPercentage: 0,
          annualPreKwh: 0,
          annualPostKwh: 0,
          annualSavedKwh: 0,
          annualEnergyPayment: 0,
          annualPreBurnHours: 0,
          annualPostBurnHours: 0,
          maintenanceSavingsPerFixture: 0,
          maintenanceRetainedSavingsInPercentage: 0,
          maintenanceRepairAndOperationsAnnualPayment: 0,
          annualEnergySavingsInDollars: 0,
          totalOngoingContractValue: 0,
          upFrontPayment: 0,
          preTaxContractValue: 220,
          npvRevenue: 0,
          npvOngoingCosts: 0,
          npvNetRevenue: 0,
          netMarginInDollars: 0,
          netMarginInPercentage: 0,
          internalRateOfReturn: 0,
          grossMarginInPercentage: 0,
          grossMargin: 0,
          ttmBillReductionInPercentage: 0,
          costReduction: null,
          auditVendor: '',
        },
        {
          id: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd2',
          opportunityId: '0064o0PkaAzAAJ',
          salesforceSiteId: '0014600001TrLv2AAF',
          name: 'Test3',
          contractTermMonths: 3,
          dealType: 'EAAS',
          status: 'PENDING',
          transferRate: 6,
          operationsAndMaintenance: 0,
          operationsAndMaintenanceBasis: 'NPV_REVENUE',
          partnerFeeInPercentage: 0,
          referralFeeInPercentage: 0,
          referralFeeBasis: 'CONTRACT_VALUE',
          contingencyInPercentage: 0,
          costPerMeter: 0,
          measurementAndVerificationSource: 'ASSET_BASED',
          measurementAndVerificationCostAsPerProjectCosts: 0,
          maintenanceSavingsContemplated: true,
          maintenanceLightingSavings: 8,
          insuranceForEveryHundredDollarsOfProjectCost: 0,
          capexMarginSeek: 0,
          totalProposedFixtureCount: 0,
          lightingAddFixtureCount: 0,
          lightingRelampBypassBallastCount: 0,
          lightingReplaceFixtureCount: 0,
          lightingRelampCount: 0,
          lightingRetrofitKitCount: 0,
          lightingNoChange: 0,
          lightingSensorsOnly: 0,
          lightingRemoveFixtures: 0,
          materialCosts: 5.6,
          laborCosts: 0,
          otherCost: 0,
          baseCost: 0,
          partnerFee: 0,
          vendorSalesTax: 0,
          contingencyCost: 0,
          upFrontMeasurementAndVerification: 0,
          insuranceFee: 0,
          referralCost: 0,
          initialRebateAmount: 0,
          rebateHcInPercentage: 0,
          finalRebateAmount: 0,
          incentives: 0,
          netCost: 0,
          totalNte: 56,
          ttmUtilityUsage: 0,
          trailing12MonthsUtilityBill: 0,
          utilityRate: 0,
          contractRate: 0,
          energyRetainedSavingsInPercentage: 0,
          annualPreKwh: 0,
          annualPostKwh: 0,
          annualSavedKwh: 0,
          annualEnergyPayment: 0,
          annualPreBurnHours: 0,
          annualPostBurnHours: 0,
          maintenanceSavingsPerFixture: 0,
          maintenanceRetainedSavingsInPercentage: 0,
          maintenanceRepairAndOperationsAnnualPayment: 0,
          annualEnergySavingsInDollars: 0,
          totalOngoingContractValue: 0,
          upFrontPayment: 0,
          preTaxContractValue: 220,
          npvRevenue: 0,
          npvOngoingCosts: 0,
          npvNetRevenue: 0,
          netMarginInDollars: 0,
          netMarginInPercentage: 0,
          internalRateOfReturn: 0,
          grossMarginInPercentage: 0,
          grossMargin: 0,
          ttmBillReductionInPercentage: 0,
          costReduction: null,
          auditVendor: '',
        },
      ],
    },
    {
      externalId: '0064600000FgnRjAAJ',
      redaptiveOpportunityId: 'R00002651',
      name: 'Lighting - Berry Global - 349 Steven Taylor Boulevard - McAlester - OK',
      opportunityType: 'Lighting',
      stageName: 'Evaluating',
      programOpportunity: '0064600000PLL16AAH',
      scenarioTotalCount: 0,
      opportunityStatus: 'SCOPING',
      batchAnalysisTotalCount: 0,
      salesforceSiteId: '0014600001LOpEaAAL',
      currencyCode: 'USD',
      selectedInBatchAnalysis: true,
      selectedScenarioIdInBatch: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd1cd',
      scenarios: [
        {
          id: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd1',
          opportunityId: '0064o00000PkaAzAAJ',
          salesforceSiteId: '0014600001TrLv2AAF',
          name: 'Test1',
          contractTermMonths: 3,
          dealType: 'EAAS',
          status: 'APPROVED',
          transferRate: 6,
          operationsAndMaintenance: 0,
          operationsAndMaintenanceBasis: 'NPV_REVENUE',
          partnerFeeInPercentage: 0,
          referralFeeInPercentage: 0,
          referralFeeBasis: 'CONTRACT_VALUE',
          contingencyInPercentage: 0,
          costPerMeter: 0,
          measurementAndVerificationSource: 'ASSET_BASED',
          measurementAndVerificationCostAsPerProjectCosts: 0,
          maintenanceSavingsContemplated: true,
          maintenanceLightingSavings: 8,
          insuranceForEveryHundredDollarsOfProjectCost: 0,
          capexMarginSeek: 0,
          totalProposedFixtureCount: 180,
          lightingAddFixtureCount: 0,
          lightingRelampBypassBallastCount: 0,
          lightingReplaceFixtureCount: 0,
          lightingRelampCount: 0,
          lightingRetrofitKitCount: 0,
          lightingNoChange: 0,
          lightingSensorsOnly: 0,
          lightingRemoveFixtures: 0,
          materialCosts: 5.6,
          laborCosts: 0,
          otherCost: 0,
          baseCost: 0,
          partnerFee: 0,
          vendorSalesTax: 0,
          contingencyCost: 0,
          upFrontMeasurementAndVerification: 0,
          insuranceFee: 0,
          referralCost: 0,
          initialRebateAmount: 10,
          rebateHcInPercentage: 0,
          finalRebateAmount: 0,
          incentives: 0,
          netCost: 0,
          totalNte: 56,
          ttmUtilityUsage: 0,
          trailing12MonthsUtilityBill: 0,
          utilityRate: 0,
          contractRate: 0,
          energyRetainedSavingsInPercentage: 10,
          annualPreKwh: 0,
          annualPostKwh: 0,
          annualSavedKwh: 0,
          annualEnergyPayment: 0,
          annualPreBurnHours: 0,
          annualPostBurnHours: 0,
          maintenanceSavingsPerFixture: 0,
          maintenanceRetainedSavingsInPercentage: 0,
          maintenanceRepairAndOperationsAnnualPayment: 0,
          annualEnergySavingsInDollars: 0,
          totalOngoingContractValue: 0,
          upFrontPayment: 0,
          preTaxContractValue: 2220,
          npvRevenue: 0,
          npvOngoingCosts: 0,
          npvNetRevenue: 0,
          netMarginInDollars: 0,
          netMarginInPercentage: 0,
          internalRateOfReturn: 0,
          grossMarginInPercentage: 10,
          grossMargin: 500,
          ttmBillReductionInPercentage: 0,
          costReduction: null,
          auditVendor: '',
        },
        {
          id: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd1cd',
          opportunityId: '0064o0PkaAzAAJ',
          salesforceSiteId: '0014600001TrLv2AAF',
          name: 'Test2',
          contractTermMonths: 3,
          dealType: 'EAAS',
          status: 'PENDING',
          transferRate: 6,
          operationsAndMaintenance: 0,
          operationsAndMaintenanceBasis: 'NPV_REVENUE',
          partnerFeeInPercentage: 0,
          referralFeeInPercentage: 0,
          referralFeeBasis: 'CONTRACT_VALUE',
          contingencyInPercentage: 0,
          costPerMeter: 0,
          measurementAndVerificationSource: 'ASSET_BASED',
          measurementAndVerificationCostAsPerProjectCosts: 0,
          maintenanceSavingsContemplated: true,
          maintenanceLightingSavings: 8,
          insuranceForEveryHundredDollarsOfProjectCost: 0,
          capexMarginSeek: 0,
          totalProposedFixtureCount: 0,
          lightingAddFixtureCount: 0,
          lightingRelampBypassBallastCount: 0,
          lightingReplaceFixtureCount: 0,
          lightingRelampCount: 0,
          lightingRetrofitKitCount: 0,
          lightingNoChange: 0,
          lightingSensorsOnly: 0,
          lightingRemoveFixtures: 0,
          materialCosts: 5.6,
          laborCosts: 0,
          otherCost: 0,
          baseCost: 0,
          partnerFee: 0,
          vendorSalesTax: 0,
          contingencyCost: 0,
          upFrontMeasurementAndVerification: 0,
          insuranceFee: 0,
          referralCost: 0,
          initialRebateAmount: 0,
          rebateHcInPercentage: 0,
          finalRebateAmount: 0,
          incentives: 0,
          netCost: 0,
          totalNte: 56,
          ttmUtilityUsage: 0,
          trailing12MonthsUtilityBill: 0,
          utilityRate: 0,
          contractRate: 0,
          energyRetainedSavingsInPercentage: 0,
          annualPreKwh: 0,
          annualPostKwh: 0,
          annualSavedKwh: 0,
          annualEnergyPayment: 0,
          annualPreBurnHours: 0,
          annualPostBurnHours: 0,
          maintenanceSavingsPerFixture: 0,
          maintenanceRetainedSavingsInPercentage: 0,
          maintenanceRepairAndOperationsAnnualPayment: 0,
          annualEnergySavingsInDollars: 0,
          totalOngoingContractValue: 0,
          upFrontPayment: 0,
          preTaxContractValue: 300,
          npvRevenue: 0,
          npvOngoingCosts: 0,
          npvNetRevenue: 0,
          netMarginInDollars: 0,
          netMarginInPercentage: 0,
          internalRateOfReturn: 0,
          grossMarginInPercentage: 0,
          grossMargin: 0,
          ttmBillReductionInPercentage: 0,
          costReduction: null,
          auditVendor: '',
        },
      ],
    },
  ],
  redaptiveOpportunityIds: null,
  lastModified: '2021-09-21T13:52:37.671016',
}
