import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTBillingSendToNetsuiteModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import Button2 from '../Button2'
import {
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
} & FTBillingSendToNetsuiteModal

const billingSendToNetsuiteModal = ({
  actions,
  savingsMonth,
  opportunityList,
  loading,
  setSendToNetsuiteSubmitted,
}: FTProps) => {
  const handleConfirm = () => {
    actions.sendToNetsuite({
      savingsMonth,
      opportunityList,
    })
    setSendToNetsuiteSubmitted(true)
  }

  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled>
        <ModalConfirmTitleStyled>
          Are you sure you want to send this Opportunity(ies) to Netsuite?
        </ModalConfirmTitleStyled>
        <ModalConfirmButtonsStyled>
          <Button2
            onClick={actions.hideModal}
            disabled={loading}
            type='transparent'
          >
            Cancel
          </Button2>
          <Button2 onClick={handleConfirm} loading={loading} type='primary'>
            Confirm
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const {
    sendToNetsuiteMeta: { loading },
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(billingSendToNetsuiteModal)
