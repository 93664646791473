import Spinner from './Spinner'

const LoadingSpinner = () => (
  <>
    {'Loading... '}
    <Spinner inline size='micro' />
  </>
)

export default LoadingSpinner
