import { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import GlobalInputsForm from './GlobalInputsForm'
import GlobalInputsView from './GlobalInputsView'
import { selectors as authSelectors } from '../../../ducks/auth'
import type {
  FTCreateProposalCustomerGlobalInputsAction,
  FTProposalCustomerGlobalInputs,
  FTPutProposalCustomerGlobalInputsAction,
} from '../../../ducks/proposals/customerGlobalInputs'
import {
  actions as customerGlobalInputsActions,
  selectProposalCustomerGlobalInputsEntity,
} from '../../../ducks/proposals/customerGlobalInputs'
import type { FTWithRouter } from '../../../types'
import { colors } from '../../../utils/themes'
import Spinner from '../../Spinner'
import EditNoteIconStyled from '../EditNoteIconStyled'

type FTProps = {
  actions: {
    putProposalCustomerGlobalInputs: (
      params: FTPutProposalCustomerGlobalInputsAction,
    ) => null
    createProposalCustomerGlobalInputs: (
      params: FTCreateProposalCustomerGlobalInputsAction,
    ) => null
  }
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  error: string
  loading: boolean
  userGroups: Array<string>
} & FTWithRouter
const GlobalInputsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
`
const Heading = styled.span`
  color: ${colors.blue2};
`
const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
`

const GlobalInputs = (props: FTProps) => {
  const {
    actions,
    customerGlobalInputs,
    error,
    loading,
    match: {
      params: { salesforceCustomerId },
    },
    userGroups,
  } = props
  const {
    createProposalCustomerGlobalInputs,
    putProposalCustomerGlobalInputs,
  } = actions
  const [editMode, setEditMode]: [boolean, (...args: Array<any>) => any] =
    useState(false)
  const [initialLoadComplete, setInitialLoadComplete]: [
    boolean,
    (...args: Array<any>) => any,
  ] = useState(false)
  const isProposalOpsUser = useMemo(() => {
    if (userGroups?.length) {
      return userGroups.some((group) =>
        ['proposal-operations', 'proposal-operations-admin'].includes(group),
      )
    }
    return false
  }, [userGroups])
  useEffect(() => {
    if (!loading && !initialLoadComplete) {
      setInitialLoadComplete(true)
    }
  }, [loading])
  return (
    <>
      <GlobalInputsHeading>
        <Heading>{editMode ? 'Edit Global Inputs' : 'Global Inputs'}</Heading>
        {!editMode && isProposalOpsUser && (
          <EditNoteIconStyled onClick={() => setEditMode(true)} />
        )}
      </GlobalInputsHeading>
      {!initialLoadComplete && (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )}
      {initialLoadComplete && editMode && (
        <GlobalInputsForm
          customerGlobalInputs={customerGlobalInputs}
          createProposalCustomerGlobalInputs={
            createProposalCustomerGlobalInputs
          }
          error={error}
          loading={loading}
          salesforceCustomerId={salesforceCustomerId}
          setEditMode={setEditMode}
          putProposalCustomerGlobalInputs={putProposalCustomerGlobalInputs}
        />
      )}
      {initialLoadComplete && !editMode && (
        <>
          {loading && (
            <SpinnerStyles>
              <Spinner />
            </SpinnerStyles>
          )}
          {!loading && (
            <GlobalInputsView
              customerGlobalInputs={customerGlobalInputs}
              loading={loading}
            />
          )}
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(customerGlobalInputsActions, dispatch) },
})

const mapStateToProps = (state) => {
  const customerGlobalInputsEntity =
    selectProposalCustomerGlobalInputsEntity(state)
  const userGroups = authSelectors.selectGroups(state)
  const {
    meta: { error, loading },
    items: customerGlobalInputs,
  } = customerGlobalInputsEntity
  return {
    customerGlobalInputs: customerGlobalInputs[0] || {},
    error,
    loading,
    userGroups,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalInputs),
)
