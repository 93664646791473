import initialState from './initialState'
import { STORAGE_LOGIN_TOKEN } from '../constants'
import * as types from '../constants/actionTypes'
import { types as userTypes } from '../ducks/users'

type FTState = {
  readonly isAuthenticated: boolean
  readonly username: string | null | undefined
  readonly userId: string | null | undefined
  readonly notice: string | null | undefined
}
type FTAction =
  | {
      type: 'FORGOT_PASSWORD_REQUEST'
    }
  | {
      type: 'FORGOT_PASSWORD_REQUEST_SUCCESS'
    }
  | {
      type: 'FORGOT_PASSWORD_REQUEST_ERROR'
    }
  | {
      type: 'LOGIN_REQUEST'
      creds: {
        username: string
        password: string
      }
      notice: string | null | undefined
    }
  | {
      type: 'LOGIN_SUCCESS'
      user: {
        username: string
        userId: string
      }
    }
  | {
      type: 'LOGIN_FAILURE'
    }
  | {
      type: 'LOGOUT_REQUEST'
    }
  | {
      type: 'PASSWORD_EXPIRED'
      error: Record<string, any>
    }
  | {
      type: 'SET_LOGIN_FORM_EMAIL_FIELD_VALUE'
      value: string
    }
  | {
      type: 'LOGOUT_SUCCESS'
    }
export default function authReducer(
  state: FTState = initialState.user,
  action: FTAction,
): FTState {
  const isAuthenticated = !!localStorage.getItem(STORAGE_LOGIN_TOKEN)

  switch (action.type) {
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        notice: null,
        forgotPasswordError: false,
        forgotPasswordLoading: true,
      }

    case types.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return { ...state, forgotPasswordLoading: false }

    case types.FORGOT_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        forgotPasswordError: true,
        forgotPasswordLoading: false,
      }

    case types.LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated,
        user: action.creds,
        notice: action.notice,
      }

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated,
        username: action.user.username,
        userId: action.user.userId,
      }

    case types.LOGIN_FAILURE:
      return { ...state, isAuthenticated }

    case types.LOGOUT_REQUEST:
      return { ...state, isAuthenticated, username: null }

    case types.LOGOUT_SUCCESS:
      return { ...state, isAuthenticated }

    case types.PASSWORD_EXPIRED:
      return { ...state, isAuthenticated, error: action.error }

    case types.SET_LOGIN_FORM_EMAIL_FIELD_VALUE:
      return { ...state, loginFormEmailFieldValue: action.value }

    case userTypes.FETCH_USER_INFO_SUCCESS:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
