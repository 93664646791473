// TODO: This is temporary file. Will be removed once this file is hosted on server.

/* eslint-disable max-len */
const sampleInvoiceData =
  'SiteID,UtilityName,AccountNumber,MeterNumber,ServiceType,TariffName,InvoiceNumber,BillPeriodStart,BillPeriodEnd,BillingPeriod,TotalUnits,UnitOfMeasure,TotalBillAmount,Source,SourceDocument,DataSource,URL,EstimatedReading,PeakDemand,PeakDemandUnits\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,08/01/2026,09/30/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,FALSE,2,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,01/01/2020,01/31/2029,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,3,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,01/02/2029,01/31/2029,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,4,kw\n' +
  '0017800000FjI9lAAF,PG&EET,,101007850222,Water,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,5,kw\n' +
  '0017800000FjI9lAAF,,2981279047-192,101007850222,Water,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,6,kw\n' +
  '0017800000FjI9lAAF,PG&EE,2981279047-192,101007850222,Water,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,7,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,,Water,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,8,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,3345,Water,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,9,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Electric,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,10,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Eelec,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,11,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Eelec,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoices,UtilityAPI,,TRUE,12,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,01/01/2026,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,13,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,01/01/2026,02/28/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,14,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,12/31/2025,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,15,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,01/31/2026,01/01/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,16,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,01/31/26,01/31/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,17,kw\n' +
  '0017800000FjI9lAAF,PG&EET,2981279047-192,101007850222,Water,New tariff charge,,04/01/2026,04/30/2027,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,TRUE,2,kw\n' +
  '0017800000FjI9lAAF,PG&EET,298127904,101007850222,Water,New tariff charge,,08/01/2026,09/30/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,FALSE,2,kw\n' +
  '0017800000FjI9lAAF,PG&EE,2981279047-192,101007850222,Water,New tariff charge,,08/01/2026,09/30/2026,,5000,GAL,15000,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,FALSE,2,kw'
export default sampleInvoiceData
