export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='10'
    viewBox='0 0 12 10'
    fill='none'
  >
    <path
      d='M11.9499 4.75C10.7383 1.81875 8.45914 0 6 0C3.54086 0 1.26165 1.81875 0.0500734 4.75C0.0170469 4.82885 0 4.91396 0 5C0 5.08604 0.0170469 5.17115 0.0500734 5.25C1.26165 8.18125 3.54086 10 6 10C8.45914 10 10.7383 8.18125 11.9499 5.25C11.983 5.17115 12 5.08604 12 5C12 4.91396 11.983 4.82885 11.9499 4.75ZM6 8.75C4.09266 8.75 2.29929 7.31875 1.26165 5C2.29929 2.68125 4.09266 1.25 6 1.25C7.90734 1.25 9.70071 2.68125 10.7383 5C9.70071 7.31875 7.90734 8.75 6 8.75ZM6 2.5C5.52549 2.5 5.06164 2.64662 4.6671 2.92133C4.27256 3.19603 3.96505 3.58648 3.78346 4.04329C3.60187 4.50011 3.55436 5.00277 3.64693 5.48773C3.73951 5.97268 3.96801 6.41814 4.30353 6.76777C4.63906 7.1174 5.06655 7.3555 5.53195 7.45196C5.99734 7.54843 6.47973 7.49892 6.91812 7.3097C7.35651 7.12048 7.73121 6.80005 7.99483 6.38893C8.25846 5.9778 8.39916 5.49445 8.39916 5C8.39916 4.33696 8.1464 3.70107 7.69647 3.23223C7.24653 2.76339 6.6363 2.5 6 2.5ZM6 6.25C5.76274 6.25 5.53082 6.17669 5.33355 6.03934C5.13628 5.90199 4.98252 5.70676 4.89173 5.47835C4.80094 5.24995 4.77718 4.99861 4.82347 4.75614C4.86975 4.51366 4.984 4.29093 5.15177 4.11612C5.31953 3.9413 5.53328 3.82225 5.76597 3.77402C5.99867 3.72579 6.23986 3.75054 6.45906 3.84515C6.67826 3.93976 6.8656 4.09998 6.99742 4.30554C7.12923 4.5111 7.19958 4.75277 7.19958 5C7.19958 5.33152 7.0732 5.64946 6.84823 5.88388C6.62327 6.1183 6.31815 6.25 6 6.25Z'
      fill='#6C6D6E'
      fillOpacity='0.933333'
    />
  </svg>
)
