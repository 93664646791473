import Input, { FTInputProps } from './Input'

export const inputIntegerOnInput = (event: any) => {
  const { target } = event

  if (target.value && target.value !== '0') {
    target.value = parseInt(target.value, 10) || ''
  }
}
export const inputIntegerOnKeyDown = (event: KeyboardEvent) => {
  if (event.key.toLowerCase() === 'e' || event.key === '.') {
    event.preventDefault()
  }
}
export default (props: FTInputProps) => (
  <Input
    onInput={inputIntegerOnInput}
    onKeyDown={inputIntegerOnKeyDown}
    {...props}
  />
)
