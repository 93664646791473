export default [
  {
    externalId: '0064o00000ZLH58AAH',
    redaptiveOpportunityId: 'R00010893',
    name: 'LIGHT - Access Corp - 6101 North 63rd Street - Lincoln - NE',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJw3oAAC',
  },
  {
    externalId: '0064o00000ZLGvcAAH',
    redaptiveOpportunityId: 'R00010883',
    name: 'LIGHT - Access Corp - 750 Mountain View Drive -  Piney Flats - TN',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJvxjAAC',
  },
  {
    externalId: '0064o00000ZLGwuAAH',
    redaptiveOpportunityId: 'R00010885',
    name: 'LIGHT - Access Corp - 2918 Dairy Drive - Madison - WI',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJvxtAAC',
  },
  {
    externalId: '0064o00000ZLGwzAAH',
    redaptiveOpportunityId: 'R00010886',
    name: 'LIGHT - Access Corp - 13950 Cerritos Corporate Drive, Building C - Cerritos - CA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJufyAAC',
  },
  {
    externalId: '0064o00000ZLIo8AAH',
    redaptiveOpportunityId: 'R00010917',
    name: 'LIGHT - Access Corp - 21024 24th Avenue South Suite 117 - SeaTac - WA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nKCjQAAW',
  },
  {
    externalId: '0064o00000ZLInyAAH',
    redaptiveOpportunityId: 'R00010916',
    name: 'LIGHT - Access Corp - 5450 Spellmire Drive - West Chester Township - OH',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nKCiSAAW',
  },
  {
    externalId: '0064o00000ZLGtbAAH',
    redaptiveOpportunityId: 'R00010879',
    name: 'LIGHT - Access Corp - 8951 South 126th Street - Omaha - NE',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJvxZAAS',
  },
  {
    externalId: '0064o00000ZLH4tAAH',
    redaptiveOpportunityId: 'R00010892',
    name: 'LIGHT - Access Corp - 6751 Discovery Boulevard South West - Mableton - GA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJw1nAAC',
  },
  {
    externalId: '0064o00000ZLGtgAAH',
    redaptiveOpportunityId: 'R00010880',
    name: 'LIGHT - Access Corp - 3903 Spur Ridge Lane - Bellingham - WA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJvx5AAC',
  },
  {
    externalId: '0064o00000ZLH4jAAH',
    redaptiveOpportunityId: 'R00010891',
    name: 'LIGHT - Access Corp - 799 Georgia Avenue - Gainesville - GA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJw1OAAS',
  },
  {
    externalId: '0064o00000ZLGvmAAH',
    redaptiveOpportunityId: 'R00010884',
    name: 'LIGHT - Access Corp - 1200 Humbracht Circle - Bartlett - IL',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJvxyAAC',
  },
  {
    externalId: '0064o00000ZLGtHAAX',
    redaptiveOpportunityId: 'R00010876',
    name: 'LIGHT - Access Corp - 1165 South Girls School Road - Indianapolis - IN',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJvwlAAC',
  },
  {
    externalId: '0064o00000ZLIoDAAX',
    redaptiveOpportunityId: 'R00010918',
    name: 'LIGHT - Access Corp - 690 East Crescentville Road - West Chester Township - OH',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nKCjaAAG',
  },
  {
    externalId: '0064o00000ZLGtRAAX',
    redaptiveOpportunityId: 'R00010878',
    name: 'LIGHT - Access Corp - 22210 West Sprague Road - Reardon - WA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJvx0AAC',
  },
  {
    externalId: '0064o00000ZLGtMAAX',
    redaptiveOpportunityId: 'R00010877',
    name: 'LIGHT - Access Corp - 4100 West 76th Street - Chicago - IL',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJvwqAAC',
  },
  {
    externalId: '0064o00000ZLIoXAAX',
    redaptiveOpportunityId: 'R00010919',
    name: 'LIGHT - 21806 24th Avenue Suite 120 - Des Moines - WA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nKCl2AAG',
  },
  {
    externalId: '0064o00000ZLGxJAAX',
    redaptiveOpportunityId: 'R00010889',
    name: 'LIGHT - Access Corp - 11248 186th Street - Edmonton - AB',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJughAAC',
  },
  {
    externalId: '0064o00000ZLGxEAAX',
    redaptiveOpportunityId: 'R00010888',
    name: 'LIGHT - Access Corp - 1264 Apollo Way - Santa Rosa - CA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJugcAAC',
  },
  {
    externalId: '0064o00000ZLGtqAAH',
    redaptiveOpportunityId: 'R00010882',
    name: 'LIGHT - Access Corp - 3919 West Washington Street - Phoenix - AZ',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJvxeAAC',
  },
  {
    externalId: '0064o00000ZLGtlAAH',
    redaptiveOpportunityId: 'R00010881',
    name: 'LIGHT - Access Corp - 2640 Willamette Drive Northeast - Lacey - WA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJvxAAAS',
  },
  {
    externalId: '0064o00000ZLH4ZAAX',
    redaptiveOpportunityId: 'R00010890',
    name: 'LIGHT - Access Corp - 5405 Buford Highway - Norcross - GA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'SCOPING',
    salesforceSiteId: '0014o00001nJw14AAC',
  },
  {
    externalId: '0064o00000ZLGx9AAH',
    redaptiveOpportunityId: 'R00010887',
    name: 'LIGHT - Access Corp - 4501 Pell Drive - Sacramento - CA',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0064o00000ZJJfyAAH',
    opportunityStatus: 'NTP_GENERATED',
    salesforceSiteId: '0014o00001nJugXAAS',
  },
].map((opportunitySummary) => ({
  ...opportunitySummary,
  batchAnalysisTotalCount: Math.ceil(Math.random() * 100),
  scenarioTotalCount: Math.ceil(Math.random() * 100),
}))
