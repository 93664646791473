export default [
  {
    id: '3d7c8e3d-56e3-4ef3-9f24-d3eb660e1ea5',
    firstName: 'Oscar',
    lastName: 'Lee',
    postedTime: '2021-12-09T09:49:44.169',
    comment: 'Looks good to me.',
    action: 'REASSIGNED_TO',
    issueOwners: 'Meter Design',
  },
  {
    id: '5a4c8e3d-56e3-4ef3-9f24-d3eb660e352c',
    firstName: 'Susan',
    lastName: 'Fake',
    postedTime: '2021-12-08T08:49:44.169',
    comment: 'This is a changelog comment',
    action: 'ASSIGNED_TO',
    issueOwners: 'Meter Maintenance',
  },
  {
    id: '3d7c8e3d-56e3-4ef3-9f24-d3eb660e1ea6',
    firstName: 'Susan',
    lastName: 'Fake',
    postedTime: '2021-12-08T09:49:44.169',
    comment:
      'Selected non suggested billing action, Actual, due to Meter Issues: this site needs attention',
    action: 'SEND',
    issueOwners: null,
  },
  {
    id: '3d7c8e3d-56e3-4ef3-9f24-d3eb660e1ebz',
    firstName: 'Susan',
    lastName: 'Fake',
    postedTime: '2021-12-06T09:49:44.169',
    comment: 'Everything looks good for this bill',
    action: 'Sent to Approval',
    issueOwners: null,
  },
]

export const commentsByMonthMockData = [
  {
    opportunityId: 'R00000087',
    postedTime: '2024-08-21T14:26:27.952654',
    comment: 'testing',
    userEmail: 'arpana.singh@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000870',
    postedTime: '2024-08-19T04:16:02.116032',
    comment:
      'Low performance is expected considering the historical performance of this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000872',
    postedTime: '2024-08-19T12:13:30.929416',
    comment:
      'We cannot recover the offline meters, and Alex Duven recommends continuing to bill based on estimates going forward. Please follow the Asana task for updates from Alex. https://app.asana.com/0/1206301728093611/1206804674591344/f',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000873',
    postedTime: '2024-08-19T12:45:50.958824',
    comment:
      'The meters are still offline. However, there is ongoing preventative maintenance at the site, and we are actively working with the maintenance partner to recover the meter.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000928',
    postedTime: '2024-08-19T21:42:48.484018',
    comment: 'For approval, <85% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000928',
    postedTime: '2024-08-19T10:02:54.963322',
    comment:
      'The drop in performance is mainly due to low metered burn hours in the last couple of months compared to burn hours in the contract. This site has shown similar behavior in the previous months as well.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000928',
    postedTime: '2024-08-19T04:43:45.496146',
    comment: 'Slightly underperfoming  for the past 2 months.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000933',
    postedTime: '2024-08-19T21:43:47.618036',
    comment: 'For approval, <85% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000933',
    postedTime: '2024-08-19T10:04:53.63582',
    comment:
      'Low performance is expected considering the historical performance of this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000933',
    postedTime: '2024-08-19T04:44:51.668903',
    comment: 'Slight underperformance for the past 2 months',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000934',
    postedTime: '2024-08-19T04:45:30.154256',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00000936',
    postedTime: '2024-08-19T04:45:24.608663',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00001315',
    postedTime: '2024-08-19T21:39:02.383984',
    comment: 'For approval >130% for 2 months',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00001315',
    postedTime: '2024-08-19T09:43:56.216701',
    comment:
      'This site is expected to overperform due to higher metered burn hours than what is specified in the contract.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00001315',
    postedTime: '2024-08-19T04:17:30.793094',
    comment: 'Reason for overperformance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002265',
    postedTime: '2024-08-12T03:58:21.003388',
    comment: 'Performance waive through. Consistently underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002310',
    postedTime: '2024-08-19T04:33:36.731401',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002311',
    postedTime: '2024-08-19T04:33:50.521208',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002535',
    postedTime: '2024-08-19T03:35:18.202129',
    comment:
      'It’s a 100% switch light. The high performance is due to higher burn hours compared to the contract value of ~249 hours.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002571',
    postedTime: '2024-08-19T04:34:02.210213',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002633',
    postedTime: '2024-08-12T04:14:49.284665',
    comment:
      '05/15/2023 - Blanket approval - Agreed on estimated billing going forward by Ashley S.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00002656',
    postedTime: '2024-08-19T04:14:07.006204',
    comment: 'Consitently underperforming ',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003142',
    postedTime: '2024-08-19T04:34:18.350463',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003162',
    postedTime: '2024-08-12T04:14:22.094823',
    comment:
      '05/15/2023 - Blanket approval from Ashley S. - to bill on estimates no meter here - there are meters connected to the HVAC panel but nothing for the lighting circuits. Bill on estimates.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003170',
    postedTime: '2024-08-12T04:13:44.898957',
    comment:
      "Selected non-suggested billing action, ESTIMATE due to Other : This IRM site has two billable meters wrt lighting, as one of them has been abandoned. The site coverage comes down to ~6% with just 2 billable circuits in the meter. Ashley Sutton · Aug 22 - let's use estimated savings for billing - abandon the meter.",
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003180',
    postedTime: '2024-08-19T04:34:30.32787',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003198',
    postedTime: '2024-08-19T04:34:38.662482',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003201',
    postedTime: '2024-08-19T04:37:13.904962',
    comment:
      'We have released an updated ISR. This site has 81% stipulated lighting. The monthly metered burn hours are higher than the post-retrofit burn hours in the contract, resulting in low performance. Based on historical data, this site is expected to continue underperforming',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003215',
    postedTime: '2024-08-19T04:37:48.567526',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003216',
    postedTime: '2024-08-19T04:37:59.644547',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003583',
    postedTime: '2024-08-12T03:47:11.426261',
    comment: 'Performance wave through. Consistently overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003936',
    postedTime: '2024-08-12T03:53:25.35137',
    comment: 'Performance wave through. Consistently underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00003954',
    postedTime: '2024-08-12T03:58:53.797072',
    comment: 'Performance waive through. Consistently underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00004279',
    postedTime: '2024-08-19T04:38:11.223432',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005038',
    postedTime: '2024-08-12T03:44:31.791487',
    comment: 'Performance Wave through - historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005039',
    postedTime: '2024-08-12T03:45:08.006729',
    comment: 'Performance wave through. Consistently overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005040',
    postedTime: '2024-08-19T03:47:18.687157',
    comment:
      'This is a ~95% switch lighting site. High performance is due to higher burn hours. Consistenly overperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005042',
    postedTime: '2024-08-15T00:20:54.011277',
    comment: 'For approval, no data capture. Ref to comments and Asana link',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005042',
    postedTime: '2024-08-14T08:16:24.593219',
    comment:
      'The ISR is still on hold as the as-built is not ready to get uploaded to the database. Waiting for an update from Nathan.  https://app.asana.com/0/1205290714283749/1207654641323077/f',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005298',
    postedTime: '2024-08-19T04:33:26.172909',
    comment: 'Historically underperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005501',
    postedTime: '2024-08-19T04:47:21.710645',
    comment: 'Historically low in performance',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005502',
    postedTime: '2024-08-19T04:47:32.444214',
    comment: 'Historically low in performance',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005920',
    postedTime: '2024-08-19T21:09:53.739867',
    comment: 'This is stipulated billing',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00005920',
    postedTime: '2024-08-19T04:45:55.944785',
    comment:
      'This site has 58% controls; however, the effect of controls was less in June, leading to lower energy savings and performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006126',
    postedTime: '2024-08-12T03:47:46.269155',
    comment: 'Performance wave through. Consistently underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006129',
    postedTime: '2024-08-19T04:15:24.111095',
    comment:
      'Low performance is expected considering the historical performance of this site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006137',
    postedTime: '2024-08-12T04:03:09.992229',
    comment:
      'Selected non-suggested billing action, ESTIMATE due to Other : 8/31/21 - email from Dani Ossenfort - "since the site has never had a problem with billing on estimates, I would say we continue to bill on estimates indefinitely." 10/18/21 email - Andrew and Dani Ossenfort agreed that it will be fine to bill on estimates on any BH site where necessary. (e-mail fwd by Evan on 06/03/22).',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006138',
    postedTime: '2024-08-12T04:00:30.236223',
    comment:
      'Meter is being abandoned in place; the site has been sold and Baker Hughes is no longer the tenant. Andrew Robertson is aware.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006225',
    postedTime: '2024-08-19T04:46:33.16719',
    comment:
      'This is a 60% switch lighting and 40% controlled lighting site. Low in performance is mainly due to lower burn hours. Historically low performing site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006356',
    postedTime: '2024-08-19T10:06:56.621855',
    comment:
      'Low data capture is due to a meter being offline.  A maintenance case has already been created to recover the meter.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006356',
    postedTime: '2024-08-19T04:49:29.000819',
    comment: 'Please provide update on offline meter.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006360',
    postedTime: '2024-08-09T12:03:05.906362',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006362',
    postedTime: '2024-08-09T12:03:05.318037',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006366',
    postedTime: '2024-08-09T12:01:26.894438',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006368',
    postedTime: '2024-08-09T12:03:05.846197',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006372',
    postedTime: '2024-08-09T12:03:05.798037',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006374',
    postedTime: '2024-08-09T12:01:26.945892',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006376',
    postedTime: '2024-08-09T12:03:05.826601',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006380',
    postedTime: '2024-08-09T12:03:05.292947',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006383',
    postedTime: '2024-08-09T12:01:26.909495',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006385',
    postedTime: '2024-08-09T12:03:05.706113',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006603',
    postedTime: '2024-08-19T21:23:57.786004',
    comment: 'For approval, low data capture, ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006603',
    postedTime: '2024-08-19T11:38:37.403194',
    comment:
      'The low data capture and lack of performance calculations are due to the meter being offline. A Preventative Maintenance case has already been created to recover the meter. The updated ISR will be released once the offline maintenance case is resolved to eliminated the abandoned meter.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006604',
    postedTime: '2024-08-19T21:40:58.464995',
    comment: 'For approval, <85% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006604',
    postedTime: '2024-08-19T09:49:34.384544',
    comment:
      'The drop in performance is mainly due to low metered burn hours (301 Hrs.) compared to burn hours in the contract (341 Hrs.).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006604',
    postedTime: '2024-08-19T04:21:49.482932',
    comment: 'Reason for drop in performance',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006952',
    postedTime: '2024-08-19T03:46:01.044583',
    comment: 'Historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00006998',
    postedTime: '2024-08-12T03:45:40.877746',
    comment: 'Performance wave through. Consistently overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007019',
    postedTime: '2024-08-19T03:36:21.144607',
    comment: 'Overperforming site since COD 03/2023',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007049',
    postedTime: '2024-08-19T03:57:12.778254',
    comment: 'Historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007081',
    postedTime: '2024-08-19T03:45:25.209021',
    comment:
      ' evelyn ona 18 Jul 7:01AM This site has been historically overperforming.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007089',
    postedTime: '2024-08-09T12:03:05.335691',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007119',
    postedTime: '2024-08-09T12:03:05.941708',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007122',
    postedTime: '2024-08-09T12:03:05.349937',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007133',
    postedTime: '2024-08-09T12:03:05.365072',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007144',
    postedTime: '2024-08-09T12:03:05.406102',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007152',
    postedTime: '2024-08-09T12:01:26.914906',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007162',
    postedTime: '2024-08-09T12:03:05.436136',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007165',
    postedTime: '2024-08-09T12:03:05.451713',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007170',
    postedTime: '2024-08-09T12:03:05.386117',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007177',
    postedTime: '2024-08-09T12:01:26.940909',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007182',
    postedTime: '2024-08-09T12:01:26.919551',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007184',
    postedTime: '2024-08-09T12:01:26.925514',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007188',
    postedTime: '2024-08-09T12:03:05.421884',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007234',
    postedTime: '2024-08-19T21:10:18.972953',
    comment: 'This is stipulated billing',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007234',
    postedTime: '2024-08-19T21:08:49.46625',
    comment: 'This is Stipulated',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007234',
    postedTime: '2024-08-19T04:46:13.001113',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007290',
    postedTime: '2024-08-14T09:31:34.375382',
    comment:
      ' The drop in performance is mainly due to low metered burn hours (335 Hrs.) compared to burn hours in the contract (396 Hrs.).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007294',
    postedTime: '2024-08-19T03:43:29.760134',
    comment: 'Consistently overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007295',
    postedTime: '2024-08-19T04:12:40.62076',
    comment: 'Overperforming for the past 3 months.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007304',
    postedTime: '2024-08-14T12:26:15.660193',
    comment:
      'Low data capture is due to few meters being offline for a few days in July. The meters are now working normally and the data capture is expected to improve in Aug-24',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007378',
    postedTime: '2024-08-09T12:03:05.467577',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007381',
    postedTime: '2024-08-09T12:03:05.487659',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007383',
    postedTime: '2024-08-09T12:03:05.503581',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007388',
    postedTime: '2024-08-09T12:03:05.517642',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007390',
    postedTime: '2024-08-09T12:03:05.53384',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007391',
    postedTime: '2024-08-09T12:03:05.554017',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007394',
    postedTime: '2024-08-09T12:03:05.781997',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007398',
    postedTime: '2024-08-09T12:01:26.930559',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007400',
    postedTime: '2024-08-09T12:03:05.726106',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007403',
    postedTime: '2024-08-09T12:03:05.598141',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007404',
    postedTime: '2024-08-09T12:01:26.935793',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007405',
    postedTime: '2024-08-09T12:03:05.866171',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007411',
    postedTime: '2024-08-09T12:03:05.742164',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007414',
    postedTime: '2024-08-09T12:03:05.962206',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007436',
    postedTime: '2024-08-09T12:03:05.685336',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007437',
    postedTime: '2024-08-09T12:03:05.618023',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007440',
    postedTime: '2024-08-09T12:03:05.807003',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007472',
    postedTime: '2024-08-09T12:03:05.926236',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007483',
    postedTime: '2024-08-09T12:03:05.762254',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007495',
    postedTime: '2024-08-09T12:03:05.638293',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007521',
    postedTime: '2024-08-09T12:03:05.978106',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007529',
    postedTime: '2024-08-09T12:01:26.951092',
    comment: 'IRM RTU - 10 Opportunities to bill on estimates',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007535',
    postedTime: '2024-08-09T12:03:06.001813',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007536',
    postedTime: '2024-08-09T12:03:05.657951',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007596',
    postedTime: '2024-08-19T04:19:43.448087',
    comment:
      'This is a 100% switch lighting site. The high performance is due to higher burn hours.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007596',
    postedTime: '2024-08-19T04:18:10.917878',
    comment:
      'This is a 100% switch lighting site. The high performance is due to higher burn hours.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007597',
    postedTime: '2024-08-19T04:20:03.334627',
    comment:
      'Low performance is expected considering the historical performance of this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007597',
    postedTime: '2024-08-19T04:18:26.101603',
    comment:
      'Low performance is expected considering the historical performance of this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007712',
    postedTime: '2024-08-12T03:59:55.16396',
    comment:
      '8/17/22 Andrew Robertson - Please continue to bill on estimates forever. There is no reason to ask about this site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00007807',
    postedTime: '2024-08-09T12:03:05.678159',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00008313',
    postedTime: '2024-08-19T04:15:43.137241',
    comment: 'Historically low in performan',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009196',
    postedTime: '2024-08-09T12:03:05.88157',
    comment: 'IRM RTU - 39 Opportunities to bill on actuals.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009258',
    postedTime: '2024-08-19T21:39:57.192255',
    comment: 'For approval, >130% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009258',
    postedTime: '2024-08-19T09:47:36.766984',
    comment:
      'The slight overperform is due to higher metered burn hours (510 hrs) than what is specified in the contract (354 hrs).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009258',
    postedTime: '2024-08-19T04:19:10.406359',
    comment: 'Reason for overperformance this month.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009304',
    postedTime: '2024-08-19T02:33:06.211074',
    comment: 'consistently overperfoming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009348',
    postedTime: '2024-08-19T21:26:16.815102',
    comment: 'For approval, low data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009348',
    postedTime: '2024-08-19T10:49:16.121738',
    comment:
      'All the meters are now back online, but data capture for August 2024 will still be lower due to a few offline days earlier in the month.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009349',
    postedTime: '2024-08-19T04:38:46.544689',
    comment: 'Historically underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009350',
    postedTime: '2024-08-19T04:39:07.542309',
    comment:
      'Low performance is expected considering the historical performance of this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009409',
    postedTime: '2024-08-19T04:20:17.994684',
    comment: 'Underperforming site since COD 04/2023',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009516',
    postedTime: '2024-08-19T04:39:50.912264',
    comment:
      'No meters are offline at this site and it’s 100% switch light. The low performance is due to fewer burn hours compared to the contract value of 730 hours. Considering the historical data the average performance of this site is ~66%, so we can expect underperformance in the coming months as well due to low burn hours.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009678',
    postedTime: '2024-08-19T03:45:45.292351',
    comment:
      'Higher performance is expected considering the historical performance of this site. The burn hours are higher than the contracted burn hours, leading to the high performance',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009744',
    postedTime: '2024-08-14T09:20:46.792929',
    comment:
      'The drop in performance is mainly due to low metered burn hours (297 hours) compared to the contract burn hours (401 hours).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009789',
    postedTime: '2024-08-19T02:55:54.471041',
    comment: 'For approval, <85% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009789',
    postedTime: '2024-08-14T11:36:58.590701',
    comment:
      'The drop in performance is mainly due to low metered burn hours (350 Hrs.) compared to burn hours in the contract (578 Hrs.).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009803',
    postedTime: '2024-08-19T03:36:00.76037',
    comment:
      ' evelyn ona 18 Jul 9:22AM Consitently overperforming site from 01/2024',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009818',
    postedTime: '2024-08-14T11:46:35.626181',
    comment:
      'The drop in performance is mainly due to low metered burn hours (245 Hrs.) compared to burn hours in the contract (365 Hrs.)',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009821',
    postedTime: '2024-08-19T03:46:54.455567',
    comment: 'Overperforming site since Dec23.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00009927',
    postedTime: '2024-08-19T03:43:43.356459',
    comment: 'Consistently overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010268',
    postedTime: '2024-08-19T04:25:23.75872',
    comment:
      'Low performance is expected considering the low energy consumption at this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010269',
    postedTime: '2024-08-19T04:31:10.788138',
    comment: 'Historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010270',
    postedTime: '2024-08-19T03:44:03.560912',
    comment: 'Historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010336',
    postedTime: '2024-08-14T14:00:44.335451',
    comment: 'Issue with Solar Inverter communication & Solar Irradiation.',
    userEmail: 'chaitanya.thombare@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010336',
    postedTime: '2024-08-14T13:58:20.898349',
    comment: 'Issue with Solar Inverter communication & Solar Irradiation. ',
    userEmail: 'chaitanya.thombare@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010528',
    postedTime: '2024-08-19T21:27:48.230333',
    comment: 'For approval, low data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010528',
    postedTime: '2024-08-19T10:43:33.407297',
    comment:
      'The low data capture and lack of performance calculations are due to the meter being offline. A maintenance case has already been created to recover the meter, and an electrician has been scheduled for the maintenance.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010548',
    postedTime: '2024-08-19T21:19:11.670328',
    comment:
      'For approval, offline meters. Ref to comments and Asana link https://app.asana.com/0/1206301728093611/1206804674591346/\n',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00010778',
    postedTime: '2024-08-16T11:43:13.758865',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011170',
    postedTime: '2024-08-19T03:56:20.752801',
    comment: 'Consitently underperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011431',
    postedTime: '2024-08-16T12:29:42.723412',
    comment:
      'Low performance is expected considering the historical performance of this site. The burn hours are lower than the contracted burn hours, leading to the low performance.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011461',
    postedTime: '2024-08-14T14:44:11.084395',
    comment:
      'The site is expected to underperform considering the historical energy consumption and reduction in metered burn hours.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011555',
    postedTime: '2024-08-19T04:47:51.834705',
    comment:
      'Low performing site since COD 04/2023. the site has 36% switch lighting and 64% control lighting.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011563',
    postedTime: '2024-08-19T04:33:13.805567',
    comment: 'Consistently low in performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011776',
    postedTime: '2024-08-19T04:47:02.697103',
    comment:
      'The low in performance is mainly due to low metered burn hours compared to burn hours in the contract (266 Hrs.).',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011777',
    postedTime: '2024-08-19T03:07:35.664643',
    comment: 'Fo approval, low data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011777',
    postedTime: '2024-08-16T12:47:53.069951',
    comment:
      'The low data capture and lack of performance calculations are due to the meter being offline. A maintenance case has already been created to recover the meter.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011786',
    postedTime: '2024-08-19T09:56:31.194302',
    comment:
      'Low data capture is due to a meter being offline for a few days in July. The meter is now working normally and the data capture is expected to improve in Aug-24.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011786',
    postedTime: '2024-08-19T04:41:24.281313',
    comment: 'Meter back online but data capture still low than expected.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011803',
    postedTime: '2024-08-12T04:01:26.859626',
    comment:
      '06/21/2022 - E-mail approval from Dallas Gamble to continue billing on actuals',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011993',
    postedTime: '2024-08-19T03:44:35.932759',
    comment:
      'Considering previous months consumption, we can expect high performance in coming months as well due to higher burn hours compared to the contract.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011995',
    postedTime: '2024-08-16T12:33:24.747591',
    comment:
      'Low performance is expected considering the historical performance of this site especially because of -15% reduction in kW savings.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00011997',
    postedTime: '2024-08-19T04:32:40.619019',
    comment: 'Please provide update on issue below.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012012',
    postedTime: '2024-08-19T03:06:33.428358',
    comment: 'For approval, no data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012012',
    postedTime: '2024-08-16T12:38:53.924069',
    comment:
      'The ISR is still on hold as the meter installation is currently in progress.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012092',
    postedTime: '2024-08-19T02:53:18.710098',
    comment: 'For approval. Ref to comments and Asana link.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012092',
    postedTime: '2024-08-14T09:28:04.158089',
    comment:
      'Considering the installation date, we will be able to release the ISR only in the last week of August to ensure we have sufficient data. Attaching the Asana task link: https://app.asana.com/0/1201236664889797/1206430834279909/f',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012579',
    postedTime: '2024-08-19T02:54:57.828391',
    comment: 'For approval, re to commnets.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012579',
    postedTime: '2024-08-14T11:23:05.813729',
    comment:
      'The drop in performance is mainly due to low metered burn hours compared to burn hours in the contract. The site is expected to underperform.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012669',
    postedTime: '2024-08-19T21:11:43.893144',
    comment: 'No data capture. First bill.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012791',
    postedTime: '2024-08-19T03:33:50.027353',
    comment: 'Historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012997',
    postedTime: '2024-08-16T11:44:40.488336',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00012999',
    postedTime: '2024-08-16T11:44:40.509219',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013002',
    postedTime: '2024-08-16T11:44:40.519226',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013003',
    postedTime: '2024-08-16T11:44:40.506131',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013004',
    postedTime: '2024-08-16T11:44:40.56132',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013005',
    postedTime: '2024-08-16T11:44:40.537143',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013011',
    postedTime: '2024-08-16T11:44:40.502769',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013012',
    postedTime: '2024-08-16T11:44:40.564223',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013013',
    postedTime: '2024-08-16T11:44:40.574098',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013014',
    postedTime: '2024-08-16T11:44:40.551471',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013015',
    postedTime: '2024-08-16T11:44:40.567198',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013016',
    postedTime: '2024-08-16T11:44:40.54067',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013017',
    postedTime: '2024-08-16T11:44:40.554574',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013018',
    postedTime: '2024-08-16T11:44:40.548526',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013020',
    postedTime: '2024-08-16T11:44:40.492811',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013021',
    postedTime: '2024-08-16T11:44:40.512855',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013024',
    postedTime: '2024-08-16T11:44:40.516263',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013059',
    postedTime: '2024-08-19T03:42:44.874129',
    comment:
      'We can expect continuous overperformance as the monthly burn hours are greater that the burn hours in the contract (199 Hrs).',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013060',
    postedTime: '2024-08-19T02:31:39.211025',
    comment: 'For approval, ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013060',
    postedTime: '2024-08-14T08:40:42.53886',
    comment: 'Meters are back online. ISR will be released for Aug-24.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013074',
    postedTime: '2024-08-14T11:39:11.720223',
    comment:
      'Low performance is due to reduction in burn hours. The contract burn hours are 566 but monthly burn hours are below it, resulting in low site performance.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013075',
    postedTime: '2024-08-19T03:02:12.469751',
    comment: 'For approval, no data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013075',
    postedTime: '2024-08-14T12:20:38.967784',
    comment:
      'We are in the process of installing a new meter to improve site coverage. The ISR remains on hold until the installation is complete.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013109',
    postedTime: '2024-08-16T11:44:40.545349',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013110',
    postedTime: '2024-08-16T11:44:40.533143',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013111',
    postedTime: '2024-08-16T11:44:40.570545',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013112',
    postedTime: '2024-08-16T11:44:40.558302',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013180',
    postedTime: '2024-08-14T12:17:50.701093',
    comment:
      'The drop in performance is mainly due to low metered burn hours compared to burn hours estimated in the contract.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013181',
    postedTime: '2024-08-19T03:42:21.611678',
    comment:
      't’s a 100% switch lighting site. We can expect high performance in the coming months as well. The high performance is due to higher burn hours compared to the contract value.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013182',
    postedTime: '2024-08-19T02:56:33.352628',
    comment: 'For approval, ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013182',
    postedTime: '2024-08-14T11:43:35.648719',
    comment:
      'The meter is still offline, so the savings are not calculated. After completing the PM case, the meters are expected to come back online.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013184',
    postedTime: '2024-08-19T03:33:09.561687',
    comment: 'Consistently overperforming site.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013255',
    postedTime: '2024-08-19T03:00:41.474057',
    comment:
      'For approval, low data capture, no actual performance savings. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013255',
    postedTime: '2024-08-14T11:58:16.870043',
    comment:
      'Two meters are offline leading to low data capture. We are currently working actively to recover the offline meters.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013304',
    postedTime: '2024-08-16T11:44:40.497668',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013305',
    postedTime: '2024-08-16T11:44:40.522233',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013326',
    postedTime: '2024-08-16T11:44:40.525594',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013545',
    postedTime: '2024-08-14T11:21:25.404175',
    comment:
      'Low data capture is due to the meter being offline for a few days in July. The meters are now working normally. However, due to a 20% decrease in kW savings in the as-built compared to the contract, the site is expected to underperform.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013560',
    postedTime: '2024-08-19T03:36:41.227833',
    comment:
      'we can expect high performance in the coming months as well. The high performance is due to higher burn hours compared to the contract value.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013632',
    postedTime: '2024-08-19T02:32:27.710391',
    comment: 'Rof approval >130% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013632',
    postedTime: '2024-08-14T09:15:01.935222',
    comment:
      'This site is expected to overperform due to higher metered burn hours than what is specified in the contract.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013633',
    postedTime: '2024-08-16T11:44:40.528846',
    comment: 'BofA JLL 25 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013638',
    postedTime: '2024-08-14T14:38:31.75908',
    comment:
      'Low data capture is due to few meters being offline for a few days in July. The meters are now working normally and the data capture is expected to improve in Aug-24. \nLow performance is due to less effect of controls and this site is expected to underperform.\n',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013638',
    postedTime: '2024-08-14T12:42:59.443691',
    comment: 'Low capture?',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013720',
    postedTime: '2024-08-14T11:54:19.112301',
    comment:
      'Low data capture was due to multiple meters being offline in July. Now meters are showing good data capture. The high performance is due to higher metered burn hours.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013843',
    postedTime: '2024-08-16T11:43:13.628441',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013844',
    postedTime: '2024-08-16T11:43:13.371648',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013845',
    postedTime: '2024-08-16T11:43:13.244508',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013847',
    postedTime: '2024-08-16T11:43:13.548608',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013849',
    postedTime: '2024-08-16T11:43:13.591432',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013850',
    postedTime: '2024-08-16T11:43:13.794999',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013851',
    postedTime: '2024-08-16T11:43:13.467343',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013855',
    postedTime: '2024-08-16T11:43:13.605334',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013856',
    postedTime: '2024-08-16T11:43:13.789198',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013859',
    postedTime: '2024-08-16T11:43:13.416827',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013860',
    postedTime: '2024-08-16T11:43:13.357785',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013861',
    postedTime: '2024-08-16T11:43:13.487776',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013862',
    postedTime: '2024-08-16T11:43:13.268138',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013863',
    postedTime: '2024-08-16T11:43:13.259416',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013864',
    postedTime: '2024-08-16T11:43:13.652179',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013865',
    postedTime: '2024-08-16T11:43:13.637519',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013867',
    postedTime: '2024-08-16T11:43:13.608737',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013868',
    postedTime: '2024-08-16T11:43:13.236579',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013870',
    postedTime: '2024-08-16T11:43:13.387355',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013872',
    postedTime: '2024-08-16T11:43:13.807609',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013873',
    postedTime: '2024-08-16T11:43:13.344398',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013874',
    postedTime: '2024-08-16T11:43:13.731616',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013875',
    postedTime: '2024-08-16T11:43:13.255422',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013876',
    postedTime: '2024-08-16T11:43:13.559368',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013877',
    postedTime: '2024-08-16T11:43:13.511182',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013878',
    postedTime: '2024-08-16T11:43:13.672619',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013879',
    postedTime: '2024-08-16T11:43:13.440703',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013880',
    postedTime: '2024-08-16T11:43:13.773478',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013881',
    postedTime: '2024-08-16T11:43:13.598772',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013883',
    postedTime: '2024-08-16T11:43:13.762851',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013886',
    postedTime: '2024-08-16T11:43:13.804612',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013889',
    postedTime: '2024-08-16T11:43:13.535444',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013890',
    postedTime: '2024-08-16T11:43:13.755774',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013891',
    postedTime: '2024-08-16T11:43:13.573605',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013894',
    postedTime: '2024-08-16T11:43:13.401898',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013895',
    postedTime: '2024-08-16T11:43:13.280188',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013896',
    postedTime: '2024-08-16T11:43:13.310475',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013901',
    postedTime: '2024-08-16T11:43:13.825699',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013902',
    postedTime: '2024-08-16T11:43:13.263549',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013903',
    postedTime: '2024-08-16T11:43:13.641097',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013905',
    postedTime: '2024-08-16T11:43:13.43514',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013907',
    postedTime: '2024-08-16T11:43:13.556311',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013909',
    postedTime: '2024-08-16T11:43:13.56683',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013913',
    postedTime: '2024-08-16T11:43:13.822325',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013914',
    postedTime: '2024-08-16T11:43:13.779607',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013915',
    postedTime: '2024-08-16T11:43:13.375686',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013916',
    postedTime: '2024-08-16T11:43:13.538512',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013917',
    postedTime: '2024-08-16T11:43:13.685866',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013918',
    postedTime: '2024-08-16T11:43:13.350085',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013921',
    postedTime: '2024-08-16T11:43:13.580606',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013922',
    postedTime: '2024-08-16T11:43:13.782381',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013925',
    postedTime: '2024-08-16T11:43:13.501308',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013926',
    postedTime: '2024-08-16T11:43:13.631302',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013930',
    postedTime: '2024-08-16T11:43:13.745181',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013932',
    postedTime: '2024-08-16T11:43:13.524816',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013936',
    postedTime: '2024-08-16T11:43:13.322224',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013941',
    postedTime: '2024-08-16T11:43:13.329965',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013942',
    postedTime: '2024-08-16T11:43:13.61218',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013944',
    postedTime: '2024-08-16T11:43:13.615612',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013947',
    postedTime: '2024-08-16T11:43:13.625035',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013958',
    postedTime: '2024-08-16T11:43:13.481379',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013966',
    postedTime: '2024-08-16T11:43:13.2325',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013969',
    postedTime: '2024-08-16T11:43:13.25174',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013975',
    postedTime: '2024-08-16T11:43:13.31455',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013979',
    postedTime: '2024-08-16T11:43:13.514697',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013981',
    postedTime: '2024-08-16T11:43:13.307357',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013982',
    postedTime: '2024-08-16T11:43:13.354306',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013985',
    postedTime: '2024-08-16T11:43:13.291983',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013986',
    postedTime: '2024-08-16T11:43:13.644423',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013987',
    postedTime: '2024-08-16T11:43:13.390834',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013988',
    postedTime: '2024-08-16T11:43:13.333457',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013992',
    postedTime: '2024-08-16T11:43:13.544879',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013993',
    postedTime: '2024-08-16T11:43:13.70703',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013995',
    postedTime: '2024-08-16T11:43:13.776196',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013996',
    postedTime: '2024-08-16T11:43:13.710617',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013997',
    postedTime: '2024-08-16T11:43:13.457444',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013998',
    postedTime: '2024-08-16T11:43:13.48471',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00013999',
    postedTime: '2024-08-16T11:43:13.21533',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014000',
    postedTime: '2024-08-16T11:43:13.507593',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014003',
    postedTime: '2024-08-16T11:43:13.461104',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014006',
    postedTime: '2024-08-16T11:43:13.2955',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014059',
    postedTime: '2024-08-14T12:00:35.917876',
    comment:
      'Site is expected to overperform due to higher effect of controls.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014619',
    postedTime: '2024-08-16T11:43:13.521294',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014621',
    postedTime: '2024-08-16T11:43:13.577433',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014622',
    postedTime: '2024-08-16T11:43:13.770124',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014623',
    postedTime: '2024-08-16T11:43:13.413172',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014627',
    postedTime: '2024-08-16T11:43:13.336401',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014629',
    postedTime: '2024-08-16T11:43:13.477902',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014631',
    postedTime: '2024-08-16T11:43:13.532255',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014632',
    postedTime: '2024-08-16T11:43:13.379416',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014633',
    postedTime: '2024-08-16T11:43:13.734858',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014634',
    postedTime: '2024-08-16T11:43:13.420954',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014635',
    postedTime: '2024-08-16T11:43:13.662349',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014636',
    postedTime: '2024-08-16T11:43:13.498386',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014637',
    postedTime: '2024-08-16T11:43:13.398223',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014638',
    postedTime: '2024-08-16T11:43:13.445761',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014639',
    postedTime: '2024-08-16T11:43:13.798511',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014640',
    postedTime: '2024-08-16T11:43:13.275952',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014641',
    postedTime: '2024-08-16T11:43:13.22407',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014642',
    postedTime: '2024-08-16T11:43:13.813887',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014643',
    postedTime: '2024-08-16T11:43:13.727938',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014648',
    postedTime: '2024-08-16T11:43:13.648084',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014650',
    postedTime: '2024-08-16T11:43:13.326083',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014651',
    postedTime: '2024-08-16T11:43:13.428809',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014652',
    postedTime: '2024-08-16T11:43:13.690445',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014653',
    postedTime: '2024-08-16T11:43:13.383544',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014655',
    postedTime: '2024-08-16T11:43:13.552565',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014656',
    postedTime: '2024-08-16T11:43:13.738055',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014658',
    postedTime: '2024-08-16T11:43:13.669183',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014662',
    postedTime: '2024-08-16T11:43:13.655786',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014663',
    postedTime: '2024-08-16T11:43:13.703992',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014664',
    postedTime: '2024-08-16T11:43:13.470589',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014666',
    postedTime: '2024-08-16T11:43:13.682504',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014668',
    postedTime: '2024-08-16T11:43:13.504262',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014669',
    postedTime: '2024-08-16T11:43:13.595217',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00014685',
    postedTime: '2024-08-19T03:34:34.106719',
    comment: 'Consistently high in performance.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015412',
    postedTime: '2024-08-16T11:43:13.451899',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015413',
    postedTime: '2024-08-16T11:43:13.303403',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015414',
    postedTime: '2024-08-16T11:43:13.622216',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015415',
    postedTime: '2024-08-16T11:43:13.810485',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015416',
    postedTime: '2024-08-16T11:43:13.766364',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015418',
    postedTime: '2024-08-16T11:43:13.724334',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015419',
    postedTime: '2024-08-16T11:43:13.792084',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015420',
    postedTime: '2024-08-16T11:43:13.528496',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015421',
    postedTime: '2024-08-16T11:43:13.491466',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015422',
    postedTime: '2024-08-16T11:43:13.288146',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015423',
    postedTime: '2024-08-16T11:43:13.819527',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015424',
    postedTime: '2024-08-16T11:43:13.717283',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015426',
    postedTime: '2024-08-16T11:43:13.563653',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015427',
    postedTime: '2024-08-16T11:43:13.424525',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015428',
    postedTime: '2024-08-16T11:43:13.720487',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00015429',
    postedTime: '2024-08-16T11:43:13.785836',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00016300',
    postedTime: '2024-08-19T03:45:00.377703',
    comment: 'overperforming since COD 12/01/2023',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00016318',
    postedTime: '2024-08-14T08:36:04.472982',
    comment:
      'Due to a 58% decrease in kW savings in the as-built compared to the contract, the site is expected to underperform.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00016320',
    postedTime: '2024-08-14T09:25:07.634463',
    comment:
      'This is a 100% switch lighting site. The drop in performance is mainly due to the lower metered burn hours compared to the estimates in the contract.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00016365',
    postedTime: '2024-08-19T21:11:04.943635',
    comment: 'No data capture. First bill.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00016793',
    postedTime: '2024-08-19T03:34:18.942386',
    comment: 'Historically overperforming site',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00016992',
    postedTime: '2024-08-14T08:33:37.33535',
    comment:
      'This site is expected to overperform due to higher metered burn hours than what is specified in the contract.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00017002',
    postedTime: '2024-08-15T00:32:26.696999',
    comment: 'For approval. >130% for 2 months. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00017002',
    postedTime: '2024-08-14T08:19:37.749596',
    comment:
      'The increase in performance is mainly due to higher metered burn hours (417 Hrs.) compared to burn hours in the contract (269 Hrs.).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00017579',
    postedTime: '2024-08-19T03:35:37.787655',
    comment: 'Overperforming since first actual saving month - Nov23',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019339',
    postedTime: '2024-08-16T11:43:13.364894',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019340',
    postedTime: '2024-08-16T11:43:13.679318',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019341',
    postedTime: '2024-08-16T11:43:13.693576',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019343',
    postedTime: '2024-08-16T11:43:13.816675',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019344',
    postedTime: '2024-08-16T11:43:13.318075',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019345',
    postedTime: '2024-08-16T11:43:13.752032',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019347',
    postedTime: '2024-08-16T11:43:13.587972',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019348',
    postedTime: '2024-08-16T11:43:13.741563',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019349',
    postedTime: '2024-08-16T11:43:13.449126',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019350',
    postedTime: '2024-08-16T11:43:13.394442',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019351',
    postedTime: '2024-08-16T11:43:13.541691',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019352',
    postedTime: '2024-08-16T11:43:13.602076',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019353',
    postedTime: '2024-08-16T11:43:13.219335',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019356',
    postedTime: '2024-08-16T11:43:13.634098',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019361',
    postedTime: '2024-08-16T11:43:13.665435',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019365',
    postedTime: '2024-08-16T11:43:13.211283',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019366',
    postedTime: '2024-08-16T11:43:13.618836',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019367',
    postedTime: '2024-08-16T11:43:13.228988',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019369',
    postedTime: '2024-08-16T11:43:13.696849',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019370',
    postedTime: '2024-08-16T11:43:13.248303',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019372',
    postedTime: '2024-08-16T11:43:13.700393',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019376',
    postedTime: '2024-08-16T11:43:13.464467',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019381',
    postedTime: '2024-08-16T11:43:13.368345',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019384',
    postedTime: '2024-08-16T11:43:13.474114',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019386',
    postedTime: '2024-08-16T11:43:13.570005',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019387',
    postedTime: '2024-08-16T11:43:13.409382',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019388',
    postedTime: '2024-08-16T11:43:13.454604',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019389',
    postedTime: '2024-08-16T11:43:13.583958',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019390',
    postedTime: '2024-08-16T11:43:13.284231',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019391',
    postedTime: '2024-08-16T11:43:13.431996',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019395',
    postedTime: '2024-08-16T11:43:13.713742',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00019405',
    postedTime: '2024-08-16T11:43:13.272388',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020288',
    postedTime: '2024-08-15T00:33:40.633511',
    comment:
      'Low data capture but overperforming for the past 2 months. Ref to comments',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020288',
    postedTime: '2024-08-14T08:25:30.315739',
    comment:
      'Low data capture is due to an offline meter and we are currently working on the maintenance case. The increase in performance is mainly due to higher metered burn hours (537 Hrs.) compared to burn hours in the contract (299 Hrs.).',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020528',
    postedTime: '2024-08-16T11:43:13.200045',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020537',
    postedTime: '2024-08-16T11:43:13.658794',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020592',
    postedTime: '2024-08-16T11:43:13.207214',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020593',
    postedTime: '2024-08-16T11:43:13.240736',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020594',
    postedTime: '2024-08-16T11:43:13.748302',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020596',
    postedTime: '2024-08-16T11:43:13.801772',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020597',
    postedTime: '2024-08-16T11:43:13.405639',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020598',
    postedTime: '2024-08-16T11:43:13.517791',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020906',
    postedTime: '2024-08-16T11:43:13.361237',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00020907',
    postedTime: '2024-08-16T11:43:13.675741',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00021067',
    postedTime: '2024-08-16T11:43:13.299189',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00021068',
    postedTime: '2024-08-16T11:43:13.495062',
    comment: 'BofA CBRE, 175 sites to bill on actuals.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00021755',
    postedTime: '2024-08-19T21:24:37.555734',
    comment: 'No data capture. First Bill.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00021756',
    postedTime: '2024-08-19T21:22:54.214358',
    comment: 'For approval, low data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00021757',
    postedTime: '2024-08-14T08:37:55.816924',
    comment:
      'We can expect slightly higher performance at this site due to the greater effect of controls.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
  {
    opportunityId: 'R00023304',
    postedTime: '2024-08-15T00:35:05.416893',
    comment: 'For approval, no data capture. Ref to comments.',
    userEmail: 'evelyn.ona@redaptiveinc.com',
  },
  {
    opportunityId: 'R00023304',
    postedTime: '2024-08-14T08:32:03.914366',
    comment:
      'The meter is still offline, and the ISR will be on hold until it is back online.',
    userEmail: 'vishnu.sasi@redaptiveinc.com',
  },
]
