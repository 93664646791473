import { ReactNode } from 'react'
import styled from 'styled-components'

import Button from './Button'
import ErrorMessage from './ErrorMessage'
import { SpinnerStyles } from './Spinner'

export const CardStyles = styled.div`
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  margin: 60px auto auto;
  min-height: 155px;
  padding: 20px 35px;
  text-align: center;
  width: 478px;

  ${SpinnerStyles} {
    margin: 0 auto;
  }
`
export const TitleStyles = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
`
export const DescriptionStyles = styled.div`
  font-size: 14px;
  height: 80px;
  line-height: 20px;

  b {
    font-weight: 600;
  }
`
const ButtonStyles = styled(Button)`
  margin-top: 16px;
  width: 100%;
`
const ErrorStyles = styled.div`
  font-size: 14px;
  margin-top: 10px;
`
type FTProps = {
  buttonText: string
  buttonDisabled?: boolean
  buttonLoading?: boolean
  description: ReactNode
  error?: string | null | undefined
  handleClick: (...args: Array<any>) => any
  title: string
}

const ActionCard = (props: FTProps) => {
  const {
    buttonDisabled,
    buttonLoading,
    buttonText,
    description,
    error,
    handleClick,
    title,
  } = props
  return (
    <CardStyles>
      <TitleStyles>{title}</TitleStyles>
      {error && (
        <ErrorStyles>
          <ErrorMessage message={error} />
        </ErrorStyles>
      )}
      <DescriptionStyles>{description}</DescriptionStyles>
      <ButtonStyles
        disabled={buttonDisabled}
        loading={buttonLoading}
        primary
        onClick={handleClick}
      >
        {buttonText}
      </ButtonStyles>
    </CardStyles>
  )
}

ActionCard.defaultProps = {
  buttonDisabled: false,
  buttonLoading: false,
  error: '',
}
export default ActionCard
