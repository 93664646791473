import classNames from 'classnames'
import * as React from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import TabContent from './TabContent'
import type { FTRouterMatch } from '../../types'
import './TabPane.css'
import { getThemeFromPathname } from '../../utils/themes'
import CheckBoxIcon from '../Icons/CheckBoxIcon'

type FTTab = {
  tab: string
  text: string
  title?: string
  total?: number
  meta?: string
  render: () => React.ReactNode
  rightHandLink?: {
    href: string
    text: string
    withDefaultTab?: boolean
  }
}
type FTProps = {
  tabs: Array<FTTab>
} & FTRouterMatch

class TabPane extends React.PureComponent<FTProps> {
  renderRightHandLink = (tab, activeTab, link) => {
    if (!link) {
      return null
    }

    const { href, text, withDefaultTab } = link

    if ((!activeTab && !withDefaultTab) || tab !== activeTab) {
      return null
    }

    return (
      <div className='TabPane-right-link'>
        <Link href={href} to={href}>
          {text}
        </Link>
      </div>
    )
  }

  renderPane = (props: { match: FTRouterMatch }) => {
    const {
      match: {
        params: { tab: currentTab },
      },
    } = props
    const {
      tabs,
      match: { url: outerUrl },
      location: { pathname },
    } = this.props
    const activeTab =
      (currentTab && tabs.find(({ tab }) => tab === currentTab)) || tabs[0]
    const theme = getThemeFromPathname(pathname)
    const isRedaptive2Theme = theme === 'redaptive2'
    return (
      <div className='TabPane'>
        <div className='TabPane-selector'>
          {tabs.map(({ text, tab, rightHandLink, total }, index) => {
            const active = tab === activeTab.tab
            const href = `${outerUrl}/${tab}`
            const isChecked = total === 0
            return (
              <div key={href}>
                <div
                  className={classNames({
                    'TabPane-item': true,
                    active,
                    'TabPane-RedaptiveTheme-item-margin':
                      isRedaptive2Theme && !index,
                    'TabPane-RedaptiveTheme-item-styles': isRedaptive2Theme,
                    'TabPane-RedaptiveTheme-item-not-active':
                      isRedaptive2Theme && !active,
                  })}
                >
                  {!active && (
                    <div>
                      <Link href={href} to={href}>
                        {text}
                      </Link>
                    </div>
                  )}
                  {active && text}
                  {isRedaptive2Theme && !isChecked && (
                    <div
                      className={classNames({
                        'TabPane-item-total': true,
                      })}
                    >
                      {total}
                    </div>
                  )}
                  {isRedaptive2Theme && isChecked && (
                    <div className='box-checked'>
                      <CheckBoxIcon />
                    </div>
                  )}
                </div>
                {this.renderRightHandLink(tab, activeTab.tab, rightHandLink)}
              </div>
            )
          })}
        </div>
        <TabContent
          title={activeTab.title}
          meta={activeTab.meta}
          className='TabPane-body'
        >
          {activeTab.render()}
        </TabContent>
      </div>
    )
  }

  render() {
    const {
      match: { path },
    } = this.props
    return <Route path={`${path}/:tab?`} render={this.renderPane} />
  }
}

export default withRouter(TabPane)
