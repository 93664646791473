import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { call, put, takeEvery } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders, financeApiUrl } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { isVariantActive } from '../utils'

export type FTFetchLightingAsBuiltsAssetUploadJobAction = {
  jobId: string
}
export type FTFetchLightingAsBuiltsAssetUploadJobMockAction = {
  jobId: string
  mockAxios: Record<string, any>
}
export type FTPostLightingAsBuiltsAssetAction = {
  file: any
}
type FTLightingAsBuiltsAssetUploadError = {
  message: string
}
type FTLightingAsBuiltsAssetErrors = Array<FTLightingAsBuiltsAssetUploadError>
export type FTLightingAsBuiltsAssetState = {
  job: {
    id: string
    progress: number
    reason: string
    status: string
  }
  errors: FTLightingAsBuiltsAssetErrors
  isPosting: boolean
  isCheckingJob: boolean
}
type FTLightingAsBuiltsAssetStateRoot = {
  lightingAsBuiltsAsset: FTLightingAsBuiltsAssetState
}
export const types = {
  FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB:
    'FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB',
  FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_ERROR:
    'FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_ERROR',
  FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_SUCCESS:
    'FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_SUCCESS',
  POST_LIGHTING_AS_BUILTS_ASSET: 'POST_LIGHTING_AS_BUILTS_ASSET',
  POST_LIGHTING_AS_BUILTS_ASSET_ERROR: 'POST_LIGHTING_AS_BUILTS_ASSET_ERROR',
  POST_LIGHTING_AS_BUILTS_ASSET_SUCCESS:
    'POST_LIGHTING_AS_BUILTS_ASSET_SUCCESS',
  RESET_LIGHTING_AS_BUILTS_ASSET_STATE: 'RESET_LIGHTING_AS_BUILTS_ASSET_STATE',
  RESET_LIGHTING_AS_BUILTS_ASSET_STATE_ERROR:
    'RESET_LIGHTING_AS_BUILTS_ASSET_STATE_ERROR',
}
export const selectLightingAsBuiltsAsset = (
  state: FTLightingAsBuiltsAssetStateRoot,
): FTLightingAsBuiltsAssetState => state.lightingAsBuiltsAsset
export const initialState: FTLightingAsBuiltsAssetState = {
  job: {
    id: '',
    progress: 0,
    reason: '',
    status: 'INPROGRESS',
  },
  errors: [],
  isPosting: false,
  isCheckingJob: false,
}

const lightingAsBuiltsAssetReducer = (
  state: FTLightingAsBuiltsAssetState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB:
      return { ...state, isCheckingJob: true }

    case types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_SUCCESS:
      return { ...state, isCheckingJob: false, job: action.job }

    case types.POST_LIGHTING_AS_BUILTS_ASSET:
      return {
        ...state,
        isPosting: true,
        job: {
          id: '',
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.POST_LIGHTING_AS_BUILTS_ASSET_ERROR:
      return { ...state, isPosting: false, errors: action.errors }

    case types.POST_LIGHTING_AS_BUILTS_ASSET_SUCCESS:
      return {
        ...state,
        isPosting: false,
        job: {
          id: action.payload,
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.RESET_LIGHTING_AS_BUILTS_ASSET_STATE:
      return initialState

    default:
      return state
  }
}

export default lightingAsBuiltsAssetReducer
export const actions = {
  postLightingAsBuiltsAsset: (params: FTPostLightingAsBuiltsAssetAction) => ({
    type: types.POST_LIGHTING_AS_BUILTS_ASSET,
    ...params,
  }),
  postLightingAsBuiltsAssetError: (errors: FTLightingAsBuiltsAssetErrors) => ({
    type: types.POST_LIGHTING_AS_BUILTS_ASSET_ERROR,
    errors,
  }),
  fetchLightingAsBuiltsAssetUploadJob: (
    params: FTFetchLightingAsBuiltsAssetUploadJobAction,
  ) => ({
    type: types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchLightingAsBuiltsAssetUploadJobMock: (
    params: FTFetchLightingAsBuiltsAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchLightingAsBuiltsAssetUploadJobMockError: (
    params: FTFetchLightingAsBuiltsAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB,
    ...params,
  }),
  resetLightingAsBuiltsAssetState: () => ({
    type: types.RESET_LIGHTING_AS_BUILTS_ASSET_STATE,
  }),
}
type FTPostLightingAsBuiltsAssetException = {
  name: string
  errors: FTLightingAsBuiltsAssetErrors
  toString: string
}

const PostLightingAsBuiltsAssetException = ({
  errors,
}): FTPostLightingAsBuiltsAssetException => ({
  name: 'PostLightingAsBuiltsAssetError',
  errors,
  toString: 'PostLightingAsBuiltsAssetError',
})

const handlePostLightingAsBuiltsAssetError = (error: Record<string, any>) => {
  const { response } = error || {}
  const { status, data } = response || {}

  if (status === 400) {
    const dataFormatted = data instanceof Array ? data : [data]
    const errors = data ? dataFormatted : initialState.errors
    throw PostLightingAsBuiltsAssetException({
      errors,
    })
  } else {
    handleAxiosError(error)
  }
}

// API
export class API {
  static postLightingAsBuiltsAsset({ file }: Record<string, any>) {
    const url = `${financeApiUrl()}/admin/csv/ltg-as-built`
    const formData = new FormData()
    formData.set('file', file)

    if (isVariantActive('lighting-as-builts-asset-manager-mock-error')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 500,
      })

      mock
        .onPost(url)
        .reply(400, [
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
          {
            message: 'Test Message',
          },
        ])
        .onAny()
        .passThrough()
      return mockAxios({
        method: 'post',
        url,
        data: formData,
        headers: defaultHeaders(),
      })
        .then(({ data: { jobId } }) => jobId)
        .catch(handlePostLightingAsBuiltsAssetError)
    }

    if (
      isVariantActive('lighting-as-builts-asset-manager-mock') ||
      isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
    ) {
      return Promise.resolve('mockJobId').then((data) => data)
    }

    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
    })
      .then(({ data: { jobId } }) => jobId)
      .catch(handlePostLightingAsBuiltsAssetError)
  }

  static fetchLightingAsBuiltsAssetUploadJob({ jobId }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return axios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchLightingAsBuiltsAssetUploadJobMock({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchLightingAsBuiltsAssetUploadJobMockError({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason:
          headers['job-reason'] || 'MeasureCode with id F43ILLAA not found',
        status: headers['job-status'] || 'ERROR',
      }))
      .catch(handleAxiosError)
  }
}

function* postLightingAsBuiltsAssetSaga(
  params: FTPostLightingAsBuiltsAssetAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.postLightingAsBuiltsAsset, params)
    yield put({
      type: types.POST_LIGHTING_AS_BUILTS_ASSET_SUCCESS,
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'PostLightingAsBuiltsAssetError') {
      const { errors } = error
      yield put(actions.postLightingAsBuiltsAssetError(errors))
    } else {
      yield handleSagaError(
        'POST_LIGHTING_AS_BUILTS_ASSET_ERROR_GENERAL',
        error,
      )
    }
  }
}

function* fetchLightingAsBuiltsAssetUploadJobSaga(
  params: FTFetchLightingAsBuiltsAssetUploadJobAction,
): Generator<any, void, any> {
  try {
    let job

    if (isVariantActive('lighting-as-builts-asset-manager-mock')) {
      job = yield call(API.fetchLightingAsBuiltsAssetUploadJobMock, params)
    } else if (
      isVariantActive('lighting-as-builts-asset-manager-mock-job-error')
    ) {
      job = yield call(API.fetchLightingAsBuiltsAssetUploadJobMockError, params)
    } else {
      job = yield call(API.fetchLightingAsBuiltsAssetUploadJob, params)
    }

    yield put({
      type: types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_SUCCESS,
      job,
    })
  } catch (error) {
    yield handleSagaError(
      types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB_ERROR,
      error,
    )
  }
}

export const sagas = [
  takeEvery(types.POST_LIGHTING_AS_BUILTS_ASSET, postLightingAsBuiltsAssetSaga),
  takeEvery(
    types.FETCH_LIGHTING_AS_BUILTS_ASSET_UPLOAD_JOB,
    fetchLightingAsBuiltsAssetUploadJobSaga,
  ),
]
