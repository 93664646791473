import { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import * as loginActions from '../actions/login'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import { FooterLinksStyles } from '../components/LoginForm'
import Spinner from '../components/Spinner'
import { HelpTitleStyles } from '../pages/LoginPage'
import logo from '../redaptive-logo.svg'
import type { FTWithRouter } from '../types'

type Props = FTWithRouter & {
  forgotPasswordRequest: (...args: Array<any>) => any
  error: boolean
  loading: boolean
  loginFormEmailFieldValue: string
}

class ForgotPasswordFormContainer extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { loading: prevLoading } = prevProps
    const { history, error, loading } = this.props

    if (prevLoading && !loading && !error) {
      history.push('/login?forgot')
    }
  }

  handleSubmit = (email) => {
    this.props.forgotPasswordRequest(email)
  }

  renderLinks = () => {
    const { loading } = this.props
    return (
      <FooterLinksStyles>
        {!loading && (
          <span className='item'>
            <Link to='/help'>Help</Link>
          </span>
        )}
        {loading && <Spinner size='tiny' />}
      </FooterLinksStyles>
    )
  }

  render() {
    const { loading, loginFormEmailFieldValue } = this.props
    return (
      <div className='LoginContainer'>
        <div className='LoginForm'>
          <div className='form'>
            <div className='logo'>
              <img src={logo} alt='Redaptive' />
            </div>
            <HelpTitleStyles>Forgot your password?</HelpTitleStyles>
            <ForgotPasswordForm
              handleSubmit={this.handleSubmit}
              loading={loading}
              loginFormEmailFieldValue={loginFormEmailFieldValue}
            />
            {this.renderLinks()}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  forgotPasswordRequest(email) {
    return dispatch(loginActions.forgotPasswordRequest(email))
  },
})

const mapStateToProps = (state) => {
  const { user } = state
  const {
    forgotPasswordLoading,
    forgotPasswordError,
    loginFormEmailFieldValue,
  } = user
  return {
    error: forgotPasswordError,
    loading: forgotPasswordLoading,
    loginFormEmailFieldValue,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormContainer),
) as any
