import { colors } from '../utils/themes'

const isUrl = (word) => {
  const urlPattern =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g
  return word.match(urlPattern)
}

export const Linkify = ({ children }) => {
  if (typeof children !== 'string') {
    return <span>{children}</span>
  }

  const words = children.split(' ')
  const finalSentence = words.map((word) => {
    if (isUrl(word)) {
      return (
        <a
          href={word}
          target='_blank'
          rel='noreferrer'
          style={{
            color: colors.blue,
            textDecoration: 'underline',
          }}
        >
          {word}
        </a>
      )
    }

    return <span>{word} </span>
  })
  return finalSentence
}
