import Checkbox from 'rc-checkbox'
import styled from 'styled-components'
import 'rc-checkbox/assets/index.css'

const StyledCheckbox = styled(Checkbox)`
  cursor: default !important;

  & .rc-checkbox-inner {
    height: 14px;
    width: 14px;
    border: ${({ readOnly }) =>
      readOnly ? '1px solid #b8b8b8' : '1px solid #979797'};

    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: ${({ readOnly }) =>
      !readOnly ? 'inset 0 1px 2px 0 rgba(0,0,0,0.2)' : 'none'};
  }

  & input[type='checkbox'] {
    margin: 0;
    height: 16px;
    width: 16px;
    cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
  }

  &.rc-checkbox-checked .rc-checkbox-inner {
    border: ${({ readOnly }) =>
      readOnly ? '1px solid #b8b8b8' : '1px solid #979797'};
    background-color: #ffffff;
    box-shadow: ${({ readOnly }) =>
      !readOnly ? 'inset 0 1px 2px 0 rgba(0,0,0,0.2)' : 'none'};
  }

  & .rc-checkbox-inner:after {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
  }

  &.rc-checkbox-checked .rc-checkbox-inner:after {
    border-width: 3px;
    width: 4px;
    height: 7px;
    top: 0;
    border-color: ${({ readOnly }) => (readOnly ? '#97bfee' : '#4a90e2')};
  }

  & .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: ${({ readOnly }) => (readOnly ? '#b8b8b8' : '#979797')};
  }

  &.rc-checkbox-checked:hover .rc-checkbox-inner,
  &.rc-checkbox:hover .rc-checkbox-inner,
  &.rc-checkbox-checked:hover .rc-checkbox-inner {
    border-color: ${({ readOnly }) => (readOnly ? '#b8b8b8' : '#4a90e2')};
  }

  &:after {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: ${({ edited }) => (edited ? '#c70d08' : 'transparent')};
    margin-left: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    content: '';
  }
`
export default StyledCheckbox
