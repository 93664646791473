import TabPane2 from '../../../../components/Tabs/TabPane2'
import { maintenanceTabs } from '../../helpers/utils'
import TabTextSuffix from '../components/TabTextSuffix'

const renderTabPane = () => {
  const allTabs = maintenanceTabs.map((tabItem) => ({
    ...tabItem,
    TextSuffix: TabTextSuffix,
  }))
  return <TabPane2 tabs={allTabs} attachQueryParamsToLinks />
}

export default renderTabPane
