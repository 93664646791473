import styled from 'styled-components'

import CheckCircleOutlineIcon from '../../../../components/Icons/CheckCircleOutlineIcon'
import IndicatorCard, {
  IndicatorCardStyled,
} from '../../../../components/IndicatorCard'
import { colors } from '../../../../utils/themes'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'

const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/indicator-card',
    text: 'Indicator Card',
  },
]
const MainContentStyled = styled.div`
  margin: 0 32px;
`
const IndicatorCardWrapperStyled = styled.div`
  ${IndicatorCardStyled} {
    padding: 12px 16px 11px;
    width: 272px;
  }
`
const BodyStyled = styled.div``
const PercentWrapperStyled = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 17px;
`
const PercentStyled = styled.div`
  font-size: 16px;
  margin-left: 5px;
`
const DataRowStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`
const DataRowStatStyled = styled.div`
  color: ${colors.blue2};
  font-weight: bold;
`
const DataRowTextStyled = styled.div`
  font-size: 12px;
`
const CheckCircleOutlineIconStyled = styled(CheckCircleOutlineIcon)`
  color: ${colors.green4};
`

const Body = () => (
  <BodyStyled>
    <PercentWrapperStyled>
      100.00
      <PercentStyled>%</PercentStyled>
    </PercentWrapperStyled>
    <DataRowStyled>
      <DataRowTextStyled>Data Points Received</DataRowTextStyled>
      <DataRowStatStyled>57,620</DataRowStatStyled>
    </DataRowStyled>
    <DataRowStyled>
      <DataRowTextStyled>Data Points Expected</DataRowTextStyled>
      <DataRowStatStyled>57,620</DataRowStatStyled>
    </DataRowStyled>
  </BodyStyled>
)

const Indicator = () => <CheckCircleOutlineIconStyled />

export default () => (
  <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Indicator Card'>
    <MainContentStyled>
      <IndicatorCardWrapperStyled>
        <IndicatorCard Indicator={Indicator} Body={Body} title='Data Capture' />
      </IndicatorCardWrapperStyled>
    </MainContentStyled>
  </DesignLibraryPageWrapper>
)
