import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'

export type FTPhotoDownloadUrl = {
  fileName: string
  fileUploadPath: string
  id: string
  objectId: string
  objectType: string
  preSignedThumbnailUrl: string
  preSignedUrl: string
  responseStatus: string
}

export type FTPanelMetaDataState = {
  loading: boolean
  error: string
  panelPhotos: Array<any>
  photosDownloadUrl: FTPhotoDownloadUrl[]
  photsDownloadUrlLoading: boolean
  photosDownloadUrlError: string
}

// Action Types
const types = {
  FETCH_PANEL_PHOTOS: 'FETCH_PANEL_PHOTOS',
  FETCH_PANEL_PHOTOS_SUCCESS: 'FETCH_PANEL_PHOTOS_SUCCESS',
  FETCH_PANEL_PHOTOS_ERROR: 'FETCH_PANEL_PHOTOS_ERROR',
  FETCH_PHOTOS_DOWNLOAD_URL: 'FETHC_PHOTOS_DOWNLOAD_URL',
  FETCH_PHOTOS_DOWNLOAD_URL_SUCCESS: 'FETCH_PHOTOS_DOWNLOAD_URL_SUCCESS',
  FETCH_PHOTOS_DOWNLOAD_URL_ERROR: 'FETCH_PANEL_PHOTOS_ERROR',
}

// API
const API = {
  fetchPanelPhotos: ({ objectId }: { objectId: string }) => {
    const url = `${consoleApiUrl()}/files/metadata?${objectId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchPhotosDownloadUrl: ({ fileIds }: { fileIds: Array<string> }) => {
    const body = {
      fileIds,
    }
    const url = `${consoleApiUrl()}/files/download/generate-url`
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
}
// Actions
// eslint-disable-next-line import/no-unused-modules
export const actions = {
  fetchPanelPhotos: (params: { objectId: string }) => ({
    type: types.FETCH_PANEL_PHOTOS,
    ...params,
  }),
  fetchPhotosDownloadUrl: (params: { fileIds: Array<string> }) => ({
    type: types.FETCH_PHOTOS_DOWNLOAD_URL,
    ...params,
  }),
}

// Sagas

function* fetchPanelPhotosSaga(params: {
  obectId: string
}): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchPanelPhotos, params)
    yield put({
      type: types.FETCH_PANEL_PHOTOS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PANEL_PHOTOS_ERROR, e)
  }
}

function* fetchPhotosDownloadUrlSaga(params: {
  fileIds: Array<string>
}): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchPhotosDownloadUrl, params)
    yield put({
      type: types.FETCH_PHOTOS_DOWNLOAD_URL_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PHOTOS_DOWNLOAD_URL_ERROR, e)
  }
}

// eslint-disable-next-line import/no-unused-modules
export const sagas = [
  takeLatest(types.FETCH_PANEL_PHOTOS, fetchPanelPhotosSaga),
  takeLatest(types.FETCH_PHOTOS_DOWNLOAD_URL, fetchPhotosDownloadUrlSaga),
]

// State
const initialState: FTPanelMetaDataState = {
  loading: false,
  error: '',
  panelPhotos: [],
  photosDownloadUrl: [],
  photsDownloadUrlLoading: false,
  photosDownloadUrlError: '',
}

// Reducers
function panelMetaData(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PANEL_PHOTOS:
      return { ...state, error: '', loading: true }

    case types.FETCH_PANEL_PHOTOS_SUCCESS:
      return {
        ...state,
        panelPhotos: action.payload,
        photosDownloadUrl: [],
        error: '',
        loading: false,
      }

    case types.FETCH_PANEL_PHOTOS_ERROR:
      return {
        ...state,
        error: action.error,
        photosDownloadUrl: [],
        loading: false,
      }

    case types.FETCH_PHOTOS_DOWNLOAD_URL:
      return {
        ...state,
        photosDownloadUrlError: '',
        photsDownloadUrlLoading: true,
      }

    case types.FETCH_PHOTOS_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        photosDownloadUrl: action.payload,
        photosDownloadUrlError: '',
        photsDownloadUrlLoading: false,
      }

    case types.FETCH_PHOTOS_DOWNLOAD_URL_ERROR:
      return {
        ...state,
        photosDownloadUrlError: action.error,
        photsDownloadUrlLoading: false,
      }

    default:
      return state
  }
}

// Selectors
export const selectPanelMetaData = (state) => state.entities?.panelMetaData

export default panelMetaData
