import { FormikProps } from 'formik'

import {
  ErrorStyled,
  FieldContainerStyled,
  LabelStyled,
  LableSupportText,
  PageBodyStyled,
  PageHeaderStyled,
  PanelFieldValues,
  RadioButtonContainerStyled,
  validPanelTypes,
  validPanelVoltages,
} from './commons'
import { FTPanel } from '../../ducks/panels'
import { validAmperageValues } from '../../utils/panelConfiguration'
import ListSelector, { FTItem } from '../ListSelector'
import StyledRadioButton from '../RadioButton'

type StepProps = {
  formik: FormikProps<PanelFieldValues>
  panels: FTPanel[]
  isMetered: boolean
  updateMode: boolean
  initialValues: PanelFieldValues
  handleRadioButtonChange: (event: Event) => void
}
const PanelConfigForm = (props: StepProps) => {
  const {
    formik,
    panels,
    isMetered,
    updateMode,
    initialValues,
    handleRadioButtonChange,
  } = props

  const validVoltageItems =
    formik.values.type ? validPanelVoltages[formik.values.type] : []

  // Getting Selected List Selector Items
  const selectedPanelType = validPanelTypes.find(
    (opt) => opt.id === formik.values.type,
  )
  const selectedVoltage = validVoltageItems.find(
    (opt: { id: string; value: string }) => opt.id === formik.values.voltage,
  )
  const selectedAmperage = validAmperageValues.find(
    (opt) => opt.id === formik.values.amperage,
  )
  const selectedPanel = panels.find(
    (panel) => panel.id === formik.values.parentPanelId,
  )

  // helper to show subpanel
  const shouldShowSubPanel = ['SUB_PANEL', 'MDP'].includes(
    formik.values.powerSourceLevel,
  )

  return (
    <>
      <PageHeaderStyled>
        <p>Configuration</p>
        <div />
      </PageHeaderStyled>

      <PageBodyStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Panel Configuration <span>*</span>
          </LabelStyled>
          <LableSupportText>
            The panel configuration type, i.e. &quot;3-Phase 4-Wire Wye&quot;
          </LableSupportText>
          <ListSelector
            disabled={isMetered}
            notSetLabelText='Select a panel configuration'
            items={validPanelTypes as FTItem[]}
            selectedItem={selectedPanelType as FTItem}
            updateValue={({ value }) => {
              formik.setFieldValue('type', value).then(() => {
                formik.setFieldTouched('type', true)
                formik.setFieldValue('voltage', '')
              })
            }}
          />
          {formik.errors.type && formik.touched.type ?
            <ErrorStyled className='input__error'>
              *{formik.errors.type}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Panel Voltage <span>*</span>
          </LabelStyled>
          <LableSupportText>
            The panel voltage rating, i.e. &quot;120/240V&quot;
          </LableSupportText>
          <ListSelector
            disabled={isMetered}
            notSetLabelText='Select a panel voltage'
            items={validVoltageItems as FTItem[]}
            selectedItem={selectedVoltage as FTItem}
            updateValue={({ value }) => {
              formik.setFieldValue('voltage', value).then(() => {
                formik.setFieldTouched('voltage', true)
              })
            }}
          />
          {formik.errors.voltage && formik.touched.voltage ?
            <ErrorStyled className='input__error'>
              *{formik.errors.voltage}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>Panel Amperage</LabelStyled>
          <LableSupportText>
            The panel amperage rating i.e &apos;100A&apos;
          </LableSupportText>
          <ListSelector
            notSetLabelText='Select panel amperage'
            items={validAmperageValues as FTItem[]}
            selectedItem={selectedAmperage as FTItem}
            updateValue={({ value }) => {
              formik.setFieldValue('amperage', value).then(() => {
                formik.setFieldTouched('amperage', true)
              })
            }}
          />
          {formik.errors.amperage && formik.touched.amperage ?
            <ErrorStyled className='input__error'>
              *{formik.errors.amperage}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Panel Slot Numbering<span>*</span>
          </LabelStyled>
          <LableSupportText>
            Are the panel slots numbered? Subpanels and panelboards are
            typically numbered. Switchboards may not be numbered.
          </LableSupportText>
          <RadioButtonContainerStyled>
            <div>
              <StyledRadioButton
                name='isNumbered'
                value='true'
                onChange={(e) => {
                  handleRadioButtonChange(e)
                  formik.setFieldValue('numberOfSwitches', undefined)
                  if (updateMode) {
                    formik.setFieldValue(
                      'numberOfCircuits',
                      initialValues.numberOfCircuits,
                    )
                    formik.setFieldValue(
                      'leftSidePanel',
                      initialValues.leftSidePanel,
                    )
                    formik.setFieldValue(
                      'rightSidePanel',
                      initialValues.rightSidePanel,
                    )
                    formik.setFieldValue(
                      'phasingPattern',
                      initialValues.phasingPattern,
                    )
                  }
                }}
                checked={formik.values.isNumbered === true}
                disabled={isMetered || updateMode}
              />
              <LabelStyled>Numbered</LabelStyled>
            </div>
            <div>
              <StyledRadioButton
                name='isNumbered'
                value='false'
                onChange={(e) => {
                  handleRadioButtonChange(e)
                  formik.setFieldValue('numberOfCircuits', undefined)
                  formik.setFieldValue('leftSidePanel', undefined)
                  formik.setFieldValue('rightSidePanel', undefined)
                  formik.setFieldValue('phasingPattern', undefined)
                  if (updateMode) {
                    formik.setFieldValue(
                      'numberOfSwitches',
                      initialValues.numberOfSwitches,
                    )
                  }
                }}
                checked={formik.values.isNumbered === false}
                disabled={isMetered || updateMode}
              />
              <LabelStyled>Non Numbered</LabelStyled>
            </div>
          </RadioButtonContainerStyled>
          {formik.errors.isNumbered && formik.touched.isNumbered ?
            <ErrorStyled className='input__error'>
              *{formik.errors.isNumbered}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>
      </PageBodyStyled>

      <PageHeaderStyled>
        <p>Power</p>
        <div />
      </PageHeaderStyled>
      <PageBodyStyled className='last'>
        <FieldContainerStyled className='w-100'>
          <LabelStyled>
            Power Source<span>*</span>
          </LabelStyled>
          <LableSupportText>
            Utility Feed, Main Distribution Panel (MDP) and Sub-Panel
          </LableSupportText>
          <RadioButtonContainerStyled>
            <div>
              <StyledRadioButton
                name='powerSourceLevel'
                value='UTILITY_FEED'
                onChange={(e) => {
                  formik.setFieldValue('parentPanelId', 'null')
                  handleRadioButtonChange(e)
                }}
                checked={formik.values.powerSourceLevel === 'UTILITY_FEED'}
              />
              <LabelStyled>Utility Feed</LabelStyled>
            </div>
            <div>
              <StyledRadioButton
                name='powerSourceLevel'
                value='MDP'
                onChange={handleRadioButtonChange}
                checked={formik.values.powerSourceLevel === 'MDP'}
              />
              <LabelStyled>Main Distribution Panel (MDP)</LabelStyled>
            </div>
            <div>
              <StyledRadioButton
                name='powerSourceLevel'
                value='SUB_PANEL'
                onChange={handleRadioButtonChange}
                checked={formik.values.powerSourceLevel === 'SUB_PANEL'}
              />
              <LabelStyled>Sub-Panel</LabelStyled>
            </div>
            <div>
              <StyledRadioButton
                name='powerSourceLevel'
                value='UNKNOWN'
                onChange={(e) => {
                  formik.setFieldValue('parentPanelId', 'null')
                  handleRadioButtonChange(e)
                }}
                checked={formik.values.powerSourceLevel === 'UNKNOWN'}
              />
              <LabelStyled>Unknown</LabelStyled>
            </div>
          </RadioButtonContainerStyled>
          {formik.errors.powerSourceLevel && formik.touched.powerSourceLevel ?
            <ErrorStyled className='input__error'>
              *{formik.errors.powerSourceLevel}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>

        {shouldShowSubPanel ?
          <FieldContainerStyled>
            <LabelStyled>Sub-Panel</LabelStyled>
            <LableSupportText>Select the panel feeding power</LableSupportText>
            <ListSelector
              items={panels}
              selectedItem={selectedPanel}
              notSetLabelText='Select the Sub-Panel'
              updateValue={({ value }) => {
                formik.setFieldValue('parentPanelId', value).then(() => {
                  formik.setFieldTouched('parentPanelId', true)
                  formik.validateForm()
                })
              }}
            />
            {formik.errors.parentPanelId && !!formik.touched.parentPanelId ?
              <ErrorStyled className='input__error'>
                *{formik.errors.parentPanelId}
              </ErrorStyled>
            : null}
          </FieldContainerStyled>
        : null}
      </PageBodyStyled>
    </>
  )
}

export default PanelConfigForm
