import { Field } from 'formik'
import { Component } from 'react'
import styled from 'styled-components'

import { fieldNameMap } from '../../ducks/meters/generation'
import type { FTModalPanelForm } from '../../ducks/modal'
import type { FTFormFieldEvent, FTWithFormik } from '../../types'
import { makeListSelectorItem } from '../../utils'
import ErrorMessage, { ErrorMessageStyles } from '../ErrorMessage'
import FormButtons from '../FormButtons'
import type { FTOnChangePayload } from '../ListSelector'
import ListSelector from '../ListSelector'
import EditBlock from '../MeterConfiguration/EditBlock'
import StackedInput from '../StackedInput'
import UnsavedChanges from '../UnsavedChanges'

const WrapperStyles = styled.div`
  .PanelForm__EditBlock {
    background-color: #fff;
    padding: 10px 0 20px;
  }

  input {
    width: 100%;
  }

  .input__error {
    left: auto;
    right: 6px;
  }
`
const FormButtonsStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
const FieldErrorMessageStyles = styled.div`
  font-size: 12px;

  .panel-modal__disabled-field-error {
    margin: 4px 0 8px;
  }
`
const FormErrorMessageStyles = styled.div`
  ${ErrorMessageStyles} {
    margin: 0 0 2px;
  }
`
type FTProps = {
  error: string
} & FTWithFormik &
  FTModalPanelForm
type FTState = {
  showDisabledErrors: boolean
}
export default class Form extends Component<FTProps, FTState> {
  constructor(props: any) {
    super(props)
    this.state = {
      showDisabledErrors: false,
    }
  }

  canSubmit = () => {
    const { dirty, values } = this.props
    const { name, type, voltage } = values
    return !!(dirty && name && type && voltage)
  }

  onUpdateField =
    (fieldName: string) => (data: FTFormFieldEvent & FTOnChangePayload) => {
      const { setFieldValue } = this.props
      const { value } = data.target || data
      setFieldValue(fieldName, value)
    }

  onUpdateParentPanel = () => (data: FTFormFieldEvent) => {
    const { setFieldValue, panelsById } = this.props
    const { value } = data.target || data

    setFieldValue('parentPanelId', value)
    setFieldValue('parentPanelName', panelsById[value]?.name)
  }

  onUpdateType = ({ value: type }: { value: string }) => {
    const { conditionalFields, setValues, values } = this.props
    const { voltage } = values
    const validVoltages = conditionalFields.getVoltagesById(type)
    const newVoltage =
      voltage && voltage !== 'OTHER' && validVoltages[voltage] ? voltage : null
    setValues({ ...values, type, voltage: newVoltage })
  }

  renderPanelSection = () => {
    const {
      errors,
      conditionalFields,
      initialValues,
      touched,
      values,
      status,
      panels,
      panelsById,
    } = this.props

    const { showDisabledErrors } = this.state

    const {
      id,
      description = '',
      name = '',
      type = null,
      voltage = null,
      parentPanelId,
      panelLevel,
    } = values

    const validPanels = id ? panels.filter((panel) => panel.id !== id) : panels
    const { validPanelLevels } = status
    const panelTypes = conditionalFields.panelTypes.map<string>((value) =>
      makeListSelectorItem(value, fieldNameMap.get(value)),
    )

    const panelVoltages = conditionalFields
      .getVoltageValues(type)
      .map<string>((value) =>
        makeListSelectorItem(value, fieldNameMap.get(value)),
      )

    const handleDisabledClick = () => {
      if (id && !showDisabledErrors) {
        this.setState((prev) => ({ ...prev, showDisabledErrors: true }))
      }
    }

    return (
      <WrapperStyles>
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='The name of the panel, i.e. "Panel A"'
            editDotRight='-15px'
            edited={name !== initialValues.name}
            error={errors.name}
            fieldTitle='Panel Name'
            hideEditDotOnHover={false}
            name='name'
            onBlur={this.onUpdateField('name')}
            required
          />
        </EditBlock>
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='A description to further identify the panel, i.e. "1st Floor Breakroom"'
            editDotRight='-15px'
            edited={description !== initialValues.description}
            error={touched.description && errors.description}
            fieldTitle='Panel Description'
            hideEditDotOnHover={false}
            name='description'
            onBlur={this.onUpdateField('description')}
          />
        </EditBlock>
        <EditBlock
          className='PanelForm__EditBlock'
          onClick={handleDisabledClick}
        >
          <Field
            component={StackedInput}
            description='The panel configuration type, i.e. "Single Phase, 3-Wire (Split-Phase)"'
            disabled={!!id}
            edited={type !== initialValues.type}
            editDotRight='-15px'
            fieldComponent={ListSelector}
            fieldTitle='Panel Type'
            hideEditDotOnHover={false}
            items={panelTypes}
            name='type'
            notSetLabelText='-- Select a panel type --'
            selectedItem={makeListSelectorItem(
              type,
              fieldNameMap.get(type || ''),
            )}
            updateValue={this.onUpdateType}
          />
          {showDisabledErrors && (
            <FieldErrorMessageStyles>
              <ErrorMessage
                className='panel-modal__disabled-field-error'
                collapseWhenEmpty
                message='Panel type cannot be changed because there are one or more meters assigned to this panel. Please create a new panel instead.'
              />
            </FieldErrorMessageStyles>
          )}
        </EditBlock>
        <EditBlock
          className='PanelForm__EditBlock'
          onClick={handleDisabledClick}
        >
          <Field
            component={StackedInput}
            description='The panel voltage rating, i.e. "120/240V"'
            disabled={!!id}
            edited={voltage !== initialValues.voltage}
            editDotRight='-15px'
            fieldTitle='Panel Voltage'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={panelVoltages}
            name='voltage'
            notSetLabelText='-- Select a panel voltage --'
            selectedItem={makeListSelectorItem(
              voltage,
              fieldNameMap.get(voltage || ''),
            )}
            updateValue={this.onUpdateField('voltage')}
          />
          {showDisabledErrors && (
            <FieldErrorMessageStyles>
              <ErrorMessage
                className='panel-modal__disabled-field-error'
                collapseWhenEmpty
                message='Panel voltage cannot be changed because there are one or more meters assigned to this panel. Please create a new panel instead.'
              />
            </FieldErrorMessageStyles>
          )}
        </EditBlock>
        {/* Parent Panel Selection */}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='Denotes the panel feeding power'
            editDotRight='-15px'
            edited={parentPanelId !== initialValues.parentPanelId}
            fieldTitle='Parent Panel'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={validPanels}
            name='parentPanelName'
            notSetLabelText='-- Search for the panel --'
            notSetItemValue=''
            selectedItem={makeListSelectorItem(panelsById[parentPanelId]?.name)}
            updateValue={this.onUpdateParentPanel()}
          />
        </EditBlock>
        {/* Panel Hierarchy Type */}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='The hierarchy level at which the panel is setup'
            editDotRight='-15px'
            edited={panelLevel !== initialValues.panelLevel}
            fieldTitle='Panel Hierarchy Type'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={validPanelLevels}
            name='panelLevel'
            notSetLabelText='-- Select the panel level --'
            notSetItemValue=''
            selectedItem={makeListSelectorItem(panelLevel)}
            updateValue={this.onUpdateField('panelLevel')}
          />
        </EditBlock>
      </WrapperStyles>
    )
  }

  render() {
    const { dirty, error, goBack, handleSubmit, id, isSubmitting } = this.props
    return (
      <>
        <UnsavedChanges when={!isSubmitting && dirty} />
        <FormErrorMessageStyles>
          <ErrorMessage message={error} />
        </FormErrorMessageStyles>
        {this.renderPanelSection()}
        <FormErrorMessageStyles>
          <ErrorMessage message={error} />
        </FormErrorMessageStyles>

        <FormButtonsStyles>
          <FormButtons
            cancelText='Cancel'
            isSubmitting={isSubmitting && !error}
            onCancel={goBack}
            onSubmit={handleSubmit}
            submitDisabled={!this.canSubmit()}
            submitText={id ? 'Save' : 'Create'}
          />
        </FormButtonsStyles>
      </>
    )
  }
}
