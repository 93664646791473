import { connect } from 'react-redux'

import BillingRemainingQuantityModal from '../components/Billing/RemainingQuantityModal'
import BillingReRunCaculationsModal from '../components/Billing/RerunSavingsModal'
import BillingReSendToVerificationModal from '../components/Billing/ResendToVerificationModal'
import BillingSendToInvestigationModal from '../components/Billing/SendToInvestigationModal'
import BillingSendToNetsuiteModal from '../components/Billing/SendToNetsuiteConfirmModal'
import BillingSendToReviewBulkModal from '../components/Billing/SendToReviewBulkModal'
import BillingSendToReviewModal from '../components/Billing/SendToReviewModal'
import BillingSendToVerificationModal from '../components/Billing/SendToVerificationModal'
import EntityEditorModal from '../components/EntityEditorModal'
import ModalKfactorBase from '../components/GasMeterConfiguration/KFactorModal'
import MeterConfigHistory from '../components/MeterConfigHistory'
import ModalConfirm2 from '../components/ModalConfirm2'
import ModalElectronVerifiedForm from '../components/ModalElectronVerifiedForm'
import ModalFieldStatus from '../components/ModalFieldStatus'
import ModalGeneric from '../components/ModalGeneric'
import ModalMeterContactAssociation from '../components/ModalMeterContractAssociation'
import ModalPanelDeleteConflict from '../components/ModalPanelDeleteConflict'
import ModalPanelForm from '../components/ModalPanelForm'
import ModalPanelForm2 from '../components/ModalPanelForm2'
import ModalPanel3 from '../components/ModalPanelForm3'
import ModalPanelListEditor from '../components/ModalPanelListEditor'
import ModalPanelPhotoView from '../components/ModalPanelPhotosView'
import ModalResetPasswordLink from '../components/ModalResetPasswordLink'
import ModalSignupLink from '../components/ModalSignupLink'
import ModalUploadCustomerLogo from '../components/ModalUploadCustomerLogo'
import ConfirmModal from '../components/ProposalsEngine/ConfirmModal'
import ModalAddScenarioWithoutSalesTax from '../components/ProposalsEngine/ModalAddScenarioWithoutSalesTax'
import ModalLockUnlock from '../components/ProposalsEngine/ModalLockUnlock'
import ModalScenario from '../components/ProposalsEngine/ModalScenario'
import ModalSeekMargin from '../components/ProposalsEngine/ModalSeekMargin'
import ConnectedServerErrorModal from '../components/ServerErrorModal'
import ModalSimple from '../components/SimpleModal'
import { type FTCreateModalParams, modalTypes } from '../ducks/modal'
import SubmissionDoneModal from '../pages/IntegrationSubmissions/modals/SubmissionDoneModal'
import UtilityDataModal from '../pages/ToolsAndReports/UtilityData/UploadModal'

type FTProps = FTCreateModalParams

type FTProps = FTCreateModalParams
const MODAL_COMPONENTS = {
  [modalTypes.MODAL_BILLING_SEND_TO_VERIFICATION]:
    BillingSendToVerificationModal,
  [modalTypes.MODAL_BILLING_SEND_TO_REVIEW]: BillingSendToReviewModal,
  [modalTypes.MODAL_BILLING_SEND_TO_REVIEW]: BillingSendToReviewModal,
  [modalTypes.MODAL_BILLING_SEND_TO_REVIEW_BULK]: BillingSendToReviewBulkModal,
  [modalTypes.MODAL_BILLING_RE_SEND_TO_VERIFICATION]:
    BillingReSendToVerificationModal,
  [modalTypes.MODAL_BILLING_SEND_TO_INVESTIGATION]:
    BillingSendToInvestigationModal,
  [modalTypes.MODAL_BILLING_RERUN_SAVINGS]: BillingReRunCaculationsModal,
  [modalTypes.MODAL_BILLING_SEND_TO_NETSUITE]: BillingSendToNetsuiteModal,
  [modalTypes.MODAL_CONFIRM]: ModalSimple,
  [modalTypes.MODAL_CONFIRM2]: ModalConfirm2,
  [modalTypes.MODAL_ENTITY_EDITOR]: EntityEditorModal,
  [modalTypes.MODAL_ELECTRON_VERIFIED_FORM]: ModalElectronVerifiedForm,
  [modalTypes.MODAL_FIELD_STATUS_FORM]: ModalFieldStatus,
  [modalTypes.MODAL_GENERIC]: ModalGeneric,
  [modalTypes.MODAL_LOADING]: ModalSimple,
  [modalTypes.MODAL_PANEL_DELETE_CONFLICT]: ModalPanelDeleteConflict,
  [modalTypes.MODAL_PANEL_FORM]: ModalPanelForm,
  [modalTypes.MODAL_PANEL_FORM2]: ModalPanelForm2,
  [modalTypes.MODAL_PANEL_FORM3]: ModalPanel3,
  [modalTypes.MODAL_PANEL_LIST_EDITOR]: ModalPanelListEditor,
  [modalTypes.MODAL_RESET_PASSWORD_LINK]: ModalResetPasswordLink,
  [modalTypes.MODAL_SIGNUP_LINK]: ModalSignupLink,
  [modalTypes.MODAL_SERVER_ERROR]: ConnectedServerErrorModal,
  [modalTypes.MODAL_SCENARIO]: ModalScenario,
  [modalTypes.MODAL_LOCK_UNLOCK]: ModalLockUnlock,
  [modalTypes.MODAL_UTILITY_DATA]: UtilityDataModal,
  [modalTypes.MODAL_BILLING_REMAINING_QUANTITY]: BillingRemainingQuantityModal,
  [modalTypes.MODAL_SEEK_MARGIN]: ModalSeekMargin,
  [modalTypes.MODAL_ENERGY_STAR]: SubmissionDoneModal,
  [modalTypes.MODAL_KFACTOR_BASE]: ModalKfactorBase,
  [modalTypes.MODAL_METER_CONTRACT_ASSOCIATION]: ModalMeterContactAssociation,
  [modalTypes.MODAL_CONFIG_HISTORY]: MeterConfigHistory,
  [modalTypes.MODAL_ADD_SCENARIO_WITHOUT_SALES_TAX]:
    ModalAddScenarioWithoutSalesTax,
  [modalTypes.MODAL_PANEL_PHOTOS_VIEW]: ModalPanelPhotoView,
  [modalTypes.MODAL_UPLOAD_CUSTOMER_LOGO]: ModalUploadCustomerLogo,
  [modalTypes.MODAL_DELETE_CUSTOMER_LOGO]: ConfirmModal,
}

const ModalContainer = ({ modalType, modalProps }: FTProps) => {
  if (!modalType) {
    return null
  }

  const CurrentModal = MODAL_COMPONENTS[modalType]

  if (!CurrentModal) {
    return null
  }

  return <CurrentModal {...modalProps} />
}

export default connect((state) => state.modal)(ModalContainer)
