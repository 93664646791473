import axios from 'axios'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleBaseUrl, defaultHeaders } from '../../../api'
import { handleAxiosError } from '../../../api/utils'
import batchSummariesMockData from '../../../mockData/proposals/multisite/batchSummaries'
import { handleSagaError } from '../../../sagas/utils'
import type { FTProjectType } from '../../../utils'
import { isVariantActive } from '../../../utils'
import { colors } from '../../../utils/themes'

type FTProposalBatchSummaryResponse = {
  created: string
  createdBy: string
  id: string
  lastModified: string
  modified: string
  modifiedBy: string
  name: string
  opportunityIds: Array<string>
  projectType: FTProjectType
  redaptiveOpportunityIds: Array<string>
  salesforceCustomerId: string
  status: string
}
export type FTProposalBatchSummary = FTProposalBatchSummaryResponse
export type FTProposalBatchSummaries = Array<FTProposalBatchSummary>
export type FTFetchProposalBatchSummariesAction = {
  salesforceCustomerId: string
}
export type FTProposalBatchSummaryDownloadAction = {
  filename: string
  batchId: string
  downloadType: string
}
export type FTProposalBatchSummariesMetaState = {
  error: string
  loading: boolean
}
export type FTProposalBatchSummariesEntityState = {
  byId: Record<string, FTProposalBatchSummary>
  items: FTProposalBatchSummaries
  meta: FTProposalBatchSummariesMetaState
  downloadMeta: {
    error: string
    loading: boolean
  }
}
type FTState = {
  entities: {
    proposalBatchSummaries: FTProposalBatchSummariesEntityState
  }
}
export const types = {
  FETCH_PROPOSAL_BATCH_SUMMARIES: 'FETCH_PROPOSAL_BATCH_SUMMARIES',
  FETCH_PROPOSAL_BATCH_SUMMARIES_SUCCESS:
    'FETCH_PROPOSAL_BATCH_SUMMARIES_SUCCESS',
  FETCH_PROPOSAL_BATCH_SUMMARIES_ERROR: 'FETCH_PROPOSAL_BATCH_SUMMARIES_ERROR',
  DOWNLOAD_PROPOSAL_BATCH_SUMMARY: 'DOWNLOAD_PROPOSAL_BATCH_SUMMARY',
  DOWNLOAD_PROPOSAL_BATCH_SUMMARY_SUCCESS:
    'DOWNLOAD_PROPOSAL_BATCH_SUMMARY_SUCCESS',
  DOWNLOAD_PROPOSAL_BATCH_SUMMARY_ERROR:
    'DOWNLOAD_PROPOSAL_BATCH_SUMMARY_ERROR',
}
export const actions = {
  fetchProposalBatchSummaries: (
    props: FTFetchProposalBatchSummariesAction,
  ) => ({
    type: types.FETCH_PROPOSAL_BATCH_SUMMARIES,
    ...props,
  }),
  downloadProposalBatchSummary: (
    props: FTProposalBatchSummaryDownloadAction,
  ) => ({
    type: types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
  downloadMeta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('proposalBatchSummaries')

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalBatchSummaries> = Object.values(
    action.payload.entities.proposalBatchSummaries,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalBatchSummaries }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_BATCH_SUMMARIES:
      return initialState.byId

    case types.FETCH_PROPOSAL_BATCH_SUMMARIES_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_BATCH_SUMMARIES:
      return initialState.items

    case types.FETCH_PROPOSAL_BATCH_SUMMARIES_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_BATCH_SUMMARIES:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_BATCH_SUMMARIES_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_BATCH_SUMMARIES_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function downloadMeta(state = initialState.meta, action) {
  switch (action.type) {
    case types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY:
      return { ...state, error: '', loading: true }

    case types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  downloadMeta,
})
export const selectProposalBatchSummariesEntity = (
  state: FTState,
): FTProposalBatchSummariesEntityState => state.entities.proposalBatchSummaries
export const batchStatuses: Record<
  string,
  {
    color: string
    displayName: string
  }
> = {
  PENDING: {
    color: '#7F7F7F',
    displayName: 'Pending',
  },
  FINALIZED: {
    color: colors.orange,
    displayName: 'Finalized',
  },
}
export const API = {
  downloadProposalBatchSummary: ({
    filename,
    batchId,
    downloadType,
  }: FTProposalBatchSummaryDownloadAction) => {
    const downloadTypePath =
      downloadType === 'consolidated' ? 'consolidated/' : ''
    const baseUrl = `
      ${consoleBaseUrl()}/proposal/api/opportunity/batch-analysis/summary/download/${downloadTypePath}zip`
    const url = `${baseUrl}?id=${batchId}`
    return axios
      .get(url, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => {
        const elm = document.createElement('a')
        elm.href = URL.createObjectURL(response.data)
        elm.setAttribute('download', filename)
        elm.click()
        elm.remove()
      })
      .catch(handleAxiosError)
  },
  fetchProposalBatchSummaries: ({
    salesforceCustomerId,
  }: FTFetchProposalBatchSummariesAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(batchSummariesMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/batch-analysis?salesforceCustomerId=${salesforceCustomerId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: Array<FTProposalBatchSummaryResponse> }) => data)
      .catch(handleAxiosError)
  },
}

function* downloadProposalBatchSummarySaga({
  type,
  ...params
}: FTProposalBatchSummaryDownloadAction & {
  type: string
}): Generator<any, void, any> {
  try {
    yield call(API.downloadProposalBatchSummary, params)
    yield put({
      type: types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY_ERROR, e)
  }
}

function* fetchProposalBatchSummariesSaga({
  type,
  ...params
}: FTFetchProposalBatchSummariesAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalBatchSummaries = yield call(
      API.fetchProposalBatchSummaries,
      params,
    )
    const normalized =
      response.length ?
        normalize(response, [entitySchema]) // If the server responds with an empty array, normalize won't properly
        // format the object, so creating it here.
      : {
          entities: {
            proposalBatchSummaries: {},
          },
        }
    yield put({
      type: types.FETCH_PROPOSAL_BATCH_SUMMARIES_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_BATCH_SUMMARIES_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.DOWNLOAD_PROPOSAL_BATCH_SUMMARY,
    downloadProposalBatchSummarySaga,
  ),
  takeLatest(
    types.FETCH_PROPOSAL_BATCH_SUMMARIES,
    fetchProposalBatchSummariesSaga,
  ),
]
