import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import array from 'yup/lib/array'

import { ISRUploadStatusDisplayForTable } from './ISRUploadStatusDisplayForTable'
import { LinkStyled, TableWrapper } from './styles'
import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import {
  actions as isrDataActions,
  onDownload,
  selectISRData,
} from '../../../ducks/billing/ISR/isrTableItems'
import MultiSelectFilter from '../BillingThisMonth/components/MultiSelectFilter'

const ISRFileUploadDataTable = () => {
  const StatusDisplayCell = useCallback(
    ({
      row: {
        original: { fileUploadStatus, uploadedDate },
      },
    }) => (
      <ISRUploadStatusDisplayForTable
        status={fileUploadStatus}
        uploadDate={uploadedDate}
      />
    ),
    [],
  )
  const OpportunityIdLinkCell = useCallback(
    ({
      row: {
        original: { opportunityId, s3FileId, fileUploadStatus },
      },
    }) =>
      fileUploadStatus === 'SUCCESS' ?
        <LinkStyled onClick={() => onDownload(s3FileId)}>
          {opportunityId}
        </LinkStyled>
      : <>{opportunityId}</>,
    [],
  )
  const dispatch = useDispatch()
  const {
    isrData,
    isrDataMeta: { loading, error },
  } = useSelector(selectISRData)
  const [selectedStatus, setSelectedStatus] = useState<[]>([] as array)

  const tableData = useMemo(() => {
    if (!loading && !error)
      return [...isrData].sort(
        (a, b) => new Date(b.uploadedDate) - new Date(a.uploadedDate),
      )
    return []
  }, [isrData])

  const columns = useMemo(
    () => [
      {
        accessor: 'opportunityId',
        Header: 'Opportunity ID',
        hideSettings: false,
        id: 'opportunityId',
        maxWidth: 120,
        Cell: OpportunityIdLinkCell,
      },
      {
        accessor: 'billableCircuitsCount',
        Header: 'Billable Circuits',
        hideSettings: false,
        id: 'billableCircuitsCount',
        maxWidth: 120,
        Cell: ({ value }) => value || '_',
      },
      {
        accessor: 'uploadedBy',
        Header: 'Uploaded By',
        hideSettings: false,
        id: 'uploadedBy',
        maxWidth: 120,
      },
      {
        accessor: 'fileUploadStatus',
        Header: 'Status',
        hideSettings: false,
        id: 'fileUploadStatus',
        maxWidth: 120,
        Cell: StatusDisplayCell,
        filter: 'includesSome',
        Filter: (data) => (
          <MultiSelectFilter
            {...data}
            setSelectedFilter={setSelectedStatus}
            selectedFilter={selectedStatus}
          />
        ),
      },
    ],
    [selectedStatus],
  )

  useEffect(() => {
    dispatch(isrDataActions.fetchISRData())
  }, [dispatch])
  return (
    <TableWrapper>
      <RedaptiveReactTable7
        columns={columns}
        data={tableData}
        enableRowSelection
        globalFilterable={false}
        filterable
        enablePagination
      />
    </TableWrapper>
  )
}

export default ISRFileUploadDataTable
