import { Parser } from '@json2csv/plainjs'
import { useRollbarContext } from '@rollbar/react'
import Big from 'big.js'
import { capitalCase } from 'capital-case'
import FileSaver from 'file-saver'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { TbCsv, TbPdf } from 'react-icons/tb'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'

import { SpinnerStyles } from './CustomerListingPage'
import { multiCurrencyErrorText, salesTaxWarningValue } from './utils'
import AuthorizedFeatures from '../../../authorization/features'
import Breadcrumbs2 from '../../../components/Breadcrumbs2'
import { ButtonStyled } from '../../../components/Button2'
import Checkbox from '../../../components/Checkbox'
import DownloadVendorFile from '../../../components/DownloadVendorFile'
import DropdownMenuNew from '../../../components/DropdownMenuNew'
import AccountBalanceIcon from '../../../components/Icons/AccountBalanceIcon'
import AutorenewIcon from '../../../components/Icons/AutorenewIcon'
import CheckCircleOutlineIcon from '../../../components/Icons/CheckCircleOutlineIcon'
import Copy from '../../../components/Icons/Copy'
import EditNoteIcon from '../../../components/Icons/EditNoteIcon'
import HistoryIcon from '../../../components/Icons/HistoryIcon'
import SaveIcon from '../../../components/Icons/SaveIcon'
import ChevronDown from '../../../components/Icons/svg/ChevronDown'
import ChevronUp from '../../../components/Icons/svg/ChevronUp'
import { InputComponent } from '../../../components/Input'
import IonIcon, { IonIconStyles } from '../../../components/IonIcon'
import PageHeader from '../../../components/PageHeader'
import EditBatchAlert from '../../../components/ProposalsEngine/ModalAlerts/EditBatchAlert'
import RadioButton2 from '../../../components/RadioButton2'
import RedaptiveReactTable7, {
  HeaderActionsWrapper,
  ReactTableStyled,
  ReactTableWrapperStyles,
  SortIconsWrapperStyled,
  tableBorder,
  TdInnerStyled,
  TdStyled,
} from '../../../components/RedaptiveReactTable7'
import type { FTTableHeaderRow } from '../../../components/RedaptiveReactTable7/TableHead'
import TableHead from '../../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../../components/Spinner'
import StyledLink from '../../../components/StyledLink'
import UnsavedChanges from '../../../components/UnsavedChanges'
import { selectors as authSelectors } from '../../../ducks/auth'
import type { FTMessageInput } from '../../../ducks/messages'
import { actions as messagesActions } from '../../../ducks/messages'
import type {
  FTFetchProposalCustomerGlobalInputsAction,
  FTProposalCustomerGlobalInputs,
} from '../../../ducks/proposals/customerGlobalInputs'
import {
  actions as customerGlobalInputsActions,
  selectProposalCustomerGlobalInputsEntity,
} from '../../../ducks/proposals/customerGlobalInputs'
import type {
  FTFetchProposalCustomerSummariesAction,
  FTProposalCustomerSummary,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as customerSummariesActions,
  selectProposalCustomerSummariesEntity,
} from '../../../ducks/proposals/customerSummaries'
import type {
  FTCreateProposalBatchAction,
  FTFetchProposalBatchesAction,
  FTFetchProposalBatchFieldMappingAction,
  FTFetchProposalSiteAction,
  FTFieldType,
  FTProposalBatch,
  FTUpdateProposalBatchFieldMappingAction,
  FTUpdateProposalBatchStatusAction,
} from '../../../ducks/proposals/multisite/batches'
import {
  actions as batchActions,
  selectProposalBatchesEntity,
  selectProposalSiteEntity,
} from '../../../ducks/proposals/multisite/batches'
import type { FTProposalBatchSummaryDownloadAction } from '../../../ducks/proposals/multisite/batchSummaries'
import {
  actions as batchSummaryActions,
  selectProposalBatchSummariesEntity,
} from '../../../ducks/proposals/multisite/batchSummaries'
import type { FTFetchProposalOpportunitiesAction } from '../../../ducks/proposals/multisite/opportunities'
import { actions as opportunityActions } from '../../../ducks/proposals/multisite/opportunities'
import type {
  FTFetchProposalOpportunitySummariesAction,
  FTUpdateProposalOpportunityStatusAction,
} from '../../../ducks/proposals/opportunitySummaries'
import { actions as opportunitySummaryActions } from '../../../ducks/proposals/opportunitySummaries'
import type { FTProposalOpportunitySummaryDownloadMetaState } from '../../../ducks/proposals/opportunitySummaryDownload'
import {
  actions as opportunitySummaryDownloadActions,
  selectProposalOpportunitySummaryDownloadMeta,
} from '../../../ducks/proposals/opportunitySummaryDownload'
import type {
  FTUpdateProposalScenarioAction,
  FTUpdateProposalScenarioStatusAction,
} from '../../../ducks/proposals/scenarios'
import {
  actions as scenarioActions,
  columnStyles,
  formatScenarioFieldValueCurrency,
  formatScenarioFieldValuePercentage,
  getScenarioEnhancedWithCalculations,
  overrideScenarioFieldInfoOnBatch,
  scenarioFieldInfo as defaultScenarioFieldInfo,
  selectProposalScenarioEntity,
} from '../../../ducks/proposals/scenarios'
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_FORMAT_TIMESTAMP,
} from '../../../ducks/utils'
import type { FTWithRouter } from '../../../types'
import {
  getCurrencyFormat,
  getNameFromEMail,
  getNumberFormatMaxFractionDigits2,
  getNumberFormatMinFractionDigits2,
  naturallySortEmptyLastCaseInsensitive,
  zIndices,
} from '../../../utils'
import {
  batchReviewMappings,
  defaultBatchFieldMappings,
  defaultBatchHeaders,
} from '../../../utils/batchFieldMappings'
import { colors } from '../../../utils/themes'
// import FileDownloadIcon from '../../../components/Icons/FileDownloadIcon';
import { ActionsColumnStyles } from '../../Billing/BillingThisMonth/styles'
// import ActionPaneView from '../../../components/ActionPaneView';
import type { ComponentType } from 'react'

type FTProps = {
  actions: {
    fetchProposalBatches: (params: FTFetchProposalBatchesAction) => null
    fetchProposalCustomerGlobalInputs: (
      params: FTFetchProposalCustomerGlobalInputsAction | null | undefined,
    ) => null
    fetchProposalCustomerSummaries: (
      params: FTFetchProposalCustomerSummariesAction | null | undefined,
    ) => null
    fetchProposalOpportunities: (
      params: FTFetchProposalOpportunitiesAction,
    ) => null
    fetchProposalOpportunitySummaries: (
      params: FTFetchProposalOpportunitySummariesAction,
    ) => null
    fetchProposalBatchFieldMappings: (
      params: FTFetchProposalBatchFieldMappingAction,
    ) => null
    updateProposalBatchFieldMappings: (
      params: FTUpdateProposalBatchFieldMappingAction,
    ) => null
    downloadProposalBatchSummary: (
      props: FTProposalBatchSummaryDownloadAction,
    ) => null
    updateProposalBatch: (props: FTCreateProposalBatchAction) => null
    updateProposalBatchStatus: (
      props: FTUpdateProposalBatchStatusAction,
    ) => null
    updateProposalScenarioStatus: (
      props: FTUpdateProposalScenarioStatusAction,
    ) => null
    updateProposalOpportunityStatus: (
      props: FTUpdateProposalOpportunityStatusAction,
    ) => null
    updateProposalScenario: (params: FTUpdateProposalScenarioAction) => null
    hideMessage: (value: string) => null
    showMessage: (props: FTMessageInput) => null
    fetchProposalSite: (props: FTFetchProposalSiteAction) => null
  }
  authGroups: Array<string>
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  batchId: string
  batchDetails: FTProposalBatch
  batchDetailsLoading: boolean
  batchFieldMappings: Array<FTFieldType>
  batchUpdateError: string
  batchUpdateLoading: boolean
  customerSummariesById: Record<string, FTProposalCustomerSummary>
  downloadBatchSummaryLoading: boolean
  opportunitySummaryDownloadMeta: FTProposalOpportunitySummaryDownloadMetaState
  scenariosLoading: boolean
  scenariosLoadingError: string
} & FTWithRouter
const tableMarginBottom = 80
const CustomerOpportunitiesStyles = styled.div`
  font-family: 'Public Sans', sans-serif;
  padding-bottom: 80px;
  margin-top: 36px;

  * {
    box-sizing: border-box;
  }

  a {
    color: #3e5caa;
  }

  ${TdInnerStyled} {
    padding: 0px;
  }

  ${ReactTableStyled} {
    margin-bottom: ${tableMarginBottom}px;
  }

  ${SortIconsWrapperStyled} {
    text-align: right;
  }
`
const BreadcrumbsStyles = styled.div`
  margin-bottom: 24px;
`
const MetricsStyles = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`
const MetricStyles = styled.div`
  border: 1px solid #dadce0;
  box-shadow: 0 0 5px 1px rgba(105, 105, 105, 0.15);
  border-radius: 4px;
  display: inline-block;
  margin: 0 20px 20px 0;

  &:last-child {
    margin-right: 0;
  }
`
const MetricInnerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  padding: 14px;
  width: 220px;
`
const MetricTopStyles = styled.div`
  display: flex;
  justify-content: space-between;
`
const MetricBottomStyles = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: right;
`
const MetricIconStyles = styled.div`
  margin-right: 30px;
`
const MetricTitleStyles = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
`
const BatchTableContainerStyled = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #162447;

  ${ReactTableWrapperStyles} {
    overflow-x: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  ${HeaderActionsWrapper} {
    position: absolute;
    left: 0;

    ${ButtonStyled} {
      margin-top: 0px;
    }
  }
`
const AccountBalanceIconStyled: ComponentType<{
  color: string
}> = styled(AccountBalanceIcon)`
  color: ${({ color }) => color};
`
export const ActionsPopupStyled: ComponentType<{
  open: boolean
}> = styled.div`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  left: -220px;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 214px;
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
`
export const ActionStyled = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const ActionIconWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  margin-right: 5px;
`
const ActionsCellWrapperStyled = styled.div`
  position: relative;
`
export const ActionsIconStyled = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;

  ${IonIconStyles} {
    & > span {
      left: 8px;
      position: absolute;
      top: -7px;
    }
  }
`

const Metric = ({
  color,
  metric,
  title,
}: {
  color: string
  metric: number | string
  title: string
}) => (
  <MetricStyles>
    <MetricInnerStyles>
      <MetricTopStyles>
        <MetricIconStyles>
          <AccountBalanceIconStyled color={color} />
        </MetricIconStyles>
        <MetricTitleStyles>{title}</MetricTitleStyles>
      </MetricTopStyles>
      <MetricBottomStyles>{metric}</MetricBottomStyles>
    </MetricInnerStyles>
  </MetricStyles>
)

const NameLinkStyled = styled(StyledLink)`
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`
const TdStyledStyled = styled(TdStyled)`
  border-bottom: 0;
  border-top: ${tableBorder};
  padding: 0;

  &:first-child {
    border-top: ${({ row }) => (row.depth ? '0' : tableBorder)};
    border-right: 1px solid #e0e0e0;
  }

  tr:first-child > & {
    border-top: 0;
  }

  tr:last-child > & {
    border-bottom: ${tableBorder};
  }
`
const HeaderActionIconStyles = css`
  margin-right: 6px;
`
// const FileDownloadIconStyled = styled(FileDownloadIcon)`
//   ${HeaderActionIconStyles};
// `;
const HeaderActionStyled = styled.div`
  align-items: center;
  color: ${colors.blue3};
  cursor: pointer;
  display: flex;
  position: relative;
`
const HeaderActionsStyled = styled.div`
  align-items: center;
  color: ${colors.blue3};
  display: flex;
  font-size: 12px;

  ${HeaderActionStyled} + ${HeaderActionStyled} {
    margin-left: 32px;
  }
`
const AutorenewIconIconStyled = styled(AutorenewIcon)`
  ${HeaderActionIconStyles};
`
const SaveIconStyled = styled(SaveIcon)`
  width: 30px;
`
const HistoryIconStyled = styled(HistoryIcon)`
  width: 30px;
`
const HeaderActionBodyLoadingStyles = css`
  opacity: 0.5;
`
const HeaderActionBodyStyled: ComponentType<{
  $loading: boolean
}> = styled.div`
  align-items: center;
  display: flex;
  ${({ $loading }) => $loading && HeaderActionBodyLoadingStyles};
`
const DownloadSummaryActionSpinnerStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
`
const ExpandableStyles = css`
  border-left: 1px solid #e0e0e0;
`
const ExpanderCellStyled: ComponentType<{
  $expandable: boolean
}> = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 4px;
  }

  ${({ $expandable }) => $expandable && ExpandableStyles};
`
const OpportunityCellStyled = styled.div`
  ${ExpanderCellStyled} {
    border: 0;
  }
`
const SelectionCellStyled = styled.div`
  width: 60px;
  height: 60px;
  border-right: 1px #e0e0e0 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 0px 4px rgba(144, 151, 165, 0.25);
`
const EditableCellStyled = styled.div`
  width: 60px;
`
const ScenarioIndicatorStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`
const ActiveScenarioIndicatorStyled = styled.div`
  width: 6px;
  height: 59px;

  background: #7bcc76;
  border-radius: 0px 2px 2px 0px;
`
const FilterStyles = css`
  vertical-align: middle;
`
const ScenarioNameStyles = css`
  padding-left: 79px;
  border-left: 1px solid #e0e0e0;
  ${FilterStyles};
`
const OpportunityHeaderStyles = css`
  padding-left: 30px;
  position: sticky;
  ${FilterStyles};
`
const HeaderStyles = {
  name: ScenarioNameStyles,
  redaptiveOpportunityId: OpportunityHeaderStyles,
}
const ArrowsStyled = styled.div`
  padding-left: 22px;
  padding-right: 18px;
`
const InputStyles = styled(InputComponent)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
`
const DownloadDropDownWrapper = styled.div`
  margin-left: 20px;
  .title {
    font-weight: 400;
  }
`
const currencySet = new Set([
  'preTaxContractValue',
  'totalContractValueWithSalesTax',
  'netMarginInDollars',
  'partnerFee',
  'referralCost',
  'contingencyCost',
  'upFrontMeasurementAndVerification',
  'finalRebateAmount',
  'annualEnergySavingsInDollars',
  'annualMaintenanceSavings',
  'costReduction',
  'upFrontPayment',
  'utilityRate',
  'contractRate',
])
const percentageSet = new Set([
  'energyRetainedSavingsInPercentage',
  'maintenanceRetainedSavingsInPercentage',
  'netMarginInPercentage',
  'internalRateOfReturn',
  'grossMarginInPercentage',
  'partnerFeeInPercentage',
  'referralFeeInPercentage',
  'contingencyInPercentage',
  'rebateHcInPercentage',
])

const formatDateStringForView = ({ value }) =>
  moment(value, DATE_FORMAT_DATA_API_RESPONSE).format(DATE_FORMAT_TIMESTAMP)

const convertEmailToNameForView = ({ value }) => getNameFromEMail(value)

const CopyIconStyled = styled(Copy)`
  ${HeaderActionIconStyles};
`
type FTSort = {
  id: string
  desc: boolean
}
const initialSort = {
  id: '',
  desc: false,
}
const scenarioFieldInfo = {
  ...defaultScenarioFieldInfo,
  ...overrideScenarioFieldInfoOnBatch,
}

const EditableTermCellStyled = ({
  row,
  value,
  isProposalOperationsUser,
  dirtyId,
  setDirtyId,
  handleTermChange,
}: any) => {
  const {
    original: { id },
  } = row
  const [tempState, setTempState] = React.useState(value)
  return (
    <EditableCellStyled>
      {!isProposalOperationsUser ?
        <div>{tempState}</div>
      : <>
          {dirtyId !== id && (
            <div onClick={() => setDirtyId(id)}>{tempState}</div>
          )}
          {dirtyId === id && (
            <form
              onSubmit={(e) => e.preventDefault()}
              onBlur={() => {
                if (tempState !== value) {
                  handleTermChange(id, tempState)
                }

                setDirtyId('')
              }}
            >
              <InputStyles
                value={tempState}
                onChange={(e) => {
                  const parsedValue = parseInt(e.target.value, 10)
                  setTempState(!Number.isNaN(parsedValue) ? parsedValue : 0)
                }}
              />
            </form>
          )}
        </>
      }
    </EditableCellStyled>
  )
}

const EditableRetainedSavingsCellStyled = ({
  row,
  value,
  isProposalOperationsUser,
  dirtyId,
  setDirtyId,
  handleRetainedSavingsChange,
}: any) => {
  const {
    original: { id },
  } = row
  const numberFormatMinFractionDigits2 =
    getNumberFormatMinFractionDigits2('en-US')
  const [tempState, setTempState] = React.useState(value)
  return (
    <EditableCellStyled>
      {!isProposalOperationsUser ?
        <div>{tempState}</div>
      : <>
          {dirtyId !== id && (
            <div onClick={() => setDirtyId(id)}>
              {`${numberFormatMinFractionDigits2.format(tempState)}%`}
            </div>
          )}
          {dirtyId === id && (
            <form
              onSubmit={(e) => e.preventDefault()}
              onBlur={() => {
                const parsedValue = parseInt(tempState, 10)

                if (parsedValue !== value && !Number.isNaN(parsedValue)) {
                  handleRetainedSavingsChange(id, parsedValue)
                } else {
                  setTempState(value)
                }

                setDirtyId('')
              }}
            >
              <InputStyles
                value={tempState}
                onChange={(e) => {
                  const inputValue = e.target.value
                  const isValidInput =
                    /^-?\d*$/.test(inputValue) &&
                    inputValue.split('-').length <= 2

                  if (isValidInput) {
                    setTempState(inputValue)
                  }
                }}
              />
            </form>
          )}
        </>
      }
    </EditableCellStyled>
  )
}

const EditableMaintenanceRetainedSavingsCellStyled = ({
  row,
  value,
  isProposalOperationsUser,
  dirtyId,
  setDirtyId,
  handleMaintenanceRetainedSavingsChange,
}: any) => {
  const {
    original: { id },
  } = row
  const numberFormatMinFractionDigits2 =
    getNumberFormatMinFractionDigits2('en-US')
  const [tempState, setTempState] = React.useState(value)
  return (
    <EditableCellStyled>
      {!isProposalOperationsUser ?
        <div>{tempState}</div>
      : <>
          {dirtyId !== id && (
            <div onClick={() => setDirtyId(id)}>
              {`${numberFormatMinFractionDigits2.format(tempState)}%`}
            </div>
          )}
          {dirtyId === id && (
            <form
              onSubmit={(e) => e.preventDefault()}
              onBlur={() => {
                const parsedValue = parseInt(tempState, 10)

                if (parsedValue !== value && !Number.isNaN(parsedValue)) {
                  handleMaintenanceRetainedSavingsChange(id, parsedValue)
                } else {
                  setTempState(value)
                }

                setDirtyId('')
              }}
            >
              <InputStyles
                value={tempState}
                onChange={(e) => {
                  const inputValue = e.target.value
                  const isValidInput =
                    /^-?\d*$/.test(inputValue) &&
                    inputValue.split('-').length <= 2

                  if (isValidInput) {
                    setTempState(inputValue)
                  }
                }}
              />
            </form>
          )}
        </>
      }
    </EditableCellStyled>
  )
}

const BatchAnalysisPage = (props: FTProps) => {
  const {
    actions,
    authGroups,
    customerGlobalInputs,
    batchDetails,
    batchDetailsLoading,
    batchFieldMappings,
    batchUpdateError,
    batchUpdateLoading,
    customerSummariesById,
    downloadBatchSummaryLoading,
    history,
    scenariosLoading,
    scenariosLoadingError,
    sites,
    permissions,
    match: {
      params: { batchId, salesforceCustomerId },
    },
  } = props
  const isProposalOperationsUser = React.useMemo(
    () =>
      authGroups.some((authGroup) =>
        ['proposal-operations-admin', 'proposal-operations'].includes(
          authGroup,
        ),
      ),
    [authGroups],
  )
  const showColumnSettings = React.useMemo(() =>
    AuthorizedFeatures.editProposalFieldMappings.anyMatchesWithPermissions(
      permissions,
    ),
  )
  const alwaysLockedColumns = React.useMemo(
    () => ['redaptiveOpportunityId', 'name'],
    [],
  )
  const { locale = 'en-US' } = customerGlobalInputs || {}
  const customerSummary = customerSummariesById[salesforceCustomerId] || {}
  const { name: customerName = '' } = customerSummary || {}
  const [currencyCode, setCurrencyCode] = React.useState('')
  const [heroMetricsCurrencyCode, setHeroMetricsCurrencyCode] =
    React.useState('USD')
  const heroMetricscurrencyFormat = getCurrencyFormat(
    locale,
    heroMetricsCurrencyCode,
  )
  const [isMultiCurrency, setIsMultiCurrency] = React.useState(false)
  const [showMultiCurrencyErrorPopUp, setShowMultiCurrencyErrorPopUp] =
    React.useState(false)
  const numberFormatMinFractionDigits2 =
    getNumberFormatMinFractionDigits2('en-US')
  const [totalAssetCount, setTotalAssetCount] = React.useState(0)
  const [netUpfront, setNetUpfront] = React.useState(0)
  const [totalPortfolioMargin, setTotalPortfolioMargin] = React.useState(0)
  const [totalGrossMarginPercentage, setTotalGrossMarginPercentage] =
    React.useState(0)
  const [totalPortfolioMarginPercentage, setTotalPortfolioMarginPercentage] =
    React.useState(0)
  const [preTaxContractValue, setPreTaxContractValue] = React.useState(0)
  const [totalNPVRevenue, setTotalNPVRevenue] = React.useState(0)
  const [actionsPopupId, setActionsPopupId] = React.useState('')
  const [dirtyId, setDirtyId] = React.useState('')
  const [scenariosSort, setScenariosSort] = React.useState(initialSort)
  const [sortOpportunityDesc, setSortOpportunityDesc] = React.useState(false)
  const [selectedScenarios, setSelectedScenarios] = React.useState([])
  const [selectedOpportunityScenarioObj, setSelectedOpportunityScenarioObj] =
    React.useState({})
  const [scenariosStatusMap, setScenariosStatusMap] = React.useState(new Map())
  const [initialScenariosStatusMap, setInitialScenariosStatusMap] =
    React.useState(new Map())
  const [modifiedScenariosMap, setModifiedScenariosMap] = React.useState(
    new Map(),
  )
  const [revertFlag, setRevertFlag] = React.useState(false)
  const [hiddenColumns, setHiddenColumns] = React.useState([])
  const [columnsOrder, setColumnsOrder] = React.useState([])
  const [lockedColumnsArray, setLockedColumnsArray] = React.useState([
    ...alwaysLockedColumns,
  ])
  const [updatedFieldMappings, setUpdatedFieldMappings] = useState(
    batchFieldMappings.length ? batchFieldMappings : defaultBatchFieldMappings,
  )
  const tableInstanceRef = React.useRef()
  const popupRef = React.useRef()
  const site = sites[0]
  const numberFormatMaxFractionDigits2 =
    getNumberFormatMaxFractionDigits2('en-US')
  const isMountedProposalBatch = useRef(false)
  useRollbarContext('Batch Analysis Page')
  const handleSortClick = React.useCallback(
    ({
      target: {
        dataset: { id },
      },
    }) => {
      if (scenariosSort.id !== id) {
        setScenariosSort({
          id,
          desc: false,
        })
      } else if (scenariosSort.desc) {
        setScenariosSort(initialSort)
      } else {
        setScenariosSort({
          id,
          desc: true,
        })
      }
    },
    [scenariosSort],
  )
  React.useEffect(() => {
    actions.fetchProposalBatches({
      batchId,
    })
    actions.fetchProposalBatchFieldMappings({
      batchId,
    })

    if (!Object.keys(customerSummariesById).length) {
      actions.fetchProposalCustomerSummaries()
    }
  }, [])
  // Reload batchDetails whenever the Batch or Batch status is updated.
  React.useEffect(() => {
    if (isMountedProposalBatch.current) {
      if (!batchUpdateLoading) {
        actions.fetchProposalBatches({
          batchId,
        })
      }
    } else {
      isMountedProposalBatch.current = true
    }
  }, [batchUpdateLoading])
  // Show error message when UpdateSalesforce fails
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (batchUpdateError !== '' && !batchUpdateLoading) {
      actions.showMessage({
        messageId: 'updatingBatchFailed',
        title: batchUpdateError,
        type: 'error',
      })
      setTimeout(() => actions.hideMessage('updatingBatchFailed'), 4000)
    }
  }, [batchUpdateError])
  const hidePopUp = (): void => {
    setShowMultiCurrencyErrorPopUp(false)
  }
  const EditBatchAlertModal = (
    <EditBatchAlert
      text={multiCurrencyErrorText} // eslint-disable-next-line react/jsx-props-no-spreading
      type='danger'
      heading='Batch Edit Failed'
      onClose={hidePopUp}
      showFooter
    />
  )
  React.useEffect(() => {
    if (batchDetails.opportunities && batchDetails.opportunities.length) {
      setSelectedOpportunityScenarioObj(
        batchDetails.opportunities
          .filter(
            (opportunity) =>
              opportunity.scenarios &&
              opportunity.scenarios.length &&
              opportunity.selectedInBatchAnalysis,
          )
          .reduce(
            (acc, curr) => ({
              ...acc,
              [curr.externalId]: curr.selectedScenarioIdInBatch,
            }),
            {},
          ),
      )
      const selectedOpportunities = batchDetails.opportunities.filter(
        (opportunity) => opportunity.selectedInBatchAnalysis === true,
      )
      setIsMultiCurrency(false)
      selectedOpportunities.forEach((opportunity) => {
        if (opportunity.selectedInBatchAnalysis) {
          const currenctCurrency = opportunity.currencyCode
          const initialCurrency = selectedOpportunities[0].currencyCode
          setCurrencyCode(initialCurrency)
          setHeroMetricsCurrencyCode(initialCurrency)
          if (currenctCurrency !== initialCurrency) {
            setIsMultiCurrency(true)
            setHeroMetricsCurrencyCode('USD')
          }
        }
      })

      // Populate the scenariosStatusMap with the initial scenario statuses
      batchDetails.opportunities
        .filter(
          (opportunity) =>
            opportunity.scenarios && opportunity.scenarios.length,
        )
        .forEach((opportunity) => {
          opportunity.scenarios.forEach((scenario) =>
            scenariosStatusMap.set(scenario.id, {
              status: scenario.status,
            }),
          )
        })
      setInitialScenariosStatusMap(new Map(Array.from(scenariosStatusMap)))
      setScenariosStatusMap(new Map(Array.from(scenariosStatusMap)))
    }
  }, [
    batchDetails.opportunities,
    revertFlag,
    heroMetricsCurrencyCode,
    currencyCode,
  ])
  const initialSelectedScenariosInBatch = React.useMemo(
    () =>
      new Set(
        batchDetails.opportunities && batchDetails.opportunities.length ?
          batchDetails.opportunities
            .filter(
              (opportunity) =>
                opportunity.scenarios &&
                opportunity.scenarios.length &&
                opportunity.selectedInBatchAnalysis,
            )
            .map((opportunity) => opportunity.selectedScenarioIdInBatch)
        : [],
      ),
    [batchDetails.opportunities],
  )
  const opportunityScenarios = React.useMemo(
    () =>
      batchDetails.opportunities && batchDetails.opportunities.length ?
        batchDetails.opportunities
          .filter(
            (opportunity) =>
              opportunity.scenarios &&
              opportunity.scenarios.length &&
              opportunity.selectedInBatchAnalysis,
          )
          .map((opportunity) => {
            const enhancedScenarios = opportunity.scenarios.map((scenario) => {
              if (modifiedScenariosMap.has(scenario.id)) {
                return {
                  externalId: opportunity.externalId,
                  redaptiveOpportunityId: opportunity.redaptiveOpportunityId,
                  selectedScenarioIdInBatch:
                    opportunity.selectedScenarioIdInBatch,
                  ...scenario,
                  // Override the status property with the new status
                  ...scenariosStatusMap.get(scenario.id),
                  // Override the other properties with the calculated values
                  ...getScenarioEnhancedWithCalculations({
                    ...scenario,
                    ...modifiedScenariosMap.get(scenario.id),
                    site,
                  }),
                }
              }

              return {
                externalId: opportunity.externalId,
                redaptiveOpportunityId: opportunity.redaptiveOpportunityId,
                selectedScenarioIdInBatch:
                  opportunity.selectedScenarioIdInBatch,
                ...scenario,
                // Override the status property with the new status
                ...scenariosStatusMap.get(scenario.id),
              }
            })
            return { ...opportunity, scenarios: enhancedScenarios }
          })
      : [],
    [
      batchDetails,
      scenariosStatusMap,
      modifiedScenariosMap,
      sortOpportunityDesc,
      scenariosSort,
    ],
  )
  React.useEffect(() => {
    if (scenariosLoadingError) {
      actions.showMessage({
        messageId: 'savingScenariosFailed',
        title: 'There was an issue saving the changes. Please try again.',
        type: 'error',
      })
      setTimeout(() => actions.hideMessage('savingScenariosFailed'), 4000)
    }

    if (
      scenariosLoadingError === '' &&
      !scenariosLoading &&
      modifiedScenariosMap.size
    ) {
      actions.showMessage({
        messageId: 'savingScenariosSuccess',
        title: 'Your edits have been successfully saved.',
        type: 'success',
      })
      setTimeout(() => actions.hideMessage('savingScenariosSuccess'), 2000)
      actions.fetchProposalBatches({
        batchId,
      })
      setModifiedScenariosMap(new Map())
    }
  }, [scenariosLoading, scenariosLoadingError])
  const opportunityScenariosObj = React.useMemo(
    () =>
      opportunityScenarios.length ?
        opportunityScenarios.reduce((acc, curr) => {
          const scenarioIds = curr.scenarios.map((item) => item.id)
          return { ...acc, [curr.externalId]: scenarioIds }
        }, {})
      : {},
    [opportunityScenarios],
  )
  const enhancedScenarios = React.useMemo(
    () =>
      opportunityScenarios.length ?
        opportunityScenarios.reduce(
          (acc, curr) => [...acc, ...curr.scenarios],
          [],
        )
      : [],
    [opportunityScenarios],
  )
  const scenariosById = React.useMemo(
    () =>
      enhancedScenarios.length ?
        enhancedScenarios.reduce((a, b) => ({ ...a, [b.id]: { ...b } }), {})
      : {},
    [enhancedScenarios],
  )
  React.useEffect(() => {
    setSelectedScenarios(
      Object.values(selectedOpportunityScenarioObj).filter((item) => item),
    )
  }, [selectedOpportunityScenarioObj, scenariosById])
  React.useEffect(() => {
    if (batchDetails.opportunities && batchDetails.opportunities.length) {
      actions.fetchProposalSite({
        id: batchDetails.opportunities[0].salesforceSiteId,
      })
    }
  }, [batchDetails.opportunities])
  // Show buttons if all the selected scenarios are Active scenarios
  const showBatchChangeButtons = React.useMemo(
    () =>
      selectedScenarios.every(
        (scenarioId) =>
          scenariosStatusMap.get(scenarioId)?.status === 'APPROVED',
      ),
    [selectedScenarios, scenariosStatusMap],
  )
  const showScenarioChangeButtons = React.useMemo(
    () => !!modifiedScenariosMap.size,
    [modifiedScenariosMap],
  )
  const compareStatusMaps = React.useCallback((map1, map2) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, val] of map1) {
      const testVal = map2.get(key)

      // in cases of an undefined value, make sure the key
      // actually exists on the object so there are no false positives
      if (testVal !== val || (testVal === undefined && !map2.has(key))) {
        return false
      }
    }

    return true
  }, [])

  const enhancedScenarioRowsData = (
    scenarioData: any[],
    currencyCode: string,
  ) =>
    scenarioData.map((value) => {
      const updatedValue = { ...value }
      const opportunityLevelCurrencyFormat = getCurrencyFormat(
        locale,
        currencyCode,
      )
      Object.keys(value).forEach((key) => {
        const { fieldType = '' } = scenarioFieldInfo[key] || {}
        if (fieldType === 'currency') {
          updatedValue[key] = opportunityLevelCurrencyFormat.format(value[key])
        }
      })

      if (value.estimatedSalesTaxInPercentage === salesTaxWarningValue) {
        return { ...updatedValue, estimatedSalesTax: 'Not Calculated' }
      }

      return {
        ...updatedValue,
        estimatedSalesTax: opportunityLevelCurrencyFormat.format(
          value.estimatedSalesTax,
        ),
      }
    })

  const isBatchDirty = React.useMemo(() => {
    const initialOpportunities = Object.keys(opportunityScenariosObj)
    const selectedOpportunities = Object.keys(selectedOpportunityScenarioObj)
    return (
      initialOpportunities.length !== selectedOpportunities.length ||
      !selectedScenarios.every((scenarioId) =>
        initialSelectedScenariosInBatch.has(scenarioId),
      ) ||
      !compareStatusMaps(initialScenariosStatusMap, scenariosStatusMap)
    )
  }, [
    selectedOpportunityScenarioObj,
    opportunityScenariosObj,
    initialSelectedScenariosInBatch,
    selectedScenarios,
    scenariosStatusMap,
  ])
  const sortedOpportunityScenarios = React.useMemo(() => {
    const validOpportunityScenarios = opportunityScenarios
      .slice()
      .sort((item1, item2) =>
        sortOpportunityDesc ?
          naturallySortEmptyLastCaseInsensitive(
            item2.redaptiveOpportunityId,
            item1.redaptiveOpportunityId,
            sortOpportunityDesc,
          )
        : naturallySortEmptyLastCaseInsensitive(
            item1.redaptiveOpportunityId,
            item2.redaptiveOpportunityId,
            sortOpportunityDesc,
          ),
      )
      .filter((opportunity) => opportunity.scenarios.length)
    return validOpportunityScenarios.reduce((acc, curr, index) => {
      const rows = validOpportunityScenarios[index].scenarios
      // Sort based on selected scenario
      const sortedBasedOnSelectedRows = rows.sort((a) =>
        a.id === curr.selectedScenarioIdInBatch ? -1 : 0,
      )
      const enhancedRows = enhancedScenarioRowsData(rows, curr.currencyCode)

      if (rows.length > 1) {
        if (scenariosSort.id) {
          sortedBasedOnSelectedRows.sort((rowA, rowB) =>
            scenariosSort.desc ?
              naturallySortEmptyLastCaseInsensitive(
                rowB[scenariosSort.id],
                rowA[scenariosSort.id],
                scenariosSort.desc,
              )
            : naturallySortEmptyLastCaseInsensitive(
                rowA[scenariosSort.id],
                rowB[scenariosSort.id],
                scenariosSort.desc,
              ),
          )
        }

        const firstRow = enhancedRows.shift()
        return [...acc, { ...firstRow, subRows: enhancedRows }]
      }

      return [...acc, ...sortedBasedOnSelectedRows]
    }, [])
  }, [scenariosSort, sortOpportunityDesc, opportunityScenarios])
  React.useEffect(() => {
    let assetCount = 0
    let upfrontCosts = 0
    let netMarginInDollars = 0
    let contractValue = 0
    let npvNetRevenue = 0

    if (selectedScenarios.length && Object.keys(scenariosById).length) {
      selectedScenarios.forEach((id) => {
        // $FlowFixMe
        const scenario = scenariosById[id]

        if (scenario) {
          assetCount += scenario.totalProposedFixtureCount
          upfrontCosts += scenario.netCost
          netMarginInDollars += scenario.netMarginInDollars
          contractValue += scenario.preTaxContractValue
          npvNetRevenue += scenario.npvNetRevenue
        }
      })
    }

    const grossMargin = contractValue - upfrontCosts
    setTotalAssetCount(assetCount)
    setNetUpfront(upfrontCosts)
    setTotalPortfolioMargin(netMarginInDollars)
    setPreTaxContractValue(contractValue)
    setTotalNPVRevenue(npvNetRevenue)
    setTotalPortfolioMarginPercentage(
      (npvNetRevenue ? (netMarginInDollars / npvNetRevenue) * 100 : 0).toFixed(
        2,
      ),
    )
    setTotalGrossMarginPercentage(
      (contractValue ? (grossMargin / contractValue) * 100 : 0).toFixed(2),
    )
  }, [selectedScenarios, scenariosById, modifiedScenariosMap])
  React.useEffect(() => {
    setUpdatedFieldMappings(
      batchFieldMappings.length ? batchFieldMappings : (
        defaultBatchFieldMappings
      ),
    )
  }, [batchFieldMappings])
  const breadcrumbs = [
    {
      href: '/proposal-operations/proposals-engine',
      text: 'Proposal Engine',
    },
    {
      href: '/proposal-operations/proposals-engine/',
      text: 'Customers',
    },
    {
      href: `/proposal-operations/proposals-engine/${salesforceCustomerId}/batches`,
      text: customerName,
    },
    {
      href: `/proposal-operations/proposals-engine/${salesforceCustomerId}/batch-analysis/${batchId}`,
      text: batchDetails && batchDetails.name,
    },
  ]
  const getColumnHeader = React.useCallback(({ column: { id } }) => {
    if (id === 'opportunityCurrencyCode') {
      return 'Currency'
    }

    return scenarioFieldInfo[id]?.label || 'Opportunity ID'
  }, [])
  const handleActivateScenarioClick = React.useCallback(
    (id: string, opportunityId: string) => () => {
      opportunityScenariosObj[opportunityId].forEach((scenarioId) => {
        scenariosStatusMap.set(scenarioId, {
          status: 'ARCHIVED',
        })
      })
      scenariosStatusMap.set(id, {
        status: 'APPROVED',
      })
      setScenariosStatusMap(new Map(Array.from(scenariosStatusMap)))
      setActionsPopupId('')
      setSelectedOpportunityScenarioObj((prevState) => ({
        ...prevState,
        [opportunityId]: id,
      }))
    },
    [scenariosStatusMap],
  )
  const handleSetPendingScenarioClick = React.useCallback(
    (id: string) => () => {
      scenariosStatusMap.set(id, {
        status: 'PENDING',
      })
      setScenariosStatusMap(new Map(Array.from(scenariosStatusMap)))
      setActionsPopupId('')
    },
    [scenariosStatusMap],
  )

  const ActionsPopup = ({
    open,
    id,
    opportunityId,
    status,
  }: {
    open: boolean
    id: string
    opportunityId: string
    status: string
  }) => (
    <ActionsPopupStyled open={open}>
      {(status === 'PENDING' || status === 'ARCHIVED') && (
        <ActionStyled onClick={handleActivateScenarioClick(id, opportunityId)}>
          <ActionIconWrapperStyled>
            <CheckCircleOutlineIcon fontSize='24px' />
          </ActionIconWrapperStyled>
          Mark as Active
        </ActionStyled>
      )}
      {status === 'APPROVED' && (
        <ActionStyled onClick={handleSetPendingScenarioClick(id)}>
          <ActionIconWrapperStyled>
            <CheckCircleOutlineIcon fontSize='24px' />
          </ActionIconWrapperStyled>
          Mark as Pending
        </ActionStyled>
      )}
    </ActionsPopupStyled>
  )

  const handleKeydown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && actionsPopupId) {
        setActionsPopupId('')
      }
    },
    [actionsPopupId],
  )

  // TODO: Consider adding an alpha layer to handle the click outside instead of dealing with refs
  // Close the action popup upon clicking outside
  const handleMousedown = (event: MouseEvent) => {
    if (
      popupRef &&
      popupRef.current &&
      !popupRef.current.contains(event.target)
    ) {
      setActionsPopupId('')
    }
  }

  React.useEffect(() => {
    if (actionsPopupId) {
      document.addEventListener('keydown', handleKeydown, true)
      document.addEventListener('click', handleMousedown, true)
    } else {
      document.removeEventListener('keydown', handleKeydown, true)
      document.removeEventListener('click', handleMousedown, true)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown, true)
    }
  }, [actionsPopupId])
  const getActionsCell = React.useCallback(
    (cellProps) => {
      const {
        row: {
          original: { id, externalId, status },
        },
      } = cellProps

      if (!isProposalOperationsUser) {
        return ''
      }

      return (
        <ActionsCellWrapperStyled ref={actionsPopupId === id ? popupRef : null}>
          <ActionsIconStyled
            onClick={() => {
              setActionsPopupId(actionsPopupId !== id ? id : '')
            }}
          >
            <IonIcon fontSize='24px' iconClass='ion-android-more-vertical' />
          </ActionsIconStyled>
          <ActionsPopup
            id={id}
            opportunityId={externalId}
            history={history}
            open={actionsPopupId === id}
            status={status}
          />
        </ActionsCellWrapperStyled>
      )
    },
    [actionsPopupId, scenariosStatusMap],
  )

  const handleTermChange = (id, newValue) => {
    let valueWithinLimits = newValue
    if (newValue < 0) valueWithinLimits = 0
    if (newValue > 360) valueWithinLimits = 360
    modifiedScenariosMap.set(id, {
      ...modifiedScenariosMap.get(id),
      contractTermMonths: valueWithinLimits,
    })
    setModifiedScenariosMap(new Map(Array.from(modifiedScenariosMap)))
  }

  const handleRetainedSavingsChange = (id, newValue) => {
    let valueWithinLimits = newValue
    if (newValue > 100) valueWithinLimits = 100
    modifiedScenariosMap.set(id, {
      ...modifiedScenariosMap.get(id),
      energyRetainedSavingsInPercentage: valueWithinLimits,
    })
    setModifiedScenariosMap(new Map(Array.from(modifiedScenariosMap)))
  }

  const handleMaintenanceRetainedSavingsChange = (id, newValue) => {
    let valueWithinLimits = newValue
    if (newValue > 100) valueWithinLimits = 100
    modifiedScenariosMap.set(id, {
      ...modifiedScenariosMap.get(id),
      maintenanceRetainedSavingsInPercentage: valueWithinLimits,
    })
    setModifiedScenariosMap(new Map(Array.from(modifiedScenariosMap)))
  }

  const handleOpportuntiySelection = React.useCallback(
    (event, opportunityId) => {
      if (!isProposalOperationsUser) {
        return
      }
      if (event.target.checked) {
        if (batchDetails && batchDetails.opportunities) {
          const selectedScenarioId = batchDetails.opportunities.find(
            (opportunity) => opportunity.externalId === opportunityId,
          )?.selectedScenarioIdInBatch
          setSelectedOpportunityScenarioObj((prevState) => ({
            ...prevState,
            [opportunityId]: selectedScenarioId,
          }))
          setSelectedScenarios((prevState) => [
            ...prevState,
            selectedScenarioId,
          ])
        }
      } else {
        setSelectedOpportunityScenarioObj((prevState) => {
          // eslint-disable-next-line no-param-reassign
          delete prevState[opportunityId]
          return { ...prevState }
        })
      }
    },
    [batchDetails.opportunities],
  )
  const handleScenarioSelection = React.useCallback(
    (e, opportunityId, scenarioId) => {
      if (e.target.checked && isProposalOperationsUser) {
        setSelectedOpportunityScenarioObj((prevState) => ({
          ...prevState,
          [opportunityId]: scenarioId,
        }))
      }
    },
    [],
  )

  const EditableTermCell = React.useCallback(
    ({ row, value }: any) => (
      <EditableTermCellStyled
        row={row}
        value={value}
        isProposalOperationsUser={isProposalOperationsUser}
        dirtyId={dirtyId}
        setDirtyId={setDirtyId}
        handleTermChange={handleTermChange}
      />
    ),
    [handleTermChange, setDirtyId, isProposalOperationsUser],
  )

  const EditableRetainedSavingsCell = React.useCallback(
    ({ row, value }: any) => (
      <EditableRetainedSavingsCellStyled
        row={row}
        value={value}
        isProposalOperationsUser={isProposalOperationsUser}
        dirtyId={dirtyId}
        setDirtyId={setDirtyId}
        handleRetainedSavingsChange={handleRetainedSavingsChange}
      />
    ),
    [handleRetainedSavingsChange, setDirtyId],
  )
  const EditableMaintenanceRetainedSavingsCell = React.useCallback(
    ({ row, value }: any) => (
      <EditableMaintenanceRetainedSavingsCellStyled
        row={row}
        value={value}
        isProposalOperationsUser={isProposalOperationsUser}
        dirtyId={dirtyId}
        setDirtyId={setDirtyId}
        handleMaintenanceRetainedSavingsChange={
          handleMaintenanceRetainedSavingsChange
        }
      />
    ),
    [handleMaintenanceRetainedSavingsChange, setDirtyId],
  )

  const cellNumber2DigitsPercentage = React.useCallback(
    ({ value }) =>
      formatScenarioFieldValuePercentage({
        locale,
        value,
      }),
    [locale],
  )
  const ScenarioNameCell = React.useCallback(
    ({ row, value }: any) => {
      const { externalId, id, status } = row.original
      return (
        <ExpanderCellStyled $expandable>
          <ScenarioIndicatorStyled>
            <SelectionCellStyled>
              <RadioButton2
                checked={selectedOpportunityScenarioObj[externalId] === id}
                onChange={(e) => handleScenarioSelection(e, externalId, id)}
              />
            </SelectionCellStyled>
            {status === 'APPROVED' ?
              <ActiveScenarioIndicatorStyled />
            : null}
          </ScenarioIndicatorStyled>
          {value}
        </ExpanderCellStyled>
      )
    },
    [selectedOpportunityScenarioObj, handleScenarioSelection],
  )
  const OpportunityIdCell = React.useCallback(
    ({ row, value }: any) => {
      const { externalId } = row.original

      if (row.canExpand) {
        return (
          <OpportunityCellStyled>
            <ExpanderCellStyled $expandable>
              <Checkbox
                checked={Object.keys(selectedOpportunityScenarioObj).includes(
                  externalId,
                )}
                onChange={(e) => handleOpportuntiySelection(e, externalId)}
              />
              <NameLinkStyled
                href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${externalId}`}
              >
                {value}
              </NameLinkStyled>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <ArrowsStyled {...row.getToggleRowExpandedProps()}>
                {row.isExpanded && <ChevronUp />}
                {!row.isExpanded && <ChevronDown />}
              </ArrowsStyled>
            </ExpanderCellStyled>
          </OpportunityCellStyled>
        )
      }

      if (!row.depth) {
        return (
          <ExpanderCellStyled $expandable={false}>
            <Checkbox
              checked={Object.keys(selectedOpportunityScenarioObj).includes(
                externalId,
              )}
              onChange={(e) => handleOpportuntiySelection(e, externalId)}
            />
            <NameLinkStyled
              href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${externalId}`}
            >
              {value}
            </NameLinkStyled>
          </ExpanderCellStyled>
        )
      }

      return row.depth ? '' : value
    },
    [handleOpportuntiySelection, selectedOpportunityScenarioObj],
  )
  const getDefaultColumnsCell = React.useCallback(
    (fieldType, cellProps) => {
      const { value } = cellProps

      switch (fieldType) {
        case 'percentage':
          return cellNumber2DigitsPercentage(cellProps)

        case 'string':
          return value || ''

        default:
          return value
      }
    },
    [cellNumber2DigitsPercentage],
  )

  const renderVendorProposal = (row) => {
    const {
      original: { vendorProposalScenarioFileName, vendorProposalScenarioId },
    } = row

    if (vendorProposalScenarioFileName && vendorProposalScenarioId) {
      return (
        <DownloadVendorFile
          fileIds={vendorProposalScenarioId}
          name={vendorProposalScenarioFileName}
        />
      )
    }

    return null
  }

  const getColumnCell = React.useCallback(
    (cellProps) => {
      const {
        column: { id },
        value,
        row,
      } = cellProps
      const { fieldType = '' } = scenarioFieldInfo[id] || {}

      switch (id) {
        case 'name':
          return ScenarioNameCell(cellProps)

        case 'energyRetainedSavingsInPercentage':
          return EditableRetainedSavingsCell(cellProps)

        case 'maintenanceRetainedSavingsInPercentage':
          return EditableMaintenanceRetainedSavingsCell(cellProps)

        case 'contractTermMonths':
          return EditableTermCell(cellProps)

        case 'redaptiveOpportunityId':
          return OpportunityIdCell(cellProps)

        case 'opportunityCurrencyCode':
          return currencyCode

        case 'modifiedBy':
          return getNameFromEMail(value)

        case 'modified':
          return moment(value).format(DATE_FORMAT_TIMESTAMP)

        case 'vendorProposalScenarioFileName':
          return renderVendorProposal(row)

        default:
          return getDefaultColumnsCell(fieldType, cellProps)
      }
    },
    [
      ScenarioNameCell,
      cellNumber2DigitsPercentage,
      EditableRetainedSavingsCell,
      EditableTermCell,
      OpportunityIdCell,
    ],
  )
  const initialLockedColumns = React.useMemo(
    () =>
      batchFieldMappings && batchFieldMappings.length ?
        batchFieldMappings
          .filter(
            (column) =>
              column.locked && !alwaysLockedColumns.includes(column.fieldName),
          )
          .map((column) => column.fieldName)
      : defaultBatchFieldMappings.filter((column) => column.locked),
    [batchFieldMappings],
  )
  const initialHiddenColumns = React.useMemo(
    () =>
      batchFieldMappings && batchFieldMappings.length ?
        batchFieldMappings
          .filter((column) => !column.visible)
          .map((column) => column.fieldName)
      : defaultBatchFieldMappings.filter((column) => !column.visible),
    [batchFieldMappings],
  )
  const columns = React.useMemo(() => {
    // If the field mappings are null returns default mappings
    // columns.
    const columnsFromFieldMappings =
      batchFieldMappings?.length ?
        batchFieldMappings.map((column) => column.fieldName)
      : defaultBatchFieldMappings.map((column) => column.fieldName)
    const baseColumns = columnsFromFieldMappings.map((accessor) => ({
      accessor,
      Cell: getColumnCell,
      Header: getColumnHeader,
      disableFilters: true,
      minWidth: columnStyles[accessor]?.minWidth || 140,
    }))

    const actionColumn =
      isProposalOperationsUser ?
        [
          {
            id: 'actions',
            Header: 'Actions',
            disableSortBy: true,
            hideSettings: true,
            Cell: getActionsCell,
            minWidth: 50,
            zIndex: 1,
            position: 'sticky',
            right: '0px',
          },
        ]
      : []

    return baseColumns.concat(actionColumn)
  }, [
    batchFieldMappings,
    selectedOpportunityScenarioObj,
    opportunityScenariosObj,
    actionsPopupId,
    modifiedScenariosMap,
    dirtyId,
    handleOpportuntiySelection,
    handleScenarioSelection,
    isProposalOperationsUser,
  ])

  const handleOpportunitySortClick = React.useCallback(() => {
    if (tableInstanceRef.current) {
      tableInstanceRef.current.toggleAllRowsExpanded(false)
    }

    setSortOpportunityDesc((prevState) => !prevState)
  }, [sortOpportunityDesc])
  const handleSelectAllOpportunities = React.useCallback(
    (e) => {
      if (e.target.checked) {
        const alreadySelectedOpportunities = new Set(
          Object.keys(selectedOpportunityScenarioObj),
        )
        const newSelectionObj = batchDetails.opportunities
          .filter(
            (opportunity) =>
              opportunity.scenarios &&
              opportunity.scenarios.length &&
              opportunity.selectedInBatchAnalysis &&
              !alreadySelectedOpportunities.has(opportunity.externalId),
          )
          .reduce(
            (acc, curr) => ({
              ...acc,
              [curr.externalId]: curr.selectedScenarioIdInBatch,
            }),
            {},
          )
        setSelectedOpportunityScenarioObj({
          ...newSelectionObj,
          ...selectedOpportunityScenarioObj,
        })
      } else {
        setSelectedOpportunityScenarioObj({})
      }
    },
    [
      selectedOpportunityScenarioObj,
      batchDetails.opportunities,
      setSelectedOpportunityScenarioObj,
    ],
  )
  const tableHeaderRows: Array<FTTableHeaderRow> = React.useMemo(() => {
    const headerRows = defaultBatchHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      handleSortClick:
        item.id === 'redaptiveOpportunityId' ?
          handleOpportunitySortClick
        : handleSortClick,
      selectionHandler:
        item.id === 'redaptiveOpportunityId' ?
          handleSelectAllOpportunities
        : () => {},
      thStyles: HeaderStyles[item.id] || FilterStyles,
      minWidth: item.minWidth,
      maxWidth: item.maxWidth,
    }))
    const actionsHeader = {
      align: 'left',
      id: 'actions',
      label: 'Actions',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      thStyles: ActionsColumnStyles,
    }
    const sortedHeaderRows = []
    headerRows.forEach((header) => {
      const itemIndex = (itemName) => columnsOrder.indexOf(itemName)

      sortedHeaderRows[itemIndex(header.id)] = header
    })
    return [
      {
        id: 'row1',
        headers: [
          ...sortedHeaderRows.filter((row) => !hiddenColumns.includes(row.id)),
          actionsHeader,
        ],
      },
    ]
  }, [
    handleSortClick,
    columnsOrder,
    hiddenColumns,
    isBatchDirty,
    lockedColumnsArray,
  ])
  const TableHeadComponent = React.useCallback(
    () => (
      <TableHead rows={tableHeaderRows} lockedColumns={lockedColumnsArray} />
    ),
    [tableHeaderRows, columnsOrder, lockedColumnsArray],
  )
  const handleUpdateSalesforceClick = React.useCallback(() => {
    actions.updateProposalBatchStatus({
      status: 'FINALIZED',
      batchId,
    })
  }, [batchId])
  const handleDownloadSummaryClick = React.useCallback(
    (type) => {
      const downloadType = type
      actions.downloadProposalBatchSummary({
        filename: `${batchDetails.name} - Summary documents - ${moment().format(
          DATE_FORMAT_DATA_API_RESPONSE,
        )}.zip`,
        batchId,
        downloadType,
      })
    },
    [batchDetails.name],
  )
  const handleSaveScenarioChanges = React.useCallback(() => {
    modifiedScenariosMap.forEach((value, key) => {
      actions.updateProposalScenario(scenariosById[key])
    })
  }, [modifiedScenariosMap])
  const handleUpdateBatch = React.useCallback(() => {
    if (showScenarioChangeButtons) {
      handleSaveScenarioChanges()
      setModifiedScenariosMap(new Map(Array.from(modifiedScenariosMap)))
    }

    const selectedSet = new Set(Object.keys(selectedOpportunityScenarioObj))

    if (selectedSet.size) {
      const modifiedBatchOpportunities = batchDetails.opportunities
        .slice()
        .map((opportunity) =>
          (
            !selectedSet.has(opportunity.externalId) &&
            opportunity.scenarios &&
            opportunity.scenarios.length
          ) ?
            { selectedInBatchAnalysis: false }
          : {
              ...opportunity,
              selectedScenarioIdInBatch:
                selectedOpportunityScenarioObj[opportunity.externalId],
            },
        )
        .filter(
          (opportunity) =>
            opportunity.scenarios && opportunity.scenarios.length,
        )
        .map((opportunity) => {
          const scenariosWithLatestStatus = opportunity.scenarios.map(
            (scenario) => {
              if (modifiedScenariosMap.has(scenario.id)) {
                return {
                  ...scenario,
                  ...modifiedScenariosMap.get(scenario.id),
                  ...scenariosById[scenario.id],
                  // added to update the batch with the updated scenario
                  status: scenariosStatusMap.get(scenario.id)?.status,
                }
              }

              return {
                ...scenario,
                status: scenariosStatusMap.get(scenario.id)?.status,
              }
            },
          )
          return { ...opportunity, scenarios: scenariosWithLatestStatus }
        })
      actions.updateProposalBatch({
        ...batchDetails,
        opportunities: modifiedBatchOpportunities,
      })
    } else {
      actions.showMessage({
        messageId: 'selectMinimumOneOpportunity',
        title: 'At least one opportunity needs to be selected',
        type: 'error',
      })
      setTimeout(() => actions.hideMessage('selectMinimumOneOpportunity'), 3000)
    }
  }, [
    batchDetails.opportunities,
    selectedOpportunityScenarioObj,
    modifiedScenariosMap,
  ])
  const handleRevertBatchChanges = React.useCallback(() => {
    setSelectedOpportunityScenarioObj(
      batchDetails.opportunities
        .filter(
          (opportunity) =>
            opportunity.scenarios &&
            opportunity.scenarios.length &&
            opportunity.selectedInBatchAnalysis,
        )
        .reduce((acc, curr) => {
          const selectedScenarioId = curr.scenarios.find(
            (scenario) => scenario.id === curr.selectedScenarioIdInBatch,
          )?.id
          return { ...acc, [curr.externalId]: selectedScenarioId }
        }, {}),
    )
    setRevertFlag((prevState) => !prevState)
    setModifiedScenariosMap(new Map())
  }, [batchDetails.opportunities])
  const handleSetHiddenColumns = React.useMemo(() => {
    if (tableInstanceRef.current) {
      const { hiddenColumns: hiddenColumnsFromRef } =
        tableInstanceRef.current.state
      setHiddenColumns(hiddenColumnsFromRef)
    }
  }, [tableInstanceRef, hiddenColumns])
  const handleColumnSettingsChange = React.useCallback(
    ({
      columns: newColumnsOrder,
      lockedColumns,
    }: {
      columns: Array<string>
      lockedColumns: Array<string>
    }) => {
      setLockedColumnsArray(lockedColumns)
      setColumnsOrder(newColumnsOrder)
    },
    [columnsOrder],
  )
  const handleColumnSettingsClose = React.useCallback(
    ({ columns: columnsNew, lockedColumns, columnsVisibleOnTable }) => {
      const fieldMappingsNew = columnsNew.map((columnEntry, index) => ({
        fieldName: columnEntry,
        locked: lockedColumns.includes(columnEntry),
        sequenceNumber: index,
        visible: columnsVisibleOnTable.includes(columnEntry),
      }))
      actions.updateProposalBatchFieldMappings({
        batchId,
        fieldMappings: fieldMappingsNew,
      })
    },
    [batchId],
  )

  // const renderMain = () => false;
  const copyToClipboard = (sel) => {
    document.execCommand('copy')
    sel.removeAllRanges()
  }

  /**
   *
   * @param {} opportunities - opportunities & scenarios data
   * @returns true if all Opportunities (rows) have one active scenario
   */
  const ifAllRowsHaveActiveScenario = (opportunities) => {
    let nonActiveScenarios = opportunities.length
    opportunities.forEach((opportunity) => {
      if (opportunity.status === 'APPROVED') {
        nonActiveScenarios -= 1
      } else if (opportunity?.subRows?.length) {
        opportunity.subRows.every((subRow) => {
          if (subRow.status === 'APPROVED') {
            nonActiveScenarios -= 1
            return false
          }

          return true
        })
      }
    })

    if (nonActiveScenarios) {
      return false
    }

    return true
  }

  const getVisibleColumnHeaders = (mappings) =>
    mappings
      .filter((field) => field.visible)
      .map((field) => {
        if (field.fieldName === 'opportunityCurrencyCode') {
          return '<th>Currency</th>'
        }

        if (field.fieldName === 'redaptiveOpportunityId') {
          return '<th>Opportunity ID</th>'
        }

        return `<th>${scenarioFieldInfo?.[field.fieldName]?.label}</th>`
      })
      .join('')

  const getVisibleTableRowData = (scenario, isSubrow, copyOnlyActiveRows) =>
    updatedFieldMappings
      .filter((field) => field.visible)
      .map((field) => {
        const name = field.fieldName

        if (
          isSubrow &&
          name === 'redaptiveOpportunityId' &&
          !copyOnlyActiveRows
        ) {
          return '<td></td>'
        }

        if (name === 'opportunityCurrencyCode') {
          return `<td>${currencyCode}</td>`
        }

        if (currencySet.has(name)) {
          return `<td>
          ${formatScenarioFieldValueCurrency({
            locale,
            currencyCode,
            value: scenario[name],
            precision: scenarioFieldInfo[name].precision,
          })}
        </td>`
        }

        if (percentageSet.has(name)) {
          return `<td>
          ${formatScenarioFieldValuePercentage({
            locale,
            value: scenario[name],
          })}
        </td>`
        }

        if (name === 'modified') {
          return `<td>
          ${formatDateStringForView({
            value: scenario[name],
          })}
        </td>`
        }

        if (name === 'modifiedBy') {
          return `<td>
          ${convertEmailToNameForView({
            value: scenario[name],
          })}
        </td>`
        }

        return `<td>${scenario[name]}</td>`
      })
      .join('')

  const getSubRowTableData = (mappings, copyOnlyActiveRows) => {
    const copyScenarios = mappings
    const isSubrow = true

    if (copyOnlyActiveRows) {
      return copyScenarios
        .map(
          (scenario) => `
          ${
            scenario.status === 'APPROVED' ?
              `<tr align="center">${getVisibleTableRowData(
                scenario,
                isSubrow,
                copyOnlyActiveRows,
              )}</tr>`
            : ''
          }
        `,
        )
        .join('')
    }

    return copyScenarios
      .map(
        (scenario) => `
        <tr align="center">${getVisibleTableRowData(
          scenario,
          isSubrow,
          copyOnlyActiveRows,
        )}</tr>
      `,
      )
      .join('')
  }

  const getVisibleTableData = (mappings: Record<string, any>) => {
    const copyScenarios = mappings
    let data
    const isSubrow = false
    const copyOnlyActiveRows = ifAllRowsHaveActiveScenario(copyScenarios)

    if (copyOnlyActiveRows) {
      data = copyScenarios
        .map(
          (scenario) => `
              ${
                scenario.status === 'APPROVED' ?
                  `<tr align="center">${getVisibleTableRowData(
                    scenario,
                    isSubrow,
                    copyOnlyActiveRows,
                  )}</tr>`
                : ''
              }
        ${
          scenario.subRows?.length ?
            getSubRowTableData(scenario.subRows, copyOnlyActiveRows)
          : ''
        }`,
        )
        .join('')
    } else {
      data = copyScenarios
        .map(
          (scenario) => `
          <tr align="center">${getVisibleTableRowData(
            scenario,
            isSubrow,
            copyOnlyActiveRows,
          )}</tr>
          ${
            scenario.subRows?.length ?
              getSubRowTableData(scenario.subRows, copyOnlyActiveRows)
            : ''
          }`,
        )
        .join('')
    }

    return data
  }

  const copyTable = () => {
    // $FlowFixMe
    const elTable = document.querySelector('.table-wrapper table')
    const tempTableNode = document.createElement('div')

    if (elTable) {
      elTable.appendChild(tempTableNode)
    }

    tempTableNode.id = 'temp-copy-table'
    const tempTableElement = document.querySelector(`#${tempTableNode.id}`)

    if (document.createRange && window.getSelection) {
      // Ensure that range and selection are supported by the browsers
      const range = document.createRange()
      const sel = window.getSelection()

      try {
        if (tempTableElement) {
          tempTableElement.innerHTML =
            '<table border="1" cellspacing="0" cellpadding="10" align="center" nowrap>' +
            '<tr align="center">' +
            `${getVisibleColumnHeaders(updatedFieldMappings)}` +
            '</tr>' +
            `${getVisibleTableData(sortedOpportunityScenarios)}` +
            '</table>'
          range.selectNodeContents(tempTableElement)
          sel.addRange(range)
        }
      } catch (e) {
        if (tempTableElement) {
          range.selectNode(tempTableElement)
          sel.addRange(range)
        }
      }

      copyToClipboard(sel)

      /** Adding again to copy because copy was not working on first click!
       * If found any better solution, then can call copy only once!
       */
      sel.addRange(range)
      copyToClipboard(sel)
    }

    actions.showMessage({
      messageId: 'copyTableConfirmation',
      title: 'Table Copied!',
      type: 'success',
    })
    setTimeout(() => {
      actions.hideMessage('copyTableConfirmation')
      // $FlowFixMe
      document.querySelector('#temp-copy-table')?.remove()
    }, 100)
  }

  const getReviewRowData = (scenario) => ({
    'Scenario ID': scenario.name,
    'Schedule No.': scenario.redaptiveOpportunityId,
    Address: site?.shippingStreet,
    City: site?.shippingCity,
    State: site?.shippingState,
    Zip: site?.shippingPostalCode,
    'Sq. Feet': numberFormatMaxFractionDigits2.format(site.squareFeet),
    'Total Proposed Fixture Count': numberFormatMaxFractionDigits2.format(
      scenario.totalProposedFixtureCount,
    ),
    'Add Fixture': numberFormatMaxFractionDigits2.format(
      scenario.lightingAddFixtureCount,
    ),
    'Re-Lamp / Bypass Ballast': numberFormatMaxFractionDigits2.format(
      scenario.lightingRelampBypassBallastCount,
    ),
    'Replace Fixture': numberFormatMaxFractionDigits2.format(
      scenario.lightingReplaceFixtureCount,
    ),
    'Re-Lamp': numberFormatMaxFractionDigits2.format(
      scenario.lightingRelampCount,
    ),
    'Retrofit Kit': numberFormatMaxFractionDigits2.format(
      scenario.lightingRetrofitKitCount,
    ),
    'No Change': numberFormatMaxFractionDigits2.format(
      scenario.lightingNoChange,
    ),
    'Sensors Only': numberFormatMaxFractionDigits2.format(
      scenario.lightingSensorsOnly,
    ),
    'Remove Fixtures': numberFormatMaxFractionDigits2.format(
      scenario.lightingRemoveFixtures,
    ),
    'Saved Resource Limit': numberFormatMaxFractionDigits2.format(
      Big(scenario.annualSavedKwh)
        .times(scenario.contractTermMonths)
        .div(12)
        .toFixed(2),
    ),
    Currency: currencyCode,
    'Current Average Utility Rate': formatScenarioFieldValueCurrency({
      locale,
      currencyCode,
      value: scenario.utilityRate,
      precision: scenarioFieldInfo.utilityRate.precision,
    }),
    'Contract Rate': formatScenarioFieldValueCurrency({
      locale,
      currencyCode,
      value: scenario.contractRate,
      precision: scenarioFieldInfo.contractRate.precision,
    }),
    'Schedule Term': numberFormatMaxFractionDigits2.format(
      scenario.contractTermMonths,
    ),
    'Fixed Monthly Payment for Maintenance Savings':
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: Big(scenario.maintenanceRepairAndOperationsAnnualPayment).div(
          12,
        ),
        precision: 2,
      }),
    'Total Resources Savings Payments (for Maintenance Savings)':
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: Big(scenario.maintenanceRepairAndOperationsAnnualPayment),
        precision: 2,
      }),
    'Total Resources Savings Payments (for Actual Savings)':
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: Big(scenario.annualEnergyPayment)
          .div(12)
          .times(scenario.contractTermMonths),
        precision: 2,
      }),
    'Maximum Saved Resources Purchase Amount (not to exceed)':
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.totalContractValueWithSalesTax,
        precision: 2,
      }),
    'Est Sales Tax': formatScenarioFieldValueCurrency({
      locale,
      currencyCode,
      value: scenario.estimatedSalesTax,
      precision: 2,
    }),
    'Final Rebate Amount': formatScenarioFieldValueCurrency({
      locale,
      currencyCode,
      value: scenario.finalRebateAmount,
      precision: 2,
    }),
    'Estimated Pre-System Consumption ': numberFormatMaxFractionDigits2.format(
      scenario.annualPreKwh,
    ),
    'Estimated Post-System consumption': numberFormatMaxFractionDigits2.format(
      scenario.annualPostKwh,
    ),
    'Annual Pre Burn Hours': numberFormatMaxFractionDigits2.format(
      scenario.annualPreBurnHours,
    ),
    'Annual Post Burn Hours (With New Sensors)':
      numberFormatMaxFractionDigits2.format(scenario.annualPostBurnHours),
    'Estimated Metric Tons of CO2e Avoided Annually':
      scenario.metricTonsOfCo2AvoidedAnnually,
    'Estimated Metric Tons of CO2e Avoided over Contract Term':
      scenario.metricTonsOfCo2AvoidedContractTerm,
    'Estimated Metric Tons of CO2e Avoided over EUL':
      scenario.metricTonsOfCo2AvoidedOverEul,
    'Additional Retained Savings from UBR Hedging over Contract Term':
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.savingsThroughUbrHedging,
        precision: scenarioFieldInfo.savingsThroughUbrHedging.precision,
      }),
    'Additional Savings from Avoided REC Purchase over EUL':
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.savedDollarsOnAvoidedRecPurchase,
        precision: scenarioFieldInfo.savedDollarsOnAvoidedRecPurchase.precision,
      }),
  })

  const fetchActiveScenario = (scenarios) => {
    const activeScenario = scenarios.filter(
      (scenario) => scenario.status === 'APPROVED',
    )
    return activeScenario[0]
  }

  const getCsvData = () => {
    const json2csvParser = new Parser({
      batchReviewMappings,
    })
    const activeScenearioData = sortedOpportunityScenarios
      .filter(
        (scenario) =>
          scenario.status === 'APPROVED' ||
          fetchActiveScenario(scenario?.subRows),
      )
      .map((scenario) => {
        if (scenario.status === 'APPROVED') {
          return scenario
        }

        return fetchActiveScenario(scenario?.subRows)
      })
    let csvData = []
    csvData = activeScenearioData.map((scenario) => getReviewRowData(scenario))
    return json2csvParser.parse(csvData)
  }

  const handleDownloadReviewClick = () => {
    const file = new Blob([getCsvData()], {
      type: 'text/csv',
    })
    const fileName = `Review Page - ${moment().format(
      DATE_FORMAT_DATA_API_RESPONSE,
    )}`
    FileSaver.saveAs(file, fileName)
  }

  const getDownloadMenu = () => {
    const downloadActions = []
    downloadActions.push({
      label: 'Summary - All Opportunities',
      onClick: () => handleDownloadSummaryClick('all'),
      icon: <TbPdf size={20} />,
      value: 1,
    })
    downloadActions.push({
      label: 'Summary - Consolidated',
      onClick: () => handleDownloadSummaryClick('consolidated'),
      icon: <TbPdf size={20} />,
      value: 1,
    })
    downloadActions.push({
      label: 'Review Page',
      onClick: handleDownloadReviewClick,
      icon: <TbCsv size={20} />,
      value: 1,
    })
    return (
      <DownloadDropDownWrapper
        style={{
          display: 'block',
          height: '36px',
          paddingLeft: '5px',
          marginRight: '10px',
        }}
      >
        <DropdownMenuNew
          title='Download'
          actions={downloadActions}
          border
          iconBefore
          bgColor='#fafafa'
          style={{
            float: 'left',
            width: '250px',
          }}
        />
        {downloadBatchSummaryLoading && (
          <DownloadSummaryActionSpinnerStyled
            style={{
              marginTop: '7px',
              float: 'right',
              position: 'relative',
            }}
          >
            <Spinner inline size='tiny' />
          </DownloadSummaryActionSpinnerStyled>
        )}
      </DownloadDropDownWrapper>
    )
  }

  const PageHeaderActions = () => (
    <HeaderActionsStyled>
      <HeaderActionStyled onClick={copyTable}>
        <HeaderActionBodyStyled $loading={batchUpdateLoading}>
          <CopyIconStyled />
          Copy Table
        </HeaderActionBodyStyled>
        {batchUpdateLoading && (
          <DownloadSummaryActionSpinnerStyled>
            <Spinner inline size='tiny' />
          </DownloadSummaryActionSpinnerStyled>
        )}
      </HeaderActionStyled>
      {(
        !isBatchDirty &&
        !showScenarioChangeButtons &&
        showBatchChangeButtons &&
        batchDetails.status !== 'FINALIZED' &&
        isProposalOperationsUser
      ) ?
        <HeaderActionStyled onClick={handleUpdateSalesforceClick}>
          <HeaderActionBodyStyled $loading={batchUpdateLoading}>
            <AutorenewIconIconStyled />
            Update Salesforce
          </HeaderActionBodyStyled>
          {batchUpdateLoading && (
            <DownloadSummaryActionSpinnerStyled>
              <Spinner inline size='tiny' />
            </DownloadSummaryActionSpinnerStyled>
          )}
        </HeaderActionStyled>
      : null}
      {!showScenarioChangeButtons && !!isBatchDirty ?
        <HeaderActionStyled onClick={handleRevertBatchChanges}>
          <HeaderActionBodyStyled $loading={false}>
            <HistoryIconStyled />
            Revert changes
          </HeaderActionBodyStyled>
        </HeaderActionStyled>
      : null}
      {(!!isBatchDirty || showScenarioChangeButtons) &&
        isProposalOperationsUser && (
          <HeaderActionStyled onClick={handleUpdateBatch}>
            <HeaderActionBodyStyled $loading={batchUpdateLoading}>
              <SaveIconStyled />
              Save Batch
            </HeaderActionBodyStyled>
            {batchUpdateLoading && (
              <DownloadSummaryActionSpinnerStyled>
                <Spinner inline size='tiny' />
              </DownloadSummaryActionSpinnerStyled>
            )}
          </HeaderActionStyled>
        )}
      {batchDetails.status !== 'FINALIZED' && isProposalOperationsUser && (
        <HeaderActionStyled
          onClick={() => {
            if (isMultiCurrency) {
              setShowMultiCurrencyErrorPopUp(true)
              return false
            }
            // eslint-disable-next-line react/prop-types
            history.push(
              `/proposal-operations/proposals-engine/${salesforceCustomerId}/edit-batch/${batchId}`,
            )
          }}
        >
          <EditNoteIcon />
          Edit
        </HeaderActionStyled>
      )}
      {showMultiCurrencyErrorPopUp && EditBatchAlertModal}
      {!isBatchDirty &&
        !showScenarioChangeButtons &&
        showBatchChangeButtons &&
        getDownloadMenu()}
    </HeaderActionsStyled>
  )

  return (
    <>
      <UnsavedChanges
        when={isBatchDirty}
        message='There are unsaved changes in this Batch. Are you sure you want to navigate?'
      />
      <CustomerOpportunitiesStyles>
        <BreadcrumbsStyles>
          <Breadcrumbs2 items={breadcrumbs} />
        </BreadcrumbsStyles>
        {batchDetailsLoading && (
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        )}
        {!batchDetailsLoading && !!Object.keys(batchDetails).length && (
          <>
            <PageHeader
              Title={batchDetails.name}
              handleBackNavigation={history.goBack}
              statusText={capitalCase(batchDetails.status)}
              statusColor={
                batchDetails.status.toUpperCase() === 'PENDING' ?
                  '#7F7F7F'
                : colors.orange
              }
              Actions={PageHeaderActions}
            />
            <MetricsStyles>
              <Metric
                color={colors.blue2}
                metric={totalAssetCount}
                title='Total Fixture count'
              />
              <Metric
                color={colors.green}
                metric={heroMetricscurrencyFormat.format(netUpfront)}
                title='Net cost'
              />
              <Metric
                color={colors.orange}
                metric={heroMetricscurrencyFormat.format(totalPortfolioMargin)}
                title='Portfolio Margin'
              />
              <Metric
                color={colors.blue6}
                metric={`${numberFormatMinFractionDigits2.format(
                  totalPortfolioMarginPercentage,
                )}%`}
                title='Portfolio Margin %'
              />
              <Metric
                color={colors.blue7}
                metric={heroMetricscurrencyFormat.format(preTaxContractValue)}
                title='Total Pre-Tax Contract value'
              />
              <Metric
                color={colors.green4}
                metric={heroMetricscurrencyFormat.format(totalNPVRevenue)}
                title='Batch Net Revenue'
              />
              <Metric
                color={colors.blue6}
                metric={`${numberFormatMinFractionDigits2.format(
                  totalGrossMarginPercentage,
                )}%`}
                title='Gross Margin %'
              />
            </MetricsStyles>
            <BatchTableContainerStyled className='table-wrapper'>
              <RedaptiveReactTable7
                alwaysLockedColumns={alwaysLockedColumns}
                autoResetExpanded={false}
                columns={columns}
                data={sortedOpportunityScenarios}
                enableColumnSettings={showColumnSettings}
                enableColumnHiding
                handleColumnSettingsChange={handleColumnSettingsChange}
                handleColumnSettingsClose={handleColumnSettingsClose} // HeaderActions={HeaderActions}
                initialHiddenColumns={initialHiddenColumns}
                initialLockedColumns={initialLockedColumns}
                maxLockableColumns={3}
                setHiddenColumns={handleSetHiddenColumns}
                _TableHead={TableHeadComponent} // _ to ignore this attribute
                tableInstanceRef={tableInstanceRef}
                TdComponent={TdStyledStyled}
                enablePagination
              />
            </BatchTableContainerStyled>
          </>
        )}
      </CustomerOpportunitiesStyles>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(batchActions, dispatch),
    ...bindActionCreators(batchSummaryActions, dispatch),
    ...bindActionCreators(customerGlobalInputsActions, dispatch),
    ...bindActionCreators(customerSummariesActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
    ...bindActionCreators(opportunitySummaryActions, dispatch),
    ...bindActionCreators(opportunitySummaryDownloadActions, dispatch),
    ...bindActionCreators(opportunityActions, dispatch),
    ...bindActionCreators(scenarioActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const batchDetailsEntity = selectProposalBatchesEntity(state)
  const batchSummariesEntity = selectProposalBatchSummariesEntity(state)
  const customerGlobalInputsEntity =
    selectProposalCustomerGlobalInputsEntity(state)
  const customerSummariesEntity = selectProposalCustomerSummariesEntity(state)
  const opportunitySummaryDownloadMeta =
    selectProposalOpportunitySummaryDownloadMeta(state)
  const scenariosEntity = selectProposalScenarioEntity(state)
  const siteEntity = selectProposalSiteEntity(state)
  const authGroups = authSelectors.selectGroups(state)
  const { items: customerGlobalInputs } = customerGlobalInputsEntity
  const { byId: customerSummariesById } = customerSummariesEntity
  const {
    items: batchDetails,
    meta: { loading: batchDetailsLoading },
    updateMeta: { loading: batchUpdateLoading, error: batchUpdateError },
    fieldMappings: batchFieldMappings,
  } = batchDetailsEntity
  const {
    downloadMeta: { loading: downloadBatchSummaryLoading },
  } = batchSummariesEntity
  const {
    meta: { loading: scenariosLoading, error: scenariosLoadingError },
  } = scenariosEntity
  const { items: sites } = siteEntity
  const {
    auth: { permissions },
  } = state
  return {
    authGroups,
    batchDetails,
    batchDetailsLoading,
    batchFieldMappings,
    batchUpdateError,
    batchUpdateLoading,
    customerGlobalInputs: customerGlobalInputs[0] || {},
    customerSummariesById,
    downloadBatchSummaryLoading,
    opportunitySummaryDownloadMeta,
    scenariosLoading,
    scenariosLoadingError,
    sites,
    permissions,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BatchAnalysisPage),
)
