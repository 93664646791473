export default () => (
  <svg
    width='19'
    height='22'
    viewBox='0 0 19 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.2874 2.7H15.9194V1.6C15.9191 1.30831 15.8026 1.02872 15.5958 0.822467C15.3887 0.616381 15.1081 0.500327 14.8154 0.5H11.1352C10.8424 0.500327 10.5618 0.616381 10.3548 0.822467C10.1479 1.02872 10.0314 1.30831 10.0311 1.6V2.7H2.30274C1.81493 2.70049 1.34719 2.89397 1.00218 3.23755C0.657329 3.58129 0.463151 4.04731 0.462646 4.53331V18.4665C0.463139 18.9525 0.657334 19.4186 1.00218 19.7623C1.3472 20.1059 1.81493 20.2994 2.30274 20.2999H2.67075V21.3998C2.67108 21.6915 2.78756 21.9711 2.99441 22.1774C3.20142 22.3835 3.48204 22.4995 3.77482 22.4999H4.51085C4.80362 22.4995 5.08425 22.3835 5.29126 22.1774C5.49811 21.9711 5.61459 21.6915 5.61492 21.3998V20.2999H6.35096V21.3998C6.35128 21.6915 6.46777 21.9711 6.67461 22.1774C6.88163 22.3835 7.16225 22.4995 7.45502 22.4999H8.19106C8.48383 22.4995 8.76445 22.3835 8.97147 22.1774C9.17831 21.9711 9.2948 21.6915 9.29512 21.3998V20.2999H10.0312V21.3998C10.0315 21.6915 10.148 21.9711 10.3548 22.1774C10.5618 22.3835 10.8425 22.4995 11.1352 22.4999H11.8713C12.164 22.4995 12.4447 22.3835 12.6517 22.1774C12.8585 21.9711 12.975 21.6915 12.9753 21.3998V20.2999H16.2875C16.7753 20.2994 17.243 20.1059 17.588 19.7623C17.9329 19.4186 18.1271 18.9525 18.1276 18.4665V4.53331C18.1271 4.04731 17.9329 3.58129 17.588 3.23755C17.243 2.89396 16.7753 2.7005 16.2875 2.7H16.2874ZM17.3915 18.4665C17.3912 18.7582 17.2747 19.0378 17.0678 19.2441C16.8608 19.4502 16.5802 19.5662 16.2874 19.5665H2.30275C2.00998 19.5662 1.72936 19.4502 1.52234 19.2441C1.31549 19.0378 1.19901 18.7582 1.19868 18.4665V4.53331C1.19901 4.24162 1.31549 3.96203 1.52234 3.75578C1.72935 3.54969 2.00997 3.43364 2.30275 3.43331H16.2874C16.5802 3.43364 16.8608 3.54969 17.0678 3.75578C17.2747 3.96203 17.3912 4.24162 17.3915 4.53331V18.4665Z'
      fill='#485DA0'
    />
    <path
      d='M16.2878 4.1665H2.30308C2.09985 4.1665 1.93506 4.33069 1.93506 4.53317V18.4664C1.93506 18.5636 1.97383 18.6569 2.04284 18.7257C2.11184 18.7944 2.20549 18.8331 2.30308 18.8331H16.2878C16.3853 18.8331 16.479 18.7944 16.548 18.7257C16.617 18.6569 16.6558 18.5636 16.6558 18.4664V4.53317C16.6558 4.43594 16.617 4.34263 16.548 4.27388C16.479 4.20514 16.3853 4.1665 16.2878 4.1665ZM3.03911 8.19981C2.89026 8.19981 2.75603 8.11043 2.69919 7.97343C2.64218 7.83642 2.67356 7.67879 2.77887 7.57386C2.88418 7.46893 3.0424 7.43767 3.17991 7.49447C3.31743 7.55111 3.40713 7.68484 3.40713 7.83314C3.40713 7.93037 3.36836 8.02368 3.29935 8.09243C3.23035 8.16117 3.1367 8.19981 3.03911 8.19981ZM4.51118 5.63316H3.40713V6.36649C3.40713 6.56897 3.24234 6.73315 3.03911 6.73315C2.83588 6.73315 2.67109 6.56897 2.67109 6.36649V5.2665C2.67109 5.06401 2.83588 4.89983 3.03911 4.89983H4.51118C4.71441 4.89983 4.8792 5.06401 4.8792 5.2665C4.8792 5.46898 4.71441 5.63316 4.51118 5.63316ZM11.8139 10.9634L9.23779 14.9967C9.1701 15.1024 9.05313 15.1665 8.92728 15.1665C8.89245 15.1665 8.85779 15.1615 8.82427 15.1517C8.6672 15.1062 8.55926 14.9628 8.55926 14.7998V12.5998H7.08719C6.9528 12.5998 6.82925 12.5268 6.76452 12.4096C6.69995 12.2922 6.70455 12.1492 6.77667 12.0362L9.3528 8.00292C9.44069 7.86542 9.6091 7.80223 9.76616 7.84791C9.92323 7.89341 10.0313 8.0368 10.0313 8.19984V10.3998H11.5034C11.6378 10.3998 11.7613 10.4728 11.8261 10.59C11.8906 10.7074 11.886 10.8505 11.8139 10.9634ZM15.9196 17.7331C15.9196 17.8303 15.8808 17.9236 15.8118 17.9923C15.7428 18.0611 15.6492 18.0997 15.5516 18.0997H14.4475C14.2443 18.0997 14.0795 17.9355 14.0795 17.7331C14.0795 17.5306 14.2443 17.3664 14.4475 17.3664H15.1836V16.6331C15.1836 16.4306 15.3484 16.2664 15.5516 16.2664C15.7548 16.2664 15.9196 16.4306 15.9196 16.6331V17.7331Z'
      fill='#485DA0'
    />
  </svg>
)
