import styled from 'styled-components'

export const ModalContentStyled = styled.div`
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.16) 0%,
      rgba(224, 224, 224, 0.16) 100%
    ),
    #fff;
  border: 1px solid #dadce0;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  width: 924px;
  display: flex;
  flex-direction: column;
  padding: 24px;
`
export const ModalBodyStyled = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
export const ModalFooterStyled = styled.div`
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 8px 24px;
    display: flex;
    justify-content: flex-end;
    & button {
      margin-left: 8px;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
`
export const HeaderStyled = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
`
export const HeaderLabelStyled = styled.div`
  font-size: 14px;
  color: #162447;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`
export const SubHeaderLableStyled = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #485da0;
  text-transform: uppercase;
`
export const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(108, 109, 110, 0.32);
  margin: 12px 0 24px;
`
export const CloseIconStyled = styled.div`
  color: #464a54;
  cursor: pointer;
  font-size: 24px;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #485da0;
  & .ion-android-close {
    color: #fff;
  }
`
export const BorderedContainerStyled = styled.div`
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 24px;
  color: #e0e0e0;
  margin: 16px 0;
  flex-grow: 1;
`
export const ErrorStyled = styled.p`
  color: #c62b2b;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  padding: 0;
  margin: 0;
  text-align: right;
  width: 100%;
`
export const LabelStyled = styled.div`
  font-size: 12px;
  color: #162447;
  font-weight: 600;
`
export const LableSupportText = styled.span`
  font-weight: 400;
`
export const InputStyled = styled.input`
  display: block;
  width: 100%;
  padding: 2px 8px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: ${(props) =>
    props.disabled ?
      'linear-gradient(0deg, rgba(224, 224, 224, 0.32) 0%, rgba(224, 224, 224, 0.32) 100%), #FFF;'
    : '#FFF'};
  color: #162447;
  margin-top: 4px;
  &.error {
    border: 1px solid #c62b2b;
    background: linear-gradient(
        0deg,
        rgba(198, 43, 43, 0.1) 0%,
        rgba(198, 43, 43, 0.1) 100%
      ),
      #fff;
  }
`
export const TextAreaStyled = styled.textarea`
  display: block;
  width: 100%;
  padding: 2px 8px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  backgground: #fff;
  font-family: 'Public Sans', sans-serif;
  margin-top: 4px;
`
export const FieldContainerStyled = styled.div`
  margin-bottom: 24px;
  position: relative;
  & .ListSelector-Dropdown {
    &--wrapper {
      margin-top: 4px;
    }
  }
`
export const FieldErrorStyled = styled.p`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
`
export const ManualToggleContainerStyled = styled.div`
  margin-top: 48px;
  & p {
    color: #6c6d6e;
    font-size: 12px;
    text-transform: uppercase;
  }

  & button {
    margin-left: 0px;
    margin-top: 2px;
  }
`

export const KFactorContainer = styled(ManualToggleContainerStyled)`
  margin-top: 20px;
`

export const UploadPhotoButtonStyled = styled.button`
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: linear-gradient(0deg, rgba(224, 224, 224, 0.32) 0%, rgba(224, 224, 224, 0.32) 100%), #FFF;
  width: 100%;
  margin-top: 4px;
  height: 40px;
  display flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 8px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity:${({ disabled }) => (disabled ? '0.5' : '1')};
  & > span {
    margin-left: 8px;
    color: #6C6D6E;
    font-size: 14px;
    font-weight: 500;
  }
`
export const ClearBtnStyled = styled.div`
  display flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 8px 16px;
  color: #6C6D6E;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity:${({ disabled }) => (disabled ? '0.5' : '1')};
  & span {
    margin-left: 4px;
  }
`
export const DoubleColumnContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
    flex-grow: 0;
  }
`
export const ImagePrevieweStyled = styled.img`
  height: 100px;
  width: 100%;
  border-radius: 4px;
  margin-top: 4px;
  object-fit: cover;
  object-position: 50% top;
`
export const ImageActionBtnContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  align-items: center;
  width: 100%;
`
export const RequiredMarkStyled = styled.span`
  color: #ff0000;
`
export const HiddenInputStyled = styled.input`
  display: none;
`
export const ErrorMessageStyled = styled.div`
  color: #ff0000;
  font-size: 12px;
`
export const CenteredContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export type FTModalKfactorProps = {
  meterId: string
  meter: Record<string, any>
  closeModal: (...args: Array<any>) => any
  updateMode: boolean
}
export const CALCULATION_TYPE = {
  MANUAL: 'MANUAL',
  UTILITY_METER: 'UTILITY_METER',
  CALCULATED: 'CALCULATED',
}
export const READING_UNIT_OPTIONS = [
  {
    id: 'CCF',
    name: 'CCF',
  },
  {
    id: 'BTU',
    name: 'BTU',
  },
  {
    id: 'Therms',
    name: 'Therms',
  },
]
