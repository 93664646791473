import { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Modal from './Modal'
import type { FTModalPanelDeleteConflict } from '../ducks/modal'
import { actions as modalActions } from '../ducks/modal'

const ModalStyles = styled.div`
  .Modal .content {
    padding-bottom: 0;
  }
`
type FTProps = {
  dispatch: (...args: Array<any>) => any
} & FTModalPanelDeleteConflict

class ModalPanelDeleteConflict extends PureComponent<FTProps> {
  onClose = () => {
    this.props.dispatch(modalActions.hideModal())
  }

  render() {
    const { panelName, meters } = this.props
    const title = `Panel "${panelName}" cannot be deleted because it is assigned to the following meters:`
    const listItems = meters.map((meter) => {
      const { id, name } = meter
      return (
        <li key={id}>
          <a href={`/account-management/meters/${id}`}>{name}</a>
        </li>
      )
    })

    const renderContent = () => (
      <>
        <ul>{listItems}</ul>
        <p>
          Please assign the meters listed above to a different panel and try
          again.
        </p>
      </>
    )

    return (
      <ModalStyles>
        <Modal
          acceptText='OK'
          hideClose
          modalWidth='470px'
          onAccept={this.onClose}
          onClose={this.onClose}
          renderContent={renderContent}
          title={title}
        />
      </ModalStyles>
    )
  }
}

export default connect(() => ({}))(ModalPanelDeleteConflict)
