// The Headers are ordered as per the requirement. Do not change the order unless it's required.
const defaultBatchHeaders = [
  {
    id: 'redaptiveOpportunityId',
    label: 'Opportunity ID',
    minWidth: 160,
    maxWidth: 260,
  },
  {
    id: 'name',
    label: 'Scenario ID',
    minWidth: 150,
    maxWidth: 220,
  },
  {
    id: 'modified',
    label: 'Last Modified (UTC)',
  },
  {
    id: 'modifiedBy',
    label: 'Modified By',
  },
  {
    id: 'totalProposedFixtureCount',
    label: 'Total Proposed Fixture Count',
    minWidth: 120,
  },
  {
    id: 'opportunityCurrencyCode',
    label: 'Currency',
  },
  {
    id: 'contractTermMonths',
    label: 'Contract Term Months',
    minWidth: 120,
  },
  {
    id: 'energyRetainedSavingsInPercentage',
    label: 'Energy Retained Savings %',
    minWidth: 120,
  },
  {
    id: 'maintenanceRetainedSavingsInPercentage',
    label: 'Maintenance Retained Savings %',
  },
  {
    id: 'grossMarginInPercentage',
    label: 'Gross Margin %',
    minWidth: 120,
  },
  {
    id: 'internalRateOfReturn',
    label: 'IRR %',
  },
  {
    id: 'netMarginInDollars',
    label: 'Net Margin $',
  },
  {
    id: 'netMarginInPercentage',
    label: 'Net Margin %',
  },
  {
    id: 'maintenanceContractValue',
    label: 'Maintenance Contract Value',
  },
  {
    id: 'preTaxContractValue',
    label: 'Pre-Tax Contract Value',
    minWidth: 120,
  },
  {
    id: 'monthlyPaymentToRedaptive',
    label: 'Monthly Payment to Redaptive',
  },
  {
    id: 'estimatedSalesTax',
    label: 'Estimated Sales Tax',
  },
  {
    id: 'totalContractValueWithSalesTax',
    label: 'Total Contract Value',
  },
  {
    id: 'meterCount',
    label: 'Meter Count',
  },
  {
    id: 'upFrontMeasurementAndVerification',
    label: 'M&V Cost',
  },
  {
    id: 'rebateHcInPercentage',
    label: 'Rebate HC%',
  },
  {
    id: 'finalRebateAmount',
    label: 'Final Rebate Amount',
  },
  {
    id: 'contingencyInPercentage',
    label: 'Contingency %',
  },
  {
    id: 'contingencyCost',
    label: 'Contingency Cost',
  },
  {
    id: 'partnerFeeInPercentage',
    label: 'Partner Fee %',
  },
  {
    id: 'partnerFee',
    label: 'Partner Fee',
  },
  {
    id: 'referralFeeInPercentage',
    label: 'Referral Fee %',
  },
  {
    id: 'referralCost',
    label: 'Referral Cost',
  },
  {
    id: 'costReduction',
    label: 'Cost Reduction',
  },
  {
    id: 'upFrontPayment',
    label: 'Up-front CapEx',
  },
  {
    id: 'netCost',
    label: 'Net Cost',
  },
  {
    id: 'percentInKwhReduction',
    label: '% in kWh Reduction',
  },
  {
    id: 'blockKwh',
    label: 'Block kWh',
  },
  {
    label: 'Annual Saved kWh',
    id: 'annualSavedKwh',
  },
  {
    id: 'utilityRate',
    label: 'Utility Rate',
  },
  {
    id: 'contractRate',
    label: 'Contract Rate',
  },
  {
    id: 'annualEnergySavingsInDollars',
    label: 'Annual Energy Savings',
  },
  {
    id: 'annualMaintenanceSavings',
    label: 'Maint. Savings',
  },
  {
    id: 'annualPreBurnHours',
    label: 'Monthly Burn Hours',
  },
  {
    label: 'MT CO2 Avoided Annually',
    id: 'metricTonsOfCo2AvoidedAnnually',
  },
  {
    label: 'Vendor Proposal',
    id: 'vendorProposalScenarioFileName',
  },
  {
    id: 'annualPreKwh',
    label: 'Annual Pre kWh',
  },
  {
    label: 'Annual Post kWh',
    id: 'annualPostKwh',
  },
  {
    id: 'address',
    label: 'Address',
  },
]
const defaultBatchFieldMappings = defaultBatchHeaders.map((item, index) => ({
  fieldName: item.id,
  sequence: index,
  locked: false,
  visible: true,
}))
export const batchReviewMappings = [
  {
    fieldName: 'Scenario ID',
    label: 'Scenario ID',
  },
  {
    fieldName: 'Schedule No.',
    label: 'Schedule No.',
  },
  {
    fieldName: 'Address',
    label: 'Address',
  },
  {
    fieldName: 'City',
    label: 'City',
  },
  {
    fieldName: 'State',
    label: 'State',
  },
  {
    fieldName: 'Zip',
    label: 'Zip',
  },
  {
    fieldName: 'Floor Space',
    label: 'Floor Space',
  },
  {
    fieldName: 'Fixtures',
    label: 'Fixtures',
  },
  {
    fieldName: 'Add Fixture',
    label: 'Add Fixture',
  },
  {
    fieldName: 'Re-Lamp / Bypass Ballast',
    label: 'Re-Lamp / Bypass Ballast',
  },
  {
    fieldName: 'Replace Fixture',
    label: 'Replace Fixture',
  },
  {
    fieldName: 'Re-Lamp',
    label: 'Re-Lamp',
  },
  {
    fieldName: 'Retrofit Kit',
    label: 'Retrofit Kit',
  },
  {
    fieldName: 'No Change',
    label: 'No Change',
  },
  {
    fieldName: 'Sensors Only',
    label: 'Sensors Only',
  },
  {
    fieldName: 'Remove Fixtures',
    label: 'Remove Fixtures',
  },
  {
    fieldName: 'Saved Resource Limit',
    label: 'Saved Resource Limit',
  },
  {
    fieldName: 'Currency',
    label: 'Currency',
  },
  {
    fieldName: 'Current Average Utility Rate',
    label: 'Current Average Utility Rate',
  },
  {
    fieldName: 'Contract Rate',
    label: 'Contract Rate',
  },
  {
    fieldName: 'Schedule Term',
    label: 'Schedule Term',
  },
  {
    fieldName: 'Fixed Monthly Payment for Maintenance Savings',
    label: 'Fixed Monthly Payment for Maintenance Savings',
  },
  {
    fieldName: 'Total Resource Savings Payments (for Maintenance Savings)',
    label: 'Total Resource Savings Payments (for Maintenance Savings)',
  },
  {
    fieldName: 'Total Resource Savings Payments (for Actual Savings)',
    label: 'Total Resource Savings Payments (for Actual Savings)',
  },
  {
    fieldName: 'Maximum Saved Resources Purchase Amount (not to exceed)',
    label: 'Maximum Saved Resources Purchase Amount (not to exceed)',
  },
  {
    fieldName: 'Est Sales Tax',
    label: 'Est Sales Tax',
  },
  {
    fieldName: 'Final Rebate Amount',
    label: 'Final Rebate Amount',
  },
  {
    fieldName: 'Estimated Pre-System Consumption ',
    label: 'Estimated Pre-System Consumption ',
  },
  {
    fieldName: 'Estimated Post-System consumption',
    label: 'Estimated Post-System consumption',
  },
  {
    fieldName: 'Annual Pre Burn Hours',
    label: 'Annual Pre Burn Hours',
  },
  {
    fieldName: 'Annual Post Burn Hours (With New Sensors)',
    label: 'Annual Post Burn Hours (With New Sensors)',
  },
  {
    fieldName: 'Estimated Metric Tons of CO2e Avoided Annually',
    label: 'Estimated Metric Tons of CO2e Avoided Annually',
  },
  {
    fieldName: 'Estimated Metric Tons of CO2e Avoided over Contract Term',
    label: 'Estimated Metric Tons of CO2e Avoided over Contract Term',
  },
  {
    fieldName: 'Estimated Metric Tons of CO2e Avoided over EUL',
    label: 'Estimated Metric Tons of CO2e Avoided over EUL',
  },
  {
    fieldName:
      'Additional Retained Savings from UBR Hedging over Contract Term',
    label: 'Additional Retained Savings from UBR Hedging over Contract Term',
  },
  {
    fieldName: 'Additional Savings from Avoided REC Purchase over EUL',
    label: 'Additional Savings from Avoided REC Purchase over EUL',
  },
]
export { defaultBatchFieldMappings, defaultBatchHeaders }
