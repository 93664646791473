import './Alert.css'

const NOTICE_INFO = 'info'
const NOTICE_WARN = 'warn'
const NOTICE_ERROR = 'error'
const NOTICE_DANGER = 'danger'
const config = {
  [NOTICE_INFO]: {
    icon: 'mi mi-info-outline',
  },
  [NOTICE_WARN]: {
    icon: 'mi mi-warning',
  },
  [NOTICE_ERROR]: {
    color: '',
    icon: 'mi mi-error-outline',
  },
  [NOTICE_DANGER]: {
    color: '',
    icon: 'icon-shield',
  },
}

const Alert = ({
  level = NOTICE_INFO,
  text,
}: {
  level: 'info' | 'warn' | 'error' | 'danger'
  text: Array<string>
}) => (
  <div className={`Alert ${level}`}>
    <table>
      <tbody>
        <tr>
          <td>
            <div className={`${config[level].icon} icon`} />
          </td>
          <td>
            <div className='text'>
              {/* eslint-disable react/no-array-index-key */}
              {text.map((line, i) => (
                <div key={`address-line-${i}`}>{line}</div>
              ))}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default Alert
