import Big from 'big.js'

import type { FTInputProps } from './Input'
import Input from './Input'

export const inputNumberWithPrecisionOnInput =
  (precision: number) => (event: any) => {
    const { target, nativeEvent } = event
    const { data } = nativeEvent

    if (
      target.value &&
      parseFloat(target.value) !== 0 && // If the last key pressed was a period...
      data !== '.'
    ) {
      const decimalLength = target.value.toString().split('.')[1]?.length
      target.value =
        parseFloat(target.value) ?
          Big(target.value)
            .round(precision, Big.roundDown)
            .toNumber()
            .toFixed(Math.min(decimalLength || 0, precision))
        : ''
    }
  }

const inputNumberOnKeyDownWithPrecision =
  (precision: number) => (event: KeyboardEvent) => {
    if (precision === 0) {
      if (event.key.toLowerCase() === 'e' || event.key === '.') {
        event.preventDefault()
      }
    } else if (precision !== 0) {
      if (event.key.toLowerCase() === 'e') {
        event.preventDefault()
      }
    }
  }

export type FTInputPropsWithPrecision = FTInputProps & {
  precision: number
}
export default (props: FTInputPropsWithPrecision) => (
  <Input
    onInput={inputNumberWithPrecisionOnInput(props.precision)}
    onKeyDown={inputNumberOnKeyDownWithPrecision(props.precision)}
    {...props}
  />
)
