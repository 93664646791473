import * as React from 'react'
import styled from 'styled-components'

const TabTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`
const Meta = styled.span`
  color: #7f7f7f;
  font-size: 12px;
  line-height: 22px;
  margin-left: auto;
`
const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`
type FTProps = {
  title?: string
  meta?: string | React.ReactNode
  className?: string
  children?: React.ReactNode
  marginTop?: string
  marginBottom?: string
}

const TabContent = ({
  title = '',
  meta = '',
  className = '',
  children = undefined,
  marginTop = '31px',
  marginBottom = '31px',
}: FTProps) => (
  <div className={className}>
    {(title || meta) && (
      <TabHeader marginTop={marginTop} marginBottom={marginBottom}>
        {title && <TabTitle>{title}</TabTitle>}
        {meta && <Meta>{meta}</Meta>}
      </TabHeader>
    )}
    <div>{children && children}</div>
  </div>
)

export default TabContent
