import * as React from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  color: #909090;
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
`
type FTProps = {
  children?: React.ReactNode
  className?: string
  inline?: boolean
}

const Disabled = ({ children, className, inline }: FTProps) => (
  <Styles className={className} inline={inline}>
    {children}
  </Styles>
)

Disabled.defaultProps = {
  className: '',
  inline: false,
  children: null,
}
export default Disabled
