import styled from 'styled-components'

import Modal from '../../../../../components/Modal'

const ModalOuter = styled.div`
  .header {
    padding: 0px;
    min-height: 40px;
    > :first-child {
      color: orange !important;
    }
  }

  .buttons {
    margin-top: 20px !important;
  }
`

const WarningModal = ({
  onSubmit,
  onClose,
}: {
  onSubmit: () => void
  onClose: () => void
}) => (
  <ModalOuter>
    <Modal
      modalWidth='418px'
      title='Warning!'
      renderContent={() => (
        <>
          Some of the submissions have an associated active maintenance case. Do
          you want to proceed with the submission?
        </>
      )}
      acceptText='Submit'
      onAccept={() => {
        onSubmit()
        onClose()
      }}
      onClose={onClose}
    />
  </ModalOuter>
)

export default WarningModal
