import * as types from '../constants/actionTypes'

export const forgotPasswordRequest = (email: string): Record<string, any> => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  email,
})
export const loginUser = (creds: { username: string; password: string }) => {
  const { username, password } = creds
  return {
    type: types.LOGIN_REQUEST,
    username,
    password,
  }
}
export const logoutUser = () => ({
  type: types.LOGOUT_REQUEST,
})
export const sessionTimeout = () => ({
  type: types.SESSION_TIMEOUT,
})
export const setLoginFormEmailFieldValue = ({
  value,
}: {
  value: string
}): Record<string, any> => ({
  type: types.SET_LOGIN_FORM_EMAIL_FIELD_VALUE,
  value,
})
