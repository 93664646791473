export default {
  content: [
    {
      batchAnalysesInProgressCount: 8,
      lastModified: '2020-07-30T04:38:34.000Z',
      name: 'PPG Industries',
      id: '0014600000ppVaBAAU',
      accountOwner: 'Steve Farber',
      pendingOpportunities: 1,
    },
    {
      batchAnalysesInProgressCount: 18,
      lastModified: '2019-08-12T04:38:34.000Z',
      name: 'Parts Authority',
      id: '0014600000ppVaHAAU',
      accountOwner: 'Max Dworkin',
      pendingOpportunities: 12,
    },
    {
      batchAnalysesInProgressCount: 16,
      lastModified: '2018-09-13T04:38:34.000Z',
      name: 'Saint-Gobain',
      id: '0014600000ppVakAAE',
      accountOwner: 'Mark Macomber',
      pendingOpportunities: 29,
    },
    {
      batchAnalysesInProgressCount: 30,
      lastModified: '2021-12-12T04:38:34.000Z',
      name: 'Leggett & Platt',
      id: '0014600000ppVTtAAM',
      accountOwner: 'Bert Brown',
      pendingOpportunities: 5,
    },
    {
      batchAnalysesInProgressCount: 5,
      lastModified: '2021-04-05T04:38:34.000Z',
      name: 'IBM',
      id: '0014600000ppVVTAA2',
      accountOwner: 'Bert Brown',
      pendingOpportunities: 5,
    },
    {
      batchAnalysesInProgressCount: 9,
      lastModified: '2020-07-15T04:38:34.000Z',
      name: 'McKesson Corporation',
      id: '0014600000ppVWnAAM',
      accountOwner: 'Bert Brown',
      pendingOpportunities: 1,
    },
    {
      batchAnalysesInProgressCount: 200,
      lastModified: '2019-06-30T04:38:34.000Z',
      name: 'Cinemark',
      id: '0014600000ppVWoAAM',
      accountOwner: 'Lana Gee',
      pendingOpportunities: 2,
    },
    {
      batchAnalysesInProgressCount: 98,
      lastModified: '2019-11-05T04:38:34.000Z',
      name: 'Whirlpool',
      id: '0014600000thPa4AAE',
      accountOwner: 'Max Dworkin',
      pendingOpportunities: 13,
    },
    {
      batchAnalysesInProgressCount: 17,
      lastModified: '2021-01-13T04:38:34.000Z',
      name: 'New Senior',
      id: '0014600000thPnYAAU',
      accountOwner: 'Andrew Robertson',
      pendingOpportunities: 1,
    },
    {
      batchAnalysesInProgressCount: 32,
      lastModified: '2021-12-02T04:38:34.000Z',
      name: 'Iron Mountain North America',
      id: '0014600000thPZqAAM',
      accountOwner: 'Bert Brown',
      pendingOpportunities: 1,
    },
    {
      batchAnalysesInProgressCount: 89,
      lastModified: '2021-05-23T04:38:34.000Z',
      name: 'T-Mobile US, Inc.',
      id: '0014600000wv7Y5AAI',
      accountOwner: 'Mark Macomber',
      pendingOpportunities: 24,
    },
    {
      batchAnalysesInProgressCount: 87,
      lastModified: '2021-09-07T04:38:34.000Z',
      name: 'Cintas',
      id: '00146000016EtR9AAK',
      accountOwner: 'Andrew Robertson',
      pendingOpportunities: 254,
    },
    {
      batchAnalysesInProgressCount: 34,
      lastModified: '',
      name: 'CSX Corporation',
      id: '00146000018kk9lAAA',
      accountOwner: 'Andrew Robertson',
      pendingOpportunities: 24,
    },
    {
      batchAnalysesInProgressCount: 56,
      lastModified: '2021-12-12T04:38:34.000Z',
      name: 'Goodyear',
      id: '00146000018M5WrAAK',
      accountOwner: 'Andrew Robertson',
      pendingOpportunities: 308,
    },
    {
      batchAnalysesInProgressCount: 78,
      lastModified: '2021-05-20T04:38:34.000Z',
      name: 'GKN Aerospace',
      id: '0014600001CbyDqAAJ',
      accountOwner: 'Mark Macomber',
      pendingOpportunities: 30,
    },
    {
      batchAnalysesInProgressCount: 16,
      lastModified: '2021-03-14T04:38:34.000Z',
      name: 'Trinchero Family Estates',
      id: '0014600001CcKOPAA3',
      accountOwner: 'Lana Gee',
      pendingOpportunities: 5,
    },
    {
      batchAnalysesInProgressCount: 1,
      lastModified: '2021-01-11T04:44:34.000Z',
      name: 'Cooper-Standard Holdings',
      id: '0014600001CdGajAAF',
      accountOwner: 'Andrew Robertson',
      pendingOpportunities: 3,
    },
    {
      batchAnalysesInProgressCount: 2,
      lastModified: '2021-09-25T04:48:34.000Z',
      name: 'Baker Hughes',
      id: '0014600001dGRZiAAO',
      accountOwner: 'Andrew Robertson',
      pendingOpportunities: 15,
    },
    {
      batchAnalysesInProgressCount: 4,
      lastModified: '2021-02-12T04:12:34.000Z',
      name: 'Halliburton',
      id: '0014600001dwEMDAA2',
      accountOwner: 'Bert Brown',
      pendingOpportunities: 76,
    },
    {
      batchAnalysesInProgressCount: 19,
      lastModified: '2021-02-18T04:23:34.000Z',
      name: 'Avantor Inc',
      id: '0014600001dwEOsAAM',
      accountOwner: 'Mark Macomber',
      pendingOpportunities: 26,
    },
  ],
  pageable: {
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    pageNumber: 0,
    pageSize: 20,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  last: false,
  totalPages: 2,
  totalElements: 35,
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  first: true,
  number: 0,
  numberOfElements: 20,
  size: 20,
  empty: false,
}
