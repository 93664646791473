// TODO: This is temporary file. Will be removed once this file is hosted on server.

const sampleUtilityData =
  'SiteID,RDPSiteId,ServiceAddress2,UtilityName,AccountNumber,MeterNumber,ServiceType,TariffName,InvoiceNumber,BillPeriodStart,BillPeriodEnd,BillingPeriod,TotalBillAmount,TotalUnits,UnitOfMeasure,source,SourceDocument,DataSource,URL,CustomerLocationCode,EstimatedReading,PeakDemand,PeakDemandUnits\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,loon house accomodation,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,,,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoices,UtilityAPI,,,TRUE,54,kw\n' +
  ',eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Electric,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EETs,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services1,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,07/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,56,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,05/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,08/01/2028,08/30/2028,82028,6000,440,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,05/01/28,05/31/28,,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,FALSE,147,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,08/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  '0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,03/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,,TRUE,54,kw\n' +
  ',eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,loon house accomodation,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,CT23,TRUE,54,kw\n' +
  ',eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,loon house accomodation,PG&EET,2981279047-192,101007850222,Water,E19S Medium General Demand-Metered TOU Services,,04/01/2028,06/30/2028,52028,44000,670,GAL,https://utilityapi.com/api/v2/files/pdf_bill/711293/2981279459_2020-09-02_to_2020-10-02.pdf,Invoice,UtilityAPI,,BH-1068,TRUE,54,kw\n'
export default sampleUtilityData
