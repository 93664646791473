import moment from 'moment'

export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const PROGRAM_TO_DATE = 'PROGRAM_TO_DATE'
export const YEAR_TO_DATE = 'YEAR_TO_DATE'
export const URL_DATE_FORMAT = 'YYYY-MM-DDTHH:mm'
export const LAST_24_HOURS = 'LAST_24_HOURS'
export const LAST_3_DAYS = 'LAST_3_DAYS'
export const LAST_7_DAYS = 'LAST_7_DAYS'
export const LAST_3_MONTHS = 'LAST_3_MONTHS'
export const LAST_6_MONTHS = 'LAST_6_MONTHS'
export const LAST_12_MONTHS = 'LAST_12_MONTHS'
export const LAST_MONTH = 'LAST_MONTH'
export const THIS_MONTH = 'THIS_MONTH'
export const API_DEFAULT_START_DATE: string = moment()
  .subtract(12, 'months')
  .startOf('month')
  .format(API_DATE_FORMAT)
export const RES_HOURLY = 'HOURLY'
export const RES_MONTHLY = 'MONTHLY'
export const SELECTOR_DATE_FORMAT = 'MM/DD/YY'
export const HOURLY_DATE_FORMAT = 'YYYY-MM-DD-HH:mm:ss'
export const RES_DAILY = 'DAILY'
export const DAILY_DATE_FORMAT = 'MMM DD'
export const DATE_FORMAT_DATA_API_REQUEST = 'YYYY-MM-DDTHH:mm:ss'
export const DATE_FORMAT_SIMPLE_WITH_TIME = 'YYYY-MM-DD h:mmA z'
export const NOT_SET = 'Not Set'
export const LOGOUT_TIMEOUT = 1000 * 5
export const COMPANY_URL =
  import.meta.env.VITE_CORPORATE_URL || 'https://www.redaptiveinc.com'
export const TERMS_URL = `${COMPANY_URL}/terms-use`
export const PRIVACY_URL = `${COMPANY_URL}/privacy-policy`
export const SORT_ASC = 'ASC'
export const SORT_DESC = 'DESC'
export const SORT_NONE = 'NONE'
export const STORAGE_LOGIN_TOKEN = 'admin-login-token'
export const STORAGE_USERNAME = 'admin-username'
export const STORAGE_USER_ID = 'admin-user-id'
