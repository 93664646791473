import styled from 'styled-components'

const ButtonLink = styled.button`
  background: none;
  border: none;
  color: #337ab7;
  cursor: pointer;
  font-family: 'Avenir Next', sans-serif;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`
export default ButtonLink
