import styled from 'styled-components'

export const StyledLabel = styled.p`
  color: #4a4a4a;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`

export const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  button {
    &:first-child {
      margin-left: 0;
    }
  }
`

export const HeaderContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
