import axios from 'axios'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import type { FTProposalScenario } from './scenarios'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError, queryStringify } from '../../api/utils'
import { ERR_SERVER_ERROR } from '../../constants/error'
import opportunitySummariesMockData from '../../mockData/proposals/opportunitySummaries'
import { MODAL_ERROR_BODY_FOR_UPDATE_SALESFORCE } from '../../pages/ProposalOperations/ProposalsEngine/utils'
import { handleSagaError } from '../../sagas/utils'
import { isVariantActive } from '../../utils'
import { colors } from '../../utils/themes'

type FTProposalOpportunityStatuses =
  | 'NO_SCENARIO_CREATED'
  | 'ANALYSIS_IN_PROGRESS'
  | 'SCENARIO_SELECTED'
  | 'CONTRACT_OBJECT_CREATED'
type FTSalesforceContractStatus =
  | 'NEW_CONTRACT'
  | 'UPDATE'
  | 'REJECT_AND_UPDATE'
  | null
type FTProposalOpportunitySummaryResponse = {
  auditVendor: string
  batchAnalysisTotalCount: number
  currencyCode: string
  externalId: string
  name: string
  opportunityStatus: FTProposalOpportunityStatuses
  opportunityType: string
  type: string
  programOpportunity: string
  redaptiveOpportunityId: string
  salesforceSiteId: string
  scenarios: Array<FTProposalScenario>
  scenarioTotalCount: number
  selectedInBatchAnalysis: boolean
  isLocked: boolean
  stageName: string
}
type FTProposalOpportunityStatusUpdateResponse = {
  id: string
  opportunityId: string
  latestContractId: string
}
type FTProposalSalesforceStatusResponse = {
  remodelScenario: FTSalesforceContractStatus
}
type FTProposalOpportunitySummariesResponse =
  Array<FTProposalOpportunitySummaryResponse>
type FTFieldType = {
  fieldName: string
  sequenceNumber: number
  locked: boolean
  visible: boolean
}
type FTFetchProposalOpportunityFieldMappingResponse = {
  opportunityId?: string
  opportunityBatchAnalysisId?: string
  fieldMappings?: Array<any>
}
type FTUpdateProposalOpportunityFieldMappingResponse = {
  opportunityId?: string
  opportunityBatchAnalysisId?: string
  fieldMappings: Array<FTFieldType>
}
export type FTFetchProposalOpportunityFieldMappingAction = {
  opportunityId: string
} & FTFetchProposalOpportunityFieldMappingResponse
export type FTUpdateProposalOpportunityFieldMappingAction = {
  opportunityId: string
} & FTUpdateProposalOpportunityFieldMappingResponse
export type FTProposalOpportunitySummary = {
  id: string
  isLocked: boolean
  stageName: string
} & FTProposalOpportunitySummaryResponse
export type FTFetchProposalOpportunitySummariesAction = {
  salesforceCustomerId: string
  stageNames?: Array<string>
}
export type FTUpdateOpportunityLockUnlockStatus = {
  opportunityId: string
  updatedStatus: boolean
}
export type FTUpdateProposalOpportunityStatusAction = {
  opportunityId: string
}
export type FTFetchProposalSalesforceStatusAction = {
  opportunityId: string
}
export type FTProposalOpportunitySummariesMetaState = {
  error: string
  loading: boolean
  success: boolean
}
type FTProposalSalesforceStatusMetaState = {
  error: string
  loading: boolean
}
type FTProposalFieldMappingsMetaState = {
  error: string
  loading: boolean
}
export type FTFetchProposalSalesforceStatus = {
  status: FTSalesforceContractStatus
}
type FTProposalOpportunitySummariesEntityState = {
  byId: Record<string, FTProposalOpportunitySummary>
  items: Array<FTProposalOpportunitySummary>
  fieldMappings: Array<FTFieldType>
  fieldMappingsMeta: Array<FTFieldType>
  meta: FTProposalOpportunitySummariesMetaState
  statusUpdateMeta: FTProposalFieldMappingsMetaState
  proposalSalesforceStatus: FTSalesforceContractStatus
  proposalSalesforceStatusMeta: FTProposalSalesforceStatusMetaState
}
type FTState = {
  entities: {
    proposalOpportunitySummaries: FTProposalOpportunitySummariesEntityState
  }
}
// Action Types
const types = {
  FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES: 'FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES',
  FETCH_SALESFORCE_SCENARIO_STATUS: 'FETCH_SALESFORCE_SCENARIO_STATUS',
  FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_SUCCESS:
    'FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_SUCCESS',
  FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_ERROR:
    'FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_ERROR',
  FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS:
    'FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS',
  FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR:
    'FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR',
  FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
    'FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS',
  UPDATE_PROPOSAL_OPPORTUNITY_STATUS: 'UPDATE_PROPOSAL_OPPORTUNITY_STATUS',
  UPDATE_PROPOSAL_OPPORTUNITY_STATUS_SUCCESS:
    'UPDATE_PROPOSAL_OPPORTUNITY_STATUS_SUCCESS',
  FETCH_SALESFORCE_SCENARIO_STATUS_SUCCESS:
    'FETCH_SALESFORCE_SCENARIO_STATUS_SUCCESS',
  UPDATE_PROPOSAL_OPPORTUNITY_STATUS_ERROR:
    'UPDATE_PROPOSAL_OPPORTUNITY_STATUS_ERROR',
  UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS:
    'UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS',
  UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR:
    'UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR',
  UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
    'UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS',
  FETCH_SALESFORCE_SCENARIO_STATUS_ERROR:
    'FETCH_SALESFORCE_SCENARIO_STATUS_ERROR',
  UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS: 'UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS',
  UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_SUCCESS:
    'UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_SUCCESS',
  UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_ERROR:
    'UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_ERROR',
}
export const actions = {
  fetchProposalOpportunitySummaries: (
    props: FTFetchProposalOpportunitySummariesAction,
  ) => ({
    type: types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES,
    ...props,
  }),
  fetchProposalOpportunityFieldMappings: (
    props: FTFetchProposalOpportunityFieldMappingAction,
  ) => ({
    type: types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS,
    ...props,
  }),
  updateProposalOpportunityStatus: (
    props: FTUpdateProposalOpportunityStatusAction,
  ) => ({
    type: types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS,
    ...props,
  }),
  updateProposalOpportunityFieldMappings: (
    props: FTUpdateProposalOpportunityFieldMappingAction,
  ) => ({
    type: types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS,
    ...props,
  }),
  fetchSalesforceScenarioStatus: (
    props: FTFetchProposalSalesforceStatusAction,
  ) => ({
    type: types.FETCH_SALESFORCE_SCENARIO_STATUS,
    ...props,
  }),
  updateOpportunityLockUnlockStatus: (
    props: FTUpdateOpportunityLockUnlockStatus,
  ) => ({
    type: types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS,
    ...props,
  }),
}
const initialState = {
  byId: {},
  items: [],
  fieldMappings: [],
  proposalSalesforceStatus: '',
  meta: {
    error: '',
    loading: false,
  },
  statusUpdateMeta: {
    error: '',
    loading: false,
  },
  proposalSalesforceStatusMeta: {
    error: '',
    loading: false,
  },
  fieldMappingsMeta: {
    error: '',
    loading: false,
  },
}
const entitySchema = new schema.Entity('proposalOpportunitySummaries')

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalOpportunitySummary> = Object.values(
    action.payload.entities.proposalOpportunitySummaries,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalOpportunitySummaries }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES:
      return {}

    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_SUCCESS:
      return entityById(action, state)

    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS:
      return state

    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS_SUCCESS:
      return {
        ...state,
        [action.opportunityId]: {
          ...state[action.opportunityId],
          opportunityStatus: action.status,
        },
      }

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES:
      return initialState.items

    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_SUCCESS:
      return entityItems(action, state)

    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS:
      return state

    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS_SUCCESS:
      return [
        ...state.map((opportunity) =>
          opportunity.id === action.opportunityId ?
            { ...opportunity, opportunityStatus: action.status }
          : opportunity,
        ),
      ]

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES:
    case types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS:
    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS:
    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_ERROR:
    case types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_ERROR:
    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR:
    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_SUCCESS:
    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
    case types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_SUCCESS:
    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function proposalSalesforceStatus(
  state = initialState.proposalSalesforceStatus,
  action,
) {
  switch (action.type) {
    case types.FETCH_SALESFORCE_SCENARIO_STATUS:
      return initialState.proposalSalesforceStatus

    case types.FETCH_SALESFORCE_SCENARIO_STATUS_SUCCESS:
      return action.payload

    default:
      return state
  }
}

function statusUpdateMeta(state = initialState.meta, action) {
  switch (action.type) {
    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS:
      return { ...state, error: '', loading: true, success: false }

    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS_ERROR:
      return { ...state, error: action.error, loading: false, success: false }

    case types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS_SUCCESS:
      return { ...state, error: '', loading: false, success: true }

    default:
      return state
  }
}

function fieldMappings(state = initialState.fieldMappings, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
      return action.fieldMappings

    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
      return state

    default:
      return state
  }
}

function fieldMappingsMeta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS:
    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR:
    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
    case types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function proposalSalesforceStatusMeta(
  state = initialState.proposalSalesforceStatusMeta,
  action,
) {
  switch (action.type) {
    case types.FETCH_SALESFORCE_SCENARIO_STATUS:
      return { ...state, error: '', loading: true }

    case types.FETCH_SALESFORCE_SCENARIO_STATUS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_SALESFORCE_SCENARIO_STATUS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  statusUpdateMeta,
  fieldMappings,
  fieldMappingsMeta,
  proposalSalesforceStatus,
  proposalSalesforceStatusMeta,
})
export const selectProposalOpportunitySummariesEntity = (
  state: FTState,
): FTProposalOpportunitySummariesEntityState =>
  state.entities.proposalOpportunitySummaries
const utils = {
  enhanceOpportunitySummary: (
    opportunitySummary: FTProposalOpportunitySummaryResponse,
  ): FTProposalOpportunitySummary => ({
    ...opportunitySummary,
    id: opportunitySummary.externalId,
  }),
}
export const opportunityStatuses: Record<
  string,
  {
    color: string
    displayName: string
  }
> = {
  NO_SCENARIO_CREATED: {
    color: colors.gray,
    displayName: 'NO SCENARIO CREATED',
  },
  ANALYSIS_IN_PROGRESS: {
    color: colors.green3,
    displayName: 'ANALYSIS IN PROGRESS',
  },
  SCENARIO_SELECTED: {
    color: colors.gray,
    displayName: 'SCENARIO SELECTED',
  },
  CONTRACT_OBJECT_CREATED: {
    color: colors.green3,
    displayName: 'CONTRACT OBJECT CREATED',
  },
  CONTRACT_CREATED_MANUALLY: {
    color: colors.green3,
    displayName: 'CONTRACT CREATED MANUALLY',
  },
  CONTRACT_APPROVED: {
    color: colors.green3,
    displayName: 'CONTRACT APPROVED',
  },
  REMODELING: {
    color: colors.green3,
    displayName: 'REMODELING',
  },
  UNLOCKED_FOR_REMODELING: {
    color: colors.gray,
    displayName: 'UNLOCKED FOR REMODELING',
  },
  REMODELING_IN_PROGRESS: {
    color: colors.green3,
    displayName: 'REMODELING IN PROGRESS',
  },
  REMODELING_SCENARIO_SELECTED: {
    color: colors.gray,
    displayName: 'REMODELING SCENARIO SELECTED',
  },
  REMODELING_CONTRACT_OBJECT_CREATED: {
    color: colors.green3,
    displayName: 'REMODELING CONTRACT OBJECT CREATED',
  },
}
const API = {
  updateProposalOpportunityLockUnlock: ({
    opportunityId,
    updatedStatus,
  }: FTUpdateOpportunityLockUnlockStatus) => {
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/${opportunityId}/lockedstatus`
    return axios
      .patch(url, updatedStatus, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchProposalOpportunitySummaries: ({
    salesforceCustomerId,
    stageNames,
  }: FTFetchProposalOpportunitySummariesAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(opportunitySummariesMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const query = queryStringify({
      salesforceCustomerId,
      stageNames,
    })
    const baseUrl = `${consoleBaseUrl()}/proposal/api/opportunity`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalOpportunitySummaryResponse }) => data)
      .catch(handleAxiosError)
  },
  updateProposalOpportunityStatus: ({
    opportunityId,
    ...params
  }: FTUpdateProposalOpportunityStatusAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({
        id: 1234,
        opportunityId,
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/${opportunityId}/status`
    return axios
      .patch(url, params, {
        headers: defaultHeaders(),
      })
      .then(
        ({ data }: { data: FTProposalOpportunityStatusUpdateResponse }) => data,
      )
      .catch((error) => {
        if (error.response) {
          throw new Error(ERR_SERVER_ERROR)
        }
      })
  },
  fetchSalesforceScenarioStatus: ({
    opportunityId,
  }: FTUpdateProposalOpportunityStatusAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({
        opportunityId,
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/${opportunityId}/remodel/scenario`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalSalesforceStatusResponse }) => data)
      .catch((error) => {
        if (error.response) {
          throw new Error(ERR_SERVER_ERROR)
        }
      })
  },
  fetchProposalOpportunityFieldMappings: ({
    opportunityId,
  }: FTFetchProposalOpportunityFieldMappingAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(opportunitySummariesMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const baseUrl = `${consoleBaseUrl()}/proposal/api/opportunity/field-mapping?opportunityId=${opportunityId}`
    const url = `${baseUrl}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(
        ({
          data,
        }: {
          data: FTFetchProposalOpportunityFieldMappingResponse
        }) => {
          const tempData: FTFetchProposalOpportunityFieldMappingResponse = data

          // Here we are checking if the fieldMappings is present and it does not have 'createdOnStageName' object in it.
          // If thats the case add the below written object at the 2nd position.
          // The 2nd position is chosen because it is to be shown on the 2nd number on UI.
          if (
            tempData.fieldMappings &&
            tempData.fieldMappings.length &&
            !tempData.fieldMappings.some(
              (item) => item.fieldName === 'createdOnStageName',
            )
          ) {
            tempData.fieldMappings.splice(1, 0, {
              fieldName: 'createdOnStageName',
              sequenceNumber: 1,
              locked: true,
              visible: true,
            })

            if (tempData.fieldMappings && tempData.fieldMappings.length) {
              // Here we are increasing the sequenceNumber by 1 for the objects after 2nd position.
              tempData.fieldMappings = tempData.fieldMappings.map((item, i) => {
                if (i <= 1) return item
                const tempItem = item
                tempItem.sequenceNumber += 1
                return tempItem
              })
            }
          }

          return tempData
        },
      )
      .catch(handleAxiosError)
  },
  updateProposalOpportunityFieldMappings: ({
    opportunityId,
    fieldMappings: fieldMappingsNew,
  }: FTUpdateProposalOpportunityFieldMappingAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({
        id: 1234,
        opportunityId,
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/field-mapping`
    return axios
      .post(
        url,
        {
          opportunityId,
          fieldMappings: [...fieldMappingsNew],
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(
        ({ data }: { data: FTUpdateProposalOpportunityFieldMappingResponse }) =>
          data,
      )
      .catch(handleAxiosError)
  },
}

function* fetchProposalOpportunitySummariesSaga({
  type,
  ...params
}: FTFetchProposalOpportunitySummariesAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalOpportunitySummariesResponse = yield call(
      API.fetchProposalOpportunitySummaries,
      params,
    )
    const normalized = normalize(
      response.map(utils.enhanceOpportunitySummary),
      [entitySchema],
    )
    yield put({
      type: types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES_ERROR, e)
  }
}

function* updateProposalOpportunityLockUnlockStatus({
  type,
  ...params
}: FTUpdateOpportunityLockUnlockStatus & {
  type: string
}): Generator<any, void, any> {
  try {
    yield call(API.updateProposalOpportunityLockUnlock, params)
    yield put({
      type: types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_SUCCESS,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS_ERROR, e)
  }
}

function* updateProposalOpportunityStatusSaga({
  type,
  ...params
}: FTUpdateProposalOpportunityStatusAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const payload: FTProposalOpportunityStatusUpdateResponse = yield call(
      API.updateProposalOpportunityStatus,
      params,
    )
    yield put({
      type: types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS_SUCCESS,
      ...payload,
    })
  } catch (e) {
    yield handleSagaError(
      types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS_ERROR,
      e,
      MODAL_ERROR_BODY_FOR_UPDATE_SALESFORCE,
      false,
    )
  }
}

function* fetchProposalOpportunityFieldMappingsSaga({
  type,
  ...params
}: FTFetchProposalOpportunityFieldMappingAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const payload: FTFetchProposalOpportunityFieldMappingResponse = yield call(
      API.fetchProposalOpportunityFieldMappings,
      params,
    )
    yield put({
      type: types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS,
      ...payload,
    })
  } catch (e) {
    yield handleSagaError(
      types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR,
      e,
    )
  }
}

function* updateProposalOpportunityFieldMappingsSaga({
  type,
  ...params
}: FTUpdateProposalOpportunityFieldMappingAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const payload: FTUpdateProposalOpportunityFieldMappingResponse = yield call(
      API.updateProposalOpportunityFieldMappings,
      params,
    )
    yield put({
      type: types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_SUCCESS,
      ...payload,
    })
  } catch (e) {
    yield handleSagaError(
      types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS_ERROR,
      e,
    )
  }
}

function* fetchSalesforceScenarioStatusSaga({
  type,
  ...params
}: FTFetchProposalSalesforceStatusAction & {
  type: string
}): Generator<any, void, any> {
  try {
    const response: FTProposalSalesforceStatusResponse = yield call(
      API.fetchSalesforceScenarioStatus,
      params,
    )
    yield put({
      type: types.FETCH_SALESFORCE_SCENARIO_STATUS_SUCCESS,
      payload: response.remodelScenario,
    })
  } catch (e) {
    yield handleSagaError(
      types.FETCH_SALESFORCE_SCENARIO_STATUS_ERROR,
      e,
      MODAL_ERROR_BODY_FOR_UPDATE_SALESFORCE,
      false,
    )
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_PROPOSAL_OPPORTUNITY_SUMMARIES,
    fetchProposalOpportunitySummariesSaga,
  ),
  takeLatest(
    types.UPDATE_PROPOSAL_OPPORTUNITY_STATUS,
    updateProposalOpportunityStatusSaga,
  ),
  takeLatest(
    types.UPDATE_OPPORTUNIY_LOCK_UNLOCK_STATUS,
    updateProposalOpportunityLockUnlockStatus,
  ),
  takeLatest(
    types.FETCH_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS,
    fetchProposalOpportunityFieldMappingsSaga,
  ),
  takeLatest(
    types.UPDATE_PROPOSAL_OPPORTUNITY_FIELD_MAPPINGS,
    updateProposalOpportunityFieldMappingsSaga,
  ),
  takeLatest(
    types.FETCH_SALESFORCE_SCENARIO_STATUS,
    fetchSalesforceScenarioStatusSaga,
  ),
]
