import { capitalCase } from 'capital-case'
import moment from 'moment'
import { useState } from 'react'

import {
  InfoIcon,
  StatusDisplayText,
  TooltipContainer,
  TooltipText,
  UploadTimeDisplayText,
} from './styles'
import InfoCircleOutline from '../../../components/Icons/svg/InfoCircleOutline'
import { SELECTOR_DATE_FORMAT } from '../../../constants'
import { colors } from '../../../utils/themes'

type TSProps = {
  status: 'SUCCESS' | 'FAILED' | 'Processing'
  uploadDate: string
}
export const ISRUploadStatusDisplayForTable = ({
  status,
  uploadDate,
}: TSProps) => {
  const getColour = () => {
    switch (status.toLowerCase()) {
      case 'success':
        return colors.green
      case 'failed':
        return colors.red2
      default:
        return colors.orange
    }
  }

  const InfoIconWithTooltip = ({ tooltipText }) => {
    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    return (
      <TooltipContainer>
        <InfoIcon
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <InfoCircleOutline />
        </InfoIcon>
        <TooltipText isHovered={isHovered}>{tooltipText}</TooltipText>
      </TooltipContainer>
    )
  }
  return (
    <>
      <StatusDisplayText statusColor={getColour()}>
        {capitalCase(status)}
      </StatusDisplayText>
      {status !== 'FAILED' ?
        <>
          |
          <UploadTimeDisplayText>
            {moment(uploadDate).format(SELECTOR_DATE_FORMAT)}
          </UploadTimeDisplayText>
        </>
      : <InfoIconWithTooltip tooltipText='Failed due to missing data.' />}
    </>
  )
}
