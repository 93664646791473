import styled from 'styled-components'

import { fieldNameMap } from '../ducks/meters/generation'
import type { FTPanel } from '../ducks/panels'
import type { FTFormFieldEvent } from '../types'

const StyledPanelSelectorItem = styled.div`
  width: 100%;
`
const StyledIcon = styled.div`
  cursor: pointer;
  width: 16px;
`
const StyledIcons = styled.div`
  display: none;
  float: right;
  margin-top: 4px;
  margin-right: 22px;

  ${StyledPanelSelectorItem}:hover & {
    display: block;
  }

  ${StyledIcon} {
    display: inline-block;

    + ${StyledIcon} {
      margin-left: 13px;
    }

    &:hover {
      color: #337ab7;
    }
  }
`
const StyledPanelDetails = styled.div`
  color: #7f7f7f;
  font-size: 12px;
  line-height: 1.7;
`
const StyledPanelTitle = styled.div`
  color: #4a4a4a;
  font-weight: 600;
  line-height: 1.7;
`
type FTProps = {
  onClickEdit: FTFormFieldEvent
  onClickDelete: FTFormFieldEvent
  panel: FTPanel
  readOnly?: boolean
}

const PanelSelectorItem = (props: FTProps) => {
  const { onClickEdit, onClickDelete, panel, readOnly = false } = props
  const { description, name, type, voltage } = panel
  const details = [description, voltage, type]
    .filter(Boolean)
    .map((fieldValue) => fieldNameMap.get(fieldValue) || fieldValue)
    .join(' | ')
  return (
    <StyledPanelSelectorItem>
      {!readOnly && (
        <StyledIcons>
          <StyledIcon className='icon-pencil' onClick={onClickEdit} />
          <StyledIcon className='icon-trash' onClick={onClickDelete} />
        </StyledIcons>
      )}
      <StyledPanelTitle>{name}</StyledPanelTitle>
      <StyledPanelDetails>{details}</StyledPanelDetails>
    </StyledPanelSelectorItem>
  )
}

export default PanelSelectorItem
