import { ReactNode } from 'react'
import styled from 'styled-components'

import { colors } from '../utils/themes'

type FTProps = {
  Body: () => ReactNode
  Indicator: () => ReactNode
  title: string
}
export const IndicatorCardStyled = styled.div`
  background-color: #fff;
  border: 1px solid ${colors.blue14};
  border-radius: 4px;
  box-shadow: 0 0 5px 1px rgba(105, 105, 105, 0.15);
  padding: 16px;
`
const IndicatorCardHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
export const IndicatorCardTitleStyled = styled.div`
  color: ${colors.blue3};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`
export default ({ title, Indicator, Body }: FTProps) => (
  <IndicatorCardStyled>
    <IndicatorCardHeaderStyled>
      <Indicator />
      <IndicatorCardTitleStyled>{title}</IndicatorCardTitleStyled>
    </IndicatorCardHeaderStyled>
    <Body />
  </IndicatorCardStyled>
)
