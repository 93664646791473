import { ReactNode } from 'react'
import styled from 'styled-components'

import { Label, Value } from './Base'

const StyledTable = styled.table`
  width: 100%;
`
const StyledLabel = styled(Label)`
  font-weight: 500;
`
const StyledValue = styled(Value)`
  text-align: right;
  overflow: unset;
`
const StyledItem = styled.div`
  color: #4a4a4a;
  line-height: 46px;
  padding: 0 20px;
  margin-bottom: 0;
  tr:nth-child(odd) & {
    background-color: #f7f7f7;
  }
`
type FTRow = {
  label: string
  value: number | string
  renderField?: () => ReactNode
}
type FTProps = {
  items: Array<FTRow>
}

const StripedFields = ({ items }: FTProps) => (
  <StyledTable>
    <tbody>
      {items.map(({ label, value, renderField }) => (
        <tr key={`${label}-${value}`}>
          <td>
            <StyledItem>
              <StyledLabel>{label}</StyledLabel>
              <StyledValue>{renderField ? renderField() : value}</StyledValue>
            </StyledItem>
          </td>
        </tr>
      ))}
    </tbody>
  </StyledTable>
)

export default StripedFields
