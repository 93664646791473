import * as React from 'react' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const LinkStyles = css`
  display: ${({ display }) => display || 'block'};
  color: #337ab7;

  &:hover {
    text-decoration: underline;
  }
`
const StyledLink = styled.a`
  ${LinkStyles}
`
const StyledRouterLink = styled(Link)`
  ${LinkStyles}
`
type FTProps = {
  href: string
  children: React.ReactNode
  display?: string
  external?: boolean
}

const Component = ({
  href,
  children,
  display = 'block',
  external = false,
  ...props
}: FTProps) => {
  if (external) {
    return (
      <StyledLink
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        display={display}
        {...props}
      >
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledRouterLink href={href} to={href} display={display} {...props}>
      {children}
    </StyledRouterLink>
  )
}

export default Component
