import Tippy from '@tippyjs/react'
import * as React from 'react'
import styled from 'styled-components'

import SplitTable from '../SplitTable'
import type { FTChannelConfig } from '../../ducks/meters/config'
import '../../ducks/meters/config'
import Checkbox from '../Checkbox'
import InfoCircleIcon from '../Icons/InfoCircleIcon'

const StyledCheckbox = styled(Checkbox)`
  padding-left: 7px;

  &:after {
    margin: 0;
    padding: 0;
    width: 0;
  }
`
const InfoIconStyled = styled(InfoCircleIcon)`
  color: #888;
  font-size: 16px;
  position: relative;
  top: 4px;
  width: 16px;
`
type FTProps = {
  renderRowBody: (arg0: FTChannelConfig, arg1: number) => React.ReactNode
  items: Array<FTChannelConfig>
  onInUseSelectAllChange1?: React.EventHandler
  onInUseSelectAllChange2?: React.EventHandler
  inUseSelectAllChecked1?: boolean
  inUseSelectAllChecked2?: boolean
  isEditForm?: boolean
  isNebula: boolean
  isOrion: boolean
}

const ChannelsTable = ({
  inUseSelectAllChecked1,
  inUseSelectAllChecked2,
  isEditForm,
  isNebula,
  isOrion,
  items,
  onInUseSelectAllChange1,
  onInUseSelectAllChange2,
  renderRowBody,
}: FTProps) => {
  const defaultHeaders = [
    {
      fieldName: 'meterChannel',
      displayName: 'Meter Channel',
    },
    {
      fieldName: 'panelId',
      displayName: 'Panel',
    },
    {
      fieldName: 'breakerNumber',
      displayName: 'Breaker #',
    },
    {
      fieldName: 'ctType',
      displayName: 'CT Type',
    },
    {
      fieldName: 'phase',
      displayName: 'Phase',
    },
  ]
  const ctOrientationToolTip = (
    <span>{'(\u2021) Indicates default setting'}</span>
  )
  const defaultHeaders2 =
    isNebula || isOrion ?
      [
        {
          fieldName: 'flippedCTStatus',
          displayName:
            isEditForm ?
              <>
                {'CT Orientation '}
                <Tippy content={ctOrientationToolTip} delay={500}>
                  <InfoIconStyled inline />
                </Tippy>
              </>
            : 'CT Orientation',
        },
      ]
    : []
  const tableHeaders1 = [
    ...defaultHeaders,
    {
      fieldName: 'inUse',
      displayName:
        isEditForm ?
          [
            'In Use',
            <StyledCheckbox
              checked={inUseSelectAllChecked1}
              onChange={onInUseSelectAllChange1}
            />,
          ]
        : 'In Use',
    },
    ...defaultHeaders2,
  ]
  const tableHeaders2 = [
    ...defaultHeaders,
    {
      fieldName: 'inUse',
      displayName:
        isEditForm ?
          [
            'In Use',
            <StyledCheckbox
              checked={inUseSelectAllChecked2}
              onChange={onInUseSelectAllChange2}
            />,
          ]
        : 'In Use',
    },
    ...defaultHeaders2,
  ]
  return (
    <SplitTable.Grouped
      keyField='circuitId'
      columnCount={tableHeaders1.length}
      renderRowBody={renderRowBody}
      items={items}
      tableHeaders1={tableHeaders1}
      tableHeaders2={tableHeaders2}
    />
  )
}

ChannelsTable.defaultProps = {
  isEditForm: false,
  inUseSelectAllChecked1: false,
  inUseSelectAllChecked2: false,
  onInUseSelectAllChange1: () => {},
  onInUseSelectAllChange2: () => {},
}
export default ChannelsTable
