import { PureComponent } from 'react'
import styled from 'styled-components'

const MAX_STEPS = 9
const StepMarker = styled.span`
  border-radius: 25px;
  background-color: ${({ active, completed }) =>
    active || completed ? '#0193d7' : '#AAAAAA'};
  color: #fff;
  font-size: ${({ completed }) => (completed ? '12px' : '13px')};
  font-weight: ${({ completed }) => (completed ? '600' : '500')};
  width: 22px;
  height: 22px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`
export const StepLabelStyles = styled.div`
  font-size: 13px;
  font-weight: 500;
  display: inline;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`
const StepNumber = styled.div`
  text-align: center;
  line-height: 22px;
`
const Step = styled.div`
  & + &:before {
    content: ' ';
    width: 150px;
    height: 3px;
    display: inline-block;
    border-top: 1px solid #cccccc;
    margin-left: 15px;
    margin-right: 15px;
    cursor: default;
  }
`
export const StepperInstructionsStyles = styled.div`
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`
const StepsSection = styled.div`
  display: flex;
`
export const StepperStyles = styled.div`
  user-select: none;
  margin-top: 35px;
  margin-bottom: 30px;
`
export type FTStep = {
  label: string
  instructions?: string
  canClick?: boolean
}
type FTProps = {
  steps: Array<FTStep>
  currentStep: number
  setStep: (...args: Array<any>) => any
}
export default class Stepper extends PureComponent<FTProps> {
  render() {
    const { steps, currentStep, setStep, ...rest } = this.props
    let step = currentStep

    if (currentStep < 0) {
      step = 1
    } else if (currentStep > MAX_STEPS) {
      step = steps.length + 1
    }

    let instructions = ''

    if (step <= steps.length) {
      ;({ instructions } = steps[step - 1])
    }

    return (
      <StepperStyles {...rest}>
        <StepsSection>
          {steps.map(({ label, canClick }, i) => {
            const active = step === i + 1
            const completed = step > i + 1
            return (
              <Step active={active} key={label}>
                <StepMarker active={active} completed={completed}>
                  {!completed && <StepNumber>{i + 1}</StepNumber>}
                  {completed && (
                    <StepNumber
                      clickable={canClick}
                      completed={completed}
                      onClick={() => canClick && setStep(i + 1)}
                    >
                      <i className='fas fa-check' />
                    </StepNumber>
                  )}
                </StepMarker>
                <StepLabelStyles
                  clickable={canClick}
                  onClick={() => canClick && setStep(i + 1)}
                >
                  {label}
                </StepLabelStyles>
              </Step>
            )
          })}
        </StepsSection>
        {instructions && (
          <StepperInstructionsStyles>
            {`Step ${step}: ${instructions}`}
          </StepperInstructionsStyles>
        )}
      </StepperStyles>
    )
  }
}
