import { useCallback, useState } from 'react'

import FileUploadModal from './FileUploadModal'
import Button from '../../../components/Button2'
import UploadIcon from '../../../components/Icons/svg/UploadIcon'
import PageHeader from '../../../components/PageHeader'
import { FTWithRouter } from '../../../types'
import {
  DataSolutionInvoicingPageStyled,
  FileUploadSectionContainer,
  HeaderStyled,
  IconContainer,
} from '../BillingThisMonth/styles'

type FTProps = FTWithRouter
const DataSolutionInvoicingIndex = (props: FTProps) => {
  const { history } = props
  const [showFileUploadModal, setShowFileUploadModal] = useState(false)
  const handleBackNavigation = useCallback(() => {
    history.push('/billing')
  }, [history])
  return (
    <DataSolutionInvoicingPageStyled>
      <HeaderStyled>
        <PageHeader
          Title='Data Solutions Invoice Generator'
          handleBackNavigation={handleBackNavigation}
        />
      </HeaderStyled>
      <FileUploadSectionContainer>
        <Button
          type='outlined'
          onClick={() => {
            setShowFileUploadModal(true)
          }}
          disabled={false}
        >
          <IconContainer>
            <UploadIcon height={16} width={16} />
          </IconContainer>
          &nbsp; Upload Invoice File
        </Button>
      </FileUploadSectionContainer>
      <FileUploadModal
        showDilog={showFileUploadModal}
        setShowDialog={setShowFileUploadModal}
      />
    </DataSolutionInvoicingPageStyled>
  )
}
export default DataSolutionInvoicingIndex
