import * as React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
  user-select: none;
`
const Description = styled.div`
  margin-bottom: 30px;
  user-select: none;
`
const Body = styled.div`
  padding: 0 10px;
`
const Styles = styled.div`
  padding: 20px;
  background-color: ${({ isReadOnly }) =>
    isReadOnly ? 'transparent' : '#f6f6f6'};
  border: 1px solid #e0e0e0;
  border-radius: 3px;

  & + & {
    margin-top: 40px;
  }
`
type FTProps = {
  title?: string
  description?: string
  children: React.ReactNode
  isReadOnly?: boolean
}
export default class FormSection extends React.PureComponent<FTProps> {
  static defaultProps = {
    title: '',
    description: '',
    isReadOnly: false,
  }

  render() {
    const { title, description, children, isReadOnly } = this.props
    return (
      <Styles isReadOnly={isReadOnly}>
        {title && <Title>{title}</Title>}
        <Body>
          {description && <Description>{description}</Description>}
          {children}
        </Body>
      </Styles>
    )
  }
}
