export default {
  next: 'http://localhost:8088/api/meter/submission/details/list?pageNumber=2&pageSize=20',
  results: [
    {
      id: '440456c3-d608-4986-bb56-c6db40341d4e',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bac',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-09-01T13:29:01.656105',
      createdBy: 'jitendra.tiwari@redaptiveinc.com',
      modified: '2022-09-01T16:47:12.152479',
      modifiedBy: 'jitendra.tiwari@redaptiveinc.com',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '19f79d05-2d03-4527-a793-dd166f4f1a13',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-06-28T10:33:51.328475',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '3f961828-e140-4d48-bae7-153581ae5f5b',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-06-28T11:17:45.556224',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: 'f26706c5-87ab-489f-b204-cd5f6c9350d0',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-06-29T17:20:58.300528',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '144d8046-72f4-49d5-b27b-1642ff0b01c6',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: '4d6a214a-50c2-490e-856c-285e728a4dba',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-06-17T15:01:59.775276',
      createdBy: 'conceptVines@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '3a01bf50-de7e-4a04-bffe-c5ba54b5cb50',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: '4d6a214a-50c2-490e-856c-285e728a4dba',
      jobId: '272f6aeb-40b4-4dbe-b000-a138a48440e8',
      opportunityId: 'R00004977',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-02T15:04:12.533772',
      createdBy: 'conceptVines@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: 'c262e7ff-f370-471d-b5f2-283d106ba720',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: '4d6a214a-50c2-490e-856c-285e728a4dba',
      jobId: '272f6aeb-40b4-4dbe-b000-a138a48440e8',
      opportunityId: 'R00004977',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-09T06:42:35.342109',
      createdBy: 'conceptVines@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '9ae16e34-9b09-4a41-97db-912838e759c7',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-12T07:28:51.92091',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '6fd409c1-92fd-4df5-a19b-204083f606b5',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-12T07:39:04.14994',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: 'b57018aa-3319-4af1-9c5b-2ad3c8a6b94d',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-12T07:40:30.887905',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '5dd72ede-1059-4d10-8f39-3111139e8375',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bac',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-12T18:21:35.273913',
      createdBy: 'sunny.gangisetti@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '12a0b813-4dad-40f1-a406-5e93e3821fa0',
      meterId: 'e616733c-da2b-4a23-95f8-70a9a6d72fce',
      isAssignedToSite: 'NO',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: '4d6a214a-50c2-490e-856c-285e728a4dba',
      opportunityId: 'E00009399',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-18T18:16:39.350395',
      createdBy: 'conceptVines@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
    },
    {
      id: '81c8f53e-efe1-44b3-ad61-d2357bc6c11a',
      meterId: 'a6adff20-214f-4d8d-8882-92c94ba6e512',
      macAddress: '3045110FF612',
      isAssignedToSite: 'NO',
      customerName: 'Parts Authority',
      siteLocation: '3119 South Willow Avenue, Fresno, CA 93725',
      cellRouterInstalled: 'YES',
      evVerified: 'NO',
      userId: 'bcc6f0b2-1adb-4bf3-a317-360fa75224dd',
      jobId: '9a7cee35-b18d-47b6-9988-c1242054abe1',
      opportunityId: 'R00004806',
      code: 'E00009393',
      isDeleted: false,
      submissionDate: '2022-08-23T12:22:04.290645',
      createdBy: 'udayap@conceptvines.com',
      modified: '2022-08-23T12:26:48.541585',
      modifiedBy: 'udayap@conceptvines.com',
      configAcceptedDate: '2019-09-21T00:01:29.000+00:00',
    },
    {
      id: '8e18d2bd-7924-48b1-a21b-d12efdc39d5e',
      meterId: '32b77310-7311-4385-a3d3-471502f93b85',
      isAssignedToSite: 'NO',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: '632f15b8-8543-4511-84eb-41a1ab066a34',
      opportunityId: 'R00011769',
      code: 'R00011769',
      isDeleted: false,
      submissionDate: '2022-08-29T18:12:08.884007',
      createdBy: 'bill.masters@redaptiveinc.com',
      modified: '2022-09-02T07:44:51.504577',
    },
    {
      id: 'ec7e3b6c-2545-48c9-b0b5-b67d59759cef',
      meterId: '48cde8ab-c219-48c8-8f0f-6db18bc6a628',
      isAssignedToSite: 'NO',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: 'd659f9ab-c5f7-43ba-b107-cc09574b2ed7',
      opportunityId: 'R00001750',
      code: 'R00001750',
      isDeleted: false,
      submissionDate: '2022-08-23T17:18:04.949314',
      createdBy: 'seyedm@conceptvines.com',
      modified: '2022-08-23T17:22:53.426963',
      modifiedBy: 'seyedm@conceptvines.com',
    },
    {
      id: 'c1e005a8-9eb2-4448-b136-d7efa0d433fb',
      meterId: '251c2b3b-9d7e-4c7b-b357-f7d450e018ef',
      macAddress: '0035FF99D272',
      isAssignedToSite: 'NO',
      customerName: 'ALG',
      siteLocation: '185 Brick Farm Road, Sylva, NC 28779',
      cellRouterInstalled: 'YES',
      evVerified: 'YES',
      userId: 'd659f9ab-c5f7-43ba-b107-cc09574b2ed7',
      opportunityId: 'R00001750',
      code: 'R00001750',
      isDeleted: false,
      submissionDate: '2022-08-23T17:35:22.285292',
      createdBy: 'seyedm@conceptvines.com',
      modified: '2022-09-02T07:44:51.504577',
    },
    {
      id: '9b0701a1-0b3d-4bf9-a16f-47e27b4200fb',
      meterId: '48cde8ab-c219-48c8-8f0f-6db18bc6a628',
      isAssignedToSite: 'NO',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: 'bcc6f0b2-1adb-4bf3-a317-360fa75224dd',
      jobId: '9a7cee35-b18d-47b6-9988-c1242054abe1',
      opportunityId: 'R00004806',
      code: 'E00009393',
      isDeleted: false,
      submissionDate: '2022-08-24T06:38:49.939983',
      createdBy: 'udayap@conceptvines.com',
      modified: '2022-08-26T05:53:33.877092',
      modifiedBy: 'udayap@conceptvines.com',
    },
    {
      id: '0150728f-429f-4f18-a79c-4ee82c043e33',
      meterId: '2a07e63f-b537-4976-9363-574aedde2405',
      macAddress: 'D8A98B5D48CB',
      isAssignedToSite: 'YES',
      customerName: 'Default',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: 'bcc6f0b2-1adb-4bf3-a317-360fa75224dd',
      jobId: '9a7cee35-b18d-47b6-9988-c1242054abe1',
      opportunityId: 'R00004806',
      code: 'E00009393',
      isDeleted: false,
      submissionDate: '2022-08-23T13:45:54.389248',
      createdBy: 'udayap@conceptvines.com',
      modified: '2022-08-23T14:21:19.264136',
      modifiedBy: 'udayap@conceptvines.com',
    },
    {
      id: '979dc20d-9fbb-439f-a997-f5a7f42e50c7',
      meterId: '32b77310-7311-4385-a3d3-471502f93b85',
      isAssignedToSite: 'NO',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: 'af444eed-b613-4c7e-aff3-cd6695bb4bab',
      jobId: '272f6aeb-40b4-4dbe-b000-a138a48440e8',
      opportunityId: 'R00004977',
      code: 'E00009399',
      isDeleted: false,
      submissionDate: '2022-08-24T16:18:47.210867',
      createdBy: 'smita.sinha@redaptiveinc.com',
      modified: '2022-09-01T11:23:36.355363',
      modifiedBy: 'smita.sinha@redaptiveinc.com',
    },
    {
      id: 'b4108ab3-4aaf-47dd-891b-5e34ff2e51b4',
      meterId: '32b77310-7311-4385-a3d3-471502f93b85',
      isAssignedToSite: 'NO',
      siteLocation: '',
      cellRouterInstalled: 'NO',
      evVerified: 'NO',
      userId: 'd659f9ab-c5f7-43ba-b107-cc09574b2ed7',
      opportunityId: 'E00009425',
      code: 'E00009425',
      isDeleted: false,
      submissionDate: '2022-08-29T11:29:08.63783',
      createdBy: 'seyedm@conceptvines.com',
      modified: '2022-09-02T07:44:51.504577',
    },
  ],
  totalCount: 40,
}
