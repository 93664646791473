import { FastField, Form, withFormik } from 'formik'
import { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import logo from '../redaptive-logo.svg'
import Alert from './Alert'
import Spinner from './Spinner'
import './LoginForm.css'
import { AUTH_EMAIL_INCORRECT, AUTH_LOGGED_OUT } from '../constants/strings'
import type { FTWithFormik } from '../types'

type FTProps = FTWithFormik & {
  notice: string
  authError: Record<string, any>
  loading: boolean
  isSubmitting: boolean
  setSubmitting: (...args: Array<any>) => any
  onEmailChange: (...args: Array<any>) => any
  initialValues: {
    username: string
  }
}
export const FooterLinksStyles = styled.div`
  color: #dcdcdc;
  margin-top: 30px;
  text-align: center;

  a {
    color: #337ab7;
  }
`
const validationSchema = Yup.object().shape({
  username: Yup.string().email().required(),
  password: Yup.string().required(),
}) // https://github.com/redux-saga/redux-saga/issues/161

class LoginForm extends PureComponent<FTProps> {
  componentDidUpdate(prevProps: FTProps) {
    const { loading, values, onEmailChange } = this.props
    const { username } = values
    const { values: valuesPrev } = prevProps
    const { username: usernamePrev } = valuesPrev

    if (username !== usernamePrev) {
      onEmailChange(username)
    }

    if (!loading) {
      this.props.setSubmitting(false)
    }
  }

  render() {
    const { notice, authError, loading, isSubmitting } = this.props
    let text
    let level

    if (notice) {
      level = 'info'

      if (notice === 'logout') {
        text = AUTH_LOGGED_OUT
      } else {
        text = [notice]
      }
    }

    // Auth error takes precedence over notices.
    if (authError) {
      text = AUTH_EMAIL_INCORRECT
      level = 'danger'
    }

    return (
      <div className='LoginForm'>
        <div className='form'>
          <div className='logo'>
            <img src={logo} alt='Redaptive' />
          </div>
          {!loading && text && level && <Alert level={level} text={text} />}
          {loading && <Spinner size='tiny' />}

          <Form>
            <FastField
              aria-label='username'
              name='username'
              type='email'
              placeholder='Email'
            />
            <FastField
              aria-label='password'
              name='password'
              type='password'
              placeholder='Password'
            />
            <button aria-label='sign in' type='submit' disabled={isSubmitting}>
              Sign In
            </button>
          </Form>
          <FooterLinksStyles>
            <Link to='/help'>Help</Link>
            {' | '}
            <Link to='/forgot'>Forgot Your Password?</Link>
          </FooterLinksStyles>
        </div>
      </div>
    )
  }
}

export default withFormik({
  validationSchema,

  handleSubmit(values, { props }) {
    const { onSubmit } = props
    onSubmit(values)
  },

  mapPropsToValues: (props: FTProps) => {
    const {
      initialValues: { username },
    } = props
    return {
      username,
      password: '',
    }
  },
})(LoginForm)
