import moment from 'moment'

import type { FTPending } from '../types'
import type { FTAction } from '../utils'
import { makeActionTypes, makeHybridField, renderTimestamp } from '../utils'
import '../types'
import type {
  FTMeterConfigPhaseCorrectionAngles,
  FTMeterConfigResponse,
  FTMeterConfigVoltageScalingFactors,
  FTMeterResponse,
} from './index'
import type { FTMessageInput } from '../messageFlasher'
import type { FTFlippedCtStatus } from '../circuits'
import { flippedCTStatusLabels, utils as circuitUtils } from '../circuits'
import Modals from '../../components/MeterConfiguration/Modals'
import { formatNumberToDecimals } from '../../utils'

type FTBaseChannelConfig = {
  breakerNumber?: string
  circuitId: string
  ctType: number
  ctTypeAmps?: string
  flippedCTLabel?: string
  flippedCTManuallySet?: boolean
  flippedCTStatusCurrent?: FTFlippedCtStatus
  flippedCTStatusDefault?: FTFlippedCtStatus
  inUse?: boolean
  meterChannel: string
  panelId?: string
  phase: string
}
export type FTPendingChannelConfig = {
  ctType: FTPending
  ctTypeAmps: FTPending
  inUse: FTPending
} & FTBaseChannelConfig
export type FTChannelConfig = FTBaseChannelConfig | FTPendingChannelConfig
type FTChannelConfigUpdate = {
  breakerNumber?: number
  circuitId?: string
  ctType?: string
  flippedCTStatusCurrent?: string
  inUse?: boolean
  panelId?: boolean
  phase?: string
}
export type FTMeterConfigUpdateRequest = {
  id: string
  meta: {
    circuits: Array<{
      breakerNumber: string
      id: string
      flippedCT?: {
        status: string
      }
    }>
    meter?: {
      id: string
      powersource: {
        vtapL1PhaseA?: string
        vtapL2PhaseB?: string
        vtapL3PhaseC?: string
      }
    }
    panel?: {
      description?: string
      id: string
      name?: string
      siteId: string
      type?: string
      voltage?: string
    }
  }
  meterConfig?: {
    circuitConfigurations: Array<{
      circuitId: string
      ctType: number
      inUse: boolean
      phase: string
    }>
    phaseCorrectionAngles: FTMeterConfigPhaseCorrectionAngles | null | undefined
    voltageScalingFactor: number
    voltageScalingFactors: FTMeterConfigVoltageScalingFactors | null | undefined
  }
  type?: string
}
export type FTMeterConfigUpdate = {
  circuitsConfigChanged: boolean
  phaseCorrectionAnglesChanged: boolean
  voltageScalingChanged: boolean
  voltageScalingFactorsChanged: boolean
  isNebula: boolean
  isOrion: boolean
  meterOnline: boolean
} & FTMeterConfigUpdateRequest
export type FTMeterConfig = {
  appliedTimestamp?: string
  circuitConfigurationsById: Record<string, FTChannelConfig>
  createdTimestamp?: string
  createdTs: number
  dataResolution?: string
  formattedVersion?: string
  stepDownTransformer?: string
  voltageScalingFactor: number | FTPending
} & FTMeterConfigResponse
export type FTState = {
  meta: {
    error: string
    updateLoading: boolean
  }
}
const initialState = {
  meta: {
    error: '',
    updateLoading: false,
  },
}
// Action Types
export const types = { ...makeActionTypes('UPDATE_METER_CONFIG') }

function meterConfig(state: FTState = initialState, action: FTAction) {
  switch (action.type) {
    case types.UPDATE_METER_CONFIG:
      return {
        ...state,
        meta: { ...state.meta, updateLoading: true, error: '' },
      }

    case types.UPDATE_METER_CONFIG_ERROR:
      return {
        ...state,
        meta: { ...state.meta, updateLoading: false, error: action.error },
      }

    case types.UPDATE_METER_CONFIG_SUCCESS:
      return {
        ...state,
        meta: { ...state.meta, updateLoading: false, error: '' },
      }

    default:
      return state
  }
}

export const configStates = {
  NO_CHANGE: 'NO_CHANGE',
  PENDING: 'PENDING',
  PAST_DUE: 'PAST_DUE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}
export const hasConfigState = {
  noChange: () => ({
    status: configStates.NO_CHANGE,
    createdTimestamp: '',
  }),
  unknownError: () => ({
    status: configStates.UNKNOWN_ERROR,
    createdTimestamp: '',
  }),
  pending: (createdTimestamp: string) => ({
    status: configStates.PENDING,
    createdTimestamp,
  }),
  pastDue: (createdTimestamp: string) => ({
    status: configStates.PAST_DUE,
    createdTimestamp,
  }),
}
// Utils
export const getMeterConfigState = (
  meter: FTMeterResponse,
  pendingLimit: [number, string],
) => {
  if (!meter) {
    return hasConfigState.noChange()
  }

  const { pendingConfig, currentConfig, siteTimezone } = meter

  if (!pendingConfig) {
    return hasConfigState.noChange()
  }

  const { createdTs } = pendingConfig

  if (!createdTs || (pendingConfig && !currentConfig)) {
    return hasConfigState.unknownError()
  }

  const momentCreated = moment(createdTs)

  if (!momentCreated.isValid()) {
    return hasConfigState.unknownError()
  }

  const createdWithTimezone = renderTimestamp(createdTs, siteTimezone)
  const limit = momentCreated.clone().add(...pendingLimit)

  if (moment().startOf('minute').isSameOrBefore(limit)) {
    return hasConfigState.pending(createdWithTimezone)
  }

  return hasConfigState.pastDue(createdWithTimezone)
}

const getMeterConfigNotice = ({
  meter,
  pendingLimit,
}: {
  meter: FTMeterResponse
  pendingLimit: [number, string]
}): FTMessageInput | null | undefined => {
  const pendingLimitDescription = pendingLimit.join(' ')
  const { status, createdTimestamp } = getMeterConfigState(meter, pendingLimit)

  switch (status) {
    case configStates.UNKNOWN_ERROR:
      return {
        type: 'error',
        title: 'Something went wrong',
        description:
          "We're having issues determining the current Meter's Configuration. " +
          'If this issue persists, please contact an Administrator',
      }

    case configStates.PENDING:
      return {
        type: 'info',
        title: 'Meter Configuration Pending',
        description:
          `The configuration changes submitted on ${createdTimestamp} ` +
          `are still pending. It may take up to ${pendingLimitDescription} for the meter to update.`,
      }

    case configStates.PAST_DUE:
      return {
        type: 'error',
        title: 'Meter Did Not Update',
        // eslint-disable-next-line
        description:
          `It has been more than ${pendingLimitDescription} since the last configuration was submitted on ${createdTimestamp}. ` +
          'Please verify meter connectivity.',
      }

    default:
      return undefined
  }
}

const hybridFields = [
  'voltageScalingFactor',
  'voltageScalingFactors',
  'phaseCorrectionAngles',
  'dataResolutionInSeconds',
  'createdTs',
  'appliedTs',
  'createdTimestamp',
  'appliedTimestamp',
  'dataResolution',
  'formattedVersion',
  'vtapL1PhaseA',
  'vtapL2PhaseB',
  'vtapL3PhaseC',
]

const makeHybridConfig = (
  pendingConfig: FTMeterConfig | null | undefined,
  currentConfig: FTMeterConfig | null | undefined,
) => {
  if (!currentConfig) {
    return {
      circuitConfigurations: [],
    }
  }

  const { circuitConfigurations: currentCircuits } = currentConfig
  const stepDownTransformer = makeHybridField(
    'stepDownTransformer',
    pendingConfig,
    currentConfig,
    true,
  )
  const topLevelConfig = hybridFields.reduce(
    (accumulator, field) => ({
      ...accumulator,
      [field]: makeHybridField(field, pendingConfig, currentConfig),
    }),
    {
      stepDownTransformer,
    },
  )
  // $FlowFixMe Address as part of RDP-5266
  const circuitConfigurations = currentCircuits.map((circuit, i) => {
    const pendingCircuit =
      pendingConfig ? pendingConfig.circuitConfigurations[i] : undefined

    if (!pendingCircuit) {
      return {
        ...circuit,
        ctType: makeHybridField('ctType', undefined, circuit),
        ctTypeAmps: makeHybridField('ctTypeAmps', undefined, circuit),
        inUse: makeHybridField('inUse', undefined, circuit),
        phase: makeHybridField('phase', undefined, circuit),
      }
    }

    return {
      ...circuit,
      ctType: makeHybridField('ctType', pendingCircuit, circuit),
      ctTypeAmps: makeHybridField('ctTypeAmps', pendingCircuit, circuit),
      inUse: makeHybridField('inUse', pendingCircuit, circuit),
      phase: makeHybridField('phase', pendingCircuit, circuit),
    }
  })
  const circuitConfigurationsById = circuitConfigurations.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current.circuitId]: current,
    }),
    {},
  )
  return {
    ...topLevelConfig,
    circuitConfigurations,
    circuitConfigurationsById,
  }
}

export const enhanceMeterConfig = (
  config: FTMeterConfigResponse | null | undefined,
  validCtTypes: Array<number> = [],
  validScalingFactorsById: Record<string, any>,
) => {
  if (!config) {
    return null
  }

  const {
    dataResolutionInSeconds: seconds,
    createdTs,
    appliedTs,
    version,
    voltageScalingFactor: voltageScalingFactorRaw,
  } = config
  const voltageScalingFactor =
    voltageScalingFactorRaw ?
      formatNumberToDecimals(voltageScalingFactorRaw, 3)
    : '1.000'
  const dataResolution = seconds === 60 ? '1 minute' : `${seconds} seconds`
  const stepDownTransformer =
    validScalingFactorsById[voltageScalingFactor] || 'OTHER'
  const circuitConfigurations =
    config.circuitConfigurations.map<FTBaseChannelConfig>((circuitConfig) => {
      const ctType = circuitConfig.ctType || validCtTypes[0]
      return {
        inUse: false,
        breakerNumber: '',
        flippedCTLabel: flippedCTStatusLabels.NOT_FLIPPED_CT,
        flippedCTStatusCurrent: 'NOT_FLIPPED_CT',
        flippedCTManuallySet: false,
        flippedCTStatusDefault: 'NOT_FLIPPED_CT',
        ...circuitConfig,
        ctType,
        // $FlowFixMe Address as part of RDP-5266
        ctTypeAmps: circuitUtils.formatCTType(ctType),
        phase: circuitConfig.phase || 'Phase 1',
      }
    })
  return {
    ...config,
    circuitConfigurations,
    dataResolution,
    createdTimestamp: renderTimestamp(createdTs, ''),
    appliedTimestamp: renderTimestamp(appliedTs, ''),
    formattedVersion: `v.${version}`,
    stepDownTransformer,
  }
}
export const utils = {
  enhanceMeterConfig,
  getMeterConfigNotice,
  makeHybridConfig,
}
export const channelListToMap = (channels: Array<FTChannelConfig>) =>
  channels.reduce(
    (accumulator, { circuitId, ...rest }) => ({
      ...accumulator,
      [circuitId]: { ...rest, circuitId },
    }),
    {},
  )
export const channelMapFlatten = (
  channelMap: Record<string, FTChannelConfig>,
) =>
  Object.entries(channelMap).map<FTChannelConfigUpdate>(
    ([
      circuitId,
      { breakerNumber, ctType, inUse, flippedCTStatusCurrent, panelId, phase },
    ]: any) => ({
      breakerNumber,
      circuitId,
      ctType,
      inUse,
      flippedCTStatusCurrent,
      panelId,
      phase,
    }),
  )
export const channelMapToCircuitConfigurations = (
  channelMap: Record<string, FTChannelConfig>,
) =>
  Object.entries(channelMap).map<FTChannelConfigUpdate>(
    ([circuitId, { ctType, phase }]: any) => ({
      circuitId,
      ctType: `${ctType}`,
      phase,
    }),
  )
export const channelMapToCircuitsMeta = (
  channelMap: Record<string, FTChannelConfig>,
) =>
  Object.values(channelMap).map<any>(
    ({
      breakerNumber,
      circuitId,
      flippedCTStatusCurrent,
      inUse = false,
      panelId,
    }: any) => ({
      breakerNumber,
      id: circuitId,
      inUse,
      panelId,
      flippedCT: {
        status: flippedCTStatusCurrent,
      },
    }),
  )
export const getRenderPendingNotice = (
  meterOnline: boolean,
  isNebula: boolean,
  isOrion: boolean,
) => {
  if (!meterOnline) {
    return Modals.renderPendingOfflineNotice
  }

  if (isNebula || isOrion) {
    return Modals.renderNebulaPendingNotice
  }

  return Modals.renderPendingNotice
}
export const phaseLabels = new Map<string, string>([
  ['Phase 1', 'A'],
  ['Phase 2', 'B'],
  ['Phase 3', 'C'],
])
export default meterConfig
