import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import Spinner from '../../../components/Spinner'
import { alwaysLockedColumns, defaultSort, TabUrlEnum } from '../helpers/utils'
import {
  ConfirmationScreen,
  ConfirmationTitleStyled,
  LoadingSpinnerWrapperStyled,
  SubmissionPageOuter,
  TableWrapperStyled,
} from '../styles'
import HeaderDownloadButtons from './components/HeaderButtons'
import RenderActionPaneView from './components/RenderActionPaneView'
import RenderMonthPicker from './components/RenderMonthPicker'
import getDataByTabs from './helpers/getDataByTabs'
import getTableColumns from './helpers/getTableColumns'
import tableDataMapper from './mapper/tableDataMapper'
import {
  useEnergyStarCommonData,
  useSubmissionsDataQuery,
} from '../../../queries/energyStar'

const SubmitSubmissions = () => {
  const { activeSubmissionTab, isActiveMonthValid, activeYearMonth } =
    useEnergyStarCommonData()
  const { search } = useLocation()
  const history = useHistory()

  const submissionsData = useSubmissionsDataQuery()

  const tableData = useMemo(
    () =>
      tableDataMapper(
        getDataByTabs(submissionsData.data ?? [])?.[activeSubmissionTab].items,
      ),
    [activeSubmissionTab, submissionsData.data],
  )

  useEffect(() => {
    if (!activeYearMonth || !isActiveMonthValid) {
      const month = activeYearMonth
      const tempQueryParams = new URLSearchParams(search)
      tempQueryParams.set('activeMonth', month)
      history.replace({ search: tempQueryParams.toString() })
    }
  }, [activeYearMonth, history, isActiveMonthValid, search])

  const isSubmissionInvalid = (original) =>
    !(
      original.dataAvailability &&
      original.dataAvailability >= 0.99 &&
      original.currentMonthEnergyConsumption &&
      original.currentMonthEnergyConsumption >= 0
    )

  const customCheckboxProps = ({ original }) => {
    if (isSubmissionInvalid(original)) {
      return {
        disabled: true,
      }
    }
    return {}
  }
  const customSelectAllCheckboxProps = ({ rows }) => {
    const isAnyRowSelected = rows.some((row) => row.isSelected)
    const areAllRowsSelected = rows.every(
      (row) => isSubmissionInvalid(row.original) || row.isSelected,
    )
    const allRowsAreDisabled = rows.every((row) =>
      isSubmissionInvalid(row.original),
    )

    const areSomeRowsSelected = isAnyRowSelected && !areAllRowsSelected

    return {
      onChange: () => {
        rows.forEach((row) => {
          if (!isSubmissionInvalid(row.original) && row.toggleRowSelected) {
            if (areSomeRowsSelected) {
              row.toggleRowSelected(true)
            } else {
              row.toggleRowSelected()
            }
          }
        })
      },
      checked: areAllRowsSelected && !allRowsAreDisabled,
    }
  }

  if (submissionsData?.isFetching) {
    return (
      <LoadingSpinnerWrapperStyled>
        <Spinner size='medium' />
      </LoadingSpinnerWrapperStyled>
    )
  }

  return (
    <SubmissionPageOuter suppressLoading={submissionsData?.isFetching}>
      <RenderActionPaneView />
      {tableData.length ?
        <TableWrapperStyled suppressLoading={submissionsData?.isFetching}>
          <RedaptiveReactTable7
            columns={getTableColumns(activeSubmissionTab)}
            HeaderActions={HeaderDownloadButtons}
            data={tableData}
            defaultSort={defaultSort}
            alwaysLockedColumns={alwaysLockedColumns}
            enableColumnHiding
            enablePagination
            filterable
            enableRowSelection={
              activeSubmissionTab === TabUrlEnum.READY_FOR_REVIEW
            }
            globalFilterable={false}
            fixedHeader
            customCheckboxProps={customCheckboxProps}
            customSelectAllCheckboxProps={customSelectAllCheckboxProps}
          />
        </TableWrapperStyled>
      : <>
          <RenderMonthPicker />
          <ConfirmationScreen>
            <ConfirmationTitleStyled>No Submissions</ConfirmationTitleStyled>
            <h2>Please submit customer meter data for Energy Star Rating.</h2>
          </ConfirmationScreen>
        </>
      }
    </SubmissionPageOuter>
  )
}

export default SubmitSubmissions
