import { withRouter } from 'react-router-dom'

import UpdateForm from './UpdateForm'
import InfoSection from '../InfoSection'

const MeterInstallLocationAndPowerSource = ({
  location,
  meter,
  loading,
  handleUpdate,
  history,
  match: { url },
}) => {
  const { pathname } = location
  const parsedData = {
    installLocation: meter.installLocation,
    meterPowerSource: meter.meterPowerSource,
    vtapL1PhaseA: meter.powerSource?.vtapL1PhaseA,
  }

  const goBackFromForm = () => {
    history.push(url)
  }

  const updateAndGoBack = (body) => {
    handleUpdate(body)
    goBackFromForm()
  }

  if (pathname.endsWith('/install-location/edit')) {
    return (
      <UpdateForm
        loading={loading}
        formData={parsedData}
        cancelHandler={goBackFromForm}
        handleUpdate={updateAndGoBack}
      />
    )
  }

  const fields = [
    {
      label: 'Install Location',
      value: parsedData.installLocation,
    },
    {
      label: 'Power Source',
      value: parsedData.meterPowerSource,
    },
    {
      label: 'Voltage Tap Phase A',
      value: parsedData.vtapL1PhaseA,
    },
  ]
  const info = [
    {
      header: null,
      values: fields,
    },
  ]
  return <InfoSection info={info} />
}

export default withRouter(MeterInstallLocationAndPowerSource)
