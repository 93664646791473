type iconProps = {
  fill: string
}
export default ({ fill }: iconProps) => (
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.4651 0.5C4.95206 0.5 0.465332 4.98673 0.465332 10.4998C0.465332 16.0128 4.95206 20.4995 10.4651 20.4995C15.9781 20.4995 20.4648 16.0128 20.4648 10.4998C20.4648 4.98673 15.9781 0.5 10.4651 0.5ZM10.4651 18.9206C5.82046 18.9206 2.04424 15.1417 2.04424 10.4998C2.04424 5.85776 5.82046 2.07891 10.4651 2.07891C15.1097 2.07891 18.8859 5.85776 18.8859 10.4998C18.8859 15.1417 15.1097 18.9206 10.4651 18.9206Z'
      fill={fill}
    />
    <path
      d='M14.6709 7.28418L9.46838 12.013L7.63159 9.97096C7.33949 9.64729 6.8395 9.62097 6.51582 9.91307C6.19215 10.2052 6.16583 10.7025 6.45793 11.0288L8.82629 13.6603C8.96576 13.8156 9.16313 13.9103 9.37365 13.9209C9.38681 13.9209 9.39996 13.9209 9.41312 13.9209C9.60785 13.9209 9.79732 13.8472 9.94469 13.7156L15.734 8.45258C16.0577 8.16048 16.0814 7.66049 15.7867 7.33682C15.4946 7.01577 14.9946 6.99209 14.6709 7.28418Z'
      fill={fill}
    />
  </svg>
)
