import { useCallback, useEffect, useState } from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Button from './Button'
import ErrorMessage from './ErrorMessage'
import ArrowUpwardIcon from './Icons/ArrowUpwardIcon'
import Modal from './Modal'
import Spinner from './Spinner'
import {
  actions as customerActions,
  FTState,
  selectCustomerPresignUrl,
  selectCustomerUpdateState,
  selectCustomLogoPresignUrlMeta,
  selectCustomLogoUploadMeta,
} from '../ducks/customers'

const Styles = styled.div`
  .Modal .box {
    width: 500px;
    padding: 16px;
  }
`

const Uploader = styled.div`
  padding: 20px;
  background-color: #f6f6f6;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  max-width: 816px;

  ul > li a {
    &:hover {
      text-decoration: underline;
    }
  }
`
const UploadTitle = styled.b`
  margin: 0;
  font-weight: 600;
`
const ValidationList = styled.ul`
  line-height: 2;
  margin-bottom: 26px;
`
const DropZone = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  outline: none;
  padding: 40px;
  transition: border 0.24s ease-in-out 0s;
`
const ErrorStyle = css`
  color: #ff0000;
`
const ErrorMessageStyles = styled(ErrorMessage)`
  ${ErrorStyle}
`
const ErrorText = styled.p`
  ${ErrorStyle}
`

const ArrowIconStyle = styled(ArrowUpwardIcon)`
  align-items: center;
  border: 2px solid #4a4a4a;
  border-radius: 6px;
  display: flex;
  padding: 6px;
`

const DragFileText = styled.p`
  margin-bottom: 24px;
`

const Buttons = styled.div`
  margin-top: 24px;
  & > :first-child {
    margin-right: 30px;
  }
`

const getDragColor = ({
  isDragAccept,
  isDragReject,
  isDragActive,
}: {
  isDragAccept: boolean
  isDragReject: boolean
  isDragActive: boolean
}) => {
  if (isDragAccept) {
    return '#00e676'
  }

  if (isDragReject) {
    return '#ff1744'
  }

  if (isDragActive) {
    return '#2196f3'
  }

  return '#ccc'
}

const ModalUploadCustomerLogo = ({ customerId }: { customerId: string }) => {
  const dispatch = useDispatch()

  const { loading: updateCustomerLoading, error: updateCustomerError } =
    useSelector((state: FTState) => selectCustomerUpdateState(state))
  const { url } = useSelector((state: FTState) =>
    selectCustomerPresignUrl(state),
  )
  const { loading: presignUrlLoading, error: presignUrlError } = useSelector(
    (state: FTState) => selectCustomLogoPresignUrlMeta(state),
  )
  const { loading: customLogoUploadLoading, error: customLogoUploadError } =
    useSelector((state: FTState) => selectCustomLogoUploadMeta(state))
  const [errorMessage, setErrorMessage] = useState('')
  const [uploading, setUploading] = useState(false)

  const ImageValidator = (file: File): Promise<FileError[] | null> =>
    new Promise((resolve) => {
      if (file.type !== 'image/png') {
        resolve([
          {
            code: 'file-invalid-type',
            message: 'The file must be a .png.',
          },
        ])
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        const image = new Image()
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.width = image.width
          canvas.height = image.height

          context?.drawImage(image, 0, 0, image.width, image.height)

          const imageData = context?.getImageData(
            0,
            0,
            canvas.width,
            canvas.height,
          )
          let isTransparent = false

          if (imageData) {
            for (let i = 0; i < imageData.data.length; i += 4) {
              if (imageData.data[i + 3] < 255) {
                isTransparent = true
                break
              }
            }
          }

          if (!isTransparent) {
            resolve([
              {
                code: 'image-no-transparency',
                message: 'The image must be transparent.',
              },
            ])
          } else {
            resolve(null)
          }
        }
        image.src = e.target.result as string
      }
      reader.readAsDataURL(file)
    })

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])

  const handleFilesValidation = useCallback(async (files: File[]) => {
    const newAcceptedFiles: File[] = []
    const newFileRejections: FileRejection[] = []

    const errors = await ImageValidator(files[0])
    if (errors) {
      newFileRejections.push({
        file: files[0],
        errors: Array.isArray(errors) ? errors : [errors],
      })
    } else {
      newAcceptedFiles.push(files[0])
    }

    setAcceptedFiles(newAcceptedFiles)
    setFileRejections(newFileRejections)
  }, [])

  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    accept: 'image/png',
    maxSize: 8 * 1024 * 1024, // 8 MB
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onDrop: (newAcceptedFiles) => {
      handleFilesValidation(newAcceptedFiles)
    },
  })

  const fileRejectionError = fileRejections[0]?.errors[0]?.message

  const dropzoneClass = getDragColor({
    isDragActive,
    isDragAccept,
    isDragReject,
  })

  const handleUpload = () => {
    setErrorMessage('')
    setUploading(true)
  }

  const handleCancel = () => {
    setErrorMessage('')
    setAcceptedFiles([])
    setUploading(false)
    dispatch(customerActions.clearCustomLogo())
  }

  useEffect(() => {
    if (uploading) {
      // generate presigned url
      dispatch(
        customerActions.getCustomLogoPresignedUrl(
          customerId,
          acceptedFiles[0]?.name,
        ),
      )
    }
  }, [customerId, acceptedFiles, uploading, dispatch])

  useEffect(() => {
    if (!presignUrlLoading && !presignUrlError && acceptedFiles[0] && url) {
      // upload file to s3
      dispatch(customerActions.uploadCustomLogo(acceptedFiles[0]))
    }
  }, [dispatch, presignUrlLoading, presignUrlError, url])

  return (
    <Styles>
      <Modal
        title='Upload Customer Logo'
        renderBody={() => (
          <Uploader>
            <UploadTitle>Upload Custom Logo</UploadTitle>
            <ValidationList>
              <li>The uploaded file must be a .png.</li>
              <li>
                Images should be 648 pixels wide by 140 pixels high, otherwise
                they will be resized.
              </li>
              <li>
                Images should have a transparent background. You can use{' '}
                <a
                  href='https://www.remove.bg/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  https://www.remove.bg/
                </a>{' '}
                or another image editor to do this.
              </li>
              <li>
                DO NOT CLOSE THIS WINDOW while upload is in progress, otherwise
                you will lose access to the upload status.
              </li>
            </ValidationList>
            <DropZone
              {...getRootProps({
                isDragActive,
                isDragAccept,
                isDragReject,
              })}
              style={{
                borderColor: dropzoneClass,
              }}
            >
              <input name='file' {...getInputProps()} />
              {(presignUrlLoading ||
                customLogoUploadLoading ||
                updateCustomerLoading) && (
                <div>
                  <Spinner inline size='micro' /> <b>Upload in progress...</b>
                </div>
              )}
              {(presignUrlError ||
                customLogoUploadError ||
                updateCustomerError) && (
                <ErrorMessageStyles
                  message={
                    presignUrlError ||
                    customLogoUploadError ||
                    updateCustomerError
                  }
                />
              )}
              <ArrowIconStyle />
              {fileRejectionError && (
                <ErrorText>{fileRejectionError}</ErrorText>
              )}
              {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
              {acceptedFiles?.length > 0 && (
                <div>
                  <b>File:</b> {acceptedFiles[0].name}
                </div>
              )}
              {!acceptedFiles[0]?.name && (
                <DragFileText>Drag file to upload</DragFileText>
              )}
              {!acceptedFiles[0]?.name && (
                <Button primary onClick={open}>
                  Choose File
                </Button>
              )}
              {!!acceptedFiles[0]?.name && (
                <Buttons>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    disabled={uploading || fileRejectionError || errorMessage}
                    primary
                    onClick={handleUpload}
                  >
                    Upload
                  </Button>
                </Buttons>
              )}
            </DropZone>
          </Uploader>
        )}
      />
    </Styles>
  )
}
export default ModalUploadCustomerLogo
