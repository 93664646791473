// @flow

import { withFormik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import DeprecatedEntityListPane from '../../components/DeprecatedEntityListPane'
import IonIcon from '../../components/IonIcon'
import MeterAssignmentForm from '../../components/MeterAssignmentForm'
import Stepper from '../../components/Stepper'
import Link from '../../components/StyledLink'
import {
  actions as customerActions,
  selectCustomerListEntity,
} from '../../ducks/customers'
import { actions as siteActions, selectSiteListEntity } from '../../ducks/sites'
import {
  actions as unassignedActions,
  selectUnassignedListEntity,
} from '../../ducks/unassignedMeters'
import { FTRouterLocation } from '../../types'

type FTProps = FTRouterLocation

type FTState = {
  meterIds: Array<string>
  siteId: string
  customerId: string
  opportunityId: string
}

const StyledButton = styled(Button)`
  margin-top: 80px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
`

const SuccessMessage = styled.div`
  margin-top: 36px;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  & .IonIcon {
    margin-right: 25px;
    color: #7bcc76;
    font-size: 20px;
  }

  & .IonIcon > span {
    top: 4px;
  }

  a {
    display: inline;
  }
`

const emptyEntity = {}

const initialValues = {
  selected: {},
  siteId: '',
  customerId: '',
}

const FormikMeterAssignmentForm = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: () => initialValues,
  handleSubmit({ selected, siteId, customerId }, { props, setSubmitting }) {
    const { step, submitSelectMeters, submitSiteId, submitAssignments } = props
    if (step === 1) {
      const meterIds = Object.entries(selected)
        .filter(([, set]) => set)
        .map(([id]) => id)
      submitSelectMeters(meterIds)
      setSubmitting(false)
    } else if (step === 2) {
      submitSiteId(siteId, customerId)
      setSubmitting(false)
    } else if (step === 3) {
      submitAssignments()
    }
  },
})(MeterAssignmentForm)

// eslint-disable-next-line
class MeterAssignmentPage extends React.PureComponent<FTProps, FTState> {
  unassignedMetersProps = {
    orderBy: { field: 'firstReportDate', sort: 'ASC' },
    pageSize: 10 ** 4,
  }

  constructor(props) {
    super(props)
    this.state = {
      meterIds: [],
      siteId: null,
      customerId: null,
      opportunityId: null,
    }
  }

  componentDidMount() {
    const { actions } = this.props
    actions.fetchUnassignedMeterList(this.unassignedMetersProps)
    actions.fetchAllCustomers({
      pageSize: 10 ** 4,
      orderBy: { field: 'name', sort: 'ASC' },
    })
    actions.clearSites()
  }

  componentDidUpdate({ success: prevSuccess, error: prevError }) {
    const { meterIds, siteId } = this.state
    const { success, history, error } = this.props
    const step = this.parseStep()
    if (meterIds.length < 1 && step !== 1) {
      history.push('/account-management/meter-assignment/1')
      return
    }

    if (!siteId && step > 2) {
      history.push('/account-management/meter-assignment/2')
      return
    }

    if (!prevSuccess && success === true) {
      history.push('/account-management/meter-assignment/4')
      return
    }

    if (step < 4 && success === true) {
      history.push('/account-management/meter-assignment/4')
    }

    if (!prevError && error) {
      window.scrollTo(0, 0)
    }
  }

  componentWillUnmount() {
    this.props.actions.clearMeterAssignmentForm()
  }

  setStep = (step) => {
    const { history } = this.props
    history.push(`/account-management/meter-assignment/${step}`)
  }

  fetchSites = (customerId) => {
    this.props.actions.fetchAllSites({
      customerId,
      pageSize: 10 ** 4,
      orderBy: { field: 'name', sort: 'ASC' },
    })
  }

  submitSelectMeters = (meterIds) => {
    this.setState(
      (prev) => ({
        ...prev,
        meterIds,
      }),
      () => {
        const { history } = this.props
        history.push('/account-management/meter-assignment/2')
      },
    )
  }

  submitSiteId = (siteId, customerId) => {
    this.setState(
      (prev) => ({
        ...prev,
        siteId,
        customerId,
      }),
      () => {
        const { history } = this.props
        history.push('/account-management/meter-assignment/3')
      },
    )
  }

  submitAssignments = () => {
    const { history, actions } = this.props
    const { meterIds, siteId, opportunityId } = this.state
    if (meterIds.length < 1) {
      history.push('/account-management/meter-assignment/1')
      return
    }

    if (!siteId) {
      history.push('/account-management/meter-assignment/2')
      return
    }

    actions.submitMeterAssignments({ meterIds, siteId, opportunityId })
  }

  updateMeterState = (id, checked) => {
    if (checked) {
      this.setState((prev) => ({
        ...prev,
        meterIds: [...prev.meterIds, id],
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        meterIds: prev.meterIds.filter((item) => item !== id),
      }))
    }
  }

  updateFormState = (data) => {
    this.setState((prev) => ({ ...prev, ...data }))
  }

  parseStep() {
    const {
      match: {
        params: { step },
      },
    } = this.props

    return Number.parseInt(step, 10) || 1
  }

  renderBreadcrumbs = () => {
    const items = [
      { href: '/account-management/unassigned', text: 'Accounts' },
      {
        href: '/account-management/meter-assignment',
        text: 'Unassigned Meters',
      },
    ]

    return <Breadcrumbs items={items} />
  }

  renderSelectedMetersTable = () => {
    const { unassignedListEntity, actions } = this.props
    const { items } = unassignedListEntity
    const { meterIds } = this.state

    const filtered = items.filter(({ id }) => meterIds.includes(id))

    const entity = {
      ...unassignedListEntity,
      items: filtered,
    }

    return (
      <div>
        <DeprecatedEntityListPane
          striped
          description=''
          showPageSizer={false}
          entity={entity}
          loadEntity={actions.fetchUnassignedMeterList}
          loadEntityProps={this.unassignedMetersProps}
          tableHeaders={[
            { fieldName: 'name', displayName: 'Meter Identifier' },
            { fieldName: 'source', displayName: 'Meter Type' },
            { fieldName: 'firstReportDate', displayName: 'First Reported' },
          ]}
          renderTableRow={({ id, name, meterType, firstReported }) => (
            <tr key={id}>
              <td>
                <div>
                  <Link
                    to={`/account-management/meters/${id}`}
                    href={`/account-management/meters/${id}`}
                  >
                    {name}
                  </Link>
                </div>
              </td>
              <td>{meterType}</td>
              <td>{firstReported}</td>
            </tr>
          )}
        />
      </div>
    )
  }

  render() {
    const {
      unassignedListEntity,
      customerListEntity: { items: customers },
      siteListEntity: { items: sites },
      history,
      actions,
      error,
    } = this.props

    const { siteId, customerId, meterIds, opportunityId } = this.state
    const selectedSite =
      siteId ? sites.find(({ id }) => id === siteId) : emptyEntity
    const selectedCustomer =
      customerId ? customers.find(({ id }) => id === customerId) : emptyEntity

    const loadAction = actions.fetchUnassignedMeterList
    let linkToSite = ''
    let finalSiteName = ''
    let linkToCustomer = ''
    let finalCustomerName = ''
    if (selectedCustomer && selectedSite) {
      linkToCustomer = `/account-management/customers/${selectedCustomer.id}`
      finalCustomerName = `${selectedCustomer.validName}, `
      linkToSite = `/account-management/customers/${selectedCustomer.id}/sites/${selectedSite.id}/meters`
      finalSiteName = `${selectedSite.validName}`
    }

    const step = this.parseStep()

    const steps = [
      {
        label: 'Choose Meters',
        instructions:
          'Choose the meters that you would like to assign to a site and opportunity:',
        canClick: step > 1 || meterIds.length > 0,
      },
      {
        label: 'Choose Site',
        instructions:
          'Choose the customer, site, and opportunity that the meters belong to:',
        canClick: step > 2 || meterIds.length > 0 || !!siteId,
      },
      {
        label: 'Verify and assign',
        instructions:
          'Verify and assign the selected customer, site, opportunity, and meters:',
        canClick: meterIds.length > 0 && !!siteId,
      },
    ]

    return (
      <div>
        {this.renderBreadcrumbs()}
        <Title>Meter Assignment Tool</Title>
        {step !== 4 && (
          <Stepper steps={steps} currentStep={step} setStep={this.setStep} />
        )}

        {step !== 4 && (
          <FormikMeterAssignmentForm
            unassignedListEntity={unassignedListEntity}
            loadAction={loadAction}
            loadActionProps={this.unassignedMetersProps}
            onSubmit={this.submitSelectMeters}
            submitSelectMeters={this.submitSelectMeters}
            submitSiteId={this.submitSiteId}
            submitAssignments={this.submitAssignments}
            selectedSite={selectedSite}
            selectedCustomer={selectedCustomer}
            selectedOpportunityId={opportunityId}
            step={step}
            renderSelectedMeters={this.renderSelectedMetersTable}
            customers={customers}
            sites={sites}
            fetchSites={this.fetchSites}
            history={history}
            updateState={this.updateFormState}
            updateMeterState={this.updateMeterState}
            error={error}
          />
        )}
        {step === 4 && (
          <div>
            <SuccessMessage>
              <IonIcon iconClass='ion-checkmark-circled' />
              {' Success! The meters listed below have been assigned to: '}
              <Link to={linkToCustomer} href={linkToCustomer}>
                {finalCustomerName}
              </Link>
              <Link to={linkToSite} href={linkToSite}>
                {finalSiteName}
              </Link>
            </SuccessMessage>
            {this.renderSelectedMetersTable()}
            <StyledButton
              primary
              onClick={() => history.push('/account-management/unassigned')}
            >
              Done
            </StyledButton>
          </div>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(unassignedActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
  },
})

const mapStateToProps = (state) => ({
  unassignedListEntity: selectUnassignedListEntity(state),
  customerListEntity: selectCustomerListEntity(state),
  siteListEntity: selectSiteListEntity(state),
  success: state.entities.unassignedMeters.form.success,
  error: state.entities.unassignedMeters.form.error,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeterAssignmentPage),
)
