import { FastField, Field } from 'formik'
import { Component, ComponentType } from 'react'
import styled from 'styled-components'

import AutoFillOptions from './AutoFillOptions'
import ChannelsTable from './ChannelsTable'
import '../../ducks/meters/config'
import EditBlock from './EditBlock'
import { breakerNumbers, flippedCTStatusLabels } from '../../ducks/circuits'
import type { FTChannelConfig } from '../../ducks/meters/config'
import { fieldNameMap, nonNeutalPanels } from '../../ducks/meters/generation'
import type { FTConfirmModal } from '../../ducks/modal'
import type { FTPanel, FTPanelListEntityMetaState } from '../../ducks/panels'
import { isRedaptiveMeter } from '../../ducks/sites'
import type { FTFormFieldEvent, FTWithFormik } from '../../types'
import {
  getValueFromEvent,
  makeListSelectorItem,
  naturallySortItems,
} from '../../utils'
import Checkbox from '../Checkbox'
import ErrorMessage from '../ErrorMessage'
import FormButtons from '../FormButtons'
import InlineInput, {
  InlineInputLabelSectionStyled,
  InlineInputSectionStyled,
} from '../InlineInput'
import { InputComponent, InputErrorMessageStyles, Marker } from '../Input'
import ListSelector from '../ListSelector'
import type { FTItem, FTOnChangePayload } from '../ListSelector'
import PanelSelectorItem from '../PanelSelectorItem'
import StyledRadioButton from '../RadioButton'
import { SortableFieldStyles } from '../Sortable'
import { TableHeaderCellStyles } from '../Table'
import TabContent from '../Tabs/TabContent'
import UnsavedChanges from '../UnsavedChanges'

const StyledErrorMessage = styled(ErrorMessage)`
  font-weight: 600;
`
const StyledListSelector = styled(ListSelector)`
  width: 80px;
  height: 36px;
`
const StyledVoltageTapSelector = styled(StyledListSelector)`
  width: 110px;
`
const StyledStepDownSelector = styled(StyledListSelector)`
  width: 320px;
`
const StyledChannelPanelSelector = styled(StyledListSelector)`
  width: 163px;

  .Select__control .Select__value-container {
    padding-right: 18px;
  }
`
const StyledCTTypeSelector = styled(StyledListSelector)`
  width: 100px;
`
const StyledBreakerNumberSelector = styled(StyledListSelector)`
  width: 65px;
`
const StyledPhaseSelector = styled(StyledListSelector)`
  width: 50px;
`
const StyledFlippedCTSelector = styled(StyledListSelector)`
  width: 220px;
`
const StyledPanelSelector = styled(StyledListSelector)`
  * {
    box-sizing: border-box;
  }

  width: 500px;

  .Select__input,
  .Select__multi-value-wrapper {
    height: 100%;
  }

  .Select__option,
  .Select__value-container {
    align-items: center;
    display: flex;
  }

  .Select__placeholder {
    width: 100%;
  }

  .Select__menu-outer {
    top: auto;
  }
`
const StyledPanelDetails = styled.div`
  color: #7f7f7f;
  font-size: 12px;
  line-height: 1.7;
`
const StyledPanelTitle = styled.div`
  color: #4a4a4a;
  font-weight: 600;
  line-height: 1.7;
`
const StyledTableCell = styled.td`
  .Table & {
    padding: 5px;
    vertical-align: middle;
  }
`
const StyledDeletePanelModalBody = styled.p`
  padding-bottom: 16px;
  text-align: center;
`
const StyledUpdatePanelModal = styled.div`
  margin-bottom: -20px;

  li {
    padding-bottom: 10px;
  }

  ul {
    margin: 22px 0 0;
    padding-left: 26px;
  }
`
const ChannelsTableWrapperStyles = styled.div`
  ${SortableFieldStyles} {
    padding-right: 0;
  }

  ${TableHeaderCellStyles} {
    padding-right: 0;

    &:nth-child(6) {
      min-width: 64px;
    }
  }
`
const StyledInstallationLocationSection = styled.div`
  .has-value .ListSelector-Dropdown,
  .has-value .ListSelector-Dropdown--wrapper,
  .has-value .Select__control,
  .has-value .Select__value-container,
  .has-value ${StyledListSelector}, .Select__control--menu-is-open,
  .Select__control--menu-is-open .Select__option,
  ${StyledListSelector} .Select__menu .Select__option {
    height: 60px;
  }

  .Select__placeholder {
    width: 100%;
  }

  .Select__value-container {
    top: -3px;
  }
`
const EditBlockStyled = styled(EditBlock)`
  align-items: center;
  display: flex;
`
const ScalingFactorInlineInputStyled = styled(InlineInput)`
  display: inline-block;
  margin-left: 38px;
  width: 200px;
  ${InputComponent} {
    border: ${({ error }) => (error ? '1px solid red' : '1px solid #c7c7c7')};
  }

  ${InlineInputSectionStyled} {
    margin-left: 0;
  }

  ${InlineInputLabelSectionStyled} {
    display: none;
  }

  ${Marker} {
    position: relative;
  }
  ${InputErrorMessageStyles} {
    left: 0;
    top: 30px;
  }
`
const VoltageTapBreakerInputStyled = styled(InlineInput)`
  align-items: center;
  display: inline-flex;

  ${InlineInputSectionStyled} {
    display: inline-block;
  }

  ${InlineInputLabelSectionStyled} {
    display: inline-block;
  }
`
const VoltageTapColumnHeadersStyled = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  left: 632px;
  margin-bottom: 10px;
`
const VoltageScalingFactorContainerStyled: ComponentType<{
  bottomSpacing?: boolean
}> = styled.div`
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? '30px' : '')};
`
const VoltageTapColumnHeadersBreakerStyled = styled.div``
const VoltageTapColumnHeadersScalingFactorStyled = styled.div`
  margin-left: 42px;
`
const VoltageTapColumnHeadersPhaseAngleStyled = styled.div`
  margin-left: 92px;
`
const PowerSourceSectionTabContentStyled = styled(TabContent)`
  position: relative;
`
const doubleDagger = '\u2021'
const flippedCTStatusItems: Record<string, Array<FTItem>> = Object.keys(
  flippedCTStatusLabels,
).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: Object.keys(flippedCTStatusLabels).map((flippedCTStatusValue) =>
      cur === flippedCTStatusValue ?
        makeListSelectorItem(
          flippedCTStatusValue,
          `${flippedCTStatusLabels[flippedCTStatusValue]} (${doubleDagger})`,
        )
      : makeListSelectorItem(
          flippedCTStatusValue,
          flippedCTStatusLabels[flippedCTStatusValue],
        ),
    ),
  }),
  {},
)
const flippedCTStatusItemsById: Record<
  string,
  Record<string, FTItem>
> = Object.keys(flippedCTStatusLabels).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: flippedCTStatusItems[cur].reduce(
      (accItem, curItem) => ({ ...accItem, [curItem.id]: curItem }),
      {},
    ),
  }),
  {},
)
type FTState = {
  breakerNumberItemsById: Record<string, FTItem>
  ctTypeItemsById: Record<string, FTItem>
  inUseSelectAllChecked1: boolean
  inUseSelectAllChecked2: boolean
  panelItems: Array<FTItem>
  panelItemsById: Record<string, FTItem>
  phaseItemsById: Record<string, FTItem>
  updateValueHandlers: Record<string, any>
}
type FTProps = {
  panels: Array<FTPanel>
  panelsById: Record<string, FTPanel>
  panelsMeta: FTPanelListEntityMetaState
  showConfirmModal: (arg0: FTConfirmModal) => void
} & FTWithFormik
export default class Form extends Component<FTProps, FTState> {
  constructor(props: FTProps) {
    super(props)
    const panelItems = this.getPanelItems()
    this.state = {
      breakerNumberItemsById: this.getBreakerNumberItemsById(),
      ctTypeItemsById: this.getCtTypeItemsById(),
      inUseSelectAllChecked1: false,
      inUseSelectAllChecked2: false,
      panelItems,
      panelItemsById: this.getPanelItemsById(panelItems),
      phaseItemsById: this.getPhaseItemsById(),
      updateValueHandlers: this.getUpdateValueHandlers(),
    }
  }

  componentDidUpdate(prev: FTProps) {
    const {
      error: prevError,
      panels: prevPanels,
      panelsMeta: { addLoading: prevAddPanelLoading },
      status,
      updateLoading: prevUpdateLoading,
      values: { panelId: prevMeterPanelId },
    } = prev
    const {
      error,
      panels,
      panelsById,
      panelsMeta: { addLoading: addPanelLoading },
      setFieldValue,
      setSubmitting,
      updateLoading,
      values,
    } = this.props
    const { channels, panelId: meterPanelId } = values
    const { meter } = status || {}
    const { isOrion = false, isNebula = false } = meter || {}

    if (prevUpdateLoading && !updateLoading && !prevError && error) {
      setSubmitting(false)
      window.scrollTo(0, 0)
      return
    }

    if (prevPanels !== panels) {
      this.setState({
        panelItems: this.getPanelItems(),
      })
    }

    if (prevMeterPanelId !== meterPanelId) {
      const panelItems = this.getPanelItems()
      this.setState({
        panelItems,
        panelItemsById: this.getPanelItemsById(panelItems),
      })
      const newChannels = {}
      Object.keys(channels).forEach((circuitId) => {
        newChannels[circuitId] = {
          ...channels[circuitId],
          panelId: meterPanelId || '',
        }
      })
      setFieldValue('channels', newChannels)

      if (isOrion) {
        const panel = panelsById[meterPanelId] || {}
        const panelPrev = panelsById[prevMeterPanelId] || {}

        if (
          panel.type !== panelPrev.type ||
          panel.voltage !== panelPrev.voltage
        ) {
          if (
            (panel.type === 'THREE_PHASE_3_WIRE_WYE' ||
              'THREE_PHASE_3_WIRE_DELTA') &&
            panel.voltage === '_600V'
          ) {
            setFieldValue('voltageScalingFactorL1', 3.84)
            setFieldValue('voltageScalingFactorL2', 3.93)
          } else {
            setFieldValue('voltageScalingFactorL1', 1.7321)
            setFieldValue('voltageScalingFactorL2', 1.7321)
          }

          setFieldValue('voltageScalingFactorL3', 1.0)
          setFieldValue('panel', panel)
        }
      }

      if (isNebula) {
        const panel = panelsById[meterPanelId] || {}
        const panelPrev = panelsById[prevMeterPanelId] || {}
        const isNoNeutralPanel = nonNeutalPanels.includes(panel.type)

        if (
          panel.type !== panelPrev.type ||
          panel.voltage !== panelPrev.voltage
        ) {
          if (isNoNeutralPanel) {
            // no netural panels
            if (
              (panel.type === 'THREE_PHASE_3_WIRE_WYE' ||
                'THREE_PHASE_3_WIRE_DELTA') &&
              panel.voltage === '_600V'
            ) {
              setFieldValue('voltageScalingFactorL1', 3.84)
              setFieldValue('voltageScalingFactorL2', 3.93)
            } else if (
              panel.type === 'THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA' &&
              panel.voltage !== 'OTHER'
            ) {
              setFieldValue('voltageScalingFactorL1', 1.0)
              setFieldValue('voltageScalingFactorL2', 1.0)
            } else {
              setFieldValue('voltageScalingFactorL1', 1.7321)
              setFieldValue('voltageScalingFactorL2', 1.7321)
            }

            setFieldValue('voltageScalingFactorL3', 1.0)
          } else {
            // prev nebula panels
            setFieldValue('voltageScalingFactorL1', 1.0)
            setFieldValue('voltageScalingFactorL2', 1.0)
            setFieldValue('voltageScalingFactorL3', 1.0)
          }

          setFieldValue('panel', panel)
        }
      }
    }

    if (
      prevAddPanelLoading &&
      !addPanelLoading &&
      !values.panelId &&
      panels.length > prevPanels.length
    ) {
      const newPanelId = panels.filter(
        (panel) => !prevPanels.includes(panel),
      )[0].id
      setFieldValue('panelId', newPanelId)
    }
  }

  getUpdateValueHandlers = () => {
    const {
      initialValues: { channels },
      status: { voltageTapPhases },
    } = this.props
    const updateValueHandlers = {
      channels: {},
      voltageTap: {},
    }
    Object.keys(channels).forEach((circuitId) => {
      updateValueHandlers.channels[circuitId] = {
        breakerNumber: this.onUpdateBreakerNumber(circuitId),
        ctType: this.onUpdateChannel(circuitId, 'ctType'),
        flippedCTStatusCurrent: this.onUpdateChannel(
          circuitId,
          'flippedCTStatusCurrent',
        ),
        inUse: this.onUpdateInUse(circuitId),
        panelId: this.onUpdateChannel(circuitId, 'panelId'),
        phase: this.onUpdateChannel(circuitId, 'phase'),
      }
    })
    voltageTapPhases.forEach((key, index) => {
      const fieldName = `vtapL${index + 1}Phase${key}`
      updateValueHandlers.voltageTap[fieldName] = this.onUpdateField(fieldName)
      const fieldNameScalingFactor = `voltageScalingFactorL${index + 1}`
      updateValueHandlers[fieldNameScalingFactor] = this.onUpdateField(
        fieldNameScalingFactor,
      )
    })
    return updateValueHandlers
  }

  getBreakerNumberItemsById = () => {
    const breakerNumberItemsById = {}
    breakerNumbers.forEach((breakerNumberItem) => {
      breakerNumberItemsById[breakerNumberItem.id] = breakerNumberItem
    })
    return breakerNumberItemsById
  }

  getCtTypeItemsById = () => {
    const {
      status: { ctTypes },
    } = this.props
    const ctTypeItemsById = {}
    ctTypes.forEach((ctTypeItem) => {
      ctTypeItemsById[ctTypeItem.id] = ctTypeItem
    })
    return ctTypeItemsById
  }

  getPanelItems = (): Array<FTItem> => {
    const {
      panels,
      panelsById,
      values: { panelId: meterPanelId },
    } = this.props
    const panelItems = []
    const meterPanel = panelsById[meterPanelId]

    if (meterPanelId && meterPanel) {
      // Populates the select options with panels that have the same voltage
      // and type as the meter panel.
      panels.forEach((panel) => {
        if (
          panel.voltage === meterPanel.voltage &&
          panel.type === meterPanel.type
        ) {
          panelItems.push(makeListSelectorItem(panel.id, panel.name))
        }
      })
    }

    return panelItems.sort(naturallySortItems)
  }

  getPanelItemsById = (panelItems: Array<FTItem>) => {
    const panelItemsById = {}
    panelItems.forEach((panelItem) => {
      panelItemsById[panelItem.id] = panelItem
    })
    return panelItemsById
  }

  getPhaseItemsById = () => {
    const {
      status: { phaseOptions },
    } = this.props
    const phaseItemsById = {}
    phaseOptions.forEach((phaseItem) => {
      phaseItemsById[phaseItem.id] = phaseItem
    })
    return phaseItemsById
  }

  onUpdateField =
    (fieldName: string) => (data: FTFormFieldEvent & FTOnChangePayload) => {
      const { setFieldValue } = this.props
      const { value } = data.target || data
      setFieldValue(fieldName, value)
    }

  onUpdateChannel =
    (id: string, fieldName: string) => (event: FTFormFieldEvent) => {
      const { setFieldValue } = this.props
      setFieldValue(`channels.${id}.${fieldName}`, getValueFromEvent(event))
    }

  onUpdateVoltageScalingFactorCustom = this.onUpdateField(
    'voltageScalingFactorCustom',
  )

  onUpdateBreakerNumber = (id: string) => (event: FTFormFieldEvent) => {
    const { setFieldValue } = this.props
    const value = getValueFromEvent(event)
    setFieldValue(`channels.${id}.breakerNumber`, getValueFromEvent(event))

    if (value !== '') {
      setFieldValue(`channels.${id}.inUse`, true)
    }
  }

  onUpdateMeterPanelId = (event: FTFormFieldEvent) => {
    const { setFieldValue, showConfirmModal, values } = this.props
    const { panelId: panelIdPrevious } = values
    const value = getValueFromEvent(event)

    if (!panelIdPrevious) {
      setFieldValue('panelId', value)
      return
    }
    showConfirmModal({
      modalWidth: '500px',
      onPrimaryAction: undefined,
      onSecondaryAction: () => {
        setFieldValue('panelId', value)
        setFieldValue('highLeg', null)
        setFieldValue('stepDownTransformerName', null)
      },
      primaryActionText: 'No',
      renderContent: () => (
        <StyledUpdatePanelModal>
          <ul>
            <li>All meter channels will be changed to the primary panel</li>
            <li>
              Changes will not take effect until the configuration form is saved
            </li>
          </ul>
        </StyledUpdatePanelModal>
      ),
      secondaryActionText: 'Yes',
      title: 'Are you sure you want to change the primary panel assignment?',
    })
  }

  onUpdateInUse = (id: string) => (event: FTFormFieldEvent) => {
    const { setFieldValue } = this.props
    const value = getValueFromEvent(event)

    if (value === false) {
      const { inUseSelectAllChecked1, inUseSelectAllChecked2 } = this.state
      const inUseSelectAllId =
        parseInt(id, 10) % 2 ?
          'inUseSelectAllChecked2'
        : 'inUseSelectAllChecked1'
      const inUseSelectAllChecked =
        parseInt(id, 10) % 2 ? inUseSelectAllChecked2 : inUseSelectAllChecked1

      if (inUseSelectAllChecked === true) {
        this.setState((prev) => ({ ...prev, [inUseSelectAllId]: false }))
      }
    }

    setFieldValue(`channels.${id}.inUse`, value)
  }

  onUpdatePanelVoltage = ({ value: panelVoltage }: { value: string }) => {
    const { conditionalFields, setValues, values } = this.props
    const { panelType } = values
    const validVoltageScalingFactors =
      conditionalFields.getVoltageScalingFactorsById(panelType, panelVoltage)
    const newVoltageScalingFactor = Object.keys(validVoltageScalingFactors)[0]
    const newVoltageScalingFactorCustom =
      newVoltageScalingFactor === 'OTHER' ? 1.0 : newVoltageScalingFactor
    setValues({
      ...values,
      panelVoltage,
      voltageScalingFactor: newVoltageScalingFactor,
      voltageScalingFactorCustom: newVoltageScalingFactorCustom,
    })
  }

  onUpdateVoltageScalingFactor = ({
    value: voltageScalingFactor,
    label,
  }: {
    value: string
    label: string
  }) => {
    const { setFieldValue } = this.props
    setFieldValue('voltageScalingFactor', voltageScalingFactor)
    setFieldValue('stepDownTransformerName', label)
    if (voltageScalingFactor !== 'OTHER') {
      setFieldValue('voltageScalingFactorCustom', voltageScalingFactor)
    }
  }

  onInUseSelectAllChange1 = ({ target: { checked } }: FTFormFieldEvent) => {
    const { setValues, values } = this.props
    const { channels } = values
    const newChannels = {}
    Object.keys(channels).forEach((channelId, index) => {
      newChannels[channelId] =
        index < 24 ?
          { ...channels[channelId], inUse: checked }
        : { ...channels[channelId] }
    })
    setValues({ ...values, channels: newChannels })
    this.setState((prev) => ({ ...prev, inUseSelectAllChecked1: checked }))
  }

  onInUseSelectAllChange2 = ({ target: { checked } }: FTFormFieldEvent) => {
    const { setValues, values } = this.props
    const { channels } = values
    const newChannels = {}
    Object.keys(channels).forEach((channelId, index) => {
      newChannels[channelId] =
        index > 23 ?
          { ...channels[channelId], inUse: checked }
        : { ...channels[channelId] }
    })
    setValues({ ...values, channels: newChannels })
    this.setState((prev) => ({ ...prev, inUseSelectAllChecked2: checked }))
  }

  getCTTypeOrDefault = (
    channelId: string,
    values: Record<string, any> | null | undefined,
  ) => {
    const {
      status: { ctTypes },
      values: currentValues,
    } = this.props
    const formValues = values || currentValues

    if (
      !channelId ||
      !formValues ||
      !formValues.channels ||
      Object.keys(formValues.channels).length < 1
    ) {
      return ctTypes[0]
    }

    const { channels } = formValues
    const channel = channels[channelId]

    if (!channel) {
      return ctTypes[0]
    }

    const { ctType } = channel

    if (!ctType) {
      return ctTypes[0]
    }

    return this.state.ctTypeItemsById[ctType]
  }

  getInitialChannelValue = (id: string, field: string) => {
    const { initialValues } = this.props
    const initialChannel = initialValues.channels[id]

    if (!initialChannel) {
      return undefined
    }

    return initialChannel[field]
  }

  channelFieldIsEdited = (id: string, field: string) => {
    const {
      values: { channels },
    } = this.props
    const initial = this.getInitialChannelValue(id, field)
    const current = channels[id] ? channels[id][field] : undefined
    return initial !== current
  }

  renderChannelRowBody = (row: FTChannelConfig, index: number) => {
    const {
      initialValues,
      meter: { isNebula, isOrion, siteId },
      panelsById,
      status,
      values: { channels, panelId: MeterPanelId },
    } = this.props
    const { ctTypes, phaseOptions } = status
    const channelsCount = Object.keys(channels).length
    // $FlowFixMe Address as part of RDP-5266
    const {
      breakerNumber,
      circuitId,
      inUse,
      flippedCTStatusCurrent = 'NOT_FLIPPED',
      flippedCTStatusDefault = 'NOT_FLIPPED',
      meterChannel,
      panelId,
      phase,
    } = row
    const meterPanel = panelsById[MeterPanelId]
    const selectedFlippedCTStatusItem =
      isNebula || isOrion ?
        flippedCTStatusItemsById[flippedCTStatusDefault][flippedCTStatusCurrent]
      : null
    const selectedPanelId =
      (MeterPanelId &&
        meterPanel &&
        panelId &&
        panelsById[panelId] &&
        panelsById[panelId].voltage === meterPanel.voltage &&
        panelsById[panelId].type === meterPanel.type &&
        panelsById[panelId].id) ||
      ''
    const panelIdEdited =
      initialValues.channels[circuitId].panelId !== selectedPanelId
    const ctTypeSelected = this.getCTTypeOrDefault(circuitId)
    const ctTypeInitial = this.getCTTypeOrDefault(circuitId, initialValues)
    const ctTypeEdited = ctTypeInitial.id !== ctTypeSelected.id
    const directionUp = index === Math.floor(channelsCount / 2) - 1
    const selectedBreakerNumber =
      breakerNumber ?
        this.state.breakerNumberItemsById[breakerNumber]
      : makeListSelectorItem(breakerNumber)
    return (
      <>
        <StyledTableCell>{meterChannel}</StyledTableCell>
        <StyledTableCell>
          <StyledChannelPanelSelector
            directionUp={directionUp}
            disabled={siteId === '15e7e4ce-44ec-486a-bb1f-cdc6ce533e25'}
            editDotRight='-7px'
            edited={panelIdEdited}
            hideEditDotOnHover={false}
            items={this.state.panelItems}
            notSetItemText='--'
            notSetItemValue=''
            notSetLabelText='--'
            selectedItem={this.state.panelItemsById[selectedPanelId]}
            unsettable
            updateValue={
              this.state.updateValueHandlers.channels[circuitId].panelId
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <StyledBreakerNumberSelector
            items={breakerNumbers}
            unsettable
            notSetItemText='--'
            notSetLabelText='--'
            notSetItemValue=''
            selectedItem={selectedBreakerNumber}
            updateValue={
              this.state.updateValueHandlers.channels[circuitId].breakerNumber
            }
            directionUp={directionUp}
            edited={this.channelFieldIsEdited(circuitId, 'breakerNumber')}
            editDotRight='-7px'
            hideEditDotOnHover={false}
          />
        </StyledTableCell>
        <StyledTableCell>
          <StyledCTTypeSelector
            items={ctTypes}
            selectedItem={ctTypeSelected}
            unsettable={false}
            updateValue={
              this.state.updateValueHandlers.channels[circuitId].ctType
            }
            directionUp={directionUp}
            edited={ctTypeEdited}
            editDotRight='-7px'
            hideEditDotOnHover={false}
          />
        </StyledTableCell>
        <StyledTableCell>
          <StyledPhaseSelector
            items={phaseOptions}
            selectedItem={this.state.phaseItemsById[phase]}
            unsettable={false}
            updateValue={
              this.state.updateValueHandlers.channels[circuitId].phase
            }
            directionUp={directionUp}
            edited={this.channelFieldIsEdited(circuitId, 'phase')}
            editDotRight='-7px'
            hideEditDotOnHover={false}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Checkbox
            name='inUse'
            value={inUse}
            checked={inUse}
            onChange={this.state.updateValueHandlers.channels[circuitId].inUse}
            edited={this.channelFieldIsEdited(circuitId, 'inUse')}
          />
        </StyledTableCell>
        {isNebula && (
          <StyledTableCell>
            <StyledFlippedCTSelector
              directionUp={directionUp}
              editDotRight='-7px'
              edited={this.channelFieldIsEdited(
                circuitId,
                'flippedCTStatusCurrent',
              )}
              hideEditDotOnHover={false}
              items={flippedCTStatusItems[flippedCTStatusDefault]}
              selectedItem={selectedFlippedCTStatusItem}
              unsettable={false}
              updateValue={
                this.state.updateValueHandlers.channels[circuitId]
                  .flippedCTStatusCurrent
              }
            />
          </StyledTableCell>
        )}
      </>
    )
  }

  renderPowerSourceSection = () => {
    const {
      conditionalFields,
      errors,
      initialValues,
      meter,
      status,
      touched,
      values,
      panelsById,
      setFieldValue,
    } = this.props
    const { voltageTapPhases, voltageTapOptions } = status
    const { isNebula, isOrion } = meter
    const {
      panelId,
      voltageScalingFactor,
      voltageScalingFactorCustom,
      highLeg,
      stepDownTransformerName,
    } = values
    const { type: panelType, voltage: panelVoltage } = panelsById[panelId] || {}
    const isHighLegPanel = panelType === 'THREE_PHASE_4_WIRE_DELTA'

    // removing 3 phase 3 wire wye (non-neutral) from the list of non-neutral panels

    const transformerSupportedPanels = structuredClone(nonNeutalPanels).filter(
      (panel) => panel !== 'THREE_PHASE_3_WIRE_WYE',
    )
    const isNoNeutralPanel = nonNeutalPanels.includes(panelType)
    const isNoNeutralNebulaPanel = isNoNeutralPanel && isNebula
    const isTrasformerSupportedPanel = !(
      transformerSupportedPanels.includes(panelType) && isNebula
    )
    const voltageScalingFactorsById =
      conditionalFields.getVoltageScalingFactorsById(panelType, panelVoltage)
    const voltageScalingFactorSelected =
      (voltageScalingFactorsById[voltageScalingFactor] &&
        voltageScalingFactor) ||
      (panelVoltage === 'OTHER' &&
        (panelType === 'OTHER' || voltageScalingFactor > 1) &&
        'OTHER') ||
      Object.keys(voltageScalingFactorsById)[0] ||
      'OTHER'

    if (voltageScalingFactor !== voltageScalingFactorSelected) {
      this.onUpdateVoltageScalingFactor({
        value: voltageScalingFactorSelected,
      })
    }

    // eslint-disable-next-line no-unused-vars
    const voltageScalingFactorItems =
      conditionalFields.getVoltageScalingFactorItems(panelType, panelVoltage)
    let voltageScalingFactorSelectedItem =
      stepDownTransformerName &&
      stepDownTransformerName.length > 0 &&
      voltageScalingFactorItems.find(
        (item) => item.name === stepDownTransformerName,
      )
    if (!voltageScalingFactorSelectedItem) {
      voltageScalingFactorSelectedItem = makeListSelectorItem(
        voltageScalingFactorSelected,
        fieldNameMap.get(voltageScalingFactorSelected),
      )
    }

    const showVoltageScalingFactorCustom = (): boolean =>
      !isNebula && !isOrion && voltageScalingFactorSelected === 'OTHER'

    const voltageTapColumnHeaders =
      isOrion || isNebula ?
        <VoltageTapColumnHeadersStyled>
          <VoltageTapColumnHeadersBreakerStyled>
            Breaker Number
          </VoltageTapColumnHeadersBreakerStyled>
          <VoltageTapColumnHeadersScalingFactorStyled>
            Voltage Scaling Factor
          </VoltageTapColumnHeadersScalingFactorStyled>
          {isHighLegPanel && (
            <VoltageTapColumnHeadersScalingFactorStyled>
              High Leg
            </VoltageTapColumnHeadersScalingFactorStyled>
          )}
          {(isOrion || isNoNeutralNebulaPanel) && (
            <VoltageTapColumnHeadersPhaseAngleStyled>
              Phase Angle Correction Factor
            </VoltageTapColumnHeadersPhaseAngleStyled>
          )}
        </VoltageTapColumnHeadersStyled>
      : ''
    const voltageScalingFactorComponent = (
      <VoltageScalingFactorContainerStyled bottomSpacing={isNebula || isOrion}>
        {!isOrion && isTrasformerSupportedPanel && (
          <EditBlock>
            <Field
              component={InlineInput}
              description='Required for 3-Phase 4-Wire Wye panels with voltages greater than 480V.'
              editDotRight='-15px'
              edited={
                voltageScalingFactor !== initialValues.voltageScalingFactor
              }
              error={
                touched.voltageScalingFactor && errors.voltageScalingFactor
              }
              fieldComponent={StyledStepDownSelector}
              fieldTitle='Step-Down Transformer'
              hideEditDotOnHover={false}
              items={voltageScalingFactorItems}
              name='voltageScalingFactor'
              selectedItem={voltageScalingFactorSelectedItem}
              unsettable={false}
              updateValue={this.onUpdateVoltageScalingFactor}
            />
          </EditBlock>
        )}
        {showVoltageScalingFactorCustom() && (
          <EditBlock>
            <Field
              component={InlineInput}
              description='Required when using a non-standard step-down transformer. (1.000 - 9.999)'
              editDotRight='-15px'
              edited={
                voltageScalingFactorCustom !==
                initialValues.voltageScalingFactorCustom
              }
              error={
                touched.voltageScalingFactorCustom &&
                errors.voltageScalingFactorCustom
              }
              fieldTitle='Voltage Scaling Factor'
              hideEditDotOnHover={false}
              max='9.999'
              min='1'
              name='voltageScalingFactorCustom'
              step='.001'
              type='number'
              onChange={this.onUpdateVoltageScalingFactorCustom}
            />
          </EditBlock>
        )}
      </VoltageScalingFactorContainerStyled>
    )
    const phaseColors = {
      A: 'black',
      B: 'red',
      C: 'blue',
    }
    const isVoltageScalingFactorDropdownEnabled =
      panelVoltage === '_600V' ||
      (panelType === 'THREE_PHASE_4_WIRE_WYE' &&
        panelVoltage === '_600Y_347V') ||
      (panelType === 'THREE_PHASE_4_WIRE_DELTA' &&
        panelVoltage === '_120_208_240V_SINGLE_PHASE') ||
      (panelType === 'OTHER' && panelVoltage === 'OTHER') ||
      isNoNeutralNebulaPanel
    const voltageTapsComponent = (
      <>
        {voltageTapPhases.map((key, index) => {
          const leadNumber = index + 1
          const fieldNameBreaker = `vtapL${leadNumber}Phase${key}`
          const fieldValueBreaker = values[fieldNameBreaker]
          const fieldNameScalingFactor = `voltageScalingFactorL${leadNumber}`
          const fieldNamePhaseAngle = `phaseCorrectionAngleL${leadNumber}`
          const description =
            (
              isOrion // eslint-disable-next-line max-len
            ) ?
              `Specify the breaker number and voltage scaling factor for the line providing power to the L${leadNumber} (${phaseColors[key]}) meter lead.`
            : `The circuit breaker number of the circuit providing Phase ${key} power to the meter`
          const fieldTitle = `Voltage Tap - L${leadNumber} Phase ${key}`
          return (
            <EditBlockStyled key={fieldNameBreaker}>
              <FastField
                component={VoltageTapBreakerInputStyled}
                fieldComponent={StyledVoltageTapSelector}
                items={voltageTapOptions}
                unsettable
                notSetItemText='--'
                notSetLabelText='--'
                selectedItem={makeListSelectorItem(fieldValueBreaker)}
                name={fieldNameBreaker}
                fieldTitle={fieldTitle}
                description={description}
                updateValue={
                  this.state.updateValueHandlers.voltageTap[fieldNameBreaker]
                }
                edited={
                  values[fieldNameBreaker] !== initialValues[fieldNameBreaker]
                }
                error={touched[fieldNameBreaker] && errors[fieldNameBreaker]}
                editDotRight='-15px'
                hideEditDotOnHover={false}
              />
              {(isOrion || isNebula) && (
                <Field
                  component={ScalingFactorInlineInputStyled}
                  name={fieldNameScalingFactor}
                  disabled={!isVoltageScalingFactorDropdownEnabled}
                  updateValue={
                    this.state.updateValueHandlers[fieldNameScalingFactor]
                  }
                  edited={
                    values[fieldNameScalingFactor] !==
                    initialValues[fieldNameScalingFactor]
                  }
                  error={
                    touched[fieldNameScalingFactor] &&
                    errors[fieldNameScalingFactor]
                  }
                  editDotRight='-15px'
                  editDotTop='0px'
                  hideEditDotOnHover={false}
                  type='number'
                />
              )}
              {isHighLegPanel && (
                <StyledRadioButton
                  checked={`L${leadNumber}` === highLeg}
                  onClick={() =>
                    setFieldValue && setFieldValue('highLeg', `L${leadNumber}`)
                  }
                />
              )}
              {(isOrion || isNoNeutralNebulaPanel) && (
                <FastField
                  component={ScalingFactorInlineInputStyled}
                  name={fieldNamePhaseAngle}
                  updateValue={
                    this.state.updateValueHandlers[fieldNamePhaseAngle]
                  }
                  edited={
                    values[fieldNamePhaseAngle] !==
                    initialValues[fieldNamePhaseAngle]
                  }
                  error={
                    touched[fieldNamePhaseAngle] && errors[fieldNamePhaseAngle]
                  }
                  editDotRight='-15px'
                  editDotTop='0px'
                  hideEditDotOnHover={false}
                  type='number'
                />
              )}
            </EditBlockStyled>
          )
        })}
      </>
    )
    return [
      voltageScalingFactorComponent,
      voltageTapColumnHeaders,
      voltageTapsComponent,
    ]
  }

  renderInstallationLocationSection = () => {
    const {
      conditionalFields,
      deletePanel,
      initialValues,
      meter: { generation: meterGeneration, siteId },
      panels,
      panelsById,
      showModalPanelForm,
      showConfirmModal,
      values,
    } = this.props
    const { panelId } = values
    const panelIdEdited = panelId !== initialValues.panelId

    const makePanelListSelectorItem = (panel: FTPanel) => {
      const {
        description,
        id,
        name,
        type,
        voltage,
        parentPanelId,
        parentPanelName,
        panelLevel,
      } = panel
      return {
        id,
        name,
        renderFunc: () => {
          const onClickEdit = (event: FTFormFieldEvent) => {
            event.stopPropagation()
            showModalPanelForm({
              siteId,
              meterGeneration,
              conditionalFields,
              description,
              id,
              name,
              type,
              voltage,
              parentPanelId,
              parentPanelName,
              panelLevel,
            })
          }

          const onClickDelete = (event: FTFormFieldEvent) => {
            event.stopPropagation()
            showConfirmModal({
              modalWidth: '418px',
              onPrimaryAction: undefined,
              onSecondaryAction: () =>
                deletePanel({
                  id,
                  name,
                }),
              primaryActionText: 'No',
              renderBody: () => (
                <StyledDeletePanelModalBody>
                  Are you sure you want to delete panel
                  <br />
                  <b>{name}</b>?
                </StyledDeletePanelModalBody>
              ),
              secondaryActionText: 'Yes',
            })
          }

          return (
            <PanelSelectorItem
              onClickEdit={onClickEdit}
              onClickDelete={onClickDelete}
              panel={panel}
            />
          )
        },
      }
    }

    const panelSelectorItems = panels
      .map((panel) => makePanelListSelectorItem(panel))
      .sort(naturallySortItems)
    const selectedPanelItem =
      panelId && panelsById[panelId] ?
        makePanelListSelectorItem(panelsById[panelId])
      : null
    return (
      <StyledInstallationLocationSection>
        <EditBlock>
          <Field
            className={panelId ? 'has-value' : undefined}
            component={InlineInput}
            /* eslint-disable-next-line max-len */
            description='Select the electrical panel that the meter is installed in. If the meter is monitoring circuits on multiple panels, select the panel that is powering the meter.'
            disabled={siteId === '15e7e4ce-44ec-486a-bb1f-cdc6ce533e25'}
            edited={panelIdEdited}
            editDotRight='-15px'
            editDotTop='26px'
            fieldComponent={StyledPanelSelector}
            fieldTitle='Primary Electrical Panel'
            items={panelSelectorItems}
            name='panelId'
            notSetLabelText='-- Select a Panel --'
            notSetItemRenderFunc={() => (
              <div>
                <StyledPanelTitle>Unassigned</StyledPanelTitle>
                <StyledPanelDetails>
                  This meter has not been installed yet
                </StyledPanelDetails>
              </div>
            )}
            searchable={false}
            selectedItem={selectedPanelItem}
            updateValue={this.onUpdateMeterPanelId}
            footerLinkText='+ Add Panel'
            onFooterClick={() =>
              showModalPanelForm({
                siteId,
                meterGeneration,
                conditionalFields,
              })
            }
          />
        </EditBlock>
      </StyledInstallationLocationSection>
    )
  }

  render() {
    const {
      dirty,
      error,
      handleSubmit,
      initialValues,
      isSubmitting,
      panelsById,
      resetForm,
      setFieldValue,
      setValues,
      showConfirmModal,
      status,
      values,
      errors,
    } = this.props
    const isPowerSourceValid = !(
      errors.voltageScalingFactorL1 ||
      errors.voltageScalingFactorL2 ||
      errors.voltageScalingFactorL3 ||
      errors.phaseCorrectionAngleL1 ||
      errors.phaseCorrectionAngleL2 ||
      errors.phaseCorrectionAngleL3
    )
    const { inUseSelectAllChecked1, inUseSelectAllChecked2 } = this.state
    const { goBack, meter } = status
    const { isOrion, isNebula, source } = meter
    const { channels } = values
    const channelList: any = Object.values(channels)
    const isRedaptive = isRedaptiveMeter(source)
    return (
      <>
        <UnsavedChanges when={!isSubmitting && dirty} />
        <StyledErrorMessage message={error} />

        {isRedaptive && (
          <TabContent title='Installation Location'>
            {this.renderInstallationLocationSection()}
          </TabContent>
        )}

        <PowerSourceSectionTabContentStyled title='Power Source'>
          {this.renderPowerSourceSection()}
        </PowerSourceSectionTabContentStyled>

        <TabContent
          title='Channel Configuration'
          marginTop='40px'
          marginBottom='20px'
        >
          <AutoFillOptions
            dirty={dirty}
            initialValues={initialValues}
            meter={meter}
            panelsById={panelsById}
            resetForm={resetForm}
            setFieldValue={setFieldValue}
            setValues={setValues}
            showConfirmModal={showConfirmModal}
            values={values}
          />
          <ChannelsTableWrapperStyles>
            <ChannelsTable
              isNebula={isNebula}
              isOrion={isOrion}
              renderRowBody={this.renderChannelRowBody}
              items={channelList}
              onInUseSelectAllChange1={this.onInUseSelectAllChange1}
              onInUseSelectAllChange2={this.onInUseSelectAllChange2}
              inUseSelectAllChecked1={inUseSelectAllChecked1}
              inUseSelectAllChecked2={inUseSelectAllChecked2}
              isEditForm
            />
          </ChannelsTableWrapperStyles>
        </TabContent>

        <FormButtons
          onSubmit={handleSubmit}
          submitText='Save'
          onCancel={goBack}
          cancelText='Cancel'
          submitDisabled={!dirty || !isPowerSourceValid || isSubmitting}
        />
      </>
    )
  }
}
