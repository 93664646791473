import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeEvery } from 'redux-saga/effects'

import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { handleSagaError } from '../../sagas/utils'

export type FTPostFileAction = {
  file: any
}
type FTUtilityUsageDataMeta = {
  error: string
  isUploading: boolean
}
export type FTUtilityUsageDataState = {
  utilityData: {
    uploadResponse: Record<string, any>
    meta: FTUtilityUsageDataMeta
  }
  invoiceData: {
    uploadResponse: Record<string, any>
    meta: FTUtilityUsageDataMeta
  }
}
type FTState = {
  uploadUtilityData: {
    utilityUsageData: FTUtilityUsageDataState
  }
}
export const types = {
  POST_UTILITY_DATA: 'POST_UTILITY_DATA',
  POST_UTILITY_DATA_ERROR: 'POST_UTILITY_DATA_ERROR',
  POST_UTILITY_DATA_SUCCESS: 'POST_UTILITY_DATA_SUCCESS',
  RESET_UTILITY_DATA_STATE: 'RESET_UTILITY_DATA_STATE',
  POST_INVOICE_DATA: 'POST_INVOICE_DATA',
  POST_INVOICE_DATA_ERROR: 'POST_INVOICE_DATA_ERROR',
  POST_INVOICE_DATA_SUCCESS: 'POST_INVOICE_DATA_SUCCESS',
  RESET_INVOICE_DATA_STATE: 'RESET_INVOICE_DATA_STATE',
}

export const selectUtilityUsageDataEntity = (
  state: FTState,
): FTUtilityUsageDataState => state.uploadUtilityData.utilityUsageData
const initialState: FTUtilityUsageDataState = {
  utilityData: {
    uploadResponse: null,
    meta: {
      error: '',
      isUploading: false,
    },
  },
  invoiceData: {
    uploadResponse: null,
    meta: {
      error: '',
      isUploading: false,
    },
  },
}

const utilityData = (
  state = initialState.utilityData,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.POST_UTILITY_DATA:
      return {
        ...state,
        uploadResponse: null,
        meta: {
          error: '',
          isUploading: true,
        },
      }

    case types.POST_UTILITY_DATA_ERROR:
      return {
        ...state,
        meta: {
          error: action.error,
          isUploading: false,
        },
        uploadResponse: null,
      }

    case types.POST_UTILITY_DATA_SUCCESS:
      return {
        ...state,
        meta: {
          error: '',
          isUploading: false,
        },
        uploadResponse: action.payload,
      }

    case types.RESET_UTILITY_DATA_STATE:
      return initialState.utilityData

    default:
      return state
  }
}

const invoiceData = (
  state = initialState.invoiceData,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.POST_INVOICE_DATA:
      return {
        ...state,
        uploadResponse: null,
        meta: {
          error: '',
          isUploading: true,
        },
      }

    case types.POST_INVOICE_DATA_ERROR:
      return {
        ...state,
        meta: {
          error: action.error,
          isUploading: false,
        },
        uploadResponse: null,
      }

    case types.POST_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        meta: {
          error: '',
          isUploading: false,
        },
        uploadResponse: action.payload,
      }

    case types.RESET_INVOICE_DATA_STATE:
      return initialState.invoiceData

    default:
      return state
  }
}

export default combineReducers({
  utilityData,
  invoiceData,
})
export const actions = {
  postUtilityData: (params: FTPostFileAction) => ({
    type: types.POST_UTILITY_DATA,
    ...params,
  }),
  resetUtilityDataState: () => ({
    type: types.RESET_UTILITY_DATA_STATE,
  }),
  postInvoiceData: (params: FTPostFileAction) => ({
    type: types.POST_INVOICE_DATA,
    ...params,
  }),
  resetInvoiceDataState: () => ({
    type: types.RESET_INVOICE_DATA_STATE,
  }),
}
// API
export class API {
  static postUtilityData({ file }: Record<string, any>) {
    const url = `${consoleBaseUrl()}/utility/api/csv`
    const formData = new FormData()
    formData.set('file', file)
    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
    })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static postInvoiceData({ file }: Record<string, any>) {
    const url = `${consoleBaseUrl()}/utility/api/invoice-usage/csv/upload`
    const formData = new FormData()
    formData.set('file', file)
    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
    })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* postUtilityDataSaga(
  params: FTPostFileAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.postUtilityData, params)
    yield put({
      type: types.POST_UTILITY_DATA_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.POST_UTILITY_DATA_ERROR, error)
  }
}

function* postInvoiceSaga(params: FTPostFileAction): Generator<any, void, any> {
  try {
    const payload = yield call(API.postInvoiceData, params)
    yield put({
      type: types.POST_INVOICE_DATA_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.POST_INVOICE_DATA_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.POST_UTILITY_DATA, postUtilityDataSaga),
  takeEvery(types.POST_INVOICE_DATA, postInvoiceSaga),
]
