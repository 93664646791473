export default [
  {
    created: '2021-09-14T11:26:47.933949',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modified: null,
    modifiedBy: null,
    id: '464b2031-7f06-439a-855c-2cebe25b264e',
    salesforceCustomerId: '0014600001dwEOsAAM',
    name: 'Batch-1',
    status: 'PENDING',
    projectType: 'LIGHTING',
    opportunityIds: null,
    redaptiveOpportunityIds: [
      'R00010883',
      'R00002651',
      'R00010894',
      'R00010885',
    ],
    lastModified: '2021-09-14T11:26:47.933949',
  },
  {
    created: '2021-09-21T13:50:04.586797',
    createdBy: 'admin@redaptiveinc.com',
    modified: null,
    modifiedBy: null,
    id: '28899119-51b8-4b81-b91d-8d6f9eca5eb9',
    salesforceCustomerId: '0014600001dwEOsAAM',
    name: 'Batch-6',
    status: 'PENDING',
    projectType: 'LIGHTING',
    opportunityIds: null,
    redaptiveOpportunityIds: ['R00010883', 'R00002651'],
    lastModified: '2021-09-21T13:50:04.586797',
  },
]
