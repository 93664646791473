import { ReactNode } from 'react'
import styled from 'styled-components'

const ModalOuterStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(47, 50, 53, 0.5);
  z-index: 99999;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  font-size: 14px;
`
const ModalInnerStyles = styled.div`
  position: relative;
  margin: 10% auto;
  border-radius: 4px;
  background: #fff;
  padding: 3px 0px;
  height: 204px;
  box-sizing: border-box;
  width: ${({ modalWidth, small }) => (small && '334px') || modalWidth};
`
type Props = {
  children?: ReactNode
  modalWidth: string
}

const Modal = ({ children, modalWidth }: Props) => (
  <ModalOuterStyled>
    <ModalInnerStyles modalWidth={modalWidth}>{children}</ModalInnerStyles>
  </ModalOuterStyled>
)

export default Modal
