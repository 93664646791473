import axios from 'axios'
import { capitalCase } from 'capital-case'
import moment from 'moment-timezone'
import { schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import type { FTEntityPayload } from './utils'
import {
  makeActionTypes,
  makeSagaPayload,
  orderByToString,
  renderTimestamp,
} from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { getTotalPages, handleAxiosError, queryStringify } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import type { FTSortable } from '../types'
import '../types'

export type FTUnassignedMeter = {
  id: string
  name: string
  firstReportDate: string
  meterType: string
  source: string
}
export type FTUnassignedListEntity = {
  items: Array<FTUnassignedMeter>
  meta: Record<string, any>
}
// Action Types
export const types = {
  ...makeActionTypes('FETCH_UNASSIGNED_METER_LIST'),
  ...makeActionTypes('SUBMIT_METER_ASSIGNMENTS'),
  CLEAR_METER_ASSIGNMENT_FORM: 'CLEAR_METER_ASSIGNMENT_FORM',
}
// Utils
export const utils = {
  sourceToMeterType: (source: string) => capitalCase(source),
  enhanceEntity: (meter: FTUnassignedMeter) => ({
    ...meter,
    meterType: utils.sourceToMeterType(meter.source),
    firstReported: renderTimestamp(meter.firstReportDate, moment.tz.guess()),
  }),
}
type FTMeterEntityState = {
  byId: Record<string, any>
  allIds: Array<string>
  meta: {
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
    error: string | null | undefined
    orderBy?: {
      sort: 'ASC' | 'DESC' | ''
      field: string
    }
  }
}
type FTState = {
  entities: {
    unassignedMeters: FTMeterEntityState
  }
}
// Reducers
export const initialState = {
  byId: {},
  allIds: [],
  meta: {
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    orderBy: null,
  },
  form: {
    success: false,
    loading: false,
    error: null,
  },
}
const entity = 'unassignedMeters'
export const entitySchema = new schema.Entity(entity)

function entityById(action, state) {
  return { ...state, ...action.payload.entities[entity] }
}

function entityAllIds(action) {
  return action.payload.result
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_UNASSIGNED_METER_LIST:
      return state

    case types.FETCH_UNASSIGNED_METER_LIST_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.FETCH_UNASSIGNED_METER_LIST:
      return state

    case types.FETCH_UNASSIGNED_METER_LIST_SUCCESS:
      return entityAllIds(action)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_UNASSIGNED_METER_LIST:
      return { ...state, loading: true, error: null }

    case types.FETCH_UNASSIGNED_METER_LIST_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_UNASSIGNED_METER_LIST_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    default:
      return state
  }
}

function form(state = {}, action) {
  switch (action.type) {
    case types.SUBMIT_METER_ASSIGNMENTS_SUCCESS:
      return { ...state, loading: false, error: null, success: true }

    case types.SUBMIT_METER_ASSIGNMENTS_ERROR:
      return { ...state, loading: false, error: action.error, success: false }

    case types.SUBMIT_METER_ASSIGNMENTS:
      return { ...state, loading: true, error: null }

    case types.CLEAR_METER_ASSIGNMENT_FORM:
      return initialState.form

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
  form,
})
type FTFetchListAction = {
  pageNumber?: number
  pageSize?: number
  orderBy?: FTSortable
}
type FTSubmitMeterAssignmentsAction = {
  meterIds: Array<string>
  siteId: string
  opportunityId: string
}

// Action Creators
export const actions = {
  fetchUnassignedMeterList: (params: FTFetchListAction) => ({
    type: types.FETCH_UNASSIGNED_METER_LIST,
    ...params,
  }),
  fetchUnassignedMeterListSuccess: (payload: FTEntityPayload) => ({
    type: types.FETCH_UNASSIGNED_METER_LIST_SUCCESS,
    payload,
  }),
  submitMeterAssignments: (params: FTSubmitMeterAssignmentsAction) => ({
    type: types.SUBMIT_METER_ASSIGNMENTS,
    ...params,
  }),
  submitMeterAssignmentsSuccess: () => ({
    type: types.SUBMIT_METER_ASSIGNMENTS_SUCCESS,
  }),
  clearMeterAssignmentForm: () => ({
    type: types.CLEAR_METER_ASSIGNMENT_FORM,
  }),
}
// SELECTORS
export const selectUnassignedList = (
  state: FTState,
): Array<FTUnassignedMeter> =>
  state.entities[entity].allIds.map((id) => state.entities[entity].byId[id])
export const selectUnassigned = (state: FTState, id: string) =>
  state.entities[entity].byId[id]
export const selectUnassignedListEntity = (state: FTState) => ({
  items: selectUnassignedList(state),
  meta: state.entities[entity].meta,
})
export const selectUnassignedEntity = (state: FTState, id: string) => ({
  item: selectUnassigned(state, id),
  meta: state.entities[entity].meta,
})
// API
export class API {
  static fetchUnassignedMeterList({
    pageNumber: page,
    pageSize: perPage,
    orderBy,
  }: FTFetchListAction) {
    const order = orderByToString(orderBy)
    const pageSize = perPage || 20
    const pageNumber = page || 1
    const query = queryStringify({
      pageNumber,
      pageSize,
      order,
    })
    const baseUrl = `${consoleApiUrl()}/meters/unassigned`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => {
        const { data } = response
        const { totalCount } = data
        return {
          results: data,
          totalPages: getTotalPages(totalCount, pageSize),
          pageNumber,
          pageSize,
          orderBy,
        }
      })
      .catch(handleAxiosError)
  }

  static submitMeterAssignment({
    meterIds,
    siteId,
    opportunityId,
  }: FTSubmitMeterAssignmentsAction) {
    const url = `${consoleApiUrl()}/meters/assign`
    const body = {
      siteId,
      meterIds,
      opportunityId: opportunityId || '',
    }

    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(() => ({}))
      .catch(handleAxiosError)
  }
}

function* fetchUnassignedMeterListSaga(
  params: FTFetchListAction,
): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchUnassignedMeterList, params)
    const payload = makeSagaPayload(response, entitySchema, (r) =>
      utils.enhanceEntity(r),
    )
    yield put(actions.fetchUnassignedMeterListSuccess(payload))
  } catch (e) {
    yield handleSagaError(types.FETCH_UNASSIGNED_METER_LIST_ERROR, e)
  }
}

// Reason: linter fights with itself over line length sometimes
// eslint-disable-next-line
function* submitMeterAssignmentsSaga(
  params: FTSubmitMeterAssignmentsAction,
): Generator<any, void, any> {
  try {
    yield call(API.submitMeterAssignment, params)
    yield put(actions.submitMeterAssignmentsSuccess())
  } catch (e) {
    yield handleSagaError(types.SUBMIT_METER_ASSIGNMENTS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_UNASSIGNED_METER_LIST, fetchUnassignedMeterListSaga),
  takeLatest(types.SUBMIT_METER_ASSIGNMENTS, submitMeterAssignmentsSaga),
]
