import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { call, put, takeEvery } from 'redux-saga/effects'

import { apiGatewayUrl, defaultHeaders } from '../api'
import { queryStringify } from '../api/utils'
import type { FTSaga } from '../types'
import { isVariantActive } from '../utils'

export type FTSpecSheet = {
  addedBy: string
  dateAdded: string
  fileName: string
  url: string
}
export type FTSpecSheetMeta = {
  uploadError: string
  uploading: boolean
  uploadRequestError: string
  uploadRequestLoading: boolean
  uploadUrl: string
}
export type FTSpecSheetState = FTSpecSheet & FTSpecSheetMeta
export type FTSpecSheetsState = {
  byId: Record<string, FTSpecSheetState>
  meta: {
    error: string
    loading: boolean
  }
}
type FTSpecSheetsStateRoot = {
  entities: {
    specSheets: FTSpecSheetsState
  }
}
type FTFetchSpecSheetsResponse = {
  response: {
    files: Array<FTSpecSheet>
  }
}
export type FTSpecSheetUploadAction = {
  fileName: string
  file: any
  url: string
}
export type FTSpecSheetUploadRequestAction = {
  owner: string
  fileName: string
}
export const types = {
  FETCH_SPEC_SHEETS: 'FETCH_SPEC_SHEETS',
  FETCH_SPEC_SHEETS_ERROR: 'FETCH_SPEC_SHEETS_ERROR',
  FETCH_SPEC_SHEETS_SUCCESS: 'FETCH_SPEC_SHEETS_SUCCESS',
  SPEC_SHEET_UPLOAD_REQUEST: 'SPEC_SHEET_UPLOAD_REQUEST',
  SPEC_SHEET_UPLOAD_REQUEST_ERROR: 'SPEC_SHEET_UPLOAD_REQUEST_ERROR',
  SPEC_SHEET_UPLOAD_REQUEST_SUCCESS: 'SPEC_SHEET_UPLOAD_REQUEST_SUCCESS',
  SPEC_SHEET_UPLOAD: 'SPEC_SHEET_UPLOAD',
  SPEC_SHEET_UPLOAD_ERROR: 'SPEC_SHEET_UPLOAD_ERROR',
  SPEC_SHEET_UPLOAD_SUCCESS: 'SPEC_SHEET_UPLOAD_SUCCESS',
  RESET_SPEC_SHEETS_STATE: 'RESET_SPEC_SHEETS_STATE',
}
export const selectSpecSheets = (
  state: FTSpecSheetsStateRoot,
): FTSpecSheetsState => state.entities.specSheets
export const initialState: FTSpecSheetsState = {
  byId: {},
  meta: {
    loading: false,
    error: '',
  },
}

const specSheetsReducer = (
  state: FTSpecSheetsState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.FETCH_SPEC_SHEETS:
      return {
        ...state,
        byId: {},
        meta: {
          error: '',
          loading: true,
        },
      }

    case types.FETCH_SPEC_SHEETS_ERROR:
      return {
        ...state,
        byId: {},
        meta: {
          error: action.error,
          loading: false,
        },
      }

    case types.FETCH_SPEC_SHEETS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.payload },
        meta: {
          error: '',
          loading: false,
        },
      }

    case types.SPEC_SHEET_UPLOAD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.fileName]: {
            ...state.byId[action.fileName],
            uploadError: '',
            uploading: true,
          },
        },
      }

    case types.SPEC_SHEET_UPLOAD_ERROR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.fileName]: {
            ...state.byId[action.fileName],
            uploadError: action.error,
            uploading: false,
          },
        },
      }

    case types.SPEC_SHEET_UPLOAD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.fileName]: {
            ...state.byId[action.fileName],
            uploadError: '',
            uploading: false,
          },
        },
      }

    case types.SPEC_SHEET_UPLOAD_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.fileName]: {
            addedBy: '',
            dateAdded: '',
            fileName: action.fileName,
            uploadError: '',
            uploading: false,
            uploadRequestError: '',
            uploadRequestLoading: true,
            uploadUrl: '',
            url: '',
          },
        },
      }

    case types.SPEC_SHEET_UPLOAD_REQUEST_ERROR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.fileName]: {
            ...state.byId[action.fileName],
            uploadRequestError: action.error,
            uploadRequestLoading: false,
          },
        },
      }

    case types.SPEC_SHEET_UPLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.fileName]: {
            ...state.byId[action.fileName],
            uploadRequestError: '',
            uploadRequestLoading: false,
            uploadUrl: action.payload.response.url,
          },
        },
      }

    case types.RESET_SPEC_SHEETS_STATE:
      return initialState

    default:
      return state
  }
}

export default specSheetsReducer
export const actions = {
  fetchSpecSheets: () => ({
    type: types.FETCH_SPEC_SHEETS,
  }),
  fetchSpecSheetsError: (error: string) => ({
    type: types.FETCH_SPEC_SHEETS_ERROR,
    error,
  }),
  specSheetUpload: (params: FTSpecSheetUploadAction) => ({
    type: types.SPEC_SHEET_UPLOAD,
    ...params,
  }),
  specSheetUploadError: ({
    error,
    fileName,
  }: {
    error: string
    fileName: string
  }) => ({
    type: types.SPEC_SHEET_UPLOAD_ERROR,
    error,
    fileName,
  }),
  specSheetUploadRequest: (params: FTSpecSheetUploadRequestAction) => ({
    type: types.SPEC_SHEET_UPLOAD_REQUEST,
    ...params,
  }),
  specSheetUploadRequestError: ({
    error,
    fileName,
  }: {
    error: string
    fileName: string
  }) => ({
    type: types.SPEC_SHEET_UPLOAD_REQUEST_ERROR,
    error,
    fileName,
  }),
  resetSpecSheetsState: () => ({
    type: types.RESET_SPEC_SHEETS_STATE,
  }),
}
type FTSpecSheetsAPIException = {
  name: string
  error: string
  toString: string
}

const SpecSheetsAPIException = ({ error }): FTSpecSheetsAPIException => ({
  name: 'SpecSheetsAPIError',
  error,
  toString: 'SpecSheetsAPIError',
})

const handleSpecSheetsAPIError = (error: Record<string, any>) => {
  const { response } = error || {}
  const { data } = response || {}
  const { message = 'A unknown problem occurred.' } = data
  throw SpecSheetsAPIException({
    error: message,
  })
}

// API
export class API {
  static fetchSpecSheets() {
    const url = `${apiGatewayUrl()}/spec-sheets/list`

    if (isVariantActive('2990mockErrors')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 1000,
      })
      mock
        .onGet(url)
        .reply(400, {
          message: 'Mock fetch spec sheets error',
        })
        .onAny()
        .passThrough()
      return mockAxios
        .get(url, {
          headers: defaultHeaders(),
        })
        .then(({ data }) => data)
        .catch(handleSpecSheetsAPIError)
    }

    if (isVariantActive('2990mock')) {
      return Promise.resolve({
        files: [
          {
            addedBy: 'Mock User 1',
            dateAdded: '2021-05-18T19:14:56.000Z',
            fileName: 'Green Creative Type B U Bend 17T8U6/840/BYP.pdf',
            url: '#',
          },
          {
            addedBy: 'Mock User 2',
            dateAdded: '2021-04-14T10:14:56.000Z',
            fileName: '9.5PLH-840-DIR.PDF',
            url: '#',
          },
        ],
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 1000)),
      )
    }

    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleSpecSheetsAPIError)
  }

  static specSheetUpload({ url, file }: FTSpecSheetUploadAction) {
    if (isVariantActive('2990mockErrors')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 1000,
      })
      mock
        .onPut(url)
        .reply(400, {
          message: 'Mock upload error',
        })
        .onAny()
        .passThrough()
      return mockAxios
        .get(url, {
          headers: defaultHeaders(),
        })
        .then(({ data }) => data)
        .catch(handleSpecSheetsAPIError)
    }

    if (isVariantActive('2990mock')) {
      return Promise.resolve({}).then((data) => data)
    }

    return axios
      .put(url, file, {
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
      .then(({ data }) => data)
      .catch(handleSpecSheetsAPIError)
  }

  static specSheetUploadRequest(params: FTSpecSheetUploadRequestAction) {
    const url = `${apiGatewayUrl()}/spec-sheets/upload-request?${queryStringify(
      params,
    )}`

    if (isVariantActive('2990mockErrors')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 1000,
      })
      mock
        .onGet(url)
        .reply(400, {
          message: 'Mock upload request error',
        })
        .onAny()
        .passThrough()
      return mockAxios
        .get(url, {
          headers: defaultHeaders(),
        })
        .then(({ data }) => data)
        .catch(handleSpecSheetsAPIError)
    }

    if (isVariantActive('2990mock')) {
      return Promise.resolve({
        url: 'https://example.com/sheet.pdf',
      }).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 1000)),
      )
    }

    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleSpecSheetsAPIError)
  }
}

function* specSheetUploadRequestSaga(
  params: FTSpecSheetUploadRequestAction,
): Generator<any, void, any> {
  const { fileName } = params

  try {
    const payload = yield call(API.specSheetUploadRequest, params)
    yield put({
      type: types.SPEC_SHEET_UPLOAD_REQUEST_SUCCESS,
      fileName,
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'SpecSheetsAPIError') {
      yield put(
        actions.specSheetUploadRequestError({
          error: error.error,
          fileName,
        }),
      )
    } else {
      yield put(
        actions.specSheetUploadRequestError({
          error: 'An unknown error occurred.',
          fileName,
        }),
      )
    }
  }
}

function* specSheetUploadSaga(
  params: FTSpecSheetUploadAction,
): Generator<any, void, any> {
  const { fileName } = params

  try {
    const payload = yield call(API.specSheetUpload, params)
    yield put({
      type: types.SPEC_SHEET_UPLOAD_SUCCESS,
      fileName,
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'SpecSheetsAPIError') {
      yield put(
        actions.specSheetUploadError({
          error: error.error,
          fileName,
        }),
      )
    } else {
      yield put(
        actions.specSheetUploadError({
          error: 'An unknown error occurred.',
          fileName,
        }),
      )
    }
  }
}

export function* fetchSpecSheetsSaga(): FTSaga {
  try {
    const response = yield call(API.fetchSpecSheets)
    const {
      response: { files },
    }: FTFetchSpecSheetsResponse = response
    const payload = files.reduce(
      (acc, cur) => ({ ...acc, [cur.fileName]: cur }),
      {},
    )
    yield put({
      type: types.FETCH_SPEC_SHEETS_SUCCESS,
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'SpecSheetsAPIError') {
      yield put(actions.fetchSpecSheetsError(error.error))
    } else {
      yield put(actions.fetchSpecSheetsError('An unknown error occurred.'))
    }
  }
}
export const sagas = [
  takeEvery(types.FETCH_SPEC_SHEETS, fetchSpecSheetsSaga),
  takeEvery(types.SPEC_SHEET_UPLOAD, specSheetUploadSaga),
  takeEvery(types.SPEC_SHEET_UPLOAD_REQUEST, specSheetUploadRequestSaga),
]
