/* eslint-disable no-unused-vars */
import { memo } from 'react'

import * as ValuesBox from './ValueBox'
import { getCurrencyFormat } from '../../../../utils'

export type FTModalSeekMargin = {
  scenario: Record<string, string | number>
  updatedScenario: Record<string, string | number>
  selectedVariableField: Record<string, string>
  opportunitySummary: Record<string, string>
}
type FTProps = FTModalSeekMargin

const FinalCalculationBox = (props: FTProps) => {
  const {
    scenario,
    updatedScenario,
    selectedVariableField,
    opportunitySummary,
  } = props
  const currencyFormat = getCurrencyFormat(
    'en-US',
    opportunitySummary.currencyCode,
  )
  return (
    <ValuesBox.Wrapper height='184px'>
      <ValuesBox.Row>
        <ValuesBox.Column
          spaceBetween
          styles={{
            borderRight: '1px dotted #485DA0',
          }}
        >
          <div>
            <ValuesBox.Label>Net Margin % New</ValuesBox.Label>
            <ValuesBox.Value>
              {updatedScenario.netMarginInPercentage}%
            </ValuesBox.Value>
          </div>
          <div>
            <ValuesBox.Label>{selectedVariableField.label} New</ValuesBox.Label>
            <ValuesBox.Value>
              {selectedVariableField.fieldType === 'currency' ?
                <>
                  {currencyFormat.format(
                    updatedScenario[selectedVariableField.id],
                  )}
                </>
              : <>
                  {updatedScenario[selectedVariableField.id]}
                  <ValuesBox.ValueSmall>
                    {selectedVariableField.measuringUnit}
                  </ValuesBox.ValueSmall>
                </>
              }
              &nbsp;
            </ValuesBox.Value>
          </div>
        </ValuesBox.Column>
        <ValuesBox.Column spaceBetween>
          <div>
            <ValuesBox.Label>Net Margin % Old</ValuesBox.Label>
            <ValuesBox.Value>{scenario.netMarginInPercentage}%</ValuesBox.Value>
          </div>
          <div>
            <ValuesBox.Label>{selectedVariableField.label} Old</ValuesBox.Label>
            <ValuesBox.Value>
              {selectedVariableField.fieldType === 'currency' ?
                <>
                  {currencyFormat.format(
                    updatedScenario[selectedVariableField.id],
                  )}
                </>
              : <>
                  {updatedScenario[selectedVariableField.id]}
                  <ValuesBox.ValueSmall>
                    {selectedVariableField.measuringUnit}
                  </ValuesBox.ValueSmall>
                </>
              }
              &nbsp;
            </ValuesBox.Value>
          </div>
        </ValuesBox.Column>
      </ValuesBox.Row>
    </ValuesBox.Wrapper>
  )
}

export default memo<FTProps>(FinalCalculationBox)
