import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { consoleApiUrl, defaultHeaders } from '../../../api';
import { handleAxiosError } from '../../../api/utils';

export type TSUploadInvoiceFileAction = {
  file: any;
};
export type TSInvoiceProcessingState = {
  isUploading: boolean;
  invoiceFileName: string;
  error: string | null;
};
type TSInvoiceProcessingStateRoot = {
  invoiceProcessing: TSInvoiceProcessingState;
};

export const types = {
  UPLOAD_INVOICE_FILE_REQUEST: 'UPLOAD_INVOICE_FILE_REQUEST',
  UPLOAD_INVOICE_FILE_SUCCESS: 'UPLOAD_INVOICE_FILE_SUCCESS',
  UPLOAD_INVOICE_FILE_FAILURE: 'UPLOAD_INVOICE_FILE_FAILURE',
  RESET_INVOICE_PROCESSING_STATE: 'RESET_INVOICE_PROCESSING_STATE',
};

export const selectInvoiceProcessing = (
  state: TSInvoiceProcessingStateRoot,
): TSInvoiceProcessingState => state.dataSolutionInvoicing;

  export const initialState: TSInvoiceProcessingState = {
  isUploading: false,
  invoiceFileName: '',
  error: null,
};

const invoiceProcessingReducer = (
  state: TSInvoiceProcessingState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.UPLOAD_INVOICE_FILE_REQUEST:
      return { ...state, isUploading: true, error: null };

    case types.UPLOAD_INVOICE_FILE_SUCCESS:
      return { ...state, isUploading: false, invoiceFileName: action.payload };

    case types.UPLOAD_INVOICE_FILE_FAILURE:
      return { ...state, isUploading: false, error: action.payload };

    case types.RESET_INVOICE_PROCESSING_STATE:
      return initialState;

    default:
      return state;
  }
};

export default invoiceProcessingReducer;
//
export const actions = {
  uploadInvoiceFile: (params: TSUploadInvoiceFileAction) => ({
    type: types.UPLOAD_INVOICE_FILE_REQUEST,
    ...params,
  }),
  uploadInvoiceFileSuccess: (fileName: string) => ({
    type: types.UPLOAD_INVOICE_FILE_SUCCESS,
    payload: fileName,
  }),
  uploadInvoiceFileFailure: (error: string) => ({
    type: types.UPLOAD_INVOICE_FILE_FAILURE,
    payload: error,
  }),
  resetInvoiceProcessingState: () => ({
    type: types.RESET_INVOICE_PROCESSING_STATE,
  }),
};

// API
export class API {
  static uploadInvoiceFile({ file }: Record<string, any>) {
    const url = `${consoleApiUrl()}/billing/fixed/upload-daas-invoices`;
    const formData = new FormData();
    formData.set('file', file);
    return axios({
      method: 'post',
      url,
      data: formData,
      headers: { ...defaultHeaders(), 'Content-Type': 'multipart/form-data' },
    })
      .then(({ data }) => data)
      .catch(handleAxiosError);
  }
}

function* uploadInvoiceFileSaga(
  params: TSUploadInvoiceFileAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.uploadInvoiceFile, params);
    yield put(actions.uploadInvoiceFileSuccess(payload));
  } catch (error) {
    yield put(actions.uploadInvoiceFileFailure(error.message));
  }
}

export const sagas = [
  takeEvery(types.UPLOAD_INVOICE_FILE_REQUEST, uploadInvoiceFileSaga),
];
