/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled from 'styled-components'

import PageHeader from '../components/PageHeader'

const PageStyled = styled.section`
  background-color: #fff;
  padding: 10%;
  text-align: center;
`

const ErrorPage = () => (
  <div>
    <PageHeader Title='Error' />
    <PageStyled>
      <h2>Oops! Something went wrong...</h2>
      <p>
        We are working to fix it as fast as we can. In the meantime, try
        refreshing your browser - sometimes that helps!
      </p>
    </PageStyled>
  </div>
)

export default ErrorPage
