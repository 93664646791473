import Breadcrumbs from '../Breadcrumbs'
import { MeterInfoType } from './types'

type Props = {
  meter: MeterInfoType
  pathname: string
  siteId?: string
  customerId?: string
}
export default function MeterBreadcrumbs({
  meter,
  pathname,
  siteId,
  customerId,
}: Props) {
  let items: Array<{ href: string; text: string }> = []

  if (meter) {
    items = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
    ]

    if (!siteId && !customerId) {
      items = [
        ...items,
        {
          href: '/account-management/meters',
          text: 'Meters',
        },
      ]
    } else if (meter) {
      const { customerName, siteName } = meter

      if (customerId) {
        items = [
          ...items,
          {
            href: '/account-management/customers',
            text: 'Customers',
          },
          {
            href: `/account-management/customers/${customerId}`,
            text: customerName,
          },
        ]
      } else {
        items = [
          ...items,
          {
            href: '/account-management/sites',
            text: 'Sites',
          },
        ]
      }

      items = [
        ...items,
        {
          href: pathname.replace(/\/sites\/.*/, `/sites/${siteId}`),
          text: siteName,
        },
        {
          href: pathname.replace(/\/meters\/.*/, '/meters'),
          text: 'Meters',
        },
      ]
    }
  }

  return <Breadcrumbs items={items} />
}
