import { combineReducers } from 'redux'
// Action Types
export const types = {
  HIDE_MESSAGE2: 'HIDE_MESSAGE2',
  HIDE_ALL_MESSAGES2: 'HIDE_ALL_MESSAGES2',
  SHOW_MESSAGE2: 'SHOW_MESSAGE2',
}
type FTMessageIcon = {
  className: string
  title: string
  backgroundColor: string
}
type FTMessageIconType = 'info' | 'error' | 'success' | 'warning'
export type FTMessageIconLookup = Record<FTMessageIconType, FTMessageIcon>
export type FTMessageInput = {
  title: string
  description?: string
  type?: FTMessageIconType
  messageId?: string
  position?: string
}
type FTMessage = {
  title: string
  description: string
  type: FTMessageIconType
  messageId: string
  position: string
}
export type FTMessages = Array<FTMessage>
type FTMessageState = {
  messages: FTMessages
}
// Reducers
export const initialState: FTMessageState = {
  messages: [],
}

const getMessageDefaults = (): FTMessage => ({
  title: '',
  description: '',
  type: 'info',
  messageId: Date.now().toString(),
  position: 'absolute',
})

function messages(state = initialState.messages, action) {
  switch (action.type) {
    case types.HIDE_MESSAGE2:
      return state.filter(
        (message: FTMessage) => action.value !== message.messageId,
      )

    case types.HIDE_ALL_MESSAGES2:
      return []

    case types.SHOW_MESSAGE2:
      return [...state, { ...getMessageDefaults(), ...action.value }]

    default:
      return state
  }
}

export default combineReducers({
  messages,
}) // Action Creators

export const actions = {
  hideMessage: (value: string) => ({
    type: types.HIDE_MESSAGE2,
    value,
  }),
  hideMessages: () => ({
    type: types.HIDE_ALL_MESSAGES2,
  }),
  showMessage: (value: FTMessageInput) => ({
    type: types.SHOW_MESSAGE2,
    value,
  }),
}
