import { type ComponentType, SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import type { FTBillingReason } from '../../ducks/billing/billingThisMonth/billingItems'
import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTBillingSendToReviewModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import { colors } from '../../utils/themes'
import Button2 from '../Button2'
import Checkbox from '../Checkbox'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import TextArea, { TextAreaInnerStyled } from '../TextArea'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
  reasons: Array<FTBillingReason>
} & FTBillingSendToReviewModal
const ModalBody = styled.div`
  padding: 20px;

  ${TextAreaInnerStyled} {
    max-width: 100%;
  }
`
const ReasonsWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const ReasonsTitleStyled = styled.h5`
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;

  :after {
    content: '*';
    color: ${colors.red5};
  }
`
const ReasonItemWrapperStyled: ComponentType<{
  isChecked: boolean
}> = styled.div`
  background: ${({ isChecked }) =>
    isChecked ?
      `linear-gradient(0deg, ${colors.blue}, ${colors.blue}), #FFFFFF`
    : 'linear-gradient(0deg, rgba(224, 224, 224, 0.2), rgba(224, 224, 224, 0.2)), #FFFFFF'};
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  color: ${({ isChecked }) => (isChecked ? '#FFFFFF' : colors.blue5)};
  font-size: 12px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 300;
  height: 32px;
  margin: 5px;
  padding: 5px;
`

const ReasonItem = ({
  reason,
  id,
  handleCheckboxChange,
}: {
  reason: string
  id: string
  handleCheckboxChange: (...args: Array<any>) => any
}) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = (event) => {
    setIsChecked(!isChecked)
    handleCheckboxChange(event)
  }

  return (
    <ReasonItemWrapperStyled isChecked={isChecked}>
      <Checkbox
        name={reason}
        value={id}
        checked={isChecked}
        onChange={handleChange}
      />
      {reason}
    </ReasonItemWrapperStyled>
  )
}

const billingSendToReviewModal = ({
  actions,
  billId,
  selectedAction,
  loading,
  setSendToReviewSubmitted,
  reasons,
}: FTProps) => {
  const [comment, setComment] = useState('')
  const [selectedReasons, setSelectedReasons] = useState([])
  useEffect(() => {
    actions.fetchReasons()
  }, [])

  const handleSendToReviewCommentBlur = (event: SyntheticEvent) => {
    const {
      target: { value },
    } = event
    setComment(value)
  }

  const handleConfirm = () => {
    actions.sendToReview({
      billId,
      selectedAction,
      comment,
      reasons: selectedReasons,
    })
    setSendToReviewSubmitted(true)
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target

    if (!checked) {
      setSelectedReasons(
        selectedReasons.filter((reason) => reason.id !== value),
      )
    } else {
      setSelectedReasons([
        ...selectedReasons,
        { ...reasons.find((current) => current.id === value) },
      ])
    }
  }

  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled>
        <ModalConfirmTitleStyled>
          Are you sure you want to move this Opportunity to Invoice Approval?
        </ModalConfirmTitleStyled>
        <>
          <ReasonsTitleStyled>Choose at least 1 reason</ReasonsTitleStyled>
          <ReasonsWrapperStyled>
            {!!reasons.length &&
              reasons.map((reasonInfo) => {
                const { id, reason } = reasonInfo
                return (
                  <ReasonItem
                    key={reason}
                    id={id}
                    reason={reason}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                )
              })}
          </ReasonsWrapperStyled>
        </>
        <ModalConfirmBodyStyled>
          <ModalBody>
            <TextArea
              onBlur={handleSendToReviewCommentBlur}
              placeholder='Comment'
            />
          </ModalBody>
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2 onClick={actions.hideModal} type='transparent'>
            Cancel
          </Button2>
          <Button2 onClick={handleConfirm} loading={loading} type='primary'>
            Confirm
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const {
    sendToReviewMeta: { loading },
    reasons,
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
    reasons,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(billingSendToReviewModal)
