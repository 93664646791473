import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import { logoutUser as logoutUserAction } from '../actions/login'
import Navbar from '../components/Navbar'
import { zIndices } from '../utils'
import { getThemeFromPathname } from '../utils/themes'
import type { ComponentType } from 'react'

const HeaderContainerWithSideMarginsStyles = css`
  margin-left: 60px;
  margin-right: 60px;
`
const HeaderContainerStyled: ComponentType<{
  includeSideMargins: boolean
}> = styled.div`
  background-color: #ffffff;
  height: 85px;
  margin-bottom: 30px;

  + *:not(.old-ui) {
    ${({ includeSideMargins }) =>
      includeSideMargins && HeaderContainerWithSideMarginsStyles};
    min-width: 875px;
    @media (max-width: 961px) {
      min-width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding: 20px;
    }
  }
`
const FixedNavStyled = styled.div`
  border-bottom: 1px solid #d3d3d3;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: ${zIndices.HeaderContainer};
`
type FTProps = {
  logoutUser: (...args: Array<any>) => any
  pathname: string
}

class HeaderContainer extends React.PureComponent<FTProps> {
  render() {
    const { logoutUser, pathname } = this.props
    const theme = getThemeFromPathname(pathname)
    const includeSideMargins = theme === 'redaptive'
    return (
      <HeaderContainerStyled includeSideMargins={includeSideMargins}>
        <FixedNavStyled>
          <Navbar logoutUser={logoutUser} hasConsumption={false} />
        </FixedNavStyled>
      </HeaderContainerStyled>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => {
    dispatch(logoutUserAction())
  },
})

const mapStateToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
