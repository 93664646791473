import styled from 'styled-components'

import Button2 from '../../components/Button2'
import {
  DatePickerStyles,
  DatePickerTextWrapperStyled,
} from '../../components/DatePicker'
import { SearchBarStyled } from '../../components/EntityList/SearchBar'
import { SelectStyles } from '../../components/ListSelector'
import {
  ReactTableStyled,
  ReactTableWrapperStyles,
  TableScrollStyled,
  TdInnerStyled,
  ThInnerStyled,
} from '../../components/RedaptiveReactTable7'
import StyledLink from '../../components/StyledLink'
import { colors } from '../../utils/themes'

export const PageSizeSelectorWrapperStyled = styled.div`
  float: right;
  position: relative;
  ${SelectStyles} {
    margin-left: 8px;
    width: 120px;
  }
`
export const PageSizeSelectorInnerStyled = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 5px;
`

export const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`
export const PaginatorStyles = styled.div`
  padding-top: 31px;
  padding-bottom: 40px;

  .pager {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .current {
    background-color: ${colors.orange4} !important;
  }
`

export const TableStyles = styled.div`
  td {
    &:first-child {
      > ${TdInnerStyled} {
        margin-left: 4px;
        padding-left: 20px;
      }
    }
  }
  th {
    &:first-child {
      > ${ThInnerStyled} {
        margin-left: 4px;
        padding-left: 20px;
      }
    }
  }
  ${TableScrollStyled} {
    position: relative;
    right: -23px;
  }

  ${ReactTableStyled} {
    border-width: 0px, 0px, 0px, 0px;

    border: 1px solid ${colors.blue14};
    max-width: 400px;
    &:last-child {
      ${SearchBarStyled} {
        min-width: 80px;
      }
    }
  }

  ${ReactTableWrapperStyles} {
    overflow-x: ${({ loading }) => (loading ? 'hidden' : 'scroll')};
    min-height: 700px;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`

export const Wrapper = styled.div`
  a {
    color: ${colors.blue15};
  }
`

export const Pass = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${colors.green5};
  margin-right: 10px;
`

export const Fail = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${colors.red};
  margin-right: 10px;
`

export const Button = styled(Button2)`
  width: 143px;
  margin-right: 0;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -10px 0 30px 0;
`

export const TableLinkStyled = styled(StyledLink)`
  font-weight: 500;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`
export const FilterContainerStyled = styled.div`
  input {
    width: ${({ width }) => `${width}px`};
  }
`
export const DatePickerContainerStyled = styled.div`
  ${DatePickerStyles} {
    width: 215px;
    border-radius: 0px;
    background-color: ${colors.white};
    border-radius: 3px;
    border: 1px solid ${colors.gray8};
    color: #4a4a4a;
    height: 36px;
    font: 14px 'Avenir Next';
    padding-left: 2px;
  }

  ${DatePickerTextWrapperStyled} {
    width: 210px;
  }
`

export const EmptyTableIndicatorStyled = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 400px;
`
