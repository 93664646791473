import moment from 'moment'

import { TSEnergyStarMonthlySummary, TSEnergyStarSubmissions } from './types'
import {
  DataSourceValueMap,
  ResourceValueMap,
  StatusViewValueMap,
} from '../../pages/IntegrationSubmissions/helpers/utils'
import { formatHealthStatus } from '../../utils'

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1)
}

export const monthlySummaryMapper = (data: TSEnergyStarMonthlySummary[]) => {
  const responseData = data.map(
    ({
      customerName,
      enabledSites,
      customerId,
      sitesNotSubmittedSuccessfully,
      sitesSubmittedForElectricity,
      totalSitesForElectricity,
      sitesSubmittedForWater,
      totalSitesForWater,
      sitesSubmittedForGas,
      totalSitesForGas,
    }) => ({
      customerName,
      enabledSites,
      customerId,
      sitesNotSubmittedSuccessfully,
      electricSummary: `${sitesSubmittedForElectricity}/${totalSitesForElectricity}`,
      waterSummary: `${sitesSubmittedForWater}/${totalSitesForWater}`,
      gasSummary: `${sitesSubmittedForGas}/${totalSitesForGas}`,
    }),
  )
  return responseData
}

const submissionExportMapper = (items: TSEnergyStarSubmissions[]) => {
  const data = items.map((item) => ({
    ...item,
    resourceType: ResourceValueMap[item.resourceType] || item.resourceType,
    dataSource: DataSourceValueMap[item.dataSource] || item.dataSource,
    status:
      StatusViewValueMap[item.status as keyof typeof StatusViewValueMap] ||
      item.status,
    dataAvailability: formatHealthStatus(item.dataAvailability),
    deltaEnergyConsumption:
      item.deltaEnergyConsumption ?
        `${Math.round(item.deltaEnergyConsumption)}%`
      : 'NA',
    espmStartdate:
      item.espmStartdate ?
        moment(item.espmStartdate.split('T'), 'YYYY-MM-DD').format('MM-DD-YYYY')
      : 'NA',
  }))
  return {
    submitted: data.filter(
      (item) => item.status === StatusViewValueMap.SUCCESS,
    ),
    'not-submitted': data.filter(
      (item) => item.status !== StatusViewValueMap.SUCCESS,
    ),
  }
}

export const submissionsMapper = ({
  items,
}: {
  items: TSEnergyStarSubmissions[]
}): {
  items: TSEnergyStarSubmissions[]
  exportData: {
    submitted: TSEnergyStarSubmissions[]
    notSubmitted: TSEnergyStarSubmissions[]
  }
} => {
  const responseData = items.map((item) => {
    if (item.dataAggregation) {
      let { dataAggregation } = item

      if (item.dataAggregation !== 'wholeSite') {
        if (
          item.resourceType === 'electricity' ||
          item.resourceType === 'water'
        ) {
          dataAggregation = `${capitalizeFirstLetter(dataAggregation)}-${
            item.meterName
          }`
        }
      } else {
        dataAggregation = 'Whole Site'
      }

      return { ...item, dataAggregation }
    }

    return item
  })
  return {
    items: responseData,
    exportData: submissionExportMapper(responseData),
  }
}

export const statusCodeMapper = ({
  statusCode,
  message,
  customVariables,
}: {
  statusCode: unknown
  message: string
  customVariables: {
    entityName: 'customer' | 'site' | 'meter'
  }
}) => {
  const commonMessages = {
    400: 'Application Error. Contact Product Team for Details.',
    401: 'Invalid Energy Star Credentials.',
    500: 'Problem with Energy Star Servers. Try mapping the it later.',
    502: 'Energy Star Servers under maintenance. Try mapping the it later.',
  }
  const messages = {
    customer: {
      403: 'Insufficient Access Permission for Customer Account.',
      404: "Customer Account doesn't exist in Energy Star.",
      ...commonMessages,
    },
    site: {
      403: 'Insufficient Access Permission for Site.',
      404: "Site doesn't exist in Energy Star.",
      ...commonMessages,
    },
    meter: {
      403: 'Insufficient Access Permission for Meter.',
      404: "Meter doesn't exist in Energy Star.",
      ...commonMessages,
    },
  }
  if (
    !statusCode ||
    !customVariables ||
    !messages[customVariables.entityName]?.[statusCode]
  )
    return message
  return messages[customVariables.entityName]?.[statusCode]
}
