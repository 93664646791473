import styled from 'styled-components'

export const Wrapper = styled.div`
  border: ${({ border }) => `1px solid ${border}`};
  background: ${(props) => props.background};
  border-radius: 4px;
  height: 106px;
  width: 430px;
  display: grid;
  place-items: center;
  margin: 20px 15px;
`
export const Heading = styled.div`
  font-family: Public Sans;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`
export const Text = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 0 10px 20px 10px;
  margin-top: 4px;
  font-weight: 500;
  line-height: 16px;
`
export const Icon = styled.div`
  margin-top: 10px;
`
