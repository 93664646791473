import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import createSagaMiddleware from 'redux-saga'

import rootReducer from '../reducers'
import rootSaga from '../sagas/rootSaga'
import type { FTHistory } from '../types'
import '../types'

const sagaMiddleware = createSagaMiddleware()

function configureStoreProd(
  initialState: Record<string, any>,
  browserHistory: FTHistory,
) {
  const router = routerMiddleware(browserHistory)
  const middlewares = [
    // Add other middleware on this line...
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    router,
    sagaMiddleware,
  ]
  const store = createStore(
    rootReducer(browserHistory),
    initialState,
    compose(applyMiddleware(...middlewares)),
  )
  sagaMiddleware.run(rootSaga)
  return store
}

function configureStoreDev(
  initialState: Record<string, any>,
  browserHistory: FTHistory,
) {
  const router = routerMiddleware(browserHistory)

  // Shows timing of Redux actions in Performance results
  // https://medium.com/@cvitullo/performance-profiling-a-redux-app-c85e67bf84ae
  const reduxUserTimingMiddleware = () => (next) => (action) => {
    if (performance.mark === undefined) return next(action)
    performance.mark(`${action.type}_start`)
    const result = next(action)
    performance.mark(`${action.type}_end`)
    performance.measure(
      `${action.type}`,
      `${action.type}_start`,
      `${action.type}_end`,
    )
    return result
  }

  const middlewares = [
    // Add other middleware on this line...
    // Redux middleware that spits an error on you when you try to mutate your
    // state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    router, // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    sagaMiddleware,
    reduxUserTimingMiddleware,
  ]
  // Redux dev tools
  // https://extension.remotedev.io/#12-advanced-store-setup
  const composeEnhancers =
    (
      typeof window === 'object' && // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-next-line no-underscore-dangle
    ) ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose
  const store = createStore(
    rootReducer(browserHistory),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )
  sagaMiddleware.run(rootSaga)
  return store
}

const configureStore =
  import.meta.env.NODE_ENV === 'production' ?
    configureStoreProd
  : configureStoreDev
export default configureStore
