import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import FormikForm from './FormikForm'
import type { FTMeter } from '../../ducks/meters'
import { actions as meterActions, selectMeterEntity } from '../../ducks/meters'
import type { FTModalElectronVerified } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import { DATE_FORMAT_DATA_API_REQUEST } from '../../ducks/utils'
import Modal from '../Modal'

const WrapperStyles = styled.div`
  .Modal {
    .box {
      box-sizing: border-box;
      margin: 2% auto;
      padding: 30px 40px;
      width: 400px;
    }
  }

  .header .title {
    margin-bottom: 20px;
  }
`
type FTProps = {
  dispatch: (...args: Array<any>) => any
  error: string
  handleSuccess: (...args: Array<any>) => any
  hideModal: (...args: Array<any>) => any
  meterEntity: {
    item: FTMeter
    meta: {
      error: Error
      loading: boolean
    }
  }
  updateLoaded: boolean
  updateLoading: boolean
  updateMeter: (...args: Array<any>) => any
} & FTModalElectronVerified

const ModalElectronVerifiedForm = (props: FTProps) => {
  const { hideModal, meterEntity, updateMeter } = props
  const {
    item: meter,
    meta: { error, loading: updateLoading },
  } = meterEntity
  const { id, verified, verifiedDate } = meter
  const loadingRef = useRef(false)
  const { current: loadingPrev } = loadingRef
  useEffect(() => {
    if (loadingPrev && !updateLoading && !error) {
      hideModal()
    }

    loadingRef.current = updateLoading
  })

  const getError = () => (loadingPrev && !updateLoading ? error : '')

  const submitAction = (submission: any) => {
    const {
      id: idSubmission,
      verified: verifiedSubmission,
      verifiedDate: verifiedDateSubmission,
    } = submission
    const verifiedDateFormatted =
      verifiedDateSubmission ?
        verifiedDateSubmission.format(DATE_FORMAT_DATA_API_REQUEST)
      : ''
    updateMeter({
      id: idSubmission,
      verified: verifiedSubmission,
      verifiedDate: verifiedDateFormatted,
    })
  }

  const renderContent = () => (
    <FormikForm
      id={id}
      error={getError()}
      submitAction={submitAction}
      goBack={hideModal}
      verified={verified}
      verifiedDate={verifiedDate}
    />
  )

  return (
    <WrapperStyles>
      <Modal
        {...props}
        modalWidth='480px'
        onClose={hideModal}
        renderContent={renderContent}
        title='Electron Verified'
      />
    </WrapperStyles>
  )
}

const mapStateToProps = (state, { id }) => ({
  meterEntity: selectMeterEntity(state, id),
})

const mapDispatchToProps = (dispatch) => ({
  hideModal() {
    return dispatch(modalActions.hideModal())
  },

  updateMeter(values) {
    return dispatch(meterActions.updateMeter(values))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalElectronVerifiedForm)
