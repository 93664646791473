// TODO: This is temporary file. Will be removed once this file is hosted on server.

const sampleProfileData =
  'ROID,OpportunityName,SiteID,RDPSiteId,ServiceAddress2,UtilityName,AccountNumber,MeterNumber,ServiceType,ServiceStartDate,ServiceEndDate\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,21,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Wat,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,,02/01/2023,02/28/2023\n' +
  'R00015478,,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD1,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  ',LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD2,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  ',,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAA,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R0001547,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,03/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Electric,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,NearBuy CCD,Mount tape,2364147,452454,Water,02/01/2023,11/02/22\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,eaf66d44-96ec-4fd8-ac31-71fa2ecec95f,near,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023\n' +
  'R00015478,LIGHT - Smoke testing Project Oppty 2,0017800000FjI9lAAF,,,Mount tape,2364147,452454,Water,02/01/2023,02/28/2023'
export default sampleProfileData
