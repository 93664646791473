// Login Page
export const AUTH_EMAIL_INCORRECT = [
  'Your email address or password is incorrect.',
  'Please try again.',
]
export const AUTH_LOGGED_OUT = ['You are now logged out.']
export const AUTH_SIGN_IN = 'Sign In'
// General
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again.'
export const ELLIPSES = '...'
export const COMPANY_ADDRESS = [
  '340 Brannan Street, Suite 400',
  'San Francisco, CA 94107',
  '415-413-0445',
]
// Navbar
export const NAV_LOGO_ALT = 'Redaptive Logo'
export const NAV_SIGN_OUT = 'Sign Out'
// Footer
export const FOOTER_HOME = 'Redaptive Home'
export const FOOTER_TERMS = 'Terms of Use'
export const FOOTER_PRIVACY = 'Privacy Policy'
// Modal
export const MODAL_ERROR_TITLE = 'Something went wrong'
export const MODAL_ERROR_BODY = [
  'There was an issue communicating with the server. Please refresh the page and try again.',
]
export const MODAL_ERROR_ACTION = 'Refresh'
export const CLOSE = 'Close'

// Paginator
export const PAGINATOR_PREV = 'Previous'
export const PAGINATOR_NEXT = 'Next'
export const SITE_SOURCES = Object.freeze({
  ENERTIV: 'ENERTIV',
  LEVITON: 'LEVITON',
  REDAPTIVE: 'REDAPTIVE',
  ATT: 'ATT',
})
// Terms
export const REMAINING_SO_QUANTITY = 'Remaining SO Quantity'
