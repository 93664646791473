import styled from 'styled-components'

const GENERIC_MESSAGE = 'Something went wrong. Please refresh & try again.'
// create-react-app will set NODE_ENV to 'production' for any built version of
// the app. For reference, the build environment is tracked by VITE_DEPLOY_ENV.
const IS_BUILT_APP = import.meta.env.NODE_ENV === 'production'
export const ErrorMessageStyles = styled.div`
  color: #c70d08;
  margin: ${({ hide }) => (hide ? '0' : '20px 0')};
  height: ${({ hide }) => (hide ? '0' : '19px')};
`
type FTProps = {
  collapseWhenEmpty?: boolean
  className?: string

  /* The error message to display */
  message: string | null | undefined

  /* In some cases, we want to mask the error message from the server and simply
  display a generic message.
  If the app has been built and maskError is true, it will display the generic message.
  If the app is in development mode and maskError is true, it will pass through the
  message from the server (for debugging purposes). */
  maskError?: boolean
}

/*
ErrorMessage renders the passed in `message` in red text with specific styles.
*/
const ErrorMessage = ({
  message,
  maskError = false,
  collapseWhenEmpty,
  className,
  ...rest
}: FTProps) => (
  <ErrorMessageStyles
    className={className}
    hide={!message && collapseWhenEmpty}
    {...rest}
  >
    {message && maskError && IS_BUILT_APP && GENERIC_MESSAGE}
    {(!IS_BUILT_APP || !maskError) && message}
  </ErrorMessageStyles>
)

ErrorMessage.defaultProps = {
  maskError: false,
  collapseWhenEmpty: false,
  className: '',
}
export default ErrorMessage
