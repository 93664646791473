import styled from 'styled-components'

import NotVerifiedIcon from '../Icons/NotVerifiedIcon'
import VerifiedIcon from '../Icons/VerifiedIcon'
import { Label } from '../VerticalTable/Base'

export const Styles = styled.div`
  ${Label} {
    min-width: 325px;
  }
`

export const InlineVerifiedIcon = styled(VerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`

export const InlineNotVerifiedIcon = styled(NotVerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
