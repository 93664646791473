import { TabUrlEnum } from '../../helpers/utils'
import MutipleSelectFilter from '../components/MultipleSelectFilter'
import MultipleSelectFilterFunction from '../components/MultipleSelectFilterFunction'
import RenderCustomCell from '../components/RenderCustomCell'

const columnList = {
  customerName: {
    accessor: 'customerName',
    Header: 'Customer',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    id: 'customerName',
  },
  siteName: {
    accessor: 'siteName',
    Header: 'Site',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    id: 'siteName',
  },
  caseId: {
    accessor: 'caseId',
    Header: 'Associated Case',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'caseId',
  },
  opportunityId: {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'opportunityId',
  },
  caseStatus: {
    accessor: 'caseStatus',
    Header: 'Case Status',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'caseStatus',
  },
  caseStartDate: {
    accessor: 'caseStartDate',
    Header: 'Case Start Date',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'caseStartDate',
  },
  status: {
    accessor: 'status',
    Header: 'Submission Status',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    Cell: RenderCustomCell,
    hideSettings: false,
    id: 'status',
  },
  resourceType: {
    accessor: 'resourceType',
    Header: 'Resource',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'resourceType',
  },
  dataAggregation: {
    accessor: 'dataAggregation',
    Header: 'Aggregation',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    id: 'dataAggregation',
    minWidth: 200,
  },
  dataSource: {
    accessor: 'dataSource',
    Header: 'Data Source',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'dataSource',
  },
  dataAvailability: {
    accessor: 'dataAvailability',
    Header: 'Data Availability',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    Cell: RenderCustomCell,
    hideSettings: false,
    id: 'dataAvailability',
  },
  deltaEnergyConsumption: {
    accessor: 'deltaEnergyConsumption',
    Header: 'Delta',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    Cell: RenderCustomCell,
    hideSettings: false,
    id: 'deltaEnergyConsumption',
  },
  currentMonthEnergyConsumption: {
    accessor: 'currentMonthEnergyConsumption',
    Header: 'This Period',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'currentMonthEnergyConsumption',
  },

  previousMonthEnergyConsumption: {
    accessor: 'previousMonthEnergyConsumption',
    Header: 'Previous Period',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'previousMonthEnergyConsumption',
  },
  caseAssignedTo: {
    accessor: 'caseAssignedTo',
    Header: 'Case Assigned To',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'caseAssignedTo',
  },
  meterList: {
    accessor: 'meterList',
    Header: 'Meter List',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'meterList',
  },
  submissionDate: {
    accessor: 'submissionDate',
    Header: 'Submitted ON - Date',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'submissionDate',
  },
  modifiedBy: {
    accessor: 'modifiedBy',
    Header: 'Submitter’s ID',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'modifiedBy',
  },
} as const

export const columnIdsMapByTab: {
  [key in TabUrlEnum]: (keyof typeof columnList)[]
} = {
  [TabUrlEnum.UNDER_MAINTENANCE]: [
    'customerName',
    'siteName',
    'resourceType',
    'dataAggregation',
    'dataAvailability',
    'currentMonthEnergyConsumption',
    'opportunityId',
    'caseId',
    'caseStatus',
    'caseStartDate',
    'caseAssignedTo',
    'meterList',
  ],
  [TabUrlEnum.CASE_NOT_FOUND]: [
    'customerName',
    'siteName',
    'status',
    'resourceType',
    'dataAggregation',
    'dataAvailability',
    'deltaEnergyConsumption',
    'currentMonthEnergyConsumption',
    'previousMonthEnergyConsumption',
    'opportunityId',
  ],
  [TabUrlEnum.READY_FOR_REVIEW]: [
    'customerName',
    'siteName',
    'status',
    'resourceType',
    'dataAggregation',
    'dataAvailability',
    'deltaEnergyConsumption',
    'currentMonthEnergyConsumption',
    'previousMonthEnergyConsumption',
    'opportunityId',
    'caseId',
    'caseStatus',
  ],
  [TabUrlEnum.SUBMITTED]: [
    'customerName',
    'siteName',
    'status',
    'resourceType',
    'dataAggregation',
    'dataAvailability',
    'deltaEnergyConsumption',
    'currentMonthEnergyConsumption',
    'previousMonthEnergyConsumption',
    'submissionDate',
    'modifiedBy',
  ],
}

const getTableColumns = (activeTab: TabUrlEnum) =>
  columnIdsMapByTab[activeTab].map((columnId) => columnList[columnId])

export default getTableColumns
