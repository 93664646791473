import React, {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'

import Breadcrumbs2 from '../../../components/Breadcrumbs2'
import Button2 from '../../../components/Button2'
import ColorIndicator from '../../../components/ColorIndicator'
import DropdownMenuNew from '../../../components/DropdownMenuNew'
import ChevronDown from '../../../components/Icons/svg/ChevronDown'
import ChevronUp from '../../../components/Icons/svg/ChevronUp'
import { InputComponent } from '../../../components/Input'
import Spinner from '../../../components/Spinner'
import StyledLink from '../../../components/StyledLink'
import type { FTWithRouter } from '../../../types'
import '../../../types'
import type {
  FTFetchProposalCustomerSummariesAction,
  FTProposalCustomerSummary,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as customerSummariesActions,
  selectProposalCustomerSummariesEntity,
} from '../../../ducks/proposals/customerSummaries'
import RedaptiveReactTable7, {
  tableBorder,
  TdStyled,
  ThInnerStyled,
} from '../../../components/RedaptiveReactTable7'
import type {
  FTFetchProposalOpportunitySummariesAction,
  FTProposalOpportunitySummary,
} from '../../../ducks/proposals/opportunitySummaries'
import {
  actions as opportunitySummariesActions,
  opportunityStatuses,
  selectProposalOpportunitySummariesEntity,
} from '../../../ducks/proposals/opportunitySummaries'
import type {
  FTFetchProposalOpportunitiesAction,
  FTProposalOpportunity,
} from '../../../ducks/proposals/multisite/opportunities'
import {
  actions as opportunityActions,
  selectProposalOpportunitiesEntity,
} from '../../../ducks/proposals/multisite/opportunities'
import type { FTCreateProposalBatchAction } from '../../../ducks/proposals/multisite/batches'
import {
  actions as batchActions,
  selectProposalBatchesEntity,
} from '../../../ducks/proposals/multisite/batches'
import type {
  FTFetchProposalBatchSummariesAction,
  FTProposalBatchSummaries,
} from '../../../ducks/proposals/multisite/batchSummaries'
import {
  actions as batchSummaryActions,
  selectProposalBatchSummariesEntity,
} from '../../../ducks/proposals/multisite/batchSummaries'
import PageHeader from '../../../components/PageHeader'
import type { FTMessageInput } from '../../../ducks/messages'
import { actions as messagesActions } from '../../../ducks/messages'
import type { FTTableHeaderRow } from '../../../components/RedaptiveReactTable7/TableHead'
import TableHead from '../../../components/RedaptiveReactTable7/TableHead'
import {
  getCurrencyFormat,
  getNumberFormatMinFractionDigits2,
  naturallySortEmptyLastCaseInsensitive,
} from '../../../utils'
import { colors } from '../../../utils/themes'

type FTRow = {
  row: {
    original: FTProposalOpportunitySummary
  }
}
type FTCell = {
  row: FTRow
  value: string
}
type FTProps = {
  actions: {
    fetchProposalOpportunities: (
      params: FTFetchProposalOpportunitiesAction,
    ) => null
    fetchProposalOpportunitySummaries: (
      params: FTFetchProposalOpportunitySummariesAction,
    ) => null
    fetchProposalCustomerSummaries: (
      params: FTFetchProposalCustomerSummariesAction | null | undefined,
    ) => null
    fetchProposalBatchSummaries: (
      params: FTFetchProposalBatchSummariesAction,
    ) => null
    createProposalBatch: (params: FTCreateProposalBatchAction) => null
    hideMessage: (value: string) => null
    showMessage: (props: FTMessageInput) => null
  }
  batchSummaries: FTProposalBatchSummaries
  customerSummariesById: Record<string, FTProposalCustomerSummary>
  opportunities: Array<FTProposalOpportunity>
  opportunitiesLoading: boolean
  opportunitySummaries: Array<FTProposalOpportunitySummary>
  salesforceCustomerId: string
} & FTWithRouter
const CustomerListingStyles = styled.div`
  font-family: 'Public Sans', sans-serif;
  padding-bottom: 80px;
  border-top: 1px solid #e0e0e0;

  ${ThInnerStyled} {
    bottom: 0px;
  }
`
export const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
`
const BreadcrumbsStyles = styled.div`
  margin-bottom: 24px;
`
const CreateBatchStyles = styled.div`
  font-family: 'Public Sans', sans-serif;
  padding-bottom: 80px;
  margin-top: 36px;

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }
`
const TabCountCompletedStyled = css`
  color: white;
`
const TabCountStyled: ComponentType<{
  completed: boolean
}> = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  min-width: 20px;
  ${({ completed }) => completed && TabCountCompletedStyled};
`
const TabCompletedStyles = css`
  background: ${colors.green};
  border: 1px solid ${colors.green};
`
const TabSelectedStyles = css`
  border: 1px solid ${colors.green};
  color: ${colors.green};
  font-weight: 600;
`
const TabStyled: ComponentType<{
  completed: boolean
  selected: boolean
}> = styled.div`
  border: 1px dashed ${colors.blue3};
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  margin-right: 8px;
  display: flex;
  margin: 0 5px;
  ${({ completed }) => completed && TabCompletedStyles};
  ${({ selected }) => selected && TabSelectedStyles};
`
const FormValidatorWrapperStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`
export const ValidatorWrapperStyles = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 32px;
  justify-content: center;
`
export const CurrencyDropDownWrapper = styled.div`
  margin-right: 42px;
  padding-top: 15px;
  font-weight: 500;
  font-color: #3e5caa;
  display: flex;
  align-items: center;
`
const FormTabStyled = styled.div`
  margin: 0 5px;
  color: ${colors.blue3};
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const ButtonContainerStyles = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: row;
  justify-content: space-around;
`
const NameLinkStyled = styled(StyledLink)`
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`
const OpportunityStatusIndicator = styled(ColorIndicator)`
  margin-right: 5px;
`
const InputStyles = styled(InputComponent)`
  font-size: 18px;
  font-family: 'Public Sans', sans-serif;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 8px;
  height: 40px;
  min-width: 300px;
`
const TdStyledStyled = styled(TdStyled)`
  border-bottom: 0;
  border-top: ${tableBorder};
  padding: 0;

  &:first-child {
    border-right: ${({ row }) =>
      row.isExpanded || !row.canExpand ? tableBorder : '0'};
    border-top: ${({ row }) => (row.depth ? '0' : tableBorder)};
  }

  tr:first-child > & {
    border-top: 0;
  }

  tr:last-child > & {
    border-bottom: ${tableBorder};
  }
`
const ScenarioNameCellStyled = styled.div`
  padding-left: 32px;
`
const OpportunityCellStyled = styled.div`
  min-width: 100px;
`
const ExpanderCellStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 4px;
  }
`
const FilterStyles = css`
  vertical-align: middle;
`
const ScenarioStyles = css`
  padding-left: 32px;

  ${FilterStyles};
`
const OpportunityHeaderStyles = css`
  border-right: 1px solid #e0e0e0;

  ${ScenarioStyles};
`
type FTSort = {
  id: string
  desc: boolean
}
const initialSort = {
  id: '',
  desc: false,
}

const CreateNewBatch = (props: FTProps) => {
  const {
    actions,
    batchDetails,
    batchSummaries,
    customerSummariesById,
    history,
    opportunities,
    opportunitiesLoading,
    opportunitySummaries,
    match: {
      params: { salesforceCustomerId },
    },
  } = props

  const [currentCurrency, setCurrentCurrency] = React.useState('')
  const currencyFilterActions = [
    {
      label: 'CAD',
      onClick: () => setCurrentCurrency('CAD'),
      value: 1,
    },
    {
      label: 'EUR',
      onClick: () => setCurrentCurrency('EUR'),
      value: 2,
    },

    {
      label: 'GBP',
      onClick: () => setCurrentCurrency('GBP'),
      value: 3,
    },
    {
      label: 'USD',
      onClick: () => setCurrentCurrency('USD'),
      value: 4,
    },
  ]

  const customerSummary = customerSummariesById[salesforceCustomerId] || {}
  const { name: customerName = '' } = customerSummary || {}
  const numberFormatMinFractionDigits2 =
    getNumberFormatMinFractionDigits2('en-US')
  const [selectedRows, setSelectedRows] = useState({})
  const [batchName, setBatchName] = useState('')
  const [opportunitiesSelected, setOpportunitiesSelected] = useState(false)
  const [waitingForAPI, setWaitingForAPI] = useState(false)
  const selectedRowKeys = Object.keys(selectedRows).map((key) => Number(key))
  const existingBatchNames =
    batchSummaries.map((item) => item.name.split(' ').join('').toLowerCase()) ||
    []
  const [scenariosSort, setScenariosSort] = useState(initialSort)
  const [sortOpportunityDesc, setSortOpportunityDesc] = useState(false)
  const tableInstanceRef = useRef()
  const [filteredOpportunitySummaries, setFilteredOpportunitySummaries] =
    React.useState(opportunitySummaries)
  const [availableCurrencyFilters, setAvailableCurrencyFilters] = useState<any>(
    [],
  )
  const [currencyFormat, setCurrencyFormat] = useState(
    getCurrencyFormat('en-US', 'USD'),
  )
  const availableCurrenciesInOpportunity = () => {
    const allCurrencies = opportunitySummaries.map(
      (summary) => summary.currencyCode,
    )
    return [...new Set(allCurrencies)]
  }
  const handleSortClick = useCallback(
    ({
      target: {
        dataset: { id },
      },
    }) => {
      if (scenariosSort.id !== id) {
        setScenariosSort({
          id,
          desc: false,
        })
      } else if (scenariosSort.desc) {
        setScenariosSort(initialSort)
      } else {
        setScenariosSort({
          id,
          desc: true,
        })
      }
    },
    [scenariosSort],
  )
  useEffect(() => {
    actions.fetchProposalBatchSummaries({
      salesforceCustomerId,
    })
    actions.fetchProposalOpportunitySummaries({
      salesforceCustomerId,
    })

    if (!Object.keys(customerSummariesById).length) {
      actions.fetchProposalCustomerSummaries()
    }
  }, [])
  useEffect(() => {
    if (waitingForAPI && batchDetails && batchDetails.id) {
      history.push(
        `/proposal-operations/proposals-engine/${salesforceCustomerId}/batch-analysis/${batchDetails.id}`,
      )
    }
  }, [batchDetails, waitingForAPI])

  useEffect(() => {
    switch (currentCurrency) {
      case 'CAD':
        setFilteredOpportunitySummaries(
          opportunitySummaries.filter(
            ({ currencyCode }) => currencyCode === 'CAD',
          ),
        )
        break
      case 'EUR':
        setFilteredOpportunitySummaries(
          opportunitySummaries.filter(
            ({ currencyCode }) => currencyCode === 'EUR',
          ),
        )
        break
      case 'GBP':
        setFilteredOpportunitySummaries(
          opportunitySummaries.filter(
            ({ currencyCode }) => currencyCode === 'GBP',
          ),
        )
        break
      default:
        setFilteredOpportunitySummaries(
          opportunitySummaries.filter(
            ({ currencyCode }) => currencyCode === 'USD',
          ),
        )
        break
    }
    setSelectedRows('')
    if (currentCurrency) {
      setCurrencyFormat(getCurrencyFormat('en-US', currentCurrency))
    }
  }, [opportunitySummaries, currentCurrency])

  const breadcrumbs = [
    {
      href: '/proposal-operations/proposals-engine',
      text: 'Proposal Engine',
    },
    {
      href: '/proposal-operations/proposals-engine/',
      text: 'Customers',
    },
    {
      href: `/proposal-operations/proposals-engine/${salesforceCustomerId}`,
      text: customerName,
    },
    {
      href: `/proposal-operations/proposals-engine/${salesforceCustomerId}/create-new-batch`,
      text: 'Create New Batch',
    },
  ]

  const OpportunityStatusCell = ({ value = '' }: FTCell) =>
    (value &&
      opportunityStatuses[value] &&
      opportunityStatuses[value].displayName && (
        <>
          <OpportunityStatusIndicator
            color={opportunityStatuses[value].color}
          />
          {opportunityStatuses[value].displayName}
        </>
      )) ||
    ''

  const filteredOpportunities = useMemo(
    () =>
      !opportunitiesLoading && opportunities.length ?
        opportunities.filter(
          (opportunity) =>
            opportunity.scenarios && opportunity.scenarios.length,
        )
      : [],
    [opportunities],
  )
  const opportunityScenarios = useMemo(
    () =>
      !opportunitiesLoading && opportunities.length ?
        filteredOpportunities.map((opportunity) => {
          const updatedScenarios = opportunity.scenarios.map((scenario) => ({
            externalId: opportunity.externalId,
            redaptiveOpportunityId: opportunity.redaptiveOpportunityId,
            ...scenario,
          }))
          return { ...opportunity, scenarios: updatedScenarios }
        })
      : [],
    [filteredOpportunities],
  )
  const sortedOpportunityScenarios = useMemo(() => {
    const validOpportunityScenarios = opportunityScenarios
      .slice()
      .sort((item1, item2) =>
        sortOpportunityDesc ?
          naturallySortEmptyLastCaseInsensitive(
            item2.redaptiveOpportunityId,
            item1.redaptiveOpportunityId,
            sortOpportunityDesc,
          )
        : naturallySortEmptyLastCaseInsensitive(
            item1.redaptiveOpportunityId,
            item2.redaptiveOpportunityId,
            sortOpportunityDesc,
          ),
      )
      .filter((opportunity) => opportunity.scenarios.length)
    return validOpportunityScenarios.reduce((acc, curr, index) => {
      const rows = validOpportunityScenarios[index].scenarios

      if (rows.length > 1) {
        if (scenariosSort.id) {
          rows.sort((rowA, rowB) =>
            scenariosSort.desc ?
              naturallySortEmptyLastCaseInsensitive(
                rowB[scenariosSort.id],
                rowA[scenariosSort.id],
                scenariosSort.desc,
              )
            : naturallySortEmptyLastCaseInsensitive(
                rowA[scenariosSort.id],
                rowB[scenariosSort.id],
                scenariosSort.desc,
              ),
          )
        }

        const firstRow = rows.shift()
        return [...acc, { ...firstRow, subRows: rows }]
      }

      return [...acc, ...rows]
    }, [])
  }, [scenariosSort, sortOpportunityDesc, opportunityScenarios])

  const ExpandedCell = ({ value }: any) => value

  const DollarCell = ({ value }: any) => currencyFormat.format(value)

  const PercentageCell = ({ value }: any) =>
    `${numberFormatMinFractionDigits2.format(value)}%`

  const ScenarioNameExpandedCell = ({ value }: any) => (
    <ScenarioNameCellStyled>{value}</ScenarioNameCellStyled>
  )

  const opportunitySummaryColumns = useMemo(() => [
    {
      accessor: 'redaptiveOpportunityId',
      Header: 'Opportunity ID',
      Cell: ({
        row: {
          original: { redaptiveOpportunityId, externalId },
        },
      }: FTRow) => (
        <NameLinkStyled
          href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${externalId}`}
        >
          {redaptiveOpportunityId}
        </NameLinkStyled>
      ),
    },
    {
      accessor: 'name',
      Header: 'Opportunity Name',
    },
    {
      accessor: 'currencyCode',
      Header: 'Currency',
    },
    {
      accessor: 'opportunityType',
      Header: 'Project Type',
    },
    {
      accessor: 'opportunityStatus',
      Cell: OpportunityStatusCell,
      Header: 'Status',
    },
  ])
  const opportunityScenarioColumns = useMemo(() => [
    {
      accessor: 'redaptiveOpportunityId',
      Header: 'Opportunity ID',
      disableFilters: true,
      Cell: ({
        row: {
          canExpand,
          isExpanded,
          depth,
          getToggleRowExpandedProps,
          original: { externalId },
        },
        value,
      }: any) => {
        if (canExpand) {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <OpportunityCellStyled {...getToggleRowExpandedProps()}>
              <ExpanderCellStyled>
                <NameLinkStyled
                  href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${externalId}`}
                >
                  {value}
                </NameLinkStyled>
                {isExpanded && <ChevronUp />}
                {!isExpanded && <ChevronDown />}
              </ExpanderCellStyled>
            </OpportunityCellStyled>
          )
        }

        if (!depth) {
          return (
            <NameLinkStyled
              href={`/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${externalId}`}
            >
              {value}
            </NameLinkStyled>
          )
        }

        return depth ? '' : value
      },
    },
    {
      accessor: 'name',
      Cell: ScenarioNameExpandedCell,
      disableFilters: true,
      Header: 'Scenario ID',
    },
    {
      accessor: 'contractTermMonths',
      Cell: ExpandedCell,
      disableFilters: true,
      Header: 'contractTermMonths',
    },
    {
      accessor: 'totalProposedFixtureCount',
      Cell: ExpandedCell,
      disableFilters: true,
      Header: 'Total Proposed Fixture Count',
    },
    {
      accessor: 'grossMargin',
      Cell: DollarCell,
      disableFilters: true,
      Header: 'Margin $',
    },
    {
      accessor: 'grossMarginInPercentage',
      Cell: PercentageCell,
      disableFilters: true,
      Header: 'Margin %',
    },
    {
      accessor: 'energyRetainedSavingsInPercentage',
      Cell: PercentageCell,
      disableFilters: true,
      Header: 'Retained Savings',
    },
    {
      accessor: 'initialRebateAmount',
      Cell: DollarCell,
      disableFilters: true,
      Header: 'Initial Rebate Amount',
    },
  ])
  const handleOpportunitySortClick = useCallback(() => {
    if (tableInstanceRef.current) {
      tableInstanceRef.current.toggleAllRowsExpanded(false)
    }

    setSortOpportunityDesc((prevState) => !prevState)
  }, [sortOpportunityDesc])
  const tableHeaderRows: Array<FTTableHeaderRow> = useMemo(
    () => [
      {
        id: 'row1',
        headers: [
          {
            handleSortClick: handleOpportunitySortClick,
            id: 'redaptiveOpportunityId',
            label: 'Opportunity ID',
            minWidth: 120,
            thStyles: OpportunityHeaderStyles,
          },
          {
            handleSortClick,
            id: 'name',
            label: 'Scenario ID',
            minWidth: 120,
            thStyles: ScenarioStyles,
          },
          {
            handleSortClick,
            id: 'contractTermMonths',
            label: 'Contract Term Months',
            minWidth: 120,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'totalProposedFixtureCount',
            label: 'Total Proposed Fixture Count',
            minWidth: 120,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'grossMargin',
            label: 'Margin $',
            minWidth: 120,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'grossMarginInPercentage',
            label: 'Margin %',
            minWidth: 120,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'energyRetainedSavingsInPercentage',
            label: 'Retained Savings',
            minWidth: 120,
            thStyles: FilterStyles,
          },
          {
            handleSortClick,
            id: 'initialRebateAmount',
            label: 'Initial Rebate Amount',
            minWidth: 120,
            thStyles: FilterStyles,
          },
        ],
      },
    ],
    [handleSortClick],
  )
  const TableHeadComponent = useCallback(
    () => <TableHead rows={tableHeaderRows} />,
    [tableHeaderRows],
  )

  const renderOpportunityScenarios = () => {
    if (opportunitiesLoading) {
      return (
        <SpinnerStyles>
          <Spinner />
        </SpinnerStyles>
      )
    }

    return (
      <RedaptiveReactTable7
        autoResetExpanded={false}
        columns={opportunityScenarioColumns}
        data={sortedOpportunityScenarios}
        globalFilterable={false}
        showTableScrollArrows={false}
        TdComponent={TdStyledStyled}
        tableInstanceRef={tableInstanceRef}
        TableHead={TableHeadComponent}
      />
    )
  }

  const RenderOpportunitySummaries = memo(() => (
    <RedaptiveReactTable7
      columns={opportunitySummaryColumns}
      data={filteredOpportunitySummaries}
      defaultSort={[
        {
          id: 'redaptiveOpportunityId',
          desc: false,
        },
      ]}
      globalFilterable={false}
      enableRowSelection
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  ))

  const onCancel = () => {
    history.push(
      `/proposal-operations/proposals-engine/${salesforceCustomerId}`,
    )
  }

  const handleGoToStep2Click = useCallback(() => {
    const selectedOpportunityIds = selectedRowKeys.map(
      (key) => filteredOpportunitySummaries[key].externalId,
    )
    actions.fetchProposalOpportunities({
      opportunityIds: selectedOpportunityIds,
    })
    setOpportunitiesSelected(!opportunitiesSelected)
  }, [selectedRowKeys])

  const handleCreateBatchClick = () => {
    if (!batchName) {
      actions.showMessage({
        messageId: 'emptyBatchName',
        title: 'Please enter a batch name',
        type: 'error',
      })
      setTimeout(() => actions.hideMessage('emptyBatchName'), 6000)
    } else if (
      existingBatchNames.includes(batchName.split(' ').join('').toLowerCase())
    ) {
      actions.showMessage({
        messageId: 'duplicateBatchName',
        title: 'Batch name already exists. Please enter a different name',
        type: 'error',
      })
      setTimeout(() => actions.hideMessage('duplicateBatchName'), 6000)
    } else {
      actions.createProposalBatch({
        salesforceCustomerId,
        name: batchName,
        projectType: 'LIGHTING',
        opportunities: filteredOpportunities.map((item) => ({
          ...item,
          selectedInBatchAnalysis: true,
        })),
        redaptiveOpportunityIds: [],
      })
      setWaitingForAPI(true)
    }
  }

  const EditableBatchName = () => {
    const handleSubmit = (event) => {
      event.preventDefault()
    }

    return (
      <form onSubmit={(event) => handleSubmit(event)}>
        <InputStyles
          autoFocus
          value={batchName}
          placeholder='Batch name'
          onChange={(event) => setBatchName(event.target.value)}
        />
      </form>
    )
  }

  useEffect(() => {
    const availableFilters = currencyFilterActions.filter((action) =>
      availableCurrenciesInOpportunity().includes(action.label),
    )
    setAvailableCurrencyFilters(availableFilters)
  }, [opportunitySummaries])

  useEffect(() => {
    if (availableCurrencyFilters && availableCurrencyFilters.length) {
      setCurrentCurrency(availableCurrencyFilters[0].label)
    }
  }, [availableCurrencyFilters])

  const HeaderActions = () => (
    <ButtonContainerStyles>
      <Button2 onClick={onCancel}>Cancel</Button2>
      {!opportunitiesSelected ?
        <Button2
          type='primary'
          disabled={!selectedRowKeys.length}
          onClick={handleGoToStep2Click}
        >
          Goto step 2
        </Button2>
      : <Button2
          type='primary'
          loading={waitingForAPI}
          onClick={handleCreateBatchClick}
        >
          Create Batch
        </Button2>
      }
    </ButtonContainerStyles>
  )

  return (
    <CreateBatchStyles>
      <BreadcrumbsStyles>
        <Breadcrumbs2 items={breadcrumbs} />
      </BreadcrumbsStyles>
      <CustomerListingStyles>
        {!opportunitySummaries.length && (
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        )}
        {!!opportunitySummaries.length && (
          <>
            <PageHeader
              Title={EditableBatchName}
              Actions={HeaderActions}
              handleBackNavigation={
                opportunitiesSelected ?
                  () => setOpportunitiesSelected(!opportunitiesSelected)
                : () => history.goBack()
              }
            />
            <FormValidatorWrapperStyles>
              <ValidatorWrapperStyles>
                <FormTabStyled>
                  <TabStyled
                    completed={!!selectedRowKeys.length}
                    selected={!opportunitiesSelected}
                  >
                    <TabCountStyled completed={!!selectedRowKeys.length}>
                      1
                    </TabCountStyled>
                  </TabStyled>
                  Select Opportunities
                </FormTabStyled>
                --------
                <FormTabStyled>
                  <TabStyled selected={opportunitiesSelected} completed={false}>
                    <TabCountStyled completed={false}>2</TabCountStyled>
                  </TabStyled>
                  Verify & Create Batch
                </FormTabStyled>
              </ValidatorWrapperStyles>
              <CurrencyDropDownWrapper>
                {availableCurrencyFilters?.length && (
                  <DropdownMenuNew
                    actions={availableCurrencyFilters}
                    bgColor={colors.white}
                    borderColor={opportunitiesSelected ? '#7F7F7F' : '#3E5CAA'}
                    borderOnClose
                    defaultValue={availableCurrencyFilters[0].value}
                    fontSize='12px'
                    textColor='#3E5CAA'
                    showSelected
                    disabled={opportunitiesSelected}
                  />
                )}
              </CurrencyDropDownWrapper>
            </FormValidatorWrapperStyles>
            {!opportunitiesSelected ?
              <RenderOpportunitySummaries />
            : renderOpportunityScenarios()}
          </>
        )}
      </CustomerListingStyles>
    </CreateBatchStyles>
  )
}

const mapStateToProps = (state) => {
  const batchSummariesEntity = selectProposalBatchSummariesEntity(state)
  const batchDetailsEntity = selectProposalBatchesEntity(state)
  const customerSummariesEntity = selectProposalCustomerSummariesEntity(state)
  const opportunitySummariesEntity =
    selectProposalOpportunitySummariesEntity(state)
  const opportunitiesEntity = selectProposalOpportunitiesEntity(state)
  const { items: batchSummaries } = batchSummariesEntity
  const { byId: customerSummariesById } = customerSummariesEntity
  const { items: opportunitySummaries } = opportunitySummariesEntity
  const {
    meta: { loading: opportunitiesLoading },
    items: opportunities,
  } = opportunitiesEntity
  return {
    batchDetails: batchDetailsEntity.items,
    batchSummaries,
    customerSummariesById,
    opportunities,
    opportunitiesLoading,
    opportunitySummaries,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(batchActions, dispatch),
    ...bindActionCreators(batchSummaryActions, dispatch),
    ...bindActionCreators(customerSummariesActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
    ...bindActionCreators(opportunityActions, dispatch),
    ...bindActionCreators(opportunitySummariesActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewBatch)
