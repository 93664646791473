export default class SearchInputEvent {
  static functionKeys = /F\d\d?/

  static printableCharacters = /\w+/

  static modifierKeys = [
    'Fn',
    'Hyper',
    'OS',
    'Super',
    'Win',
    'Control',
    'Alt',
    'Meta',
    'Accel',
    'ScrollLock',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'CapsLock',
    'NumLock',
    'Home',
    'PageUp',
    'PageDown',
    'End',
    'Tab',
    'Clear',
    'Enter',
    'Escape',
  ]

  constructor(event, previousTerm, persist = false) {
    if (persist) {
      event.persist()
    }

    this.event = event
    this.previousTerm = previousTerm
  }

  hasModifierKey = () => {
    const { key, shiftKey, altKey, ctrlKey, metaKey } = this.event

    if (
      SearchInputEvent.functionKeys.exec(key) ||
      shiftKey ||
      altKey ||
      ctrlKey ||
      metaKey
    ) {
      return true
    }

    return SearchInputEvent.modifierKeys.includes(key)
  }

  isValidSearch() {
    const {
      target: { value: term },
    } = this.event
    const hasModifierKey = this.hasModifierKey()

    if (hasModifierKey) {
      return false
    }

    return (
      SearchInputEvent.printableCharacters.test(term) ||
      (term.length === 0 && this.previousTerm.length > 0)
    )
  }
}
