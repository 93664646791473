import CheckIcon from '../../../../components/Icons/CheckIcon'
import { useSubmissionsDataQuery } from '../../../../queries/energyStar'
import { TabCheckedStyled, TabCountStyled } from '../../styles'
import getDataByTabs from '../helpers/getDataByTabs'

const TabTextSuffix = ({ tab }: { tab: { tabId: string } }) => {
  const submissionsData = useSubmissionsDataQuery()
  const { total } = getDataByTabs(submissionsData.data ?? [])[tab.tabId]
  const checked = total === 0

  if (checked) {
    return (
      <TabCheckedStyled data-testid={`tab-text-suffix-${tab.tabId}`}>
        <CheckIcon />
      </TabCheckedStyled>
    )
  }

  return (
    <TabCountStyled data-testid={`tab-text-suffix-${tab.tabId}`}>
      {total}
    </TabCountStyled>
  )
}

export default TabTextSuffix
