export default () => (
  <svg
    width='39'
    height='37'
    viewBox='0 0 39 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group'>
      <path
        id='Vector'
        d='M19.9716 4.08105C17.7408 4.08105 15.9482 5.87362 15.9482 8.10437C15.9482 10.3351 17.7408 12.1277 19.9716 12.1277C22.2023 12.1277 23.9949 10.3351 23.9949 8.10437C23.9949 5.87362 22.2023 4.08105 19.9716 4.08105Z'
        fill='#337AB7'
      />
      <path
        id='Vector_2'
        d='M35.3876 19.816V3.08536C35.3876 1.6513 34.2324 0.496094 32.7983 0.496094H3.00186C1.56781 0.496094 0.412598 1.6513 0.412598 3.08536V25.592C0.412598 27.0261 1.56781 28.1813 3.00186 28.1813H20.6487C20.7284 28.6992 20.8479 29.217 21.0072 29.7349C22.2421 33.5192 25.7077 36.0686 29.731 36.0686C34.7901 36.0686 38.893 31.9656 38.893 26.9066C38.8134 24.158 37.5785 21.5687 35.3876 19.816ZM22.0827 14.1993L19.7723 16.0317L15.9083 11.8092C15.4303 11.2913 14.7531 10.9727 14.0361 10.9727C14.0361 10.9727 14.0361 10.9727 13.9963 10.9727C13.2792 10.9727 12.6021 11.2515 12.124 11.7694L3.9579 20.3737C3.67905 20.6525 3.59938 21.0907 3.75872 21.4492C3.91806 21.8077 4.27658 22.0467 4.67492 22.0467H21.9632C21.2064 23.2816 20.6885 24.6758 20.569 26.1896H3.00186C2.68318 26.1896 2.40434 25.9107 2.40434 25.592V3.08536C2.40434 2.76668 2.68318 2.48784 3.00186 2.48784H32.7983C33.117 2.48784 33.3958 2.76668 33.3958 3.08536V18.5811C32.2406 18.0633 30.9659 17.7844 29.6912 17.7844C29.492 17.7844 29.2928 17.7844 29.0937 17.8242L25.5085 14.3586C24.5525 13.4822 23.0786 13.4026 22.0827 14.1993ZM33.356 27.9423H30.6472V30.6511C30.6472 31.2087 30.209 31.6469 29.6514 31.6469C29.0937 31.6469 28.6555 31.2087 28.6555 30.6511V27.9423H25.9866C25.4289 27.9423 24.9907 27.5041 24.9907 26.9464C24.9907 26.3887 25.4289 25.9506 25.9866 25.9506H28.6953V23.2418C28.6953 22.6841 29.1335 22.2459 29.6912 22.2459C30.2489 22.2459 30.6871 22.6841 30.6871 23.2418V25.9506H33.3958C33.9535 25.9506 34.3917 26.3887 34.3917 26.9464C34.3917 27.5041 33.9137 27.9423 33.356 27.9423Z'
        fill='#337AB7'
      />
    </g>
  </svg>
)
