import { withFormik } from 'formik'
import * as Yup from 'yup'

import Form from './Form'
import { fieldNameMap } from '../../ducks/meters/generation'
import { validAmperageValues } from '../../utils/panelConfiguration'
import BottomToTop from '../Icons/svg/BottomToTop'
import ILine from '../Icons/svg/ILine'
import TopToBottom from '../Icons/svg/TopToBottom'

const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    voltage: Yup.string().required('Required'),
    leftPanelOrder: Yup.string().when('numberOfCircuits', {
      is: (numberOfCircuits) => numberOfCircuits > 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    rightPanelOrder: Yup.string().when('numberOfCircuits', {
      is: (numberOfCircuits) => numberOfCircuits > 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    phasingPattern: Yup.string().when('numberOfCircuits', {
      is: (numberOfCircuits) => numberOfCircuits > 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
  })

const validPhasingPatterns = [
  {
    id: 'TOP_TO_BOTTOM',
    name: 'Top to Bottom',
    icon: <TopToBottom />,
  },
  {
    id: 'BOTTOM_TO_TOP',
    name: 'Bottom to Top',
    icon: <BottomToTop />,
  },
  {
    id: 'I_LINE',
    name: 'I-Line',
    icon: <ILine />,
  },
]
const validNumberOrders = [
  {
    id: 'ODD',
    name: 'Odd Numbers  (1,3,5,7,9........41)',
  },
  {
    id: 'EVEN',
    name: 'Even Numbers (2,4,6,8,10,.....42)',
  },
  {
    id: 'CONSECUTIVE',
    name: 'Consecutive Number (1,2,3,4,5,......42)',
  },
]
const validPanelTypes = [
  {
    id: 'SINGLE_PHASE_3_WIRE',
    name: fieldNameMap.get('SINGLE_PHASE_3_WIRE'),
  },
  {
    id: 'THREE_PHASE_3_WIRE_DELTA',
    name: fieldNameMap.get('THREE_PHASE_3_WIRE_DELTA'),
  },
  {
    id: 'THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA',
    name: fieldNameMap.get('THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA'),
  },
  {
    id: 'THREE_PHASE_3_WIRE_WYE',
    name: fieldNameMap.get('THREE_PHASE_3_WIRE_WYE'),
  },
  {
    id: 'THREE_PHASE_4_WIRE_DELTA',
    name: fieldNameMap.get('THREE_PHASE_4_WIRE_DELTA'),
  },
  {
    id: 'THREE_PHASE_4_WIRE_WYE',
    name: fieldNameMap.get('THREE_PHASE_4_WIRE_WYE'),
  },
  {
    id: 'OTHER',
    name: fieldNameMap.get('OTHER'),
  },
]
const validPanelVoltages = {
  SINGLE_PHASE_3_WIRE: [
    {
      id: '_120_240V',
      name: fieldNameMap.get('_120_240V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_4_WIRE_WYE: [
    {
      id: '_208Y_120V',
      name: fieldNameMap.get('_208Y_120V'),
    },
    {
      id: '_400Y_230V',
      name: fieldNameMap.get('_400Y_230V'),
    },
    {
      id: '415Y_240V',
      name: fieldNameMap.get('_415Y_240V'),
    },
    {
      id: '_480Y_277V',
      name: fieldNameMap.get('_480Y_277V'),
    },
    {
      id: '_600Y_347V',
      name: fieldNameMap.get('_600Y_347V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_3_WIRE_DELTA: [
    {
      id: '_230V',
      name: fieldNameMap.get('_230V'),
    },
    {
      id: '_240V',
      name: fieldNameMap.get('_240V'),
    },
    {
      id: '_400V',
      name: fieldNameMap.get('_400V'),
    },
    {
      id: '_480V',
      name: fieldNameMap.get('_480V'),
    },
    {
      id: '_600V',
      name: fieldNameMap.get('_600V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_4_WIRE_DELTA: [
    {
      id: '_120_208_240V_SINGLE_PHASE',
      name: fieldNameMap.get('_120_208_240V_SINGLE_PHASE'),
    },
    {
      id: '_120_208_240V_THREE_PHASE',
      name: fieldNameMap.get('_120_208_240V_THREE_PHASE'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_3_WIRE_WYE: [
    {
      id: '_480V',
      name: fieldNameMap.get('_480V'),
    },
    {
      id: '_600V',
      name: fieldNameMap.get('_600V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA: [
    {
      id: '_240V',
      name: fieldNameMap.get('_240V'),
    },
    {
      id: '_480V',
      name: fieldNameMap.get('_480V'),
    },
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
  OTHER: [
    {
      id: 'OTHER',
      name: fieldNameMap.get('OTHER'),
    },
  ],
}
const validPanelLevels = [
  {
    id: 'MDP',
    name: 'MDP',
  },
  {
    id: 'SUB PANEL',
    name: 'SUB PANEL',
  },
]
export default withFormik({
  validationSchema: getValidationSchema,

  validate(values, props) {
    const { id, conditionalFields: { panelListEntity: { items = [] } } = {} } =
      props
    const { numberOfCircuits } = values
    let errors = {}

    if (numberOfCircuits === 1) {
      errors = {
        numberOfCircuits: "Can't create a panel with one circuit",
      }
    }

    const existingPanels = id ? items.filter((item) => item.id !== id) : items
    const panelNameSet =
      existingPanels.length ?
        new Set(
          existingPanels.map((panel) => {
            const { name, location } = panel
            return location ?
                `${name.trim()}-${location.trim()}`
              : `${name.trim()}`
          }),
        )
      : new Set()
    const panel =
      values.location ?
        `${values.name.trim()}-${values.location.trim()}`
      : `${values.name.trim()}`
    return panelNameSet.has(panel) ?
        {
          ...errors,
          name: 'A panel already exists with the same name and location',
        }
      : errors
  },

  handleSubmit(values, formikBag) {
    const {
      props: { submitAction, siteId },
    } = formikBag
    submitAction(
      {
        siteId,
        ...values,
      },
      formikBag,
    )
  },

  mapPropsToValues(props) {
    const {
      description = '',
      id,
      meterIds,
      name = '',
      siteId,
      type = '',
      voltage = '',
      location = '',
      amperage = '',
      parentPanelId = '',
      parentPanelName = '',
      panelLevel = '',
    } = props
    return {
      description,
      id,
      meterIds,
      name,
      siteId,
      type,
      voltage,
      location,
      amperage,
      numberOfCircuits: 0,
      leftPanelOrder: '',
      rightPanelOrder: '',
      phasingPattern: '',
      parentPanelId,
      parentPanelName,
      panelLevel,
    }
  },

  mapPropsToStatus() {
    return {
      validPanelTypes,
      validPanelVoltages,
      validPhasingPatterns,
      validNumberOrders,
      validAmperageValues,
      validPanelLevels,
    }
  },
})(Form)
