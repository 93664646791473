import styled from 'styled-components'

const PortalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
`

const PortalContainer = () => (
  <PortalWrapper className='portalWrapper'>
    <div className='portalContentContainer' />
  </PortalWrapper>
)

export default PortalContainer
