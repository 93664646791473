/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import styled from 'styled-components'

import MetersDropdown from './MetersDropdown'
import NoResults from './NoResults'
import PageSizeSelector from './PageSizeSelector'
import RolesDropdown from './RolesDropdown'
import SearchBar from './SearchBar'
import type { FTEntity, FTFilterBy, FTSearchBy } from '../../ducks/types'
import '../../ducks/types'
import { MIN_PAGE_SIZE } from '../../ducks/utils'
import CheckBox from '../Checkbox'
import Description from '../Description'
import ErrorMessage from '../ErrorMessage'
import { navbarHeight } from '../Navbar'
import Spinner from '../Spinner'
import Table from '../Table'
import type {
  FTRenderHeaderActionFunc,
  FTRenderTableRowFunc,
  FTTableHeaderType,
} from '../Table'

type FTProps = {
  entity: FTEntity
  loadEntity: (...args: Array<any>) => any
  loadEntityProps?: Record<string, any>
  tableHeaders: Array<FTTableHeaderType>
  renderTableRow: FTRenderTableRowFunc
  renderHeaderAction?: FTRenderHeaderActionFunc
  renderHeader?: () => React.ReactNode
  renderNoResults?: () => React.ReactNode
  showPageSizer?: boolean
  showSearchBar?: boolean
  enableSorting?: boolean
  description?: string
  striped?: boolean
  stripeColor?: string
  filterParam?: string
  searchParam?: string
  showRolesFilter?: boolean
  showMetersFilter?: boolean
  showCheckbox?: boolean
  downloadEntity?: () => React.ReactNode
  checkboxLabel?: string
  updateCheckboxValue?: (val: boolean) => void
  flagResetters?: Array<(...args: Array<any>) => any>
  hideAllPagination?: boolean
  paginationSize?: Array<number>
}
const StyledEntityList = styled.div`
  & a[href] {
    color: #337ab7;
  }

  & a[href]:hover {
    text-decoration: underline;
  }
`
const Header = styled.div`
  display: flex;
  min-height: ${({ showHeader }) => (showHeader ? '44px' : 0)};
  position: relative;
`
const LoadingIndicator = styled.div`
  & .Spinner {
    margin: 0 auto;
  }
`

const CheckboxLabel = styled.div`
  margin: 8px 8px 8px 0;
  span {
    font-weight: 600;
    font-size: 14px;
    margin-right: 5px;
  }
`

export default class EntityListPane extends React.PureComponent<FTProps> {
  entityListRef: any

  constructor(props: FTProps) {
    super(props)
    this.entityListRef = React.createRef()
  }

  static defaultProps = {
    loadEntityProps: {},
    showPageSizer: true,
    showSearchBar: true,
    enableSorting: true,
    renderHeaderAction: undefined,
    renderHeader: undefined,
    renderNoResults: undefined,
    description: '',
    striped: false,
    stripeColor: '',
    searchParam: 'filterName',
    filterParam: '',
    hideAllPagination: false,
  }

  onChangeSort = (field: string, sort: string) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
    } = this.props
    loadEntity({
      ...loadEntityProps,
      ...meta,
      orderBy: {
        field,
        sort,
      },
    })

    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  onChangePage = (pageNumber: number) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
    } = this.props
    window.scrollTo(
      0,
      this.entityListRef.current.getBoundingClientRect().y +
        window.scrollY -
        navbarHeight -
        20,
    )
    loadEntity({ ...loadEntityProps, ...meta, pageNumber })

    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  onChangePageSize = (pageSize: number) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
    } = this.props
    loadEntity({ ...loadEntityProps, ...meta, pageSize, pageNumber: 1 })

    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  onSearch = (searchBy: FTSearchBy) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
    } = this.props
    loadEntity({ ...loadEntityProps, ...meta, searchBy, pageNumber: 1 })

    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  onFilter = (filterBy: FTFilterBy) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
    } = this.props
    loadEntity({ ...loadEntityProps, ...meta, filterBy, pageNumber: 1 })

    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  onCheckboxChange = (e: Event) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
      updateCheckboxValue,
    } = this.props

    loadEntity({
      ...loadEntityProps,
      ...meta,
      excludeRedaptiveEmail: e?.target?.checked,
      pageNumber: 1,
    })
    if (updateCheckboxValue) {
      updateCheckboxValue(e?.target?.checked)
    }
    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  renderLoadingFresh = () => {
    const { entity } = this.props
    const {
      items = [],
      meta: { loading },
    } = entity

    if (!loading || items.length > 0) {
      return null
    }

    return (
      <LoadingIndicator>
        <Spinner />
      </LoadingIndicator>
    )
  }

  renderBody = () => {
    const {
      entity,
      renderTableRow,
      renderNoResults,
      renderHeaderAction,
      enableSorting,
      ...props
    } = this.props
    const {
      items = [],
      meta: { pageNumber, pageSize, orderBy, previous, prev, ...meta },
    } = entity
    const { loading, error } = meta
    return (
      <div className='EntityListPane-body'>
        {renderNoResults && this.renderLoadingFresh()}
        {(items.length > 0 || !renderNoResults) && (
          <Table
            {...props}
            {...meta}
            page={pageNumber}
            perPage={pageSize}
            prev={previous || prev}
            items={items}
            browseToPage={this.onChangePage}
            updateSort={this.onChangeSort}
            currentSort={
              enableSorting && items.length > 0 ? orderBy : undefined
            }
            renderTableRow={renderTableRow}
            renderHeaderAction={renderHeaderAction}
          />
        )}
        {!loading &&
          !error &&
          items.length < 1 &&
          renderNoResults &&
          renderNoResults()}
      </div>
    )
  }

  render() {
    const {
      entity: { items, meta },
      description,
      showPageSizer,
      showSearchBar,
      renderHeader,
      filterParam = '',
      searchParam,
      showRolesFilter,
      showMetersFilter,
      hideAllPagination,
      paginationSize,
      showCheckbox,
      checkboxLabel,
      downloadEntity,
    } = this.props
    const { error, totalCount = 0, pageSize = 0, filterBy, searchBy } = meta
    return (
      <StyledEntityList className='EntityListPane' ref={this.entityListRef}>
        {!renderHeader && (
          <Header
            className='EntityListPane-header'
            showHeader={
              totalCount >= MIN_PAGE_SIZE && (showSearchBar || showPageSizer)
            }
          >
            {showSearchBar && (
              <SearchBar
                onSearch={this.onSearch}
                filterField={searchParam}
                previous={searchBy}
              />
            )}
            {showRolesFilter && (
              <RolesDropdown
                onSelect={this.onFilter}
                filterField={filterParam}
                previous={filterBy}
              />
            )}
            {showMetersFilter && (
              <MetersDropdown
                onSelect={this.onFilter}
                filterField={filterParam}
                previous={filterBy}
              />
            )}
            {showPageSizer && items.length > 0 && (
              <PageSizeSelector
                onUpdate={this.onChangePageSize}
                total={totalCount}
                currentSize={pageSize}
                hideAllPagination={hideAllPagination}
                paginationSize={paginationSize}
              />
            )}
            {showCheckbox && (
              <CheckboxLabel>
                <span>{checkboxLabel}: </span>
                <CheckBox type='checkbox' onChange={this.onCheckboxChange} />
              </CheckboxLabel>
            )}
            {downloadEntity && downloadEntity()}
          </Header>
        )}
        {renderHeader && renderHeader()}
        {description && (
          <Description className='EntityListPane-description'>
            {description}
          </Description>
        )}
        <ErrorMessage message={error} />
        {this.renderBody()}
      </StyledEntityList>
    )
  }
}
export { NoResults }
