import styled from 'styled-components'

const CloseButton = styled.div.attrs({
  className: 'ion-android-close',
})`
  color: #000000;
  cursor: pointer;
  font-size: 20px;
`
export default CloseButton
