import { FormikProps } from 'formik'

import {
  FieldContainerStyled,
  InputStyled,
  LabelStyled,
  LableSupportText,
  PageBodyStyled,
  PageHeaderStyled,
  PanelFieldValues,
} from './commons'
import BottomToTop from '../Icons/svg/BottomToTop'
import ILine from '../Icons/svg/ILine'
import TopToBottom from '../Icons/svg/TopToBottom'
import ListSelector from '../ListSelector'

type StepProps = {
  formik: FormikProps<PanelFieldValues>
  updateMode: boolean
}
const PanelLayoutForm = (props: StepProps) => {
  const validPhasingPatterns = [
    {
      id: 'TOP_TO_BOTTOM',
      name: 'Top to Bottom',
      icon: <TopToBottom />,
    },
    {
      id: 'BOTTOM_TO_TOP',
      name: 'Bottom to Top',
      icon: <BottomToTop />,
    },
    {
      id: 'I_LINE',
      name: 'I-Line',
      icon: <ILine />,
    },
  ]
  const validNumberOrders = [
    {
      id: 'ODD',
      name: 'Odd Numbers  (1,3,5,7,9........41)',
    },
    {
      id: 'EVEN',
      name: 'Even Numbers (2,4,6,8,10,.....42)',
    },
    {
      id: 'CONSECUTIVE',
      name: 'Consecutive Number (1,2,3,4,5,......42)',
    },
  ]

  const { formik, updateMode } = props
  const fieldsDisabled = updateMode

  // selected list selector items
  const selectedLeftPanel = validNumberOrders.find(
    (opt) => opt.id === formik.values.leftSidePanel,
  )
  const selectedRightPanel = validNumberOrders.find(
    (opt) => opt.id === formik.values.rightSidePanel,
  )
  const selectedPhasingPattern = validPhasingPatterns.find(
    (opt) => opt.id === formik.values.phasingPattern,
  )
  const bodyForNumberedPanels = (
    <PageBodyStyled className='last'>
      <FieldContainerStyled>
        <LabelStyled>
          Number of Circuits<span>*</span>
        </LabelStyled>
        <LableSupportText>
          How many number of circuits are present on the panel
        </LableSupportText>
        <InputStyled
          name='numberOfCircuits'
          onChange={formik.handleChange}
          value={formik.values.numberOfCircuits}
          error={
            formik.touched.numberOfCircuits && formik.errors.numberOfCircuits ?
              formik.errors.numberOfCircuits
            : ''
          }
          onBlur={formik.handleBlur}
          field={{ type: 'number', min: 0 }}
          disabled={fieldsDisabled}
          shiftError
        />
      </FieldContainerStyled>
      <FieldContainerStyled>
        <LabelStyled>
          Left Side Panel<span>*</span>
        </LabelStyled>
        <LableSupportText>
          What breaker number are in use down the left side of the panel?
        </LableSupportText>
        <ListSelector
          items={validNumberOrders}
          notSetItemText='---'
          notSetLabelText='Select Number Order'
          selectedItem={selectedLeftPanel}
          updateValue={({ value }) => {
            // formik.setFieldValue('leftSidePanel', value)
            // formik.setFieldTouched('leftSidePanel', true)
            formik.setFieldValue('leftSidePanel', value).then(() => {
              formik.setFieldTouched('leftSidePanel', true)
            })
            if (value === 'ODD') {
              formik.setFieldValue('rightSidePanel', 'EVEN').then(() => {
                formik.setFieldTouched('rightSidePanel', true)
              })
            }
            if (value === 'EVEN') {
              formik.setFieldValue('rightSidePanel', 'ODD').then(() => {
                formik.setFieldTouched('rightSidePanel', true)
              })
              // formik.setFieldValue('rightSidePanel', 'ODD')
              // formik.setFieldTouched('leftSidePanel', true)
            }
            if (value === 'CONSECUTIVE') {
              // formik.setFieldValue('rightSidePanel', 'CONSECUTIVE')
              // formik.setFieldTouched('leftSidePanel', true)
              formik.setFieldValue('rightSidePanel', 'CONSECUTIVE').then(() => {
                formik.setFieldTouched('rightSidePanel', true)
              })
            }
            formik.validateForm()
          }}
          disabled={fieldsDisabled}
        />
      </FieldContainerStyled>
      <FieldContainerStyled>
        <LabelStyled>
          Right Side Panel<span>*</span>
        </LabelStyled>
        <LableSupportText>
          What breaker number are in use down the Right side of the panel?
        </LableSupportText>
        <ListSelector
          items={validNumberOrders}
          notSetItemText='---'
          notSetLabelText='Select Number Order'
          selectedItem={selectedRightPanel}
          updateValue={({ value }) => {
            formik.setFieldValue('rightSidePanel', value).then(() => {
              formik.setFieldTouched('rightSidePanel', true)
            })
            if (value === 'ODD') {
              formik.setFieldValue('leftSidePanel', 'EVEN')
            }
            if (value === 'EVEN') {
              formik.setFieldValue('leftSidePanel', 'ODD')
            }
            if (value === 'CONSECUTIVE') {
              formik.setFieldValue('leftSidePanel', 'CONSECUTIVE')
            }
            formik.validateForm()
          }}
          disabled={fieldsDisabled}
        />
      </FieldContainerStyled>
      <FieldContainerStyled>
        <LabelStyled>Phasing Pattern</LabelStyled>
        <LableSupportText>
          What phasing pattern is used in the panel?
        </LableSupportText>
        <ListSelector
          customDropdown
          notSetItemText='----'
          items={validPhasingPatterns}
          selectedItem={selectedPhasingPattern}
          notSetLabelText='Select Phasing Pattern'
          updateValue={({ value }) => {
            formik.setFieldValue('phasingPattern', value).then(() => {
              formik.setFieldTouched('phasingPattern', true)
            })
            formik.validateForm()
          }}
          disabled={fieldsDisabled}
        />
      </FieldContainerStyled>
    </PageBodyStyled>
  )

  const bodyForNonNumberedPanels = (
    <PageBodyStyled className='last'>
      <FieldContainerStyled>
        <LabelStyled>
          Number of Switches<span>*</span>
        </LabelStyled>
        <LableSupportText>
          How many number of switches are present on the panel
        </LableSupportText>
        <InputStyled
          name='numberOfSwitches'
          onChange={formik.handleChange}
          value={formik.values.numberOfSwitches}
          error={
            formik.touched.numberOfSwitches && formik.errors.numberOfSwitches ?
              formik.errors.numberOfSwitches
            : ''
          }
          onBlur={formik.handleBlur}
          field={{ type: 'number', min: 0 }}
          disabled={fieldsDisabled}
        />
      </FieldContainerStyled>
    </PageBodyStyled>
  )
  return (
    <>
      <PageHeaderStyled>
        <p>Breaker Layout</p>
        <div />
      </PageHeaderStyled>
      {formik.values.isNumbered ?
        bodyForNumberedPanels
      : bodyForNonNumberedPanels}
    </>
  )
}

export default PanelLayoutForm
