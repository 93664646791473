import { withFormik } from 'formik'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import FeatureValidator from '../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../authorization/features'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import CloseButtonTableSubComponent from '../../components/CloseButtonTableSubComponent'
import Description from '../../components/Description'
import IconButton from '../../components/IconButton'
import IonIcon from '../../components/IonIcon'
import MeterMostRecentMeasurementData from '../../components/MeterMostRecentMeasurementData'
import RedaptiveReactTable, {
  booleanFilter,
} from '../../components/RedaptiveReactTable'
import RTMeterStatusForm from '../../components/RTMeterStatusForm'
import StatusIconItem from '../../components/StatusIconItem'
import Link from '../../components/StyledLink'
import Title from '../../components/Title'
import type {
  FTMeterStatus,
  FTRTMeterStatusListEntity,
} from '../../ducks/meterStatus'
import {
  actions as meterStatusActions,
  selectRTMeterListEntity,
} from '../../ducks/meterStatus'
import '../../types'
import type { FTHistory } from '../../types'
import { normalizeMACAddress, validateMACAddress } from '../../utils'

const FormWidth = styled.div`
  width: 570px;
  @media (max-width: 961px) {
    width: 100%;
  }
`

const ButtonGroup = styled.div`
  margin-top: 80px;
  button + button {
    margin-left: 40px;
  }
`
const StatusTimestamp = styled.span`
  font-weight: 600;
  margin-left: 6px;
  margin-right: 6px;

  & > .IonIcon {
    margin-right: 14px;
  }

  & .IonIcon > span {
    font-size: 14px;
  }
`
const FeatureNotAvailableStyled = styled.div`
  padding: 20px;
  text-align: center;
`
const ResultsHeader = styled.div`
  height: 36px;
  padding: 20px 0 30px 0;

  & > div:first-child {
    float: left;
    min-width: 275px;
    margin-top: 6px;
  }
  & > div:last-child {
    float: right;
  }
`
const Styles = styled.div`
  a {
    width: fit-content;
  }
  b {
    font-weight: 500;
  }
  ${Title} {
    font-weight: 600;
    line-height: 33px;
  }

  ${Description} {
    font-size: 14px;
    line-height: 20px;
    b {
      display: block;
      margin-top: 15px;
    }
  }

  .Table tbody tr td:nth-child(2) {
    max-width: 100px;
  }
`
const FormikMeterForm = withFormik({
  mapPropsToValues({ ids }) {
    const input = ids.reduce((str, cur) => `${str}\n${cur}`, '').slice(1)
    return {
      input,
    }
  },

  handleSubmit({ input }, { props }) {
    const { submitAction } = props
    submitAction({
      ids: input.split('\n').map((id) => (id ? id.replace(/:/g, '') : '')),
    })
  },

  validate: ({ input }) => {
    if (!input || input.length < 12) {
      return {
        input: 'Enter at least one MAC Address. One per line.',
      }
    }

    if (input.includes(',') || input.includes(';')) {
      return {
        input: 'Do not include commas or semicolons.',
      }
    }

    const macs = input.split('\n').filter((m) => m)

    if (macs.length > 50) {
      return {
        input:
          'Cannot search for more than 50 MAC Addresses at once. Try again with lesser number of MACs. One MAC address at a time ',
      }
    }

    for (let i = 0; i < macs.length; i += 1) {
      const macAddress = normalizeMACAddress(macs[i])
      const message = validateMACAddress(macAddress)

      if (message) {
        return {
          input: message,
        }
      }
    }

    return {}
  },
})(RTMeterStatusForm)
type FTProps = {
  error: string
  loading: boolean
  success: boolean
  submission: Array<string>
  history: FTHistory
  actions: Record<string, any>
  match: {
    params: Record<string, any>
  }
  location: {
    pathname: string
  }
  timestamp: moment
  rtMeterListEntity: FTRTMeterStatusListEntity
}
type FTState = {
  initializing: boolean
}

const MacAddressCell = ({ original }: { original: FTMeterStatus }) =>
  (original.exists && original.id && original.id !== original.macAddress && (
    <FeatureValidator
      feature={AuthorizedFeatures.meterDetail}
      onInvalid={() => original.macAddress}
    >
      <Link href={`/account-management/meters/${original.id}`}>
        {original.macAddress}
      </Link>
    </FeatureValidator>
  )) ||
  original.macAddress

class MeterStatusFormPage extends Component<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      initializing: true,
    }
  }

  componentDidMount() {
    this.props.actions.resetRTForm()
    this.setState((prev) => ({ ...prev, initializing: false }))
  }

  componentDidUpdate(prev) {
    const {
      success: prevSuccess,
      location: { pathname: prevPath },
    } = prev
    const {
      success,
      submission,
      history,
      location: { pathname },
      actions,
    } = this.props
    const basePath = '/reports/meter-status'
    const isResultsView = pathname.endsWith('/results')

    if (isResultsView && (!submission || submission.length < 1)) {
      history.push(basePath)
      return
    }

    if (prevSuccess === false && success === true && !isResultsView) {
      history.push(`${basePath}/results`)
    }

    if (prevPath.endsWith('/results') && !pathname.endsWith('/results')) {
      actions.resetRTSubmission()
    }
  }

  componentWillUnmount() {
    this.props.actions.resetRTForm()
  }

  goBack = () => this.props.history.push('/reports/meter-status')

  goHome = () => this.props.history.push('/reports')

  handleSubmit = ({ ids }) => {
    this.props.actions.getRealTimeMeterStatus({
      ids,
    })
  }

  refreshResults = () => {
    const { actions, submission } = this.props
    actions.getRealTimeMeterStatus({
      ids: submission,
    })
  }

  renderResults = () => {
    const columns = [
      {
        accessor: 'macAddress',
        Cell: MacAddressCell,
        Header: 'Meter MAC Address',
        maxWidth: 180,
      },
      {
        accessor: (row: FTMeterStatus) =>
          row.panelNames ? row.panelNames.join(', ') : null,
        Cell: ({ original }) =>
          original.panelNames ? original.panelNames.join(', ') : '',
        Header: 'Panel Name',
        id: 'panelName',
        maxWidth: 110,
      },
      {
        accessor: 'meterType',
        Cell: ({ original }: { original: FTMeterStatus }) =>
          (original.exists &&
            original.id !== original.macAddress &&
            original.meterType) ||
          'Unknown',
        Header: 'Meter Type',
        maxWidth: 100,
      },
      {
        accessor: 'onlineStatus',
        Cell: ({ original }: { original: FTMeterStatus }) =>
          (original.exists && original.id !== original.macAddress && (
            <div>
              <StatusIconItem status={original.onlineStatus} />
            </div>
          )) || <div>Could not find a meter with this MAC address</div>,
        filterMethod: booleanFilter,
        Header: 'Online Status',
      },
      {
        accessor: 'mostRecentReported',
        Cell: ({ original }: { original: FTMeterStatus }) =>
          (original.exists &&
            original.id !== original.macAddress &&
            original.mostRecentReported) ||
          '',
        Header: 'Last Reported',
      },
      {
        accessor: 'lastMeasurementDate',
        Cell: ({ original }: { original: FTMeterStatus }) =>
          (original.exists &&
            original.id !== original.macAddress &&
            original.lastMeasurementDate) ||
          '',
        Header: 'Measurement Data Timestamp',
      },
      {
        accessor: 'firstReported',
        Cell: ({ original }: { original: FTMeterStatus }) =>
          (original.exists &&
            original.id !== original.macAddress &&
            original.firstReported) ||
          '',
        Header: 'First Report',
      },
      {
        expander: true,
        Expander: ({ isExpanded, original: { macAddress, id, type } }) => {
          if (type === 'REDAPTIVE_GAS') {
            return null
          }

          if (id !== macAddress) {
            return isExpanded ?
                <CloseButtonTableSubComponent />
              : <span>Circuit View</span>
          }

          return null
        },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.original.id === rowInfo.original.macAddress) {
            return {
              onClick: () => {},
              style: {
                cursor: 'default',
              },
            }
          }

          return {
            style: {
              cursor: 'pointer',
            },
          }
        },
        Header: 'Circuit View',
        resizable: true,
        style: {
          color: '#337ab7',
        },
        width: 100,
      },
    ]
    const data = [...this.props.rtMeterListEntity.items]
    return (
      <div>
        <Title>Real-Time Meter Status Tool</Title>
        <ResultsHeader>
          {this.props.timestamp && (
            <div>
              Meter status as of
              <StatusTimestamp>
                <IonIcon iconClass='far fa-clock' /> {this.props.timestamp}
              </StatusTimestamp>
              today:
            </div>
          )}
          <div>
            <IconButton
              onClick={this.refreshResults}
              iconClass='fas fa-sync-alt'
              loading={this.props.loading}
            >
              Refresh
            </IconButton>
          </div>
        </ResultsHeader>

        <RedaptiveReactTable
          columns={columns}
          data={data}
          filterable={false}
          SubComponent={({ original }: { original: FTMeterStatus }) =>
            original.type === 'REDAPTIVE_GAS' ?
              <FeatureNotAvailableStyled>
                This feature is not available for gas meters.
              </FeatureNotAvailableStyled>
            : <MeterMostRecentMeasurementData
                meterId={original.id || ''}
                key={`meterMostRecentMeasurementData${original.id || ''}`}
              />
          }
        />

        <ButtonGroup>
          <Button onClick={this.goBack}>Back</Button>
          <Button primary onClick={this.goHome}>
            Done
          </Button>
        </ButtonGroup>
      </div>
    )
  }

  render() {
    const {
      location: { pathname },
      loading,
      error,
      submission,
    } = this.props
    const isResults = pathname.endsWith('results')
    return (
      <Styles>
        <Breadcrumbs
          items={[
            {
              href: '/reports',
              text: 'Tools & Reports',
            },
            {
              href: '/reports/meter-status',
              text: 'Meter Status Tool',
            },
          ]}
        />
        {!isResults && (
          <FormWidth>
            <FormikMeterForm
              submitAction={this.handleSubmit}
              error={error}
              loading={loading}
              goBack={this.goHome}
              ids={[]}
            />
          </FormWidth>
        )}
        {isResults && this.renderResults()}
      </Styles>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(meterStatusActions, dispatch) },
})

const mapStateToProps = (state) => {
  const rtMeterListEntity = selectRTMeterListEntity(state)
  const {
    meta: { error, success, loading, submission, timestamp },
  } = rtMeterListEntity
  return {
    error,
    success,
    loading,
    submission,
    timestamp,
    rtMeterListEntity,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterStatusFormPage)
