import axios from 'axios'
import { schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../../api'
import { getTotalPages, handleAxiosError } from '../../api/utils'
import meterInstallSubmissions from '../../mockData/meterInstallSubmissions/meterInstallSubmissions'
import { handleSagaError } from '../../sagas/utils'
import '../../types'
import type { FTSortable } from '../../types'
import { isVariantActive } from '../../utils'
import '../types'
import type { FTEntityMetaSubmission } from '../types'
import { makeApiQueryString } from '../utils'

export type FTMeterInstallSubmission = {
  id: string
  meterId: string
  macAddress: string
  isAssignedToSite: string
  customerName: string
  siteLocation: string
  cellRouterInstalled: string
  evVerified: string
  userId: string
  opportunityId: string
  code: string
  isDeleted: false
  submissionDate: string
  createdBy: string
  modified: string
  configAcceptedDate: string
}
type FTFetchInstallSubmissionsResponse = {
  next?: string
  previous?: string
  totalCount: number
  results: Array<FTMeterInstallSubmission>
}
type FTFetchInstallSubmissionsParams = FTEntityMetaSubmission & {
  opportunityId?: string
  from?: string
  to?: string
  evVerified?: string
  cellRouterInstalled?: string
  isAssignedToSite?: string
  modifiedBy?: string
  customerName?: string
  siteLocation?: string
  macAddress?: string
  orderBy?: FTSortable
  endConfigAcceptedDate?: string
  startConfigAcceptedDate?: string
  preConfigTestStatus?: string
  postConfigTestStatus?: string
}
type FTMeterInstallSubmissionMetaState = {
  error: string
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
  next: string | null | undefined
  previous: string | null | undefined
  loading: boolean
  orderBy?: FTSortable
  searchParams?: Record<string, any>
}
type FTMeterInstallSubmissionEntityState = {
  byId: Record<string, FTMeterInstallSubmission>
  items: Array<FTMeterInstallSubmission>
  meta: FTMeterInstallSubmissionMetaState
}
// Action Types
const types = {
  FETCH_INSTALL_SUBMISSIONS: 'FETCH_INSTALL_SUBMISSIONS',
  FETCH_INSTALL_SUBMISSIONS_SUCCESS: 'FETCH_INSTALL_SUBMISSIONS_SUCCESS',
  FETCH_INSTALL_SUBMISSIONS_ERROR: 'FETCH_INSTALL_SUBMISSIONS_ERROR',
}
const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    loading: false,
    orderBy: {
      field: 'modified',
      sort: 'DESC',
    },
    searchParams: {
      opportunityId: '',
      from: '',
      to: '',
      evVerified: '',
      cellRouterInstalled: '',
      isAssignedToSite: '',
      submittedBy: '',
      customer: '',
      location: '',
      macAddress: '',
      endConfigAcceptedDate: '',
      startConfigAcceptedDate: '',
    },
  },
}
export const orderMap = {
  submissionDate: 'modified',
  opportunityId: 'opportunityId',
  macAddress: 'hardwareId',
  panelNames: 'panelNames',
  isAssignedToSite: 'isAssignedToSite',
  customerName: 'customerName',
  configAcceptedDate: 'configAcceptedDate',
  cellRouterInstalled: 'cellRouterInstalled',
  evVerified: 'evVerified',
  modifiedBy: 'modifiedBy',
  siteLocation: 'location',
  preConfigTestStatus: 'preConfigTestStatus',
  postConfigTestStatus: 'postConfigTestStatus',
}
export const apiOrderMap = {
  modified: 'submissionDate',
  hardwareId: 'macAddress',
  location: 'siteLocation',
  panelNames: 'panelNames',
  opportunityId: 'opportunityId',
  isAssignedToSite: 'isAssignedToSite',
  customerName: 'customerName',
  configAcceptedDate: 'configAcceptedDate',
  cellRouterInstalled: 'cellRouterInstalled',
  evVerified: 'evVerified',
  modifiedBy: 'modifiedBy',
  preConfigTestStatus: 'preConfigTestStatus',
  postConfigTestStatus: 'postConfigTestStatus',
}
type FTState = {
  entities: {
    meterInstallSubmissions: FTMeterInstallSubmissionEntityState
  }
}
export const actions = {
  fetchInstallSubmissions: (props: FTFetchInstallSubmissionsParams) => ({
    type: types.FETCH_INSTALL_SUBMISSIONS,
    ...props,
  }),
}
const entitySchema = new schema.Entity('meterInstallSubmissions')

function entityById(action, state) {
  return { ...state, ...action.payload.result }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_INSTALL_SUBMISSIONS:
      return initialState.byId

    case types.FETCH_INSTALL_SUBMISSIONS_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_INSTALL_SUBMISSIONS:
      return initialState.items

    case types.FETCH_INSTALL_SUBMISSIONS_SUCCESS:
      return action.payload.results

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_INSTALL_SUBMISSIONS:
      return { ...state, error: '', loading: true }

    case types.FETCH_INSTALL_SUBMISSIONS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_INSTALL_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        ...action.payload,
        // Making sure to over-ride the old values to null
        next: action.payload?.next || null,
        previous: action.payload?.previous || null,
      }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
function addMetaToResponse(
  params: FTFetchInstallSubmissionsParams & {
    searchParams: Record<string, any>
  },
  response: Record<string, any>,
) {
  const { pageNumber = 1, pageSize = 20, orderBy, filterBy } = params
  const { data } = response
  const { totalCount = 0 } = data
  const { searchParams } = params
  return {
    ...data,
    totalPages: getTotalPages(totalCount, pageSize),
    pageNumber,
    pageSize,
    orderBy,
    filterBy,
    searchParams,
  }
}
export const selectMeterInstallSubmissionsEntity = (
  state: FTState,
): FTMeterInstallSubmissionEntityState => state.entities.meterInstallSubmissions
// API
const API = {
  fetchInstallSubmissions(params: FTFetchInstallSubmissionsParams) {
    const {
      opportunityId = '',
      from = '',
      to = '',
      evVerified = '',
      cellRouterInstalled = '',
      isAssignedToSite = '',
      modifiedBy = '',
      customerName = '',
      siteLocation = '',
      macAddress = '',
      endConfigAcceptedDate = '',
      startConfigAcceptedDate = '',
      preConfigTestStatus = '',
      postConfigTestStatus = '',
    } = params

    if (isVariantActive('240mock')) {
      return Promise.resolve(meterInstallSubmissions).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const searchParams = {
      opportunityId,
      from,
      to,
      startConfigAcceptedDate,
      endConfigAcceptedDate,
      evVerified: evVerified.toUpperCase(),
      cellRouterInstalled: cellRouterInstalled.toUpperCase(),
      isAssignedToSite: isAssignedToSite.toUpperCase(),
      modifiedBy,
      customerName,
      siteLocation,
      macAddress,
      postConfigTestStatus,
      preConfigTestStatus,
    }

    const baseUrl = `${consoleApiUrl()}/meter/submission/details/list`
    const query = makeApiQueryString(params, searchParams)
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) =>
        addMetaToResponse({ ...params, searchParams }, response),
      )
      .catch(handleAxiosError)
  },
}

function* fetchInstallSubmissionsSaga(
  payload: FTFetchInstallSubmissionsParams,
) {
  try {
    const response: FTFetchInstallSubmissionsResponse = yield call(
      API.fetchInstallSubmissions,
      payload,
    )
    yield put({
      type: types.FETCH_INSTALL_SUBMISSIONS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_INSTALL_SUBMISSIONS_ERROR, error)
  }
}

export const sagas = [
  takeLatest(types.FETCH_INSTALL_SUBMISSIONS, fetchInstallSubmissionsSaga),
]
