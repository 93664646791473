import debounce from 'debounce'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import {
  baseOptions,
  breadcrumbs,
  defaultElectronMeterVerifyHeaders,
  defaultElectronMeterVerifyWidths,
  format,
  getProgramStartDate,
} from './constants'
import {
  Button,
  DatePickerContainerStyled,
  EmptyTableIndicatorStyled,
  Fail,
  FilterContainerStyled,
  Header,
  PageSizeSelectorInnerStyled,
  PageSizeSelectorWrapperStyled,
  PaginatorStyles,
  Pass,
  SpinnerStyles,
  TableLinkStyled,
  TableStyles,
  Title,
  Wrapper,
} from './styles'
import Breadcrumbs from '../../components/Breadcrumbs'
import DatePicker from '../../components/DatePicker'
import SearchBar from '../../components/EntityList/SearchBar'
import ListSelector from '../../components/ListSelector'
import Paginator from '../../components/Paginator'
import RedaptiveReactTable7 from '../../components/RedaptiveReactTable7'
import TableHead from '../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../components/Spinner'
import StatusIconItem from '../../components/StatusIconItem'
import * as consts from '../../constants'
import {
  actions,
  selectMeterElectronVerificationHistory,
} from '../../ducks/electronMeters'
import { actions as electronVerificationActions } from '../../ducks/electronVerification'
import { selectRTMeterListEntity } from '../../ducks/meterStatus'
import { getPageSizes, renderTimestamp } from '../../ducks/utils'

export default function ElectronVerifyDashboard() {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const resp = useSelector((state) =>
    selectMeterElectronVerificationHistory(state),
  )
  const {
    items: meters = [],
    meta: { loading: meterLoading },
  } = useSelector((state) => selectRTMeterListEntity(state))
  const {
    items,
    meta: {
      loading,
      orderBy,
      searchParams,
      previous,
      next,
      totalPages,
      totalCount,
      pageSize,
      pageNumber,
    },
  } = resp || {}

  const [orderParams, setOrderParams] = useState(orderBy)
  const [evDate, setEvDate] = useState('PROGRAM_TO_DATE')
  const [evDateRange, setEvDateRange] = useState({
    from: moment('2022-01-01').format(consts.DATE_FORMAT_DATA_API_REQUEST),
    to: moment().format(consts.DATE_FORMAT_DATA_API_REQUEST),
  })

  useEffect(() => {
    dispatch(
      actions.fetchMeterElectronVerificationHistory({ orderBy: orderParams }),
    )
  }, [orderParams])

  const renderSubmissionDateLabel = useCallback(() => {
    const { start, end } = {
      start: evDateRange.from,
      end: evDateRange.to,
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }, [evDateRange])

  const handleUpdateSubmissionDateRange = useCallback(
    ({ endDate, startDate, selectedOptionDate }) => {
      if (selectedOptionDate) {
        setEvDate(selectedOptionDate)
      } else {
        setEvDate('')
      }
      const updatedSearchParams = {
        from: moment(startDate)
          .utc()
          .startOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
        to: moment(endDate)
          .utc()
          .endOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
      }
      setEvDateRange({ ...updatedSearchParams })
      dispatch(
        actions.fetchMeterElectronVerificationHistory({
          orderBy,
          pageSize,
          ...searchParams,
          ...updatedSearchParams,
        }),
      )
    },
    [orderBy, searchParams],
  )

  const search = (searchBy: { field: string; term: string }) => {
    dispatch(
      actions.fetchMeterElectronVerificationHistory({
        ...searchParams,
        pageSize,
        [searchBy.field]: searchBy.term,
        orderBy: orderParams,
      }),
    )
  }

  const handleSearch = debounce(search, 500)

  const selectFilter = (id) => {
    if (id === 'evTestDate') {
      return (
        <FilterContainerStyled
          width={defaultElectronMeterVerifyWidths[id].minWidth}
        >
          <DatePickerContainerStyled key={id}>
            <DatePicker
              end={evDateRange.to}
              options={baseOptions}
              period={evDate}
              getProgramStartDate={getProgramStartDate}
              start={evDateRange.from}
              text={renderSubmissionDateLabel()}
              updateDateRange={handleUpdateSubmissionDateRange}
            />
          </DatePickerContainerStyled>
        </FilterContainerStyled>
      )
    }

    return (
      <FilterContainerStyled
        width={defaultElectronMeterVerifyWidths[id]?.minWidth}
        key={id}
      >
        <SearchBar
          onSearch={handleSearch}
          filterField={id}
          previous={{
            field: id,
            term: searchParams[id] ? searchParams[id].toLowerCase() : '',
          }}
        />
      </FilterContainerStyled>
    )
  }

  const browseToPage = (number) => {
    dispatch(
      actions.fetchMeterElectronVerificationHistory({
        orderBy: orderParams,
        pageSize,
        pageNumber: Number(number),
        ...searchParams,
      }),
    )
  }

  const handleUpdateSelectedPage = ({ value }) => {
    dispatch(
      actions.fetchMeterElectronVerificationHistory({
        orderBy: orderParams,
        pageSize: value,
        ...searchParams,
      }),
    )
  }

  const handleSortClick = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    if (orderParams.field) {
      if (orderParams.field !== id) {
        setOrderParams({
          field: id,
          sort: 'ASC',
        })
      } else if (orderParams.sort === 'DESC') {
        setOrderParams({
          field: id,
          sort: 'ASC',
        })
      } else {
        setOrderParams({
          field: id,
          sort: 'DESC',
        })
      }
    }
  }

  const tableHeaderRows = () => {
    const headerRows = defaultElectronMeterVerifyHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      Filter: () =>
        item.id === 'meterStatus' || item.id === 'lastReportedDate' ?
          null
        : selectFilter(item.id),
      handleSortClick,
      sortable: !(item.id === 'meterStatus' || item.id === 'lastReportedDate'),
      sorted: orderBy.field === item.id,
      sortDesc: orderBy.sort === 'DESC',
      minWidth: defaultElectronMeterVerifyWidths?.[item.id]?.minWidth,
      maxWidth: defaultElectronMeterVerifyWidths?.[item.id]?.maxWidth,
    }))
    return [
      {
        id: 'row1',
        headers: headerRows,
      },
    ]
  }

  const TableHeadComponent = () => <TableHead rows={tableHeaderRows()} />

  const evDateCell = (cellProps) => {
    const {
      value,
      row: {
        original: { id: evId, macAddress, siteTimezone, dataCheckStatus },
      },
    } = cellProps
    const onlyLabelCheck = dataCheckStatus === 'NO_RESULT'
    return (
      <TableLinkStyled
        href={`/reports/webev-history/${evId}/macAddress/${macAddress}?onlyLabelCheck=${onlyLabelCheck}`}
      >
        {renderTimestamp(value, siteTimezone, 'MMMM DD, YYYY hh:mm:ss A z')}
      </TableLinkStyled>
    )
  }

  const booleanCell = (value) => {
    const isNoResult = value === 'NO_RESULT'
    const isPass = value === 'PASS'
    const label = isPass ? 'Pass' : 'Fail'
    const icon = isPass ? <Pass /> : <Fail />
    return isNoResult ? 'No Result' : (
        <>
          {icon}
          {label}
        </>
      )
  }

  const meterStatusCell = (macAddress, key, siteTimezone) => {
    if (meterLoading) {
      return 'Loading...'
    }
    if (meters.length) {
      const meter = meters.find((item) => macAddress === item.macAddress)

      return key === 'meterStatus' ?
          <StatusIconItem status={meter?.[key]} />
        : renderTimestamp(
            meter?.[key],
            siteTimezone,
            'MMMM DD, YYYY hh:mm:ss A z',
          ) || '-'
    }
    return '-'
  }

  const getColumnCell = (cellProps) => {
    const {
      column,
      value,
      row: { original },
    } = cellProps
    const { id } = column
    const { macAddress, siteTimezone } = original || {}
    switch (id) {
      case 'evTestDate':
        return evDateCell(cellProps)
      case 'meterStatus':
        return meterStatusCell(macAddress, 'meterStatus', siteTimezone)
      case 'lastReportedDate':
        return meterStatusCell(macAddress, 'lastReportDate', siteTimezone)
      case 'dataCheckStatus':
      case 'labelCheckStatus':
        return booleanCell(value)
      default:
        return value || '-'
    }
  }

  const columns = defaultElectronMeterVerifyHeaders.map((item) => ({
    accessor: item.id,
    Header: item.label,
    Cell: getColumnCell,
    minWidth: defaultElectronMeterVerifyWidths[item.id]?.minWidth,
    maxWidth: defaultElectronMeterVerifyWidths[item.id]?.maxWidth,
  }))

  const pageSizes = getPageSizes(totalCount, true, [20, 50])
  const pageSizeItems = pageSizes.map(({ label }) => ({
    id: label,
    name: `${label} Rows`,
  }))

  const goToPerformEvCheck = () => {
    push('/reports/webev')
  }

  useEffect(() => {
    dispatch(
      electronVerificationActions.updateMeterSelectionMeta({
        searchBy: '',
        siteId: '',
        customerId: '',
        meterMac: '',
        opportunityId: '',
      }),
    )
  }, [])

  return (
    <Wrapper>
      <Breadcrumbs items={breadcrumbs} />
      <Header>
        <Title>Electron Verify Dashboard</Title>
        <Button type='redaptivePrimary' onClick={goToPerformEvCheck}>
          Perform EV Check
        </Button>
      </Header>
      <TableStyles loading={loading}>
        <RedaptiveReactTable7
          columns={columns}
          data={items}
          TableHead={TableHeadComponent}
          filterable
          globalFilterable={false}
          hideSeparator
        />
        {loading ?
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        : <PaginatorStyles>
            <Paginator
              total={totalPages}
              current={pageNumber || 1}
              next={next}
              prev={previous}
              perPage={pageSize}
              browseToPage={browseToPage}
              showArrows
            />
            {items.length > 0 && (
              <PageSizeSelectorWrapperStyled>
                <PageSizeSelectorInnerStyled>
                  Show:
                  <ListSelector
                    directionUp
                    items={pageSizeItems}
                    updateValue={handleUpdateSelectedPage}
                    selectedItem={{ id: pageSize, name: pageSize }}
                    unsettable={false}
                  />
                </PageSizeSelectorInnerStyled>
              </PageSizeSelectorWrapperStyled>
            )}
          </PaginatorStyles>
        }
        {!loading && !items.length && (
          <EmptyTableIndicatorStyled>
            {' '}
            No data found. Change the filters and try again.
          </EmptyTableIndicatorStyled>
        )}
      </TableStyles>
    </Wrapper>
  )
}
