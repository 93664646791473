import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { queryStringify } from '../../../api/utils'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../../authorization/features'
import type { FTProposalCustomerGlobalInputs } from '../../../ducks/proposals/customerGlobalInputs'
import {
  actions as emissionActions,
  selectEmissionRateEntity,
} from '../../../ducks/proposals/emissionRate'
import type { FTProposalOpportunitySummary } from '../../../ducks/proposals/opportunitySummaries'
import { actions as recActions } from '../../../ducks/proposals/recData'
import type { FTProposalScenario } from '../../../ducks/proposals/scenarios'
import {
  actions as scenarioActions,
  getScenarioCalculations,
  selectProposalScenarioEntity,
} from '../../../ducks/proposals/scenarios'
import type { FTProposalSite } from '../../../ducks/proposals/sites'
import { salesTaxWarningValue } from '../../../pages/ProposalOperations/ProposalsEngine/utils'
import type { FTWithRouter } from '../../../types'
import { zIndices } from '../../../utils'
import { colors } from '../../../utils/themes'
import InfoCircleIcon from '../../Icons/InfoCircleIcon'
import ConfirmModal, {
  ConfirmBodyScenarioNameStyled,
  ConfirmBodyTitleStyled,
} from '../ConfirmModal'
import EditNoteIconStyled from '../EditNoteIconStyled'
import {
  WarningBodyStyles,
  WarningIconContainerStyles,
  WarningMsgContainer,
  WarningTextStyles,
} from '../ModalAddScenarioWithoutSalesTax'
import ScenarioForm, {
  getSalesTaxWarningPopupMsg,
  getWarningPopupBody,
} from '../ScenarioForm'
import ScenarioView from '../ScenarioView'
import type { ComponentType } from 'react'

const ModalScenarioStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};

  * {
    box-sizing: border-box;
  }
`
const ScenarioWrapperStyled: ComponentType<{
  showConfirm: boolean
}> = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: ${({ showConfirm }) => showConfirm && '0'};
`
const ScenarioStyled = styled.div`
  background-color: #fff;
  border: 1px solid #dadce0;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  width: 600px;
`
const HeaderStyled = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 12px 32px 0 24px;
`
const ScenarioLabelStyled = styled.div`
  font-size: 12px;
`
const ScenarioNameStyled = styled.div`
  font-size: 18px;
`
const HeaderSectionSecond = styled.div`
  display: flex;
  justify-content: flex-end;
`
const EditNoteIconWrapperStyled = styled.div`
  margin-right: 16px;
`
const CloseIconStyled = styled.div`
  color: #464a54;
  cursor: pointer;
  font-size: 24px;
  line-height: 0;
  margin-right: 12px;
  text-decoration: none;
`
const SalesTaxWarningContainerStyles = styled.div`
  display: flex;
  flex-direction: column;

  ${WarningMsgContainer} {
    margin: 0 0 15px;
  }

  ${WarningBodyStyles} {
    padding: 0;
  }
`
export type FTShowModalScenario = {
  editMode?: boolean
  closeModal: (...args: Array<any>) => any
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  onSubmit: (...args: Array<any>) => any
  opportunity: FTProposalOpportunitySummary
  queryParams: Record<string, string>
  site: FTProposalSite
  scenarioId: string
  url: string
}
type FTProps = FTShowModalScenario & {
  scenarios: Array<FTProposalScenario>
  scenariosById: Record<string, FTProposalScenario>
  scenariosLoading: boolean
} & FTWithRouter

const ModalScenario = (props: FTProps) => {
  const {
    closeModal,
    customerGlobalInputs,
    editMode: editModeProps,
    history,
    queryParams,
    onSubmit,
    opportunity,
    scenarioId,
    scenarios,
    scenariosById,
    scenariosLoading,
    site,
    url,
    actions,
    emission,
  } = props
  let scenario = scenariosById[scenarioId]
  scenario = {
    ...scenario,
    emissionRate: emission.egridRate || null,
    monthlyPreBurnHours: getScenarioCalculations(
      scenario,
      site,
    ).monthlyPreBurnHours(scenario, site),
    monthlyPostBurnHours: getScenarioCalculations(
      scenario,
      site,
    ).monthlyPostBurnHours(scenario, site),
  }
  const [formValues, setFormValues] = React.useState({
    id: scenarioId,
  })
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [showSalesTaxWarning, setShowSalesTaxWarning] = useState(false)
  const [editMode, setEditMode] = React.useState(editModeProps)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const hideEditIcon =
    (opportunity?.stageName === 'Installing' && opportunity?.isLocked) ||
    opportunity?.stageName !== scenario?.createdOnStageName

  const handleSubmit = (values) => {
    const { estimatedSalesTaxInPercentage } = values
    setFormValues(values)

    if (estimatedSalesTaxInPercentage === salesTaxWarningValue) {
      setShowSalesTaxWarning(true)
    } else {
      setShowConfirm(true)
    }
  }

  const hideConfirmPopups = () => {
    setShowConfirm(false)
    setShowSalesTaxWarning(false)
  }

  const handleSalesTaxWarningConfirm = () => {
    hideConfirmPopups()
    setShowConfirm(true)
  }

  const handleSubmitConfirm = () => {
    setSubmitted(true)
    onSubmit(formValues)
  }

  const handleSubmitCancel = () => {
    hideConfirmPopups()
  }

  React.useEffect(() => {
    if (submitted && scenariosLoading && !submitting) {
      setSubmitting(true)
    } else if (submitting && !scenariosLoading) {
      setSubmitting(false)
      setSubmitted(false)
      closeModal()
    }
  }, [scenariosLoading, submitted, submitting])
  const onScenarioFormCancel = React.useCallback(() => {
    closeModal()
  }, [])
  React.useEffect(() => {
    const { editMode: editModeQueryParam, ...newQueryParams } = queryParams

    if (editModeQueryParam && hideEditIcon) {
      history.push(`${url}?${queryStringify(newQueryParams)}`)
      setEditMode(false)
    }
  }, [])
  const handleEditNoteIconClick = React.useCallback(() => {
    if (editMode) {
      const { editMode: editModeQueryParam, ...newQueryParams } = queryParams
      history.push(`${url}?${queryStringify(newQueryParams)}`)
    } else {
      const newQueryParams = { ...queryParams, editMode: '1' }
      history.push(`${url}?${queryStringify(newQueryParams)}`)
    }

    setEditMode(!editMode)
  }, [editMode])
  const renderConfirmBody = React.useCallback(
    () => (
      <>
        <ConfirmBodyTitleStyled>
          Are you sure you want to update
        </ConfirmBodyTitleStyled>
        <ConfirmBodyScenarioNameStyled>
          {scenario.name}?
        </ConfirmBodyScenarioNameStyled>
      </>
    ),
    [scenario],
  )
  const renderSalesTaxWarningBody = React.useCallback(() => (
    <SalesTaxWarningContainerStyles>
      <WarningMsgContainer>
        <WarningIconContainerStyles>
          <InfoCircleIcon />
        </WarningIconContainerStyles>
        <WarningTextStyles>{getSalesTaxWarningPopupMsg}</WarningTextStyles>
      </WarningMsgContainer>
      <WarningBodyStyles>{getWarningPopupBody()}</WarningBodyStyles>
    </SalesTaxWarningContainerStyles>
  ))
  return (
    <>
      <ModalScenarioStyled>
        <ScenarioWrapperStyled showConfirm={showConfirm}>
          <ScenarioStyled>
            <HeaderStyled>
              <div>
                <ScenarioLabelStyled>Scenario ID</ScenarioLabelStyled>
                <ScenarioNameStyled>{scenario.name}</ScenarioNameStyled>
              </div>
              <HeaderSectionSecond>
                {!hideEditIcon &&
                  scenario &&
                  scenario.status !== 'APPROVED' && (
                    <FeatureValidator
                      feature={AuthorizedFeatures.editProposalScenario}
                    >
                      <EditNoteIconWrapperStyled>
                        <EditNoteIconStyled
                          editMode={editMode}
                          onClick={handleEditNoteIconClick}
                        />
                      </EditNoteIconWrapperStyled>
                    </FeatureValidator>
                  )}
                <CloseIconStyled onClick={closeModal}>
                  <span className='ion-android-close' />
                </CloseIconStyled>
              </HeaderSectionSecond>
            </HeaderStyled>
            {editMode ?
              <ScenarioForm
                customerGlobalInputs={customerGlobalInputs}
                editMode
                onSubmit={handleSubmit}
                opportunity={opportunity}
                scenario={scenario}
                scenarios={scenarios}
                onCancel={onScenarioFormCancel}
                site={site}
                areValuesPreFilled
                source='edit'
                actions={actions}
                emission={emission}
              />
            : <ScenarioView
                customerGlobalInputs={customerGlobalInputs}
                opportunity={opportunity}
                scenario={scenario}
                site={site}
              />
            }
          </ScenarioStyled>
        </ScenarioWrapperStyled>
      </ModalScenarioStyled>
      {showConfirm && (
        <ConfirmModal
          confirmText='Confirm'
          cancelText='Cancel'
          handleCancel={handleSubmitCancel}
          handleConfirm={handleSubmitConfirm}
          renderBody={renderConfirmBody}
        />
      )}
      {showSalesTaxWarning && (
        <ConfirmModal
          confirmText='Confirm'
          cancelText='Cancel'
          handleCancel={handleSubmitCancel}
          handleConfirm={handleSalesTaxWarningConfirm}
          renderBody={renderSalesTaxWarningBody}
        />
      )}
    </>
  )
}

ModalScenario.defaultProps = {
  editMode: false,
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(scenarioActions, dispatch),
    ...bindActionCreators(emissionActions, dispatch),
    ...bindActionCreators(recActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const scenariosEntity = selectProposalScenarioEntity(state)
  const emissionRate = selectEmissionRateEntity(state)
  const { emissionRateData: emission } = emissionRate
  const {
    byId: scenariosById,
    meta: { loading: scenariosLoading },
    items: scenarios,
  } = scenariosEntity
  return {
    scenarios,
    scenariosById,
    scenariosLoading,
    emission,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalScenario),
)
