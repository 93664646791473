import './abilities'
import type { FTAbility } from './abilities'
import ResourceAbility from './resourceAbility'
import type { FTRole } from './roles'
import Roles from './roles'
import './userPermissions'
import type { FTUserPermissions } from './userPermissions'

/* A Feature is a collection of ResourceAbilities which describes what is
required for a user to be able to interact with an aspect of the application.

A Feature can be validated in two ways:
- Any Match: as long as one of the ResourceAbilities matches the user's
permissions, it is valid.
- All Match: requires that every ResourceAbility matches the user's permissions
in order to be valid.
*/
const findRoleForResource = (permissions, resource): FTRole => {
  if (permissions[resource]) {
    return permissions[resource]
  }

  const match = Object.keys(permissions).find((prefix) =>
    resource.startsWith(prefix),
  )
  return permissions[match] || Roles.UNAUTHORIZED
}

export default class Feature {
  resourceAbilities: Array<ResourceAbility> = []

  constructor(resourceAbilities: Array<ResourceAbility>) {
    if (!resourceAbilities || resourceAbilities.length < 0) {
      throw new Error(
        'Must provide at least one ResourceAbility to define the feature.',
      )
    }

    this.resourceAbilities = resourceAbilities
  }

  static _abilityIncludesRole(ability: FTAbility, role: FTRole) {
    if (role === Roles.UNAUTHORIZED) {
      return false
    }

    return ability.includes(role)
  }

  static _validatePermissions(permissions: FTUserPermissions) {
    if (!permissions) {
      return null
    }

    return permissions
  }

  anyMatchesWithPermissions(permissions: FTUserPermissions) {
    const validPermissions = Feature._validatePermissions(permissions)

    if (!validPermissions) {
      return false
    }

    return this.resourceAbilities.some((ra) => {
      const resourceRole = findRoleForResource(permissions, ra.resource)
      return Feature._abilityIncludesRole(ra.ability, resourceRole)
    })
  }

  allMatchWithPermissions(permissions: FTUserPermissions) {
    const validPermissions = Feature._validatePermissions(permissions)

    if (!validPermissions) {
      return false
    }

    return this.resourceAbilities.every((ra) => {
      const resourceRole = findRoleForResource(permissions, ra.resource)
      return Feature._abilityIncludesRole(ra.ability, resourceRole)
    })
  }
}
