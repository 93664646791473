import axios from 'axios'
import moment from 'moment-timezone'
import { combineReducers } from 'redux'
import { call, put, takeEvery } from 'redux-saga/effects'

import type { FTCircuit } from './circuits'
import type { FTRange } from './utils'
import { DATE_FORMAT_DATA_API_RESPONSE, DATE_FORMAT_TIMESTAMP } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import './circuits'
import { handleAxiosError, queryStringify } from '../api/utils'
import { handleSagaError } from '../sagas/utils'

export type FTCircuitData = {
  circuitId: string
  data: Record<string, Array<number | null>>
  max: Record<string, number>
  min: Record<string, number>
  missingData: Array<FTRange>
  missingWeekendData: Array<FTRange>
  pointInterval: number
  resolution: string
  startDateTime: number
  ts: Array<string>
  weekends: Array<FTRange>
  xAxisMajorTickInterval: number | null | undefined
  xAxisMinorTickInterval: number | null | undefined
  uncleansed: boolean
}
export type FTCircuitDataMeta = {
  error: string
  loaded: boolean
  loading: boolean
}
type FTCircuitsData = {
  byId: Record<string, FTCircuitData>
  meta: Record<string, FTCircuitDataMeta>
}
type FTCircuitsDataState = {
  entities: {
    circuitsData: FTCircuitsData
  }
}
export type FTFetchCircuitDataAction = {
  circuitId: string
  from: string
  measurementTypes: Array<string>
  resolution: string
  siteTimezone: string
  to: string
  uncleansed: boolean
}
export type FTCircuitDataResponse = {
  circuits: Array<FTCircuit>
  from: string
  to: string
  ts: Array<string>
}
type FTFetchCircuitDataException = {
  errors: Array<{
    message: string
  }>
}
// Action
export const actions = {
  getCircuitData: (params: FTFetchCircuitDataAction) => ({
    type: 'GET_CIRCUIT_DATA',
    params,
  }),
  fetchCircuitDataError: ({
    circuitId,
    error,
  }: {
    circuitId: string
    error: string
  }) => ({
    type: 'GET_CIRCUIT_DATA_ERROR',
    circuitId,
    error,
  }),
}
// Reducers
const initialState = {
  byId: {},
  meta: {},
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case 'GET_CIRCUIT_DATA_SUCCESS':
      return { ...state, [action.payload.circuitId]: action.payload }

    default:
      return { ...state }
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case 'GET_CIRCUIT_DATA':
      return {
        ...state,
        [action.params.circuitId]: {
          loading: true,
          loaded: false,
          error: '',
        },
      }

    case 'GET_CIRCUIT_DATA_SUCCESS':
      return {
        ...state,
        [action.payload.circuitId]: {
          loading: false,
          loaded: true,
          error: '',
        },
      }

    case 'GET_CIRCUIT_DATA_ERROR':
      return {
        ...state,
        [action.circuitId]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  meta,
}) // Selectors

export const selectCircuitData = (
  state: FTCircuitsDataState,
  id: string,
): FTCircuitData =>
  state.entities.circuitsData.byId[id] || {
    circuitId: id,
    data: {},
    max: 0,
    min: 0,
    missingData: [],
    missingWeekendData: [],
    pointInterval: 1000 * 60 * 15,
    resolution: '15min',
    startDateTime: 0,
    ts: [],
    weekends: [],
    xAxisMajorTickInterval: 1000 * 60 * 60 * 24,
    xAxisMinorTickInterval: 1000 * 60 * 60 * 6,
    uncleansed: true,
  }
export const selectCircuitDataMeta = (
  state: FTCircuitsDataState,
  id: string,
): FTCircuitDataMeta =>
  state.entities.circuitsData.meta[id] || {
    loading: false,
    loaded: false,
    error: '',
  }

const fetchCircuitDataException = ({
  errors,
}): FTFetchCircuitDataException => ({
  name: 'fetchCircuitDataError',
  errors,
  toString: 'fetchCircuitDataError',
})

const handleFetchCircuitDataError = (error: Record<string, any>) => {
  const { response } = error || {}
  const { status, data } = response || {}

  if (status === 400 && Array.isArray(data)) {
    throw fetchCircuitDataException({
      errors: data,
    })
  } else {
    handleAxiosError(error)
  }
}

// API
export class API {
  static fetchCircuitData({
    circuitId,
    from,
    measurementTypes,
    resolution,
    to,
    uncleansed,
  }: FTFetchCircuitDataAction): Promise<FTCircuitDataResponse> {
    const query = queryStringify({
      from,
      measurement_types: measurementTypes,
      resolution,
      to,
      uncleansed,
    })
    const url = `${consoleApiUrl()}/circuits/${circuitId}/data?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleFetchCircuitDataError)
  }
}
export const enhanceCircuitData = ({
  response,
  requestParams,
}: {
  response: FTCircuitDataResponse
  requestParams: FTFetchCircuitDataAction
}): FTCircuitData => {
  const {
    circuitId,
    measurementTypes,
    resolution,
    siteTimezone,
    uncleansed = false,
    from: requestFrom,
    to: requestTo,
  } = requestParams
  const { from, to, ts, ...measurementTypesResponse } = response
  const tsPadded: Array<string> = []
  const data = measurementTypes.reduce(
    (acc, cur) => ({ ...acc, [cur]: [] }),
    {},
  )
  const missingData: Array<FTRange> = []
  const missingWeekendData: Array<FTRange> = []
  const momentFrom = moment.tz(
    requestFrom,
    DATE_FORMAT_DATA_API_RESPONSE,
    siteTimezone,
  )
  const momentTo = moment.tz(
    requestTo,
    DATE_FORMAT_DATA_API_RESPONSE,
    siteTimezone,
  )
  const resolutionInMinutes = parseInt(resolution.replace('min', ''), 10)
  const pointInterval = 1000 * 60 * resolutionInMinutes
  const startDateTime = momentFrom.valueOf()
  const endDateTime = momentTo.valueOf()
  const chartInterval = endDateTime - startDateTime
  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  const day = 24 * hour
  const week = 7 * day
  const max = measurementTypes.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]:
        measurementTypesResponse[cur].length ?
          Math.max(...measurementTypesResponse[cur])
        : 0,
    }),
    {},
  )
  const min = measurementTypes.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]:
        measurementTypesResponse[cur].length ?
          Math.min(...measurementTypesResponse[cur])
        : 0,
    }),
    {},
  )

  const weekends: Array<FTRange> = (() => {
    const fromDayOfWeek = momentFrom.day()
    const weekendsAcc = []

    // If the first day is Sunday...
    if (fromDayOfWeek === 0) {
      // The first weekend plot band will only be one day long.
      weekendsAcc.push({
        from: startDateTime,
        to: startDateTime + day,
      })
    }

    // For each week of data...
    for (
      let currentDateTime = startDateTime;
      currentDateTime < endDateTime;
      currentDateTime += week
    ) {
      const nextSaturdayStart = currentDateTime + day * (6 - fromDayOfWeek)
      const nextSaturdayEnd = nextSaturdayStart + day - 1
      const nextSundayEnd = nextSaturdayEnd + day

      // If the next Saturday is within the current date range...
      if (nextSaturdayStart < endDateTime) {
        // If the next Sunday is also within the current date range...
        if (nextSundayEnd < endDateTime) {
          weekendsAcc.push({
            from: nextSaturdayStart,
            to: nextSundayEnd,
          }) // Else only Saturday is within the current date range.
        } else {
          weekendsAcc.push({
            from: nextSaturdayStart,
            to: nextSaturdayEnd,
          })
        }
      }
    }

    return weekendsAcc
  })()

  let previousDateTime = startDateTime
  ts.forEach((timestamp, index) => {
    const momentNext = moment.tz(
      `${timestamp}Z`,
      DATE_FORMAT_DATA_API_RESPONSE,
      siteTimezone,
    )
    const nextDateTime = momentNext.valueOf()
    const interval = nextDateTime - previousDateTime

    // If the interval is greater than the resolution in minutes, data is missing.
    if (interval > pointInterval) {
      // Adds missing data.
      missingData.push({
        from: previousDateTime,
        to: nextDateTime,
      })
      // The number of missing entries.
      const padCount = Math.ceil(interval / pointInterval) - 1
      // Pads the power array with missing data values.
      const dataPadArray = new Array(padCount).fill(null)
      measurementTypes.forEach((measurementType) => {
        data[measurementType].push(...dataPadArray)
      })

      // Pads the timestamp array.
      for (let i = 1; i <= padCount; i += 1) {
        tsPadded.push(
          moment
            .tz(previousDateTime, siteTimezone)
            .add(pointInterval * i)
            .format(DATE_FORMAT_TIMESTAMP),
        )
      }
    }

    tsPadded.push(momentNext.format(DATE_FORMAT_TIMESTAMP))
    measurementTypes.forEach((measurementType) => {
      data[measurementType].push(
        measurementTypesResponse[measurementType][index],
      )
    })
    previousDateTime = nextDateTime
  })
  missingData.forEach(({ from: missingFrom, to: missingTo }) => {
    weekends.forEach(({ from: weekendFrom, to: weekendTo }) => {
      const missingFromIsInWeekend =
        missingFrom >= weekendFrom && missingFrom <= weekendTo
      const missingToIsInWeekend =
        missingTo >= weekendFrom && missingTo <= weekendTo

      if (missingFromIsInWeekend || missingToIsInWeekend) {
        missingWeekendData.push({
          from: missingFromIsInWeekend ? missingFrom : weekendFrom,
          to: missingToIsInWeekend ? missingTo : weekendTo,
        })
      }

      const missingDataCoversEntireWeekend =
        missingFrom < weekendFrom && missingTo > weekendTo

      if (missingDataCoversEntireWeekend) {
        missingWeekendData.push({
          from: weekendFrom,
          to: weekendTo,
        })
      }
    })
  })
  let xAxisMajorTickInterval
  let xAxisMinorTickInterval

  if (chartInterval <= day) {
    xAxisMajorTickInterval = 6 * hour
    xAxisMinorTickInterval = hour
  } else if (chartInterval <= 7 * day) {
    xAxisMajorTickInterval = 24 * hour
    xAxisMinorTickInterval = 12 * hour
  } else if (chartInterval <= 14 * day) {
    xAxisMajorTickInterval = 48 * hour
    xAxisMinorTickInterval = 24 * hour
  } else if (chartInterval <= 21 * day) {
    xAxisMajorTickInterval = 72 * hour
    xAxisMinorTickInterval = 24 * hour
  } else if (chartInterval <= 31 * day) {
    xAxisMajorTickInterval = 7 * day
    xAxisMinorTickInterval = day
  } else {
    xAxisMajorTickInterval = 7 * day
    xAxisMinorTickInterval = 7 * day
  }

  return {
    circuitId,
    uncleansed,
    data,
    max,
    min,
    missingData,
    missingWeekendData,
    pointInterval,
    resolution,
    startDateTime,
    ts: tsPadded,
    weekends,
    xAxisMajorTickInterval,
    xAxisMinorTickInterval,
  }
}

// Sagas
function* getCircuitDataSaga({
  params,
}: {
  params: FTFetchCircuitDataAction
}): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchCircuitData, params)
    const payload = enhanceCircuitData({
      response,
      requestParams: params,
    })
    yield put({
      type: 'GET_CIRCUIT_DATA_SUCCESS',
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'fetchCircuitDataError') {
      const {
        errors: [{ message }],
      } = error
      const { circuitId } = params
      yield put(
        actions.fetchCircuitDataError({
          circuitId,
          error: message,
        }),
      )
    } else {
      yield handleSagaError('GET_CIRCUIT_DATA_ERROR', error, params)
    }
  }
}

export const sagas = [takeEvery('GET_CIRCUIT_DATA', getCircuitDataSaga)]
