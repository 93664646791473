import Tippy from '@tippyjs/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import 'tippy.js/dist/tippy.css'

import {
  Actions,
  Cell,
  Link,
  ModalTitle,
  NoData,
  NoDataText,
  PanelHeading,
  SpinnerWrapper,
  TableWrapperStyled,
  Wrapper,
} from './style'
import { DataType } from './types'
import { dataCheckColumns, getFailedChecks, labelCheckColumns } from './utils'
import LinkIcon from '../../components/Icons/svg/LinkIcon'
import RedaptiveReactTable7 from '../../components/RedaptiveReactTable7'
import Spinner from '../../components/Spinner'
import StatusIconItem from '../../components/StatusIconItem'
import StyledLink from '../../components/StyledLink'
import VerticalTable from '../../components/VerticalTable'
import {
  actions as electronVerificationActions,
  selectMeterElectronVerification,
  selectMeterSelectionMeta,
} from '../../ducks/electronVerification'
import {
  actions as meterStatusActions,
  selectRTMeterListEntity,
} from '../../ducks/meterStatus'
import { actions as modalActions } from '../../ducks/modal'
import { renderTimestamp } from '../../ducks/utils'
import electronVerificationImg from '../../images/electron-verification.png'
import { colors } from '../../utils/themes'

export default function WebEv() {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const { macAddress, evId } = useParams<{
    macAddress: string
    evId?: string
  }>()
  const { search, state: prevPageData } = useLocation()
  const [evDataFromPrevPage, setEvDataFromPrevPage] = useState(prevPageData)
  const { data, loading, error } = useSelector((state) =>
    selectMeterElectronVerification(state),
  )
  const { searchBy, siteId, customerId, meterMac, opportunityId } = useSelector(
    selectMeterSelectionMeta,
  )
  const isLabelCheckOnly = search === '?onlyLabelCheck=true'
  const {
    items: meterStatus,
    meta: { loading: meterStatusLoading },
  } = useSelector((state) => selectRTMeterListEntity(state))

  const {
    meterDetail,
    dataQualityTestResults,
    labelCheckResults,
    errorMessage,
  } = evId ? data || {} : (evDataFromPrevPage?.[0] ?? data?.[0]) || {}
  const { onlineStatus, originalLastMeasurementDate } = meterStatus?.[0] || {}
  const failedDataQualityTests = getFailedChecks(dataQualityTestResults)
  const failedLabelQualityTests = getFailedChecks(labelCheckResults)
  useEffect(() => {
    if (!evDataFromPrevPage) {
      dispatch(
        electronVerificationActions.fetchMeterElectronVerification({
          macAddress,
          evId,
          isLabelCheckOnly,
        }),
      )
    }
    dispatch(meterStatusActions.getRealTimeMeterStatus({ ids: [macAddress] }))
    return () => {
      dispatch(electronVerificationActions.resetMeterElectronVerification())
    }
  }, [macAddress, evId])

  useEffect(() => {
    if (errorMessage || error) {
      dispatch(
        modalActions.showModalGeneric({
          title: <ModalTitle>Something went wrong</ModalTitle>,
          body: 'Please try again after sometime. If the issue persists, please contact the technology team',
          acceptText: 'Done',
          handleClose: onReturnToMeterSelection,
        }),
      )
    }
  }, [errorMessage, error])

  const onRerun = () => {
    setEvDataFromPrevPage(null)
    if (evId) {
      push(`/reports/webev/${macAddress}?onlyLabelCheck=${isLabelCheckOnly}`)
    } else {
      dispatch(
        electronVerificationActions.fetchMeterElectronVerification({
          evId,
          macAddress,
          isLabelCheckOnly,
        }),
      )
      dispatch(meterStatusActions.getRealTimeMeterStatus({ ids: [macAddress] }))
    }
  }

  const onReturnToMeterSelection = () => {
    if (searchBy === 'customerAndSiteID') {
      push(
        `/reports/webev?searchBy=${searchBy}&siteId=${siteId}&customerId=${customerId}`,
      )
    } else if (searchBy === 'byOpportunityID') {
      push(`/reports/webev?searchBy=${searchBy}&opportunityId=${opportunityId}`)
    } else if (searchBy === 'byMeterMAC') {
      push(`/reports/webev?searchBy=${searchBy}&meterMac=${meterMac}`)
    } else {
      push(`/reports/webev`)
    }
  }

  const onReturnToDashboard = () => {
    push(`/reports/electron-verify-dashboard`)
  }

  const fields = [
    {
      label: 'Meter Mac',
      renderField: () =>
        meterDetail?.macAddress ?
          <StyledLink
            href={`/account-management/meters/${meterDetail?.meterId}`}
            external
          >
            <Link>
              {meterDetail?.macAddress} {LinkIcon}
            </Link>
          </StyledLink>
        : null,
    },
    {
      label: 'Customer',
      renderField: () =>
        meterDetail?.customerName ?
          <StyledLink
            href={`/account-management/customers/${meterDetail?.customerId}`}
            external
          >
            <Link>
              {meterDetail?.customerName}
              {LinkIcon}
            </Link>
          </StyledLink>
        : null,
    },
    {
      label: 'Site',
      renderField: () =>
        meterDetail?.siteName ?
          <StyledLink
            href={`/account-management/sites/${meterDetail?.siteId}`}
            external
          >
            <Link>
              {meterDetail?.siteName}
              {LinkIcon}
            </Link>
          </StyledLink>
        : '-',
    },
    {
      label: 'ROID',
      value: meterDetail?.opportunityId || '-',
    },
    {
      label: 'Panel Name',
      renderField: () =>
        meterDetail?.primarypanelName ?
          <StyledLink
            href={`/account-management/sites/${meterDetail?.siteId}/panels/${meterDetail?.primaryPanelId}`}
            external
          >
            <Link>
              {meterDetail?.primarypanelName}
              {LinkIcon}
            </Link>
          </StyledLink>
        : '-',
    },
    {
      label: 'Panel Config',
      value: meterDetail?.primaryPanelConfig || '-',
    },
    {
      label: 'Last Config Date',
      value: renderTimestamp(
        meterDetail?.lastConfigDate,
        meterDetail?.siteTimezone,
        'MMMM DD, YYYY hh:mm:ss A z',
      ),
    },
    {
      label: 'Online Status',
      renderField: () =>
        meterStatusLoading ? 'Loading...' : (
          <StatusIconItem status={onlineStatus} />
        ),
    },
    {
      label: 'Last Measurement Date',
      value:
        meterStatusLoading ? 'Loading...' : (
          renderTimestamp(
            originalLastMeasurementDate,
            meterDetail?.siteTimezone,
            'MMMM DD, YYYY hh:mm:ss A z',
          )
        ),
    },
  ]

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner size='medium' />
      </SpinnerWrapper>
    )
  }
  const CellWithToolTip = (obj: {
    isExpanded?: boolean
    value: string
    cell: {
      column: {
        id: string
      }
    }
  }) => {
    const {
      isExpanded = false,
      value = '',
      cell: {
        column: { id },
      },
    } = obj
    return isExpanded ? '' : (
        <Tippy content={value} delay={500}>
          <Cell danger={id === 'measurement'}>{value}</Cell>
        </Tippy>
      )
  }

  const noDataText =
    isLabelCheckOnly ?
      ' All the Label checks for the meter are passed'
    : 'All the Data and Label checks for the meter are passed'
  const noData =
    failedDataQualityTests?.length === 0 &&
    failedLabelQualityTests?.length === 0
  const renderTableView = (label: string, data: DataType, columns) => {
    if (!data.length) return null
    return (
      <>
        <PanelHeading>{label}</PanelHeading>
        <TableWrapperStyled>
          <RedaptiveReactTable7
            columns={columns(
              ({ original }) => (
                <StyledLink
                  href={`/account-management/meters/${meterDetail.meterId}`}
                >
                  {original?.meterChannel}
                </StyledLink>
              ),
              CellWithToolTip,
            )}
            data={data}
            enablePagination
            initialPageSize={data.length}
            filterable
            globalFilterable={false}
            hideDropdownPagination
            showLinearPagination
            getRowProps={(row) => ({
              style: {
                background: row.index % 2 === 0 ? colors.gray9 : colors.white,
              },
            })}
            showTableScrollArrows={false}
          />
        </TableWrapperStyled>
      </>
    )
  }

  if (errorMessage || error) {
    return null
  }

  return (
    <Wrapper>
      {meterDetail && (
        <PanelHeading>
          Meter Info
          <Actions>
            <span onClick={onReturnToDashboard}>Return To Dashboard</span>
            <span onClick={onReturnToMeterSelection}>
              Return To Meter Selection
            </span>
            <span onClick={onRerun}>Re-Run Analysis</span>
          </Actions>
        </PanelHeading>
      )}
      {meterDetail && <VerticalTable.Basic fields={fields} columned={false} />}
      {renderTableView(
        'Label Check',
        failedLabelQualityTests,
        labelCheckColumns,
      )}
      {renderTableView('Data Check', failedDataQualityTests, dataCheckColumns)}
      {noData && (data || evDataFromPrevPage) && (
        <NoData>
          <img src={electronVerificationImg} />
          <NoDataText>{noDataText}</NoDataText>
        </NoData>
      )}
    </Wrapper>
  )
}
