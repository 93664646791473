// eslint-disable-next-line import/prefer-default-export
export const ADD_SCENARIO_STATES = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success',
  LOADING: 'loading',
  FILE_NAME_LENGTH: 'fileNameLength',
  FIELD_MAPPING_ERROR: 'fieldMappingError',
}

export const UPDATE_SALESFORCE_STATES = {
  SUCCESS: 'success',
  LOADING: 'loading',
}

export const scenarioFieldsPrecision = {
  materialCosts: 2,
  laborCosts: 2,
  otherCost: 2,
  vendorSalesTax: 2,
  utilityRate: 4,
  metricTonsOfCo2AvoidedOverEul: 0,
  metricTonsOfCo2AvoidedAnnually: 0,
  metricTonsOfCo2AvoidedContractTerm: 0,
  savedDollarsOnAvoidedRecPurchase: 2,
  lightingRelampBypassBallastCount: 0,
  lightingReplaceFixtureCount: 0,
  lightingRetrofitKitCount: 0,
  lightingRemoveFixtures: 0,
  lightingSensorsOnly: 0,
  lightingNoChange: 0,
  lightingRelampCount: 0,
  preKw: 2,
  annualPreKwh: 4,
  postKw: 2,
  annualPostKwh: 2,
  maintenanceSavingsPerFixture: 2,
}

export const ACCEPTED_IMAGE_EXTENSIONS = [
  'jpeg',
  'jpg',
  'png',
  'webp',
  'jp2',
  'tiff',
  'avif',
  'heif',
  'raw',
]
export const API_SOURCE = {
  scenario: 'scenario',
}

export enum GROUPS {
  ENERGY_STAR_ADMIN = 'energy-star-admin',
  ISR_PROCESSOR_TOOL = 'isr-processor-tool',
}

export const currentYear = new Date().getFullYear()
