/* eslint-disable func-names */
import * as Yup from 'yup'

import type { FTProposalScenario } from '../../../ducks/proposals/scenarios'
import { scenarioFieldInfo } from '../../../ducks/proposals/scenarios'

const validationsDefaults = Object.keys(scenarioFieldInfo).reduce(
  (acc, cur) =>
    scenarioFieldInfo[cur].required ?
      {
        ...acc,
        [cur]: Yup.string()
          .required('required')
          .label(scenarioFieldInfo[cur].label),
      }
    : { ...acc },
  {},
)
export default ({
  scenario,
  scenarios,
}: {
  scenario: FTProposalScenario
  scenarios: Array<FTProposalScenario>
}) =>
  Yup.object().shape({
    ...validationsDefaults,
    name: Yup.string()
      .required('required')
      .label(scenarioFieldInfo.name.label)
      .test(
        'name',
        'Scenario ID cannot match previous scenario ids.',
        (value) =>
          ('id' in scenario && scenario.name === value) ||
          !scenarios.filter(({ name }) => name === value).length,
      ),
  })
