import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { call, put, takeEvery } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { isVariantActive } from '../utils'

export type FTFetchHvacAssetUploadJobAction = {
  jobId: string
}
export type FTFetchHvacAssetUploadJobMockAction = {
  jobId: string
  mockAxios: Record<string, any>
}
export type FTPostHvacAssetAction = {
  file: any
}
type FTHvacAssetUploadError = {
  prettyMessage: string
}
type FTHvacAssetErrors = {
  asset: Array<FTHvacAssetUploadError>
  company: Array<FTHvacAssetUploadError>
  site: Array<FTHvacAssetUploadError>
  hvacEquipment: Array<FTHvacAssetUploadError>
}
export type FTHvacAssetState = {
  job: {
    id: string
    progress: number
    reason: string
    status: string
  }
  errors: FTHvacAssetErrors
  isPosting: boolean
  isCheckingJob: boolean
}
type FTHvacAssetStateRoot = {
  hvacAsset: FTHvacAssetState
}
export const types = {
  FETCH_HVAC_ASSET_UPLOAD_JOB: 'FETCH_HVAC_ASSET_UPLOAD_JOB',
  FETCH_HVAC_ASSET_UPLOAD_JOB_ERROR: 'FETCH_HVAC_ASSET_UPLOAD_JOB_ERROR',
  FETCH_HVAC_ASSET_UPLOAD_JOB_SUCCESS: 'FETCH_HVAC_ASSET_UPLOAD_JOB_SUCCESS',
  POST_HVAC_ASSET: 'POST_HVAC_ASSET',
  POST_HVAC_ASSET_ERROR: 'POST_HVAC_ASSET_ERROR',
  POST_HVAC_ASSET_SUCCESS: 'POST_HVAC_ASSET_SUCCESS',
  RESET_HVAC_ASSET_STATE: 'RESET_HVAC_ASSET_STATE',
}
export const selectHvacAsset = (
  state: FTHvacAssetStateRoot,
): FTHvacAssetState => state.hvacAsset
export const initialState: FTHvacAssetState = {
  job: {
    id: '',
    progress: 0,
    reason: '',
    status: 'INPROGRESS',
  },
  errors: {
    asset: [],
    company: [],
    site: [],
    hvacEquipment: [],
  },
  isPosting: false,
  isCheckingJob: false,
}

const hvacAssetReducer = (
  state: FTHvacAssetState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.FETCH_HVAC_ASSET_UPLOAD_JOB:
      return { ...state, isCheckingJob: true }

    case types.FETCH_HVAC_ASSET_UPLOAD_JOB_SUCCESS:
      return { ...state, isCheckingJob: false, job: action.job }

    case types.POST_HVAC_ASSET:
      return {
        ...state,
        isPosting: true,
        job: {
          id: '',
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.POST_HVAC_ASSET_ERROR:
      return { ...state, isPosting: false, errors: action.errors }

    case types.POST_HVAC_ASSET_SUCCESS:
      return {
        ...state,
        isPosting: false,
        job: {
          id: action.payload,
          progress: 0,
          reason: '',
          status: 'INPROGRESS',
        },
      }

    case types.RESET_HVAC_ASSET_STATE:
      return initialState

    default:
      return state
  }
}

export default hvacAssetReducer
export const actions = {
  postHvacAsset: (params: FTPostHvacAssetAction) => ({
    type: types.POST_HVAC_ASSET,
    ...params,
  }),
  postHvacAssetError: (errors: FTHvacAssetErrors) => ({
    type: types.POST_HVAC_ASSET_ERROR,
    errors,
  }),
  fetchHvacAssetUploadJob: (params: FTFetchHvacAssetUploadJobAction) => ({
    type: types.FETCH_HVAC_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchHvacAssetUploadJobMock: (
    params: FTFetchHvacAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_HVAC_ASSET_UPLOAD_JOB,
    ...params,
  }),
  fetchHvacAssetUploadJobMockError: (
    params: FTFetchHvacAssetUploadJobMockAction,
  ) => ({
    type: types.FETCH_HVAC_ASSET_UPLOAD_JOB,
    ...params,
  }),
  resetHvacAssetState: () => ({
    type: types.RESET_HVAC_ASSET_STATE,
  }),
}
type FTPostHvacAssetException = {
  name: string
  errors: FTHvacAssetErrors
  toString: string
}

const PostHvacAssetException = ({ errors }): FTPostHvacAssetException => ({
  name: 'PostHvacAssetError',
  errors,
  toString: 'PostHvacAssetError',
})

const handlePostHvacAssetError = (error: Record<string, any>) => {
  const { response } = error || {}
  const { status, data } = response || {}

  if (status === 400) {
    const { errors } = data || initialState.errors
    throw PostHvacAssetException({
      errors,
    })
  } else {
    handleAxiosError(error)
  }
}

// API
export class API {
  static postHvacAsset({ file }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/hvac`
    const formData = new FormData()
    formData.set('file', file)

    if (isVariantActive('hvac-asset-manager-mock-error')) {
      const mockAxios = axios.create()
      const mock = new MockAdapter(mockAxios, {
        delayResponse: 500,
      })

      mock
        .onPost(url)
        .reply(400, {
          errors: {
            asset: [
              {
                row: 1,
                prettyMessage: 'Test Message',
              },
              {
                row: 2,
                prettyMessage: 'Test Message',
              },
              {
                row: 3,
                prettyMessage: 'Test Message',
              },
              {
                row: 4,
                prettyMessage: 'Test Message',
              },
            ],
            company: [
              {
                row: 1,
                prettyMessage: 'Test Message',
              },
              {
                row: 2,
                prettyMessage: 'Test Message',
              },
              {
                row: 3,
                prettyMessage: 'Test Message',
              },
              {
                row: 4,
                prettyMessage: 'Test Message',
              },
            ],
            site: [
              {
                row: 1,
                prettyMessage: 'Test Message',
              },
              {
                row: 2,
                prettyMessage: 'Test Message',
              },
              {
                row: 3,
                prettyMessage: 'Test Message',
              },
              {
                row: 4,
                prettyMessage: 'Test Message',
              },
            ],
            'hvac-equipment': [
              {
                row: 1,
                prettyMessage: 'Test Message',
              },
              {
                row: 2,
                prettyMessage: 'Test Message',
              },
              {
                row: 3,
                prettyMessage: 'Test Message',
              },
              {
                row: 4,
                prettyMessage: 'Test Message',
              },
            ],
          },
        })
        .onAny()
        .passThrough()
      return mockAxios({
        method: 'post',
        url,
        data: formData,
        headers: defaultHeaders(),
      })
        .then(({ data: { jobId } }) => jobId)
        .catch(handlePostHvacAssetError)
    }

    if (
      isVariantActive('hvac-asset-manager-mock') ||
      isVariantActive('hvac-asset-manager-mock-job-error')
    ) {
      return Promise.resolve('mockJobId').then((data) => data)
    }

    return axios({
      method: 'post',
      url,
      data: formData,
      headers: defaultHeaders(),
    })
      .then(({ data: { jobId } }) => jobId)
      .catch(handlePostHvacAssetError)
  }

  static fetchHvacAssetUploadJob({ jobId }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return axios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchHvacAssetUploadJobMock({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason: headers['job-reason'] || '',
        status: headers['job-status'] || 'INPROGRESS',
      }))
      .catch(handleAxiosError)
  }

  static fetchHvacAssetUploadJobMockError({
    jobId,
    mockAxios,
  }: Record<string, any>) {
    const url = `${consoleApiUrl()}/admin/jobs/${jobId}`
    return mockAxios({
      method: 'head',
      url,
      headers: defaultHeaders(),
    })
      .then(({ headers }) => ({
        id: jobId,
        progress: headers['job-progress'] || 0,
        reason:
          headers['job-reason'] || 'MeasureCode with id F43ILLAA not found',
        status: headers['job-status'] || 'ERROR',
      }))
      .catch(handleAxiosError)
  }
}

function* postHvacAssetSaga(
  params: FTPostHvacAssetAction,
): Generator<any, void, any> {
  try {
    const payload = yield call(API.postHvacAsset, params)
    yield put({
      type: types.POST_HVAC_ASSET_SUCCESS,
      payload,
    })
  } catch (error) {
    if (error.name && error.name === 'PostHvacAssetError') {
      const { errors } = error
      yield put(
        actions.postHvacAssetError({
          ...errors,
          hvacEquipment: errors['hvac-equipment'],
        }),
      )
    } else {
      yield handleSagaError('POST_HVAC_ASSET_ERROR_GENERAL', error)
    }
  }
}

function* fetchHvacAssetUploadJobSaga(
  params: FTFetchHvacAssetUploadJobAction,
): Generator<any, void, any> {
  try {
    let job

    if (isVariantActive('hvac-asset-manager-mock')) {
      job = yield call(API.fetchHvacAssetUploadJobMock, params)
    } else if (isVariantActive('hvac-asset-manager-mock-job-error')) {
      job = yield call(API.fetchHvacAssetUploadJobMockError, params)
    } else {
      job = yield call(API.fetchHvacAssetUploadJob, params)
    }

    yield put({
      type: types.FETCH_HVAC_ASSET_UPLOAD_JOB_SUCCESS,
      job,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_HVAC_ASSET_UPLOAD_JOB_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.POST_HVAC_ASSET, postHvacAssetSaga),
  takeEvery(types.FETCH_HVAC_ASSET_UPLOAD_JOB, fetchHvacAssetUploadJobSaga),
]
