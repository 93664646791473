import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../components/Button'
import CloseButton from '../../../components/CloseButton'
import ArrowUpwardIcon from '../../../components/Icons/ArrowUpwardIcon'
import Spinner from '../../../components/Spinner'
import {
  actions as dsiActions,
  selectInvoiceProcessing,
} from '../../../ducks/billing/DataSolutionsInvoicing/dataSolutionInvoicing'
import {
  ArrowUpwardIconStyles,
  ButtonsStyles,
} from '../../ToolsAndReports/LightingAsBuiltsManagerPage'
import { ErrorMessageDisplay } from '../ISRFileProcessor/ErrorMessageDisplay'
import {
  FileNameStyled,
  ISRFileDropZone as DSIFileDropZone,
  modalCustomStyles,
  ModalHeaderWrapper,
  UploadAssetListItems,
} from '../ISRFileProcessor/styles'
import {
  FILE_NAME_TOO_LONG_MESSAGE,
  MAX_FILE_LENGTH,
} from '../ISRFileProcessor/utils/utils'

if (document.getElementById('root')) {
  Modal.setAppElement('#root')
}

type FTProps = {
  showDilog: boolean
  setShowDialog: (show: boolean) => void
}

const FileUploadModal = ({ showDilog, setShowDialog }: FTProps) => {
  const [dsiFileName, setDsiFileName] = useState('')
  const dispatch = useDispatch()
  const {isUploading, error:dsiUploadError} =
    useSelector(selectInvoiceProcessing);

  // handlers
  const closeModal = useCallback(() => {
    setShowDialog(false)
    setDsiFileName('')
    dispatch(dsiActions.resetInvoiceProcessingState())
  }, [setShowDialog])

  const handleDropDSIFile = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setDsiFileName(file.name)
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const handleFileUpload = (file: File) => {
    dispatch(dsiActions.uploadInvoiceFile({ file }))
    setDsiFileName('')
  }

  // file length validation
  const dsiFileNameToolong = useMemo(
    () => dsiFileName.length > MAX_FILE_LENGTH,
    [dsiFileName],
  )
  const {
    acceptedFiles: acceptedFilesDSIFileUpload,
    getInputProps: getInputPropsDSIFileUpload,
    getRootProps: getRootPropsDSIFileUpload,
    open: openDSIFileUpload,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleDropDSIFile,
  })

  return (
    <Modal
      isOpen={showDilog}
      onRequestClose={closeModal}
      style={modalCustomStyles}
      contentLabel='Data Solutions Invoice File Upload'
    >
      <ModalHeaderWrapper>
        <b>Data Solutions Invoice File Upload</b>
        <CloseButton
          data-testid='close__button'
          onClick={closeModal}
        />
      </ModalHeaderWrapper>

      <UploadAssetListItems>
        <li>Upload the Invoice file here for validation. </li>
        <li>The upload file must be a .csv</li>
      </UploadAssetListItems>

      <DSIFileDropZone
        {...getRootPropsDSIFileUpload()}
        data-testid='data__solutions__invoice__dropzone'
        isError={Boolean(dsiFileNameToolong || dsiUploadError)}
      >
        {dsiFileNameToolong && (
          <ErrorMessageDisplay message={FILE_NAME_TOO_LONG_MESSAGE} />
        )}
        {dsiUploadError && <ErrorMessageDisplay message={dsiUploadError} />}

        <input
          name='data solution invoicing File'
          {...getInputPropsDSIFileUpload()}
        />

        {isUploading ?
          <div>
            <Spinner inline size='micro' />
            <b>Upload in progress...</b>
          </div>
        : !dsiFileNameToolong &&
          !dsiUploadError && (
            <ArrowUpwardIconStyles>
              <ArrowUpwardIcon data-testid='arrow__upward__icon' />
            </ArrowUpwardIconStyles>
          )
        }

        <FileNameStyled isError={Boolean(dsiFileNameToolong || dsiUploadError)}>
          {dsiFileName || (!isUploading && 'Drag file to upload')}
        </FileNameStyled>

        {!dsiFileName && !dsiUploadError ?
          <Button primary onClick={openDSIFileUpload}>
            Choose File
          </Button>
        : <ButtonsStyles>
            <Button onClick={closeModal}>Close</Button>
            {dsiFileName && !dsiFileNameToolong ?
              <Button
                primary
                onClick={() => handleFileUpload(acceptedFilesDSIFileUpload[0])}
              >
                Upload
              </Button>
            : <Button primary onClick={openDSIFileUpload}>
                Re-upload
              </Button>
            }
          </ButtonsStyles>
        }
      </DSIFileDropZone>
    </Modal>
  )
}

export default FileUploadModal
