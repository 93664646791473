import FeatureValidator from '../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../authorization/features'
import ActionCard from '../../components/ActionCard'
import ActionCardsPage from '../../components/ActionCardsPage'
import type { FTHistory, FTRouterMatch } from '../../types'

type FTProps = {
  history: FTHistory
  match: FTRouterMatch
}

const ProposalOperationsMainPage = (props: FTProps) => {
  const {
    history,
    match: { path },
  } = props

  const navigateSpecSheets = () => history.push(`${path}/spec-sheets`)

  const navigateProposalsEngine = () => history.push(`${path}/proposals-engine`)

  return (
    <ActionCardsPage>
      <FeatureValidator feature={AuthorizedFeatures.proposalsEngine}>
        <ActionCard
          buttonText='GO TO ENGINE'
          description={`A web-based version of the proposal model.
           Create, view, and perform opportunity scenario analyses.`}
          handleClick={navigateProposalsEngine}
          title='Proposal Engine'
        />
      </FeatureValidator>
      <ActionCard
        buttonText='VIEW SPEC SHEETS'
        description='View spec sheet links or upload new spec sheets.'
        handleClick={navigateSpecSheets}
        title='Spec Sheets'
      />
    </ActionCardsPage>
  )
}

export default ProposalOperationsMainPage
