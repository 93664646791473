import { Component } from 'react'
import styled from 'styled-components'

import { getPageSizes } from '../../ducks/utils'

const Styles = styled.div`
  position: absolute;
  top: 3px;
  right: 0;
  user-select: none;
`
const PageSizeItem = styled.span`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? '600' : '300')};
  color: #337ab7;
  text-decoration: underline;
  padding-left: 10px;

  &:not(:last-child) {
    padding-right: 10px;
  }

  &:not(:first-child) {
    border-left: 1px solid #979797;
  }
`
type FTProps = {
  total: number
  currentSize: number
  onUpdate: (pageSize: number) => null
  hideAllPagination?: boolean
  paginationSize?: Array<number>
}
export default class PageSizeSelector extends Component<FTProps> {
  onChange = (choice) => () => {
    const { currentSize, onUpdate } = this.props
    const { value: pageSize } = choice

    if (pageSize === currentSize) {
      return
    }

    onUpdate(pageSize)
  }

  render() {
    const { total, currentSize, hideAllPagination, paginationSize } = this.props
    const pageSizes = getPageSizes(total, hideAllPagination, paginationSize)
    return (
      <Styles>
        {pageSizes.length > 0 ? 'View' : ''}
        {pageSizes.map((choice) => (
          <PageSizeItem
            active={
              choice.value === currentSize ||
              (currentSize >= total && choice.label === 'All')
            }
            key={choice.label}
            onClick={this.onChange(choice)}
          >
            {choice.label}
          </PageSizeItem>
        ))}
      </Styles>
    )
  }
}
