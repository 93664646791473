import { capitalCase } from 'capital-case'
import debounce from 'debounce'
import moment from 'moment/moment'
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Breadcrumbs'
import DatePicker, {
  DatePickerStyles,
  DatePickerTextWrapperStyled,
} from '../../components/DatePicker'
import SearchBar, {
  SearchBarStyled,
} from '../../components/EntityList/SearchBar'
import Paginator from '../../components/Paginator'
import RedaptiveReactTable7, {
  ReactTableStyled,
  ReactTableWrapperStyles,
  TdInnerStyled,
} from '../../components/RedaptiveReactTable7'
import TableHead from '../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../components/Spinner'
import StyledLink from '../../components/StyledLink'
import * as consts from '../../constants'
import type { FTMeterInstallSubmission } from '../../ducks/meterInstallSubmissions/meterInstallSubmissions'
import {
  actions as meterInstallSubmissionsActions,
  apiOrderMap,
  orderMap,
  selectMeterInstallSubmissionsEntity,
} from '../../ducks/meterInstallSubmissions/meterInstallSubmissions'
import '../../types'
import type { FTSortable } from '../../types'
import {
  defaultMeterInstallHeaders,
  defaultMeterInstallWidths,
} from '../../utils/MeterInstallSubmissionMappings'
import { colors } from '../../utils/themes'

type FTProps = {
  actions: {
    fetchInstallSubmissions: (...args: Array<any>) => any
  }
  searchParams: Record<string, any>
  submissionsLoading: boolean
  orderBy: FTSortable
  submissions: Array<FTMeterInstallSubmission>
  pageNumber: number
  pageSize: number
  next: string
  previous: string
  totalPages: number
}
const TableStyles = styled.div`
  td {
    &:first-child {
      > ${TdInnerStyled} {
        margin-left: 4px;
        padding-left: 0;
      }
    }
  }

  ${ReactTableStyled} {
    &:last-child {
      ${SearchBarStyled} {
        width: 80px;
      }
    }
  }

  ${ReactTableWrapperStyles} {
    overflow-x: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`
const MainStyles = styled.div`
  padding-bottom: 80px;
  margin-top: 36px;

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }

  ${SearchBarStyled} {
    margin-bottom: 15px;
  }
`
const DatePickerContainerStyled = styled.div`
  ${DatePickerStyles} {
    width: 215px;
    border-radius: 0px;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #c7c7c7;
    color: #4a4a4a;
    height: 36px;
    font: 14px 'Avenir Next';
    padding-left: 2px;
  }

  ${DatePickerTextWrapperStyled} {
    width: 210px;
  }
`
const TableLinkStyled = styled(StyledLink)`
  font-weight: 500;
`
const FilterContainerStyled: ComponentType<{
  width: number
}> = styled.div`
  input {
    width: ${({ width }) => `${width}px`};
  }
`
const BreadcrumbsStyles = styled.div`
  margin-bottom: 24px;
`
const TitleStyles = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
`
const EmptyTableIndicatorStyled = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 400px;
`
const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 350px;
`
const PaginatorStyles = styled.div`
  padding-top: 31px;
`
const baseOptions = [
  {
    label: 'Last 24 hours',
    value: consts.LAST_24_HOURS,
  },
  {
    label: 'Last 3 Days',
    value: consts.LAST_3_DAYS,
  },
  {
    label: 'Last 7 Days',
    value: consts.LAST_7_DAYS,
  },
  {
    label: 'This Month',
    value: consts.THIS_MONTH,
  },
  {
    label: 'All',
    value: consts.PROGRAM_TO_DATE,
  },
]
const format = 'MM/DD/YYYY'

// Hardcoding to Jan 2022.
const getProgramStartDate = () => moment('20220101', consts.URL_DATE_FORMAT)

const MeterInstallSubmissionsPage = (props: FTProps) => {
  const {
    actions,
    submissionsLoading,
    orderBy,
    submissions,
    searchParams,
    pageNumber,
    pageSize,
    next,
    previous,
    totalPages,
  } = props
  const [submissionDatePeriod, setSubmissionDatePeriod] =
    useState('PROGRAM_TO_DATE')
  const [configDatePeriod, setConfigDatePeriod] = useState('PROGRAM_TO_DATE')
  const [submissionDateRange, setSubmissionDateRange] = useState({
    from: moment('2022-01-01').format(consts.DATE_FORMAT_DATA_API_REQUEST),
    to: moment().format(consts.DATE_FORMAT_DATA_API_REQUEST),
  })
  const [configDateRange, setConfigDateRange] = useState({
    startConfigAcceptedDate: moment('2022-01-01').format(
      consts.DATE_FORMAT_DATA_API_REQUEST,
    ),
    endConfigAcceptedDate: moment().format(consts.DATE_FORMAT_DATA_API_REQUEST),
  })
  const [orderParams, setOrderParams] = useState(orderBy)
  // This doesn't handle the API call upon searching
  useEffect(() => {
    actions.fetchInstallSubmissions({
      orderBy: orderParams,
      ...searchParams,
    })
  }, [orderParams])
  const breadcrumbs = [
    {
      href: '/reports',
      text: 'Tools & Reports',
    },
    {
      href: '/reports/meter-install-submissions',
      text: 'Meter Install Submissions',
    },
  ]
  const renderSubmissionDateLabel = useCallback(() => {
    const { start, end } = {
      start: submissionDateRange.from,
      end: submissionDateRange.to,
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }, [submissionDateRange])
  const renderConfigDateLabel = useCallback(() => {
    const { start, end } = {
      start: configDateRange.startConfigAcceptedDate,
      end: configDateRange.endConfigAcceptedDate,
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }, [configDateRange])

  // Will handle the API calls upon search
  const search = (searchBy: { field: string; term: string }) => {
    actions.fetchInstallSubmissions({
      ...searchParams,
      [searchBy.field]: searchBy.term,
      orderBy: orderParams,
    })
  }

  const handleSearch = debounce(search, 500)

  // Will handle the API calls upon paginator actions
  const browseToPage = (number) => {
    actions.fetchInstallSubmissions({
      orderBy: orderParams,
      pageNumber: number,
      ...searchParams,
    })
  }

  const handleUpdateSubmissionDateRange = useCallback(
    ({ endDate, startDate, selectedOptionDate }) => {
      if (selectedOptionDate) {
        setSubmissionDatePeriod(selectedOptionDate)
      } else {
        setSubmissionDatePeriod('')
      }

      const updatedSearchParams = {
        from: moment(startDate)
          .utc()
          .startOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
        to: moment(endDate)
          .utc()
          .endOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
      }
      setSubmissionDateRange({ ...updatedSearchParams })
      actions.fetchInstallSubmissions({
        orderBy,
        ...searchParams,
        ...updatedSearchParams,
      })
    },
    [orderBy, searchParams],
  )
  const handleUpdateConfigDateRange = useCallback(
    ({ endDate, startDate, selectedOptionDate }) => {
      if (selectedOptionDate) {
        setConfigDatePeriod(selectedOptionDate)
      } else {
        setConfigDatePeriod('')
      }

      const updatedSearchParams = {
        startConfigAcceptedDate: moment(startDate)
          .utc()
          .startOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
        endConfigAcceptedDate: moment(endDate)
          .utc()
          .endOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
      }
      setConfigDateRange({ ...updatedSearchParams })
      actions.fetchInstallSubmissions({
        orderBy,
        ...searchParams,
        ...updatedSearchParams,
      })
    },
    [orderBy, searchParams],
  )
  const handleSortClick = useCallback(
    ({
      currentTarget: {
        dataset: { id },
      },
    }) => {
      if (orderParams.field) {
        if (orderParams.field !== orderMap[id]) {
          setOrderParams({
            field: orderMap[id],
            sort: 'ASC',
          })
        } else if (orderParams.sort === 'DESC') {
          setOrderParams({
            field: orderMap[id],
            sort: 'ASC',
          })
        } else {
          setOrderParams({
            field: orderMap[id],
            sort: 'DESC',
          })
        }
      }
    },
    [orderParams],
  )
  const selectFilter = useCallback(
    (id) => {
      if (id === 'modified') {
        return (
          <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
            <DatePickerContainerStyled key={id}>
              <DatePicker
                end={submissionDateRange.to}
                options={baseOptions}
                period={submissionDatePeriod}
                getProgramStartDate={getProgramStartDate}
                start={submissionDateRange.from}
                text={renderSubmissionDateLabel()}
                updateDateRange={handleUpdateSubmissionDateRange}
              />
            </DatePickerContainerStyled>
          </FilterContainerStyled>
        )
      }

      // TODO: Have a single filter for both the Date fields so as to reuse the util functions.
      if (id === 'configAcceptedDate') {
        return (
          <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
            <DatePickerContainerStyled key={id}>
              <DatePicker
                end={configDateRange.endConfigAcceptedDate}
                options={baseOptions}
                period={configDatePeriod}
                getProgramStartDate={getProgramStartDate}
                start={configDateRange.startConfigAcceptedDate}
                text={renderConfigDateLabel()}
                updateDateRange={handleUpdateConfigDateRange}
                rightAligned
              />
            </DatePickerContainerStyled>
          </FilterContainerStyled>
        )
      }

      return (
        <FilterContainerStyled
          width={defaultMeterInstallWidths[id].minWidth}
          key={id}
        >
          <SearchBar
            onSearch={handleSearch}
            filterField={id}
            previous={{
              field: id,
              term: searchParams[id] ? searchParams[id].toLowerCase() : '',
            }}
          />
        </FilterContainerStyled>
      )
    },
    [searchParams],
  )
  const tableHeaderRows = useMemo(() => {
    const headerRows = defaultMeterInstallHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      Filter: () => selectFilter(item.id),
      sortable: item.id !== 'panelNames',
      handleSortClick: item.id !== 'panelNames' ? handleSortClick : () => {},
      sorted: apiOrderMap[orderBy.field] === item.id,
      sortDesc: orderBy.sort === 'DESC',
      minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
      maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
    }))
    return [
      {
        id: 'row1',
        headers: headerRows,
      },
    ]
  }, [
    submissionDateRange,
    configDateRange,
    renderConfigDateLabel,
    renderSubmissionDateLabel,
    orderBy,
    searchParams,
  ])
  const booleanCell = useCallback(
    (value) => capitalCase(value.toLowerCase()),
    [],
  )
  const dateCell = useCallback(
    (value) => `${moment(value).format('MM/DD/YYYY, hh:mm:ss A z')} UTC`,
    [],
  )
  const submissionDateCell = useCallback((cellProps) => {
    const {
      value,
      row: {
        original: { id: submissionId },
      },
    } = cellProps
    return (
      <TableLinkStyled
        href={`/reports/meter-install-submissions/${submissionId}`}
        data-id={submissionId}
      >
        {moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC
      </TableLinkStyled>
    )
  })
  const getColumnCell = useCallback((cellProps) => {
    const {
      column: { id },
      value,
    } = cellProps

    switch (id) {
      case 'configAcceptedDate':
        return dateCell(value)

      case 'modified':
        return submissionDateCell(cellProps)

      case 'evVerified':
      case 'cellRouterInstalled':
      case 'isAssignedToSite':
        return booleanCell(value)

      default:
        return value || '-'
    }
  }, [])
  const columns = useMemo(
    () =>
      defaultMeterInstallHeaders.map((item) => ({
        accessor: item.id,
        Cell: getColumnCell,
        Header: item.label,
        minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
        maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
      })),
    [orderBy, getColumnCell],
  )
  const TableHeadComponent = useCallback(
    () => <TableHead rows={tableHeaderRows} />,
    [tableHeaderRows],
  )
  return (
    <MainStyles>
      <BreadcrumbsStyles>
        <Breadcrumbs items={breadcrumbs} />
      </BreadcrumbsStyles>
      <TitleStyles>Meter Install Submissions</TitleStyles>
      <TableStyles>
        <RedaptiveReactTable7
          columns={columns}
          data={submissions}
          TableHead={TableHeadComponent}
          filterable
          globalFilterable={false}
        />
        {!submissionsLoading && (
          <PaginatorStyles>
            <Paginator
              total={totalPages}
              current={pageNumber || 1}
              next={next}
              prev={previous}
              perPage={pageSize}
              browseToPage={browseToPage}
            />
          </PaginatorStyles>
        )}
        {!!submissionsLoading && (
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        )}
        {!submissionsLoading && !submissions.length && (
          <EmptyTableIndicatorStyled>
            {' '}
            No data found. Change the filters and try again.
          </EmptyTableIndicatorStyled>
        )}
      </TableStyles>
    </MainStyles>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(meterInstallSubmissionsActions, dispatch) },
})

const mapStateToProps = (state) => {
  const meterInstallSubmissionsEntity =
    selectMeterInstallSubmissionsEntity(state)
  const {
    meta: {
      loading: submissionsLoading,
      orderBy,
      searchParams,
      pageNumber,
      pageSize,
      next,
      previous,
      totalPages,
    },
    items: submissions,
  } = meterInstallSubmissionsEntity
  return {
    submissionsLoading,
    orderBy,
    searchParams,
    submissions,
    pageNumber,
    pageSize,
    next,
    previous,
    totalPages,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeterInstallSubmissionsPage)
