import { withFormik } from 'formik'
import * as Yup from 'yup'

import Form from './Form'

const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    voltage: Yup.string().required('Required'),
  })

const validPanelLevels = [
  { id: 'MDP', name: 'MDP' },
  { id: 'SUB PANEL', name: 'SUB PANEL' },
]

export default withFormik({
  validationSchema: getValidationSchema,

  handleSubmit(values, formikBag) {
    const {
      props: { submitAction, siteId },
    } = formikBag
    submitAction(
      {
        siteId,
        ...values,
      },
      formikBag,
    )
  },

  mapPropsToValues(props) {
    const {
      description = '',
      id,
      name = '',
      siteId,
      type = null,
      voltage = null,
      parentPanelId = '',
      parentPanelName = '',
      panelLevel = '',
    } = props

    return {
      description,
      id,
      name,
      siteId,
      type,
      voltage,
      parentPanelId,
      parentPanelName,
      panelLevel,
    }
  },
  mapPropsToStatus() {
    return {
      validPanelLevels,
    }
  },
})(Form)
