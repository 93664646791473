import { Component } from 'react'
import styled from 'styled-components'

import './Paginator.css'
import { ELLIPSES, PAGINATOR_NEXT, PAGINATOR_PREV } from '../constants/strings'

const PrevNextButtonStyled = styled.button`
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  height: 28px;
  margin: 0 12px;
  width: 28px;
  cursor: pointer;
`

type FTProps = {
  browseToPage: (...args: Array<any>) => any
  total: number
  current: number
  showArrows?: boolean
  next: string | null | undefined
  prev: string | null | undefined
}
type FTPageNumberType = {
  className: string
  body: string | number
  onClick: ((...args: Array<any>) => any) | null | undefined
  key?: string
}
export default class Paginator extends Component<FTProps> {
  browseTo = (page: number) => {
    const { current, browseToPage } = this.props

    if (current !== page) {
      browseToPage(page)
    }
  }

  renderPageNumbersCentered = (numbers: Array<FTPageNumberType>) =>
    numbers.map<React.ReactNode>((n) => (
      <span
        key={n.key ? n.key : n.body}
        className={n.className}
        onClick={n.onClick}
      >
        {n.body}
      </span>
    ))

  generatePageNumbers = (
    start: number,
    end: number,
    current: number,
  ): Array<FTPageNumberType> => {
    const numbers = []

    for (let i = start; i <= end; i += 1) {
      const classes =
        current === i ? `page page-${i} current` : `page page-${i}`
      numbers.push({
        className: classes,
        body: i,
        onClick: () => {
          this.browseTo(i)
        },
      })
    }

    return numbers
  }

  generatePageNumbersCentered() {
    const { total, current } = this.props
    const pageWindow = 5
    const halfWindow = Math.floor(pageWindow / 2)
    let numbers
    let start
    let end

    if (total >= pageWindow && total <= pageWindow + 2) {
      start = 1
      end = total
      numbers = this.generatePageNumbers(start, end, current)
      return this.renderPageNumbersCentered(numbers)
    }

    if (current > total - pageWindow + 1) {
      start = Math.max(1, total - pageWindow + 1)
      end = total
    } else {
      start = current < pageWindow ? 1 : current - halfWindow
      end =
        current < pageWindow ?
          Math.min(pageWindow, total)
        : Math.min(current + halfWindow, total)
    }

    numbers = this.generatePageNumbers(start, end, current)
    const firstPage: FTPageNumberType = {
      className: 'page page-1',
      body: 1,
      onClick: () => {
        this.browseTo(1)
      },
    }
    const lastPage: FTPageNumberType = {
      className: `page page-${total}`,
      body: total,
      onClick: () => {
        this.browseTo(total)
      },
    }
    const leftEllipses: FTPageNumberType = {
      className: 'left-ellipses ellipses',
      body: ELLIPSES,
      key: 'left-ellipses',
      onClick: null,
    }
    const rightEllipses: FTPageNumberType = {
      className: 'right-ellipses ellipses',
      body: ELLIPSES,
      key: 'right-ellipses',
      onClick: null,
    }

    if (current >= pageWindow && total !== pageWindow) {
      numbers = [firstPage, leftEllipses, ...numbers]
    }

    if (current <= total - pageWindow + 1 && total !== pageWindow) {
      numbers = [...numbers, rightEllipses, lastPage]
    }

    return this.renderPageNumbersCentered(numbers)
  }

  render() {
    const { current, next, prev, browseToPage, total, showArrows } = this.props
    return (
      <div className='Paginator'>
        <div className='pager'>
          {prev && showArrows && (
            <PrevNextButtonStyled
              onClick={() => {
                browseToPage(current - 1)
              }}
            >
              {'<'}
            </PrevNextButtonStyled>
          )}
          {!showArrows && (
            <div className='prev'>
              {prev && (
                <span
                  className='icon left'
                  onClick={() => {
                    browseToPage(current - 1)
                  }}
                >
                  <span className='paginator-arrow ion-ios-arrow-left' />
                  {PAGINATOR_PREV}
                </span>
              )}
            </div>
          )}
          {total > 1 && (
            <div className='selector'>{this.generatePageNumbersCentered()}</div>
          )}
          {!showArrows && (
            <div className='next'>
              {next && (
                <span
                  className='icon next right'
                  onClick={() => {
                    browseToPage(current + 1)
                  }}
                >
                  <span className='paginator-arrow ion-ios-arrow-right' />
                  {PAGINATOR_NEXT}
                </span>
              )}
            </div>
          )}
          {next && showArrows && (
            <PrevNextButtonStyled
              onClick={() => {
                browseToPage(current + 1)
              }}
            >
              {'>'}
            </PrevNextButtonStyled>
          )}
        </div>
      </div>
    )
  }
}
