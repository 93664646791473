import classNames from 'classnames'
import styled from 'styled-components'

import type { ComponentType } from 'react'

const primaryTheme = {
  switchButton: {
    color: '#FFFFFF',
    backgroundColor: '#9DBD79',
  },
}
const SwitchButtonItem = styled.div`
  font-weight: 500;
  font-size: 11px;
  float: left;
  padding: 5px 12px 4px 12px;
  border: 1px solid #d7d7d7;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  user-select: none;

  color: ${({ disabled }) => disabled && '#a6a6a6'};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.switchButton.color};
    color: ${({ disabled }) => !disabled && '#ffffff'}
    border-color: transparent;
  }

  &:active {
    background-color: ${({ disabled }) => !disabled && '#ffffff'};
    color: ${({ disabled }) => !disabled && '#464a54'};
    border-color: ${({ disabled }) => !disabled && '#d7d7d7'};
  }

  &.selected {
    background-color: ${({ theme }) => theme.switchButton.backgroundColor};
    color: ${({ theme }) => theme.switchButton.color};
    border-color: ${({ theme }) => theme.switchButton.backgroundColor}
  }

  &.selected:hover {
    border-color: ${({ disabled }) => !disabled && '#d7d7d7'};
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: none;
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: none;
  }
`
export const SwitchButtonStyles: ComponentType<any> = styled.div`
  font-size: 11px;
  user-select: none;
  display: inline-block;
`
type FTOption = {
  label: string
  value: string
  disabled?: boolean
}
type FTProps = {
  current: string | null | undefined
  options: Array<FTOption>
  update: (...args: Array<any>) => any
}

const SwitchButton = (props: FTProps) => {
  const { current, options, update } = props
  return (
    <SwitchButtonStyles className='SwitchButton'>
      {options.map(({ value, label, disabled }) => (
        <SwitchButtonItem
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              update(value)
            }
          }}
          theme={primaryTheme}
          className={classNames({
            item: true,
            selected: !disabled && current === value,
          })}
          key={value}
        >
          {label}
        </SwitchButtonItem>
      ))}
    </SwitchButtonStyles>
  )
}

export default SwitchButton
