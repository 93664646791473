/* eslint-disable max-classes-per-file */
import type { FTAbility } from './abilities'
import Abilities from './abilities'
/*
A ResourceAbility maps a Resource to an Ability.

This allows the description of what Roles are required to perform an action on
a Resource.

*Difference from UserPermission*
UserPermission is an entry in the response from the Permissions API, i.e.
{
  "/savings": "inspector"
}
which maps a Resource ("/savings") to a Role ("inspector")

ResourceAbility is effectively very similar:
{
  "/savings": "inspect"
}
but differs in that it maps a Resource ("/savings") to an Ability ("inspect")
An Ability is a collection of Roles. In the case of the "inspect" Ability, it
actually maps to: "inspector", "creator", "editor", "manager", and "admin", i.e.
all of the roles that can perform the inspect ability.
*/

export default class ResourceAbility {
  ability: FTAbility = ''

  resource = ''

  constructor(resource: string, ability: FTAbility) {
    if (!resource || !ability) {
      throw new Error('Must provide a resource and an ability')
    }

    this.resource = resource
    this.ability = ability
  }
}
export class InspectableResource extends ResourceAbility {
  constructor(resource: string) {
    super(resource, Abilities.INSPECT)
  }
}
export class CreatableResource extends ResourceAbility {
  constructor(resource: string) {
    super(resource, Abilities.CREATE)
  }
}
export class EditableResource extends ResourceAbility {
  constructor(resource: string) {
    super(resource, Abilities.EDIT)
  }
}
export class ManageableResource extends ResourceAbility {
  constructor(resource: string) {
    super(resource, Abilities.MANAGE)
  }
}
export class AdminResource extends ResourceAbility {
  constructor(resource: string) {
    super(resource, Abilities.ADMINISTRATE)
  }
}
