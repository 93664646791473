import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import SpecSheetsUploadResults from './SpecSheetsUploadResults'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import ErrorMessage, { ErrorMessageStyles } from '../../components/ErrorMessage'
import FormSection from '../../components/FormSection'
import ArrowUpwardIcon from '../../components/Icons/ArrowUpwardIcon'
import Spinner from '../../components/Spinner'
import Title from '../../components/Title'
import {
  actions as specSheetActions,
  selectSpecSheets,
} from '../../ducks/specSheets'
import type { FTSpecSheetsState } from '../../ducks/specSheets'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }

  if (props.isDragReject) {
    return '#ff1744'
  }

  if (props.isDragActive) {
    return '#2196f3'
  }

  return '#ccc'
}

const FormSectionWrapperStyles = styled.div`
  max-width: 816px;
`
const FormSectionInnerStyles = styled.div`
  padding: 8px 0;
`
const FormHelpListStyles = styled.ul`
  line-height: 2;
  margin-bottom: 26px;
  padding-left: 1em;
`
const DropzoneStyles = styled.div`
  align-items: center;
  background-color: #fff;
  border-color: ${(props) => getColor(props)};
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  outline: none;
  padding: 40px;
  transition: border 0.24s ease-in-out;
`
const ArrowUpwardIconStyles = styled.div`
  align-items: center;
  border: 2px solid #4a4a4a;
  border-radius: 6px;
  display: flex;
  padding: 6px;
`
const FilePathStyled = styled.p`
  margin-bottom: 24px;
`
const ButtonsStyles = styled.div`
  button {
    display: inline-block;
    margin: 0 20px;
  }
`
const DragErrorsStyles = styled.div`
  margin-top: 1em;

  ${ErrorMessageStyles} {
    margin: 0;
  }
`
type FTProps = {
  actions: any
  specSheetsState: FTSpecSheetsState
  username: string
}

const SpecSheetsUploadPage = (props: FTProps) => {
  const {
    actions: { specSheetUpload, specSheetUploadRequest },
    specSheetsState: { byId: specSheetsById },
    username,
  } = props
  const [fileNames, setFileNames]: [
    Array<string>,
    (...args: Array<any>) => any,
  ] = useState([])
  const [currentFileNameIndex, setCurrentFileNameIndex]: [
    number,
    (...args: Array<any>) => any,
  ] = useState(0)
  const [uploading, setUploading]: [boolean, (...args: Array<any>) => any] =
    useState(false)
  const [uploadingComplete, setUploadingComplete]: [
    boolean,
    (...args: Array<any>) => any,
  ] = useState(false)
  const [currentFileUploadInitiated, setCurrentFileUploadInitiated]: [
    boolean,
    (...args: Array<any>) => any,
  ] = useState(false)
  const [dragErrors, setDragErrors]: [
    Array<string>,
    (...args: Array<any>) => any,
  ] = useState([])
  const handleDrop = useCallback((acceptedFiles) => {
    const newFileNames = []
    const newDragErrors = []
    acceptedFiles.forEach((file) => {
      if (
        (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) &&
        !fileNames.includes(file.name)
      ) {
        newFileNames.push(file.name)
      }
    })

    if (newFileNames.length) {
      setFileNames([...new Set(fileNames.concat(newFileNames))])
    }

    setDragErrors(newDragErrors)
  }, [])

  const handleCancelButtonClick = () => {
    setDragErrors([])
    setFileNames([])
    setCurrentFileNameIndex(0)
    setUploading(false)
    setUploadingComplete(false)
  }

  const handleUploadButtonClick = () => {
    setDragErrors([])
    setUploading(true)
  }

  const {
    acceptedFiles,
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    accept: 'application/pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
  })
  useEffect(() => {
    if (uploading) {
      const currentFileName = fileNames[currentFileNameIndex]

      // If all files have been processed...
      if (!currentFileName) {
        setUploading(false)
        setUploadingComplete(true)
        return
      }

      const currentSpecSheet = specSheetsById[currentFileName]

      // If this file has started to be processed already...
      if (currentSpecSheet) {
        const {
          uploadError,
          uploading: currentSpecSheetUploading,
          uploadRequestError,
          uploadUrl,
        } = currentSpecSheet

        // If there are errors...
        if (uploadError || uploadRequestError) {
          setCurrentFileUploadInitiated(false)
          setCurrentFileNameIndex(currentFileNameIndex + 1) // Else there are no errors.
          // If this file has already processed the upload request...
        } else if (uploadUrl) {
          // If it has not started uploading...
          if (!currentFileUploadInitiated) {
            specSheetUpload({
              fileName: currentFileName,
              file: acceptedFiles[currentFileNameIndex],
              url: uploadUrl,
            })
            setCurrentFileUploadInitiated(true) // Else it has started uploading already...
            // If it is done uploading...
          } else if (!currentSpecSheetUploading) {
            setCurrentFileUploadInitiated(false)
            setCurrentFileNameIndex(currentFileNameIndex + 1)
          }
        } // Else this file has not started to be processed yet.
      } else {
        specSheetUploadRequest({
          owner: username,
          fileName: currentFileName,
        })
      }
    }
  }, [currentFileNameIndex, specSheetsById, uploading])
  const progress =
    currentFileNameIndex === 0 ? 0 : (
      Math.round((currentFileNameIndex / fileNames.length) * 100)
    )
  return (
    <>
      <Breadcrumbs
        items={[
          {
            href: '/proposal-operations',
            text: 'Proposal Operations',
          },
          {
            href: '/proposal-operations/spec-sheets',
            text: 'Spec Sheets',
          },
        ]}
      />
      <Title>
        Spec Sheet Upload
        {uploadingComplete && ' Summary'}
      </Title>
      {!uploadingComplete && (
        <FormSectionWrapperStyles>
          <FormSection>
            <FormSectionInnerStyles>
              <b>Upload asset records</b>
              <FormHelpListStyles>
                <li>Each file must be a PDF</li>
                <li>You can upload both files or folders</li>
                <li>
                  You will be able to confirm or edit Fixture / Lamp name on the
                  following page
                </li>
                <li>
                  Duplicate files are accepted, however duplicate file names are
                  not. If a duplicate file name is detected you will be notified
                  on the following page
                </li>
                <li>
                  If you would like to delete a file, please contact a member of
                  the Technology team
                </li>
                <li>
                  DO NOT CLOSE THIS WINDOW while upload is in progress,
                  otherwise you will lose access to the upload status
                </li>
              </FormHelpListStyles>
              <DropzoneStyles
                {...getRootProps({
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
              >
                <input name='files' {...getInputProps()} />
                {uploading && (
                  <div>
                    <Spinner inline size='micro' /> <b>Upload in progress...</b>
                    {progress > 0 && ` ${progress}%`}
                  </div>
                )}
                {!uploading && (
                  <ArrowUpwardIconStyles>
                    <ArrowUpwardIcon />
                  </ArrowUpwardIconStyles>
                )}
                {!!dragErrors.length && (
                  <DragErrorsStyles>
                    {dragErrors.map((dragError) => (
                      <ErrorMessage key={dragError} message={dragError} />
                    ))}
                  </DragErrorsStyles>
                )}
                <FilePathStyled>
                  {!fileNames.length && 'Drag file(s) or folder to upload'}
                  {fileNames.length > 0 && fileNames[0]}
                  {fileNames.length > 1 &&
                    ` and ${fileNames.length - 1} other file`}
                  {fileNames.length > 2 && 's'}
                </FilePathStyled>
                {!fileNames.length && (
                  <Button primary onClick={open}>
                    Choose File(s)
                  </Button>
                )}
                {!!fileNames.length && (
                  <ButtonsStyles>
                    <Button onClick={handleCancelButtonClick}>Cancel</Button>
                    <Button
                      disabled={uploading}
                      primary
                      onClick={handleUploadButtonClick}
                    >
                      Upload
                    </Button>
                  </ButtonsStyles>
                )}
              </DropzoneStyles>
            </FormSectionInnerStyles>
          </FormSection>
        </FormSectionWrapperStyles>
      )}
      {uploadingComplete && <SpecSheetsUploadResults fileNames={fileNames} />}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(specSheetActions, dispatch) },
})

const mapStateToProps = (state) => ({
  specSheetsState: selectSpecSheets(state),
  username: state.user.username,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpecSheetsUploadPage)
