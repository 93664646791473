import styled from 'styled-components'

import Button2, { ButtonStyled } from '../../../../components/Button2'
import Input from '../../../../components/Input'
import PageHeader from '../../../../components/PageHeader'
import DesignLibraryPageWrapper from '../../DesignLibraryPageWrapper'

const breadcrumbs = [
  {
    href: '/design-library',
    text: 'Design Library',
  },
  {
    href: '/design-library/components/page-header',
    text: 'Page Header',
  },
]
const MainContentStyled = styled.div`
  padding-bottom: 200px;

  > * {
    margin-bottom: 16px;
  }
`
const HeaderActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ButtonStyled} {
    margin: 0 16px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`
const DropdownActionsStyled = styled.div`
  display: flex;
  flex-direction: column;

  ${ButtonStyled} {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const InputStyled = styled(Input)`
  color: #162447;
  font-family: 'Public Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-left: -6px;
`

const HeaderActions = () => (
  <HeaderActionsStyled>
    <Button2 type='primary'>Header Action 1</Button2>
    <Button2 type='secondary'>Header Action 2</Button2>
  </HeaderActionsStyled>
)

const DropdownActions = () => (
  <DropdownActionsStyled>
    <Button2 type='primary'>Dropdown Action 1</Button2>
    <Button2 type='secondary'>Dropdown Action 2</Button2>
  </DropdownActionsStyled>
)

export default () => (
  <DesignLibraryPageWrapper breadcrumbs={breadcrumbs} Title='Page header'>
    <MainContentStyled>
      <PageHeader Title='Basic header' />
      <PageHeader
        Title={() => (
          <InputStyled
            alwaysDraw={false}
            placeholder='Editable Title (hover)'
          />
        )}
      />
      <PageHeader
        Title={() => 'With Back Navigation'}
        handleBackNavigation={() => null}
      />
      <PageHeader
        handleBackNavigation={() => null}
        statusColor='#7c7c7c'
        statusText='status indicator'
        Title={() => 'With status Indicator'}
      />
      <PageHeader
        Actions={HeaderActions}
        handleBackNavigation={() => null}
        statusColor='#7c7c7c'
        statusText='status indicator'
        Title={() => 'With actions'}
      />
      <PageHeader
        Actions={HeaderActions}
        DropdownActions={DropdownActions}
        handleBackNavigation={() => null}
        statusColor='#7c7c7c'
        statusText='status indicator'
        Title={() => 'With Dropdown actions'}
      />
    </MainContentStyled>
  </DesignLibraryPageWrapper>
)
