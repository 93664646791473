import styled from 'styled-components'

import { zIndices } from '../../../utils'
import { colors } from '../../../utils/themes'
import Button from '../../Button2'

const ModalOuterStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
`
const ModalInnerStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  max-width: 500px;
`
const TextBoxStyled = styled.div`
  padding: 30px;
`
const ModalHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colors.blue5};
  margin-bottom: 20px;
`
const ModalSubHeading = styled.div`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${colors.blue5};
`
const BottomButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.32),
      rgba(224, 224, 224, 0.32)
    ),
    #ffffff;
`
export type FTModalLockUnlock = {
  closeModal: (...args: Array<any>) => any
  onSubmit: (...args: Array<any>) => any
  isLocked: boolean
}

const ModalLockUnlock = (props: FTModalLockUnlock) => {
  const { closeModal, isLocked, onSubmit } = props

  const handleOnSubmit = () => {
    closeModal()
    onSubmit()
  }

  return (
    <ModalOuterStyled>
      <ModalInnerStyled>
        <TextBoxStyled>
          <ModalHeading>
            {isLocked ?
              'Are you sure you want to unlock this Installing opportunity for updates?'
            : 'Are you sure you want to lock this Installing opportunity ?'}
          </ModalHeading>
          <ModalSubHeading>
            {isLocked ?
              'If you do, any Proposal Ops user will be able' +
              ' to create new scenarios and a new Change Order contract in Salesforce.'
            : 'If you do, no one will be able to create any Change Order for this request.'
            }
          </ModalSubHeading>
        </TextBoxStyled>
        <BottomButtonBar>
          <Button type='transparent' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleOnSubmit}>
            Yes, Continue
          </Button>
        </BottomButtonBar>
      </ModalInnerStyled>
    </ModalOuterStyled>
  )
}

export default ModalLockUnlock
