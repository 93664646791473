export const defaultMeterInstallHeaders = [
  {
    id: 'opportunityId',
    label: 'Opportunity ID',
  },
  {
    id: 'jobStatus',
    label: 'Installer Job Status',
  },
  {
    id: 'jobCompletionDate',
    label: 'Completion Date',
  },
  {
    id: 'customerName',
    label: 'Customer',
  },
  {
    id: 'siteLocation',
    label: 'Location',
  },
  {
    id: 'allMetersEvVerified',
    label: 'All Meters EV Verified',
  },
  {
    id: 'meterCount',
    label: 'Meter Count',
  },
  {
    id: 'allMetersAssigned',
    label: 'All Meters Assigned',
  },
]

export const defaultMeterInstallWidths = {
  opportunityId: {
    minWidth: 120,
    maxWidth: 120,
  },
  jobStatus: {
    minWidth: 115,
    maxWidth: 135,
  },
  customerName: {
    minWidth: 180,
    maxWidth: 210,
  },
  jobCompletionDate: {
    minWidth: 260,
    maxWidth: 260,
  },
  isAssignedToSite: {
    minWidth: 120,
    maxWidth: 130,
  },
  siteLocation: {
    minWidth: 220,
    maxWidth: 300,
  },
  allMetersEvVerified: {
    minWidth: 170,
    maxWidth: 225,
  },
  meterCount: {
    minWidth: 100,
    maxWidth: 100,
  },
  allMetersAssigned: {
    minWidth: 100,
    maxWidth: 120,
  },
}

export const breadcrumbs = [
  {
    href: '/reports',
    text: 'Tools & Reports',
  },
  {
    href: '/reports/meter-install-submissions',
    text: 'Meter Install Submissions',
  },
]

const meterEvVerifiedItems = [
  {
    id: 'Yes',
    name: 'Yes',
  },
  {
    id: 'No',
    name: 'No',
  },
]

const jobStatusItems = [
  {
    id: 'In Progress',
    name: 'In Progress',
  },
  {
    id: 'COMPLETE',
    name: 'Complete',
  },
]

export const dropdownItems = {
  jobStatus: jobStatusItems,
  allMetersEvVerified: meterEvVerifiedItems,
}
