export const defaultGlobalsMapping = {
  term: 120,
  contingencyInPercentage: 2,
  measurementAndVerificationCostAsPerProjectCostsInPercentage: 5,
  measurementAndVerificationCostSalesEstimateDollars: 0,
  annualInsurancePremium: 0.09,
  capexDealMarginTarget: 15,
  measurementAndVerificationMethodology: 'METER_OPS',
  rebateHaircut: 0,
  referralFeeBasis: 'CONTRACT_VALUE',
  measurementAndVerificationCostPerMeter: 1800,
  operationsAndMaintenanceRate: 5,
  projectManagementLaborAndFee: 2,
  referralFeeInPercentage: 3,
  maintenanceObligation: 'Parts Only',
  maintenanceRetainedSavings: 100,
  energyRetainedSavings: 5,
  contractType: 'EAAS',
}
export const defaultFieldsMapping = {
  maintenanceRepairAndOperationsContractRate: 5,
  labourCostPerHour: 50,
  materialOrderLeadTimeInDays: 0,
  projectDurationInMonths: 0,
  measurementAndVerificationCostEstimateDollars: 0,
  rebateHcInPercentage: 30,
  initialRebateAmount: 0,
  emissionRate: 0,
  maintenanceSavingsCalculation: {
    noChangeProductMaintenanceCost: 0,
    noChangeLabourMaintenance: 0,
    noChangeRecyclingCost: 0,
    otherProductMaintenanceCost: 0,
    otherLabourMaintenance: 0,
    otherRecyclingCost: 0,
    otherPostTotalMaintenanceHours: 0,
  },
  maintenanceSavingsPerFixture: 5,
  capexMarginSeekEaas: 0,
  capexMarginSeek: 15,
  eulConstantCalculationDTOList: [],
}

/** These fields are not received in Scenario response
 * and hence can't be compared to find if the field is edited or not
 */
export const skipFieldsToCheckIfEdited = [
  'preTotalMaintenanceCost',
  'postTotalMaintenanceCost',
]
export const contryCodeByCountryName = (obj, countryName) => {
  const result = Object.entries(obj).find((entry) => entry[1] === countryName)
  return result ? result[0] : ''
}

export const salesTaxWarningValue = -1
export const EUL_CONST = 0.00045359237
export const STANDARD_LAMP_BURN_HOURS = 50000
export const defaultUtilityInflationRate = 2.2
export const multiCurrencyErrorText =
  'We cannot edit this batch as it contains opportunities of different currencies. Please create a new batch and work with that.'

export const recCostTable = [
  {
    year: 2024,
    recValue: 4.64,
    recEscalator: 0.03,
  },
  {
    year: 2025,
    recValue: 4.77,
    recEscalator: 0.03,
  },
  {
    year: 2026,
    recValue: 4.92,
    recEscalator: 0.03,
  },
  {
    year: 2027,
    recValue: 5.06,
    recEscalator: 0.33,
  },
  {
    year: 2028,
    recValue: 6.74,
    recEscalator: 0.33,
  },
  {
    year: 2029,
    recValue: 8.96,
    recEscalator: 1.5,
  },
  {
    year: 2030,
    recValue: 22.4,
    recEscalator: 0.12,
  },
  {
    year: 2031,
    recValue: 25.09,
    recEscalator: 0.12,
  },
  {
    year: 2032,
    recValue: 28.1,
    recEscalator: 0.12,
  },
  {
    year: 2033,
    recValue: 31.47,
    recEscalator: 0.12,
  },
  {
    year: 2034,
    recValue: 35.24,
    recEscalator: 0.12,
  },
  {
    year: 2035,
    recValue: 39.47,
    recEscalator: 0.03,
  },
  {
    year: 2036,
    recValue: 40.66,
    recEscalator: 0.03,
  },
  {
    year: 2037,
    recValue: 41.88,
    recEscalator: 0.03,
  },
  {
    year: 2038,
    recValue: 43.13,
    recEscalator: 0.03,
  },
  {
    year: 2039,
    recValue: 44.43,
    recEscalator: 0.03,
  },
  {
    year: 2040,
    recValue: 45.76,
    recEscalator: 0.03,
  },
  {
    year: 2041,
    recValue: 47.13,
    recEscalator: 0.03,
  },
  {
    year: 2042,
    recValue: 48.55,
    recEscalator: 0.03,
  },
  {
    year: 2043,
    recValue: 50,
    recEscalator: 0.03,
  },
  {
    year: 2044,
    recValue: 51.5,
    recEscalator: 0.03,
  },
  {
    year: 2045,
    recValue: 53.05,
    recEscalator: 0.03,
  },
  {
    year: 2046,
    recValue: 54.64,
    recEscalator: 0.03,
  },
  {
    year: 2047,
    recValue: 56.28,
    recEscalator: 0.03,
  },
  {
    year: 2048,
    recValue: 57.97,
    recEscalator: 0.03,
  },
  {
    year: 2049,
    recValue: 59.71,
    recEscalator: 0.03,
  },
  {
    year: 2050,
    recValue: 61.5,
    recEscalator: 0.03,
  },
]

export const MODAL_ERROR_BODY_FOR_UPDATE_SALESFORCE =
  'The system encountered a technical issue. Please reach out to the tech team @ #proposal-engine-queries-issues on Slack.'

export const conditionalReadOnlyFields = []
