import initialState from './initialState'
import * as types from '../constants/actionTypes'

type FTState = {
  auth: {}
}
type FTAction =
  | {
      type: 'UPDATE_STATUS'
      message: string | null | undefined
    }
  | {
      type: 'FORGOT_PASSWORD_REQUEST'
      message: string | null | undefined
    }
export default function requestStatusReducer(
  state: FTState = initialState.requestStatus,
  action: FTAction,
) {
  switch (action.type) {
    case types.FORGOT_PASSWORD_REQUEST:
      return { ...state, error: '' }

    case types.UPDATE_STATUS: {
      const filteredAction = Object.keys(action)
        .filter((key) => key !== 'type')
        .reduce((obj, key) => ({ ...obj, [key]: action[key] }), {})
      return { ...state, error: action.message, ...filteredAction }
    }

    default:
      return state
  }
}
