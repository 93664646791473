import styled from 'styled-components'

import Button2 from '../../components/Button2'
import ColorIndicator from '../../components/ColorIndicator'
import CheckIcon from '../../components/Icons/CheckIcon'
import InfoCircleOutlineIcon from '../../components/Icons/InfoCircleOutlineIcon'
import {
  InputFilterStyled,
  ReactTableWrapperStyles,
} from '../../components/RedaptiveReactTable7'
import {
  TabPaneSelectorStyled,
  TabPaneStyled,
} from '../../components/Tabs/TabPane2'
import Title from '../../components/Title'
import { colors } from '../../utils/themes'

export const InfoCircleOutlineIconStyled = styled(InfoCircleOutlineIcon)`
  color: #162447;
  font-size: 16px;
  margin-left: 5px;
  vertical-align: middle;
`
export const MonthPickerWrapperStyles = styled.div<{ isDisabled?: boolean }>`
  border-radius: 8px;
  color: #485da0;
  cursor: auto;
  padding-left: 10px;
  position: relative;
  text-align: left;
  float: right;
  margin-right: 15px;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity:0.5;
    pointer-events:none;
  `}
`
export const StatusColor = styled.span`
  ${({ color }) => `color: ${color}`}
`
export const BillingThisMonthMainPageStyled = styled.div`
  ${TabPaneStyled} {
    padding-bottom: 0;
  }

  ${TabPaneSelectorStyled} {
    padding-left: 36px;
  }
  margin-top: -30px;
`
export const HeaderStyled = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 25px;
`
export const ConfirmationTitleStyled = styled(Title)`
  color: #3e5caa;
  font-family: 'Public Sans', sans-serif;
  font-size: 24px;
  line-height: 36px;
`

export const SubmissionPageOuter = styled.div<{ suppressLoading?: boolean }>`
  ${({ suppressLoading }) =>
    suppressLoading && 'opacity:0.5;pointer-events:none;'}
`
export const TableWrapperStyled = styled.div<{ suppressLoading?: boolean }>`
  margin-top: 20px;

  ${ReactTableWrapperStyles} {
    overflow-x: auto;
  }

  ${InputFilterStyled} {
    width: unset;
  }

  ${({ suppressLoading }) => {
    if (suppressLoading) {
      return `
        table{
          opacity: 0.5;
        }
      `
    }

    return ''
  }}
`
export const DataCaptureStatusStatusIndicatorStyled = styled(ColorIndicator)`
  margin-right: 5px;
`

export const ConfirmationScreen = styled.div`
  padding: 100px;
  text-align: center;
`
export const TabCountStyled = styled.div`
  background-color: ${colors.blue5};
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  height: 20px;
  margin-left: 6px;
  min-width: 36px;
  text-align: center;
`
export const TabCheckedStyled = styled.div`
  background-color: #9dbd79;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  height: 20px;
  margin-left: 6px;
  min-width: 36px;
  text-align: center;

  ${CheckIcon} {
    font-size: 20px;
    line-height: 0;
    position: relative;
    top: 5px;
  }
`

export const SubmitButton = styled(Button2)`
  float: right;
  margin: 0px;
  padding: 0px 35px;
  display: flex;
  align-items: center;
`
export const HeaderButton = styled(Button2)`
  float: right;
  margin: 0px;
  margin-left: 10px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  span {
    margin-left: 3px;
  }
`

export const TableCellColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const TableCellRow = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid transparent;
  align-items: center;
  line-height: 32px;
  width: 100%;
  margin-bottom: 5px;
`

export const LoadingSpinnerWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
`
