import React from 'react'
import { TiTick } from 'react-icons/ti'
import styled from 'styled-components'
// eslint-disable-next-line import/no-unresolved

import StyledLink, { LinkStyles } from './StyledLink'
import type { ComponentType } from 'react'
import './DropdownNew.css'

const DropdownStyles: ComponentType<{
  borderColor: string
  textColor: string
  listBgColor: string
}> = styled.div`
  width: inherit;
  position: relative;
  user-select: none;
  text-transform: none;
  width: inherit;
  background-color: ${({ listBgColor }) => listBgColor};
  left: -1px;
  top: -1px;

  .List {
    box-shadow: 0 0 0px #ccc;
    border-radius: 0 0 5px 5px;
    border-left: solid 1px;
    border-right: solid 1px;
    border-top: none;
    border-color: ${({ borderColor }) => borderColor};
    width: inherit;
    z-index: 2;
  }

  .List .item {
    color: ${({ textColor }) => textColor};
    text-align: left;
    padding: 8px 12px 6px 12px;
    font-size: ${({ fontSize }) => fontSize};
    line-height: 18px;
    text-decoration: none;
    border-bottom: 1px solid;
    display: flex;
    justify-content: space-between;
    border-color: ${({ borderColor }) => borderColor};
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .List .item:last-child {
    border-radius: 5px;
  }
  z-index: 400;
`
const StyledItemIcon = styled.div`
  svg {
    vertical-align: middle;
  }
`
export type FTActionOption = {
  external?: boolean
  href?: string
  label: string
  onClick?: (arg0: React.SyntheticEvent<HTMLLinkElement>) => void
  icon?: any
  value?: number
  icon?: any
}
export const StyledAction = styled.div`
  cursor: pointer;

  ${LinkStyles};
`
type FTProps = {
  options: Array<FTActionOption>
  borderColor: string
  textColor: string
  listBgColor?: string
  fontSize?: string
  style: Record<string, any>
  showSelected?: boolean
  selectedOption?: number
  handleOptionSelect?: (...args: Array<any>) => any
  defaultValue?: number
}

const DropdownNew = ({
  options,
  borderColor,
  textColor = '#337ab7',
  listBgColor = '#ffffff',
  fontSize = '13px',
  style,
  showSelected = false,
  selectedOption,
  handleOptionSelect,
  defaultValue,
}: FTProps) => {
  const SyntheticOnclick = (
    value,
    onClick,
    e: React.SyntheticEvent<HTMLLinkElement>,
  ) => {
    // $FlowFixMe
    handleOptionSelect(value)
    // $FlowFixMe
    if (onClick) onClick(e)
  }

  return (
    <>
      {' '}
      <DropdownStyles
        className='DropdownList'
        style={style}
        borderColor={borderColor}
        textColor={textColor}
        listBgColor={listBgColor}
        fontSize={fontSize}
      >
        <div className='List'>
          {options.map(
            ({ label, href, value, external = false, onClick, icon }) => {
              if (href) {
                return (
                  <StyledLink
                    href={href}
                    to={href}
                    key={label}
                    external={external}
                    onClick={(e) => SyntheticOnclick(value, onClick, e)}
                    className='item'
                  >
                    {label}
                    <StyledItemIcon>{icon}</StyledItemIcon>
                  </StyledLink>
                )
              }

              return (
                <StyledAction
                  key={label}
                  onClick={(e) => SyntheticOnclick(value, onClick, e)}
                  className='item'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>{label}</div>
                  {icon && <StyledItemIcon>{icon}</StyledItemIcon>}
                  {showSelected &&
                    ((selectedOption === -1 && defaultValue === value) ||
                      selectedOption === value) && <TiTick />}
                </StyledAction>
              )
            },
          )}
        </div>
      </DropdownStyles>
    </>
  )
}

export default DropdownNew
