import { ReactNode } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import '../../components/Breadcrumbs'
import type { FTBreadcrumbs } from '../../components/Breadcrumbs'
import Breadcrumbs2 from '../../components/Breadcrumbs2'
import PageHeader from '../../components/PageHeader'
import { Redaptive2PageStyles } from '../../utils/themes'

type FTProps = {
  breadcrumbs: FTBreadcrumbs
  children: ReactNode
  handleBackNavigation?: () => null
  Title: string | (() => ReactNode)
} & RouteComponentProps
const DesignLibraryPageWrapperStyled = styled.div`
  ${Redaptive2PageStyles};
  margin-top: 37px;
`
const ChildrenWrapperStyled = styled.div`
  margin-top: 40px;
`

const DesignLibraryPageWrapper = (props: FTProps) => {
  const { history } = props
  const {
    breadcrumbs,
    children,
    handleBackNavigation = () => {
      history?.goBack()
    },
    Title,
  } = props
  return (
    <DesignLibraryPageWrapperStyled>
      <Breadcrumbs2 items={breadcrumbs} />
      <PageHeader Title={Title} handleBackNavigation={handleBackNavigation} />
      <ChildrenWrapperStyled>{children}</ChildrenWrapperStyled>
    </DesignLibraryPageWrapperStyled>
  )
}

export default withRouter(DesignLibraryPageWrapper)
