import { FormikProps } from 'formik'

import {
  ErrorStyled,
  FieldContainerStyled,
  InputStyled,
  LabelStyled,
  LableSupportText,
  PageBodyStyled,
  PageHeaderStyled,
  PanelFieldValues,
  RadioButtonContainerStyled,
} from './commons'
import { validSignalStrengthOptions } from '../../utils/panelConfiguration'
import ListSelector from '../ListSelector'
import StyledRadioButton from '../RadioButton'

type StepProps = {
  formik: FormikProps<PanelFieldValues>
  handleRadioButtonChange: (event: Event) => void
}
const PanelDescriptionForm = (props: StepProps) => {
  const { formik, handleRadioButtonChange } = props
  const selectedNetworkStrength =
    formik.values.networkStrength ?
      validSignalStrengthOptions.find(
        (opt) => opt.id === formik.values.networkStrength,
      )
    : undefined
  return (
    <>
      <PageHeaderStyled>
        <p>General</p>
        <div />
      </PageHeaderStyled>

      <PageBodyStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Panel Name <span>*</span>
          </LabelStyled>
          <LableSupportText>
            The name of the panel, i.e. &quot;Panel A&quot;
          </LableSupportText>
          <InputStyled
            name='name'
            onChange={formik.handleChange}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name ?
                formik.errors.name
              : ''
            }
            onBlur={formik.handleBlur}
          />
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Panel Description <span>*</span>
          </LabelStyled>
          <LableSupportText>
            A description to further identify the panel, i.e. “1st Floor
            Breakroom”
          </LableSupportText>
          <InputStyled
            name='description'
            onChange={formik.handleChange}
            value={formik.values.description}
            error={
              formik.touched.description && formik.errors.description ?
                formik.errors.description
              : ''
            }
            onBlur={formik.handleBlur}
          />
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Redaptive opportunity ID<span>*</span>
          </LabelStyled>
          <LableSupportText>
            Indicate the opportunity ID associated with this site
          </LableSupportText>
          <InputStyled
            name='opportunityId'
            onChange={formik.handleChange}
            value={formik.values.opportunityId}
            error={
              formik.touched.opportunityId && formik.errors.opportunityId ?
                formik.errors.opportunityId
              : ''
            }
            onBlur={formik.handleBlur}
          />
        </FieldContainerStyled>
      </PageBodyStyled>

      <PageHeaderStyled>
        <p>Location</p>
        <div />
      </PageHeaderStyled>
      <PageBodyStyled className='last'>
        <FieldContainerStyled>
          <LabelStyled>
            Building Floor<span>*</span>
          </LabelStyled>
          <LableSupportText>Specify the floor</LableSupportText>
          <InputStyled
            name='floorNumber'
            onChange={formik.handleChange}
            value={formik.values.floorNumber}
            error={
              formik.touched.floorNumber && formik.errors.floorNumber ?
                formik.errors.floorNumber
              : ''
            }
            onBlur={formik.handleBlur}
          />
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Panel Location<span>*</span>
          </LabelStyled>
          <LableSupportText>
            The location of the panel on the site, i.e. “ Warehouse Basement”.
          </LableSupportText>
          <InputStyled
            name='location'
            onChange={formik.handleChange}
            value={formik.values.location}
            error={
              formik.touched.location && formik.errors.location ?
                formik.errors.location
              : ''
            }
            onBlur={formik.handleBlur}
          />
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Cell Services at Panel<span>*</span>
          </LabelStyled>
          <LableSupportText>
            Are cell services available at the panel location?
          </LableSupportText>
          <RadioButtonContainerStyled>
            <div>
              <StyledRadioButton
                name='networkAvailability'
                value='true'
                onChange={handleRadioButtonChange}
                checked={formik.values.networkAvailability}
              />
              <LabelStyled>Yes</LabelStyled>
            </div>
            <div>
              <StyledRadioButton
                name='networkAvailability'
                value='false'
                onChange={handleRadioButtonChange}
                checked={!formik.values.networkAvailability}
              />
              <LabelStyled>No</LabelStyled>
            </div>
          </RadioButtonContainerStyled>
          {(
            formik.errors.networkAvailability &&
            formik.touched.networkAvailability
          ) ?
            <ErrorStyled className='input__error'>
              *{formik.errors.networkAvailability}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>
        <FieldContainerStyled>
          <LabelStyled>
            Cell Signal Strength<span>*</span>
          </LabelStyled>
          <LableSupportText>
            Indicate signal strength at panel location. 1 bar = weak, 4 bars =
            strong.
          </LableSupportText>
          <ListSelector
            updateValue={({ value }) => {
              formik.setFieldValue('networkStrength', value).then(() => {
                formik.setFieldTouched('networkStrength', true)
              })
            }}
            selectedItem={selectedNetworkStrength}
            items={validSignalStrengthOptions}
            notSetLabelText='Select a signal strength'
          />
          {formik.errors.networkStrength && formik.touched.networkStrength ?
            <ErrorStyled className='input__error'>
              *{formik.errors.networkStrength}
            </ErrorStyled>
          : null}
        </FieldContainerStyled>
      </PageBodyStyled>
    </>
  )
}

export default PanelDescriptionForm
