import * as React from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

const Icon = styled.span`
  padding-right: 8px;
  &:before {
    font-size: 14px;
  }
`
const Styles = styled.div`
  font-size: 14px;
  padding: 8px 12px;
  margin-left: 12px;
  cursor: ${({ loading }) => (loading === 'false' ? 'pointer' : undefined)};
  color: #337ab7;
  border-radius: 6px;
  display: inline-block;
  &:hover {
    background-color: ${({ loading }) => loading === 'false' && '#f5f5f5'};
  }

  & .Spinner.loader.tiny,
  & .Spinner.loader.tiny:after {
    width: 15px;
    height: 15px;
  }
`
type FTProps = {
  onClick: (...args: Array<any>) => any
  children: React.ReactNode
  iconClass?: string
  loading?: boolean
}
export default class IconButton extends React.PureComponent<FTProps> {
  static defaultProps = {
    iconClass: '',
    loading: false,
  }

  render() {
    const { onClick, iconClass, children, loading, ...rest } = this.props
    return (
      <Styles onClick={onClick} loading={loading ? 'true' : 'false'} {...rest}>
        {!loading && iconClass && <Icon className={iconClass} />}
        {!loading && children}
        {loading && <Spinner size='tiny' />}
      </Styles>
    )
  }
}
