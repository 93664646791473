import { forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import VerticalBarsLoader from './VerticalBarsLoader'
import { colors } from '../utils/themes'

type FTButtonType =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'dark'
  | 'transparent'
  | 'outlined'
  | 'default'
type FTProps = {
  children: ReactNode
  disabled?: boolean
  loading?: boolean
  type?: FTButtonType
}
export const buttonColors: Record<FTButtonType, string> = {
  primary: '#fff',
  secondary: '#fff',
  warning: '#fff',
  dark: '#fff',
  transparent: colors.blue3,
  outlined: colors.blue2,
  default: colors.blue3,
}
const buttonStyles: Record<FTButtonType, typeof css> = {
  primary: css`
    background-color: ${colors.green};
    color: ${buttonColors.primary};
  `,
  secondary: css`
    background-color: ${colors.blue};
    color: ${buttonColors.secondary};
  `,
  redaptivePrimary: css`
    background-color: ${colors.redaptiveBlue};
    color: ${buttonColors.secondary};
  `,
  redaptiveSecondary: css`
    color: ${colors.gray};
    border: 1px solid ${colors.gray6};
    background: ${colors.gray7};
  `,
  redaptiveTransparent: css`
    background-color: transparent;
    color: ${colors.blue15};
    padding: 0 16px;
    margin: 0 10px;
    font-size: 14px;
  `,
  redaptiveOutlined: css`
    background-color: transparent;
    color: ${colors.blue15};
    border: 1px solid ${colors.blue15};
  `,
  warning: css`
    background-color: ${colors.red};
    color: ${colors.warning};
  `,
  dark: css`
    background-color: ${colors.blue3};
    color: ${buttonColors.dark};
  `,
  transparent: css`
    background-color: transparent;
    color: ${buttonColors.transparent};
  `,
  outlined: css`
    background-color: transparent;
    color: ${buttonColors.outlined};
    border: 1px solid ${colors.blue2};
  `,
  default: css`
    background-color: #e0e0e0;
    color: ${buttonColors.default};
  `,
}
export const ButtonStyled = styled.button`
  border-radius: 4px;
  border-width: 0;
  cursor: pointer;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  margin: 10px;
  min-width: 64px;
  padding: 0 16px;
  ${({ type }) => buttonStyles[type]};
  &:disabled {
    cursor: not-allowed;
    filter: saturate(30%) opacity(80%);
  }
`
export default forwardRef<FTProps, any>(
  (
    {
      children,
      disabled = false,
      loading = false,
      type = 'default',
      ...rest
    }: FTProps,
    ref,
  ) => (
    <ButtonStyled disabled={disabled} type={type} {...rest} ref={ref}>
      {!loading && children}
      {loading && <VerticalBarsLoader color={buttonColors[type]} />}
    </ButtonStyled>
  ),
)
