import Tippy from '@tippyjs/react'

import { FTContractSummary } from '../../../types'
import Checkbox from '../../Checkbox'
import CheckedLabel from '../../CheckedLabel'
import { FTCell, FTCellChecknox } from '../types'

export const CellWithToolTip = ({ isExpanded, value = '' }: FTCell) =>
  isExpanded ? '' : (
    <Tippy content={value} delay={500}>
      <span>{value}</span>
    </Tippy>
  )

export const MeteredUpstreamCell = ({ isExpanded, value }: FTCellChecknox) =>
  isExpanded ? '' : (
    <span>
      <Checkbox checked={value} readOnly />
      <span>{value ? 'Yes' : 'No'}</span>
    </span>
  )

export const getBillableContractLabel = (
  contractSummary: FTContractSummary | undefined,
) => {
  const { opportunityId = '', type = '' } = contractSummary || {}
  return opportunityId && type ?
      <CheckedLabel isYes label={`${opportunityId} - ${type}`} />
    : <CheckedLabel isYes={false} label='None' />
}
