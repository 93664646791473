/* eslint react/no-did-update-set-state: 0 */
// Reason: AirBnb has since removed requirement, but hasn't released yet.
// More info: https://github.com/airbnb/javascript/issues/684#issuecomment-391814056
import { FastField, FieldArray, Form } from 'formik'
import isEqual from 'lodash.isequal'
import * as React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import styled from 'styled-components'

import AddressSection from './AddressSection'
import ExternalIntegrationsSection from './ExternalIntegrationsSection'
import MeterDataSection from './MeterDataSection'
import type { FTContract, FTWithFormik } from '../../types'
import { makeListSelectorItem, YES_NO } from '../../utils'
import { colors } from '../../utils/themes'
import Button from '../Button'
import Description from '../Description'
import FormField from '../FormField'
import FormSection from '../FormSection'
import Input from '../Input'
import ListSelector from '../ListSelector'
import ReactTabsStyles from '../ReactTabsStyles'
import StyledLink from '../StyledLink'
import UnsavedChanges from '../UnsavedChanges'

const Styles = styled(ReactTabsStyles)`
  & .Modal .box .header .title {
    font-size: 14px;
    margin-bottom: 30px;
    text-transform: none;
  }
`
const NewTabStyles = styled.div`
  cursor: pointer;
  display: inline-block;
  left: 10px;
  position: relative;
  top: 1px;
`
// const StyledListSelector = styled(ListSelector)``;
const StyledButton = styled(Button)`
  & + & {
    margin: 80px 0 100px 40px;
  }
`
const FormError = styled.div`
  color: #c70d08;
`
const StyledContractInlineField = styled.div`
  margin-bottom: 30px;

  :last-child {
    margin-bottom: 0;
  }

  .input__error {
    left: auto;
    top: -11px;
    right: 12px;
  }
`
const SearchOpportunityButtonContainer = styled.div`
  height: 50px;
  width: 100%;
  .searchOpportunity {
    float: right;
  }
`
const ContractDataSection = styled.div`
  margin-top: 40px;
`
const NonLOATextStyled = styled.p`
  color: ${colors.orange2};
`
type FTProps = {
  customers: Array<Record<string, any>>
  customer: Record<string, any> | null | undefined
  error?: Record<string, any>
  isEdit: boolean
  goBack: (...args: Array<any>) => any
} & FTWithFormik
type FTState = {
  hasSubmissionError: boolean
  hasClickedAdd: boolean
  tabIndex: number
  isSearchButtonDisabled: boolean
}
const EMPTY_CONTRACT_PLACEHOLDER = {
  id: '',
  opportunityId: '',
  type: 'LIGHTING',
  energyCommitment: '',
  monthlyBlock: '',
  energyRate: '',
  utilityRate: '',
}
export default class SiteForm extends React.PureComponent<FTProps, FTState> {
  focusTabFirstInput: (...args: Array<any>) => any

  inputRefs: Array<Record<string, any>>

  constructor(props: FTProps) {
    super(props)
    this.state = {
      hasSubmissionError: false,
      tabIndex: 0,
      hasClickedAdd: false,
      isSearchButtonDisabled: false,
    }
    this.inputRefs = this.props.values.contracts.map(() => React.createRef())

    this.focusTabFirstInput = () => {
      this.inputRefs[this.state.tabIndex].current.focus()
    }
  }

  static defaultProps = {
    error: undefined,
  }

  componentDidUpdate(prevProps: FTProps, prevState: FTState) {
    const prevErrorCt = Object.keys(prevProps.errors).length
    const errorCt = Object.keys(this.props.errors).length

    if (prevProps.isSubmitting && !this.props.isSubmitting && errorCt > 0) {
      this.setState({
        hasSubmissionError: true,
      })
      window.scrollTo(0, 0)
    } else if (prevErrorCt > 0 && errorCt < 1) {
      this.setState({
        hasSubmissionError: false,
      })
    } else if (!prevProps.error && this.props.error) {
      this.props.setSubmitting(false)
      window.scrollTo(0, 0)
    }

    if (
      prevProps.values.contracts.length < this.props.values.contracts.length
    ) {
      this.setState({
        tabIndex: this.props.values.contracts.length - 1,
      })
    }

    if (prevState.tabIndex !== this.state.tabIndex) {
      this.focusTabFirstInput()
    }

    if (this.props.newOpportunity && !this.props.newOpportunity.error) {
      const { contracts } = this.props.values
      // this.props.newOpportunity.searchOpportunityId =
      //   this.props.newOpportunity.opportunityId
      contracts[contracts.length - 1] = this.props.newOpportunity
      this.props.setFieldValue('contracts', contracts)
      this.props.actions.clearNewOpportunity()
    }
  }

  static getDerivedStateFromProps(props: FTProps, state: FTState) {
    const { contracts } = props.values
    const lastContract = contracts[contracts.length - 1]

    if (
      lastContract &&
      lastContract.searchOpportunityId &&
      contracts.some(
        ({ opportunityId }, i) =>
          i < contracts.length - 1 &&
          opportunityId === lastContract.searchOpportunityId,
      )
    ) {
      return { ...state, isSearchButtonDisabled: true }
    }

    return { ...state, isSearchButtonDisabled: false }
  }

  componentDidMount() {
    if (this.props?.site?.contractFetchError) {
      this.setTabIndex(0)
    } else if (this.props?.site?.contractUpdated) {
      this.setTabIndex(this.props.values?.contracts?.length - 1)
    }
  }

  setTabIndex = (tabIndex: number) => {
    this.setState({
      tabIndex,
    })
  }

  hasChanged = () => !isEqual(this.props.initialValues, this.props.values)

  addNewTab = (arrayHelpers: any) => {
    this.inputRefs.push(React.createRef())
    arrayHelpers.push({ ...EMPTY_CONTRACT_PLACEHOLDER })
  }

  render() {
    const {
      errors,
      error,
      touched,
      isSubmitting,
      customers,
      customer,
      values,
      setFieldValue,
      // setValues,
      goBack,
      isEdit,
      isValid,
      siteId,
      validateForm,
      isSearchingOpportunity,
      site,
    } = this.props
    const {
      contracts,
    }: {
      contracts: Array<FTContract>
    } = values
    const { hasSubmissionError, isSearchButtonDisabled } = this.state
    const { contractUpdated } = site
    const { contractFetchError } = site
    let selectedCustomerItem

    if (!values.customerId && customer) {
      selectedCustomerItem = makeListSelectorItem(
        customer.id,
        customer.validName,
      )
    } else {
      const selectedCustomer = customers.find(
        (customerCurrent) => customerCurrent.id === values.customerId,
      )
      const selectedCustomerName =
        (selectedCustomer && selectedCustomer.validName) || ''
      selectedCustomerItem = makeListSelectorItem(
        values.customerId,
        selectedCustomerName,
      )
    }

    const optionalSubtitle = '- Optional'
    const contractTypeDescription =
      'Lighting, HVAC, and Metering supported.' +
      ' For additional Contract Types, please contact the Digital Product' +
      ' Team'

    const getOpportunityDetails = (opportunityId, contractIndex) => {
      if (contracts.length) {
        contracts[contracts.length - 1] = { ...EMPTY_CONTRACT_PLACEHOLDER }
        setFieldValue('contracts', contracts)
      }

      this.props.actions.fetchOpportunity({
        opportunityId,
        siteId,
        contractIndex,
      })
    }

    const isContractLOA = (contract) => {
      if (
        contract &&
        Object.prototype.hasOwnProperty.call(contract, 'hasContract') &&
        contract.hasContract === false
      )
        return false
      return true
    }

    const renderOpportunityIdReadOnlyField = ({ ref, opportunityId }) => (
      <FormField
        title='Opportunity ID'
        renderDescription={() => 'Opportunity ID from Salesforce'}
        renderField={() => <div ref={ref}>{opportunityId}</div>}
      />
    )

    const makeContractItem = (contractIndex, contract: FTContract) => {
      const contractKey = contract.id || `new${contractIndex}`
      const contractsErrors = errors.contracts || {}
      const contractErrors = contractsErrors[contractIndex] || {}
      const isContractSaved = !!contracts[contractIndex].id
      let hideInfo = false

      // $FlowFixMe
      if (
        site?.customerName
          ?.toLowerCase()
          ?.includes('Prologis - Lightsmart'.toLowerCase())
      ) {
        contracts[contractIndex].opportunityType = 'Metering'
        hideInfo = true
      }

      const {
        // opportunityId,
        type,
      } = contract
      return (
        <TabPanel key={`${contractKey}TabPanel`}>
          <p>
            The following fields must be completed in order to generate an
            invoice for the site and/or display program-related savings in the
            dashboard.
          </p>
          {isContractSaved ?
            <StyledContractInlineField>
              {renderOpportunityIdReadOnlyField({
                ref: this.inputRefs[contractIndex],
                opportunityId: contracts[contractIndex].opportunityId,
              })}
            </StyledContractInlineField>
          : <>
              <StyledContractInlineField>
                {isSearchingOpportunity ?
                  renderOpportunityIdReadOnlyField({
                    ref: this.inputRefs[contractIndex],
                    opportunityId: contracts[contractIndex].opportunityId,
                  })
                : <FormField
                    error={
                      isSearchButtonDisabled ?
                        'Opportunity Id must be unique'
                      : ''
                    }
                    name={`contracts.${contractIndex}.searchOpportunityId`}
                    title='Enter Opportunity ID to Search'
                    isReadOnly={isSearchingOpportunity}
                    renderDescription={() => 'Opportunity ID from Salesforce'}
                    inputRef={this.inputRefs[contractIndex]}
                  />
                }
              </StyledContractInlineField>
              <SearchOpportunityButtonContainer>
                <Button
                  primary
                  type='button'
                  loading={isSearchingOpportunity}
                  disabled={isSearchButtonDisabled}
                  className='searchOpportunity'
                  onClick={() =>
                    getOpportunityDetails(
                      contracts[contractIndex].searchOpportunityId,
                      contractIndex,
                    )
                  }
                >
                  Search
                </Button>
              </SearchOpportunityButtonContainer>
            </>
          }

          {!isContractLOA(contracts[contractIndex]) && (
            <NonLOATextStyled>
              Contract information not yet available. Please proceed with saving
              and contract details will sync upon contract acceptance (LOA) in
              Salesforce. Please allow 24 hours for the sync to complete and
              reach out to the Technology team if you observe any issues.
            </NonLOATextStyled>
          )}
          {!isContractSaved && (
            <StyledContractInlineField>
              <FormField
                isReadOnly
                name={`contracts.${contractIndex}.opportunityId`}
                title='Opportunity ID'
                renderDescription={() => 'Opportunity ID from Salesforce'}
              />
            </StyledContractInlineField>
          )}
          <StyledContractInlineField>
            <FormField
              isReadOnly
              name={`contracts.${contractIndex}.opportunityName`}
              title='Opportunity Name'
              renderDescription={() => contractTypeDescription}
            />
          </StyledContractInlineField>
          {hideInfo && (
            <StyledContractInlineField>
              <p
                style={{
                  fontWeight: 600,
                }}
              >
                Opportunity Type
              </p>
              <p>Metering</p>
            </StyledContractInlineField>
          )}
          {!hideInfo && (
            <StyledContractInlineField>
              <FormField
                isReadOnly
                name={
                  contracts[contractIndex].opportunityType ?
                    `contracts.${contractIndex}.opportunityType`
                  : `contracts.${contractIndex}.type`
                }
                title='Opportunity Type'
                renderDescription={() => contractTypeDescription}
              />
            </StyledContractInlineField>
          )}
          <StyledContractInlineField>
            <FormField
              isReadOnly
              name={`contracts.${contractIndex}.opportunityTypeLevel2`}
              title='Opportunity Type Lvl 2'
            />
          </StyledContractInlineField>
          {isContractLOA(contracts[contractIndex]) && (
            <>
              {['LIGHTING', 'HVAC', 'SOLAR'].includes(type) && !hideInfo && (
                <StyledContractInlineField>
                  <FormField
                    isReadOnly
                    name={`contracts.${contractIndex}.contractLength`}
                    title='Term Length'
                    renderDescription={() =>
                      'The length of the contract, in months'
                    }
                    error={contractErrors.contractLength}
                    type='number'
                  />
                </StyledContractInlineField>
              )}
              {['LIGHTING', 'HVAC', 'SOLAR'].includes(type) && !hideInfo && (
                <StyledContractInlineField>
                  <FormField
                    isReadOnly
                    name={`contracts.${contractIndex}.resourceUnit`}
                    title='Resource Unit'
                    error={contractErrors.resourceUnit}
                    type='string'
                  />
                </StyledContractInlineField>
              )}
              {['LIGHTING', 'HVAC', 'SOLAR'].includes(type) && !hideInfo && (
                <StyledContractInlineField>
                  <FormField
                    isReadOnly
                    name={`contracts.${contractIndex}.energyCommitment`}
                    title='Resource Commitment'
                    renderDescription={() =>
                      'The value of the contract commitment, in kWh'
                    }
                    error={contractErrors.energyCommitment}
                    type='number'
                    step='any'
                  />
                </StyledContractInlineField>
              )}
              {type === 'LIGHTING' && !hideInfo && (
                <StyledContractInlineField>
                  <FormField
                    isReadOnly
                    name={`contracts.${contractIndex}.monthlyBlock`}
                    title='Estimated Monthly kWh Savings'
                    renderDescription={() => 'Expected monthly savings, in kWh'}
                    error={contractErrors.monthlyBlock}
                    type='number'
                    step='any'
                  />
                </StyledContractInlineField>
              )}
              {['LIGHTING', 'HVAC', 'SOLAR'].includes(type) && !hideInfo && (
                <>
                  <StyledContractInlineField>
                    <FormField
                      isReadOnly
                      name={`contracts.${contractIndex}.energyRate`}
                      title='Contract Rate'
                      renderDescription={() =>
                        'Redaptive invoice rate, in $/kWh. Set to 0 for CapEx and CapLease projects'
                      }
                      error={contractErrors.energyRate}
                      type='number'
                      step='any'
                    />
                  </StyledContractInlineField>
                  {type !== 'SOLAR' && (
                    <StyledContractInlineField>
                      <FormField
                        isReadOnly
                        name={`contracts.${contractIndex}.utilityRate`}
                        title='Utility Rate'
                        renderDescription={() =>
                          'Rate charged by the utility, in $/kWh'
                        }
                        error={contractErrors.utilityRate}
                        type='number'
                        step='any'
                      />
                    </StyledContractInlineField>
                  )}
                </>
              )}
              {type === 'HVAC' && !hideInfo && (
                <>
                  <StyledContractInlineField>
                    <FormField
                      isReadOnly
                      name={`contracts.${contractIndex}.maintenanceSavings`}
                      title='Contract Maintenance Value'
                      renderDescription={() =>
                        'If the program utilizes a stipulated maintenance savings,' +
                        ' known in the Salesforce Contract object as "Portion' +
                        ' Contract Value Maint. Savings",  please input here. If' +
                        ' not, please leave blank'
                      }
                      error={contractErrors.maintenanceSavings}
                      type='number'
                      step='any'
                    />
                  </StyledContractInlineField>
                  <StyledContractInlineField>
                    <FormField
                      isReadOnly
                      name={`contracts.${contractIndex}.procurementSavings`}
                      title='Contract Procurement Value'
                      renderDescription={() =>
                        'If the program utilizes a stipulated maintenance savings,' +
                        ' known in the Salesforce Contract object as "Portion' +
                        ' Contract Value Proc/Other Saving",  please input here.' +
                        ' If not, please leave blank'
                      }
                      error={contractErrors.procurementSavings}
                      type='number'
                      step='any'
                    />
                  </StyledContractInlineField>
                  <StyledContractInlineField>
                    <FormField
                      isReadOnly
                      name={`contracts.${contractIndex}.gasSavings`}
                      title='Contract Gas Value'
                      renderDescription={() =>
                        'If the program utilizes a stipulated maintenance savings,' +
                        ' known in the Salesforce Contract object as "Portion' +
                        ' Contract Value Gas Savings",  please input here. If not,' +
                        ' please leave blank'
                      }
                      error={contractErrors.gasSavings}
                      type='number'
                      step='any'
                    />
                  </StyledContractInlineField>
                  <StyledContractInlineField>
                    <FormField
                      isReadOnly
                      name={`contracts.${contractIndex}.energySavings`}
                      title='Contract Energy Value'
                      renderDescription={() =>
                        'If the program utilizes a stipulated maintenance savings,' +
                        ' known in the Salesforce Contract object as "Portion' +
                        ' Contract Value Gas Savings",  please input here. If not,' +
                        ' please leave blank'
                      }
                      error={contractErrors.energySavings}
                      type='number'
                      step='any'
                    />
                  </StyledContractInlineField>
                </>
              )}
            </>
          )}
        </TabPanel>
      )
    }

    return (
      <Styles>
        <Form>
          <UnsavedChanges
            when={!isSubmitting && (this.hasChanged() || contractUpdated)}
          />
          <Description>
            {hasSubmissionError && (
              <FormError>There are errors with the form.</FormError>
            )}
            {error && <FormError>{error}</FormError>}
          </Description>
          <FormSection title='Site Name & Customer'>
            <FormField
              title='Site Name' // eslint-disable-next-line
              renderDescription={() =>
                'The name to display in the dashboard for this site, e.g. "MC516 - CA - Oakland - 330 Chestnut St"'
              }
              renderField={() => <FastField name='display' component={Input} />}
              showRequired={touched.display && errors.display}
            />
            <FormField
              title='Customer'
              showRequired={touched.customerId && errors.customerId}
              renderDescription={() => (
                <div>
                  The customer that owns this site. Don&apos;t see the customer
                  you&apos;re looking for?
                  <StyledLink href='/account-management/customers/create'>
                    Add customer
                  </StyledLink>
                </div>
              )}
              renderField={() => (
                <ListSelector
                  items={customers}
                  selectedItem={selectedCustomerItem}
                  unsettable={false}
                  notSetLabelText='-- Select Customer --'
                  notSetItemText='-- Select Customer --'
                  updateValue={({ value }) => {
                    setFieldValue('customerId', value)
                  }}
                  showRequired={touched.customerId && errors.customerId}
                />
              )}
            />
            <FormField
              title='Display in Redaptive ONE'
              width='72px'
              showRequired={touched.active && errors.active}
              renderDescription={() => (
                <div>
                  If set to No, this site and its associated data will not be
                  visible in Redaptive ONE
                </div>
              )}
              renderField={() => (
                <ListSelector
                  searchable={false}
                  items={YES_NO}
                  selectedItem={values.active ? YES_NO[0] : YES_NO[1]}
                  unsettable={false}
                  updateValue={({ value }) =>
                    setFieldValue('active', value === 'yes')
                  }
                />
              )}
            />
          </FormSection>
          <AddressSection
            optionalSubtitle={optionalSubtitle}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
          />

          <MeterDataSection
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            isEdit={isEdit}
          />

          {siteId && (
            <ExternalIntegrationsSection
              optionalSubtitle={optionalSubtitle}
              setFieldValue={setFieldValue}
              errors={errors}
              values={values}
              initialValues={this.props.initialValues}
            />
          )}
          <ContractDataSection>
            <FormSection
              title='Contracts' // ref={this.contractsSection}
            >
              <FieldArray
                name='contracts'
                render={(arrayHelpers) => (
                  <Tabs
                    forceRenderTabPanel
                    selectedIndex={this.state.tabIndex}
                    onSelect={this.setTabIndex}
                  >
                    {contractFetchError ?
                      <input
                        type='text'
                        disabled
                        style={{
                          color: 'red',
                          backgroundColor: '#fff',
                          border: '1.4px solid red',
                          padding: '5px',
                          borderRadius: '3px',
                          width: '-webkit-fill-available',
                          marginBottom: '15px',
                        }}
                        value={contractFetchError}
                      />
                    : ''}
                    <TabList>
                      {[...contracts] // .sort(naturallySortContracts)
                        .map(({ opportunityId }: FTContract, index) => {
                          const tabKey = `${index}Tab`
                          return (
                            <Tab key={tabKey}>{opportunityId || 'New'}</Tab>
                          )
                        })}
                      {!this.state.hasClickedAdd &&
                        isEdit &&
                        contracts.length > 0 &&
                        contracts[0].id && (
                          <NewTabStyles
                            onClick={() => {
                              this.addNewTab(arrayHelpers)
                              this.setState({
                                hasClickedAdd: true,
                              })
                            }}
                          >
                            Add +
                          </NewTabStyles>
                        )}
                    </TabList>

                    {contracts.map((contract: FTContract, index) =>
                      makeContractItem(index, contract),
                    )}
                  </Tabs>
                )}
              />
            </FormSection>
          </ContractDataSection>

          <input
            name='ingestionSiteId'
            type='hidden'
            value={values.ingestionSiteId}
          />
          <StyledButton
            type='button'
            disabled={isSubmitting}
            onClick={() => goBack()}
          >
            Cancel
          </StyledButton>
          <StyledButton
            type='submit'
            disabled={
              isSubmitting ||
              !(this.hasChanged() || contractUpdated) ||
              !isValid
            }
            primary
          >
            {isEdit ? 'Save Changes' : 'Submit'}
          </StyledButton>
        </Form>
      </Styles>
    )
  }
}
