import debounce from 'debounce'
import emailValidator from 'email-validator'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as loginActions from '../actions/login'
import { getDashboardUrl } from '../api'
import ConnectedLoginForm from '../components/LoginForm'
import * as status from '../constants/status'
import type { FTHistory } from '../types'
import '../types'

type FTProps = {
  error: Record<string, any>
  loading?: boolean
  notice: string
  history: FTHistory
  loginUser: (...args: Array<any>) => any
  setLoginFormEmailFieldValue: (...args: Array<any>) => any
  loginFormEmailFieldValue: string
  isAuthenticated: boolean
  userError: Record<string, any>
}

class LoginContainer extends Component<FTProps> {
  static defaultProps = {
    loading: false,
  }

  componentDidUpdate() {
    const { error, history, isAuthenticated, userError } = this.props
    const { name: userErrorName } = userError || {}

    if (!error && !userErrorName && isAuthenticated) {
      const {
        location: { search },
      } = history
      const urlParams = new URLSearchParams(search)
      const destination = urlParams.get('destination') || ''
      const redirectPath = destination || '/'
      history.push(redirectPath)
    }

    if (userErrorName === 'PasswordExpiredException') {
      const { passwordResetId } = userError
      window.location = `${getDashboardUrl()}/reset-password/${passwordResetId}/expired`
    }
  }

  handleDebouncedEmailChange = (value: string) => {
    const { setLoginFormEmailFieldValue } = this.props

    if (emailValidator.validate(value)) {
      setLoginFormEmailFieldValue({
        value,
      })
    }
  }

  handleEmailChange = debounce(this.handleDebouncedEmailChange, 200)

  handleSubmit = (creds) => {
    this.props.loginUser(creds)
  }

  render() {
    const { error, loading, notice, loginFormEmailFieldValue, history } =
      this.props
    const {
      location: { search },
    } = history
    const urlParams = new URLSearchParams(search)
    const loginNotice =
      urlParams.has('forgot') ?
        'Further instructions have been sent to your email address.'
      : notice
    return (
      <div className='LoginContainer'>
        <ConnectedLoginForm
          notice={loginNotice}
          onSubmit={this.handleSubmit}
          authError={error}
          loading={loading}
          onEmailChange={this.handleEmailChange}
          initialValues={{
            username: loginFormEmailFieldValue,
          }}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginUser(creds) {
    return dispatch(loginActions.loginUser(creds))
  },

  setLoginFormEmailFieldValue(values) {
    return dispatch(loginActions.setLoginFormEmailFieldValue(values))
  },
})

const mapStateToProps = (state) => {
  const { requestStatus, user } = state
  const { error, status: authStatus } = requestStatus.auth
  const {
    error: userError,
    notice,
    isAuthenticated,
    loginFormEmailFieldValue,
  } = user
  return {
    error,
    isAuthenticated,
    notice,
    loading: authStatus === status.LOADING,
    loginFormEmailFieldValue,
    userError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginContainer))
