import { faker } from '@faker-js/faker'

export default new Array(200).fill(null).map(() => ({
  name: faker.name.firstName(),
  species: faker.animal.dog(),
  address: faker.address.streetAddress(),
  description: faker.hacker.phrase(),
  email: faker.internet.email(),
  opportunityId: faker.helpers.replaceSymbols('R#######'),
})) as Array<Record<string, any>>
