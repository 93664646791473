import emailValidator from 'email-validator'
import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import './LoginForm.css'

type FTProps = {
  handleSubmit: (...args: Array<any>) => any
  loading: boolean
  loginFormEmailFieldValue: string
}
const Styles = styled.div`
  text-align: center;
`
const IntroStyles = styled.p`
  margin-bottom: 2em;
`
const FieldStyles = styled.p`
  margin-bottom: 2em;
`

const ForgotPasswordForm = ({
  handleSubmit,
  loading,
  loginFormEmailFieldValue,
}: FTProps): any => {
  const [email, setEmail] = useState(loginFormEmailFieldValue)
  const emailRef: {
    current: HTMLInputElement | null
  } = useRef(null)
  useEffect(() => {
    if (
      !loading &&
      emailRef.current &&
      emailValidator.validate(loginFormEmailFieldValue)
    ) {
      emailRef.current.value = loginFormEmailFieldValue
    }
  }, [loading, loginFormEmailFieldValue])

  const handleChange = (event: SyntheticEvent) => {
    setEmail(event.target.value)
  }

  const validate = () => email && emailValidator.validate(email)

  const handleFormSubmission = (event: SyntheticEvent) => {
    event.preventDefault()

    if (validate()) {
      handleSubmit(email)
    }
  }

  return (
    <Styles>
      <IntroStyles>
        Please input your email and we&apos;ll send you instructions on how to
        reset your password. If you have forgotten the email address for your
        account, please reach out to{' '}
        <a href='mailto:info@redaptiveinc.com'>info@redaptiveinc.com</a>.
      </IntroStyles>

      <form onSubmit={handleFormSubmission}>
        <FieldStyles>
          <input
            aria-label='email'
            name='email'
            type='email'
            placeholder='Email'
            ref={emailRef}
            required='required'
            onChange={handleChange}
          />
        </FieldStyles>

        <div className='form-footer'>
          <button disabled={!validate() || loading} type='submit'>
            Submit
          </button>
        </div>
      </form>
    </Styles>
  )
}

export default ForgotPasswordForm
