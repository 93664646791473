// TODO: provide sequence numbering before commit.
export const proposalFieldMappings = [
  {
    fieldName: 'name',
    locked: true,
    visible: true,
    sequenceNumber: 1,
  },
  {
    fieldName: 'createdOnStageName',
    locked: true,
    visible: true,
    sequenceNumber: 2,
  },
  {
    fieldName: 'created',
    locked: false,
    visible: true,
    sequenceNumber: 3,
  },
  {
    fieldName: 'createdBy',
    locked: false,
    visible: true,
    sequenceNumber: 4,
  },
  {
    fieldName: 'modified',
    locked: false,
    visible: true,
    sequenceNumber: 5,
  },
  {
    fieldName: 'modifiedBy',
    locked: false,
    visible: true,
    sequenceNumber: 6,
  },
  {
    fieldName: 'totalProposedFixtureCount',
    locked: false,
    visible: true,
    sequenceNumber: 7,
  },
  {
    fieldName: 'opportunityCurrencyCode',
    locked: false,
    visible: true,
    sequenceNumber: 8,
  },
  {
    fieldName: 'contractTermMonths',
    locked: false,
    visible: true,
    sequenceNumber: 9,
  },
  {
    fieldName: 'energyRetainedSavingsInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 10,
  },
  {
    fieldName: 'maintenanceRetainedSavingsInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 11,
  },
  {
    fieldName: 'grossMarginInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 12,
  },
  {
    fieldName: 'grossMargin',
    locked: false,
    visible: true,
    sequenceNumber: 13,
  },
  {
    fieldName: 'internalRateOfReturn',
    locked: false,
    visible: true,
    sequenceNumber: 14,
  },
  {
    fieldName: 'netMarginInDollars',
    locked: false,
    visible: true,
    sequenceNumber: 15,
  },
  {
    fieldName: 'netMarginInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 16,
  },
  {
    fieldName: 'energyContractValue',
    locked: false,
    visible: true,
    sequenceNumber: 17,
  },
  {
    fieldName: 'maintenanceContractValue',
    locked: false,
    visible: true,
    sequenceNumber: 18,
  },
  {
    fieldName: 'preTaxContractValue',
    locked: false,
    visible: true,
    sequenceNumber: 19,
  },
  {
    fieldName: 'monthlyPaymentToRedaptive',
    locked: false,
    visible: true,
    sequenceNumber: 53,
  },
  {
    fieldName: 'estimatedSalesTax',
    locked: false,
    visible: true,
    sequenceNumber: 20,
  },
  {
    fieldName: 'totalContractValueWithSalesTax',
    locked: false,
    visible: true,
    sequenceNumber: 21,
  },
  {
    fieldName: 'materialCosts',
    locked: false,
    visible: true,
    sequenceNumber: 22,
  },
  {
    fieldName: 'laborCosts',
    locked: false,
    visible: true,
    sequenceNumber: 23,
  },
  {
    fieldName: 'otherCost',
    locked: false,
    visible: true,
    sequenceNumber: 24,
  },
  {
    fieldName: 'vendorSalesTax',
    locked: false,
    visible: true,
    sequenceNumber: 25,
  },
  {
    fieldName: 'meterCount',
    locked: false,
    visible: true,
    sequenceNumber: 26,
  },
  {
    fieldName: 'upFrontMeasurementAndVerification',
    locked: false,
    visible: true,
    sequenceNumber: 27,
  },
  {
    fieldName: 'initialRebateAmount',
    locked: false,
    visible: true,
    sequenceNumber: 28,
  },
  {
    fieldName: 'rebateHcInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 29,
  },
  {
    fieldName: 'finalRebateAmount',
    locked: false,
    visible: true,
    sequenceNumber: 30,
  },
  {
    fieldName: 'contingencyInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 31,
  },
  {
    fieldName: 'contingencyCost',
    locked: false,
    visible: true,
    sequenceNumber: 32,
  },
  {
    fieldName: 'partnerFeeInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 33,
  },
  {
    fieldName: 'partnerFee',
    locked: false,
    visible: true,
    sequenceNumber: 34,
  },
  {
    fieldName: 'referralFeeInPercentage',
    locked: false,
    visible: true,
    sequenceNumber: 35,
  },
  {
    fieldName: 'referralCost',
    locked: false,
    visible: true,
    sequenceNumber: 36,
  },
  {
    fieldName: 'costReduction',
    locked: false,
    visible: true,
    sequenceNumber: 37,
  },
  {
    fieldName: 'upFrontPayment',
    locked: false,
    visible: true,
    sequenceNumber: 38,
  },
  {
    fieldName: 'netCost',
    locked: false,
    visible: true,
    sequenceNumber: 39,
  },
  {
    fieldName: 'annualPreKwh',
    locked: false,
    visible: true,
    sequenceNumber: 40,
  },
  {
    fieldName: 'annualPostKwh',
    locked: false,
    visible: true,
    sequenceNumber: 41,
  },
  {
    fieldName: 'annualSavedKwh',
    locked: false,
    visible: true,
    sequenceNumber: 42,
  },
  {
    fieldName: 'percentInKwhReduction',
    locked: false,
    visible: true,
    sequenceNumber: 43,
  },
  {
    fieldName: 'blockKwh',
    locked: false,
    visible: true,
    sequenceNumber: 44,
  },
  {
    fieldName: 'utilityRate',
    locked: false,
    visible: true,
    sequenceNumber: 45,
  },
  {
    fieldName: 'contractRate',
    locked: false,
    visible: true,
    sequenceNumber: 46,
  },
  {
    fieldName: 'annualEnergySavingsInDollars',
    locked: false,
    visible: true,
    sequenceNumber: 47,
  },
  {
    fieldName: 'annualMaintenanceSavings',
    locked: false,
    visible: true,
    sequenceNumber: 48,
  },
  {
    fieldName: 'annualPreBurnHours',
    locked: false,
    visible: true,
    sequenceNumber: 49,
  },
  {
    fieldName: 'annualPostBurnHours',
    locked: false,
    visible: true,
    sequenceNumber: 50,
  },
  {
    fieldName: 'monthlyPreBurnHours',
    locked: false,
    visible: true,
    sequenceNumber: 51,
  },
  {
    fieldName: 'monthlyPostBurnHours',
    locked: false,
    visible: true,
    sequenceNumber: 52,
  },
  {
    fieldName: 'metricTonsOfCo2AvoidedAnnually',
    locked: false,
    visible: true,
    sequenceNumber: 53,
  },
  {
    fieldName: 'vendorProposalScenarioFileName',
    locked: false,
    visible: true,
    sequenceNumber: 54,
  },
]
export const proposalReviewMappings = [
  {
    fieldName: 'Scenario ID',
    label: 'Scenario ID',
  },
  {
    fieldName: 'ROID',
    label: 'ROID',
  },
  {
    fieldName: 'Address including zip code',
    label: 'Address including zip code',
  },
  {
    fieldName: 'Sq. Feet',
    label: 'Sq. Feet',
  },
  {
    fieldName: 'Total Proposed Fixture Count',
    label: 'Total Proposed Fixture Count',
  },
  {
    fieldName: 'kWh Savings',
    label: 'kWh Savings',
  },
  {
    fieldName: 'Block kWh',
    label: 'Block kWh',
  },
  {
    fieldName: 'kWh Savings  %',
    label: 'kWh Savings  %',
  },
  {
    fieldName: 'Pre Burn Hour',
    label: 'Pre Burn Hour',
  },
  {
    fieldName: 'Pre Burn Hour - Monthly',
    label: 'Pre Burn Hour - Monthly',
  },
  {
    fieldName: 'Post Burn Hour',
    label: 'Post Burn Hour',
  },
  {
    fieldName: 'Post Burn Hour - Monthly',
    label: 'Post Burn Hour - Monthly',
  },
  {
    fieldName: 'Utility Rate',
    label: 'Utility Rate',
  },
  {
    fieldName: 'Contract Rate',
    label: 'Contract Rate',
  },
  {
    fieldName: 'Maintenance Contract Value',
    label: 'Maintenance Contract Value',
  },
  {
    fieldName: 'Monthly Maintenance Contract Value',
    label: 'Monthly Maintenance Contract Value',
  },
  {
    fieldName: 'Final Rebate Amount',
    label: 'Final Rebate Amount',
  },
  {
    fieldName: 'Total Energy Savings',
    label: 'Total Energy Savings',
  },
  {
    fieldName: 'Total Soft Savings',
    label: 'Total Soft Savings',
  },
  {
    fieldName: 'Post Term Savings',
    label: 'Post Term Savings',
  },
  {
    fieldName: 'Annual Payment to Redaptive',
    label: 'Annual Payment to Redaptive',
  },
  {
    fieldName: 'Estimated Annual Net Savings',
    label: 'Estimated Annual Net Savings',
  },
  {
    fieldName: 'Pre-tax Contract Value',
    label: 'Pre-tax Contract Value',
  },
  {
    fieldName: 'Est Sales Tax',
    label: 'Est Sales Tax',
  },
  {
    fieldName: 'Total Contract Value',
    label: 'Total Contract Value',
  },
  {
    fieldName: 'Term Length',
    label: 'Term Length',
  },
  {
    fieldName: 'Term Length - Months',
    label: 'Term Length - Months',
  },
  {
    fieldName: '10 Year Savings',
    label: '10 Year Savings',
  },
  {
    fieldName: 'Retained Maintenance Savings',
    label: 'Retained Maintenance Savings',
  },
  {
    fieldName: 'Pre-Install Consumption',
    label: 'Pre-Install Consumption',
  },
  {
    fieldName: 'Pre-Install Consumption - Monthly',
    label: 'Pre-Install Consumption - Monthly',
  },
  {
    fieldName: 'Post-Install Consumption',
    label: 'Post-Install Consumption',
  },
  {
    fieldName: 'Post-Install Consumption - Monthly',
    label: 'Post-Install Consumption - Monthly',
  },
  {
    fieldName: 'Retained Energy Savings',
    label: 'Retained Energy Savings',
  },
  {
    fieldName: 'Modelled Currency',
    label: 'Modelled Currency',
  },
  {
    fieldName: 'Currency Symbol',
    label: 'Currency Symbol',
  },
  {
    fieldName: 'Estimated Metric Tons of CO2e Avoided Annually',
    label: 'Estimated Metric Tons of CO2e Avoided Annually',
  },
  {
    fieldName: 'Estimated Metric Tons of CO2e Avoided over Contract Term',
    label: 'Estimated Metric Tons of CO2e Avoided over Contract Term',
  },
  {
    fieldName: 'Estimated Metric Tons of CO2e Avoided over EUL',
    label: 'Estimated Metric Tons of CO2e Avoided over EUL',
  },
  {
    fieldName:
      'Additional Retained Savings from UBR Hedging over Contract Term',
    label: 'Additional Retained Savings from UBR Hedging over Contract Term',
  },
  {
    fieldName: 'Additional Savings from Avoided REC Purchase over EUL',
    label: 'Additional Savings from Avoided REC Purchase over EUL',
  },
]
export const proposalReviewCSVMappings = [
  {
    fieldName: 'Field Name',
    label: 'Field Name',
  },
  {
    fieldName: 'Value',
    label: 'Value',
  },
]
export default proposalFieldMappings
