import { PureComponent } from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

const Icon = styled.div`
  width: 16px;
`
const ListItem = styled.div`
  width: 400px;
  height: 40px;

  background-color: ${({ editing }) => (editing ? '#f3f3f3' : '#ffffff')};
  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  input {
    margin-left: 11px;
    padding-left: 11px;
    border-radius: 3px;
    background-color: ${({ editing }) => (editing ? '#ffffff' : '#f3f3f3')};
    border: ${({ editing }) => (editing ? '1px solid #c7c7c7' : 'none')};
    width: 300px;
    height: 34px;
    margin-top: 1px;
  }
`
const ItemValue = styled.div`
  float: left;
  clear: left;
  width: 310px;
  height: 40px;
  line-height: 40px;
  padding-left: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ItemIcons = styled.div`
  float: right;
  margin-top: 10px;
  margin-right: 22px;
  & ${Icon} {
    display: inline-block;
  }
  & ${Icon} + ${Icon} {
    margin-left: 13px;
  }

  & ${Icon}:hover {
    color: #337ab7;
  }

  ${ListItem} & .Spinner.loader {
    margin-top: -2px;
    border-width: 0.4em;
  }
`
type FTProps = {
  onEdit: (...args: Array<any>) => any
  onConfirmDelete: (...args: Array<any>) => any
  onClick: (...args: Array<any>) => any
  editLoading: boolean
  value: string
}
type FTState = {
  editing: boolean
}
export default class EditableItem extends PureComponent<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  onSubmit = () => {
    const { value, onEdit } = this.props
    const { value: inputValue } = this.input

    if (inputValue.length > 0) {
      if (inputValue !== value) {
        onEdit(inputValue)
      }

      this.setState((prev) => ({ ...prev, editing: false }))
    }
  }

  toggleEditing = () => {
    this.setState((prev) => ({ ...prev, editing: !prev.editing }))
  }

  render() {
    const { onConfirmDelete, onClick, editLoading, value } = this.props
    const { editing } = this.state
    return (
      <ListItem editing={editing} title={value}>
        {!editing && (
          <div>
            <ItemValue onClick={onClick}>{value}</ItemValue>
            <ItemIcons>
              {editLoading && <Spinner size='tiny' />}
              {!editLoading && (
                <Icon className='icon-pencil' onClick={this.toggleEditing} />
              )}
              {!editLoading && (
                <Icon className='icon-trash' onClick={onConfirmDelete} />
              )}
            </ItemIcons>
          </div>
        )}
        {editing && (
          <div>
            <input
              defaultValue={value}
              ref={(input) => {
                this.input = input
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.onSubmit()
                }
              }}
            />
            <ItemIcons>
              <Icon className='ion-checkmark' onClick={this.onSubmit} />

              <Icon className='ion-close' onClick={this.toggleEditing} />
            </ItemIcons>
          </div>
        )}
      </ListItem>
    )
  }
}
