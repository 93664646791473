import { useDispatch, useSelector } from 'react-redux'

import withPermissions from '../../../authorization/components/withPermissions'
import features from '../../../authorization/features'
import { FTUserPermissions } from '../../../authorization/models/userPermissions'
import {
  actions as meterActions,
  selectKFactorHistoryItems,
  selectMeterEntity,
} from '../../../ducks/meters'
import { actions as modalActions } from '../../../ducks/modal'
import { renderTimestamp } from '../../../ducks/utils'
import GasMeterConfiguration from '../../GasMeterConfiguration'
import InfoSection from '../../InfoSection'
import MeterInstallLocationAndPowerSource from '../../MeterInstallLocationAndPowerSource'
import MeterUtilityAccount from '../../MeterUtilityAccount'
import TabPane from '../../Tabs/TabPane'
import { GasFirmwareRow } from '../styles'
import {
  canEditGasMeterPermissions,
  getGasMeterFirmwareFields,
  getGasMeterSensorsFields,
} from '../utils'

type PropsType = {
  permissions: FTUserPermissions
  url: string
  meterId: string
  pathname: string
}

function GasMeter({ permissions, url, meterId, pathname }: PropsType) {
  const dispatch = useDispatch()
  const kFactorHistoryItems = useSelector((state) =>
    selectKFactorHistoryItems(state),
  )
  const meterEntity = useSelector((state) => selectMeterEntity(state, meterId))
  const {
    item: meter,
    meta: { loading },
  } = meterEntity

  const [editInstalLocation, editUtilityAccount] = canEditGasMeterPermissions(
    permissions,
    url,
    pathname,
  )
  const renderGasMeterSensors = () => {
    const info = meter ? getGasMeterSensorsFields(meter) : []
    return <InfoSection info={info} />
  }

  const renderGasMeterFirmware = () => {
    const fields = getGasMeterFirmwareFields(meter)
    const sections = [[fields[0], fields[1], fields[2]], [fields[3]]]
    return (
      <GasFirmwareRow>
        {sections.map((section, i) => (
          <div key={`section${i}`}>
            <InfoSection info={section} />
          </div>
        ))}
      </GasFirmwareRow>
    )
  }

  const onMeterLocationAndPowerSourceUpdate = (body) => {
    const requestData = {
      id: meter.id,
      meterPowerSource: body.meterPowerSource,
      installLocation: body.installLocation,
      powerSource: {
        vtapL1PhaseA: body.vtapL1PhaseA,
      },
    }
    dispatch(meterActions.updateMeter(requestData))
  }

  const onMeterUtilityAccountUpdate = (body) => {
    const requestData = {
      meterId: meter.id,
      ...body,
    }
    dispatch(meterActions.updateUtilityAccount(requestData))
    dispatch(
      meterActions.fetchMeter({
        id: meterId,
      }),
    )
  }

  const renderInstallLocationAndPowerSource = () => (
    <MeterInstallLocationAndPowerSource
      meter={meter}
      loading={loading}
      handleUpdate={onMeterLocationAndPowerSourceUpdate}
    />
  )

  const renderUtilityAccount = () => (
    <MeterUtilityAccount
      loading={loading}
      meter={meter}
      handleUpdate={onMeterUtilityAccountUpdate}
    />
  )
  const closeKFactorModal = () => {
    dispatch(modalActions.hideModal())
  }

  const openKFactorModal = (updateMode: boolean) => {
    dispatch(
      modalActions.showModalKfactorBase({
        meterId: meter.id,
        meter,
        closeModal: closeKFactorModal,
        updateMode,
      }),
    )
  }

  const renderGasMeterConfiguration = () => {
    const calculationTypes: Record<string, string> = {
      UTILITY_METER: 'Manufacturer',
      MANUAL: 'Manual',
      CALCULATED: 'Calculated',
    }
    const data =
      kFactorHistoryItems ?
        kFactorHistoryItems.map((item: Record<string, string>, i: number) => ({
          ...item,
          status: i === 0 ? 'Active' : 'Archived',
          comment: item.comment || '-',
          createdFormattedDate: renderTimestamp(
            item.created,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'MM-DD-YY h:mm A z',
          ),
          calculationType: calculationTypes[item.calculationType],
        }))
      : []
    const lastModifiedDate =
      data.length > 0 ?
        renderTimestamp(
          data[0].created,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'YYYY-MM-DD H:mm A z',
        )
      : ''
    const canEditMeterConfig =
      features.editMeterConfig.allMatchWithPermissions(permissions)
    return (
      <GasMeterConfiguration
        data={data}
        lastModified={lastModifiedDate}
        openKFactorModal={openKFactorModal}
        canEditMeterConfig={canEditMeterConfig}
      />
    )
  }

  const tabs = [
    {
      tab: 'sensors',
      text: 'Sensors',
      render: renderGasMeterSensors,
    },
    {
      tab: 'firmware',
      text: 'Hardware & Firmware',
      render: renderGasMeterFirmware,
    },
    {
      tab: 'install-location',
      text: 'Install Location & Power Source',
      render: renderInstallLocationAndPowerSource,
      rightHandLink: editInstalLocation,
    },
    {
      tab: 'utility-account',
      text: 'Utility Account',
      render: renderUtilityAccount,
      rightHandLink: editUtilityAccount,
    },
    {
      tab: 'configuration',
      text: 'Configuration',
      render: renderGasMeterConfiguration,
    },
  ]
  return <TabPane tabs={tabs} />
}

export default withPermissions(GasMeter)
