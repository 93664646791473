import { Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import * as Yup from 'yup'

import './EditCustomerLogoPage.css'
import { getLogoUrl } from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button2 from '../../components/Button2'
import ButtonLink from '../../components/ButtonLink'
import FormField from '../../components/FormField'
import Banner from '../../components/ProposalsEngine/Banner'
import {
  ConfirmBodyContentStyled,
  ConfirmBodyTitleStyled,
} from '../../components/ProposalsEngine/ConfirmModal'
import {
  actions as customerActions,
  FTState,
  selectCustomer,
  selectCustomerMeta,
  selectCustomerPresignUrl,
  selectCustomerUpdateState,
  selectCustomLogoUploadMeta,
} from '../../ducks/customers'
import { actions as modalActions } from '../../ducks/modal'

const renderDeleteConfirmModalBody = () => (
  <>
    <ConfirmBodyTitleStyled>
      Are you sure you want to delete this logo?
    </ConfirmBodyTitleStyled>
    <ConfirmBodyContentStyled>
      We can not recover the logo once you delete.
    </ConfirmBodyContentStyled>
  </>
)

const EditCustomerLogoPage = () => {
  const dispatch = useDispatch()

  const { customerId } = useParams<{ customerId: string }>()
  const customerMeta = useSelector((state: FTState) =>
    selectCustomerMeta(state),
  )
  const customer = useSelector((state: FTState) =>
    selectCustomer(state, customerId),
  )
  const { loading: updateCustomerLoading, error: updateCustomerError } =
    useSelector((state: FTState) => selectCustomerUpdateState(state))
  const { keyName, bucketName } = useSelector((state: FTState) =>
    selectCustomerPresignUrl(state),
  )
  const { loading: customLogoUploadLoading, error: customLogoUploadError } =
    useSelector((state: FTState) => selectCustomLogoUploadMeta(state))

  useEffect(() => {
    dispatch(customerActions.clearCustomLogo())
  }, [dispatch])

  const breadcrumbs = [
    {
      href: '/account-management',
      text: 'Accounts',
    },
    {
      href: '/account-management/customers',
      text: 'Customers',
    },
    {
      href: `/account-management/customers/${customerId}`,
      text: customer?.validName,
    },
  ]

  const CustomerLogoSchema = Yup.object().shape({
    fileName: Yup.string().required(),
    customerInitials: Yup.string()
      .max(3, 'Customer Initials must be no longer than 3 characters')
      .required('Please enter customer initials'),
    customerBgColor: Yup.string().matches(
      /^#([0-9A-F]{3}){1,2}$/i,
      'Invalid hex color code',
    ),
  })

  const [deletingLogo, setDeletingLogo] = useState(false)
  const [savingCustomerUpdate, setSavingCustomerUpdate] = useState(false)
  const [previewImgSrc, setPreviewImgSrc] = useState('')

  const handleLogoDelete = () => {
    setDeletingLogo(true)
    dispatch(
      customerActions.updateCustomer(customerId, {
        name: customer.name,
        customerLogoUrl: '',
        initials: customer?.initials || '',
        logoBackgroundColor: customer?.logoBackgroundColor || '',
      }),
    )
    dispatch(customerActions.clearCustomLogo())
    dispatch(modalActions.hideModal())
  }

  const handleLogoCancel = () => {
    dispatch(modalActions.hideModal())
  }

  const handleDeleteLogo = () => {
    dispatch(
      modalActions.showModalDeleteCustomerLogo({
        handleConfirm: handleLogoDelete,
        handleCancel: handleLogoCancel,
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        renderBody: renderDeleteConfirmModalBody,
      }),
    )
  }

  const customerLogoKeyName = customer?.customerLogoUrl?.replace(
    `${getLogoUrl()}/`,
    '',
  )

  const FormUpdater = () => {
    const { setFieldValue } = useFormikContext()
    const fileName = keyName || customerLogoKeyName

    useEffect(() => {
      setFieldValue('fileName', fileName)
    }, [keyName, customer])

    return null
  }

  const handleNewLogoUpload = () => {
    dispatch(modalActions.showModalUploadCustomerLogo({ customerId }))
  }

  useEffect(() => {
    if (!updateCustomerLoading && !updateCustomerError && deletingLogo) {
      setTimeout(() => {
        setDeletingLogo(false)
        dispatch(customerActions.fetchCustomer({ customerId }))
      }, 5000)
    }
  }, [updateCustomerLoading, deletingLogo])

  useEffect(() => {
    if (
      !updateCustomerLoading &&
      !updateCustomerError &&
      savingCustomerUpdate
    ) {
      setTimeout(() => {
        setSavingCustomerUpdate(false)
        dispatch(customerActions.fetchCustomer({ customerId }))
        dispatch(customerActions.clearCustomLogo())
      }, 5000)
    }
  }, [savingCustomerUpdate, updateCustomerLoading, updateCustomerError])

  useEffect(() => {
    if (!customLogoUploadLoading && !customLogoUploadError && keyName) {
      dispatch(modalActions.hideModal())
      setPreviewImgSrc(`https://${bucketName.replace(/-/g, '.')}/${keyName}`)
    }
  }, [customLogoUploadLoading, customLogoUploadError, keyName, dispatch])

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <div className='UploadCustomerLogoForm'>
        <div className='PageHeader'>
          <h2>Current Custom Logo</h2>
          {!customer?.customerLogoUrl && <div>None uploaded</div>}
          {customer?.customerLogoUrl && !keyName && (
            <Button2
              onClick={handleDeleteLogo}
              type='warning'
              loading={deletingLogo}
            >
              Delete
            </Button2>
          )}
        </div>
        <div>
          {!updateCustomerError && !updateCustomerLoading && deletingLogo && (
            <Banner
              type='success'
              heading='Customer logo deleted successfully'
              text=''
            />
          )}
          {!updateCustomerError &&
            !updateCustomerLoading &&
            savingCustomerUpdate && (
              <Banner
                type='success'
                heading='Customer updated successfully'
                text=''
              />
            )}
        </div>

        {!customerMeta.loading && (
          <Formik
            initialValues={{
              fileName: keyName || '',
              customerInitials: customer?.initials || '',
              customerBgColor: customer?.logoBackgroundColor || '',
            }}
            validationSchema={CustomerLogoSchema}
            onSubmit={() => {}}
            validateOnChange
            validateOnBlur
            validateOnMount
          >
            {({ values, errors, isValid, dirty, resetForm }) => {
              const handleCancel = () => {
                dispatch(customerActions.clearCustomLogo())
                resetForm()
              }

              const handleCustomerSave = () => {
                setSavingCustomerUpdate(true)
                dispatch(
                  customerActions.updateCustomer(customerId, {
                    name: customer.name,
                    customerLogoUrl: values.fileName,
                    initials: values.customerInitials,
                    logoBackgroundColor: values.customerBgColor,
                  }),
                )
              }

              return (
                <div>
                  <div className='FormWrapper'>
                    <div>
                      <p className='FormFieldTitle'>Logo</p>
                      {customer?.customerLogoUrl && !keyName && (
                        <img
                          src={customer?.customerLogoUrl}
                          alt={customer?.validName}
                          style={{
                            maxWidth: '648px',
                            maxHeight: '140px',
                          }}
                        />
                      )}
                      {keyName &&
                        bucketName &&
                        !customLogoUploadLoading &&
                        !customLogoUploadError &&
                        previewImgSrc && (
                          <img
                            src={previewImgSrc}
                            alt='Preview'
                            style={{
                              maxWidth: '648px',
                              maxHeight: '140px',
                            }}
                          />
                        )}
                      <div className='UploadField'>
                        <ButtonLink
                          className='UploadNewLogoButton'
                          type='button'
                          onClick={handleNewLogoUpload}
                        >
                          Upload a new logo
                        </ButtonLink>
                        <div className='Required'>*</div>
                      </div>
                    </div>
                    <FormField
                      name='customerInitials'
                      title='Customer Initials'
                      error={errors.customerInitials}
                      showRequired
                    />
                    <FormField
                      name='customerBgColor'
                      title='Customer Background Color'
                      error={errors.customerBgColor}
                    />
                  </div>
                  <FormUpdater />
                  <div className='Buttons'>
                    <Button2 onClick={handleCancel}>Cancel</Button2>
                    <Button2
                      type='secondary'
                      disabled={
                        !isValid ||
                        !dirty ||
                        deletingLogo ||
                        savingCustomerUpdate
                      }
                      primary
                      onClick={handleCustomerSave}
                    >
                      Save
                    </Button2>
                  </div>
                </div>
              )
            }}
          </Formik>
        )}
        {customerMeta.loading && <div>Re-fetching customer...</div>}
      </div>
    </>
  )
}

export default EditCustomerLogoPage
