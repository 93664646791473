import { Route, Switch } from 'react-router-dom'

import ResetPasswordFormPage from './ResetPasswordFormPage'
import UserFormPage from './UserFormPage'
import UserDetail from '../../components/UserDetail'
import type { FTRouterMatch } from '../../types'

const UserDetailPage = ({ match: { path } }: FTRouterMatch) => (
  <div>
    <Switch>
      <Route path={`${path}/edit/:step?`} component={UserFormPage} />
      <Route path={`${path}/reset`} component={ResetPasswordFormPage} />
      <Route component={UserDetail} />
    </Switch>
  </div>
)

export default UserDetailPage
