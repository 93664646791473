import styled from 'styled-components'

export const IonIconStyles = styled.span`
  position: relative;
  font-size: ${({ fontSize }) => `${fontSize}`};

  & > span {
    position: absolute;
    top: 3px;
    color: ${({ color }) => color && `${color} !important`};
  }
`
export const DEFAULT_ICON = 'ion-android-arrow-dropright'

const IonIcon = ({
  iconClass = DEFAULT_ICON,
  color = '',
  fontSize = '16px',
  title = '',
  ...rest
}: {
  iconClass: string
  color?: string
  fontSize?: string
  title?: string
}) => (
  <IonIconStyles
    className='IonIcon'
    {...rest}
    color={color}
    fontSize={fontSize}
  >
    <span className={iconClass} aria-hidden='true' title={title} />
  </IonIconStyles>
)

export default IonIcon
