import { FastField, Form } from 'formik'
import { PureComponent } from 'react'
import styled from 'styled-components'

import Button from './Button'
import Description from './Description'
import Title from './Title'

const StyledButton = styled(Button)`
  & + & {
    margin: 80px 0 10px 40px;
  }
`
const FormError = styled.div`
  color: #c70d08;
`
const FormNotes = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #7f7f7f;
  user-select: none;
`
const Styles = styled.div`
  b {
    font-weight: 500;
  }
  ${Title} {
    font-weight: 600;
    line-height: 33px;
  }

  ${Description} {
    width: 400px;
    font-size: 14px;
    line-height: 20px;
    b {
      display: block;
      margin-top: 15px;
    }
  }

  textarea {
    width: 400px;
    @media (max-width: 961px) {
      width: 80%;
    }
    height: 240px;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    resize: none;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
    padding: 9px 11px;
    margin-top: 10px;
    &:focus {
      border: 1px solid #0193d7;
    }
  }
`
type FTProps = {
  errors: Record<string, any>
  isSubmitting: boolean
  setSubmitting: (...args: Array<any>) => any
  loading: boolean
  error?: string
  goBack: (...args: Array<any>) => any
}
type FTState = {
  hasSubmissionError: boolean
}
export default class RTMeterStatusForm extends PureComponent<FTProps, FTState> {
  static defaultProps = {
    error: undefined,
  }

  componentDidUpdate(prev: FTProps) {
    if (!prev.error && this.props.error) {
      this.props.setSubmitting(false)
    }
  }

  render() {
    const { errors, error, loading, isSubmitting, goBack } = this.props
    return (
      <Form>
        <Styles>
          <Title>Real-Time Meter Status Tool</Title>
          {!isSubmitting && errors.input && (
            <FormError>{errors.input}</FormError>
          )}
          {!isSubmitting && error && (
            <FormError>
              An error was encountered with this request. Please try again.
            </FormError>
          )}
          <Description>
            Enter a list of meter MAC addresses to get up-to-the-minute meter
            status. This could take up to a minute to complete.
            <b>For Redaptive meters only.</b>
          </Description>
          <div>
            <b>Meter MAC Addresses</b>
          </div>
          <FastField
            name='input'
            component='textarea'
            disabled={isSubmitting}
          />
          <FormNotes>
            Enter up to 50 MAC addresses, one per line, no commas
          </FormNotes>
        </Styles>
        <StyledButton type='button' disabled={isSubmitting} onClick={goBack}>
          Cancel
        </StyledButton>
        <StyledButton
          primary
          type='submit'
          disabled={isSubmitting}
          loading={loading}
        >
          Get Status
        </StyledButton>
      </Form>
    )
  }
}
