import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import ReportProblemIcon from '../../components/Icons/ReportProblemIcon'
import RedaptiveReactTable from '../../components/RedaptiveReactTable'
import StyledLink from '../../components/StyledLink'
import type {
  FTSpecSheetsState,
  FTSpecSheetState,
} from '../../ducks/specSheets'
import {
  actions as specSheetActions,
  selectSpecSheets,
} from '../../ducks/specSheets'
import { isVariantActive, scrollTop } from '../../utils'

type FTProps = {
  actions: Record<string, (...args: Array<any>) => any>
  fileNames: Array<string>
  specSheetsState: FTSpecSheetsState
}
type FTDataRow = {
  status: string
} & FTSpecSheetState
type FTCell = {
  value: string
  row: {
    _original: FTDataRow
  }
}
const ReportProblemIconStyles = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.5em;
`
const TableStyles = styled.div`
  .rt-tr {
    min-height: 44px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .rt-td {
    align-items: center;
    display: flex;
  }
`

const getDataRowFromSpecSheet = (specSheet: FTSpecSheetState): FTDataRow => {
  const { uploadError, uploadRequestError } = specSheet
  return {
    ...specSheet,
    status: uploadRequestError || uploadError || 'Upload Successful',
  }
}

const columns = [
  {
    accessor: 'fileName',
    Cell: ({
      value,
      row: {
        _original: { url },
      },
    }: FTCell) => {
      const name = value.replace('.pdf', '').replace('.PDF', '')
      return url ?
          <StyledLink external href={url}>
            {name}
          </StyledLink>
        : name
    },
    Header: 'Fixture / Lamp',
    minWidth: 80,
  },
  {
    accessor: 'status',
    Cell: ({
      value,
      row: {
        _original: { uploadError, uploadRequestError },
      },
    }: FTCell) => (
      <>
        {(uploadError || uploadRequestError) && (
          <ReportProblemIconStyles>
            <ReportProblemIcon color='#C70D08' inline />
          </ReportProblemIconStyles>
        )}
        {value}
      </>
    ),
    Header: 'Status',
    minWidth: 80,
  },
]

const SpecSheetsUploadResults = (props: FTProps) => {
  const {
    actions,
    fileNames,
    specSheetsState: {
      byId: specSheetsById,
      meta: { loading, error },
    },
  } = props
  const [data, setData]: [Array<FTDataRow>, (...args: Array<any>) => any] =
    useState([])
  const [fetchedSpecSheets, setFetchedSpecSheets]: [
    boolean,
    (...args: Array<any>) => any,
  ] = useState(false)
  // Builds initial data rows from current spec sheets state, which includes any
  // upload errors.
  useEffect(() => {
    scrollTop()
    setData(
      Object.keys(specSheetsById)
        .filter((specSheetName) => fileNames.includes(specSheetName))
        .map((specSheetName) =>
          getDataRowFromSpecSheet(specSheetsById[specSheetName]),
        ),
    )
  }, [])
  // Fetches spec sheets after the data rows are initialized.
  useEffect(() => {
    if (!fetchedSpecSheets) {
      actions.fetchSpecSheets()
      setFetchedSpecSheets(true)
    }
  }, [data])
  // Adds the url to the data rows from the newly fetched spec sheets.
  useEffect(() => {
    if (isVariantActive('2990mock') || isVariantActive('2990mockErrors')) {
      return
    }

    if (fetchedSpecSheets && !loading && !error) {
      setData(
        data.map<FTDataRow>((dataRow) => ({
          ...dataRow,
          url:
            (!!specSheetsById[dataRow.fileName] &&
              specSheetsById[dataRow.fileName].url) ||
            '',
        })),
      )
    }
  }, [loading, error, fetchedSpecSheets])
  return (
    <TableStyles>
      <RedaptiveReactTable
        columns={columns}
        data={data}
        filterable={false}
        sortable={false}
      />
    </TableStyles>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(specSheetActions, dispatch) },
})

const mapStateToProps = (state) => ({
  specSheetsState: selectSpecSheets(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpecSheetsUploadResults)
