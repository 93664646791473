// All fields to be maintained in the order in their respective arrays.
const opportunitySummary = [
  {
    fieldName: 'customer',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'name',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'opportunityMAndVGroupName',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityId',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'codDateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingStructure',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingMetric',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'category',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'contractEstimateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'calculatedActualFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'remainingQuantity',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'dataCapture',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPerformance',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical1Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical2Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical3Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'firstActualBill',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'totalBillableSavings',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'loaDateFormatted',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityOwner',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'actualPreRetrofit',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'actualPostRetrofit',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'lastSavingsRunFormatted',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'selectedAction',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceNumber',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceCreatedDateTimeFormatted',
    isVisible: true,
    isLocked: false,
  },
]
const underInvestigation = [
  {
    fieldName: 'customer',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'name',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'opportunityMAndVGroupName',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityId',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'codDateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingStructure',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingMetric',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'category',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'contractEstimateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'calculatedActualFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'remainingQuantity',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'dataCapture',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPerformance',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical1Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical2Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical3Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'firstActualBill',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'issueOwners',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'totalBillableSavings',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'loaDateFormatted',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityOwner',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'actualPreRetrofit',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'actualPostRetrofit',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'lastSavingsRunFormatted',
    isVisible: false,
    isLocked: false,
  },
]
const performanceVerification = [
  {
    fieldName: 'customer',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'name',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'opportunityMAndVGroupName',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityId',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'codDateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingStructure',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingMetric',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'loaDateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'category',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'contractEstimateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'calculatedActualFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'remainingQuantity',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'dataCapture',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPerformance',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical1Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical2Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical3Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'firstActualBill',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'totalBillableSavings',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityOwner',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'actualPreRetrofit',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'actualPostRetrofit',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'lastSavingsRunFormatted',
    isVisible: false,
    isLocked: false,
  },
]
const invoiceApproval = [
  {
    fieldName: 'customer',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'name',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'opportunityMAndVGroupName',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityId',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingStructure',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingMetric',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'category',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'contractEstimateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'calculatedActualFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'dataCapture',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPerformance',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical1Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical2Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical3Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'firstBill',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'selectedAction',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'totalBillableSavings',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPreRetrofit',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPostRetrofit',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceNumber',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceCreationStatusFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceCreatedDateTimeFormatted',
    isVisible: false,
    isLocked: false,
  },
]
const sentToNetsuite = [
  {
    fieldName: 'customer',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'name',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'opportunityMAndVGroupName',
    isVisible: false,
    isLocked: false,
  },
  {
    fieldName: 'opportunityId',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingStructure',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'billingMetric',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'category',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'contractEstimateFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'calculatedActualFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'dataCapture',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPerformance',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'firstActualBill',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical2Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'historical3Month',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'firstBill',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'selectedAction',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'totalBillableSavings',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'totalBillableSavings',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'actualPostRetrofit',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceNumber',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceCreationStatusFormatted',
    isVisible: true,
    isLocked: false,
  },
  {
    fieldName: 'invoiceCreatedDateTimeFormatted',
    isVisible: true,
    isLocked: false,
  },
]

const appendSequneceNumber = (arr) =>
  arr.map((cols, index) => ({ ...cols, sequenceNumber: index }))

export default {
  opportunitySummary: appendSequneceNumber(opportunitySummary),
  underInvestigation: appendSequneceNumber(underInvestigation),
  performanceVerification: appendSequneceNumber(performanceVerification),
  invoiceApproval: appendSequneceNumber(invoiceApproval),
  sentToNetsuite: appendSequneceNumber(sentToNetsuite),
}
