type iconProps = {
  fill?: string
}
export default ({ fill }: iconProps) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.4648 11C12.1996 11 11.9453 11.1054 11.7577 11.2929C11.5702 11.4804 11.4648 11.7348 11.4648 12V16C11.4648 16.2652 11.5702 16.5196 11.7577 16.7071C11.9453 16.8946 12.1996 17 12.4648 17C12.7301 17 12.9844 16.8946 13.172 16.7071C13.3595 16.5196 13.4648 16.2652 13.4648 16V12C13.4648 11.7348 13.3595 11.4804 13.172 11.2929C12.9844 11.1054 12.7301 11 12.4648 11ZM12.8448 7.08C12.6014 6.97998 12.3283 6.97998 12.0848 7.08C11.9621 7.12759 11.85 7.19896 11.7548 7.29C11.6665 7.3872 11.5955 7.49882 11.5448 7.62C11.4889 7.73868 11.4615 7.86882 11.4648 8C11.4641 8.13161 11.4893 8.26207 11.5391 8.38391C11.5888 8.50574 11.6622 8.61656 11.7548 8.71C11.852 8.79833 11.9637 8.86936 12.0848 8.92C12.2363 8.98224 12.4008 9.00632 12.5638 8.99011C12.7268 8.97391 12.8833 8.91792 13.0196 8.82707C13.1558 8.73622 13.2677 8.61328 13.3454 8.46907C13.423 8.32486 13.464 8.16378 13.4648 8C13.4612 7.73523 13.3576 7.48163 13.1748 7.29C13.0797 7.19896 12.9676 7.12759 12.8448 7.08ZM12.4648 2C10.487 2 8.55364 2.58649 6.90915 3.6853C5.26465 4.78412 3.98293 6.3459 3.22605 8.17317C2.46918 10.0004 2.27114 12.0111 2.657 13.9509C3.04285 15.8907 3.99526 17.6725 5.39378 19.0711C6.79231 20.4696 8.57414 21.422 10.5139 21.8079C12.4538 22.1937 14.4644 21.9957 16.2917 21.2388C18.1189 20.4819 19.6807 19.2002 20.7795 17.5557C21.8784 15.9112 22.4648 13.9778 22.4648 12C22.4648 10.6868 22.2062 9.38642 21.7036 8.17317C21.2011 6.95991 20.4645 5.85752 19.5359 4.92893C18.6073 4.00035 17.5049 3.26375 16.2917 2.7612C15.0784 2.25866 13.7781 2 12.4648 2ZM12.4648 20C10.8826 20 9.33588 19.5308 8.02029 18.6518C6.70469 17.7727 5.67931 16.5233 5.07381 15.0615C4.46831 13.5997 4.30988 11.9911 4.61857 10.4393C4.92725 8.88743 5.68917 7.46197 6.80799 6.34315C7.92681 5.22433 9.35228 4.4624 10.9041 4.15372C12.456 3.84504 14.0645 4.00346 15.5263 4.60896C16.9881 5.21447 18.2376 6.23984 19.1166 7.55544C19.9957 8.87103 20.4648 10.4177 20.4648 12C20.4648 14.1217 19.622 16.1566 18.1217 17.6569C16.6214 19.1571 14.5866 20 12.4648 20Z'
      fill={fill || '#6C6D6E'}
    />
  </svg>
)
