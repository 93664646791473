import type { ComponentType } from 'react'

import 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../utils/themes'
import EditNoteIcon from '../Icons/EditNoteIcon'

const EditModeStyles = css`
  color: ${colors.green};
`
const EditNoteIconStyled: ComponentType<{
  editMode?: boolean
}> = styled(EditNoteIcon)`
  cursor: pointer;
  ${({ editMode }) => editMode && EditModeStyles};
`
export default EditNoteIconStyled
