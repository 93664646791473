import styled from 'styled-components'

import Icons from './index'

const NotVerifiedIcon = styled(Icons.MaterialIcon).attrs({
  children: 'gpp_bad',
})`
  color: #545b61;
`
export default NotVerifiedIcon
