type FTRoleAdmin = 'admin'
type FTRoleManager = 'manager'
type FTRoleEditor = 'editor'
type FTRoleCreator = 'creator'
type FTRoleInspector = 'inspector'
type FTRoleUnauthorized = 'unauthorized'
export type FTRole =
  | FTRoleAdmin
  | FTRoleManager
  | FTRoleEditor
  | FTRoleCreator
  | FTRoleInspector
  | FTRoleUnauthorized
export default class Roles {
  static ADMIN: FTRoleAdmin = 'admin'

  static MANAGER: FTRoleManager = 'manager'

  static EDITOR: FTRoleEditor = 'editor'

  static CREATOR: FTRoleCreator = 'creator'

  static INSPECTOR: FTRoleInspector = 'inspector'

  static UNAUTHORIZED: FTRoleUnauthorized = 'unauthorized'
}
