import { Field } from 'formik'
import {
  type ComponentType,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { type FTUserFormValues, getDefaultValues } from './index'
import { type FTCustomer } from '../../ducks/customers'
import {
  type FTUser,
  denaliUserGroupsMap,
  maUserGroupsMap,
  maUserRolesApiNames,
  r1UserRolesApiNames,
  userGroupsMap,
} from '../../ducks/users'
import type { FTUserSummary } from '../../ducks/userSummaries'
import type { FTSite, FTWithFormik } from '../../types'
import {
  naturallySortEmptyLastCaseInsensitive,
  naturallySortItems,
} from '../../utils'
import Button, { ButtonStyles } from '../Button'
import ButtonLink from '../ButtonLink'
import Checkbox from '../Checkbox'
import ReportProblemIcon from '../Icons/ReportProblemIcon'
import SuccessArrowIcon from '../Icons/SuccessArrowIcon'
import Input, { InputErrorMessageStyles } from '../Input'
import ListSelector, { SelectStyles } from '../ListSelector'
import OneTimeLink, {
  OneTimeLinkStyles,
  OneTimeLinkTitleStyles,
} from '../OneTimeLink'
import RadioButton from '../RadioButton'
import RedaptiveReactTable, {
  RedaptiveReactTableStyles,
} from '../RedaptiveReactTable'
import Stepper, {
  type FTStep,
  StepLabelStyles,
  StepperInstructionsStyles,
  StepperStyles,
} from '../Stepper'

const FormStyles = styled.div`
  ${InputErrorMessageStyles} {
    top: unset;
    bottom: -44px;
    left: 0;
  }

  ${StepperStyles} {
    margin-bottom: 24px;
  }

  ${StepLabelStyles} {
    font-size: 14px;
  }

  ${StepperInstructionsStyles} {
    margin-top: 0;
  }

  [name='email'] {
    width: 300px;
  }
`
const FieldLabelStyled = styled.label`
  font-weight: 600;
`
const SelectSitesFieldLabelWrapperStyled = styled.div`
  margin-bottom: 4px;
`
const SelectSitesListSelectorStyled = styled.div`
  margin-bottom: 40px;
`
const SystemAccessCheckboxLabelStyled: ComponentType<{
  disabled?: boolean
}> = styled(FieldLabelStyled)`
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`
const CustomFieldStyled: ComponentType<{
  disabled?: boolean
}> = styled(Field)`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`
const EmailFieldLabelStyled = styled(FieldLabelStyled)`
  display: block;
  margin-bottom: 8px;
`
const SectionHeadingStyled = styled.h3`
  font-weight: 600;
  font-size: 20px;
`
const FieldGroupStyled = styled.section`
  margin-bottom: 21px;
`
const UserFieldStyled = styled.div`
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
`
const FieldsRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2%;
`
const DisabledStyles = css`
  color: #c4c4c4;
`
const SystemAccessCheckboxFieldsStyled = styled.section`
  margin-bottom: 40px;
`
const CheckboxFieldWithDescriptionStyled: ComponentType<{
  disabled: boolean
}> = styled.div`
  ${({ disabled }) => disabled && DisabledStyles};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  margin-top: 24px;
`
const CheckboxFieldDescriptionStyled = styled.div`
  color: #7f7f7f;
  padding-left: 27px;
`
const SecondaryCheckboxFieldWithDescriptionStyled = styled.div`
  ${CheckboxFieldWithDescriptionStyled};
  margin-top: 9px;
`
const SecondaryCheckboxFieldDescriptionStyled = styled.div`
  color: #7f7f7f;
  padding-left: 27px;

  ${FieldLabelStyled} {
    font-weight: 400;
  }
`
const CheckboxDisabledUncheckedStyles = css`
  .rc-checkbox-inner:after {
    display: none;
  }
`
const CheckboxStyled: ComponentType<{
  checked: boolean
  disabled?: boolean
}> = styled(Checkbox)`
  ${({ checked, disabled }) =>
    !checked && disabled && CheckboxDisabledUncheckedStyles};
`
const ButtonsSectionStyled = styled.section`
  ${ButtonStyles} {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
`
const ContinueErrorStyled = styled.div`
  color: #c70d08;
  display: flex;
  align-items: center;
  padding: 10px 0;
`
const GroupsFieldWrapperStyled = styled.div`
  margin-bottom: 18px;
`
const GroupsFieldWithLabelStyled = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 300px;
`
const GroupsFieldDescriptionStyled = styled.ul`
  display: inline-block;
  margin: 0;
  max-width: 100%;
  width: 420px;
`
const CustomerTableStyled = styled.div`
  height: 508px;
  margin-bottom: 30px;
  max-width: 100%;
  overflow-y: auto;
  width: 800px;

  ${RedaptiveReactTableStyles} {
    overflow-y: auto;
  }
`
const SystemAccessSelectSitesStyled = styled.div`
  width: 100%;
`
const ConfirmTopSectionStyled = styled.section`
  margin-bottom: 20px;
`
const ConfirmBottomSectionStyled = styled.section`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
`
const ConfirmUserGroupsStyled = styled.div`
  display: inline-block;
  width: 400px;
  margin-right: 20px;
  max-width: 33%;
`
const ConfirmUserDashboardAccessTableStyled = styled.table`
  td:first-child {
    min-width: 200px;
    padding-right: 20px;
  }
`
const ConfirmListStyled = styled.ul`
  margin: 0;
  padding-left: 1em;
`
const DisabledFieldStyles = css`
  background-color: #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
`
const EmailFieldStyled: ComponentType<{
  disabled?: boolean
}> = styled(Input)`
  width: 340px;

  ${({ disabled }) => disabled && DisabledFieldStyles};
`
const ResultsStatusStyled = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #e0e0e0;
  display: inline-block;
  margin-bottom: 30px;
  padding: 4px 14px 4px 10px;

  ${SuccessArrowIcon} {
    color: #7bcc76;
    margin-right: 4px;
  }
`
const ResultsStatusInnerStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`
const OneTimeLinkWrapper = styled.div`
  margin-bottom: 30px;

  ${OneTimeLinkStyles} {
    margin: 0;
  }

  ${OneTimeLinkTitleStyles} {
    margin-top: 0;
    text-align: center;
  }
`
const sourceMap = {
  REDAPTIVE: 'Grand Central',
  WORKMARKET: 'Workmarket',
}
const SelectSitesWrapperStyled = styled.div`
  margin: 0 auto 36px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`
const SelectSitesListWrapperStyled = styled.div`
  ${SelectStyles} {
    &,
    .Select {
      height: auto;
      min-height: 36px;
    }
  }
`
type FTHandleChange = {
  target: {
    checked: boolean | null | undefined
    field: {
      name: string
    }
    type: string
    value: string
  }
}
type FTSystemAccessStep =
  | 'customersAccess'
  | 'groups'
  | 'groupsDenali'
  | 'sitesAccess'
  | 'maGroups'
  | ''
type FTConfirmStep = 'confirm' | 'results' | ''
export type FTCustomerAccessType = 'allSites' | 'selectSites'
type FTCustomerFieldCell = {
  original: FTCustomer
}
type FTContinueErrors = Record<string, string>
type FTProps = FTWithFormik & {
  cancelAction: (...args: Array<any>) => any
  createLoading: boolean
  customerId: string | null | undefined
  customerIds: Array<string>
  customerIdUrlParam: string | null | undefined
  customers: Array<FTCustomer>
  customersById: Record<string, FTCustomer>
  isAccountManager: boolean
  isAdmin: boolean
  navigateToCreateUser: (...args: Array<any>) => any
  newUser: FTUser | null | undefined
  setStep: (...args: Array<any>) => any
  sites: Array<FTSite>
  sitesById: Record<string, FTSite>
  step: number
  submitAction: (...args: Array<any>) => any
  updateLoading: boolean
  userError: string
  userSummaries: Array<FTUserSummary>
  values: FTUserFormValues
}

const UserForm = (props: FTProps) => {
  const {
    cancelAction,
    createLoading,
    customerId,
    customerIds,
    customerIdUrlParam,
    customers,
    customersById,
    errors,
    isAccountManager,
    isAdmin,
    navigateToCreateUser,
    newUser,
    setValues,
    setFieldError,
    setFieldValue,
    setStep,
    sites,
    sitesById,
    step,
    submitAction,
    validateField,
    updateLoading,
    userError,
    userSummaries,
    values,
  } = props

  const canAccessInformationStep = isAdmin || isAccountManager || !values.userId
  const defaultSystemAccessStep = isAdmin ? '' : 'customersAccess'
  const [systemAccessStep, setSystemAccessStep]: [
    FTSystemAccessStep,
    (...args: Array<any>) => any,
  ] = useState(defaultSystemAccessStep)
  const [customerListHasScrolled, setCustomerListHasScrolled]: [
    boolean,
    (...args: Array<any>) => any,
  ] = useState(false)
  const [finalStep, setFinalStep]: [
    FTConfirmStep,
    (...args: Array<any>) => any,
  ] = useState('confirm')
  const [submitted, setSubmitted]: [boolean, (...args: Array<any>) => any] =
    useState(false)
  const [userEmails, setUserEmails]: [
    Array<string>,
    (...args: Array<any>) => any,
  ] = useState([])
  const [customersContinueClicked, setCustomersContinueClicked] =
    useState(false)
  const [groupsContinueClicked, setGroupsContinueClicked] = useState(false)
  const [groupsDenaliContinueClicked, setGroupsDenaliContinueClicked] =
    useState(false)
  const [maGroupsContinueClicked, setMaGroupsContinueClicked] = useState(false)
  const [informationContinueClicked, setInformationContinueClicked] =
    useState(false)
  const isWorkmarketUser = values.source === 'WORKMARKET'
  const hasSiteCustomerId = Object.keys(values.customers).some(
    (key) => values.customers[key] === 'selectSites',
  )
  const hasAllSites = Object.keys(values.customers).some(
    (key) => values.customers[key] === 'allSites',
  )

  const resetForm = () => {
    setValues(
      getDefaultValues({
        isAdmin,
      }),
    )
    setSubmitted(false)
    setSystemAccessStep(defaultSystemAccessStep)
    setFinalStep('confirm')
  }

  const emailRef = useRef()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const sourceRef = useRef()
  const phoneRef = useRef()
  const companyRef = useRef()
  const customerTableStyledRef = useRef()
  const [continueErrors, setContinueErrors]: [
    FTContinueErrors,
    (...args: Array<any>) => any,
  ] = useState({
    information: '',
    groups: '',
    customers: '',
    sites: '',
  })
  const sortCustomers = useCallback(
    (a: string, b: string) =>
      naturallySortEmptyLastCaseInsensitive(
        customersById[a].name,
        customersById[b].name,
      ),
    [customersById],
  )
  const selectedCustomers: Array<string> = Object.keys(values.customers)
    .filter((id) => values.customers[id] === 'selectSites')
    .sort(sortCustomers)
  const sortSiteIds = useCallback(
    (a: string, b: string) =>
      naturallySortEmptyLastCaseInsensitive(
        sitesById[a].validName,
        sitesById[b].validName,
      ),
    [sitesById],
  )
  const sitesIdByCustomer = selectedCustomers.reduce(
    (a, customId) => ({
      ...a,
      [customId]: values.sites
        .filter((id) => sites.length && sitesById[id]?.customerId === customId)
        .sort(sortSiteIds),
    }),
    {},
  )
  const [selectedSitesByCustomer, setSelectedSitesByCustomer]: [
    Record<string, any>,
    (...args: Array<any>) => any,
  ] = useState(sitesIdByCustomer)
  const selectedSitesByCustomerSorted = useMemo(
    () =>
      Object.keys(selectedSitesByCustomer).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: selectedSitesByCustomer[cur].sort(sortSiteIds),
        }),
        {},
      ),
    [selectedSitesByCustomer, sitesIdByCustomer],
  )
  const userTypeSelected =
    values.systemAccessDashboardUser ||
    values.systemAccessDenaliUser ||
    values.systemAccessMaUser ||
    values.systemAccessGcUser
  const informationStepValid =
    !errors.email &&
    !errors.firstName &&
    !errors.lastName &&
    !errors.phone &&
    values.email &&
    userTypeSelected
  const systemAccessGroupsValid = Object.keys(values.groups).some(
    (key) => key && values.groups[key] && !['user', 'denaliUser'].includes(key),
  )
  const systemAccessGroupsDenaliValid = true
  const systemAccessMaGroupsValid = Object.keys(values.groups)
    .filter((key) => Object.keys(maUserGroupsMap).includes(key))
    .some((key) => key && values.groups[key])
  const systemAccessCustomersSelectValid = Object.keys(values.customers).length
  const systemAccessSitesSelectValid = !!values.sites.length
  const systemAccessStepValid =
    !!values.groups.admin ||
    (hasSiteCustomerId ?
      systemAccessCustomersSelectValid && systemAccessSitesSelectValid
    : systemAccessCustomersSelectValid)
  const optionalSteps: Array<FTStep> =
    canAccessInformationStep || !values.userId ?
      [
        {
          label: 'Information',
          canClick: finalStep !== 'results',
        },
      ]
    : []
  const steps: Array<FTStep> = [
    ...optionalSteps,
    {
      label: 'System Access',
      canClick:
        finalStep !== 'results' &&
        informationStepValid &&
        (values.systemAccessDashboardUser ||
          values.systemAccessMaUser ||
          values.systemAccessDenaliUser ||
          (values.systemAccessGcUser && isAdmin)),
    },
    {
      label: 'Confirm',
      canClick:
        finalStep !== 'results' &&
        informationStepValid &&
        systemAccessStepValid,
    },
  ]

  /**
   * Handles case where a user came from a customer detail page.
   *
   * Sets the new user as a dashboard user and preselects their customer.
   */
  useEffect(() => {
    if (customerId && !values.userId) {
      setValues({
        ...values,
        systemAccessDashboardUser: true,
        customers: {
          [customerId]: 'allSites',
        },
      })
    }
  }, [])

  /**
   * Handles the case where a user arrives at the form with the step already
   * set to 2 or 3.
   *
   * Resets the form to step 1.
   */
  useEffect(() => {
    if ((step === 2 || step === 3) && !informationStepValid) {
      setStep(1)
    }
  }, [step])

  /**
   * Populates the email field if this is the edit user form.
   */
  useEffect(() => {
    if (step === 1) {
      if (emailRef.current) emailRef.current.value = values.email
      // In the 'Add another' flow, the values.source would already be the mapped string
      // and sourceMap[values.source] would be undefined.
      if (sourceRef.current)
        sourceRef.current.value = sourceMap[values.source] || values.source
      if (companyRef.current) companyRef.current.value = values.company
      if (phoneRef.current) phoneRef.current.value = values.phone
      if (firstNameRef.current) firstNameRef.current.value = values.firstName
      if (lastNameRef.current) lastNameRef.current.value = values.lastName
    }
  }, [step])

  /**
   * Shows the results step after the form has been successfully submitted.
   */
  useEffect(() => {
    if (!createLoading && !updateLoading && submitted) {
      setFinalStep('results')
      setInformationContinueClicked(false)
      setCustomersContinueClicked(false)
      setGroupsContinueClicked(false)
      setGroupsDenaliContinueClicked(false)
    }
  }, [createLoading, updateLoading, submitted])

  /**
   * Resets the form when a user navigates away from it.
   */
  useEffect(
    () => () => {
      resetForm()
    },
    [],
  )

  /**
   * Handles the case when a user has used the "Add User" link on a customer
   * page or a single site user is edited.
   *
   * Scrolls the customer select form to the user's customer.
   */
  useEffect(() => {
    if (
      step === steps.length - 1 &&
      systemAccessStep === 'customersAccess' &&
      !customerListHasScrolled &&
      customerId
    ) {
      setCustomerListHasScrolled(true)
      const { current: customerTableStyledElement } =
        customerTableStyledRef || {}
      // rc-checkbox doesn't have a method for accessing the input element so
      // resorting to document.getElementById() is needed.
      const matchedCustomerAllSitesCheckboxElement = document.getElementById(
        'matchedCustomerAllSitesRadio',
      )

      if (
        !matchedCustomerAllSitesCheckboxElement ||
        !customerTableStyledElement
      ) {
        return
      }

      // $FlowFixMe
      const reactTableGroup =
        matchedCustomerAllSitesCheckboxElement.closest?.('.rt-tr-group') || {}
      // $FlowFixMe
      const offsetTopGroupToBody = reactTableGroup?.offsetTop || 0

      if (offsetTopGroupToBody) {
        customerTableStyledElement.scrollTop = offsetTopGroupToBody
      }
    }
  }, [customerId, customerListHasScrolled, step, systemAccessStep])
  useEffect(() => {
    setUserEmails(userSummaries.map((userSummary) => userSummary.email))
  }, [userSummaries])

  const updateInformationContinueError = () => {
    const informationErrors = []

    if (!informationStepValid && informationContinueClicked) {
      if (!errors.email && !values.email) {
        informationErrors.push('Email address cannot be empty.')
      }

      if (!userTypeSelected) {
        informationErrors.push('Select at least one option.')
      }
    }

    setContinueErrors({
      ...continueErrors,
      information: informationErrors.join(' '),
    })
  }

  useEffect(() => {
    updateInformationContinueError()
  }, [informationContinueClicked, informationStepValid, userTypeSelected])

  const handleInformationContinueClick = () => {
    setInformationContinueClicked(true)

    if (!informationStepValid) {
      updateInformationContinueError()
      return
    }

    if (values.systemAccessGcUser && isAdmin) {
      setSystemAccessStep('groups')
      setStep(step + 1)
    } else if (values.systemAccessDenaliUser && isAdmin) {
      setSystemAccessStep('groupsDenali')
      setStep(step + 1)
    } else if (
      values.systemAccessDashboardUser ||
      values.systemAccessDenaliUser
    ) {
      setSystemAccessStep('customersAccess')
      setStep(step + 1)
    } else if (values.systemAccessMaUser) {
      setSystemAccessStep('maGroups')
      setStep(step + 1)
    } else {
      setStep(steps.length)
    }
  }

  const updateGroupsContinueError = () => {
    if (groupsContinueClicked) {
      setContinueErrors({
        ...continueErrors,
        groups:
          systemAccessGroupsValid ? '' : (
            (values.userId &&
              'Select at least one User Group. Deactivate Grand Central Access from Actions Menu on User Page.') ||
            'Select at least one User Group'
          ),
      })
    }
  }

  useEffect(() => {
    updateGroupsContinueError()
  }, [groupsContinueClicked, systemAccessGroupsValid])

  const handleGroupsContinueClick = () => {
    setGroupsContinueClicked(true)

    if (!systemAccessGroupsValid) {
      updateGroupsContinueError()
      return
    }

    if (values.systemAccessDenaliUser) {
      setSystemAccessStep('groupsDenali')
    } else if (
      (values.systemAccessDashboardUser || values.systemAccessDenaliUser) &&
      !(values.groups.admin || values.groups.denaliAdmin)
    ) {
      setSystemAccessStep('customersAccess')
    } else if (values.systemAccessMaUser) {
      setSystemAccessStep('maGroups')
    } else {
      setStep(step + 1)
    }
  }

  const updateCustomersContinueError = () => {
    if (customersContinueClicked) {
      setContinueErrors({
        ...continueErrors,
        customers:
          systemAccessCustomersSelectValid ? '' : (
            (values.userId &&
              'Select at least one Site. Deactivate Dashboard Access from Actions Menu on User Page.') ||
            'Select at least one Site'
          ),
      })
    }
  }

  useEffect(() => {
    updateCustomersContinueError()
  }, [customersContinueClicked, systemAccessCustomersSelectValid])
  useEffect(() => {
    const sitesIds = Object.keys(selectedSitesByCustomer).reduce(
      (arr, id) => arr.concat(selectedSitesByCustomer[id]),
      [],
    )
    setFieldValue('sites', sitesIds)
  }, [selectedSitesByCustomer])

  const handleCustomersContinueClick = () => {
    setCustomersContinueClicked(true)

    if (!systemAccessCustomersSelectValid) {
      updateCustomersContinueError()
      return
    }

    if (hasSiteCustomerId) {
      setSystemAccessStep('sitesAccess')
    } else if (values.systemAccessMaUser) {
      setSystemAccessStep('maGroups')
    } else {
      setStep(step + 1)
    }
  }

  const handleSitesContinueClick = () => {
    if (values.systemAccessMaUser) {
      setSystemAccessStep('maGroups')
    } else {
      setStep(step + 1)
    }
  }

  const updateMaGroupsContinueError = () => {
    if (maGroupsContinueClicked) {
      setContinueErrors({
        ...continueErrors,
        groups:
          systemAccessMaGroupsValid ? '' : (
            (values.userId &&
              'Select at least one User Group. Deactive Mobile App from Actions Menu on User Page.') ||
            'Select at least one User Group'
          ),
      })
    }
  }

  useEffect(() => {
    updateMaGroupsContinueError()
  }, [maGroupsContinueClicked, systemAccessMaGroupsValid])

  const handleMaGroupsContinueClick = () => {
    setMaGroupsContinueClicked(true)

    if (!systemAccessMaGroupsValid) {
      updateMaGroupsContinueError()
      return
    }

    setStep(step + 1)
  }

  const handleMaGroupsBackClick = () => {
    if (
      (values.systemAccessDashboardUser || values.systemAccessDenaliUser) &&
      !(values.groups.admin || values.groups.denaliAdmin)
    ) {
      setSystemAccessStep('customersAccess')
    } else if (values.groups.admin) {
      setSystemAccessStep('groups')
    } else if (values.systemAccessDenaliUser) {
      setSystemAccessStep('groupsDenali')
    } else {
      setStep(step - 1)
    }
  }

  const updateGroupsDenaliContinueError = () => {
    if (groupsDenaliContinueClicked) {
      setContinueErrors({
        ...continueErrors,
        groups:
          systemAccessGroupsDenaliValid ? '' : 'Select at least one User Group',
      })
    }
  }

  useEffect(() => {
    updateGroupsDenaliContinueError()
  }, [groupsDenaliContinueClicked, systemAccessGroupsDenaliValid])

  const handleGroupsDenaliContinueClick = () => {
    setGroupsDenaliContinueClicked(true)

    if (!systemAccessGroupsDenaliValid) {
      updateGroupsDenaliContinueError()
      return
    }

    if (values.groups.energyStarCoordinator) {
      setValues({
        ...values,
        customers: Object.keys(values.customers).reduce(
          (acc, cur) =>
            values.customers[cur] === 'selectSites' ?
              acc
            : { ...acc, [cur]: values.customers[cur] },
          {},
        ),
      })
    }

    if (
      (values.systemAccessDashboardUser || values.systemAccessDenaliUser) &&
      !(values.groups.admin || values.groups.denaliAdmin)
    ) {
      setSystemAccessStep('customersAccess')
    } else if (values.systemAccessMaUser) {
      setSystemAccessStep('maGroups')
    } else {
      setStep(step + 1)
    }
  }

  const handleGroupsDenaliBackClick = () => {
    if (values.systemAccessGcUser && isAdmin) {
      setSystemAccessStep('groups')
    } else {
      setStep(step - 1)
    }
  }

  const selectedGroups: Array<string> = Object.keys(values.groups).filter(
    (key) => values.groups[key],
  )
  const selectedGroupsDenali: Array<string> = Object.keys(values.groups)
    .filter((key) => values.groups[key])
    .filter((key) => Object.keys(denaliUserGroupsMap).includes(key))
  const selectedMaGroups: Array<string> = Object.keys(values.groups)
    .filter((key) => values.groups[key])
    .filter((key) => Object.keys(maUserGroupsMap).includes(key))

  const handleEmailBlur = () => {
    if (userEmails.includes(values.email)) {
      setFieldError('email', 'Username already exists')
    } else {
      validateField('email')
    }
  }

  const handleEmailChange = ({ target: { value } }: FTHandleChange) => {
    if (errors.email) {
      validateField('email')
    }

    setFieldValue('email', value)
  }

  const handleOptionalFieldsChange = (
    { target: { value } }: FTHandleChange,
    fieldName,
  ) => {
    if (errors[fieldName]) {
      validateField(fieldName)
    }

    setFieldValue(fieldName, value)
  }

  const handleOptionalFieldsBlur = (fieldName) => {
    validateField(fieldName)
  }

  const handleGcSystemAccessClick = () => {
    if (isAdmin) {
      const newSystemAccessGcUser = !values.systemAccessGcUser
      const newValues = {
        ...values,
        systemAccessGcUser: newSystemAccessGcUser,
      }

      if (!newSystemAccessGcUser) {
        newValues.groups = {
          ...Object.keys(values.groups) // To make sure that the selected MA and Denali groups are not set to false.
            .reduce(
              (acc, cur) => ({
                ...acc,
                [cur]: !!(
                  selectedMaGroups.includes(cur) ||
                  selectedGroupsDenali.includes(cur)
                ),
              }),
              {},
            ),
          denaliUser: values.denaliUser,
          user: values.user,
        }
      }

      setValues(newValues)
    }
  }

  const handleDaSystemAccessClick = () => {
    if (isAdmin || isAccountManager) {
      const newSystemAccessDashboardUser = !values.systemAccessDashboardUser
      const newValues = {
        ...values,
        systemAccessDashboardUser: newSystemAccessDashboardUser,
      }

      if (!newSystemAccessDashboardUser) {
        newValues.groups = { ...values.groups, user: false }
      }

      setValues(newValues)
    }
  }

  const handleMaSystemAccessClick = () => {
    if (isAdmin || isAccountManager) {
      const newSystemAccessMaUser = !values.systemAccessMaUser
      const newValues = {
        ...values,
        systemAccessMaUser: newSystemAccessMaUser,
        sendWelcomeEmail: newSystemAccessMaUser,
      }

      if (!newSystemAccessMaUser) {
        newValues.groups = { ...values.groups }
      }

      setValues(newValues)
    }
  }

  const handleDenaliSystemAccessClick = () => {
    if (isAdmin || isAccountManager) {
      const newSystemAccessDenaliUser = !values.systemAccessDenaliUser
      const newValues = {
        ...values,
        systemAccessDenaliUser: newSystemAccessDenaliUser,
        sendWelcomeEmailR1: newSystemAccessDenaliUser,
      }
      if (!newSystemAccessDenaliUser) {
        newValues.groups = {
          ...values.groups,
          denaliUser: false,
        }
      }

      if (!newSystemAccessDenaliUser) {
        newValues.groups = { ...values.groups, denaliUser: false }
      }

      setValues(newValues)
    }
  }

  const renderInformationStep = () => (
    <>
      <FieldGroupStyled>
        <SectionHeadingStyled>User Information</SectionHeadingStyled>
        <FieldsRowStyled>
          <UserFieldStyled>
            <EmailFieldLabelStyled htmlFor='email'>
              Email Address
            </EmailFieldLabelStyled>
            <EmailFieldStyled
              disabled={values.userId}
              error={errors.email}
              id='email'
              inputRef={emailRef}
              onBlur={handleEmailBlur}
              onChange={handleEmailChange}
              placeholder='Email'
            />
          </UserFieldStyled>
          <UserFieldStyled>
            <EmailFieldLabelStyled htmlFor='firstName'>
              First Name
            </EmailFieldLabelStyled>
            <EmailFieldStyled
              disabled={values.userId && isWorkmarketUser}
              error={errors.firstName}
              id='firstName'
              inputRef={firstNameRef}
              onBlur={() => handleOptionalFieldsBlur('firstName')}
              onChange={(e) => handleOptionalFieldsChange(e, 'firstName')}
              placeholder='First Name'
            />
          </UserFieldStyled>
          <UserFieldStyled>
            <EmailFieldLabelStyled htmlFor='lastName'>
              Last Name
            </EmailFieldLabelStyled>
            <EmailFieldStyled
              disabled={values.userId && isWorkmarketUser}
              id='lastName'
              error={errors.lastName}
              inputRef={lastNameRef}
              onBlur={() => handleOptionalFieldsBlur('lastName')}
              onChange={(e) => handleOptionalFieldsChange(e, 'lastName')}
              placeholder='Last Name'
            />
          </UserFieldStyled>
        </FieldsRowStyled>
        <FieldsRowStyled>
          <UserFieldStyled>
            <EmailFieldLabelStyled htmlFor='source'>
              Source
            </EmailFieldLabelStyled>
            <EmailFieldStyled
              disabled
              id='source'
              inputRef={sourceRef}
              placeholder='Source'
            />
          </UserFieldStyled>
          <UserFieldStyled>
            <EmailFieldLabelStyled htmlFor='phone'>Phone</EmailFieldLabelStyled>
            <EmailFieldStyled
              disabled={values.userId && isWorkmarketUser}
              error={errors.phone}
              id='phone'
              inputRef={phoneRef}
              onBlur={() => handleOptionalFieldsBlur('phone')}
              onChange={(e) => handleOptionalFieldsChange(e, 'phone')}
              placeholder='1234567890'
            />
          </UserFieldStyled>
          <UserFieldStyled>
            <EmailFieldLabelStyled htmlFor='company'>
              Company
            </EmailFieldLabelStyled>
            <EmailFieldStyled
              disabled={values.userId && isWorkmarketUser}
              error={errors.company}
              id='company'
              inputRef={companyRef}
              onBlur={() => handleOptionalFieldsBlur('company')}
              onChange={(e) => handleOptionalFieldsChange(e, 'company')}
              placeholder='Company / Employer'
            />
          </UserFieldStyled>
        </FieldsRowStyled>
      </FieldGroupStyled>
      <SectionHeadingStyled>System Access</SectionHeadingStyled>
      <SystemAccessCheckboxFieldsStyled>
        <CheckboxFieldWithDescriptionStyled
          disabled={!isAdmin}
          onClick={handleGcSystemAccessClick}
        >
          <CustomFieldStyled
            checked={values.systemAccessGcUser}
            component={CheckboxStyled}
            disabled={!isAdmin}
            id='systemAccessGcUser'
            name='systemAccessGcUser'
            readOnly={!isAdmin}
          />
          <SystemAccessCheckboxLabelStyled
            disabled={!isAdmin}
            htmlFor='systemAccessGcUser'
          >
            Grand Central User
          </SystemAccessCheckboxLabelStyled>
          <CheckboxFieldDescriptionStyled>
            Assign Internal User Groups in Step 2
          </CheckboxFieldDescriptionStyled>
        </CheckboxFieldWithDescriptionStyled>
        <CheckboxFieldWithDescriptionStyled
          disabled={!isAdmin && !isAccountManager}
          onClick={handleDaSystemAccessClick}
        >
          <CustomFieldStyled
            checked={values.systemAccessDashboardUser}
            component={CheckboxStyled}
            disabled={!isAdmin && !isAccountManager}
            id='systemAccessDashboardUser'
            name='systemAccessDashboardUser'
            readOnly={!isAdmin && !isAccountManager}
          />
          <SystemAccessCheckboxLabelStyled
            disabled={!isAdmin && !isAccountManager}
            htmlFor='systemAccessDashboardUser'
          >
            Dashboard User
          </SystemAccessCheckboxLabelStyled>
          <CheckboxFieldDescriptionStyled>
            Assign External Customer/Site access in Step 2
          </CheckboxFieldDescriptionStyled>
        </CheckboxFieldWithDescriptionStyled>
        <CheckboxFieldWithDescriptionStyled
          disabled={!isAdmin && !isAccountManager}
          onClick={handleDenaliSystemAccessClick}
        >
          <Field
            checked={values.systemAccessDenaliUser}
            component={CheckboxStyled}
            disabled={!isAdmin && !isAccountManager}
            id='systemAccessDenaliUser'
            name='systemAccessDenaliUser'
            readOnly={!isAdmin && !isAccountManager}
          />
          <SystemAccessCheckboxLabelStyled
            disabled={!isAdmin && !isAccountManager}
            htmlFor='systemAccessDenaliUser'
          >
            Redaptive ONE User
          </SystemAccessCheckboxLabelStyled>
          <CheckboxFieldDescriptionStyled>
            Assign External Customer/Site access in Step 2
          </CheckboxFieldDescriptionStyled>
        </CheckboxFieldWithDescriptionStyled>
        {!values.userId && (
          <SecondaryCheckboxFieldWithDescriptionStyled
            disabled={!isAdmin && !isAccountManager}
            onClick={() => {
              if (isAdmin || isAccountManager) {
                const newValues = {
                  ...values,
                  sendWelcomeEmailR1: !values.sendWelcomeEmailR1,
                }
                setValues(newValues)
              }
            }}
          >
            <SecondaryCheckboxFieldDescriptionStyled>
              <CustomFieldStyled
                checked={
                  values.systemAccessDenaliUser && values.sendWelcomeEmailR1
                }
                component={CheckboxStyled}
                disabled={!values.systemAccessDenaliUser}
                name='sendWelcomeEmailR1'
                readOnly={!isAdmin && !isAccountManager}
              />
              <SystemAccessCheckboxLabelStyled
                disabled={!values.systemAccessDenaliUser}
                htmlFor='sendWelcomeEmailR1'
              >
                Send welcome email to user on create (contains one-time access
                link)
              </SystemAccessCheckboxLabelStyled>
            </SecondaryCheckboxFieldDescriptionStyled>
          </SecondaryCheckboxFieldWithDescriptionStyled>
        )}
        <CheckboxFieldWithDescriptionStyled
          disabled={!isAdmin && !isAccountManager}
          onClick={handleMaSystemAccessClick}
        >
          <CustomFieldStyled
            checked={values.systemAccessMaUser}
            component={CheckboxStyled}
            disabled={!isAdmin && !isAccountManager}
            id='systemAccessMaUser'
            name='systemAccessMaUser'
            readOnly={!isAdmin && !isAccountManager}
          />
          <SystemAccessCheckboxLabelStyled
            disabled={!isAdmin && !isAccountManager}
            htmlFor='systemAccessMaUser'
          >
            Redaptive Mobile App User
          </SystemAccessCheckboxLabelStyled>
          <CheckboxFieldDescriptionStyled>
            Assign User Groups in Step 2
          </CheckboxFieldDescriptionStyled>
        </CheckboxFieldWithDescriptionStyled>
        {!values.userId && (
          <SecondaryCheckboxFieldWithDescriptionStyled
            disabled={!isAdmin && !isAccountManager}
            onClick={() => {
              if (isAdmin || isAccountManager) {
                const newValues = {
                  ...values,
                  sendWelcomeEmail: !values.sendWelcomeEmail,
                }
                setValues(newValues)
              }
            }}
          >
            <SecondaryCheckboxFieldDescriptionStyled>
              <CustomFieldStyled
                checked={values.systemAccessMaUser && values.sendWelcomeEmail}
                component={CheckboxStyled}
                disabled={!values.systemAccessMaUser}
                name='sendWelcomeEmail'
                readOnly={!isAdmin && !isAccountManager}
              />
              <SystemAccessCheckboxLabelStyled
                disabled={!values.systemAccessMaUser}
                htmlFor='sendWelcomeEmail'
              >
                Send welcome email to user on create (contains one-time access
                link)
              </SystemAccessCheckboxLabelStyled>
            </SecondaryCheckboxFieldDescriptionStyled>
          </SecondaryCheckboxFieldWithDescriptionStyled>
        )}
      </SystemAccessCheckboxFieldsStyled>
      <ButtonsSectionStyled>
        <Button onClick={cancelAction}>Cancel</Button>
        <Button
          styleAsDisabled={!informationStepValid}
          onClick={handleInformationContinueClick}
          primary
        >
          Continue
        </Button>
      </ButtonsSectionStyled>
      {continueErrors.information && (
        <ContinueErrorStyled>
          <ReportProblemIcon />
          {continueErrors.information}
        </ContinueErrorStyled>
      )}
    </>
  )

  const handleSystemAccessCustomersBackClick = () => {
    if (values.systemAccessDenaliUser && isAdmin) {
      setSystemAccessStep('groupsDenali')
    } else if (values.systemAccessGcUser && isAdmin) {
      setSystemAccessStep('groups')
    } else {
      setStep(step - 1)
    }
  }

  const renderSystemAccessGroups = () => (
    <>
      <FieldGroupStyled>
        <GroupsFieldWrapperStyled>
          <SectionHeadingStyled>
            Update Grand Central User Group(s)
          </SectionHeadingStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.accountManagers',
                !values.groups.accountManagers,
              )
            }}
          >
            <Field
              checked={values.groups.accountManagers}
              component={CheckboxStyled}
              name='groups.accountManagers'
              id='groupsAccountManagers'
            />
            <FieldLabelStyled htmlFor='groupsAccountManagers'>
              {userGroupsMap.accountManagers.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Create, view, and update Customers, Sites, and Dashboard Users
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.operationsSupport',
                !values.groups.operationsSupport,
              )
            }}
          >
            <Field
              checked={values.groups.operationsSupport}
              component={CheckboxStyled}
              id='groupsOperationsSupport'
              name='groups.operationsSupport'
            />
            <FieldLabelStyled htmlFor='groupsOperationsSupport'>
              {userGroupsMap.operationsSupport.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>Create, view, and update Customers and Sites</li>
            <li>Update Circuits</li>
            <li>View Dashboard Users</li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.proposalOperations',
                !values.groups.proposalOperations,
              )
            }}
          >
            <Field
              checked={values.groups.proposalOperations}
              component={CheckboxStyled}
              id='groupsProposalOperations'
              name='groups.proposalOperations'
            />
            <FieldLabelStyled htmlFor='groupsProposalOperations'>
              {userGroupsMap.proposalOperations.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Create, update, and delete scenario analyses and multi-site
              analyses and validated the Lighting Audit on the Validation tool
              (Tools and Reports)
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.proposalOperationsAdmin',
                !values.groups.proposalOperationsAdmin,
              )
            }}
          >
            <Field
              checked={values.groups.proposalOperationsAdmin}
              component={CheckboxStyled}
              id='groupsProposalOperationsAdmin'
              name='groups.proposalOperationsAdmin'
            />
            <FieldLabelStyled htmlFor='groupsProposalOperationsAdmin'>
              {userGroupsMap.proposalOperationsAdmin.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Create, update, and approve scenario analyses and multi-site
              analyses
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.proposalOperationsReadonly',
                !values.groups.proposalOperationsReadonly,
              )
            }}
          >
            <Field
              checked={values.groups.proposalOperationsReadonly}
              component={CheckboxStyled}
              id='groupsProposalOperationsReadonly'
              name='groups.proposalOperationsReadonly'
            />
            <FieldLabelStyled htmlFor='groupsProposalOperationsReadonly'>
              {userGroupsMap.proposalOperationsReadonly.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>View scenario analyses and batch analyses</li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue('groups.admin', !values.groups.admin)
              setFieldValue('groups.denaliAdmin', !values.groups.admin)
            }}
          >
            <Field
              checked={values.groups.admin}
              component={CheckboxStyled}
              id='groupsAdmin'
              name='groups.admin'
            />
            <FieldLabelStyled htmlFor='groupsAdmin'>
              {userGroupsMap.admin.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Create, view, update, and delete/disable: Customers, Sites,
              Meters, Circuits, Dashboard Users
            </li>
            <li>Dashboard/Redaptive ONE access to all Customers and Sites</li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.energyStarAdmin',
                !values.groups.energyStarAdmin,
              )
            }}
          >
            <Field
              checked={values.groups.energyStarAdmin}
              component={CheckboxStyled}
              id='groupsEnergyStarAdmin'
              name='groups.energyStarAdmin'
            />
            <FieldLabelStyled htmlFor='energyStarAdmin'>
              {userGroupsMap.energyStarAdmin.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Enable/Disable Customer and Sites for submission to Energy Star
            </li>
            <li>
              Access to Energy star submission tool for making monthly
              submissions.
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.billingReadOnly',
                !values.groups.billingReadOnly,
              )
            }}
          >
            <Field
              checked={values.groups.billingReadOnly}
              component={CheckboxStyled}
              id='groupsBillingReadOnly'
              name='groups.billingReadOnly'
            />
            <FieldLabelStyled htmlFor='groupsBillingReadOnly'>
              {userGroupsMap.billingReadOnly.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View and download data from all tabs in monthly billing workflow
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.billingIssueInvestigator',
                !values.groups.billingIssueInvestigator,
              )
            }}
          >
            <Field
              checked={values.groups.billingIssueInvestigator}
              component={CheckboxStyled}
              id='groupsBillingIssueInvestigator'
              name='groups.billingIssueInvestigator'
            />
            <FieldLabelStyled htmlFor='groupsBillingIssueInvestigator'>
              {userGroupsMap.billingIssueInvestigator.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View and download data from all tabs in monthly billing workflow
            </li>
            <li>
              Able to add Comments, Re-run Savings, Flag for Investigation, and
              Send to Verification
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.billingPerformanceReviewer',
                !values.groups.billingPerformanceReviewer,
              )
            }}
          >
            <Field
              checked={values.groups.billingPerformanceReviewer}
              component={CheckboxStyled}
              id='groupsBillingPerformanceReviewer'
              name='groups.billingPerformanceReviewer'
            />
            <FieldLabelStyled htmlFor='groupsBillingPerformanceReviewer'>
              {userGroupsMap.billingPerformanceReviewer.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View and download data from all tabs in monthly billing workflow
            </li>
            <li>
              Able to add Comments, Re-run Savings, Flag for Investigation, Send
              to Verification, and make Billing Decisions
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.billingInvoiceApprover',
                !values.groups.billingInvoiceApprover,
              )
            }}
          >
            <Field
              checked={values.groups.billingInvoiceApprover}
              component={CheckboxStyled}
              id='groupsBillingInvoiceApprover'
              name='groups.billingInvoiceApprover'
            />
            <FieldLabelStyled htmlFor='groupsBillingInvoiceApprover'>
              {userGroupsMap.billingInvoiceApprover.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View and download data from all tabs in monthly billing workflow
            </li>
            <li>
              Able to add Comments, Re-run Savings, Flag for Investigation, Send
              to Verification, make Billing Decisions, and Send to Netsuite
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.isrProcessorTool',
                !values.groups.isrProcessorTool,
              )
            }}
          >
            <Field
              checked={values.groups.isrProcessorTool}
              component={CheckboxStyled}
              name={userGroupsMap.isrProcessorTool.apiName}
            />
            <FieldLabelStyled htmlFor={userGroupsMap.isrProcessorTool.apiName}>
              {userGroupsMap.isrProcessorTool.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Access to Lighting ISR Uploader tool to be able to upload, update,
              and download ISR files for billing
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
      </FieldGroupStyled>
      <FieldGroupStyled>
        <SectionHeadingStyled>
          Additional Grand Central Features
        </SectionHeadingStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.hvacAssetsUploader',
                !values.groups.hvacAssetsUploader,
              )
            }}
          >
            <Field
              checked={values.groups.hvacAssetsUploader}
              component={CheckboxStyled}
              id='groupsHvacAssetsUploader'
              name='groups.hvacAssetsUploader'
            />
            <FieldLabelStyled htmlFor='groupsHvacAssetsUploader'>
              {userGroupsMap.hvacAssetsUploader.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Create, view, and update Customers, Sites, and Dashboard Users
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.ltgAsBuiltUploader',
                !values.groups.ltgAsBuiltUploader,
              )
            }}
          >
            <Field
              checked={values.groups.ltgAsBuiltUploader}
              component={CheckboxStyled}
              id='groupsLtgAsBuiltUploader'
              name='groups.ltgAsBuiltUploader'
            />
            <FieldLabelStyled htmlFor='groupsLtgAsBuiltUploader'>
              {userGroupsMap.ltgAsBuiltUploader.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>Access to Lighting As-Builts tool</li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>

        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue('groups.billing', !values.groups.billing)
            }}
          >
            <Field
              checked={values.groups.billing}
              component={CheckboxStyled}
              id='groupsBilling'
              name='groups.billing'
            />
            <FieldLabelStyled htmlFor='groupsBilling'>
              {userGroupsMap.billing.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              For API users to submit Blessed Bill and edit billable circuits
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.manufacturing',
                !values.groups.manufacturing,
              )
            }}
          >
            <Field
              checked={values.groups.manufacturing}
              component={CheckboxStyled}
              id='groupsManufacturing'
              name='groups.manufacturing'
            />
            <FieldLabelStyled htmlFor='groupsManufacturing'>
              {userGroupsMap.manufacturing.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>Access to Real-time Meter Status tool</li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
      </FieldGroupStyled>
      <ButtonsSectionStyled>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button
          styleAsDisabled={!systemAccessGroupsValid}
          onClick={handleGroupsContinueClick}
          primary
        >
          Continue
        </Button>
      </ButtonsSectionStyled>
      {continueErrors.groups && (
        <ContinueErrorStyled>
          <ReportProblemIcon />
          {continueErrors.groups}
        </ContinueErrorStyled>
      )}
    </>
  )

  const renderSystemAccessGroupsDenali = () => (
    <>
      <FieldGroupStyled>
        <GroupsFieldWrapperStyled>
          <SectionHeadingStyled>
            Assign Redaptive ONE User Group(s)
          </SectionHeadingStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue('groups.denaliAdmin', !values.groups.denaliAdmin)
            }}
          >
            <Field
              checked={values.groups.denaliAdmin}
              component={CheckboxStyled}
              name='groups.denaliAdmin'
            />
            <FieldLabelStyled htmlFor='groups.denaliAdmin'>
              {userGroupsMap.denaliAdmin.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>View all Customers and Sites in Redaptive ONE app</li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled>
            <Field
              checked={values.systemAccessDenaliUser}
              component={CheckboxStyled}
              readOnly
              name='groups.denaliUser'
            />
            <FieldLabelStyled htmlFor='groups.denaliUser'>
              {userGroupsMap.denaliUser.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View specific Customers and Sites in Redaptive ONE app (specify on
              next screen)
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.performanceAndInvoicesUser',
                !values.groups.performanceAndInvoicesUser,
              )
            }}
          >
            <Field
              checked={values.groups.performanceAndInvoicesUser}
              component={CheckboxStyled}
              id='groupsPerformanceAndInvoicesUser'
              name={userGroupsMap.performanceAndInvoicesUser.apiName}
            />
            <FieldLabelStyled htmlFor='groupsPerformanceAndInvoicesUser'>
              {userGroupsMap.performanceAndInvoicesUser.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View the Project Performance and Invoices features for all
              customers and sites the user has access to
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.energyStarCoordinator',
                !values.groups.energyStarCoordinator,
              )
            }}
          >
            <Field
              checked={values.groups.energyStarCoordinator}
              component={CheckboxStyled}
              name={userGroupsMap.energyStarCoordinator.apiName}
            />
            <FieldLabelStyled
              htmlFor={userGroupsMap.energyStarCoordinator.apiName}
            >
              {userGroupsMap.energyStarCoordinator.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>Connect Redaptive ONE account with ESPM account</li>
            <li>Enable Properties for monthly submissions to ESPM</li>
            <li>
              <b>
                NOTE - This role requires access to all the sites of selected
                customer. Any existing access to limited sites will be reset to
                NO ACCESS when you press Continue.
              </b>
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.meterInstallTrackerAccess',
                !values.groups.meterInstallTrackerAccess,
              )
            }}
          >
            <Field
              checked={values.groups.meterInstallTrackerAccess}
              component={CheckboxStyled}
              name={userGroupsMap.meterInstallTrackerAccess.apiName}
            />
            <FieldLabelStyled
              htmlFor={userGroupsMap.meterInstallTrackerAccess.apiName}
            >
              {userGroupsMap.meterInstallTrackerAccess.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              View the Installation progress and details for all active metering
              projects
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
      </FieldGroupStyled>
      <ButtonsSectionStyled>
        <Button onClick={handleGroupsDenaliBackClick}>Back</Button>
        <Button
          styleAsDisabled={!systemAccessGroupsDenaliValid}
          onClick={handleGroupsDenaliContinueClick}
          primary
        >
          Continue
        </Button>
      </ButtonsSectionStyled>
      {continueErrors.groups && (
        <ContinueErrorStyled>
          <ReportProblemIcon />
          {continueErrors.groups}
        </ContinueErrorStyled>
      )}
    </>
  )

  const renderSystemAccessMaGroups = () => (
    <>
      <FieldGroupStyled>
        <GroupsFieldWrapperStyled>
          <SectionHeadingStyled>
            Redaptive Mobile App User Group(s)
          </SectionHeadingStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.mobileAppMeterInstaller',
                !values.groups.mobileAppMeterInstaller,
              )
            }}
          >
            <Field
              checked={values.groups.mobileAppMeterInstaller}
              component={CheckboxStyled}
              name='groups.mobileAppMeterInstaller'
            />
            <FieldLabelStyled htmlFor='groups.mobileAppMeterInstaller'>
              {userGroupsMap.mobileAppMeterInstaller.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Access to meter install app, permission to configure meters and
              submit install records
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
        <GroupsFieldWrapperStyled>
          <GroupsFieldWithLabelStyled
            onClick={() => {
              setFieldValue(
                'groups.mobileAppAdministrator',
                !values.groups.mobileAppAdministrator,
              )
            }}
          >
            <Field
              checked={values.groups.mobileAppAdministrator}
              component={CheckboxStyled}
              name='groups.mobileAppAdministrator'
            />
            <FieldLabelStyled htmlFor='groups.mobileAppAdministrator'>
              {userGroupsMap.mobileAppAdministrator.displayName}
            </FieldLabelStyled>
          </GroupsFieldWithLabelStyled>
          <GroupsFieldDescriptionStyled>
            <li>
              Create and manage meter app users; access meter app, configure
              meters, submit install records
            </li>
          </GroupsFieldDescriptionStyled>
        </GroupsFieldWrapperStyled>
      </FieldGroupStyled>
      <ButtonsSectionStyled>
        <Button onClick={handleMaGroupsBackClick}>Back</Button>
        <Button
          styleAsDisabled={!systemAccessMaGroupsValid}
          onClick={handleMaGroupsContinueClick}
          primary
        >
          Continue
        </Button>
      </ButtonsSectionStyled>
      {continueErrors.groups && (
        <ContinueErrorStyled>
          <ReportProblemIcon />
          {continueErrors.groups}
        </ContinueErrorStyled>
      )}
    </>
  )

  const customerNoAccessCell = ({ original: { id } }: FTCustomerFieldCell) => (
    <RadioButton
      checked={!values.customers[id]}
      onChange={({ target: { checked } }: SyntheticEvent) => {
        if (checked) {
          setValues({
            ...values,
            customers: Object.keys(values.customers).reduce(
              (acc, cur) =>
                cur === id ? acc : { ...acc, [cur]: values.customers[cur] },
              {},
            ),
          })
        }
      }}
    />
  )

  const customerSelectSitesCell = ({
    original: { id },
  }: FTCustomerFieldCell) => {
    const checked = values.customers[id] === 'selectSites'
    return (
      <RadioButton
        checked={checked}
        disabled={values.groups.energyStarCoordinator}
        onChange={({ target: { checked: checkedTarget } }: SyntheticEvent) => {
          if (checkedTarget) {
            setValues({
              ...values,
              customers: {
                ...Object.keys(values.customers).reduce(
                  (acc, cur) => ({ ...acc, [cur]: values.customers[cur] }),
                  {},
                ),
                [id]: 'selectSites',
              },
            })
          }
        }}
      />
    )
  }

  const customerAllSitesCell = ({ original: { id } }: FTCustomerFieldCell) => {
    const checked = values.customers[id] === 'allSites'
    const customerMatch = id === customerId
    return (
      <RadioButton
        checked={checked}
        onChange={({ target: { checked: checkedTarget } }: SyntheticEvent) => {
          if (checkedTarget) {
            setValues({
              ...values,
              customers: { ...values.customers, [id]: 'allSites' },
            })
          }
        }}
        id={checked && customerMatch ? 'matchedCustomerAllSitesRadio' : null}
      />
    )
  }

  const onNoAccessSelectAllClick = () => {
    setValues({ ...values, customers: {} })
  }

  const onAllSitesSelectAllClick = () => {
    setValues({
      ...values,
      customers: customerIds.reduce(
        (acc, cur) => ({ ...acc, [cur]: 'allSites' }),
        {},
      ),
    })
  }

  const selectCustomersColumns = [
    {
      accessor: 'name',
      Header: 'Customer',
      minWidth: 300,
    },
    {
      Cell: customerNoAccessCell,
      id: 'noAccess',
      Header: (
        <span>
          {'No Access '}
          <ButtonLink type='button' onClick={onNoAccessSelectAllClick}>
            (Select All)
          </ButtonLink>
        </span>
      ),
      minWidth: 100,
    },
    {
      Cell: customerSelectSitesCell,
      id: 'selectSites',
      Header: 'Select Site(s)',
      minWidth: 100,
    },
    {
      Cell: customerAllSitesCell,
      id: 'allSites',
      Header: (
        <span>
          {'All Current and Future Sites '}
          <ButtonLink type='button' onClick={onAllSitesSelectAllClick}>
            (Select All)
          </ButtonLink>
        </span>
      ),
      minWidth: 180,
    },
  ]

  const renderSystemAccessSelectCustomers = () => (
    <>
      <SectionHeadingStyled>
        Update Customer and Site Dashboard/Redaptive ONE Access
      </SectionHeadingStyled>
      <CustomerTableStyled ref={customerTableStyledRef}>
        <RedaptiveReactTable
          data={customers}
          columns={selectCustomersColumns}
          filterable={false}
          resizable={false}
          sortable={false}
        />
      </CustomerTableStyled>
      <ButtonsSectionStyled>
        {canAccessInformationStep && (
          <Button onClick={handleSystemAccessCustomersBackClick}>Back</Button>
        )}
        {!canAccessInformationStep && (
          <Button onClick={cancelAction}>Cancel</Button>
        )}
        <Button
          styleAsDisabled={!systemAccessCustomersSelectValid}
          onClick={handleCustomersContinueClick}
          primary
        >
          Continue
        </Button>
      </ButtonsSectionStyled>
      {continueErrors.customers && (
        <ContinueErrorStyled>
          <ReportProblemIcon />
          {continueErrors.customers}
        </ContinueErrorStyled>
      )}
    </>
  )

  const customerSelectionItems = (id) =>
    sites
      .filter((site) => site.customerId === id)
      .map((site) => ({
        id: site.id,
        name: site.validName,
      }))
      .sort(naturallySortItems)

  const customerName = (id) =>
    customers.find((customer) => customer.id === id).validName || ''

  const renderSystemAccessSelectSites = () => (
    <SystemAccessSelectSitesStyled>
      <SectionHeadingStyled>
        Select Dashboard/Redaptive ONE Site Access
      </SectionHeadingStyled>
      {!sites.length && (
        <>
          <p>This customer has no active sites.</p>
          <ButtonsSectionStyled>
            <Button onClick={() => setSystemAccessStep('customersAccess')}>
              Back
            </Button>
          </ButtonsSectionStyled>
        </>
      )}
      {!!sites.length && (
        <>
          <SelectSitesWrapperStyled>
            {selectedCustomers.map((id) => (
              <SelectSitesListWrapperStyled key={id}>
                <SelectSitesFieldLabelWrapperStyled>
                  <FieldLabelStyled>{customerName(id)}</FieldLabelStyled>
                </SelectSitesFieldLabelWrapperStyled>
                <SelectSitesListSelectorStyled>
                  <ListSelector
                    key={id}
                    items={customerSelectionItems(id)}
                    notSetLabelText='Select...'
                    notSetItemText='--'
                    isMulti
                    updateValueMulti={(newValues) => {
                      const newSelectedSite = newValues.map(
                        (newValue) => newValue.value,
                      )
                      setSelectedSitesByCustomer({
                        ...sitesIdByCustomer,
                        ...selectedSitesByCustomer,
                        [id]: [...new Set([...newSelectedSite])],
                      })
                    }}
                    selectedItems={customerSelectionItems(id).filter((item) =>
                      item && values.sites ?
                        values.sites.includes(item.id)
                      : true,
                    )}
                    unsettable={false}
                  />
                </SelectSitesListSelectorStyled>
              </SelectSitesListWrapperStyled>
            ))}
          </SelectSitesWrapperStyled>
          <ButtonsSectionStyled>
            <Button onClick={() => setSystemAccessStep('customersAccess')}>
              Back
            </Button>
            <Button
              disabled={!systemAccessSitesSelectValid}
              onClick={handleSitesContinueClick}
              primary
            >
              Continue
            </Button>
          </ButtonsSectionStyled>
        </>
      )}
    </SystemAccessSelectSitesStyled>
  )

  const renderSystemAccessStep = () => {
    switch (systemAccessStep) {
      case 'groups':
        return renderSystemAccessGroups()

      case 'groupsDenali':
        return renderSystemAccessGroupsDenali()

      case 'maGroups':
        return renderSystemAccessMaGroups()

      case 'customersAccess':
        return renderSystemAccessSelectCustomers()

      case 'sitesAccess':
        return renderSystemAccessSelectSites()

      default:
        return ''
    }
  }

  const handleConfirmStepGoBack = () => {
    if (
      (values.systemAccessGcUser && isAdmin) ||
      values.systemAccessMaUser ||
      values.systemAccessDashboardUser ||
      values.systemAccessDenaliUser
    ) {
      setStep(step - 1)
    } else {
      setStep(1)
    }
  }

  const confirmStepDashboardDenaliLabel = useMemo(() => {
    const labels = []
    const denaliOrDashboardAccess = !!(
      values.systemAccessDashboardUser || values.systemAccessDenaliUser
    )

    if (values.systemAccessDashboardUser || !denaliOrDashboardAccess) {
      labels.push('Dashboard')
    }

    if (values.systemAccessDenaliUser || !denaliOrDashboardAccess) {
      labels.push('Redaptive ONE')
    }

    return labels.join('/')
  }, [values.systemAccessDenaliUser, values.systemAccessDashboardUser])

  const renderConfirmStep = () => (
    <>
      <SectionHeadingStyled>Confirm User Details & Access</SectionHeadingStyled>
      <ConfirmTopSectionStyled>
        <FieldLabelStyled>Email Address</FieldLabelStyled>
        <div>{values.email}</div>
      </ConfirmTopSectionStyled>
      {!values.userId && (
        <>
          <ConfirmTopSectionStyled>
            <FieldLabelStyled>
              Send Mobile App Welcome Email on Create
            </FieldLabelStyled>
            <div>{values.sendWelcomeEmail ? 'Yes' : 'No'}</div>
          </ConfirmTopSectionStyled>
          <ConfirmTopSectionStyled>
            <FieldLabelStyled>
              Send Redaptive One Welcome Email on Create
            </FieldLabelStyled>
            <div>{values.sendWelcomeEmailR1 ? 'Yes' : 'No'}</div>
          </ConfirmTopSectionStyled>
        </>
      )}
      <ConfirmBottomSectionStyled>
        <ConfirmUserGroupsStyled>
          <FieldLabelStyled>Grand Central User Group(s)</FieldLabelStyled>
          {!values.systemAccessGcUser && <div>None</div>}
          {!!values.systemAccessGcUser && (
            <ConfirmListStyled>
              {selectedGroups
                .filter((group) => group !== 'denaliUser')
                .filter(
                  (group) => !Object.keys(denaliUserGroupsMap).includes(group),
                )
                .filter(
                  (group) => !Object.keys(maUserGroupsMap).includes(group),
                )
                .map((group) => (
                  <li key={group}>{userGroupsMap[group].displayName}</li>
                ))}
            </ConfirmListStyled>
          )}
        </ConfirmUserGroupsStyled>
        <ConfirmUserGroupsStyled>
          <FieldLabelStyled>{`${confirmStepDashboardDenaliLabel} Access`}</FieldLabelStyled>
          {!values.systemAccessDashboardUser &&
            !values.systemAccessDenaliUser && <div>None</div>}
          {!!(
            (values.systemAccessDashboardUser ||
              values.systemAccessDenaliUser) &&
            (values.groups.admin || values.groups.denaliAdmin)
          ) && (
            <ConfirmListStyled>
              <li>All Current and Future Sites</li>
            </ConfirmListStyled>
          )}
          {!!(
            values.systemAccessDashboardUser || values.systemAccessDenaliUser
          ) &&
            !(values.groups.admin || values.groups.denaliAdmin) && (
              <ConfirmUserDashboardAccessTableStyled>
                <tbody>
                  {hasSiteCustomerId &&
                    selectedCustomers.map((id) =>
                      selectedSitesByCustomerSorted[id].length ?
                        selectedSitesByCustomerSorted[id].map((siteId) => (
                          <tr key={id}>
                            <td>
                              <ConfirmListStyled>
                                <li>{customersById[id].validName}</li>
                              </ConfirmListStyled>
                            </td>
                            <td key={siteId}>{sitesById[siteId].validName}</td>
                          </tr>
                        ))
                      : <></>,
                    )}
                  {hasAllSites &&
                    Object.keys(values.customers)
                      .filter((key) => values.customers[key] === 'allSites')
                      .map((id) => (
                        <tr key={id}>
                          <td>
                            <ConfirmListStyled>
                              <li>{customersById[id].validName}</li>
                            </ConfirmListStyled>
                          </td>
                          <td>All Current and Future Sites</td>
                        </tr>
                      ))}
                </tbody>
              </ConfirmUserDashboardAccessTableStyled>
            )}
        </ConfirmUserGroupsStyled>
        <ConfirmUserGroupsStyled>
          <FieldLabelStyled>
            Redaptive Mobile App User Group(s)
          </FieldLabelStyled>
          {!values.systemAccessMaUser && <div>None</div>}
          {!!values.systemAccessMaUser && (
            <ConfirmListStyled>
              {selectedMaGroups.map((group) => (
                <li key={group}>{userGroupsMap[group].displayName}</li>
              ))}
            </ConfirmListStyled>
          )}
        </ConfirmUserGroupsStyled>
      </ConfirmBottomSectionStyled>
      <ButtonsSectionStyled>
        <Button onClick={handleConfirmStepGoBack}>Back</Button>
        <Button
          onClick={() => {
            setSubmitted(true)
            submitAction(values)
          }}
          loading={createLoading || updateLoading}
          primary
        >
          {values.userId ? 'Save' : 'Create'}
        </Button>
      </ButtonsSectionStyled>
    </>
  )

  const renderOneTimeLink = () => {
    const { activationOrResetPasswordToken = '' } = newUser || {}
    return activationOrResetPasswordToken ?
        <OneTimeLinkWrapper>
          <OneTimeLink
            email={values.email}
            resetId={activationOrResetPasswordToken}
            isSignup
          />
        </OneTimeLinkWrapper>
      : ''
  }

  const renderResultsStep = () => {
    const basePageTitle =
      (customerIdUrlParam && 'customer') || (values.userId ? 'user' : 'users')
    const isMaUser =
      newUser ?
        newUser.groups.some((group) => maUserRolesApiNames.includes(group))
      : false
    const isR1User =
      newUser ?
        newUser.groups.some((group) => r1UserRolesApiNames.includes(group))
      : false
    const isMaOnlyUser =
      newUser ?
        newUser.groups.every((group) => maUserRolesApiNames.includes(group))
      : false
    if (userError) {
      return (
        <>
          <ContinueErrorStyled>
            <ReportProblemIcon />
            {userError}
          </ContinueErrorStyled>
          <ButtonsSectionStyled>
            <Button
              onClick={() => {
                setSubmitted(false)
                setFinalStep('confirm')
              }}
            >
              Back
            </Button>
          </ButtonsSectionStyled>
        </>
      )
    }

    return (
      <div>
        <ResultsStatusStyled>
          <ResultsStatusInnerStyled>
            <SuccessArrowIcon />
            <span>
              {'User '}
              <b>{values.email}</b>
              {values.userId ?
                <>{' has been edited successfully.'}</>
              : <>{' has been created successfully.'}</>}
            </span>
          </ResultsStatusInnerStyled>
          {isMaUser && values.sendWelcomeEmail && (
            <ResultsStatusInnerStyled>
              <SuccessArrowIcon />
              <span>
                {'Redaptive mobile app email invite sent to user '}
                <b>{values.email}</b>
              </span>
            </ResultsStatusInnerStyled>
          )}
          {isR1User && values.sendWelcomeEmailR1 && (
            <ResultsStatusInnerStyled>
              <SuccessArrowIcon />
              <span>
                {'Redaptive One email invite sent to user '}
                <b>{values.email}</b>
              </span>
            </ResultsStatusInnerStyled>
          )}
        </ResultsStatusStyled>
        {!isMaOnlyUser && renderOneTimeLink()}
        <ButtonsSectionStyled>
          <Button onClick={cancelAction}>
            {`Back to ${basePageTitle} page`}
          </Button>
          {!values.userId && (
            <Button
              onClick={() => {
                resetForm()
                navigateToCreateUser()
              }}
            >
              Add another
            </Button>
          )}
        </ButtonsSectionStyled>
      </div>
    )
  }

  const renderFinalStep = () => {
    switch (finalStep) {
      case 'confirm':
        return renderConfirmStep()

      case 'results':
      default:
        return renderResultsStep()
    }
  }

  return (
    <FormStyles>
      <Stepper steps={steps} currentStep={step} setStep={setStep} />
      {step === steps.length - 2 && renderInformationStep()}
      {step === steps.length - 1 && renderSystemAccessStep()}
      {step === steps.length && renderFinalStep()}
    </FormStyles>
  )
}

export default UserForm
