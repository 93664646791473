import Tippy from '@tippyjs/react'
import styled from 'styled-components'

import { colors } from '../../../../../utils/themes'

const PlusSignStyled = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${colors.blue5};
  color: ${colors.white};
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`

const RenderMeterListCell = ({ list }: { list: string[] }) => {
  const filteredList = list.filter(
    (item) => item !== null && item !== undefined,
  )
  if (filteredList.length === 0) {
    return <>-</>
  }
  if (filteredList.length === 1) {
    return <span>{list[0]}</span>
  }
  return (
    <>
      {list[0]}
      <Tippy
        content={filteredList.filter((_, i) => i !== 0).join(', ')}
        delay={500}
        interactive
      >
        <PlusSignStyled>+{filteredList.length - 1}</PlusSignStyled>
      </Tippy>
    </>
  )
}

export default RenderMeterListCell
