import styled from 'styled-components'

const WarningMessage = styled.div`
  color: #c70d08;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  user-select: none;
`
export default WarningMessage
