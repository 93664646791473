import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { actions as messageActions } from '../../ducks/messageFlasher'
import { selectMeterEntity } from '../../ducks/meters'
import type { FTMeter } from '../../ducks/meters'

type FTProps = {
  meterEntity: {
    item: FTMeter
  }
  actions: Record<string, any>
}
type FTState = {
  flashMessageId: string
}

function withNotice(WrappedComponent) {
  return class extends Component<FTProps, FTState> {
    constructor(props) {
      super(props)
      this.state = {
        flashMessageId: '',
      }
    }

    componentDidUpdate({ meterEntity: { item: prevMeter } }) {
      const {
        meterEntity: { item: meter },
      } = this.props

      if (!prevMeter && meter && meter.id) {
        this.onMeterLoad()
      }
    }

    componentWillUnmount() {
      const { actions } = this.props
      const { flashMessageId } = this.state

      if (flashMessageId) {
        actions.hideMessage(flashMessageId)
      }
    }

    onMeterLoad = () => {
      const {
        actions,
        meterEntity: { item: meter },
      } = this.props
      const { configNotice } = meter
      actions.hideMessages()

      if (configNotice) {
        const flashMessageId = Date.now().toString()
        this.setState(
          {
            flashMessageId,
          },
          () => {
            actions.showMessage({
              ...configNotice,
              enableTimeout: false,
              messageId: flashMessageId,
            })
          },
        )
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(messageActions, dispatch) },
})

const mapStateToProps = (state, props) => ({
  meterEntity: selectMeterEntity(state, props.match.params.meterId),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNotice)
