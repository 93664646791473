import styled from 'styled-components'

export const Wrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(72, 93, 160, 0.08),
      rgba(72, 93, 160, 0.08)
    ),
    #ffffff;
  border: 1px solid #485da0;
  border-radius: 16px;
  padding: 24px;
  ${({ height }) =>
    height &&
    `
          height: ${height};
     `}
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ styles }) => styles};
  ${({ spaceBetween }) =>
    spaceBetween &&
    `
          justify-content:space-between;
     `}
`
export const Row = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: row;
  height: 100%;
`
export const Label = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #6c6d6e;
  margin-bottom: 8px;
`
export const Value = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #485da0;
`
export const ValueSmall = styled.span`
  font-size: 12px;
`
