import { Link } from 'react-router-dom'
import styled from 'styled-components'

import type { FTLink } from '../types'
import '../types'

export const BreadcrumbsStyles = styled.div`
  color: #337ab7;
  padding-bottom: 20px;
  height: 19px;

  a:hover {
    text-decoration: underline;
  }
`
const SeparaterStyled = styled.span`
  margin: 0 5px;
`
export type FTBreadcrumbs = Array<FTLink>
type FTProps = {
  items: FTBreadcrumbs
}
export default (props: FTProps) => {
  const { items } = props
  return (
    <BreadcrumbsStyles>
      {items.map(({ text, href }, i) => (
        <span key={href}>
          <Link to={href} href={href}>
            {text}
          </Link>
          {i !== items.length - 1 && <SeparaterStyled>/</SeparaterStyled>}
        </span>
      ))}
    </BreadcrumbsStyles>
  )
}
