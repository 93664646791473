import { PureComponent } from 'react'

import type { FTWithFormik } from '../../types'
import FormField from '../FormField'
import FormSection from '../FormSection'
import Input from '../Input'

type FTProps = {
  optionalSubtitle: string
} & FTWithFormik
export default class ExternalIntegrationsSection extends PureComponent<FTProps> {
  render() {
    const { optionalSubtitle, setFieldValue, values, initialValues } =
      this.props
    const { hydropointSiteId: value } = values
    const { hydropointSiteId: initialValue } = initialValues
    const formSectionTitle = 'External Integrations'
    const formSectionDescription =
      'The following fields are optional, however will be used to provide mapping between Redaptive entities and external systems.'
    return (
      <FormSection
        title={formSectionTitle}
        description={formSectionDescription}
      >
        <FormField
          title='Hydropoint Site ID'
          subtitle={optionalSubtitle}
          renderField={() => (
            <Input
              alwaysDraw
              editDotLeft='-12px'
              type='text'
              hideEditDotOnHover={false}
              key='hydropointSiteId'
              name='hydropointSiteId'
              edited={initialValue !== value}
              value={value}
              placeholder='--'
              onChange={(e) =>
                setFieldValue('hydropointSiteId', e.target.value || '')
              }
            />
          )}
        />
      </FormSection>
    )
  }
}
