import Checkbox from 'rc-checkbox'
import styled from 'styled-components'
import 'rc-checkbox/assets/index.css'

const StyledRadioButton = styled(Checkbox)`
  & .rc-checkbox-inner {
    height: 20px;
    width: 20px;
    border: 1px solid #dadce0;
    border-radius: 100px;
  }

  & input[type='checkbox'] {
    margin: 0;
    height: 18px;
    width: 18px;
  }

  &.rc-checkbox-checked:hover .rc-checkbox-inner,
  &.rc-checkbox:hover .rc-checkbox-inner {
    border-color: #162447;
  }

  &.rc-checkbox-checked .rc-checkbox-inner {
    border: 5px solid #162447;
    background-color: white;
  }

  & .rc-checkbox-inner:after {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-name: none !important;
  }

  &.rc-checkbox-checked .rc-checkbox-inner:after {
    border: 0px;
    width: 0px;
    height: 0px;
    top: 0;
  }
`
export default StyledRadioButton
