export default {
  id: '15caf3fd-3690-4484-b419-384f5660f8b8',
  source: 'REDAPTIVE',
  created: '2020-12-10',
  name: '0035FF9A3D3D',
  active: true,
  customerId: '6c0520ca-9b2d-47b4-a372-5b1266c5a5ff',
  customerName: 'Albertsons',
  siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
  siteName: 'Star Market #2569 - 1070 Lexington St - Waltham - MA',
  siteTimezone: 'America/New_York',
  hardwareId: '0035FF9A3D3D',
  model: 'RPM-C-4806',
  generation: 'OR',
  panelNames: ['CL SEC 1&2'],
  firstReportDate: '2020-12-10T16:06:30.000Z',
  lastReportDate: '2022-01-25T21:24:12.000Z',
  lastMeasurementDate: '2022-01-25T21:23:04.000Z',
  powerSource: {
    vtapL1PhaseA: '53',
    vtapL2PhaseB: '49',
    vtapL3PhaseC: '51',
    panel: {
      id: '74f3c71b-0344-44d0-9a31-16e3b21b380f',
      name: 'CL SEC 1&2',
      siteId: '2b91a38d-265e-45cc-ab4b-0e2613f5b8a9',
      type: 'THREE_PHASE_3_WIRE_WYE',
      voltage: '_600V',
    },
  },
  totalChannels: 48,
  labeledChannels: 47,
  meterOnline: false,
  mode: 'NORMAL',
  currentConfig: {
    id: '9855c498-6799-478f-b96e-7c77e2eec56f',
    version: '0001',
    dataResolutionInSeconds: 60,
    createdTs: '2021-10-22T06:54:01.000Z',
    appliedTs: '2021-10-22T06:54:46.000Z',
    voltageScalingFactor: 1.0,
    voltageScalingFactors: null,
    circuitConfigurations: [
      {
        circuitId: '578d1acf-cdc5-4980-8b49-dd8a7f950f6b',
        meterChannel: 'A1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '86c47e20-c8d8-4da4-bd46-951dfa6d9e56',
        meterChannel: 'A2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '37738f01-b245-4dac-b9b5-03dfd0123bb1',
        meterChannel: 'A3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '9ce04a1c-1710-4164-8afc-89887f1360c8',
        meterChannel: 'A4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'c7cd87e1-2a62-4388-a0b1-bd4090be6cdf',
        meterChannel: 'B1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'ccb12f10-a0f6-417e-ab2c-4a158cab2b97',
        meterChannel: 'B2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '20b5ec21-4fe3-4e8c-954c-a8b944528988',
        meterChannel: 'B3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'f75cdff4-61e8-4f1a-8f45-487442d44b6c',
        meterChannel: 'B4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '3dfce3dc-5a59-41d1-b089-4143e74d2875',
        meterChannel: 'C1',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '665ddd1b-1411-49cb-b3ad-2cdec09dbfac',
        meterChannel: 'C2',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'bd9ba1d3-b0a7-4f76-a8f3-2e6ddf40e94c',
        meterChannel: 'C3',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'bde0ce95-4a9a-44ac-baba-3645ecfde386',
        meterChannel: 'C4',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '22b12d48-496f-4f63-a677-6c7031291af5',
        meterChannel: 'D1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '9526d4b4-38c3-4925-947d-45745c3f31f9',
        meterChannel: 'D2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '7faf7ee6-8402-4bf5-8177-67029282a16d',
        meterChannel: 'D3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: 'f1902c1a-33c1-4596-bd27-3035df170e36',
        meterChannel: 'D4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '1bf5fd05-3a05-4224-aba4-86071dc61d70',
        meterChannel: 'E1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '3af8fb59-a153-4412-a7ac-9cce29ef2db3',
        meterChannel: 'E2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '825ece16-eb84-44c5-bdf2-fcfcc5ed5cdb',
        meterChannel: 'E3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'f9596f09-6537-4eda-b875-d5890db85287',
        meterChannel: 'E4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '0d074544-87b6-4001-9e03-bcfc4c24675a',
        meterChannel: 'F1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '3bf0cffb-5ae8-4e77-b026-9a0a6cf192c8',
        meterChannel: 'F2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'be5d8bba-2a2a-475c-aa3f-c24e05ea4b69',
        meterChannel: 'F3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '20f708ec-5bef-4588-8ff2-98ee1cb998d3',
        meterChannel: 'F4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'f07ae325-c06a-44c0-91d3-487bbd90ff25',
        meterChannel: 'G1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '55c0e669-a691-4d80-bd80-b4b4f2d6e5c3',
        meterChannel: 'G2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '3626ed6a-45c7-4fe7-bef5-a498c52a3598',
        meterChannel: 'G3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'c02125a0-1029-41ac-a3bf-96880669e5ef',
        meterChannel: 'G4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '5e3ff7d0-253b-4092-866e-9ea68bc942c9',
        meterChannel: 'H1',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '0d6488d5-672a-463b-adfa-d2f7264a1ba8',
        meterChannel: 'H2',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '9ae079ed-4f40-4e05-8a6a-45c548a3f61f',
        meterChannel: 'H3',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'fc5bbb6c-deed-413c-86db-35b1a6239f07',
        meterChannel: 'H4',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '567347d0-287f-44b9-80a4-5c4003327e91',
        meterChannel: 'I1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'aff0170b-182f-40c6-bf5d-e94b32e7ca5c',
        meterChannel: 'I2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '6b001bd0-1588-4d3c-831d-ac61ee62bbbf',
        meterChannel: 'I3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '99d98978-9c76-4c76-9247-ca18c024e20f',
        meterChannel: 'I4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '5cc97f5e-8264-411c-a0c5-0eda5755a53f',
        meterChannel: 'J1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '75242e57-b4e3-491c-a856-40f9aa52c992',
        meterChannel: 'J2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '10c99fdb-ba55-4d27-b6bf-1dabaae8b40c',
        meterChannel: 'J3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '134ad8fa-0707-4d73-b6d7-a9904945f8f9',
        meterChannel: 'J4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'f18c2669-4e96-497d-af8b-8e766ebe07c0',
        meterChannel: 'K1',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: 'a276cd80-7b77-47b5-b756-0aaa0e96543e',
        meterChannel: 'K2',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '1e89f6b9-efc0-47be-880c-998d4eb7107b',
        meterChannel: 'K3',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '50b60ac4-53e7-4db6-bab2-4b33b472cee4',
        meterChannel: 'K4',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '756c6fc3-5b6a-4a4d-95ed-39d5c00f85b8',
        meterChannel: 'L1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '5bde1865-ff91-4de4-87f9-f6e18da0a974',
        meterChannel: 'L2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '837729d0-8073-4196-b402-e6c883f41896',
        meterChannel: 'L3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '0c63bb04-9567-4fc8-a67d-5b5603f02256',
        meterChannel: 'L4',
        ctType: 30,
        phase: 'Phase 3',
      },
    ],
    referenceFrequency: '60 Hz',
  },
  pendingConfig: {
    id: '9855c498-6799-478f-b96e-7c77e2eec56f',
    version: '0001',
    dataResolutionInSeconds: 60,
    createdTs: '2021-10-22T06:54:01.000Z',
    appliedTs: '2021-10-22T06:54:46.000Z',
    voltageScalingFactor: 1.0,
    voltageScalingFactors: {
      phase1: 1.0,
      phase2: 1.0,
      phase3: 1.0,
    },
    circuitConfigurations: [
      {
        circuitId: '578d1acf-cdc5-4980-8b49-dd8a7f950f6b',
        meterChannel: 'A1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '86c47e20-c8d8-4da4-bd46-951dfa6d9e56',
        meterChannel: 'A2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '37738f01-b245-4dac-b9b5-03dfd0123bb1',
        meterChannel: 'A3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '9ce04a1c-1710-4164-8afc-89887f1360c8',
        meterChannel: 'A4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'c7cd87e1-2a62-4388-a0b1-bd4090be6cdf',
        meterChannel: 'B1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'ccb12f10-a0f6-417e-ab2c-4a158cab2b97',
        meterChannel: 'B2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '20b5ec21-4fe3-4e8c-954c-a8b944528988',
        meterChannel: 'B3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'f75cdff4-61e8-4f1a-8f45-487442d44b6c',
        meterChannel: 'B4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '3dfce3dc-5a59-41d1-b089-4143e74d2875',
        meterChannel: 'C1',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '665ddd1b-1411-49cb-b3ad-2cdec09dbfac',
        meterChannel: 'C2',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'bd9ba1d3-b0a7-4f76-a8f3-2e6ddf40e94c',
        meterChannel: 'C3',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'bde0ce95-4a9a-44ac-baba-3645ecfde386',
        meterChannel: 'C4',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '22b12d48-496f-4f63-a677-6c7031291af5',
        meterChannel: 'D1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '9526d4b4-38c3-4925-947d-45745c3f31f9',
        meterChannel: 'D2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '7faf7ee6-8402-4bf5-8177-67029282a16d',
        meterChannel: 'D3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: 'f1902c1a-33c1-4596-bd27-3035df170e36',
        meterChannel: 'D4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '1bf5fd05-3a05-4224-aba4-86071dc61d70',
        meterChannel: 'E1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '3af8fb59-a153-4412-a7ac-9cce29ef2db3',
        meterChannel: 'E2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '825ece16-eb84-44c5-bdf2-fcfcc5ed5cdb',
        meterChannel: 'E3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'f9596f09-6537-4eda-b875-d5890db85287',
        meterChannel: 'E4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '0d074544-87b6-4001-9e03-bcfc4c24675a',
        meterChannel: 'F1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '3bf0cffb-5ae8-4e77-b026-9a0a6cf192c8',
        meterChannel: 'F2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'be5d8bba-2a2a-475c-aa3f-c24e05ea4b69',
        meterChannel: 'F3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '20f708ec-5bef-4588-8ff2-98ee1cb998d3',
        meterChannel: 'F4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'f07ae325-c06a-44c0-91d3-487bbd90ff25',
        meterChannel: 'G1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '55c0e669-a691-4d80-bd80-b4b4f2d6e5c3',
        meterChannel: 'G2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '3626ed6a-45c7-4fe7-bef5-a498c52a3598',
        meterChannel: 'G3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'c02125a0-1029-41ac-a3bf-96880669e5ef',
        meterChannel: 'G4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '5e3ff7d0-253b-4092-866e-9ea68bc942c9',
        meterChannel: 'H1',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '0d6488d5-672a-463b-adfa-d2f7264a1ba8',
        meterChannel: 'H2',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '9ae079ed-4f40-4e05-8a6a-45c548a3f61f',
        meterChannel: 'H3',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'fc5bbb6c-deed-413c-86db-35b1a6239f07',
        meterChannel: 'H4',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '567347d0-287f-44b9-80a4-5c4003327e91',
        meterChannel: 'I1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: 'aff0170b-182f-40c6-bf5d-e94b32e7ca5c',
        meterChannel: 'I2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '6b001bd0-1588-4d3c-831d-ac61ee62bbbf',
        meterChannel: 'I3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '99d98978-9c76-4c76-9247-ca18c024e20f',
        meterChannel: 'I4',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '5cc97f5e-8264-411c-a0c5-0eda5755a53f',
        meterChannel: 'J1',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '75242e57-b4e3-491c-a856-40f9aa52c992',
        meterChannel: 'J2',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '10c99fdb-ba55-4d27-b6bf-1dabaae8b40c',
        meterChannel: 'J3',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '134ad8fa-0707-4d73-b6d7-a9904945f8f9',
        meterChannel: 'J4',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: 'f18c2669-4e96-497d-af8b-8e766ebe07c0',
        meterChannel: 'K1',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: 'a276cd80-7b77-47b5-b756-0aaa0e96543e',
        meterChannel: 'K2',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '1e89f6b9-efc0-47be-880c-998d4eb7107b',
        meterChannel: 'K3',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '50b60ac4-53e7-4db6-bab2-4b33b472cee4',
        meterChannel: 'K4',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '756c6fc3-5b6a-4a4d-95ed-39d5c00f85b8',
        meterChannel: 'L1',
        ctType: 30,
        phase: 'Phase 1',
      },
      {
        circuitId: '5bde1865-ff91-4de4-87f9-f6e18da0a974',
        meterChannel: 'L2',
        ctType: 30,
        phase: 'Phase 2',
      },
      {
        circuitId: '837729d0-8073-4196-b402-e6c883f41896',
        meterChannel: 'L3',
        ctType: 30,
        phase: 'Phase 3',
      },
      {
        circuitId: '0c63bb04-9567-4fc8-a67d-5b5603f02256',
        meterChannel: 'L4',
        ctType: 30,
        phase: 'Phase 3',
      },
    ],
    referenceFrequency: '60 Hz',
  },
  verified: true,
  verifiedDate: '2021-11-01T00:51:47.000Z',
  deactivationDate: '2021-11-01T00:51:47.000Z',
  deactivationReason: 'Abandoned',
  firstConfigDate: '2021-01-15T19:12:44.000Z',
  mostRecentConfigurationDate: '2021-10-22T06:54:46.000Z',
  serialNumber: '80060007 04160070',
  hardwareVersion: '07',
  manufactureOs: '01.03.02',
  manufactureDataPacketVersion: '0106',
  cellularCarrier: 'ATT',
  simCardICCID: '89011702278494613130',
  passedManufacturingTesting: true,
}
