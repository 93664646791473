import axios from 'axios'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../../../api'
import { handleAxiosError } from '../../../api/utils'
import itemCommentsMockData from '../../../mockData/billing/billingThisMonth/comments'
import { handleSagaError } from '../../../sagas/utils'
import { isVariantActive } from '../../../utils'

export type FTCommentResponse = {
  id: string
  firstName: string
  lastName: string
  postedTime: string
  comment: string
  action: string
}
export type FTCommentsResponse = Array<FTCommentResponse>
export type FTComment = {
  title: string
  subTitle: string
} & FTCommentResponse
export type FTFetchCommentsAction = {
  billingItemId: string
}
export type FTPostCommentAction = {
  billingItemId: string
  comment: string
}
export type FTCommentsMetaState = {
  error: string
  loading: boolean
}
export type FTPostCommentMetaState = {
  error: string
  loading: boolean
}
export type FTCommentsEntityState = {
  byId: Record<string, FTComment>
  items: Array<FTComment>
  meta: FTCommentsMetaState
  postCommentMeta: FTPostCommentMetaState
}
type FTState = {
  entities: {
    billingThisMonthComments: FTCommentsEntityState
  }
}
export const types = {
  FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS:
    'FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS',
  FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_SUCCESS:
    'FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_SUCCESS',
  FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_ERROR:
    'FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_ERROR',
  POST_BILLING_THIS_MONTH_ITEM_COMMENT: 'POST_BILLING_THIS_MONTH_ITEM_COMMENT',
  POST_BILLING_THIS_MONTH_ITEM_COMMENT_SUCCESS:
    'POST_BILLING_THIS_MONTH_ITEM_COMMENT_SUCCESS',
  POST_BILLING_THIS_MONTH_ITEM_COMMENT_ERROR:
    'POST_BILLING_THIS_MONTH_ITEM_COMMENT_ERROR',
}
export const actions = {
  fetchComments: (props: FTFetchCommentsAction) => ({
    type: types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS,
    ...props,
  }),
  postComment: (props: FTPostCommentAction) => ({
    type: types.POST_BILLING_THIS_MONTH_ITEM_COMMENT,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
  postCommentMeta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('billingThisMonthComments')

function entityById(action, state) {
  return { ...state, ...action.payload.entities.billingThisMonthComments }
}

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTCommentResponse> = Object.values(
    action.payload.entities.billingThisMonthComments,
  )
  return [...new Set(newItems.concat(state))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS:
      return initialState.byId

    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_SUCCESS:
    case types.POST_BILLING_THIS_MONTH_ITEM_COMMENT_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS:
      return initialState.items

    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_SUCCESS:
    case types.POST_BILLING_THIS_MONTH_ITEM_COMMENT_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS:
      return { ...state, error: '', loading: true }

    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

function postCommentMeta(state = initialState.postCommentMeta, action) {
  switch (action.type) {
    case types.POST_BILLING_THIS_MONTH_ITEM_COMMENT:
      return { ...state, error: '', loading: true }

    case types.POST_BILLING_THIS_MONTH_ITEM_COMMENT_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.POST_BILLING_THIS_MONTH_ITEM_COMMENT_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  postCommentMeta,
})
export const selectBillingThisMonthItemComments = (
  state: FTState,
): FTCommentsEntityState => state.entities.billingThisMonthComments
const utils = {
  enhanceBillingComment: (billingComment: FTCommentResponse): FTComment => {
    const title = `${billingComment.firstName} ${billingComment.lastName}`
    const subTitle = billingComment.action
    return { ...billingComment, title, subTitle }
  },
}
const baseUrl = `${consoleApiUrl()}/billing/variable-bills`
export const API = {
  fetchComments: ({ billingItemId }: FTFetchCommentsAction) => {
    if (isVariantActive('3587mock')) {
      return Promise.resolve(itemCommentsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${baseUrl}/${billingItemId}/comments`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTCommentsResponse }) => data)
      .catch(handleAxiosError)
  },
  postComment: ({ billingItemId, comment }: FTPostCommentAction) => {
    if (isVariantActive('3587mock')) {
      const newComment = {
        id: Date.now().toString(),
        firstName: 'Pipa',
        lastName: 'Peru',
        postedTime: new Date(Date.now()).toISOString(),
        comment,
        action: 'Comment',
      }
      return Promise.resolve(newComment).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${baseUrl}/${billingItemId}/comment`
    const body: {
      comment: string
    } = {
      comment,
    }
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchCommentsSaga({
  type,
  ...params
}: {
  type: string
} & FTFetchCommentsAction): Generator<any, void, any> {
  try {
    const response: FTCommentsResponse = yield call(API.fetchComments, params)
    const normalized = normalize(response.map(utils.enhanceBillingComment), [
      entitySchema,
    ])
    yield put({
      type: types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS_ERROR, e)
  }
}

function* postCommentSaga({
  type,
  ...params
}: {
  type: string
} & FTPostCommentAction): Generator<any, void, any> {
  try {
    const response: FTCommentResponse = yield call(API.postComment, params)
    const normalized = normalize(
      [utils.enhanceBillingComment(response)],
      [entitySchema],
    )
    yield put({
      type: types.POST_BILLING_THIS_MONTH_ITEM_COMMENT_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.POST_BILLING_THIS_MONTH_ITEM_COMMENT_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_BILLING_THIS_MONTH_ITEM_COMMENTS, fetchCommentsSaga),
  takeLatest(types.POST_BILLING_THIS_MONTH_ITEM_COMMENT, postCommentSaga),
]
