import { ComponentType, useCallback, useMemo, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import styled, { css } from 'styled-components'

import ModalScenarioTabsStyles from './ModalScenario/ModalScenarioTabsStyles'
import type { FTProposalCustomerGlobalInputs } from '../../ducks/proposals/customerGlobalInputs'
import type { FTProposalOpportunitySummary } from '../../ducks/proposals/opportunitySummaries'
import type {
  FTProposalScenario,
  FTScenarioFieldInfoItemFieldType,
} from '../../ducks/proposals/scenarios'
import {
  formatScenarioFieldValueBoolean,
  formatScenarioFieldValueCurrency,
  formatScenarioFieldValueEnum,
  formatScenarioFieldValuePercentage,
  scenarioFieldCategories,
  scenarioFieldInfo,
} from '../../ducks/proposals/scenarios'
import type { FTProposalSite } from '../../ducks/proposals/sites'
import { isValueSet, valueOrDefault } from '../../utils'
import { colors } from '../../utils/themes'
import DownloadVendorFile from '../DownloadVendorFile'

type FTProps = {
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  opportunity: FTProposalOpportunitySummary
  scenario: FTProposalScenario
  site: FTProposalSite
}
const ViewFieldStyled = styled.label`
  display: block;
  margin-bottom: 16px;
`
const ViewFieldLabelStyled = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`
export const FieldValueStyles = css`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  padding: 10px 16px;
  width: 100%;
`
const ViewFieldValueReadonlyStyles = css`
  background-color: #f5f5f5;
`
const ViewFieldValueStyled: ComponentType<{
  readOnly?: boolean
}> = styled.input`
  ${({ readOnly }) => readOnly && ViewFieldValueReadonlyStyles};
  font-size: 14px;
  ${FieldValueStyles};
`
const ViewTextareaFieldValueStyled = styled.textarea`
  background-color: #f5f5f5;
  font-size: 14px;
  height: 40px;
  position: relative;
  top: 5px;
  ${FieldValueStyles};
`
export const FieldGridStyled: ComponentType<{
  col?: number
}> = styled.div`
  align-items: flex-end;
  display: grid;
  column-gap: 20px;
  ${({ col }) =>
    col &&
    css`
      grid-template-columns: repeat(${col}, 1fr);
    `};
`
export const TabHighlightStyled = styled.div`
  bottom: 0;
  height: 4px;
  position: absolute;
  width: 100%;

  .react-tabs__tab:hover & {
    background-color: #dadce0;
    border-radius: 2px 2px 0 0;
  }

  .react-tabs__tab--selected &,
  .react-tabs__tab--selected:hover & {
    background-color: ${colors.green};
    border-radius: 2px 2px 0 0;
  }
`
export const TabPanelsStyled = styled.div`
  padding-left: 24px;
  padding-right: 32px;
`
export const FormFieldWidthWrapperFullWidthStyles = css`
  grid-column: 1/-1;
`
export const FieldWrapperStyled: ComponentType<{
  fullWidth?: boolean
}> = styled.div`
  ${({ fullWidth }) => fullWidth && FormFieldWidthWrapperFullWidthStyles};
  position: relative;
`
export const ViewInputField = ({ label, value, readOnly = false }: any) => (
  <ViewFieldStyled>
    <ViewFieldLabelStyled>{`${label}`}</ViewFieldLabelStyled>
    <ViewFieldValueStyled
      readOnly={readOnly}
      value={value || value === 0 ? value : ''}
    />
  </ViewFieldStyled>
)

const renderVendorProposal = (scenario) =>
  scenario?.vendorProposalScenarioId ?
    <ViewFieldStyled>
      <ViewFieldLabelStyled>Vendor Proposal</ViewFieldLabelStyled>
      <FieldWrapperStyled key='vendor-proposal'>
        <DownloadVendorFile
          fileIds={scenario.vendorProposalScenarioId}
          name={scenario.vendorProposalScenarioFileName}
          isFormField
        />
      </FieldWrapperStyled>
    </ViewFieldStyled>
  : <ViewFieldStyled>
      <ViewFieldLabelStyled>Vendor Proposal</ViewFieldLabelStyled>
      <ViewFieldValueStyled readOnly />
    </ViewFieldStyled>

const renderAuditVendor = (label, scenario) => (
  <ViewFieldStyled>
    <ViewInputField
      label={label}
      value={valueOrDefault(scenario?.auditVendor, '--')}
      readOnly
    />
  </ViewFieldStyled>
)

const renderFieldByLabel = (label, value, scenario) => {
  switch (label) {
    case 'Vendor Proposal':
      return renderVendorProposal(scenario)

    case 'Audit Vendor':
      return renderAuditVendor(label, scenario)

    default:
      return <ViewInputField label={label} key={label} value={value} readOnly />
  }
}

const ViewFieldGrid = ({
  items,
  scenario,
}: {
  items: Array<Record<string, any>>
  scenario: any
}) => (
  <FieldGridStyled col={2}>
    {items.map(({ label, value }) => (
      <FieldWrapperStyled key={label}>
        {renderFieldByLabel(label, value, scenario)}
      </FieldWrapperStyled>
    ))}
  </FieldGridStyled>
)

export default (props: FTProps) => {
  const { customerGlobalInputs, opportunity, scenario, site } = props
  const { currencyCode = 'USD' } = opportunity || {}
  const { locale = 'en-US' } = customerGlobalInputs || {}
  const formatScenarioFieldValue = useCallback(
    ({
      fieldType,
      formatPostProcess,
      value,
      precision,
      fieldName,
    }: {
      fieldType: FTScenarioFieldInfoItemFieldType
      formatPostProcess?: (...args: Array<any>) => any
      value: string
      precision?: number
      fieldName: string
    }) => {
      let formattedValue

      switch (fieldType) {
        case 'boolean':
          formattedValue = formatScenarioFieldValueBoolean({
            value,
          })
          break

        case 'currency':
          formattedValue = formatScenarioFieldValueCurrency({
            locale,
            currencyCode,
            value,
            precision,
          })
          break

        case 'enum':
          formattedValue = formatScenarioFieldValueEnum({
            value,
          })
          break

        case 'percentage':
          formattedValue = formatScenarioFieldValuePercentage({
            locale,
            value,
          })
          break

        default:
          formattedValue = value
      }

      if (fieldName === 'estimatedSalesTax' && formattedValue < 0)
        return 'Not Calculated'
      return formatPostProcess ?
          formatPostProcess(formattedValue, currencyCode, locale)
        : formattedValue
    },
    [currencyCode, locale],
  )
  const [selectedTab, setSelectedTab] = useState(0)
  const opportunityDetailItems: Array<Record<string, any>> = useMemo(
    () => [
      {
        label: 'Site Id',
        value: valueOrDefault(site.id, '--'),
      },
      {
        label: 'Address',
        value: valueOrDefault(site.shippingStreet, '--'),
      },
      {
        label: 'City',
        value: valueOrDefault(site.shippingCity, '--'),
      },
      {
        label: 'State',
        value: valueOrDefault(site.shippingState, '--'),
      },
      {
        label: 'Zip',
        value: valueOrDefault(site.shippingPostalCode, '--'),
      },
      {
        label: 'Square Footage',
        value: isValueSet(site.squareFeet) ? site.squareFeet : '--',
      },
      {
        label: 'ECM Type',
        value: 'Lighting',
      },
      {
        label: 'Currency',
        value: valueOrDefault(currencyCode, '--'),
      },
      {
        label: 'Audit Vendor',
      },
      {
        label: 'Vendor Proposal',
      },
    ],
    [site],
  )

  const createFieldObject = (fieldName) => ({
    label: scenarioFieldInfo[fieldName].label,
    value: formatScenarioFieldValue({
      fieldType: scenarioFieldInfo[fieldName].fieldType,
      formatPostProcess: scenarioFieldInfo[fieldName].formatPostProcess,
      value: scenario[fieldName],
      precision: scenarioFieldInfo[fieldName].precision,
      fieldName,
    }),
  })

  const scenarioCategoryItems: Record<
    string,
    Array<Record<string, any>>
  > = useMemo(
    () =>
      Object.keys(scenarioFieldCategories).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]:
            (
              scenarioFieldCategories[cur].length &&
              Array.isArray(scenarioFieldCategories[cur][0])
            ) ?
              scenarioFieldCategories[cur].map((fieldGroup: Array<string>) =>
                fieldGroup.map((fieldName: string) =>
                  createFieldObject(fieldName),
                ),
              )
            : scenarioFieldCategories[cur].map((fieldName: string) =>
                createFieldObject(fieldName),
              ),
        }),
        {},
      ),
    [scenario],
  )
  return (
    <ModalScenarioTabsStyles>
      <Tabs
        forceRenderTabPanel
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      >
        <TabList>
          <Tab>
            Opportunity Details
            <TabHighlightStyled />
          </Tab>
          {Object.keys(scenarioFieldCategories).map((scenarioCategory) => (
            <Tab key={`${scenarioCategory}Tab`}>
              {scenarioCategory}
              <TabHighlightStyled />
            </Tab>
          ))}
        </TabList>
        <TabPanelsStyled>
          <TabPanel>
            <ViewInputField
              label='Opportunity ID'
              value={opportunity.redaptiveOpportunityId}
              readOnly
            />
            <ViewInputField
              label='Scenario ID'
              value={scenario.name}
              readOnly
            />
            <ViewFieldGrid
              items={opportunityDetailItems}
              scenario={scenario}
              opportunity={opportunity}
            />
          </TabPanel>
          {Object.entries(scenarioCategoryItems).map(
            ([scenarioCategory, scenarioItems]) => (
              <TabPanel key={`${scenarioCategory}TabPanel`}>
                {/* // $FlowFixMe */}
                {(
                  Array.isArray(
                    scenarioItems.find((item) => item !== undefined),
                  ) // $FlowFixMe
                ) ?
                  scenarioItems.map((scenarioGroup) => (
                    <ViewFieldGrid items={scenarioGroup} />
                  )) // $FlowFixMe
                : <ViewFieldGrid items={scenarioItems} />}
              </TabPanel>
            ),
          )}
        </TabPanelsStyled>
      </Tabs>
    </ModalScenarioTabsStyles>
  )
}
