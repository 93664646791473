import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Modal, { ModalContentStyles } from './Modal'
import OneTimeLink, {
  OneTimeLinkStyles,
  OneTimeLinkTitleStyles,
} from './OneTimeLink'
import Spinner from './Spinner'
import type { FTModalSignupLink } from '../ducks/modal'
import { actions as modalActions } from '../ducks/modal'
import { actions as userActions, selectUserReset } from '../ducks/users'

const ModalStyles = styled.div`
  ${ModalContentStyles} {
    padding: 0;
  }

  ${OneTimeLinkStyles} {
    border: 0;
    padding: 0;
    width: auto;
  }

  ${OneTimeLinkTitleStyles} {
    margin-top: 0;
    text-align: center;
  }
`
type FTProps = {
  actions: Record<string, any>
  userSignupAndResetPassToken: Record<string, any>
} & FTModalSignupLink

const ModalSignupLink = (props: FTProps) => {
  const {
    actions,
    email,
    id,
    userSignupAndResetPassToken: { token, loading, error },
  } = props

  useEffect(() => {
    actions.getSignupAndResetPasswordToken(id)
  }, [id])

  const renderContent = useCallback(() => {
    if (loading) {
      return <Spinner inline size='medium' />
    }
    if (error) {
      return <div>{error}</div>
    }
    return <OneTimeLink email={email} resetId={token} isSignup />
  }, [token, loading, email])

  const onClose = () => {
    actions.hideModal()
  }

  return (
    <ModalStyles>
      <Modal
        modalWidth='720px'
        onAccept={onClose}
        onClose={onClose}
        renderContent={renderContent}
      />
    </ModalStyles>
  )
}

const mapStateToProps = (state) => {
  const { resetId, email: resetEmail } = selectUserReset(state)
  const userSignupAndResetPassToken =
    state.entities.users.signupAndResetPasswordToken

  return {
    resetId,
    resetEmail,
    userSignupAndResetPassToken,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(userActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalSignupLink)
