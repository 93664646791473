import { Component, ReactNode } from 'react'
import styled from 'styled-components'

import Paginator from './Paginator'
import Sortable from './Sortable'
import Spinner from './Spinner'
import type { FTSortable } from '../types'
import '../types'
import './Table.css'
import type { FTEntityMeta } from '../ducks/types'

export type FTTableHeaderType = {
  fieldName: string
  displayName: ReactNode
  renderFunc?: () => ReactNode
  sortable?: boolean
}
export type FTRenderTableRowFunc = (
  row: Record<string, any>,
  index: number,
) => ReactNode
export type FTRenderHeaderActionFunc = () => ReactNode
type FTBrowseToPageFunc = (pageNumber: number) => void
type FTUpdateSortFunc = (field: string, sort: string) => void
type FTProps = {
  items: Array<Record<string, any>>
  tableHeaders: Array<FTTableHeaderType>
  browseToPage: FTBrowseToPageFunc
  updateSort: FTUpdateSortFunc
  renderTableRow: FTRenderTableRowFunc
  renderHeaderAction?: FTRenderHeaderActionFunc | null | undefined
  currentSort?: FTSortable
  className?: string
  striped?: boolean
  stripeColor?: string
  showNoResults?: boolean
} & FTEntityMeta
const HeaderAction = styled.td`
  float: right;
`
const Divider = styled.div`
  height: 23px;
`
const Styles = styled.div`
  & .PerformanceMulti {
    overflow-x: scroll;

    ::-webkit-scrollbar {
      background-color: transparent;
      height: 7px;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      background-clip: content-box;
    }

    .Spinner {
      margin: 100px auto 317px;
    }
  }

  & .Table {
    border-spacing: ${({ striped }) => striped && '0px'};
  }

  & .Table tbody tr {
    height: ${({ striped }) => striped && '40px'};
    line-height: ${({ striped }) => striped && '40px'};
  }

  & .Table tbody tr:nth-child(odd) {
    background-color: ${({ striped, stripeColor }) =>
      stripeColor || (striped && '#f5f5f5')};
  }

  & .Table tbody tr.edited:nth-child(odd) {
    background-color: ${({ striped }) => striped && '#b4dbfa'};
  }

  & .Table tbody tr.edited:nth-child(even) {
    background-color: ${({ striped }) => striped && '#e2f1fd'};
  }
`
export const TableHeaderCellStyles = styled.td``
export default class Table extends Component<FTProps> {
  static defaultProps = {
    renderHeaderAction: undefined,
    currentSort: undefined,
    className: '',
    striped: false,
    stripeColor: '',
    showNoResults: true,
  }

  renderTableHeader() {
    const { updateSort, currentSort, tableHeaders, renderHeaderAction } =
      this.props
    return (
      <thead>
        <tr>
          {tableHeaders.map(
            ({ fieldName, displayName, renderFunc, sortable = true }) => (
              <TableHeaderCellStyles key={fieldName}>
                {!renderFunc && (
                  <Sortable
                    onSort={updateSort}
                    field={fieldName}
                    name={displayName}
                    currentSort={currentSort}
                    enabled={
                      sortable &&
                      currentSort !== null &&
                      currentSort !== undefined
                    }
                  />
                )}
                {renderFunc && renderFunc()}
              </TableHeaderCellStyles>
            ),
          )}
          {renderHeaderAction && (
            <HeaderAction>{renderHeaderAction()}</HeaderAction>
          )}
        </tr>
      </thead>
    )
  }

  render() {
    const {
      loading,
      items,
      totalPages,
      page,
      next,
      prev,
      perPage,
      browseToPage,
      className,
      striped,
      stripeColor,
      showNoResults,
    } = this.props
    const isEmptyLoading = loading && items.length < 1
    return (
      <Styles className={className} striped={striped} stripeColor={stripeColor}>
        <div className='PostInstallPerformance'>
          <div className='PostInstallMultiWrapper'>
            <div className='PerformanceMulti'>
              <table className='Table'>
                {this.renderTableHeader()}

                {items.length > 0 && (
                  <tbody
                    className={`${loading && !isEmptyLoading ? 'loading' : ''}`}
                  >
                    {items.map(this.props.renderTableRow, this)}
                  </tbody>
                )}
              </table>

              {!loading && items.length === 0 && showNoResults && (
                <div className='no-results'>No Results</div>
              )}
              {isEmptyLoading && <Spinner />}

              <Divider className='divider'>
                {!loading && (
                  <Paginator
                    total={totalPages}
                    current={page || 1}
                    next={next}
                    prev={prev}
                    perPage={perPage}
                    browseToPage={browseToPage}
                  />
                )}
              </Divider>
            </div>
          </div>
        </div>
      </Styles>
    )
  }
}
