import styled from 'styled-components'

import Icons from './index'

const VerifiedIcon = styled(Icons.MaterialIcon).attrs({
  children: 'verified_user',
})`
  color: #7bcc76;
`
export default VerifiedIcon
