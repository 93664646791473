import Tippy from '@tippyjs/react'
import axios from 'axios'
import styled, { css } from 'styled-components'

import { consoleApiUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { colors } from '../../utils/themes'

const Ellipsis = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`
const Layer = styled.div`
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  &:hover {
    & + input {
      color: ${colors.blue5};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`
const StyledTippy = styled(Tippy)`
  background: ${colors.blue5};
`
const FieldValueStyles = css`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  padding: 10px 16px;
  width: 100%;
`
const ViewFieldValueStyled = styled.input`
  background-color: #f5f5f5;
  font-size: 14px;
  ${FieldValueStyles};
`
const Wrapper = styled.div`
  cursor: pointer;
`
type FTProps = {
  fileIds: {
    items: Array<string>
  }
  name: string
  isFormField: boolean
}

const getShortendText = (name, limit) =>
  name.length >= limit ? `${name.slice(0, limit)}...` : name

export default function DownloadVendorFile({
  fileIds,
  name,
  isFormField,
}: FTProps) {
  const onDownload = () => {
    const url = `${consoleApiUrl()}/files/download/generate-url`
    const body = {
      fileIds: [fileIds],
    }
    axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => {
        const { preSignedUrl } = data?.[0] || {}
        window.location.assign(preSignedUrl)
      })
      .catch(handleAxiosError)
  }

  if (isFormField) {
    return fileIds ?
        <StyledTippy content={name} placement='bottom'>
          <Ellipsis>
            <Layer onClick={onDownload} />
            <ViewFieldValueStyled value={getShortendText(name, 28)} disabled />
          </Ellipsis>
        </StyledTippy>
      : null
  }

  return (
    <StyledTippy content={name} placement='bottom-end'>
      <Wrapper onClick={onDownload}>{getShortendText(name, 15)}</Wrapper>
    </StyledTippy>
  )
}
