import axios from 'axios'
import Big from 'big.js'
import goalSeek from 'goal-seek'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import { loremIpsum } from 'react-lorem-ipsum'
import { combineReducers } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import xirr from 'xirr'

import { FTProposalSite } from './sites'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import {
  handleAxiosError,
  handleAxiosErrorCreateScenario,
  queryStringify,
} from '../../api/utils'
import mockScenario from '../../mockData/proposals/scenario'
import {
  defaultFieldsMapping,
  defaultUtilityInflationRate,
  EUL_CONST,
  recCostTable,
  salesTaxWarningValue,
  STANDARD_LAMP_BURN_HOURS,
} from '../../pages/ProposalOperations/ProposalsEngine/utils'
import { handleSagaError } from '../../sagas/utils'
import { FTRouterAction } from '../../types'
import {
  getCurrencyFormat,
  getNumberFormatMinFractionDigits2,
  getNumberWithPrecision,
  isValueSet,
  isVariantActive,
  pv,
  stringGen,
} from '../../utils'
import { API_SOURCE, currentYear } from '../../utils/constants'
import topologicalSort from '../../utils/topologicalSort'
import countryNameLookup from '../sites/countryNameLookup'

type FTScenarioFieldEULDataItem = {
  proposedHoursWControls: number
  proposedKwhSavings: number
}

type MaintenanceSavingsCalculationObject = {
  noChangeProductMaintenanceCost: number
  noChangeLabourMaintenance: number
  noChangeRecyclingCost: number
  otherProductMaintenanceCost: number
  otherLabourMaintenance: number
  otherRecyclingCost: number
  otherPostTotalMaintenanceHours: number
}

export type FTCreateProposalScenarioAction = {
  annualEnergyPayment: number
  insuranceForEveryHundredDollarsOfProjectCost: number
  transferRate: number
  baseCost: number
  capexMarginSeek: number
  contingencyCost: number
  contingencyInPercentage: number
  contractRate: number
  dealType: string
  materialCosts: number
  energyContractValue: number
  estimatedSalesTax: string
  estimatedSalesTaxInPercentage: number
  utilityRate: number
  grossMarginInPercentage: number
  laborCosts: number
  insuranceFee: number
  internalRateOfReturn: number
  lightingAddFixtureCount: number
  totalProposedFixtureCount: number
  lightingNoChange: number
  annualPostBurnHours: number
  annualPreBurnHours: number
  monthlyPreBurnHours: number
  monthlyPostBurnHours: number
  lightingRelampBypassBallastCount: number
  lightingRelampCount: number
  lightingRemoveFixtures: number
  lightingReplaceFixtureCount: number
  lightingRetrofitKitCount: number
  lightingSensorsOnly: number
  maintenanceContractValue: number
  maintenanceObligation: string
  maintenanceRepairAndOperationsAnnualPayment: number
  annualMaintenanceSavings: number
  maintenanceSavingsPerFixture: number
  maintenanceRetainedSavingsInPercentage: number
  measurementAndVerificationCostEstimateInPercentage: number
  measurementAndVerificationCostEstimateDollars: number
  costPerMeter: number
  measurementAndVerificationSource: string
  meterCount: number
  name: string
  netCost: number
  netMarginInDollars: number
  netMarginInPercentage: number
  npvNetRevenue: number
  npvOngoingCosts: number
  npvRevenue: number
  operationsAndMaintenanceBasis: string
  operationsAndMaintenance: number
  opportunityId: string
  annualEnergySavingsInDollars: number
  otherCost: number
  postKw: number
  preKw: number
  annualPostKwh: number
  annualPreKwh: number
  partnerFee: number
  partnerFeeInPercentage: number
  rebateCollection: string
  initialRebateAmount: number
  rebateHcInPercentage: number
  finalRebateAmount: number
  costReduction: number
  referralCost: number
  referralFeeBasis: string
  referralFeeInPercentage: number
  salesforceSiteId: string
  vendorSalesTax: number
  energyRetainedSavingsInPercentage: number
  annualSavedKwh: number
  simplePaybackEnergyAndMaintenance: number
  status: string
  contractTermMonths: number
  totalNte: number
  preTaxContractValue: number
  totalContractValueWithSalesTax: number
  totalOngoingContractValue: number
  ttmUtilityUsage: number
  ttmBillReductionInPercentage: number
  upFrontPayment: number
  upFrontMeasurementAndVerification: number
  referral2Cost: number
  modified: string
  modifiedBy: string
  redaptiveOpportunityId: string
  subRows: Array<FTProposalScenario>
  createdOnStageName: string
  utilityInflationRate: number
  savingsThroughUbrHedging: number
  savedDollarsOnAvoidedRecPurchase: number
  metricTonsOfCo2AvoidedAnnually: number
  metricTonsOfCo2AvoidedContractTerm: number
  metricTonsOfCo2AvoidedOverEul: number
  labourCostPerHour: number
  savedKW: number
  blockKwh: number
  simplePaybackEnergyOnly: number
  finalNotes: string
  materialOrderLeadTimeInDays: number
  manufactureDistributorOfPurchase: string
  projectDurationInMonths: number
  manufactureDistributorOfPO: string
  dateOfPurchase: string
  additionalMargin: number
  auditVendor: string
  emissionRate: number
  file?: File
  preTotalMaintenanceCost: number
  postTotalMaintenanceCost: number
  maintenanceSavingsCalculation: MaintenanceSavingsCalculationObject
  vendorProposalScenarioId: string
  eulConstantCalculationDTOList: Array<FTScenarioFieldEULDataItem>
  kWhSavingsByEUL: number
  percentInKwhReduction: number
  created: string
  createdBy: string
  monthlyPaymentToRedaptive: number
}
export type FTProposalScenarioResponse = FTCreateProposalScenarioAction & {
  id: string
}
type FTProposalScenariosResponse = Array<FTProposalScenarioResponse>
export type FTProposalScenario = FTProposalScenarioResponse
export type FTFetchProposalScenariosAction = {
  opportunityId: string
}
export type FTFetchSalesTaxRateAction = {
  opportunityId: string
}
type FTSalesTaxRateResponse = {
  totalRateInPercentage: number
}
export type FTUpdateProposalScenarioStatusAction = {
  id: string
  status: string
}
export type FTUpdateProposalScenarioAction = {
  id: string
} & FTProposalScenarioResponse
type FTProposalScenarioMetaState = {
  error: string
  loading: boolean
  salesTaxFromAvalara: number
  type?: string
}
type FTProposalScenarioEntityState = {
  byId: Record<string, FTProposalScenario>
  items: Array<FTProposalScenario>
  meta: FTProposalScenarioMetaState
}
type FTState = {
  entities: {
    proposalScenarios: FTProposalScenarioEntityState
  }
}
export const scenarioEnumNames: Record<string, string> = {
  'No Maintenance': 'No Maintenance',
  'Parts and Labor': 'Parts and Labor',
  'Parts Only + Emergency Carveout': 'Parts Only + Emergency Carveout',
  'Parts Only': 'Parts Only',
  'Warranty Administration Only': 'Warranty Administration Only',
  'Full Maintenance Coverage 24/7': 'Full Maintenance Coverage 24/7',
  'Preventative Maintenance': 'Preventative Maintenance',
  'Parts Only + 1st year Labor': 'Parts Only + 1st year Labor',
  'Parts Only + 1st year Labor + Emergency Carveout':
    'Parts Only + 1st year Labor + Emergency Carveout',
  'Parts + Labor through vendor': 'Parts + Labor through vendor',
  APPROVED: 'Approved',
  ARCHIVED: 'Archived',
  METER_OPS: 'Meter Ops',
  CAPEX: 'CAPEX',
  CONTRACT_VALUE: 'Pre-Tax Contract Value',
  EAAS: 'EAAS',
  NPV_REVENUE: 'NPV Revenue',
  PENDING: 'Pending',
  SALES_ESTIMATE_PERCENTAGE: 'Sales Estimate - %',
  SALES_ESTIMATE_DOLLAR: 'Sales Estimate - $',
  PRE_TAX_GROSS_COST: 'Base Cost',
  NONE: '-- None --',
  INCLUDED: 'Vendor',
  EXCLUDED: 'Redaptive',
}
export const scenarioEnumFieldInfo: Record<string, Array<string>> = {
  dealType: ['EAAS', 'CAPEX'],
  measurementAndVerificationSource: [
    'METER_OPS',
    'SALES_ESTIMATE_PERCENTAGE',
    'SALES_ESTIMATE_DOLLAR',
  ],
  maintenanceObligation: [
    'No Maintenance',
    'Parts Only',
    'Parts and Labor',
    'Parts Only + Emergency Carveout',
    'Warranty Administration Only',
    'Full Maintenance Coverage 24/7',
    'Preventative Maintenance',
    'Parts Only + 1st year Labor',
    'Parts Only + 1st year Labor + Emergency Carveout',
    'Parts + Labor through vendor',
  ],
  operationsAndMaintenanceBasis: ['NPV_REVENUE', 'PRE_TAX_GROSS_COST'],
  referralFeeBasis: ['CONTRACT_VALUE', 'PRE_TAX_GROSS_COST'],
  status: ['PENDING', 'ARCHIVED', 'APPROVED'],
  rebateCollection: ['EXCLUDED', 'INCLUDED'],
}
export type FTScenarioFieldInfoItemFieldType =
  | 'percentage'
  | 'currency'
  | 'number'
  | 'integer'
  | 'enum'
  | 'boolean'
  | 'textarea'
  | 'string'
  | 'object'
  | 'array'
type ScenarioFieldInfoItem = {
  fieldType: FTScenarioFieldInfoItemFieldType
  fullWidth?: boolean
  formatPostProcess?: (...args: Array<string | number>) => string | number
  label: string
  precision?: number
  readOnly?: boolean
  required?: boolean
  placeholder?: string
}
export const scenarioFieldInfo: {
  [key in string | Array<string>]?: ScenarioFieldInfoItem
} = {
  annualEnergyPayment: {
    fieldType: 'currency',
    label: 'Annual Energy Payment',
    precision: 2,
    readOnly: true,
  },
  insuranceForEveryHundredDollarsOfProjectCost: {
    fieldType: 'percentage',
    label: 'Insurance Fee % of Base Cost',
    precision: 2,
    required: true,
  },
  transferRate: {
    fieldType: 'percentage',
    label: 'Transfer Rate %',
    required: true,
  },
  baseCost: {
    fieldType: 'currency',
    label: 'Base Cost',
    precision: 2,
    readOnly: true,
  },
  capexMarginSeek: {
    fieldType: 'percentage',
    label: 'CAPEX Margin Seek %',
    required: true,
    precision: 4,
  },
  vendorProposalScenarioId: {
    fieldType: 'string',
    label: 'Vendor Proposal Scenario Id',
  },
  contingencyCost: {
    fieldType: 'currency',
    label: 'Contingency Cost',
    precision: 2,
  },
  contingencyInPercentage: {
    fieldType: 'percentage',
    label: 'Contingency %',
    required: true,
  },
  contractRate: {
    fieldType: 'currency',
    label: 'Contract Rate',
    precision: 4,
    readOnly: true,
  },
  dealType: {
    fieldType: 'enum',
    label: 'Contract Type',
    required: true,
  },
  energyContractValue: {
    fieldType: 'currency',
    label: 'Energy Contract Value',
    precision: 2,
    readOnly: true,
  },
  materialCosts: {
    fieldType: 'currency',
    label: 'Material Cost',
    precision: 2,
    required: true,
  },
  utilityRate: {
    fieldType: 'currency',
    label: 'Utility Rate',
    precision: 4,
    required: true,
  },
  estimatedSalesTax: {
    fieldType: 'string',
    label: 'Estimated Sales Tax',
    readOnly: true,
    formatPostProcess: (value, currencyCode, locale) => {
      if (value && Array.from(value)[0] === '-') {
        // formatting applied as had to change from currency field to string
        return 'Not Calculated'
      }

      return getCurrencyFormat(locale, currencyCode, 2).format(value)
    },
  },
  estimatedSalesTaxInPercentage: {
    fieldType: 'percentage',
    label: 'Estimated Sales Tax %',
    precision: 4,
    readOnly: false,
    formatPostProcess: (value) => {
      if (Number(value) === Number(salesTaxWarningValue)) {
        return 'Value not Fetched'
      }
      return value
    },
  },
  grossMargin: {
    fieldType: 'currency',
    label: 'Gross Margin',
    readOnly: true,
  },
  grossMarginInPercentage: {
    fieldType: 'percentage',
    label: 'Gross Margin %',
    readOnly: true,
  },
  laborCosts: {
    fieldType: 'currency',
    label: 'Labor Cost',
    precision: 2,
  },
  insuranceFee: {
    fieldType: 'currency',
    label: 'Insurance Fee',
    precision: 2,
    readOnly: true,
  },
  internalRateOfReturn: {
    fieldType: 'percentage',
    label: 'IRR %',
    readOnly: true,
  },
  lightingAddFixtureCount: {
    fieldType: 'integer',
    label: 'Add Fixture',
  },
  totalProposedFixtureCount: {
    fieldType: 'integer',
    label: 'Total Proposed Fixture Count',
    readOnly: true,
    required: true,
  },
  lightingNoChange: {
    fieldType: 'integer',
    label: 'No Change',
  },
  annualPostBurnHours: {
    fieldType: 'number',
    label: 'Annual Post Burn Hours',
    precision: 2,
    readOnly: true,
  },
  annualPreBurnHours: {
    fieldType: 'number',
    label: 'Annual Pre Burn Hours',
    precision: 2,
    readOnly: true,
  },
  monthlyPreBurnHours: {
    fieldType: 'number',
    label: 'Monthly Pre Burn Hours',
    precision: 2,
    readOnly: true,
  },
  monthlyPostBurnHours: {
    fieldType: 'number',
    label: 'Monthly Post Burn Hours',
    precision: 2,
    readOnly: true,
  },
  lightingRelampBypassBallastCount: {
    fieldType: 'integer',
    label: 'Re-Lamp / Bypass Ballast',
  },
  lightingRelampCount: {
    fieldType: 'integer',
    label: 'Re-Lamp',
  },
  lightingRemoveFixtures: {
    fieldType: 'integer',
    label: 'Remove Fixtures',
  },
  lightingReplaceFixtureCount: {
    fieldType: 'integer',
    label: 'Replace Fixture',
  },
  lightingRetrofitKitCount: {
    fieldType: 'integer',
    label: 'Retrofit Kit',
  },
  lightingSensorsOnly: {
    fieldType: 'integer',
    label: 'Sensors Only',
  },
  maintenanceContractValue: {
    fieldType: 'currency',
    label: 'Maintenance Contract Value',
    precision: 2,
    readOnly: true,
  },
  maintenanceObligation: {
    fieldType: 'enum',
    label: 'Maintenance Obligation',
    required: true,
  },
  maintenanceRepairAndOperationsAnnualPayment: {
    fieldType: 'currency',
    label: 'Annual Maintenance Payment',
    precision: 2,
    readOnly: true,
  },
  annualMaintenanceSavings: {
    fieldType: 'currency',
    label: 'Annual Maintenance Savings',
    precision: 2,
  },
  maintenanceSavingsPerFixture: {
    fieldType: 'currency',
    label: 'Maintenance Savings per Fixture',
    precision: 2,
    required: true,
  },
  maintenanceRetainedSavingsInPercentage: {
    fieldType: 'percentage',
    label: 'Maintenance Retained Savings %',
    required: true,
    precision: 4,
  },
  measurementAndVerificationCostEstimateInPercentage: {
    fieldType: 'percentage',
    label: 'M&V Cost Estimate %',
  },
  measurementAndVerificationCostEstimateDollars: {
    fieldType: 'currency',
    label: 'M&V Cost Estimate',
  },
  costPerMeter: {
    fieldType: 'currency',
    label: 'Cost Per Meter',
    precision: 2,
    required: true,
  },
  measurementAndVerificationSource: {
    fieldType: 'enum',
    label: 'M&V Source',
    required: true,
  },
  meterCount: {
    fieldType: 'integer',
    label: 'Meter Count',
    required: true,
  },
  name: {
    fieldType: 'string',
    label: 'Scenario ID',
  },
  netCost: {
    fieldType: 'currency',
    label: 'Net Cost',
    precision: 2,
    readOnly: true,
  },
  netMarginInDollars: {
    fieldType: 'currency',
    label: 'Net Margin',
    precision: 2,
    readOnly: true,
  },
  netMarginInPercentage: {
    fieldType: 'percentage',
    label: 'Net Margin %',
    readOnly: true,
  },
  npvNetRevenue: {
    fieldType: 'currency',
    label: 'NPV Net Revenue',
    precision: 2,
    readOnly: true,
  },
  npvOngoingCosts: {
    fieldType: 'currency',
    label: 'NPV Ongoing Costs',
    precision: 2,
    readOnly: true,
  },
  npvRevenue: {
    fieldType: 'currency',
    label: 'NPV Revenue',
    precision: 2,
    readOnly: true,
  },
  operationsAndMaintenanceBasis: {
    fieldType: 'enum',
    label: 'O&M Basis',
    required: true,
  },
  operationsAndMaintenance: {
    fieldType: 'percentage',
    label: 'O&M %',
    required: true,
  },
  opportunityId: {
    fieldType: 'string',
    label: 'Opportunity',
  },
  createdOnStageName: {
    fieldType: 'string',
    label: 'Stage',
  },
  annualEnergySavingsInDollars: {
    fieldType: 'currency',
    label: 'Annual Energy Savings',
    readOnly: true,
  },
  otherCost: {
    fieldType: 'currency',
    label: 'Other Cost',
    precision: 2,
  },
  postKw: {
    fieldType: 'number',
    label: 'Post kW',
    required: true,
    precision: 2,
  },
  preKw: {
    fieldType: 'number',
    label: 'Pre kW',
    required: true,
    precision: 2,
  },
  annualPostKwh: {
    fieldType: 'number',
    label: 'Annual Post kWh',
    required: true,
    precision: 0,
  },
  annualPreKwh: {
    fieldType: 'number',
    label: 'Annual Pre kWh',
    required: true,
    precision: 0,
  },
  partnerFee: {
    fieldType: 'currency',
    label: 'Partner Fee',
    precision: 2,
  },
  partnerFeeInPercentage: {
    fieldType: 'percentage',
    label: 'Partner Fee %',
    required: true,
  },
  rebateCollection: {
    fieldType: 'enum',
    label: 'Rebate Collection',
    required: true,
  },
  initialRebateAmount: {
    fieldType: 'currency',
    label: 'Initial Rebate Amount',
    precision: 2,
    required: true,
  },
  rebateHcInPercentage: {
    fieldType: 'percentage',
    label: 'Rebate HC%',
    required: true,
  },
  finalRebateAmount: {
    fieldType: 'currency',
    label: 'Final Rebate Amount',
    precision: 2,
    readOnly: true,
  },
  costReduction: {
    fieldType: 'currency',
    label: 'Cost Reduction',
    precision: 2,
  },
  referralCost: {
    fieldType: 'currency',
    label: 'Referral Cost',
    readOnly: true,
  },
  referralFeeBasis: {
    fieldType: 'enum',
    label: 'Referral Fee Basis',
    required: true,
  },
  referralFeeInPercentage: {
    fieldType: 'percentage',
    label: 'Referral Fee %',
    required: true,
  },
  salesforceSiteId: {
    fieldType: 'string',
    label: 'Salesforce Site Id',
  },
  vendorSalesTax: {
    fieldType: 'currency',
    label: 'Vendor Sales Tax',
    precision: 2,
  },
  energyRetainedSavingsInPercentage: {
    fieldType: 'percentage',
    label: 'Energy Retained Savings %',
    required: true,
    precision: 4,
  },
  annualSavedKwh: {
    fieldType: 'number',
    label: 'Annual Saved kWh',
    precision: 2,
    readOnly: true,
  },
  simplePaybackEnergyAndMaintenance: {
    fieldType: 'number',
    label: 'Simple Payback - Energy + Maintenance',
    precision: 2,
    readOnly: true,
  },
  status: {
    fieldType: 'enum',
    label: 'Status',
  },
  contractTermMonths: {
    fieldType: 'integer',
    label: 'Contract Term Months',
    required: true,
  },
  totalNte: {
    fieldType: 'currency',
    label: 'Total NTE',
    precision: 2,
    readOnly: true,
  },
  preTaxContractValue: {
    fieldType: 'currency',
    label: 'Pre-Tax Contract Value',
    precision: 2,
    readOnly: true,
  },
  totalContractValueWithSalesTax: {
    fieldType: 'currency',
    label: 'Total Contract Value',
    readOnly: true,
  },
  totalOngoingContractValue: {
    fieldType: 'currency',
    label: 'Total Ongoing Contract Value',
    precision: 2,
    readOnly: true,
  },
  ttmUtilityUsage: {
    fieldType: 'number',
    label: 'TTM Utility Usage',
    precision: 2,
  },
  ttmBillReductionInPercentage: {
    fieldType: 'percentage',
    formatPostProcess: (value) => (value === '0.00' ? '0' : value),
    label: 'TTM Bill Reduction %',
    readOnly: true,
  },
  upFrontPayment: {
    fieldType: 'currency',
    label: 'Upfront Payment',
    precision: 2,
  },
  upFrontMeasurementAndVerification: {
    fieldType: 'currency',
    label: 'M&V Cost',
    precision: 2,
    readOnly: true,
  },
  referral2Cost: {
    fieldType: 'currency',
    label: 'Referral #2 Cost',
    precision: 2,
  },
  modified: {
    fieldType: 'string',
    label: 'Last Modified (UTC)',
    precision: 2,
  },
  modifiedBy: {
    fieldType: 'string',
    label: 'Modified By',
    precision: 2,
  },
  savingsThroughUbrHedging: {
    fieldType: 'currency',
    precision: 0,
    label: 'Savings Through UBR Hedging',
    readOnly: true,
  },
  savedDollarsOnAvoidedRecPurchase: {
    fieldType: 'currency',
    precision: 2,
    label: 'Saving for Avoided REC purchase ',
    readOnly: false,
  },
  metricTonsOfCo2AvoidedAnnually: {
    fieldType: 'number',
    precision: 2,
    label: 'Metric Tons of CO2 Avoided Annually',
    readOnly: false,
  },
  metricTonsOfCo2AvoidedContractTerm: {
    fieldType: 'number',
    precision: 2,
    label: 'Metric Tons of CO2 Avoided Over Contract Term',
    readOnly: false,
  },
  metricTonsOfCo2AvoidedOverEul: {
    fieldType: 'number',
    precision: 2,
    label: 'Metric Tons of CO2 Avoided Over EUL',
    readOnly: false,
  },
  utilityInflationRate: {
    fieldType: 'percentage',
    precision: 2,
    label: 'Utility Inflation Rate %',
    required: true,
  },
  labourCostPerHour: {
    fieldType: 'currency',
    precision: 2,
    label: 'Labor Cost per Hour',
    required: true,
  },
  savedKW: {
    fieldType: 'number',
    precision: 2,
    label: 'Saved kW',
    readOnly: true,
  },
  blockKwh: {
    fieldType: 'number',
    precision: 2,
    label: 'Block kWh',
    readOnly: true,
  },
  simplePaybackEnergyOnly: {
    fieldType: 'number',
    precision: 2,
    label: 'Simple Payback - Energy only',
    readOnly: true,
  },
  finalNotes: {
    fieldType: 'textarea',
    fullWidth: true,
    label: 'Final Notes',
  },
  materialOrderLeadTimeInDays: {
    fieldType: 'integer',
    precision: 0,
    label: 'Material Order Lead Time in Days',
  },
  manufactureDistributorOfPurchase: {
    fieldType: 'string',
    label: 'Manufacturer/Distributor of Purchase',
  },
  projectDurationInMonths: {
    fieldType: 'integer',
    precision: 0,
    label: 'Project Duration in Months',
  },
  manufactureDistributorOfPO: {
    fieldType: 'string',
    label: 'Manufacturer/Distributor of PO#',
  },
  dateOfPurchase: {
    fieldType: 'string',
    label: 'Date of Purchase',
  },
  additionalMargin: {
    fieldType: 'currency',
    label: 'Additional Margin',
  },
  auditVendor: {
    fieldType: 'string',
    label: 'Audit Vendor',
  },
  monthlyPaymentToRedaptive: {
    fieldType: 'currency',
    label: 'Monthly Payment to Redaptive',
    precision: 2,
    readOnly: true,
  },
  address: {
    fieldType: 'textarea',
    fullWidth: true,
    label: 'Address',
  },
  vendorProposalScenarioFileName: {
    fieldType: 'string',
    label: 'Vendor Proposal',
    readOnly: true,
  },
  emissionRate: {
    fieldType: 'number',
    label: 'Emission Rate',
  },
  preTotalMaintenanceCost: {
    fieldType: 'number',
    label: '',
  },
  postTotalMaintenanceCost: {
    fieldType: 'number',
    label: '',
  },
  maintenanceSavingsCalculation: {
    fieldType: 'object',
    label: '',
    noChangeProductMaintenanceCost: {
      fieldType: 'number',
      label: 'No Change Product Maintenance Cost',
    },
    noChangeLabourMaintenance: {
      fieldType: 'number',
      label: 'No Change Labour Maintenance',
    },
    noChangeRecyclingCost: {
      fieldType: 'number',
      label: 'No Change Recycling Cost',
    },
    otherProductMaintenanceCost: {
      fieldType: 'number',
      label: 'Other Product Maintenance Cost',
    },
    otherLabourMaintenance: {
      fieldType: 'number',
      label: 'Other Labour Maintenance',
    },
    otherRecyclingCost: {
      fieldType: 'number',
      label: 'Other Recycling Cost',
    },
    otherPostTotalMaintenanceHours: {
      fieldType: 'number',
      label: 'Other Post Total Maintenance Hours',
    },
  },
  kWhSavingsByEUL: {
    fieldType: 'number',
    label: 'kWh Savings By EUL',
    readOnly: true,
  },
  eulConstantCalculationDTOList: {
    fieldType: 'array',
    label: '',
  },
  percentInKwhReduction: {
    fieldType: 'percentage',
    precision: 2,
    label: '% in kWh Reduction',
    readOnly: true,
  },
  created: {
    fieldType: 'string',
    label: 'Created On ',
  },
  createdBy: {
    fieldType: 'string',
    label: 'Created By ',
  },
}
export const overrideScenarioFieldInfoOnBatch = {
  annualPreBurnHours: {
    fieldType: 'number',
    precision: 2,
    readOnly: true,
    fullWidth: true,
    label: 'Monthly Burn Hours',
  },
}
export const columnStyles: Record<
  string,
  {
    minWidth: number
  }
> = {
  name: {
    minWidth: 200,
  },
  contractTermMonths: {
    minWidth: 100,
  },
  dealType: {
    minWidth: 100,
  },
  energyRetainedSavingsInPercentage: {
    minWidth: 120,
  },
  netCost: {
    minWidth: 120,
  },
  maintenanceRetainedSavingsInPercentage: {
    minWidth: 120,
  },
  preTaxContractValue: {
    minWidth: 120,
  },
  estimatedSalesTax: {
    minWidth: 120,
  },
  totalContractValueWithSalesTax: {
    minWidth: 120,
  },
  netMarginInDollars: {
    minWidth: 120,
  },
  netMarginInPercentage: {
    minWidth: 120,
  },
  internalRateOfReturn: {
    minWidth: 120,
  },
  grossMarginInPercentage: {
    minWidth: 120,
  },
  totalProposedFixtureCount: {
    minWidth: 120,
  },
  annualSavedKwh: {
    minWidth: 120,
  },
  opportunityCurrencyCode: {
    minWidth: 120,
  },
  partnerFee: {
    minWidth: 120,
  },
  partnerFeeInPercentage: {
    minWidth: 120,
  },
  referralCost: {
    minWidth: 120,
  },
  referralFeeInPercentage: {
    minWidth: 120,
  },
  contingencyInPercentage: {
    minWidth: 120,
  },
  contingencyCost: {
    minWidth: 120,
  },
  upFrontMeasurementAndVerification: {
    minWidth: 120,
  },
  meterCount: {
    minWidth: 120,
  },
  finalRebateAmount: {
    minWidth: 120,
  },
  rebateHcInPercentage: {
    minWidth: 120,
  },
  annualEnergySavingsInDollars: {
    minWidth: 120,
  },
  annualMaintenanceSavings: {
    minWidth: 120,
  },
  costReduction: {
    minWidth: 120,
  },
  upFrontPayment: {
    minWidth: 120,
  },
  utilityRate: {
    minWidth: 120,
  },
  contractRate: {
    minWidth: 120,
  },
  annualPreKwh: {
    minWidth: 120,
  },
  annualPostKwh: {
    minWidth: 120,
  },
  created: {
    minWidth: 120,
  },
  createdBy: {
    minWidth: 120,
  },
  modified: {
    minWidth: 120,
  },
  modifiedBy: {
    minWidth: 120,
  },
  vendorProposalScenarioFileName: {
    minWidth: 180,
  },
}
type FTXirrTransaction = {
  amount: number
  when: Date
}
export type FTCalculations = Record<
  string,
  (values: FTProposalScenario, site: FTProposalSite) => number
>
export const getXirrTransactions = (
  values: FTProposalScenario,
): Array<FTXirrTransaction> => {
  const { preTaxContractValue, npvOngoingCosts, netCost, contractTermMonths } =
    values
  const initialAmount = parseFloat(Big(netCost).times(-1).toFixed(16))
  const subsequentAmount = parseFloat(
    Big(preTaxContractValue)
      .minus(npvOngoingCosts)
      .div(contractTermMonths)
      .toFixed(16),
  )
  let nextTransactionDate = moment().endOf('month')
  const transactions: Array<FTXirrTransaction> = [
    {
      amount: initialAmount,
      when: nextTransactionDate,
    },
  ]

  for (let i = 0; i < contractTermMonths; i += 1) {
    nextTransactionDate = moment(nextTransactionDate)
      .add(1, 'month')
      .endOf('month')
    transactions.push({
      amount: subsequentAmount,
      when: nextTransactionDate,
    })
  }

  return transactions
}

const getFixtureEUL = (
  values: FTProposalScenario,
  eulConstantCalculationItem: FTScenarioFieldEULDataItem,
) => {
  const { dealType, contractTermMonths } = values
  const { proposedHoursWControls } = eulConstantCalculationItem
  if (Number(proposedHoursWControls) === 0) {
    return 0
  }
  const standardEUL = Number(
    Big(STANDARD_LAMP_BURN_HOURS).div(proposedHoursWControls),
  )
  const contractYears = Number(Big(contractTermMonths).div(12))
  let EUL: number = 0
  if (dealType === 'EAAS') {
    if (standardEUL < contractYears) {
      EUL = contractYears
    } else EUL = standardEUL

    if (EUL > 20) {
      EUL = 20
    }
  }
  if (dealType === 'CAPEX') {
    if (Number(standardEUL) > 20) {
      EUL = 20
    } else EUL = standardEUL
  }
  return Number(Big(EUL).round())
}

const getRecValue = (year: number) => {
  const recData = recCostTable.find((data) => data.year === year)
  return recData?.recValue ?? 0
}

export const scenarioCalculations: number = {
  annualSavedKwh: (values: FTProposalScenario) =>
    Big(values.annualPreKwh).minus(values.annualPostKwh).toFixed(16),
  contractRate: (values: FTProposalScenario) =>
    Big(values.utilityRate)
      .times(
        Big(1).minus(Big(values.energyRetainedSavingsInPercentage).div(100)),
      )
      .toFixed(scenarioFieldInfo.contractRate.precision),
  annualEnergyPayment: (values: FTProposalScenario) =>
    Big(values.annualSavedKwh).times(values.contractRate).toFixed(16),
  baseCost: (values: FTProposalScenario) =>
    Big(values.materialCosts)
      .plus(values.laborCosts)
      .plus(values.otherCost)
      .toFixed(16),
  contingencyCost: (values: FTProposalScenario) =>
    Big(values.baseCost)
      .times(Big(values.contingencyInPercentage).div(100))
      .toFixed(16),
  annualEnergySavingsInDollars: (values: FTProposalScenario) =>
    Big(values.utilityRate).times(values.annualSavedKwh).toFixed(16),
  totalProposedFixtureCount: (values: FTProposalScenario) =>
    Big(values.lightingAddFixtureCount)
      .plus(values.lightingRelampBypassBallastCount)
      .plus(values.lightingReplaceFixtureCount)
      .plus(values.lightingRelampCount)
      .plus(values.lightingRetrofitKitCount)
      .toFixed(16),
  upFrontMeasurementAndVerification: (values: FTProposalScenario) =>
    Big(values.meterCount).times(values.costPerMeter).toFixed(16),
  finalRebateAmount: (values: FTProposalScenario) =>
    Big(values.initialRebateAmount)
      .times(Big(1).minus(Big(values.rebateHcInPercentage).div(100)))
      .toFixed(16),
  preTaxContractValue: (values: FTProposalScenario) =>
    Big(values.totalOngoingContractValue)
      .plus(values.upFrontPayment)
      .toFixed(16),
  partnerFee: (values: FTProposalScenario) =>
    Big(values.baseCost)
      .times(Big(values.partnerFeeInPercentage).div(100))
      .toFixed(16),
  insuranceFee: (values: FTProposalScenario) => {
    const insurenceFeeValue = Big(values.contractTermMonths)
      .div(12)
      .times(values.insuranceForEveryHundredDollarsOfProjectCost)
      .times(values.baseCost)
      .div(100)
      .toFixed(16)
    if (values.dealType === 'EAAS') {
      if (
        Number(values.baseCost) < 10000 ||
        Number(values.baseCost) < 10000 + Number(insurenceFeeValue)
      ) {
        return 0
      }
      return insurenceFeeValue
    }
    return 0
  },
  referralCost: (values: FTProposalScenario) =>
    values.referralFeeBasis === 'PRE_TAX_GROSS_COST' ?
      Big(Big(values.referralFeeInPercentage).div(100))
        .times(values.baseCost)
        .toFixed(16)
    : Big(Big(values.referralFeeInPercentage).div(100))
        .times(values.preTaxContractValue)
        .toFixed(16),
  netCost: (values: FTProposalScenario) =>
    Big(
      Big(values.baseCost)
        .plus(values.partnerFee)
        .plus(values.vendorSalesTax)
        .plus(values.contingencyCost)
        .plus(values.upFrontMeasurementAndVerification)
        .plus(values.insuranceFee)
        .plus(values.referralCost)
        .plus(values.referral2Cost),
    )
      .minus(Big(values.finalRebateAmount).plus(values.costReduction))
      .toFixed(16),
  grossMarginInPercentage: (values: FTProposalScenario) =>
    parseInt(values.preTaxContractValue, 10) ?
      Big(Big(values.preTaxContractValue).minus(values.netCost))
        .div(values.preTaxContractValue)
        .times(100)
        .toFixed(16)
    : 0,
  grossMargin: (values: FTProposalScenario) =>
    Big(values.preTaxContractValue).minus(values.netCost).toFixed(16),
  preTotalMaintenanceCost: (values: FTProposalScenario) =>
    values.maintenanceSavingsCalculation && values.vendorProposalScenarioId ?
      Big(values.maintenanceSavingsCalculation.noChangeProductMaintenanceCost)
        .plus(values.maintenanceSavingsCalculation.noChangeRecyclingCost)
        .plus(
          Big(
            values.maintenanceSavingsCalculation.noChangeLabourMaintenance,
          ).times(values.labourCostPerHour),
        )
        .plus(
          Big(values.maintenanceSavingsCalculation.otherProductMaintenanceCost)
            .plus(values.maintenanceSavingsCalculation.otherRecyclingCost)
            .plus(
              Big(
                values.maintenanceSavingsCalculation.otherLabourMaintenance,
              ).times(values.labourCostPerHour),
            ),
        )
        .toFixed(16)
    : 0,
  postTotalMaintenanceCost: (values: FTProposalScenario) => {
    if (
      values.maintenanceObligation === 'Parts Only' ||
      values.maintenanceObligation === 'No Maintenance'
    ) {
      return (
          values.maintenanceSavingsCalculation &&
            values.vendorProposalScenarioId
        ) ?
          Big(
            values.maintenanceSavingsCalculation.otherPostTotalMaintenanceHours,
          )
            .times(values.labourCostPerHour)
            .plus(
              Big(
                values.maintenanceSavingsCalculation
                  .noChangeProductMaintenanceCost,
              )
                .plus(
                  values.maintenanceSavingsCalculation.noChangeRecyclingCost,
                )
                .plus(
                  Big(
                    values.maintenanceSavingsCalculation
                      .noChangeLabourMaintenance,
                  ).times(values.labourCostPerHour),
                ),
            )
            .toFixed(16)
        : 0
    }

    return 0
  },
  annualMaintenanceSavings: (values: FTProposalScenario) => {
    if (values.vendorProposalScenarioId) {
      let annualMaintenanceValue
      if (
        values.maintenanceObligation === 'Parts Only' ||
        values.maintenanceObligation === 'Parts and Labor' ||
        values.maintenanceObligation === 'No Maintenance'
      ) {
        annualMaintenanceValue = Big(values.preTotalMaintenanceCost)
          .minus(values.postTotalMaintenanceCost)
          .toFixed(16)
      } else {
        annualMaintenanceValue = Big(values.totalProposedFixtureCount)
          .times(values.maintenanceSavingsPerFixture)
          .toFixed(16)
      }

      if (Number(annualMaintenanceValue) === 0) {
        annualMaintenanceValue = Big(values.totalProposedFixtureCount)
          .times(defaultFieldsMapping.maintenanceSavingsPerFixture)
          .toFixed(16)
      }
      return annualMaintenanceValue
    }

    return Big(values.maintenanceSavingsPerFixture)
      .times(values.totalProposedFixtureCount)
      .toFixed(16)
  },
  maintenanceSavingsPerFixture: (values: FTProposalScenario) => {
    if (values.vendorProposalScenarioId) {
      if (
        values.maintenanceObligation === 'Parts Only' ||
        values.maintenanceObligation === 'Parts and Labor' ||
        values.maintenanceObligation === 'No Maintenance'
      ) {
        return parseInt(values.totalProposedFixtureCount, 10) ?
            Big(values.annualMaintenanceSavings)
              .div(values.totalProposedFixtureCount)
              .toFixed(16)
          : 0
      }

      return values.maintenanceSavingsPerFixture
    }

    return values.maintenanceSavingsPerFixture
  },
  maintenanceRepairAndOperationsAnnualPayment: (values: FTProposalScenario) =>
    Big(values.annualMaintenanceSavings)
      .times(
        Big(1).minus(
          Big(values.maintenanceRetainedSavingsInPercentage).div(100),
        ),
      )
      .toFixed(16),
  totalOngoingContractValue: (values: FTProposalScenario) => {
    const annualLightingContractValue = Big(values.annualSavedKwh).times(
      values.contractRate,
    )
    const maintenanceSavingsPercent = Big(1).minus(
      Big(values.maintenanceRetainedSavingsInPercentage).div(100),
    )
    const annualMaintenanceContractValue = Big(
      values.annualMaintenanceSavings,
    ).times(Big(maintenanceSavingsPercent))
    const annualContractValue = Big(annualLightingContractValue).plus(
      Big(annualMaintenanceContractValue),
    )

    if (values.dealType === 'EAAS') {
      return annualContractValue
        .times(Big(values.contractTermMonths).div(12))
        .toFixed(16)
    }

    if (values.referralFeeBasis === 'CONTRACT_VALUE') {
      const divisor = Big(1)
        .minus(Big(values.capexMarginSeek).div(100))
        .minus(Big(values.referralFeeInPercentage).div(100))
        .toFixed(16)
      return Number(divisor) ?
          Big(values.baseCost)
            .plus(Big(values.vendorSalesTax))
            .plus(Big(values.contingencyCost))
            .plus(Big(values.upFrontMeasurementAndVerification))
            .plus(Big(values.insuranceFee))
            .plus(Big(values.partnerFee))
            .minus(
              Big(values.finalRebateAmount).plus(Big(values.costReduction)),
            )
            .div(divisor)
            .plus(Big(values.additionalMargin))
            .toFixed(16)
        : 0
    }

    const divisor = Big(1)
      .minus(Big(values.capexMarginSeek).div(100))
      .toFixed(16)
    return Number(divisor) ?
        Big(values.netCost)
          .div(divisor)
          .plus(Big(values.additionalMargin))
          .toFixed(16)
      : 0
  },
  npvRevenue: (values: FTProposalScenario) => {
    if (values.dealType === 'CAPEX') {
      return Big(values.totalOngoingContractValue)
        .plus(values.upFrontPayment)
        .toFixed(16)
    }

    const termValue = parseInt(values.contractTermMonths, 10)
    const presentValue =
      (termValue &&
        pv(
          Big(values.transferRate).div(12).div(100).toFixed(16),
          termValue,
          Big(values.totalOngoingContractValue).div(termValue).toFixed(16),
        )) ||
      0
    return Big(-1).times(presentValue).plus(values.upFrontPayment).toFixed(16)
  },
  npvOngoingCosts: (values: FTProposalScenario) => {
    if (values.dealType === 'CAPEX') {
      return 0
    }

    if (values.operationsAndMaintenanceBasis === 'NPV_REVENUE') {
      return Big(values.npvRevenue)
        .times(Big(values.operationsAndMaintenance).div(100))
        .toFixed(16)
    }

    return Big(values.baseCost)
      .times(Big(values.operationsAndMaintenance).div(100))
      .toFixed(16)
  },
  npvNetRevenue: (values: FTProposalScenario) =>
    Big(values.npvRevenue).minus(values.npvOngoingCosts).toFixed(16),
  internalRateOfReturn: (values: FTProposalScenario) => {
    if (values.dealType === 'EAAS') {
      try {
        const xirrScenario = xirr(getXirrTransactions(values))
        return typeof xirrScenario === 'number' ?
            Big(xirrScenario).times(100).toFixed(16)
          : 0
      } catch (error) {
        return 0
      }
    }
    return 0
  },
  netMarginInDollars: (values: FTProposalScenario) =>
    Big(values.npvNetRevenue).minus(values.netCost).toFixed(16),
  netMarginInPercentage: (values: FTProposalScenario) =>
    parseInt(values.npvNetRevenue, 10) ?
      Big(values.netMarginInDollars)
        .div(values.npvNetRevenue)
        .times(100)
        .toFixed(16)
    : 0,
  ttmBillReductionInPercentage: (values: FTProposalScenario) =>
    parseFloat(values.ttmUtilityUsage) ?
      Big(values.annualPreKwh)
        .minus(values.annualPostKwh)
        .div(values.ttmUtilityUsage)
        .times(100)
        .toFixed(16)
    : 0,
  simplePaybackEnergyAndMaintenance: (values: FTProposalScenario) => {
    const numerator =
      values.dealType === 'CAPEX' ? values.preTaxContractValue : values.netCost
    const denominator = Big(values.annualEnergySavingsInDollars).plus(
      Big(values.annualMaintenanceSavings),
    )
    return denominator.toNumber() ?
        Big(numerator).div(denominator).toFixed(16)
      : 0
  },
  totalContractValueWithSalesTax: (values: FTProposalScenario) => {
    const estimatedSalesTaxValue =
      values.estimatedSalesTaxInPercentage < 0 ? 0 : values.estimatedSalesTax
    return Big(values.preTaxContractValue)
      .plus(estimatedSalesTaxValue)
      .toFixed(16)
  },
  estimatedSalesTax: (values: FTProposalScenario) =>
    Number(values.estimatedSalesTaxInPercentage) ?
      Big(values.estimatedSalesTaxInPercentage)
        .times(values.preTaxContractValue)
        .div(100)
        .toFixed(16)
    : '',
  annualPreBurnHours: (values: FTProposalScenario) =>
    parseFloat(values.preKw) ?
      Big(values.annualPreKwh).div(values.preKw).toFixed(0)
    : 0,
  annualPostBurnHours: (values: FTProposalScenario) => {
    if (
      parseFloat(values.postKw) &&
      parseFloat(values.preKw) &&
      Big(values.annualPostKwh)
        .div(values.postKw)
        .gt(Big(values.annualPreKwh).div(values.preKw))
    ) {
      return Big(values.annualPreKwh).div(values.preKw).toFixed(0)
    }

    if (parseFloat(values.postKw)) {
      return Big(values.annualPostKwh).div(values.postKw).toFixed(0)
    }

    return 0
  },
  monthlyPreBurnHours: (values: FTProposalScenario) =>
    values.annualPreBurnHours ?
      Big(values.annualPreBurnHours).div(12).toFixed(0)
    : 0,
  monthlyPostBurnHours: (values: FTProposalScenario) =>
    values.annualPostBurnHours ?
      Big(values.annualPostBurnHours).div(12).toFixed(0)
    : 0,
  meterCount: (values: FTProposalScenario) => {
    if (parseFloat(values.costPerMeter)) {
      const fixtureCalc = Big(values.totalProposedFixtureCount)
        .div(values.costPerMeter)
        .times(15)
      let baseCostCalc = 0

      if (values.measurementAndVerificationCostEstimateInPercentage) {
        baseCostCalc = Big(values.baseCost)
          .times(values.measurementAndVerificationCostEstimateInPercentage)
          .div(100)
          .div(values.costPerMeter)
      }

      const finalValue = Math.ceil(Math.min(fixtureCalc, baseCostCalc))
      return finalValue
    }

    return 0
  },
  energyContractValue: (values: FTProposalScenario) =>
    values.dealType === 'CAPEX' ?
      0
    : Big(values.annualSavedKwh)
        .times(values.contractTermMonths)
        .div(12)
        .times(values.contractRate)
        .toFixed(16),
  maintenanceContractValue: (values: FTProposalScenario) =>
    values.dealType === 'CAPEX' ?
      0
    : Big(values.annualMaintenanceSavings)
        .times(
          Big(1).minus(
            Big(values.maintenanceRetainedSavingsInPercentage).div(100),
          ),
        )
        .times(values.contractTermMonths)
        .div(12)
        .toFixed(16),
  savingsThroughUbrHedging: (
    values: FTProposalScenario,
    site: FTProposalSite,
  ) => {
    const { shippingStateCode = '' } = site ?? ''
    const {
      annualPreKwh = 0,
      annualPostKwh = 0,
      contractRate = 0,
      utilityRate = 0,
      utilityInflationRate = defaultUtilityInflationRate,
      contractTermMonths,
    } = values
    const kwhDiff = Big(annualPreKwh).minus(annualPostKwh)
    const paymentToRedaptive = kwhDiff.times(contractRate)
    const retainedSavings = kwhDiff.times(Big(utilityRate).minus(contractRate))

    const getYearlyUBRSavings = (inflatedUtilityRate) =>
      Big(annualPreKwh)
        .times(inflatedUtilityRate)
        .minus(
          Big(annualPostKwh)
            .times(inflatedUtilityRate)
            .plus(paymentToRedaptive)
            .plus(retainedSavings),
        )
        .round(4)

    let ubrHedging = 0
    if (!contractTermMonths) return 0
    const year = Big(contractTermMonths).div(12).round()
    let inflatedUtilityRate = utilityRate

    for (let i = 1; i <= year; i += 1) {
      const yearlyUBRSavings = getYearlyUBRSavings(inflatedUtilityRate)
      ubrHedging = Big(ubrHedging).plus(yearlyUBRSavings.toNumber())
      inflatedUtilityRate = Big(inflatedUtilityRate)
        .times(Big(utilityInflationRate).div(100).plus(1))
        .round(4)
        .toFixed()
    }

    return ubrHedging.toFixed(2)
  },
  savedKW: (values: FTProposalScenario) =>
    Big(values.preKw).minus(values.postKw).toFixed(16),
  blockKwh: (values: FTProposalScenario) =>
    Big(values.annualSavedKwh)
      .times(values.contractTermMonths)
      .div(12)
      .toFixed(16),
  simplePaybackEnergyOnly: (values: FTProposalScenario) => {
    const numerator =
      values.dealType === 'CAPEX' ?
        Big(values.preTaxContractValue)
      : Big(values.netCost)
    const denominator = Big(values.annualEnergySavingsInDollars)
    return denominator.toNumber() ?
        Big(numerator).div(denominator).toFixed(16)
      : 0
  },
  totalNte: (values: FTProposalScenario) =>
    Big(values.materialCosts)
      .plus(values.laborCosts)
      .plus(values.otherCost)
      .plus(values.vendorSalesTax)
      .toFixed(16),
  metricTonsOfCo2AvoidedAnnually: (values: FTProposalScenario) =>
    Big(Big(values.annualSavedKwh).div(1000))
      .times(Big(values.emissionRate || 0).times(0.00045359237))
      .toFixed(2),
  metricTonsOfCo2AvoidedContractTerm: (values: FTProposalScenario) =>
    Big(values.metricTonsOfCo2AvoidedAnnually)
      .times(values.contractTermMonths)
      .div(12)
      .toFixed(2),
  kWhSavingsByEUL: (values: FTProposalScenario) => {
    const { eulConstantCalculationDTOList = [] } = values
    if (eulConstantCalculationDTOList.length) {
      const accKwhSavingsByEUL = eulConstantCalculationDTOList.reduce(
        (acc, eulConstantCalculationItem) => {
          const { proposedKwhSavings = 0 } = eulConstantCalculationItem
          const EUL = getFixtureEUL(values, eulConstantCalculationItem)
          return Number(
            Big(acc).plus(Big(EUL).times(proposedKwhSavings)).toFixed(16),
          )
        },
        0,
      )
      return accKwhSavingsByEUL
    }
    return 0
  },
  metricTonsOfCo2AvoidedOverEul: (values: FTProposalScenario) =>
    Big(values.kWhSavingsByEUL)
      .div(1000)
      .times(values.emissionRate || 0)
      .times(EUL_CONST)
      .toFixed(2),
  savedDollarsOnAvoidedRecPurchase: (
    values: FTProposalScenario,
    site?: FTProposalSite = {},
  ) => {
    const { eulConstantCalculationDTOList = [] } = values
    const { shippingCountry = countryNameLookup.US } = site
    const discountRate = 6
    if (
      eulConstantCalculationDTOList.length &&
      shippingCountry === countryNameLookup.US
    ) {
      return eulConstantCalculationDTOList.reduce(
        (acc, eulConstantCalculationItem) => {
          const { proposedKwhSavings = 0 } = eulConstantCalculationItem
          const EUL = getFixtureEUL(values, eulConstantCalculationItem)
          let totalRecSavings: number = 0
          for (let i = 0; i < EUL; i += 1) {
            const recSavings = Number(
              Big(proposedKwhSavings)
                .times(getRecValue(Number(currentYear) + i))
                .div(1000)
                .toFixed(16),
            )
            const recSavingsNPV = Big(recSavings)
              .div(Big(Big(1).plus(Big(discountRate).div(100))).pow(i + 1))
              .toFixed(16)
            totalRecSavings += Number(recSavingsNPV)
          }
          return Number(Big(acc).plus(totalRecSavings).toFixed(2))
        },
        0,
      )
    }
    return 0
  },
  percentInKwhReduction: (values: FTProposalScenario) => {
    if (values.annualSavedKwh > 0) {
      return Big(values.annualSavedKwh)
        .div(values.annualPreKwh)
        .times(100)
        .toFixed(2)
    }
    return 0
  },
  monthlyPaymentToRedaptive: (values: FTProposalScenario) => {
    if (values.contractTermMonths <= 0) return 0
    return Big(values.preTaxContractValue)
      .div(values.contractTermMonths)
      .toFixed(16)
  },
}

const getInsuranceFeeDependencies = (scenario: FTProposalScenario) =>
  scenario?.dealType === 'EAAS' ?
    [
      'insuranceForEveryHundredDollarsOfProjectCost',
      'baseCost',
      'contractTermMonths',
    ]
  : []

const getPostTotalMaintenanceCostDependencies = (
  scenario: FTProposalScenario,
) =>
  (
    scenario.maintenanceObligation === 'Parts Only' ||
    scenario.maintenanceObligation === 'No Maintenance'
  ) ?
    [
      'otherPostTotalMaintenanceHours',
      'labourCostPerHour',
      'noChangeProductMaintenanceCost',
      'noChangeLabourMaintenance',
      'noChangeRecyclingCost',
    ]
  : []

const getAnnualMaintenanceSavingsDependencies = (
  scenario: FTProposalScenario,
) =>
  (
    scenario.vendorProposalScenarioId &&
    (scenario.maintenanceObligation === 'Parts Only' ||
      scenario.maintenanceObligation === 'Parts and Labor' ||
      scenario.maintenanceObligation === 'No Maintenance')
  ) ?
    ['preTotalMaintenanceCost', 'postTotalMaintenanceCost']
  : ['maintenanceSavingsPerFixture', 'totalProposedFixtureCount']

const getMaintenanceSavingsPerFixtureDependencies = (
  scenario: FTProposalScenario,
) =>
  (
    scenario.vendorProposalScenarioId &&
    (scenario.maintenanceObligation === 'Parts Only' ||
      scenario.maintenanceObligation === 'Parts and Labor' ||
      scenario.maintenanceObligation === 'No Maintenance')
  ) ?
    ['annualMaintenanceSavings', 'totalProposedFixtureCount']
  : []

const getNPVOngoingCostsDependencies = (scenario: FTProposalScenario) =>
  scenario.dealType === 'CAPEX' ?
    []
  : (scenario.operationsAndMaintenanceBasis === 'NPV_REVENUE' && [
      'npvRevenue',
      'operationsAndMaintenance',
    ]) || ['baseCost', 'operationsAndMaintenance']

const getNPVRevenueDependencies = (scenario: FTProposalScenario) =>
  scenario.dealType === 'CAPEX' ?
    ['totalOngoingContractValue', 'upFrontPayment']
  : [
      'contractTermMonths',
      'transferRate',
      'totalOngoingContractValue',
      'upFrontPayment',
    ]

const getReferralCostDependencies = (scenario: FTProposalScenario) =>
  scenario.referralFeeBasis === 'PRE_TAX_GROSS_COST' ?
    ['referralFeeInPercentage', 'baseCost']
  : ['referralFeeInPercentage', 'preTaxContractValue']

const getSimplePaybackEnergyAndMaintenanceDependencies = (
  scenario: FTProposalScenario,
) =>
  scenario.dealType === 'CAPEX' ?
    [
      'preTaxContractValue',
      'annualEnergySavingsInDollars',
      'annualMaintenanceSavings',
    ]
  : ['netCost', 'annualEnergySavingsInDollars', 'annualMaintenanceSavings']

const getTotalOngoingContractValueDependencies = (
  scenario: FTProposalScenario,
) =>
  scenario?.dealType === 'EAAS' ?
    [
      'annualSavedKwh',
      'contractRate',
      'annualMaintenanceSavings',
      'maintenanceRetainedSavingsInPercentage',
      'contractTermMonths',
    ]
  : (scenario.referralFeeBasis === 'CONTRACT_VALUE' && [
      'baseCost',
      'vendorSalesTax',
      'contingencyCost',
      'upFrontMeasurementAndVerification',
      'insuranceFee',
      'partnerFee',
      'finalRebateAmount',
      'costReduction',
      'capexMarginSeek',
      'referralFeeInPercentage',
      'additionalMargin',
    ]) || ['netCost', 'capexMarginSeek']

const getSimplePaybackEnergyOnlyDependencies = (
  scenario: FTProposalScenario,
) =>
  scenario.dealType === 'CAPEX' ?
    ['preTaxContractValue', 'annualEnergySavingsInDollars']
  : ['netCost', 'annualEnergySavingsInDollars']

const getKwhSavingsByEULDependencies = (scenario: FTProposalScenario) =>
  scenario?.dealType === 'EAAS' ?
    ['eulConstantCalculationDTOList', 'contractTermMonths']
  : ['eulConstantCalculationDTOList']

const getSavedDollarsOnAvoidedRecPurchaseDependencies = (
  scenario: FTProposalScenario,
) =>
  scenario?.dealType === 'EAAS' ?
    ['eulConstantCalculationDTOList', 'contractTermMonths']
  : ['eulConstantCalculationDTOList']

export const getScenarioDependencies = (scenario: FTProposalScenario) => ({
  annualEnergyPayment: ['annualSavedKwh', 'contractRate'],
  baseCost: ['materialCosts', 'laborCosts', 'otherCost'],
  contingencyCost: ['baseCost', 'contingencyInPercentage'],
  contractRate: ['utilityRate', 'energyRetainedSavingsInPercentage'],
  energyContractValue: [
    'dealType',
    'annualSavedKwh',
    'contractTermMonths',
    'contractRate',
  ],
  estimatedSalesTax: ['estimatedSalesTaxInPercentage', 'preTaxContractValue'],
  grossMargin: ['preTaxContractValue', 'netCost'],
  grossMarginInPercentage: ['preTaxContractValue', 'netCost'],
  insuranceFee: getInsuranceFeeDependencies(scenario),
  internalRateOfReturn: [
    'dealType',
    'preTaxContractValue',
    'npvOngoingCosts',
    'netCost',
    'contractTermMonths',
  ],
  totalProposedFixtureCount: [
    'lightingAddFixtureCount',
    'lightingRelampBypassBallastCount',
    'lightingReplaceFixtureCount',
    'lightingRelampCount',
    'lightingRetrofitKitCount',
  ],
  annualPreBurnHours: ['preKw', 'annualPreKwh'],
  monthlyPreBurnHours: ['annualPreBurnHours'],
  monthlyPostBurnHours: ['annualPostBurnHours'],
  annualPostBurnHours: ['postKw', 'preKw', 'annualPostKwh', 'annualPreKwh'],
  maintenanceContractValue: [
    'dealType',
    'annualMaintenanceSavings',
    'contractTermMonths',
    'maintenanceRetainedSavingsInPercentage',
  ],
  preTotalMaintenanceCost: [
    'noChangeProductMaintenanceCost',
    'noChangeLabourMaintenance',
    'noChangeRecyclingCost',
    'labourCostPerHour',
    'otherProductMaintenanceCost',
    'otherRecyclingCost',
    'otherLabourMaintenance',
  ],
  postTotalMaintenanceCost: getPostTotalMaintenanceCostDependencies(scenario),
  annualMaintenanceSavings: getAnnualMaintenanceSavingsDependencies(scenario),
  maintenanceSavingsPerFixture:
    getMaintenanceSavingsPerFixtureDependencies(scenario),
  maintenanceRepairAndOperationsAnnualPayment: [
    'annualMaintenanceSavings',
    'maintenanceRetainedSavingsInPercentage',
  ],
  meterCount: [
    'totalProposedFixtureCount',
    'baseCost',
    'costPerMeter',
    'measurementAndVerificationCostEstimateInPercentage',
  ],
  netCost: [
    'baseCost',
    'partnerFee',
    'vendorSalesTax',
    'contingencyCost',
    'upFrontMeasurementAndVerification',
    'insuranceFee',
    'referralCost',
    'referral2Cost',
    'finalRebateAmount',
    'costReduction',
  ],
  netMarginInDollars: ['npvNetRevenue', 'netCost'],
  netMarginInPercentage: ['npvNetRevenue', 'netMarginInDollars'],
  npvNetRevenue: ['npvRevenue', 'npvOngoingCosts'],
  npvOngoingCosts: getNPVOngoingCostsDependencies(scenario),
  npvRevenue: getNPVRevenueDependencies(scenario),
  annualEnergySavingsInDollars: ['utilityRate', 'annualSavedKwh'],
  partnerFee: ['baseCost', 'partnerFeeInPercentage'],
  finalRebateAmount: ['initialRebateAmount', 'rebateHcInPercentage'],
  referralCost: getReferralCostDependencies(scenario),
  annualSavedKwh: ['annualPreKwh', 'annualPostKwh'],
  simplePaybackEnergyAndMaintenance:
    getSimplePaybackEnergyAndMaintenanceDependencies(scenario),
  preTaxContractValue: ['totalOngoingContractValue', 'upFrontPayment'],
  totalContractValueWithSalesTax: [
    'estimatedSalesTaxInPercentage',
    'preTaxContractValue',
    'estimatedSalesTax',
  ],
  totalOngoingContractValue: getTotalOngoingContractValueDependencies(scenario),
  ttmBillReductionInPercentage: [
    'ttmUtilityUsage',
    'annualPreKwh',
    'annualPostKwh',
  ],
  upFrontMeasurementAndVerification: ['meterCount', 'costPerMeter'],
  savingsThroughUbrHedging: [
    'annualPreKwh',
    'annualPostKwh',
    'contractRate',
    'utilityRate',
    'utilityInflationRate',
    'contractTermMonths',
  ],
  savedKW: ['preKw', 'postKw'],
  blockKwh: ['annualSavedKwh', 'contractTermMonths'],
  simplePaybackEnergyOnly: getSimplePaybackEnergyOnlyDependencies(scenario),
  totalNte: ['materialCosts', 'laborCosts', 'otherCost', 'vendorSalesTax'],
  metricTonsOfCo2AvoidedAnnually: ['annualSavedKwh', 'emissionRate'],
  metricTonsOfCo2AvoidedContractTerm: [
    'metricTonsOfCo2AvoidedAnnually',
    'contractTermMonths',
  ],
  kWhSavingsByEUL: getKwhSavingsByEULDependencies(scenario),
  metricTonsOfCo2AvoidedOverEul: ['kWhSavingsByEUL', 'emissionRate'],
  savedDollarsOnAvoidedRecPurchase:
    getSavedDollarsOnAvoidedRecPurchaseDependencies(scenario),
  percentInKwhReduction: ['annualSavedKwh', 'annualPreKwh'],
  monthlyPaymentToRedaptive: ['preTaxContractValue', 'contractTermMonths'],
})
export const getScenarioCalculations = (
  scenario: FTProposalScenario,
  site?: FTProposalSite,
): FTCalculations => {
  const dependencies = getScenarioDependencies(scenario)
  const topologicalSortDependencies = Object.keys(dependencies).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: dependencies[cur].filter((fieldName) =>
        Object.keys(scenarioCalculations).includes(fieldName),
      ),
    }),
    {},
  )
  const sortedCalculationsKeys = topologicalSort(topologicalSortDependencies)
  return sortedCalculationsKeys.result.reduce(
    (acc, cur) => ({ ...acc, [cur]: scenarioCalculations[cur] }),
    {},
  )
}
export const getPrecisionDefault = (
  fieldType: FTScenarioFieldInfoItemFieldType,
) => {
  switch (fieldType) {
    case 'currency':
    case 'percentage':
      return 2

    case 'number':
      return 4

    default:
      return 0
  }
}

const formatScenarioFeild = (name: string, value: Record<string, number>) => {
  const { fieldType, precision } = scenarioFieldInfo[name] || {}
  let newValue = value

  if (precision) {
    newValue = getNumberWithPrecision(newValue, precision)
  } else {
    switch (fieldType) {
      case 'number':
        newValue = getNumberWithPrecision(
          newValue,
          getPrecisionDefault('number'),
        )
        break

      case 'integer':
        newValue = getNumberWithPrecision(
          newValue,
          getPrecisionDefault('integer'),
        )
        break

      case 'currency':
        newValue = getNumberWithPrecision(
          newValue,
          getPrecisionDefault('currency'),
        )
        break

      case 'percentage':
        newValue = getNumberWithPrecision(
          newValue,
          getPrecisionDefault('percentage'),
        )
        break

      default:
    }
  }

  return newValue
}

const formatScenarioCalculatedValues = (scenario: FTProposalScenario) =>
  Object.keys(scenario).reduce((acc, cur) => {
    let newValue = scenario[cur]

    if (scenarioCalculations[cur] && isValueSet(newValue)) {
      newValue = formatScenarioFeild(cur, newValue)
    }

    return { ...acc, [cur]: newValue }
  }, {})

const goalSeekCalculations = (
  scenario: FTProposalScenario,
  calculations: Record<string, any>,
  inputFieldName: string,
  inputFieldValue: number,
  editedCalculatedFields?: Array<string>,
  outputFieldName: string,
  result: Record<string, number>,
  site?: FTProposalSite,
) => {
  const newValues = { ...scenario }
  newValues[inputFieldName] = inputFieldValue
  Object.keys(calculations).forEach((fieldName) => {
    if (
      !editedCalculatedFields ||
      !editedCalculatedFields?.includes(fieldName)
    ) {
      // $FlowFixMe
      const calculationsInputs = Object.keys(newValues).reduce(
        (acc, cur) => ({ ...acc, [cur]: newValues[cur] || 0 }),
        {},
      )
      newValues[fieldName] = calculations[fieldName](calculationsInputs, site)
      // If field name is Maint. Savings per Fixture, then put a check and update this input in the formulas
    }
  })
  const data = newValues
  Object.assign(result, data)
  return newValues[outputFieldName]
}

export const getScenarioEnhancedWithCalculations = (
  scenario: FTProposalScenario,
  editedCalculatedFields?: Array<string>,
  site?: FTProposalSite,
  isAddScenario?: boolean = false,
) => {
  const newValues = { ...scenario }
  const calculations = getScenarioCalculations(scenario, site)
  Object.keys(calculations).forEach((fieldName) => {
    const valuesObj = { ...newValues }
    if (
      !editedCalculatedFields ||
      !editedCalculatedFields.includes(fieldName)
    ) {
      // $FlowFixMe
      if (fieldName === 'maintenanceSavingsPerFixture' && !isAddScenario) {
        /**
         * If the maintenanceSavingsPerFixture is being calculated, then we need to pass the db value (edited) of annualMaintenanceSavings - Blue-1976
         */
        valuesObj.annualMaintenanceSavings = scenario.annualMaintenanceSavings
      }
      if (
        fieldName === 'metricTonsOfCo2AvoidedContractTerm' &&
        !isAddScenario &&
        editedCalculatedFields?.includes('metricTonsOfCo2AvoidedAnnually')
      ) {
        /**
         * If the metricTonsOfCo2AvoidedContractTerm is being calculated, then we need to pass the db value (edited) of metricTonsOfCo2AvoidedAnnually - Blue-1976
         */
        valuesObj.metricTonsOfCo2AvoidedAnnually =
          scenario.metricTonsOfCo2AvoidedAnnually
      }
      const calculationsInputs = Object.keys(valuesObj).reduce(
        (acc, cur) => ({ ...acc, [cur]: valuesObj[cur] || 0 }),
        {},
      )
      newValues[fieldName] = calculations[fieldName](calculationsInputs, site)
    }
  })
  return formatScenarioCalculatedValues(newValues)
}
export const goalSeekByFeildName = (
  scenario: FTProposalScenario,
  inputFieldName: string,
  targetNpvPercentage: number,
  editedCalculatedFields: Array<string>,
  outputFieldName: string,
  maxIterations: number,
): Record<string, any> => {
  let inputFieldValue = scenario[inputFieldName]
  inputFieldValue = inputFieldValue || 0
  const calculations = getScenarioCalculations(scenario)
  let foundMatch = false
  const resultObject = {}
  const fnParams = [
    scenario,
    calculations,
    inputFieldName,
    inputFieldValue,
    editedCalculatedFields,
    outputFieldName,
    resultObject,
  ]
  const maxStep =
    (
      [
        'energyRetainedSavingsInPercentage',
        'contractTermMonths',
        'capexMarginSeek',
      ].includes(inputFieldName)
    ) ?
      1
    : 1000
  const fn = goalSeekCalculations

  try {
    goalSeek({
      fn,
      fnParams,
      percentTolerance: 1,
      maxIterations: maxIterations || 200,
      maxStep,
      goal: targetNpvPercentage,
      independentVariableIdx: 3,
    })
    foundMatch = true // eslint-disable-next-line no-empty
  } catch (e) {
    // TODO-Error handling for errors other than interations compeleted
    console.log(e)
  }

  let targetInputValue = resultObject[inputFieldName]
  targetInputValue = formatScenarioFeild(inputFieldName, targetInputValue)
  resultObject[inputFieldName] = targetInputValue
  const formattedResult = getScenarioEnhancedWithCalculations(
    resultObject,
    editedCalculatedFields,
  )
  return [foundMatch, formattedResult]
}

export const scenarioFieldCategories: Record<
  string,
  Array<string | Array<string>>
> = {
  'Scenario Details': [
    'dealType',
    'transferRate',
    'operationsAndMaintenance',
    'operationsAndMaintenanceBasis',
    'partnerFeeInPercentage',
    'contingencyInPercentage',
    'referralFeeInPercentage',
    'referralFeeBasis',
    'insuranceForEveryHundredDollarsOfProjectCost',
    'maintenanceObligation',
    'labourCostPerHour',
    'maintenanceSavingsPerFixture',
    'utilityRate',
    'utilityInflationRate',
    'capexMarginSeek',
    'additionalMargin',
  ],
  'Lighting Details': [
    'lightingAddFixtureCount',
    'lightingRelampBypassBallastCount',
    'lightingReplaceFixtureCount',
    'lightingRelampCount',
    'lightingRetrofitKitCount',
    'lightingNoChange',
    'lightingSensorsOnly',
    'lightingRemoveFixtures',
    'totalProposedFixtureCount',
    'meterCount',
  ],
  'Cost Details': [
    [
      'materialCosts',
      'laborCosts',
      'otherCost',
      'vendorSalesTax',
      'baseCost',
      'totalNte',
    ],
    [
      'partnerFee',
      'contingencyCost',
      'referralCost',
      'referral2Cost',
      'insuranceFee',
      'costReduction',
    ],
    [
      'measurementAndVerificationSource',
      'costPerMeter',
      'measurementAndVerificationCostEstimateInPercentage',
      'measurementAndVerificationCostEstimateDollars',
      'upFrontMeasurementAndVerification',
    ],
    [
      'rebateCollection',
      'initialRebateAmount',
      'rebateHcInPercentage',
      'finalRebateAmount',
      'netCost',
    ],
  ],
  'Baseline and Proposed Data': [
    [
      'annualPreKwh',
      'preKw',
      'annualPreBurnHours',
      'ttmUtilityUsage',
      'annualPostKwh',
      'postKw',
      'annualPostBurnHours',
      'ttmBillReductionInPercentage',
      'annualSavedKwh',
      'savedKW',
      'percentInKwhReduction',
    ],
    ['monthlyPreBurnHours', 'monthlyPostBurnHours'],
  ],
  Toggles: [
    [
      'energyRetainedSavingsInPercentage',
      'maintenanceRetainedSavingsInPercentage',
      'contractTermMonths',
      'grossMarginInPercentage',
    ],
    [
      'preTaxContractValue',
      'netMarginInPercentage',
      'netMarginInDollars',
      'internalRateOfReturn',
    ],
  ],
  Savings: [
    'annualEnergySavingsInDollars',
    'annualMaintenanceSavings',
    'contractRate',
    'blockKwh',
    'upFrontPayment',
  ],
  Financial: [
    [
      'energyContractValue',
      'maintenanceContractValue',
      'preTaxContractValue',
      'totalContractValueWithSalesTax',
      'estimatedSalesTaxInPercentage',
      'estimatedSalesTax',
    ],
    [
      'npvRevenue',
      'npvOngoingCosts',
      'npvNetRevenue',
      'monthlyPaymentToRedaptive',
    ],
    [
      'grossMargin',
      'grossMarginInPercentage',
      'netMarginInDollars',
      'netMarginInPercentage',
      'simplePaybackEnergyOnly',
      'simplePaybackEnergyAndMaintenance',
      'internalRateOfReturn',
      'finalNotes',
    ],
  ],
  'Additional Savings': [
    'metricTonsOfCo2AvoidedAnnually',
    'metricTonsOfCo2AvoidedContractTerm',
    'metricTonsOfCo2AvoidedOverEul',
    'savedDollarsOnAvoidedRecPurchase',
    'savingsThroughUbrHedging',
  ],
  'Project Durations': [
    'materialOrderLeadTimeInDays',
    'manufactureDistributorOfPurchase',
    'projectDurationInMonths',
    'manufactureDistributorOfPO',
    'dateOfPurchase',
  ],
}
export const getAdjustedFieldValue = (
  fieldName: string,
  value: string | number,
) => {
  const { fieldType } = scenarioFieldInfo[fieldName]

  switch (fieldType) {
    case 'percentage':
    case 'number':
    case 'integer':
    case 'currency':
      if (isValueSet(value) && typeof parseFloat(value) === 'number') {
        const decimalLength = value.toString().split('.')[1]?.length
        return parseFloat(value).toFixed(decimalLength || 0)
      }

      return ''

    default:
      return value
  }
}
export const formatScenarioFieldValueCurrency = ({
  locale,
  currencyCode,
  value,
  precision,
}: {
  locale: string
  currencyCode: string
  value: React.ReactNode
  precision?: number
}) => {
  if (isValueSet(value)) {
    return getCurrencyFormat(locale, currencyCode, precision).format(value)
  }

  return '--'
}
export const formatScenarioFieldValuePercentage = ({
  locale,
  value,
}: {
  locale: string
  value: React.ReactNode
}) => {
  const numberFormatMinFractionDigits2 =
    getNumberFormatMinFractionDigits2(locale)

  if (isValueSet(value) && numberFormatMinFractionDigits2) {
    return `${numberFormatMinFractionDigits2.format(value)}`
  }

  return '--'
}
export const formatScenarioFieldValueEnum = ({ value }: { value: string }) =>
  (value && scenarioEnumNames[value]) || value
export const formatScenarioFieldValueBoolean = ({
  value,
}: {
  value: string | number
}) => (value ? 'Yes' : 'No')
const scenariosMockData = new Array(20)
  .fill(null)
  .map<Record<string, number>>((_, i) =>
    Object.keys(mockScenario).reduce((acc, cur) => {
      const { fieldType } = scenarioFieldInfo[cur] || {}
      const newValue =
        (cur === 'id' && `${i}`) ||
        (cur === 'opportunityId' &&
          `R000${Math.round(Math.random() * 10000)}`) ||
        (cur === 'salesforceSiteId' && stringGen(18)) ||
        (cur === 'name' &&
          loremIpsum({
            avgWordsPerSentence: 6,
            avgSentencesPerParagraph: 1,
            startWithLoremIpsum: false,
            random: true,
          })[0]
            .split(' ')
            .slice(0, 8)
            .join(' ')) ||
        (cur === 'status' &&
          ['PENDING', 'ARCHIVED'][Math.floor(Math.random() * 2)]) ||
        (fieldType === 'percentage' &&
          Math.round(Math.random() * 10000) / 100) ||
        (fieldType === 'integer' && Math.round(Math.random() * 1000)) ||
        (fieldType === 'number' &&
          Math.round(Math.random() * 10000000) / 100) ||
        (fieldType === 'currency' &&
          Math.round(Math.random() * 1000000) / 100) ||
        (fieldType === 'boolean' && !!Math.round(Math.random())) ||
        (scenarioEnumFieldInfo[cur] ?
          scenarioEnumFieldInfo[cur][
            Math.floor(Math.random() * scenarioEnumFieldInfo[cur].length)
          ]
        : false) ||
        mockScenario[cur]
      return { ...acc, [cur]: newValue }
    }, {}),
  )
scenariosMockData.push(mockScenario)
export const defaultScenario = getScenarioEnhancedWithCalculations(
  Object.keys(scenarioFieldInfo).reduce((acc, cur) => {
    const { fieldType } = scenarioFieldInfo[cur]
    let newValue = ''

    switch (fieldType) {
      case 'enum':
        ;[newValue] = scenarioEnumFieldInfo[cur]
        break

      case 'boolean':
        newValue = true
        break

      default:
    }

    return { ...acc, [cur]: newValue }
  }, {}),
)

const prepareFormValuesForApi = (values) => {
  const newValues = {}
  const { file } = values
  Object.keys(values).forEach((fieldName) => {
    // $FlowFixMe
    const value = values[fieldName]

    if (
      // If this is a numeric field...
      scenarioFieldInfo[fieldName] &&
      ['currency', 'number', 'integer'].includes(
        scenarioFieldInfo[fieldName].fieldType,
      )
    ) {
      // If the field has a value, convert to float, otherwise set it to zero.
      newValues[fieldName] = value ? parseFloat(value) : 0
    } else if (
      // If this is a string field...
      scenarioFieldInfo[fieldName] &&
      scenarioFieldInfo[fieldName].fieldType === 'string'
    ) {
      // Only include the field if there is a value set.
      if (isValueSet(value)) {
        newValues[fieldName] = value
      } else {
        // for the null string values
        newValues[fieldName] = null
      }
    } else if (
      // If this is the ID field...
      fieldName === 'id' || // Or another field type...
      scenarioFieldInfo[fieldName]
    ) {
      // Use the original value.
      newValues[fieldName] = value
    }
  })
  return file ? { ...newValues, file } : newValues
}

function getCreateScenarioPayload(values) {
  const { file } = values
  const formData = new FormData()
  const json = JSON.stringify(values)
  const blob = new Blob([json], {
    type: 'application/json',
  })
  // $FlowFixMe
  formData.append('body', blob)

  if (file) {
    // $FlowFixMe
    formData.append('file', file)
  }

  return formData
}

// Action Types
const types = {
  CREATE_PROPOSAL_SCENARIO: 'CREATE_PROPOSAL_SCENARIO',
  CREATE_PROPOSAL_SCENARIO_SUCCESS: 'CREATE_PROPOSAL_SCENARIO_SUCCESS',
  CREATE_PROPOSAL_SCENARIO_ERROR: 'CREATE_PROPOSAL_SCENARIO_ERROR',
  FETCH_PROPOSAL_SCENARIOS: 'FETCH_PROPOSAL_SCENARIOS',
  FETCH_PROPOSAL_SCENARIOS_SUCCESS: 'FETCH_PROPOSAL_SCENARIOS_SUCCESS',
  FETCH_PROPOSAL_SCENARIOS_ERROR: 'FETCH_PROPOSAL_SCENARIOS_ERROR',
  UPDATE_PROPOSAL_SCENARIO: 'UPDATE_PROPOSAL_SCENARIO',
  UPDATE_PROPOSAL_SCENARIO_SUCCESS: 'UPDATE_PROPOSAL_SCENARIO_SUCCESS',
  UPDATE_PROPOSAL_SCENARIO_ERROR: 'UPDATE_PROPOSAL_SCENARIO_ERROR',
  UPDATE_PROPOSAL_SCENARIO_STATUS: 'UPDATE_PROPOSAL_SCENARIO_STATUS',
  UPDATE_PROPOSAL_SCENARIO_STATUS_SUCCESS:
    'UPDATE_PROPOSAL_SCENARIO_STATUS_SUCCESS',
  UPDATE_PROPOSAL_SCENARIO_STATUS_ERROR:
    'UPDATE_PROPOSAL_SCENARIO_STATUS_ERROR',
  FETCH_SALES_TAX_RATE: 'FETCH_SALES_TAX_RATE',
  FETCH_SALES_TAX_RATE_SUCCESS: 'FETCH_SALES_TAX_RATE_SUCCESS',
  FETCH_SALES_TAX_RATE_ERROR: 'FETCH_SALES_TAX_RATE_ERROR',
}
export const actions = {
  createProposalScenario: (props: FTCreateProposalScenarioAction) => ({
    type: types.CREATE_PROPOSAL_SCENARIO,
    ...props,
  }),
  fetchProposalScenarios: (props: FTFetchProposalScenariosAction) => ({
    type: types.FETCH_PROPOSAL_SCENARIOS,
    ...props,
  }),
  updateProposalScenario: (props: FTUpdateProposalScenarioAction) => ({
    type: types.UPDATE_PROPOSAL_SCENARIO,
    ...props,
  }),
  updateProposalScenarioStatus: (
    props: FTUpdateProposalScenarioStatusAction,
  ) => ({
    type: types.UPDATE_PROPOSAL_SCENARIO_STATUS,
    ...props,
  }),
  fetchSalesTaxRate: (props: FTFetchSalesTaxRateAction) => ({
    type: types.FETCH_SALES_TAX_RATE,
    ...props,
  }),
}
const initialState = {
  byId: {},
  items: [],
  salesTaxFromAvalara: 0,
  meta: {
    error: '',
    loading: false,
    type: '',
  },
}
const entitySchema = new schema.Entity('proposalScenarios')

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalScenario> = Object.values(
    action.payload.entities.proposalScenarios,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalScenarios }
}

function byId(state = initialState.byId, action: FTRouterAction) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_SCENARIOS:
      return {}

    case types.CREATE_PROPOSAL_SCENARIO_SUCCESS:
    case types.FETCH_PROPOSAL_SCENARIOS_SUCCESS:
    case types.UPDATE_PROPOSAL_SCENARIO_SUCCESS:
    case types.UPDATE_PROPOSAL_SCENARIO_STATUS_SUCCESS:
    case types.FETCH_SALES_TAX_RATE_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action: FTRouterAction) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_SCENARIOS:
      return initialState.items

    case types.CREATE_PROPOSAL_SCENARIO_SUCCESS:
    case types.FETCH_PROPOSAL_SCENARIOS_SUCCESS:
    case types.UPDATE_PROPOSAL_SCENARIO_SUCCESS:
    case types.UPDATE_PROPOSAL_SCENARIO_STATUS_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action: FTRouterAction) {
  switch (action.type) {
    case types.CREATE_PROPOSAL_SCENARIO:
    case types.FETCH_PROPOSAL_SCENARIOS:
    case types.UPDATE_PROPOSAL_SCENARIO:
    case types.UPDATE_PROPOSAL_SCENARIO_STATUS:
      return { ...state, error: '', loading: true }

    case types.CREATE_PROPOSAL_SCENARIO_ERROR:
    case types.FETCH_PROPOSAL_SCENARIOS_ERROR:
    case types.UPDATE_PROPOSAL_SCENARIO_ERROR:
    case types.UPDATE_PROPOSAL_SCENARIO_STATUS_ERROR:
    case types.FETCH_SALES_TAX_RATE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        type: action.type,
      }

    case types.CREATE_PROPOSAL_SCENARIO_SUCCESS:
    case types.FETCH_PROPOSAL_SCENARIOS_SUCCESS:
    case types.UPDATE_PROPOSAL_SCENARIO_SUCCESS:
    case types.UPDATE_PROPOSAL_SCENARIO_STATUS_SUCCESS:
      return { ...state, error: '', loading: false }

    case types.FETCH_SALES_TAX_RATE_SUCCESS:
      return {
        ...state,
        salesTaxFromAvalara: action?.payload?.result[0],
        error: '',
        loading: false,
      }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectProposalScenarioEntity = (
  state: FTState,
): FTProposalScenarioEntityState => state.entities.proposalScenarios
const selectProposalScenario = (
  state: FTState,
  id: string,
): FTProposalScenario => state.entities.proposalScenarios.byId[id]
const API = {
  createProposalScenario: (params: FTCreateProposalScenarioAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve({ ...params, id: 1234 }).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 500)
          }),
      )
    }

    const preparedValues = prepareFormValuesForApi(params)
    const values = getCreateScenarioPayload(preparedValues)
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/scenario`
    return axios
      .post(url, values, {
        headers: defaultHeaders(API_SOURCE.scenario),
      })
      .then(({ data }: { data: FTProposalScenarioResponse }) => data)
      .catch(handleAxiosErrorCreateScenario)
  },
  fetchProposalScenarios: (params: FTFetchProposalScenariosAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(scenariosMockData).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 200)
          }),
      )
    }

    const query = queryStringify(params)
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/scenario?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalScenarioResponse }) => data)
      .catch(handleAxiosError)
  },
  updateProposalScenario: (params: FTUpdateProposalScenarioAction) => {
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/scenario/${
      params.id
    }`
    const preparedValues = prepareFormValuesForApi(params)
    return axios
      .put(url, preparedValues, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalScenarioResponse }) => data)
      .catch(handleAxiosError)
  },
  updateProposalScenarioStatus: ({
    id,
    ...params
  }: FTUpdateProposalScenarioStatusAction) => {
    const url = `${consoleBaseUrl()}/proposal/api/opportunity/scenario/${id}/status`
    return axios
      .patch(url, params, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalScenarioResponse }) => data)
      .catch(handleAxiosError)
  },
  fetchSalesTaxRate: (params: FTFetchSalesTaxRateAction) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(scenariosMockData).then(
        (data) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(data), 200)
          }),
      )
    }

    const url = `${consoleBaseUrl()}/proposal/api/opportunity/${
      params.opportunityId
    }/tax-rate`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalScenarioResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* createProposalScenarioSaga({
  type,
  ...params
}: FTCreateProposalScenarioAction & {
  type: string
}): Generator<unknown, void, FTProposalScenarioResponse> {
  try {
    const response: FTProposalScenarioResponse = yield call(
      API.createProposalScenario,
      params,
    )
    const normalized = normalize([response], [entitySchema])
    yield put({
      type: types.CREATE_PROPOSAL_SCENARIO_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.CREATE_PROPOSAL_SCENARIO_ERROR, e)
  }
}

function* fetchProposalScenariosSaga({
  type,
  ...params
}: FTFetchProposalScenariosAction & {
  type: string
}): Generator<unknown, void, FTProposalScenariosResponse> {
  try {
    const response: FTProposalScenariosResponse = yield call(
      API.fetchProposalScenarios,
      params,
    )
    const normalized = normalize(response, [entitySchema])
    yield put({
      type: types.FETCH_PROPOSAL_SCENARIOS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_SCENARIOS_ERROR, e)
  }
}

function* updateProposalScenarioSaga({
  type,
  ...params
}: FTUpdateProposalScenarioAction & {
  type: string
}): Generator<unknown, void, FTProposalScenarioResponse> {
  try {
    let response: FTProposalScenarioResponse

    if (isVariantActive('3300mock')) {
      const scenario = yield select(selectProposalScenario, params.id)

      const mockUpdateApi = () =>
        Promise.resolve({ ...scenario, ...params }).then(
          (data) =>
            new Promise((resolve) => {
              setTimeout(() => resolve(data), 500)
            }),
        )

      response = yield call(mockUpdateApi)
    } else {
      response = yield call(API.updateProposalScenario, params)
    }

    const normalized = normalize([response], [entitySchema])
    yield put({
      type: types.UPDATE_PROPOSAL_SCENARIO_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PROPOSAL_SCENARIO_ERROR, e)
  }
}

function* updateProposalScenarioStatusSaga({
  type,
  ...params
}: FTUpdateProposalScenarioStatusAction & {
  type: string
}): Generator<unknown, void, FTProposalScenarioResponse> {
  try {
    let response: FTProposalScenarioResponse

    if (isVariantActive('3300mock')) {
      const scenario = yield select(selectProposalScenario, params.id)

      const mockUpdateApi = () =>
        Promise.resolve({ ...scenario, ...params }).then(
          (data) =>
            new Promise((resolve) => {
              setTimeout(() => resolve(data), 500)
            }),
        )

      response = yield call(mockUpdateApi)
    } else {
      response = yield call(API.updateProposalScenarioStatus, params)
    }

    const normalized = normalize([response], [entitySchema])
    yield put({
      type: types.UPDATE_PROPOSAL_SCENARIO_STATUS_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PROPOSAL_SCENARIO_STATUS_ERROR, e)
  }
}

function* fetchSalesTaxRateSaga({
  type,
  ...params
}: FTFetchSalesTaxRateAction & {
  type: string
}): Generator<unknown, void, FTSalesTaxRateResponse> {
  try {
    const response: FTSalesTaxRateResponse = yield call(
      API.fetchSalesTaxRate,
      params,
    )
    const normalized = normalize(response, [entitySchema])
    yield put({
      type: types.FETCH_SALES_TAX_RATE_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_SALES_TAX_RATE_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.CREATE_PROPOSAL_SCENARIO, createProposalScenarioSaga),
  takeLatest(types.FETCH_PROPOSAL_SCENARIOS, fetchProposalScenariosSaga),
  takeLatest(types.UPDATE_PROPOSAL_SCENARIO, updateProposalScenarioSaga),
  takeLatest(
    types.UPDATE_PROPOSAL_SCENARIO_STATUS,
    updateProposalScenarioStatusSaga,
  ),
  takeLatest(types.FETCH_SALES_TAX_RATE, fetchSalesTaxRateSaga),
]
