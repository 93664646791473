export default () => (
  <svg
    width='14'
    height='9'
    viewBox='0 0 14 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 1.31323L7 7.29323L13 1.31323'
      stroke='#3E5CAA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
