import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeEvery } from 'redux-saga/effects'

import { makeActionTypes } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'

type FTFetchMeterMostRecentMeasurementDataAction = {
  meterId: string
}
export type FTMeterMostRecentMeasurementData = {
  amperage: string
  ctType: string
  circuitId: string
  meterChannel: string
  panelName: string
  phase: string
  phaseAngle: string
  powerFactor: string
  voltage: string
  wattage: string
}
export type FTMeterMostRecentMeasurementDataMeta = {
  error: string
  loading: boolean
}
type FTMetersMostRecentMeasurementDataEntity = {
  byId: Record<string, FTMeterMostRecentMeasurementData>
  meta: Record<string, FTMeterMostRecentMeasurementDataMeta>
}
type FTMetersMostRecentMeasurementDataState = {
  metersMostRecentMeasurementData: FTMetersMostRecentMeasurementDataEntity
}
// Action Types
export const types = {
  ...makeActionTypes('GET_METER_MOST_RECENT_MEASUREMENT_DATA'),
}
// Reducers
export const initialState = {
  byId: {},
  meta: {},
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.GET_METER_MOST_RECENT_MEASUREMENT_DATA:
      return state

    case types.GET_METER_MOST_RECENT_MEASUREMENT_DATA_SUCCESS:
      return { ...state, [action.payload.meterId]: action.payload.results }

    case types.GET_METER_MOST_RECENT_MEASUREMENT_DATA_ERROR:
      return state

    case types.RESET_RT_FORM:
      return initialState.byId

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.GET_METER_MOST_RECENT_MEASUREMENT_DATA:
      return {
        ...state,
        [action.params.meterId]: {
          loading: true,
          error: '',
        },
      }

    case types.GET_METER_MOST_RECENT_MEASUREMENT_DATA_SUCCESS:
      return {
        ...state,
        [action.payload.meterId]: {
          loading: false,
          error: '',
        },
      }

    case types.GET_METER_MOST_RECENT_MEASUREMENT_DATA_ERROR:
      return {
        ...state,
        [action.payload.meterId]: {
          loading: false,
          error: action.error,
        },
      }

    case types.RESET_RT_FORM:
      return initialState.meta

    default:
      return state
  }
}

export default combineReducers({
  byId,
  meta,
}) // Action Creators

export const actions = {
  getMeterMostRecentMeasurementData: (
    params: FTFetchMeterMostRecentMeasurementDataAction,
  ) => ({
    type: types.GET_METER_MOST_RECENT_MEASUREMENT_DATA,
    params,
  }),
}
// Selectors
export const selectMeterMostRecentMeasurementData = (
  state: FTMetersMostRecentMeasurementDataState,
  id: string,
): FTMeterMostRecentMeasurementData =>
  state.metersMostRecentMeasurementData.byId[id] || []
export const selectMeterMostRecentMeasurementDataMeta = (
  state: FTMetersMostRecentMeasurementDataState,
  id: string,
): FTMeterMostRecentMeasurementDataMeta =>
  state.metersMostRecentMeasurementData.meta[id] || {
    loading: true,
    error: '',
  }
// API
export class API {
  static fetchMeterMostRecentMeasurementData({
    meterId,
  }: FTFetchMeterMostRecentMeasurementDataAction) {
    const url = `${consoleApiUrl()}/meters/${meterId}/most-recent-measurement-data`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

// Sagas
function* getMeterMostRecentMeasurementDataSaga({
  params,
}: {
  params: FTFetchMeterMostRecentMeasurementDataAction
}): Generator<any, void, any> {
  try {
    const results = yield call(API.fetchMeterMostRecentMeasurementData, params)
    const { meterId } = params
    yield put({
      type: types.GET_METER_MOST_RECENT_MEASUREMENT_DATA_SUCCESS,
      payload: { ...results, meterId },
    })
  } catch (e) {
    yield handleSagaError(types.GET_METER_MOST_RECENT_MEASUREMENT_DATA_ERROR, e)
  }
}

export const sagas = [
  takeEvery(
    types.GET_METER_MOST_RECENT_MEASUREMENT_DATA,
    getMeterMostRecentMeasurementDataSaga,
  ),
]
