import styled from 'styled-components'

import LoginContainer from '../containers/LoginContainer'
import SimpleFooterContainer from '../containers/SimpleFooterContainer'
import './LoginPage.css'
import logo from '../redaptive-logo.svg'
import type { FTHistory, FTWithRouter } from '../types'
import Button from '../components/Button'
import ForgotPasswordFormContainer from '../containers/ForgotPasswordFormContainer'

type FTProps = FTWithRouter
export const HelpTitleStyles = styled.div`
  color: #0193d7;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
`
const HelpDescriptionStyles = styled.div`
  font-size: 14px;
  text-align: center;

  a {
    color: #337ab7;
  }
`
const HelpButtonStyles = styled.div`
  margin-top: 30px;
  text-align: center;

  button {
    line-height: normal;
  }
`

const Help = (props: { history: FTHistory }) => (
  <div className='LoginContainer'>
    <div className='LoginForm'>
      <div className='form'>
        <div className='logo'>
          <img src={logo} alt='Redaptive' />
        </div>
        <HelpTitleStyles>Need help?</HelpTitleStyles>
        <HelpDescriptionStyles>
          {
            'Please contact your Redaptive representative for assistance, or email '
          }
          <a href='mailto:info@redaptiveinc.com'>info@redaptiveinc.com</a>
        </HelpDescriptionStyles>
        <HelpButtonStyles>
          <Button onClick={props.history.goBack} primary>
            Back
          </Button>
        </HelpButtonStyles>
      </div>
    </div>
  </div>
)

const LoginPage = (props: FTProps) => {
  const { match } = props
  const { path } = match
  return (
    <div className='LoginPage'>
      {path === '/login' && <LoginContainer />}
      {path === '/help' && <Help history={props.history} />}
      {path === '/forgot' && <ForgotPasswordFormContainer />}
      <SimpleFooterContainer />
    </div>
  )
}

export default LoginPage
