import { colors } from '../../../../utils/themes'

const getColorForDataAvailability = (value: number) => {
  if (!value) return ''
  if (value < 95) return colors.red2
  if (value >= 95 && value < 99) return colors.orange
  if (value >= 99) return colors.green2
  return ''
}

export default getColorForDataAvailability
