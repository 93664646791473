import moment from 'moment'

import { TSEnergyStarSubmissions } from '../../../../../ducks/energyStarIntegration/types'

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1)
}
const tableDataMapper = (items: TSEnergyStarSubmissions[]) =>
  items.map((item) => {
    const tempItem = { ...item }
    tempItem.submissionDate =
      moment(tempItem.submissionDate?.split('T'), 'YYYY-MM-DD').format(
        'MM/DD/YYYY',
      ) ?? '-'
    tempItem.dataAggregation =
      tempItem.dataAggregation === 'wholeSite' ?
        'Whole Site'
      : `Meter-${tempItem.meterName}`

    return { ...tempItem }
  })

export default tableDataMapper
