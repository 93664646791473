export const meterTableHeaders = [
  {
    id: 'macAddress',
    label: 'Meter Mac',
  },
  {
    id: 'modified',
    label: 'Submitted Date',
  },
  {
    id: 'history',
    label: 'Submission History',
  },
  {
    id: 'panelNames',
    label: 'Panel Name',
  },
  {
    id: 'createdBy',
    label: 'Submitted By',
  },
  {
    id: 'isAssignedToSite',
    label: 'Assigned to Site',
  },
  {
    id: 'configAcceptedDate',
    label: 'Config Accepted',
  },
  {
    id: 'preConfigTestStatus',
    label: 'Pre-Config Tests',
  },
  {
    id: 'postConfigTestStatus',
    label: 'Post-Config Tests',
  },
  {
    id: 'evVerified',
    label: 'EV Verified',
  },
]

export const defaultMeterInstallWidths = {
  macAddress: {
    minWidth: 134,
    maxWidth: 135,
  },
  modified: {
    minWidth: 250,
    maxWidth: 250,
  },
  history: {
    minWidth: 170,
    maxWidth: 170,
  },
  panelNames: {
    minWidth: 190,
    maxWidth: 190,
  },
  createdBy: {
    minWidth: 230,
    maxWidth: 230,
  },
  configAcceptedDate: {
    minWidth: 250,
    maxWidth: 250,
  },
  isAssignedToSite: {
    minWidth: 160,
    maxWidth: 160,
  },
  evVerified: {
    minWidth: 120,
    maxWidth: 120,
  },
  preConfigTestStatus: {
    minWidth: 150,
    maxWidth: 150,
  },
  postConfigTestStatus: {
    minWidth: 150,
    maxWidth: 150,
  },
}
