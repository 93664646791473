/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import {
  ButtonsStyles,
  SuccessArrowIconStyles,
  UploadSuccessStyles,
} from './UploadModal'
import Button from '../../../components/Button'
import FormSection from '../../../components/FormSection'
import ArrowUpwardIcon from '../../../components/Icons/ArrowUpwardIcon'
import SuccessArrowIcon from '../../../components/Icons/SuccessArrowIcon'
import Input from '../../../components/Input'
import Spinner from '../../../components/Spinner'
import {
  actions as uploadAuditVendorActions,
  FTUploadFileAction,
  selectAuditVendorEntity,
} from '../../../ducks/auditVendorValidations'
import {
  ArrowUpwardIconStyles,
  DropzoneStyles,
  FilePathStyled,
} from '../LightingAsBuiltsManagerPage'

const FormSectionStyled = styled.div``

const SiteIdWrapperStyled = styled.div`
  margin: 20px 0;
  label {
    font-weight: bold;
  }
`

const ListStyled = styled.ul`
  line-height: 2;
  margin-bottom: 26px;
  padding: 15px;
`

type FTProps = {
  actions: {
    uploadAuditFile: (params: FTUploadFileAction) => null
    resetAuditFileState: () => null
  }
  auditFileUploadResponse: string
  error: string
  isUploading: boolean
  onClose: (data: { refetchList: boolean }) => void
  showPopup: boolean
  closePopup: any
  updateClosePopupVisibility: any
}

const AuditVendorValidationComponent = (props: FTProps) => {
  const {
    actions,
    error,
    auditFileUploadResponse = '',
    isUploading,
    closePopup,
    updateClosePopupVisibility,
  } = props
  const [auditVendorFilename, setAuditVendorFilename] = useState('')
  const [validatedAuditVendorFilename, setValidatedAuditVendorFilename] =
    useState('')
  const [isDownload, setIsDownload] = useState(false)
  const siteIdRef = useRef()

  const downloadVendorAuditFile = () => {
    const blob = new Blob([auditFileUploadResponse], {
      type: 'multipart/form-data',
    })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = validatedAuditVendorFilename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setIsDownload(true)
  }

  const handleAuditVendorReset = () => {
    setAuditVendorFilename('')
    actions.resetAuditFileState()
  }

  const handleAuditData = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xlsm')) {
          const fileExt = file.name.slice(-5)
          const fileName = file.name.slice(0, -5)
          setAuditVendorFilename(file.name)
          setValidatedAuditVendorFilename(`${fileName}_validated${fileExt}`)
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {
    acceptedFiles: acceptedFilesAudit,
    getInputProps: getInputPropsAudit,
    getRootProps: getRootPropsAudit,
    isDragAccept: isDragAcceptAudit,
    isDragActive: isDragActiveAudit,
    isDragReject: isDragRejectAudit,
    open: openAuditVendor,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleAuditData,
  })

  const handleAuditVendorUpload = () => {
    actions.uploadAuditFile({
      file: acceptedFilesAudit[0],
      siteId: siteIdRef.current.value,
    })
  }

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      handleAuditVendorReset()
    }
  }, [])

  useEffect(() => {
    if (isUploading) {
      updateClosePopupVisibility(false)
    } else {
      updateClosePopupVisibility(true)
    }
  }, [isUploading, updateClosePopupVisibility])

  return (
    <FormSection>
      <FormSectionStyled>
        {!!error && closePopup()}
        {!error && (
          <>
            <b>Vendor Audit file for Validation</b>
            <ListStyled>
              <li>
                Upload the Vendor Audit file here for validation. Sample audit
                template can be downloaded
                <Link to='#'> here.</Link>
              </li>
              <li>The upload file must be a .xlsx.</li>
              <li>
                DO NOT CLOSE THIS WINDOW while upload is in progress, otherwise
                you will lose access to the upload status.
              </li>
            </ListStyled>
            <SiteIdWrapperStyled>
              <label>Site ID</label> (Optional)
              <Input className='audit-vendor-site-id' inputRef={siteIdRef} />
            </SiteIdWrapperStyled>
            <DropzoneStyles
              {...getRootPropsAudit({
                isDragActiveAudit,
                isDragAcceptAudit,
                isDragRejectAudit,
              })}
            >
              <input name='file' {...getInputPropsAudit()} />
              {isUploading && (
                <div>
                  <Spinner inline size='tiny' /> <b>Upload in progress...</b>
                </div>
              )}
              {!isUploading && !auditFileUploadResponse && (
                <ArrowUpwardIconStyles>
                  <ArrowUpwardIcon />
                </ArrowUpwardIconStyles>
              )}
              {!!auditFileUploadResponse &&
                !isDownload &&
                !isUploading &&
                auditVendorFilename && (
                  <UploadSuccessStyles>
                    <SuccessArrowIconStyles>
                      <SuccessArrowIcon />
                      <b>
                        &nbsp; The validation is completed, please download the
                        file
                      </b>
                    </SuccessArrowIconStyles>
                  </UploadSuccessStyles>
                )}
              {isDownload && (
                <SuccessArrowIconStyles>
                  <SuccessArrowIcon />
                  <b>
                    The validation is completed and the file has been
                    downloaded.
                  </b>
                </SuccessArrowIconStyles>
              )}
              <FilePathStyled>
                {auditVendorFilename || 'Drag file to upload'}
              </FilePathStyled>
              <ButtonsStyles>
                {auditVendorFilename &&
                  !isUploading &&
                  !isDownload &&
                  !!auditFileUploadResponse && (
                    <Button primary onClick={downloadVendorAuditFile}>
                      Download
                    </Button>
                  )}
                {auditVendorFilename &&
                  !auditFileUploadResponse &&
                  !isUploading && (
                    <Button onClick={handleAuditVendorReset}>Cancel</Button>
                  )}
                {auditVendorFilename &&
                  !auditFileUploadResponse &&
                  !isUploading && (
                    <Button primary onClick={handleAuditVendorUpload}>
                      Upload
                    </Button>
                  )}
                {!auditVendorFilename && !auditFileUploadResponse && (
                  <Button primary onClick={openAuditVendor}>
                    Choose File
                  </Button>
                )}
                {isDownload && (
                  <Button color='#7BCC76' onClick={closePopup}>
                    Done
                  </Button>
                )}
              </ButtonsStyles>
            </DropzoneStyles>
          </>
        )}
      </FormSectionStyled>
    </FormSection>
  )
}

const mapStateToProps = (state) => {
  const entity = selectAuditVendorEntity(state)
  const { auditVendor } = entity
  const {
    uploadResponse: auditFileUploadResponse,
    meta: { error, isUploading },
  } = auditVendor
  return {
    auditFileUploadResponse,
    isUploading,
    error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(uploadAuditVendorActions, dispatch) },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditVendorValidationComponent),
)
