import { Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

import type { FTRouterLocation } from '../types'
import '../types'

const REDIRECT_URL = '/'
const { NODE_ENV, VITE_ENABLE_TRACKING } = import.meta.env
type FTProps = {
  userId: string
} & FTRouterLocation
// TODO: RDP-4547, rather than checking trackingEnabled everywhere, split up
// into multiple components and only render them if trackingEnabled is true
const trackingEnabled = VITE_ENABLE_TRACKING && NODE_ENV !== 'development'

class Analytics extends Component<FTProps> {
  constructor(props) {
    super(props)

    if (!trackingEnabled) {
      return
    }

    ReactGA.initialize('UA-91795215-4')
  }

  componentDidMount() {
    if (!trackingEnabled) {
      return
    }

    const { userId } = this.props

    // If the user is already logged in, userId is already set
    if (userId) {
      ReactGA.set({
        userId,
      })
    }

    // Submit initial tracking data when component mounts
    this.sendPageChange(
      this.props.location.pathname,
      this.props.location.search,
    )
  }

  componentDidUpdate({ location: prevLocation, userId: prevUserId }) {
    if (!trackingEnabled) {
      return
    }

    const { location, userId } = this.props

    if (!prevUserId && userId) {
      // When the user logs in, userId will become set
      ReactGA.set({
        userId,
      })
    } else if (prevUserId && !userId) {
      // When the user logs out, clear userId
      ReactGA.set({
        userId: undefined,
      })
    }

    const { pathname: prevPath, search: prevSearch } = prevLocation
    const { pathname, search } = location

    // Submit tracking data only if the pathname or query params have changed.
    if (pathname !== prevPath || search !== prevSearch) {
      this.sendPageChange(pathname, search)
    }
  }

  sendPageChange = (pathname: string, search: string = '') => {
    // Only send tracking data if we're in a production build and the pathname
    // is not the REDIRECT_URL
    if (!trackingEnabled || pathname === REDIRECT_URL) {
      return
    }

    const page = pathname + search
    ReactGA.set({
      page,
    })
    ReactGA.pageview(page)
  }

  render() {
    return null
  }
}

export default connect(({ user: { userId } }) => ({
  userId,
}))(Analytics)
