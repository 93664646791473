/* eslint-disable react/require-default-props */
import moment from 'moment'
import { ComponentProps, ComponentType, ReactElement, useState } from 'react'
import styled from 'styled-components'

import { Linkify } from './Linkify'
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_FORMAT_SIMPLE_WITH_TIME,
} from '../ducks/utils'
import { zIndices } from '../utils'
import { colors } from '../utils/themes'

export type FTCommentProps = {
  id: string
  postedTime: string
  comment: string
  title: string
  subTitle: string
  issueOwners: Array<string>
}
type FTProps = {
  comments: Array<FTCommentProps>
  handleCommentSubmit: (...args: Array<any>) => any
  loading: boolean
  Header?: () => ReactElement<ComponentProps<any>, any>
  isReadOnly?: boolean
  onClickOutside: (...args: Array<any>) => any
  activityIconClickedPosition: Record<string, any>
}
const CommentInfoWrapperStyled = styled.div`
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  width: 85%;
  margin-top: 15px;
  margin-right: 15px;
`
const StatusLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const OuterCircleStyled = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid ${colors.blue};
  height: 10px;
  margin: 10px;
  position: relative;
  width: 10px;
`
const InnerCircleStyled = styled.div`
  background-color: ${colors.blue};
  border-radius: 50%;
  display: none;
  height: 5px;
  transform: translate(30%, 30%);
  width: 5px;
`
const UpperVerticalLineStyled = styled.div`
  border-left: 2px solid ${colors.blue3};
  color: ${colors.blue3};
  margin: auto;
  height: 25px;
`
const BottomVerticalLineStyled = styled.div`
  border-left: 2px solid ${colors.blue3};
  color: ${colors.blue3};
  margin: auto;
  flex-grow: 1;
`
const CommentsPopupWrapperStyled: ComponentType<{
  show: boolean
  activityIconClickedPosition: Record<string, any>
}> = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #dadce0;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  height: 300px;
  position: fixed;
  right: 70px;
  top: ${({ activityIconClickedPosition }) =>
    activityIconClickedPosition.height - activityIconClickedPosition.y > 300 ?
      `${activityIconClickedPosition.y}px`
    : 'unset'};
  bottom: ${({ activityIconClickedPosition }) =>
    activityIconClickedPosition.height - activityIconClickedPosition.y > 300 ?
      'unset'
    : '10px'};
  width: 300px;
  z-index: ${zIndices.CommentsPopup};
`
const CommentsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
const CommentWrapperStyled = styled.div`
  display: flex;

  &:first-child {
    ${InnerCircleStyled} {
      display: block;
    }
    ${UpperVerticalLineStyled} {
      visibility: hidden;
    }
  }
  &:last-child {
    border-bottom: 0;

    ${BottomVerticalLineStyled} {
      visibility: hidden;
    }
  }
`
const CommentHeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
const TitleStyled = styled.div`
  color: ${colors.blue5};
  font-style: normal;
  font-weight: 700;
`
const DateStyled = styled.div`
  color: ${colors.blue3};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`
const CommentDescriptionStyled = styled.div``
const SubTitleStyled = styled.div`
  color: ${colors.blue};
  font-style: italic;
  font-weight: 400;
  margin-bottom: 5px;
`
const CommentStyled = styled.div`
  color: ${colors.blue5};
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
`
const InputStyled = styled.input`
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e0e0e0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  box-shadow: 0 1px 3px 2px rgba(224, 224, 224, 0.4);
  height: 44px;
  padding-left: 15px;
`
export const FullScreenBgAlphaLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff5c;
  z-index: -1;
`

const dateFormat = (date) =>
  moment(date, DATE_FORMAT_DATA_API_RESPONSE).format(
    DATE_FORMAT_SIMPLE_WITH_TIME,
  )

const CommentsPopup = ({
  comments,
  handleCommentSubmit,
  loading,
  Header,
  isReadOnly,
  onClickOutside,
  activityIconClickedPosition,
}: FTProps) => {
  const placeholderText = comments.length ? 'Reply' : 'Add a new note'
  const [commentText, setCommentText] = useState('')

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleCommentSubmit(commentText)
      setCommentText('')
    }
  }

  return (
    <CommentsPopupWrapperStyled
      show={!loading}
      activityIconClickedPosition={activityIconClickedPosition}
    >
      <FullScreenBgAlphaLayer onClick={onClickOutside} />
      {Header && <Header />}
      {!loading && (
        <CommentsWrapperStyled>
          {comments.map(
            ({ id, title, subTitle, postedTime, comment, issueOwners }) => (
              <CommentWrapperStyled key={id}>
                <StatusLineWrapper>
                  <UpperVerticalLineStyled />
                  <OuterCircleStyled>
                    <InnerCircleStyled />
                  </OuterCircleStyled>
                  <BottomVerticalLineStyled />
                </StatusLineWrapper>
                <CommentInfoWrapperStyled>
                  <CommentHeaderWrapperStyled>
                    <TitleStyled>{title}</TitleStyled>
                    <DateStyled>{dateFormat(postedTime)}</DateStyled>
                  </CommentHeaderWrapperStyled>
                  <CommentDescriptionStyled>
                    {subTitle && (
                      <SubTitleStyled>
                        {subTitle !== 'COMMENT' && subTitle}
                        {issueOwners && (
                          <>
                            <span>&nbsp;</span>
                            {issueOwners.join(', ')}
                          </>
                        )}
                      </SubTitleStyled>
                    )}
                    <CommentStyled>
                      <Linkify>{comment}</Linkify>
                    </CommentStyled>
                  </CommentDescriptionStyled>
                </CommentInfoWrapperStyled>
              </CommentWrapperStyled>
            ),
          )}
        </CommentsWrapperStyled>
      )}
      {!isReadOnly && (
        <InputStyled
          placeholder={placeholderText}
          onChange={(e) => setCommentText(e.target.value || '')}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      )}
    </CommentsPopupWrapperStyled>
  )
}

export default CommentsPopup
