import FormField from './FormField'
import { inputNumberOnInput, inputNumberOnKeyDown } from './InputNumber'

const FormFieldNumber = (props: any) => (
  <FormField
    onInput={inputNumberOnInput}
    onKeyDown={inputNumberOnKeyDown}
    {...props}
  />
)

export default FormFieldNumber
