import { Field } from 'formik'
import { Component } from 'react'
import styled from 'styled-components'

import { fieldNameMap } from '../../ducks/meters/generation'
import type { FTModalPanelForm2 } from '../../ducks/modal'
import type { FTFormFieldEvent, FTWithFormik } from '../../types'
import { getValueFromEvent, makeListSelectorItem } from '../../utils'
import ErrorMessage, { ErrorMessageStyles } from '../ErrorMessage'
import FormButtons from '../FormButtons'
import type { FTOnChangePayload } from '../ListSelector'
import ListSelector from '../ListSelector'
import EditBlock from '../MeterConfiguration/EditBlock'
import StackedInput from '../StackedInput'
import UnsavedChanges from '../UnsavedChanges'

const WrapperStyles = styled.div`
  .PanelForm__EditBlock {
    background-color: #fff;
    padding: 10px 5px 20px 5px;

    .Select--is-disabled,
    input::placeholder {
      opacity: 0.5;
    }
  }

  input {
    width: 100%;
  }

  .input__error {
    left: auto;
    right: 6px;
  }
`
const FormButtonsStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
const FieldErrorMessageStyles = styled.div`
  font-size: 12px;

  .panel-modal__disabled-field-error {
    margin-left: 8px;
    margin-top: 0px;
  }
`
const FormErrorMessageStyles = styled.div`
  ${ErrorMessageStyles} {
    margin: 0 0 2px;
  }
`
type FTProps = {
  error: string
} & FTWithFormik &
  FTModalPanelForm2
type FTState = {
  showDisabledErrors: boolean
}
export default class Form extends Component<FTProps, FTState> {
  constructor(props: any) {
    super(props)
    this.state = {
      showDisabledErrors: false,
    }
  }

  canSubmit = () => {
    const { dirty, values, errors } = this.props
    const { name, type, voltage } = values
    return !!(dirty && name && type && voltage && !Object.keys(errors).length)
  }

  onUpdateField =
    (fieldName: string) => (data: FTFormFieldEvent & FTOnChangePayload) => {
      const { setFieldValue } = this.props
      const { value } = data.target || data
      setFieldValue(fieldName, value)
    }

  onUpdateParentPanel = () => (data: FTFormFieldEvent) => {
    const { setFieldValue, panelsById } = this.props
    const { value } = data.target || data

    setFieldValue('parentPanelId', value)
    setFieldValue('parentPanelName', panelsById[value]?.name)
  }

  onUpdatePanelOrder = (event: FTFormFieldEvent, side: 'LEFT' | 'RIGHT') => {
    const currFieldName = side === 'LEFT' ? 'leftPanelOrder' : 'rightPanelOrder'
    const otherFieldName =
      side === 'LEFT' ? 'rightPanelOrder' : 'leftPanelOrder'
    const { setValues, setTouched, values } = this.props
    const value = getValueFromEvent(event)
    if (value === 'CONSECUTIVE' || value === '') {
      setValues({ ...values, [currFieldName]: value, [otherFieldName]: value })
    } else if (value === 'ODD') {
      setValues({
        ...values,
        [currFieldName]: value,
        [otherFieldName]: 'EVEN',
      })
    } else {
      setValues({ ...values, [currFieldName]: value, [otherFieldName]: 'ODD' })
    }

    setTouched({
      [currFieldName]: true,
      [otherFieldName]: true,
    })
  }

  onUpdateType = ({ value: type }: { value: string }) => {
    const {
      setValues,
      values,
      status: { validPanelVoltages },
    } = this.props
    const { voltage } = values
    const newVoltage =
      (
        voltage &&
        voltage !== 'OTHER' &&
        validPanelVoltages[type].find((item) => item.id === voltage)
      ) ?
        voltage
      : null
    setValues({ ...values, type, voltage: newVoltage })
  }

  renderPanelSection = () => {
    const {
      errors,
      // conditionalFields,
      initialValues,
      touched,
      values,
      status,
      panels,
      panelsById,
    } = this.props

    const { showDisabledErrors } = this.state

    const {
      id,
      meterIds,
      description,
      name,
      type,
      voltage,
      location,
      numberOfCircuits,
      leftPanelOrder,
      rightPanelOrder,
      phasingPattern,
      amperage,
      parentPanelId,
      panelLevel,
    } = values

    const validPanels = id ? panels.filter((panel) => panel.id !== id) : panels

    const handleDisabledClick = () => {
      if (meterIds && !showDisabledErrors) {
        this.setState((prev) => ({ ...prev, showDisabledErrors: true }))
      }
    }

    const {
      validPanelTypes,
      validPanelVoltages,
      validPhasingPatterns,
      validNumberOrders,
      validAmperageValues,
      validPanelLevels,
    } = status

    return (
      <WrapperStyles>
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='The name of the panel, i.e. "Panel A"'
            editDotRight='-15px'
            edited={name !== initialValues.name}
            fieldTitle='Panel Name'
            hideEditDotOnHover={false}
            name='name'
            onBlur={this.onUpdateField('name')}
            required
          />
        </EditBlock>
        {errors.name && (
          <FieldErrorMessageStyles>
            <ErrorMessage
              className='panel-modal__disabled-field-error'
              collapseWhenEmpty
              message={errors.name}
            />
          </FieldErrorMessageStyles>
        )}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='A description to further identify the panel, i.e. "1st Floor Breakroom"'
            editDotRight='-15px'
            edited={description !== initialValues.description}
            error={touched.description && errors.description}
            fieldTitle='Panel Description'
            hideEditDotOnHover={false}
            name='description'
            placeholder='Optional'
            onBlur={this.onUpdateField('description')}
          />
        </EditBlock>
        <EditBlock
          className='PanelForm__EditBlock'
          onClick={handleDisabledClick}
        >
          <Field
            component={StackedInput}
            description='The panel configuration type, i.e. "Single Phase, 3-Wire (Split-Phase)"'
            disabled={!!meterIds}
            edited={type !== initialValues.type}
            editDotRight='-15px'
            fieldComponent={ListSelector}
            fieldTitle='Panel Type'
            hideEditDotOnHover={false}
            items={validPanelTypes}
            name='type'
            notSetLabelText='-- Select a panel type --'
            selectedItem={makeListSelectorItem(
              type,
              fieldNameMap.get(type || ''),
            )}
            updateValue={this.onUpdateType}
          />
          {showDisabledErrors && (
            <FieldErrorMessageStyles>
              <ErrorMessage
                className='panel-modal__disabled-field-error'
                collapseWhenEmpty
                message='Panel type cannot be changed because there could be one or more meters assigned to this panel. Please create a new panel instead.'
              />
            </FieldErrorMessageStyles>
          )}
        </EditBlock>
        <EditBlock
          className='PanelForm__EditBlock'
          onClick={handleDisabledClick}
        >
          <Field
            component={StackedInput}
            description='The panel voltage rating, i.e. "120/240V"'
            disabled={!!meterIds}
            edited={voltage !== initialValues.voltage}
            editDotRight='-15px'
            fieldTitle='Panel Voltage'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={validPanelVoltages[type] || []}
            name='voltage'
            notSetLabelText='-- Select a panel voltage --'
            selectedItem={makeListSelectorItem(
              voltage,
              fieldNameMap.get(voltage || ''),
            )}
            updateValue={this.onUpdateField('voltage')}
          />
          {showDisabledErrors && (
            <FieldErrorMessageStyles>
              <ErrorMessage
                className='panel-modal__disabled-field-error'
                collapseWhenEmpty
                message='Panel voltage cannot be changed because there could be one or more meters assigned to this panel. Please create a new panel instead.'
              />
            </FieldErrorMessageStyles>
          )}
        </EditBlock>
        {/* Panel Location */}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='The location of the panel on the site, i.e. “ Warehouse Basement”.'
            editDotRight='-15px'
            edited={location !== initialValues.location}
            error={touched.location && errors.location}
            fieldTitle='Panel Location'
            hideEditDotOnHover={false}
            name='location'
            placeholder='Optional'
            onBlur={this.onUpdateField('location')}
          />
        </EditBlock>
        {/* Panel Amperage */}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description="The panel amperage rating i.e '100A'"
            editDotRight='-15px'
            edited={amperage !== initialValues.amperage}
            fieldTitle='Panel Amperage'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={validAmperageValues}
            name='amperage'
            notSetLabelText='-- Select a panel amperage --'
            selectedItem={makeListSelectorItem(amperage)}
            updateValue={this.onUpdateField('amperage')}
          />
        </EditBlock>
        {/* Parent Panel Selection */}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='Denotes the panel feeding power'
            editDotRight='-15px'
            edited={parentPanelId !== initialValues.parentPanelId}
            fieldTitle='Parent Panel'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={validPanels}
            name='parentPanelName'
            notSetLabelText='-- Search for the panel --'
            notSetItemValue=''
            selectedItem={makeListSelectorItem(panelsById[parentPanelId]?.name)}
            updateValue={this.onUpdateParentPanel()}
          />
        </EditBlock>
        {/* Panel Hierarchy Type */}
        <EditBlock className='PanelForm__EditBlock'>
          <Field
            component={StackedInput}
            description='The hierarchy level at which the panel is setup'
            editDotRight='-15px'
            edited={panelLevel !== initialValues.panelLevel}
            fieldTitle='Panel Hierarchy Type'
            fieldComponent={ListSelector}
            hideEditDotOnHover={false}
            items={validPanelLevels}
            name='panelLevel'
            notSetLabelText='-- Select the panel level --'
            notSetItemValue=''
            selectedItem={makeListSelectorItem(panelLevel)}
            updateValue={this.onUpdateField('panelLevel')}
          />
        </EditBlock>
        {/* Remove the following derived fields in Edit mode */}
        {!id && (
          <>
            <EditBlock className='PanelForm__EditBlock'>
              <Field
                component={StackedInput}
                type='number'
                min={0}
                description='How many number of circuits are present on the panel?'
                error={touched.numberOfCircuits && errors.numberOfCircuits}
                fieldTitle='Number of Circuits'
                hideEditDotOnHover={false} // defaultValue={numberOfCircuits}
                name='numberOfCircuits'
                onBlur={this.onUpdateField('numberOfCircuits')}
              />
            </EditBlock>
            <EditBlock className='PanelForm__EditBlock' disabled>
              <Field
                component={StackedInput}
                disabled={numberOfCircuits <= 1}
                description='What breaker number are in use down the left side of the panel?'
                fieldComponent={ListSelector}
                fieldTitle='Left Side Panel'
                hideEditDotOnHover={false}
                items={validNumberOrders}
                name='leftPanelOrder'
                notSetLabelText='-- Select Number Order --'
                notSetItemText='--'
                notSetItemValue=''
                selectedItem={makeListSelectorItem(leftPanelOrder)}
                updateValue={(e) => this.onUpdatePanelOrder(e, 'LEFT')}
              />
            </EditBlock>
            {errors.leftPanelOrder && (
              <FieldErrorMessageStyles>
                <ErrorMessage
                  className='panel-modal__disabled-field-error'
                  collapseWhenEmpty
                  message={errors.leftPanelOrder}
                />
              </FieldErrorMessageStyles>
            )}
            <EditBlock className='PanelForm__EditBlock'>
              <Field
                component={StackedInput}
                disabled={numberOfCircuits <= 1}
                description='What breaker number are in use down the right side of the panel?'
                fieldComponent={ListSelector}
                fieldTitle='Right Side Panel'
                hideEditDotOnHover={false}
                items={validNumberOrders}
                name='rightPanelOrder'
                notSetLabelText='-- Select Number Order --'
                notSetItemText='--'
                notSetItemValue=''
                selectedItem={makeListSelectorItem(rightPanelOrder)}
                updateValue={(e) => this.onUpdatePanelOrder(e, 'RIGHT')}
              />
            </EditBlock>
            {errors.rightPanelOrder && (
              <FieldErrorMessageStyles>
                <ErrorMessage
                  className='panel-modal__disabled-field-error'
                  collapseWhenEmpty
                  message={errors.rightPanelOrder}
                />
              </FieldErrorMessageStyles>
            )}
            <EditBlock className='PanelForm__EditBlock'>
              <Field
                component={StackedInput}
                description='What phasing pattern is used in the panel?'
                disabled={numberOfCircuits <= 1}
                fieldComponent={ListSelector}
                fieldTitle='Phasing Pattern'
                hideEditDotOnHover={false}
                items={validPhasingPatterns}
                name='phasingPattern'
                notSetLabelText='-- Select a Phasing pattern --'
                notSetItemText='--'
                notSetItemValue=''
                selectedItem={makeListSelectorItem(phasingPattern)}
                updateValue={this.onUpdateField('phasingPattern')}
                customDropdown
              />
            </EditBlock>
            {errors.phasingPattern && (
              <FieldErrorMessageStyles>
                <ErrorMessage
                  className='panel-modal__disabled-field-error'
                  collapseWhenEmpty
                  message={errors.phasingPattern}
                />
              </FieldErrorMessageStyles>
            )}
          </>
        )}
      </WrapperStyles>
    )
  }

  render() {
    const { dirty, error, goBack, handleSubmit, id, isSubmitting } = this.props
    return (
      <>
        <UnsavedChanges when={!isSubmitting && dirty} />
        <FormErrorMessageStyles>
          <ErrorMessage message={error} />
        </FormErrorMessageStyles>
        {this.renderPanelSection()}
        <FormErrorMessageStyles>
          <ErrorMessage message={error} />
        </FormErrorMessageStyles>

        <FormButtonsStyles>
          <FormButtons
            cancelText='Cancel'
            isSubmitting={isSubmitting && !error}
            onCancel={goBack}
            onSubmit={handleSubmit}
            submitDisabled={!this.canSubmit()}
            submitText={id ? 'Save' : 'Create'}
          />
        </FormButtonsStyles>
      </>
    )
  }
}
