import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { phaseLabels } from '../../ducks/meters/config'
import type { FTMeterMostRecentMeasurementData } from '../../ducks/metersMostRecentMeasurementData'
import {
  actions as mostRecentMeasurementDataActions,
  selectMeterMostRecentMeasurementData,
  selectMeterMostRecentMeasurementDataMeta,
} from '../../ducks/metersMostRecentMeasurementData'
import RedaptiveReactTable from '../RedaptiveReactTable'

type FTProps = {
  actions: Record<string, any>
  data: Array<FTMeterMostRecentMeasurementData>
  error: string
  loading: boolean
  meterId: string
}

class MeterMostRecentMeasurementData extends Component<FTProps> {
  componentDidMount() {
    const { meterId } = this.props
    this.props.actions.getMeterMostRecentMeasurementData({
      meterId,
    })
  }

  render() {
    const { data, error, loading } = this.props
    return (
      <div>
        {loading && 'Loading'}
        {error && `Error: ${error}`}
        {!loading && !error && (
          <RedaptiveReactTable
            columns={[
              {
                accessor: 'meterChannel',
                Header: 'Meter Channel',
              },
              {
                accessor: 'panelName',
                Header: 'Panel',
              },
              {
                accessor: 'breakerNumber',
                Header: 'Breaker Number',
              },
              {
                accessor: 'phase',
                Cell: ({ row }) => phaseLabels.get(row.phase) || '',
                Header: 'Phase',
              },
              {
                accessor: 'phaseAngle',
                Header: 'Phase Angle',
              },
              {
                accessor: 'ctType',
                Cell: ({ row }) => (row.ctType ? `${row.ctType}A` : ''),
                Header: 'CT Type',
              },
              {
                accessor: 'wattage',
                Header: 'Wattage (kW)',
              },
              {
                accessor: 'voltage',
                Header: 'Voltage',
              },
              {
                accessor: 'amperage',
                Header: 'Amperage',
              },
              {
                accessor: 'powerFactor',
                Header: 'Power Factor',
              },
            ]}
            data={data}
            filterable={false}
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(mostRecentMeasurementDataActions, dispatch),
  },
})

const mapStateToProps = (state, { meterId }) => {
  const meta = selectMeterMostRecentMeasurementDataMeta(state, meterId)
  const { error, loading } = meta
  return {
    data: selectMeterMostRecentMeasurementData(state, meterId),
    error,
    loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeterMostRecentMeasurementData)
