import { Route, Switch } from 'react-router-dom'

import Components from './Components'
import DesignLibraryMainPage from './DesignLibraryMainPage'
import FeatureValidator from '../../authorization/components/FeatureValidator'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import AuthorizedFeatures from '../../authorization/features'
import type { FTRouterMatch } from '../../types'

type FTProps = {
  match: FTRouterMatch
}

const DesignLibrary = ({ match: { path } }: FTProps) => (
  <FeatureValidator feature={AuthorizedFeatures.accountManagement}>
    <UnauthorizedRedirect
      from={`${path}(.*)`}
      to='/not-authorized'
      feature={AuthorizedFeatures.accountManagement}
    />
    <Switch>
      <Route path={`${path}/components`} component={Components} />
      <Route path={`${path}`} component={DesignLibraryMainPage} />
    </Switch>
  </FeatureValidator>
)

export default DesignLibrary
