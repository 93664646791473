import { useState } from 'react'
import styled from 'styled-components'

import AddKFactorForm from './AddKFactorForm'
import CalculatedKFactor from './CalculatedKFactor'
import type { FTModalKfactorProps } from './commons'
import ManualKFactorForm from './ManualKFactorForm'
import { zIndices } from '../../../utils'
import { colors } from '../../../utils/themes'

const KFactorModalStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};

  * {
    box-sizing: border-box;
  }
`
const ModalContentWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ModalKfactorBase = (props: FTModalKfactorProps) => {
  const [manualMode, setManualMode] = useState(false)
  const [calculationMode, setCalculationMode] = useState(false)
  const { meter, closeModal, updateMode } = props
  const meterId = meter ? meter.id : null
  let contentToRender = (
    <AddKFactorForm
      meterId={meterId}
      closeModal={closeModal}
      setCalculationMode={setCalculationMode}
      setManualMode={setManualMode}
    />
  )

  if (updateMode) {
    contentToRender = (
      <CalculatedKFactor
        meter={meter}
        closeModal={closeModal}
        setManualMode={setManualMode}
        updateMode
      />
    )

    if (manualMode) {
      contentToRender = (
        <ManualKFactorForm meterId={meterId} closeModal={closeModal} />
      )
    }
  } else {
    contentToRender = (
      <AddKFactorForm
        meterId={meterId}
        closeModal={closeModal}
        setCalculationMode={setCalculationMode}
        setManualMode={setManualMode}
      />
    )
    if (manualMode) {
      contentToRender = (
        <ManualKFactorForm meterId={meterId} closeModal={closeModal} />
      )
    }

    if (calculationMode) {
      contentToRender = (
        <CalculatedKFactor
          meter={meter}
          closeModal={closeModal}
          setManualMode={setManualMode}
        />
      )
    }
  }

  return (
    <KFactorModalStyled>
      <ModalContentWrapperStyled>{contentToRender}</ModalContentWrapperStyled>
    </KFactorModalStyled>
  )
}

export default ModalKfactorBase
