import { call, put, take, takeEvery } from 'redux-saga/effects'

import { createUploadFileChannel } from './utils'
import { actions, resources, types } from '../ducks/upload'
// Upload the specified file
export function* uploadFileSaga({ customerId, resource, file }) {
  const bulkType = resource === resources.SAVINGS ? 'savings' : 'sites'
  const path = `/admin/bulk/${bulkType}/${customerId}`
  const channel = yield call(createUploadFileChannel, path, file)

  while (true) {
    const { progress = 0, err, success } = yield take(channel)

    if (err) {
      yield put(actions.uploadFailure(file, err))
      return
    }

    if (success) {
      yield put(actions.uploadSuccess(file))
      yield put(actions.clearUpload())
      return
    }

    yield put(actions.uploadProgress(file, progress))
  }
}
// Watch for an upload request and then
// defer to another saga to perform the actual upload
export function* uploadRequestWatcherSaga() {
  yield takeEvery(types.UPLOAD_REQUEST, function* uploadProcessor(action) {
    const file = action.payload
    yield call(uploadFileSaga, file)
  })
}
