import styled, { css } from 'styled-components'

const modalScenarioSidePaddingWidths = {
  left: 24,
  right: 32,
}
const tabSideMarginWidth = 12
const TabListPaddingLeft =
  modalScenarioSidePaddingWidths.left - tabSideMarginWidth
const TabListPaddingRight =
  modalScenarioSidePaddingWidths.right - tabSideMarginWidth
const TabListPaddingStyles = css`
  padding-left: ${TabListPaddingLeft}px;
  padding-right: ${TabListPaddingRight}px;
`
export default styled.div`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid #dadce0;
    ${TabListPaddingStyles};
  }

  .react-tabs__tab {
    border-bottom: 1px solid #dadce0;
    display: inline-block;
    list-style: none;
    margin: 0 ${tabSideMarginWidth}px;
    padding: 8px 0;
    cursor: pointer;
    position: relative;
    top: 1px;
  }

  .react-tabs__tab--selected {
    font-weight: 600;
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`
