/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import styled from 'styled-components'

import { isValueSet } from '../../utils'

export const Item = styled.div`
  margin-bottom: 14px;
  break-inside: avoid-column;
  ${({ hasHeading }) => hasHeading && 'padding-left: 16px;'}
`
const Description = styled.div`
  color: #7f7f7f;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  user-select: none;
`
export const Label = styled.div`
  font-weight: 600;
  min-width: 200px;
  user-select: none;
  float: left;
`
export const Heading = styled.div`
  font-weight: 600;
  user-select: none;
  margin-bottom: 14px;
`
export const Value = styled.div`
  overflow: hidden;
  & input {
    border: none;
    padding: 11px 8px;
  }
`
export const BaseTable = styled.div`
  margin-bottom: 70px;

  & a {
    display: inline-block;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }

  ${Label} {
    float: ${({ labelsInline }) => (labelsInline ? 'left' : 'none')};
    padding-bottom: ${({ labelsInline }) => (labelsInline ? '0' : '7px')};
    padding-right: ${({ labelsInline, isFieldWithoutTitle }) =>
      isFieldWithoutTitle ? '66px' : labelsInline && '50px'};
  }

  ${Value} {
    padding-top: ${({ labelsInline }) => (labelsInline ? '0' : '8px')};
  }

  ${Item} {
    padding-bottom: ${({ labelsInline }) => (labelsInline ? '0' : '40px')};
  }
`
const TwoColumnTable = styled(BaseTable)`
  margin-bottom: 70px;
  column-count: 2;
  column-gap: ${({ labelsInline }) => (labelsInline ? '100px' : '190px')};

  ${Label}, ${Value} {
    min-width: ${({ labelsInline }) => (labelsInline ? '235px' : '322px')};
    padding-bottom: ${({ labelsInline }) => (labelsInline ? '6px' : '0')};
  }
`
export type FTField = {
  label: string
  value?: string | number | React.ReactNode
  description?: string
  renderField?: () => React.ReactNode
}
type FTProps = {
  fields: Array<FTField>
  columned?: boolean
  labelsInline?: boolean
  notAvailableText?: string
  className?: string
  heading?: string
  isFieldWithoutTitle?: boolean
  index?: number
}
export default class VerticalTable extends React.PureComponent<FTProps> {
  static defaultProps = {
    columned: true,
    labelsInline: true,
    isFieldWithoutTitle: false,
    notAvailableText: 'Not Available',
    className: '',
    index: 0,
  }

  render() {
    const {
      columned,
      fields,
      notAvailableText,
      labelsInline,
      isFieldWithoutTitle,
      className,
      heading,
      index,
    } = this.props
    const Component = columned ? TwoColumnTable : BaseTable
    return (
      <Component
        labelsInline={labelsInline}
        isFieldWithoutTitle={isFieldWithoutTitle}
        className={className}
      >
        {heading && <Heading>{heading}</Heading>}
        {fields.map(({ value, label, renderField, description }, i) => (
          <Item key={`${label}${index || '0'}${i}`} hasHeading={!!heading}>
            <Label>{label}</Label>
            {!renderField && (
              <Value>{isValueSet(value) ? value : notAvailableText}</Value>
            )}
            {renderField && <Value>{renderField()}</Value>}
            {description && <Description>{description}</Description>}
          </Item>
        ))}
      </Component>
    )
  }
}
