import styled from 'styled-components'

import Icons from './index'

const ActiveFieldIcon = styled(Icons.MaterialIcon).attrs({
  children: 'check_circle',
})`
  color: #7bcc76;
`
export default ActiveFieldIcon
