import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ButtonLink from '../components/ButtonLink'
import Description from '../components/Description'
import Title from '../components/Title'
import { actions as authActions, FTState } from '../ducks/auth'

const Styles = styled.div`
  a[href] {
    color: #337ab7;
  }
`

const NotAuthorizedPage = () => {
  const error = useSelector((state: FTState) => state.auth.error)
  const dispatch = useDispatch()
  const history = useHistory()
  const reload = () => {
    dispatch(authActions.fetchUserPermissions())
    setTimeout(() => history.replace('/'), 1000)
  }

  if (error) {
    return (
      <Styles>
        <Title>Something went wrong</Title>
        <Description>
          Something went wrong in verifying your access to Grand Central.
        </Description>
        <Description>
          Please <ButtonLink onClick={reload}>click here</ButtonLink> to try
          again.
        </Description>
      </Styles>
    )
  }

  return (
    <Styles>
      <Title>You are not authorized to view this page</Title>
      <Description>
        Please contact a Redaptive administrator to request access.
      </Description>
    </Styles>
  )
}

export default NotAuthorizedPage
