import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { flippedCTStatusLabels } from '../../ducks/circuits'
import { fieldNameMap } from '../../ducks/meters/generation'
import ChevronDown from '../Icons/svg/ChevronDown'
import ChevronUp from '../Icons/svg/ChevronUp'
import RedaptiveReactTable from '../RedaptiveReactTable'

const SectionNameStyled = styled.div`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #337ab7;
  display: flex;
  align-items: center;
`
const SectionCellStyled = styled.div`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`
const SectionDateStyled = styled.span`
  font-family: Avenir Next;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #7f7f7f;
`
const ChevronContainerStyled = styled.span`
  margin-left: 5px;
  margin-top: 12px;
  display: inline-block;
`
const rowNameMap = new Map([
  ['vtapL1PhaseA', 'Voltage Tap - L1 Phase A'],
  ['vtapL2PhaseB', 'Voltage Tap - L2 Phase B'],
  ['vtapL3PhaseC', 'Voltage Tap - L3 Phase C'],
  ['highLeg', 'High Leg'],
  ['stepdownTransformer', 'Step-down Transformer'],
  ['breakerNumber', 'Breaker #'],
  ['ctType', 'CT Type'],
  ['inUse', 'In Use'],
  ['phase', 'Phase'],
  ['flippedCT', 'CT Orientation'],
  ['panelSummary', 'Panel'],
])
const phaseMap = new Map([
  ['Phase 1', 'Phase A'],
  ['Phase 2', 'Phase B'],
  ['Phase 3', 'Phase C'],
  ['-', '-'],
])

const MeterConfigDetail = (props) => {
  const { currentVersionDetails, previousVersionDetails, siteTimezone } = props

  const camelCaseToTitleCase = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
  }

  const convertToSiteTimezone = (date) => {
    const convertedDate = moment(date).tz(siteTimezone)
    const formattedDate = convertedDate.format('MMM DD, YYYY hh:mm:ss A z')
    return formattedDate
  }

  let data = []

  if (currentVersionDetails) {
    const datePrevious =
      previousVersionDetails ?
        convertToSiteTimezone(previousVersionDetails.created)
      : '-'
    const dateAfter =
      currentVersionDetails ?
        convertToSiteTimezone(currentVersionDetails.created)
      : '-'
    data.push({
      name: 'Panel',
      datePrevious,
      dateAfter,
      data: {
        before:
          previousVersionDetails && previousVersionDetails.powerSource ?
            previousVersionDetails.powerSource.panel
          : '-',
        after:
          currentVersionDetails && currentVersionDetails.powerSource ?
            currentVersionDetails.powerSource.panel
          : '-',
      },
    })

    if (currentVersionDetails.powerSource) {
      const powerSourceKeys = Object.keys(
        currentVersionDetails.powerSource || {},
      ).filter((k) => k !== 'panel')
      const powerSourceKeysBefore = Object.keys(
        previousVersionDetails ? previousVersionDetails.powerSource : {},
      ).filter((k) => k !== 'panel')

      if (powerSourceKeys.length > 0 || powerSourceKeysBefore.length > 0) {
        data.push({
          name: 'Power Source',
          datePrevious,
          dateAfter,
          data: {
            before:
              previousVersionDetails ? previousVersionDetails.powerSource : '-',
            after:
              currentVersionDetails ? currentVersionDetails.powerSource : '-',
          },
        })
      }
    }

    if (currentVersionDetails.misc) {
      data.push({
        name: 'Misc',
        datePrevious,
        dateAfter,
        data: {
          before: previousVersionDetails ? previousVersionDetails.misc : '-',
          after: currentVersionDetails ? currentVersionDetails.misc : '-',
        },
      })
    }

    if (
      currentVersionDetails.channels &&
      currentVersionDetails.channels.length > 0
    ) {
      const channels = [...currentVersionDetails.channels]
      const prevChannels =
        previousVersionDetails ? [...previousVersionDetails.channels] : []
      channels.forEach((channel, index) => {
        const channelBefore =
          prevChannels[prevChannels.findIndex((c) => c.id === channel.id)] ||
          '-'
        const channelAfter = channel
        data.push({
          name: channel.meterChannel || `Channel ${index + 1}`,
          datePrevious,
          dateAfter,
          data: {
            before: channelBefore || '-',
            after: channelAfter || '-',
          },
        })
      })
    }
  }

  // only keep unique elements in data array based on name
  data = [...new Map(data.map((item) => [item.name, item])).values()]
  const columns = [
    {
      expander: true,
      Expander: ({
        isExpanded,
        original,
      }: {
        isExpanded: boolean
        original: Record<string, any>
      }) => (
        <SectionNameStyled>
          {(original && original.name) || '-'}
          <ChevronContainerStyled>
            {isExpanded ?
              <ChevronUp color='#337AB7' />
            : <ChevronDown color='#337AB7' />}
          </ChevronContainerStyled>
        </SectionNameStyled>
      ),
      id: 'name',
      disableFilters: true,
      maxWidth: 250,
      width: 250,
    },
    {
      Cell: ({
        original,
        row,
      }: {
        original: Record<string, any>
        row: Record<string, any>
      }) => (
        <SectionCellStyled>
          Before{' '}
          {row._index < 1 ?
            <SectionDateStyled>{original.datePrevious}</SectionDateStyled>
          : ''}
        </SectionCellStyled>
      ),
      id: 'before',
      disableFilters: true,
      maxWidth: 330,
    },
    {
      Cell: ({
        original,
        row,
      }: {
        original: Record<string, any>
        row: Record<string, any>
      }) => (
        <SectionCellStyled>
          After{' '}
          {row._index < 1 ?
            <SectionDateStyled>{original.dateAfter}</SectionDateStyled>
          : ''}
        </SectionCellStyled>
      ),
      id: 'after',
      disableFilters: true,
      maxWidth: 330,
    },
  ]

  const renderSubComponent = ({
    original,
  }: {
    original: Record<string, any>
  }) => {
    const parent = { ...original }
    const subComponentData = []

    if (parent.name === 'Panel') {
      const panelKeys = ['name', 'description', 'type', 'voltage']
      panelKeys.forEach((key) => {
        let beforeData = parent.data.before ? parent.data.before[key] : '-'
        let afterData = parent.data.after ? parent.data.after[key] : '-'

        if (key === 'type' || key === 'voltage') {
          beforeData = fieldNameMap.get(beforeData)
          afterData = fieldNameMap.get(afterData)
        }

        subComponentData.push({
          name: `Panel ${camelCaseToTitleCase(key)}`,
          beforeData: beforeData || '-',
          afterData: afterData || '-',
        })
      })
    }

    if (parent.name === 'Power Source') {
      const powerSourceKeys = [
        'vtapL1PhaseA',
        'vtapL2PhaseB',
        'vtapL3PhaseC',
        'highLeg',
        'stepdownTransformer',
      ]
      powerSourceKeys.forEach((key) => {
        let beforeDataPowerSource = parent.data.before[key]
        let afterDataPowerSource = parent.data.after[key]
        if (key === 'stepdownTransformer') {
          beforeDataPowerSource =
            beforeDataPowerSource ? beforeDataPowerSource.fullName : '-'
          afterDataPowerSource =
            afterDataPowerSource ? afterDataPowerSource.fullName : '-'
        }
        subComponentData.push({
          name: rowNameMap.get(key),
          beforeData:
            typeof beforeDataPowerSource === 'number' ?
              beforeDataPowerSource
            : beforeDataPowerSource || '-',
          afterData:
            typeof afterDataPowerSource === 'number' ? afterDataPowerSource : (
              afterDataPowerSource || '-'
            ),
        })
      })
    }

    if (parent.name === 'Misc') {
      const miscKeys = ['phaseCorrectionAngles', 'voltageScalingFactors']
      const miscColNameMap = new Map([
        ['phase1', 'L1'],
        ['phase2', 'L2'],
        ['phase3', 'L3'],
      ])
      miscKeys.forEach((key) => {
        const innerKeys = ['phase1', 'phase2', 'phase3']

        if (key === 'phaseCorrectionAngles') {
          innerKeys.forEach((phaseCorrectionKey) => {
            const beforeDataMiscPhase =
              parent.data.before?.[key] ?
                parent.data.before[key][phaseCorrectionKey]
              : '-'
            const afterDataMiscPhase =
              parent.data.after?.[key] ?
                parent.data.after[key][phaseCorrectionKey]
              : '-'
            subComponentData.push({
              name: `Phase Angle Correction Factor ${miscColNameMap.get(
                phaseCorrectionKey,
              )}`,
              beforeData:
                typeof beforeDataMiscPhase === 'number' ? beforeDataMiscPhase
                : beforeDataMiscPhase || '-',
              afterData:
                typeof afterDataMiscPhase === 'number' ? afterDataMiscPhase : (
                  afterDataMiscPhase || '-'
                ),
            })
          })
        }

        if (key === 'voltageScalingFactors') {
          innerKeys.forEach((voltageScalingKey) => {
            const beforeDataMiscVoltage =
              parent.data.before?.[key] ?
                parent.data.before[key][voltageScalingKey]
              : '-'
            const afterDataMiscVoltage =
              parent.data.after?.[key]?.[voltageScalingKey]
            subComponentData.push({
              name: `Voltage Scaling Factor ${miscColNameMap.get(
                voltageScalingKey,
              )}`,
              beforeData:
                typeof beforeDataMiscVoltage === 'number' ?
                  beforeDataMiscVoltage
                : beforeDataMiscVoltage || '-',
              afterData:
                typeof afterDataMiscVoltage === 'number' ? afterDataMiscVoltage
                : afterDataMiscVoltage || '-',
            })
          })
        }
      })
    }

    const doneKeys = ['Misc', 'Power Source', 'Panel']

    if (!doneKeys.includes(parent.name)) {
      const channelKeys = Object.keys(parent.data.after || {}).filter(
        (k) => k !== 'id' && k !== 'meterChannel',
      )
      const channelKeysBefore = Object.keys(parent.data.before || {}).filter(
        (k) => k !== 'id' && k !== 'meterChannel',
      )
      const uniqueChannelKeys = [
        ...new Set([...channelKeys, ...channelKeysBefore]),
      ].filter((k) => k !== '0')
      uniqueChannelKeys.forEach((key) => {
        let beforeData = parent.data.before[key]
        let afterData = parent.data.after[key]

        if (key === 'inUse') {
          if (beforeData === undefined) {
            beforeData = '-'
          } else if (beforeData) {
            beforeData = 'Yes'
          } else {
            beforeData = 'No'
          }

          afterData = afterData ? 'Yes' : 'No'
        }

        if (key === 'flippedCT') {
          beforeData =
            beforeData ? flippedCTStatusLabels[beforeData.status] : '-'
          afterData = afterData ? flippedCTStatusLabels[afterData.status] : '-'
        }

        if (key === 'panelSummary') {
          beforeData = beforeData ? beforeData.name : '-'
          afterData = afterData ? afterData.name : '-'
        }

        if (key === 'phase') {
          beforeData = beforeData ? phaseMap.get(beforeData) : '-'
          afterData = afterData ? phaseMap.get(afterData) : '-'
        }

        subComponentData.push({
          name: rowNameMap.get(key),
          beforeData:
            typeof beforeData === 'number' ? beforeData : beforeData || '-',
          afterData:
            typeof afterData === 'number' ? afterData : afterData || '-',
        })
      })

      if (!uniqueChannelKeys.includes('breakerNumber')) {
        subComponentData.push({
          name: rowNameMap.get('breakerNumber'),
          beforeData:
            typeof parent.data.before.breakerNumber === 'number' ?
              parent.data.before.breakerNumber
            : parent.data.before.breakerNumber || '-',
          afterData:
            typeof parent.data.after.breakerNumber === 'number' ?
              parent.data.after.breakerNumber
            : parent.data.after.breakerNumber || '-',
        })
      }

      if (!uniqueChannelKeys.includes('panelSummary')) {
        subComponentData.push({
          name: rowNameMap.get('panelSummary'),
          beforeData:
            typeof parent.data.before.panelSummary === 'number' ?
              parent.data.before.panelSummary
            : parent.data.before.panelSummary || '-',
          afterData:
            typeof parent.data.after.panelSummary === 'number' ?
              parent.data.after.panelSummary
            : parent.data.after.panelSummary || '-',
        })
      }

      subComponentData.sort((a, b) => a.name.localeCompare(b.name))
    }

    const subComponentColumns = [
      {
        Cell: (cellProps: Record<string, any>) => {
          const colData = cellProps.original
          const isHighlighted = colData.beforeData !== colData.afterData
          return (
            <div
              title={colData.name}
              className={isHighlighted ? 'highlight' : ''}
            >
              {colData.name}
            </div>
          )
        },
        id: 'name',
        disableFilters: true,
        maxWidth: 250,
        width: 250,
      },
      {
        Cell: (cellProps: Record<string, any>) => {
          const colData = cellProps.original
          return <div title={colData.beforeData}>{colData.beforeData}</div>
        },
        id: 'beforeData',
        disableFilters: true,
        maxWidth: 330,
      },
      {
        Cell: (cellProps: Record<string, any>) => {
          const colData = cellProps.original
          return <div title={colData.afterData}>{colData.afterData}</div>
        },
        id: 'afterData',
        disableFilters: true,
        maxWidth: 330,
      },
    ]
    return (
      <RedaptiveReactTable
        className='-config-history-detail-subtable -striped'
        data={subComponentData}
        columns={subComponentColumns}
        defaultPageSize={20}
        filterable={false}
        sortable={false}
        resizable={false}
      />
    )
  }

  return (
    <RedaptiveReactTable
      className='-config-history-detail'
      data={data}
      columns={columns}
      defaultPageSize={20}
      filterable={false}
      sortable={false}
      resizable={false}
      SubComponent={renderSubComponent}
      defaultExpanded={data.map((d, index) => ({
        id: index,
      }))}
    />
  )
}

MeterConfigDetail.propTypes = {
  currentVersionDetails: PropTypes.shape(),
  previousVersionDetails: PropTypes.shape(),
  siteTimezone: PropTypes.string.isRequired,
}
export default MeterConfigDetail
