import debounce from 'debounce'
import moment from 'moment'
import { SyntheticEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Breadcrumbs'
import ErrorMessage from '../../components/ErrorMessage'
import IconButton from '../../components/IconButton'
import Input, { InputComponent } from '../../components/Input'
import RedaptiveReactTable from '../../components/RedaptiveReactTable'
import Spinner from '../../components/Spinner'
import StyledLink from '../../components/StyledLink'
import Title from '../../components/Title'
import type { FTSpecSheet, FTSpecSheetsState } from '../../ducks/specSheets'
import {
  actions as specSheetActions,
  selectSpecSheets,
} from '../../ducks/specSheets'
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_FORMAT_SIMPLE,
} from '../../ducks/utils'
import type { FTHistory, FTRouterMatch } from '../../types'

const HeaderBarStyles = styled.div`
  display: flex;
  justify-content: space-between;
`
const HelpTextStyles = styled.p`
  margin-top: 0;
`
const InputStyles = styled.div`
  ${InputComponent} {
    width: 400px;
  }
`
const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`
type FTCell = {
  value: string
  row: {
    _original: FTSpecSheet
  }
}
type FTProps = {
  actions: any
  history: FTHistory
  match: FTRouterMatch
  specSheetsState: FTSpecSheetsState
}
const columns = [
  {
    accessor: 'fileName',
    Cell: ({
      value,
      row: {
        _original: { url },
      },
    }: FTCell) => {
      const name = value.replace('.pdf', '').replace('.PDF', '')
      return (
        <StyledLink display='inline' external href={url}>
          {name}
        </StyledLink>
      )
    },
    Header: 'Fixture / Lamp',
    minWidth: 80,
  },
  {
    accessor: 'addedBy',
    Header: 'Added By',
  },
  {
    accessor: 'dateAdded',
    Cell: ({ value }: FTCell) =>
      moment(value, DATE_FORMAT_DATA_API_RESPONSE).format(DATE_FORMAT_SIMPLE),
    Header: 'Date Added',
  },
]

const SpecSheetsListPage = (props: FTProps) => {
  const {
    history,
    match: { path },
    actions: { fetchSpecSheets },
    specSheetsState: {
      byId: specSheetsById,
      meta: { loading, error },
    },
  } = props
  const [data, setData]: [Array<FTSpecSheet>, (...args: Array<any>) => any] =
    useState([])
  const [initialData, setInitialData]: [
    Array<FTSpecSheet>,
    (...args: Array<any>) => any,
  ] = useState([])
  const [specSheetsRequested, setSpecSheetsRequested] = useState(false)
  const [search, setSearch] = useState('')
  useEffect(() => {
    if (!specSheetsRequested) {
      fetchSpecSheets()
      setSpecSheetsRequested(true)
    }
  }, [specSheetsRequested])
  useEffect(() => {
    const newData = Object.values(specSheetsById)
    setData(newData)
    setInitialData(newData)
  }, [specSheetsById])
  useEffect(() => {
    setData(
      initialData.filter(({ fileName }) =>
        fileName.toLowerCase().includes(search.toLowerCase()),
      ),
    )
  }, [search])

  const handleSearch = ({ target: { value } }: SyntheticEvent) => {
    setSearch(value)
  }

  const handleSearchDebounced = debounce(handleSearch, 200)

  const navigateSpecSheetsUpload = () => history.push(`${path}/upload`)

  return (
    <>
      <Breadcrumbs
        items={[
          {
            href: '/proposal-operations',
            text: 'Proposal Operations',
          },
          {
            href: '/proposal-operations/spec-sheets',
            text: 'Spec Sheets',
          },
        ]}
      />
      <HeaderBarStyles>
        <Title>Spec Sheets</Title>
        <div>
          <IconButton
            onClick={navigateSpecSheetsUpload}
            iconClass='fas fa-plus'
          >
            Add Spec Sheets
          </IconButton>
        </div>
      </HeaderBarStyles>
      <HelpTextStyles>
        Uploaded spec sheet files are listed here. Use the search bar below to
        search or filter the list, or click the arrows next to the header titles
        to sort.
      </HelpTextStyles>
      {error && <ErrorMessage message={error} />}
      <InputStyles>
        <Input placeholder='Search' onChange={handleSearchDebounced} />
      </InputStyles>
      {loading && (
        <LoadingStyles>
          <Spinner />
        </LoadingStyles>
      )}
      {!loading && !error && (
        <RedaptiveReactTable columns={columns} data={data} filterable={false} />
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(specSheetActions, dispatch) },
})

const mapStateToProps = (state) => ({
  specSheetsState: selectSpecSheets(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(SpecSheetsListPage)
