export default {
  circuits: [
    {
      id: '5eb93cfb-e723-45c9-a363-337fe5a44ada',
      breakerNumber: 1,
      sideBreakerOrder: 'LEFT',
      phasingPattern: 'TOP_TO_BOTTOM',
      breakerType: 'SINGLE_POLE_BREAKER',
      description: 'Aisle A-B Lighting',
      phase: 'A',
      phaseGroup: '1-1',
      buildingSystemId: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
      equipmentName: 'Aisle A-B Lighting',
      buildingArea: 'WareHouse',
      CTSizeExpected: '10A',
      isMetered: true,
    },
    {
      id: '32d8d571-de31-4ce1-95e5-4388fad552f7',
      breakerNumber: 3,
      sideBreakerOrder: 'LEFT',
      phasingPattern: 'TOP_TO_BOTTOM',
      breakerType: 'SINGLE_POLE_BREAKER',
      description: 'Aisle A-B Lighting',
      phase: 'B',
      phaseGroup: '1-1',
      buildingSystemId: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
      equipmentName: 'Aisle A-B Lighting',
      buildingArea: 'WareHouse',
      amperage: '100A',
      CTSizeExpected: '100A',
      isMetered: false,
    },
    {
      id: '32d8d571-de31-4ce1-95e5-4388fad552g7',
      breakerNumber: 5,
      sideBreakerOrder: 'LEFT',
      phasingPattern: 'TOP_TO_BOTTOM',
      breakerType: 'SINGLE_POLE_BREAKER',
      description: 'Aisle A-B Lighting 2',
      phase: 'A',
      phaseGroup: '1-1',
      buildingSystemId: '',
      equipmentName: 'Aisle A-B Lighting 2',
      buildingArea: 'WareHouse 2',
      amperage: '200A',
      CTSizeExpected: '50A',
      isMetered: false,
    },
    {
      id: '32d8d571-de31-4ce1-95e5-4388fad552h7',
      breakerNumber: 7,
      sideBreakerOrder: 'LEFT',
      phasingPattern: 'TOP_TO_BOTTOM',
      breakerType: 'TWO_POLE_DIFFERENT_BREAKER',
      description: 'Aisle A-B Lighting',
      phase: 'B',
      phaseGroup: '1-1',
      buildingSystemId: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
      equipmentName: 'Aisle A-B Lighting',
      buildingArea: 'WareHouse',
      amperage: '100A',
      CTSizeExpected: '100A',
      isMetered: false,
    },
    {
      id: '32d8d571-de31-4ce1-95e5-4388fad552i7',
      breakerNumber: 9,
      sideBreakerOrder: 'LEFT',
      phasingPattern: 'TOP_TO_BOTTOM',
      breakerType: 'TWO_POLE_DIFFERENT_BREAKER',
      description: 'Aisle A-B Lighting',
      phase: 'B',
      phaseGroup: '1-1',
      buildingSystemId: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
      equipmentName: 'Aisle A-B Lighting',
      buildingArea: 'WareHouse',
      amperage: '100A',
      CTSizeExpected: '100A',
      isMetered: false,
    },
    {
      id: '41871d10-e875-430e-a237-52b7a7b48ab0',
      breakerNumber: 2,
      sideBreakerOrder: 'RIGHT',
      phasingPattern: 'BOTTOM_TO_TOP',
      breakerType: 'SINGLE_POLE_BREAKER',
      description: 'Aisle A-B Lighting',
      phase: 'A',
      phaseGroup: '1-1',
      buildingSystemId: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
      equipmentName: 'Aisle A-B Lighting',
      buildingArea: 'WareHouse',
      amperage: '100A',
      CTSizeExpected: '100A',
      isMetered: true,
    },
    {
      id: '97c22bfd-492a-4100-9a05-48261fd2a7ff',
      breakerNumber: 4,
      sideBreakerOrder: 'RIGHT',
      phasingPattern: 'BOTTOM_TO_TOP',
      breakerType: 'SINGLE_POLE_BREAKER',
      description: 'Aisle A-B Lighting',
      phase: 'B',
      phaseGroup: '1-1',
      buildingSystemId: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
      equipmentName: 'Aisle A-B Lighting',
      buildingArea: 'WareHouse',
      amperage: '100A',
      CTSizeExpected: '100A',
      isMetered: false,
    },
  ],
}
