import axios from 'axios'
import FileSaver from 'file-saver'
import * as React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import EntityList from './EntityList'
import '../ducks/types'
import { consoleApiUrl, defaultHeaders } from '../api'
import Button2 from './Button2'
import type { FTEntity, FTFetchEntityList } from '../ducks/types'
import '../types'
import type { FTRouterMatch } from '../types'

type FTProps = {
  fetchListEntity: FTFetchEntityList
  match: FTRouterMatch
  listEntity: FTEntity
  description?: string
  customerId?: string
  siteId?: string
  showCheckboxOption?: boolean
  showDownload?: boolean
  customerName?: string
  onNoResults?: () => React.ReactNode
}

const ButtonWrapper = styled.div`
  position: relative;
  top: -10px;
`

const WarningMessage = styled.div`
  color: #c70d08;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  user-select: none;
`

type FTState = {
  isDownloading: boolean
  error: string
  excludeRedaptiveEmail: boolean
}

class UsersTable extends React.Component<FTProps, FTState> {
  static defaultProps = {
    description: '',
    customerId: '',
    siteId: '',
    onNoResults: undefined,
  }

  constructor(props: FTProps) {
    super(props)
    this.state = {
      isDownloading: false,
      error: '',
      excludeRedaptiveEmail: false,
    }
  }

  headers = [
    {
      fieldName: 'email',
      displayName: 'Email Address',
    },
    {
      fieldName: 'roles',
      displayName: 'User Group',
    },
    {
      fieldName: 'status',
      displayName: 'Status',
    },
    {
      fieldName: 'lastActivity',
      displayName: 'Last Login',
    },
  ]

  updateRedaptiveEmailFlag = (excludeRedaptiveEmail) => {
    this.setState({ excludeRedaptiveEmail })
  }

  componentDidMount() {
    this.props.fetchListEntity(this.getFetchProps())
  }

  componentDidUpdate(prevProps: Readonly<FTProps>): void {
    const { customerId: prevCustomerId, siteId: prevSiteId } = prevProps
    const { customerId, siteId } = this.props

    if (customerId !== prevCustomerId || prevSiteId !== siteId) {
      this.props.fetchListEntity(this.getFetchProps())
    }
  }

  getFetchProps = () => {
    const { customerId, siteId, showCheckboxOption } = this.props
    const { excludeRedaptiveEmail } = this.state
    const orderProps = {
      orderBy: {
        field: 'email',
        sort: 'ASC',
      },
    }
    const defaultProps =
      showCheckboxOption ?
        {
          ...orderProps,
          excludeRedaptiveEmail,
        }
      : orderProps

    if (!customerId && !siteId) {
      return defaultProps
    }

    const props =
      customerId ?
        {
          customerId,
        }
      : {
          siteId,
        }
    return { ...defaultProps, ...props }
  }

  renderTableRow = ({ id, email, userType, status, lastLogin, signupId }) => {
    const {
      match: { url },
    } = this.props
    const userUrl =
      url.includes('/users') ? `${url}/${id}` : `${url}/users/${id}`
    return (
      <tr key={id || signupId}>
        <td className='email'>
          {id && (
            <Link to={userUrl} href={userUrl}>
              {email}
            </Link>
          )}
          {!id && <span>{email}</span>}
        </td>
        <td>{userType}</td>
        <td>{status}</td>
        <td>{lastLogin}</td>
      </tr>
    )
  }

  onDownloadExport = () => {
    const { customerName, customerId } = this.props
    this.setState({
      isDownloading: true,
      error: '',
    })
    axios
      .get(
        `${consoleApiUrl()}/admin/usersummaries/csv?customerId=${customerId}`,
        {
          responseType: 'blob',
          headers: defaultHeaders(),
        },
      )
      .then((response) => {
        this.setState({
          isDownloading: false,
          error: '',
        })
        FileSaver.saveAs(response.data, `${customerName} “R-One Users”.csv`)
      })
      .catch((error) => {
        this.setState({
          isDownloading: false,
          error,
        })
      })
  }

  renderDownloadButton = () => {
    const { isDownloading, error } = this.state
    return (
      <ButtonWrapper>
        <Button2
          loading={isDownloading}
          onClick={this.onDownloadExport}
          type='secondary'
        >
          Export User Information
        </Button2>
        {error && <WarningMessage>{error}</WarningMessage>}
      </ButtonWrapper>
    )
  }

  render() {
    const {
      fetchListEntity,
      listEntity,
      description,
      onNoResults,
      showCheckboxOption,
      showDownload,
    } = this.props
    return (
      <EntityList
        striped
        description={description}
        entity={listEntity}
        showRolesFilter
        loadEntity={fetchListEntity}
        loadEntityProps={this.getFetchProps()}
        tableHeaders={this.headers}
        renderTableRow={this.renderTableRow}
        renderNoResults={onNoResults}
        filterParam='roles'
        showCheckbox={showCheckboxOption}
        downloadEntity={showDownload && this.renderDownloadButton}
        checkboxLabel='Ignore Redaptive users'
        searchParam='filterEmail'
        updateCheckboxValue={this.updateRedaptiveEmailFlag}
      />
    )
  }
}

export default withRouter(UsersTable)
