import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { POPUP_STATES, VIEWS } from './ constants'
import {
  CancelButton,
  ConfirmationContent,
  ConfirmationFooter,
  ConfirmationModal,
  Error,
  Footer,
  Input,
  InputWrapper,
  Label,
  NoButton,
  OppName,
  OppText,
  SaveButton,
  SearchClear,
  SubTitle,
  Title,
  WrapperStyles,
  YesButton,
} from './style'
import { actions as meterActions, selectMeterEntity } from '../../ducks/meters'
import { actions as modalActions } from '../../ducks/modal'
import Modal from '../Modal'

type FTProps = {
  opportunityId: string
  id: string
  opportunities: any
}
export default function ModalMeterContactAssociation({
  id,
  opportunityId,
  opportunities = [],
}: FTProps) {
  const dispatch = useDispatch()
  const { meta } = useSelector(selectMeterEntity) || {}
  const [searchText, setSearchText] = useState('')
  const [view, setView] = useState(VIEWS.SEARCH)
  const [selectedOpportunity, setSelectedOpportunity] = useState(null)
  const [errorView, setErrorView] = useState(false)
  const [popup, setPopup] = useState(POPUP_STATES.NONE)
  useEffect(() => {
    if (opportunityId) {
      setSearchText(opportunityId)
      const opp = opportunities?.find(
        (val) => val?.opportunityId === opportunityId,
      )
      setSelectedOpportunity(opp)
      setView(VIEWS.CLEAR)
    }
  }, [opportunityId, opportunities])

  const onSearchOrClear = () => {
    if (view === VIEWS.SEARCH) {
      const opp = opportunities.find((val) => val.opportunityId === searchText)

      if (opp) {
        setView(VIEWS.NONE)
        setSelectedOpportunity(opp)
      } else {
        setView(VIEWS.CLEAR)
        setErrorView(true)
      }
    } else if (view === VIEWS.CLEAR) {
      setSearchText('')
      setSelectedOpportunity(null)
      setView(VIEWS.SEARCH)
      setErrorView(false)
    }
  }

  const isUpdate =
    opportunityId &&
    selectedOpportunity &&
    opportunityId !== selectedOpportunity?.opportunityId

  const onUpdateMeter = (oppId) => {
    dispatch(
      meterActions.updateMeter({
        id,
        opportunityId: oppId,
      }),
    )
  }

  const onAccept = () => {
    if (opportunityId && searchText === '') {
      setPopup(POPUP_STATES.REMOVE)
    } else if (isUpdate) {
      setPopup(POPUP_STATES.UPDATE)
    } else if (!opportunityId && selectedOpportunity) {
      setPopup(POPUP_STATES.ADD)
    }
  }

  const hideModal = () => {
    dispatch(modalActions.hideModal())
  }

  const handleTextChange = (e) => {
    if (view === VIEWS.NONE || view === VIEWS.CLEAR || errorView) {
      setSelectedOpportunity(null)
      setView(VIEWS.SEARCH)
      setErrorView(false)
    }

    setSearchText(e.target.value)
  }

  const renderOppDetails = () => (
    <div>
      <OppName>Opportunity Name</OppName>
      <OppText>{selectedOpportunity.opportunityName}</OppText>
    </div>
  )

  const onBack = () => {
    if (opportunityId) {
      setPopup(POPUP_STATES.CLEAR)
    }

    setPopup(POPUP_STATES.NONE)
  }

  const onRemove = () => {
    onUpdateMeter('')
  }

  const onUpdate = () => {
    // update api call
    onUpdateMeter(selectedOpportunity?.opportunityId)
  }

  const renderContent = () => (
    <>
      <SubTitle>Opportunity ID</SubTitle>
      <Label>Search with Opportunity ID</Label>
      <InputWrapper>
        <Input
          type='text'
          value={searchText}
          onChange={handleTextChange}
          error={errorView}
          maxLength='15'
        />
        {view !== VIEWS.NONE && (
          <SearchClear onClick={onSearchOrClear}>
            {view === VIEWS.SEARCH ? 'Search' : 'Clear'}
          </SearchClear>
        )}
      </InputWrapper>
      {selectedOpportunity?.opportunityId && renderOppDetails()}
      {errorView && <Error>Opportunity ID not found.</Error>}
      <Footer>
        <CancelButton onClick={hideModal} type='default'>
          CANCEL
        </CancelButton>
        <SaveButton
          onClick={onAccept}
          loading={isUpdate ? false : meta?.loading}
        >
          {isUpdate ? 'UPDATE' : 'SAVE'}
        </SaveButton>
      </Footer>
    </>
  )

  const getPopupText = () => {
    const isRemoveView = popup === POPUP_STATES.REMOVE

    if (isRemoveView) {
      return 'Are you sure you want to remove this opportunity?'
    }

    return 'Are you sure you want to update this opportunity?'
  }

  const renderPopup = () => {
    const isRemoveView = popup === POPUP_STATES.REMOVE
    const text = getPopupText()
    return (
      <>
        <ConfirmationContent>{text}</ConfirmationContent>
        <ConfirmationFooter>
          <NoButton onClick={onBack} type='default'>
            NO
          </NoButton>
          <YesButton
            loading={meta?.loading}
            onClick={isRemoveView ? onRemove : onUpdate}
          >
            YES
          </YesButton>
        </ConfirmationFooter>
      </>
    )
  }

  return popup !== POPUP_STATES.NONE ?
      <ConfirmationModal>
        <Modal modalWidth='390px' renderContent={renderPopup} />
      </ConfirmationModal>
    : <WrapperStyles>
        <Modal
          modalWidth='480px'
          onClose={hideModal}
          renderContent={renderContent}
          title={<Title>Opportunity Association</Title>}
        />
      </WrapperStyles>
}
