import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions as authActions } from '../../ducks/auth'

/*
UserPermissionsLoader is a declarative method of fetching user permissions when
the user first enters an authenticated route (wheter on login or on page load).
*/
type FTProps = {
  actions: {
    fetchUserPermissions: (...args: Array<any>) => any
  }
}

class UserPermissionsLoader extends PureComponent<FTProps> {
  componentDidMount() {
    this.props.actions.fetchUserPermissions()
  }

  render() {
    return null
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch),
})

export default connect(null, mapDispatchToProps)(UserPermissionsLoader)
