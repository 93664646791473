import { capitalCase } from 'capital-case'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  actions as meterActions,
  FTState as MeterHealthType,
  selectMeterListWithHealthEntity,
} from '../../../ducks/meters'
import { FTState, selectRTMeterById } from '../../../ducks/meterStatus'
import {
  FTState as SiteType,
  isRedaptiveMeter,
  selectSiteHealthRequestStatus,
} from '../../../ducks/sites'
import { renderTimestamp } from '../../../ducks/utils'
import { renderHealthStatus } from '../../../utils'
import DeprecatedEntityListPane from '../../DeprecatedEntityListPane'
import RequestStateFieldLabel from '../../RequestStateFieldLabel'
import StatusIconItem from '../../StatusIconItem'
import StyledLink from '../../StyledLink'
import { InlineNotVerifiedIcon, InlineVerifiedIcon } from '../styles'
import { RowType } from '../types'

const getMetersTableHeaders = (siteHealthRequestStatus: SiteType) => [
  {
    fieldName: 'name',
    displayName: 'Meter Identifier',
  },
  {
    fieldName: 'panel',
    displayName: 'Panel',
    sortable: false,
  },
  {
    fieldName: 'meterOnline',
    displayName: 'Online Status',
    sortable: false,
  },
  {
    fieldName: 'fiedStatus',
    displayName: 'Field Status',
  },
  {
    fieldName: 'verified',
    displayName: 'Electron Verified',
  },
  {
    fieldName: 'lastReportDate',
    displayName: 'Last Reported',
    sortable: false,
  },
  {
    fieldName: 'labeledChannels',
    displayName: 'Labeled Channels',
  },
  {
    fieldName: 'source',
    displayName: 'Meter Type',
  },
  {
    fieldName: 'model',
    displayName: 'Meter Model',
  },
  {
    fieldName: 'resource',
    displayName: 'Resource Type',
    sortable: false,
  },
  {
    fieldName: 'healthStatusLastMonth',
    displayName: (
      <RequestStateFieldLabel
        fieldLabel={
          <>
            Data Availability:
            <br />
            Last Month
          </>
        }
        requestStatus={siteHealthRequestStatus}
      />
    ),
    sortable: false,
  },
  {
    fieldName: 'healthStatusCurrentMonth',
    displayName: (
      <RequestStateFieldLabel
        fieldLabel={
          <>
            Data Availability:
            <br />
            Month to Date
          </>
        }
        requestStatus={siteHealthRequestStatus}
      />
    ),
    sortable: false,
  },
]

const getVerified = (meterType: string, verified: boolean) => {
  if (isRedaptiveMeter(meterType) || meterType === 'Redaptive Gas') {
    return verified ?
        <>
          <InlineVerifiedIcon />
          {' Yes'}
        </>
      : <>
          <InlineNotVerifiedIcon />
          {' No'}
        </>
  }
  return 'N/A'
}

const onlineStatusCell = (value: boolean | 'loading') => {
  if (value === 'loading') return <div>Loading...</div>
  return <StatusIconItem status={value} />
}

const lastMesurementDateCell = (value: string, tz: string) => {
  if (value === 'loading') return <div>Loading...</div>
  if (value.toLowerCase() === 'unavailable') return <div>Unavailable</div>
  const format = 'MMMM DD, YYYY hh:mm:ss A z'
  const date = renderTimestamp(value, tz, format)
  return date
}
const Meters = ({
  resetMeterStatusAPIFlag,
  currentURL,
}: {
  resetMeterStatusAPIFlag: () => void
  currentURL: string
}) => {
  const { siteId } = useParams<{ siteId: string }>()
  const dispatch = useDispatch()

  const meterStatusById = useSelector((state: FTState) =>
    selectRTMeterById(state),
  )
  const siteHealthRequestStatus = useSelector((state: SiteType) =>
    selectSiteHealthRequestStatus(state),
  )
  const meterListEntity = useSelector((state: MeterHealthType) =>
    selectMeterListWithHealthEntity(state),
  )

  const renderTableRow = ({
    id,
    name,
    panelNames,
    labeledTotal,
    meterType,
    model,
    healthStatusLastMonth,
    healthStatusCurrentMonth,
    resource,
    verified,
    active,
    deactivationReason,
    siteTimezone,
  }: RowType) => (
    <tr key={id}>
      <td className='name'>
        <StyledLink href={`${currentURL}/meters/${id}`}>{name}</StyledLink>
      </td>
      <td>{panelNames && panelNames.join(', ')}</td>
      <td>
        {onlineStatusCell(
          meterStatusById[id] ? meterStatusById[id].onlineStatus : 'loading',
        )}
      </td>
      <td>
        <StatusIconItem
          status={active}
          labelText={active ? 'Active' : deactivationReason || 'Other'}
        />
      </td>
      <td>{getVerified(meterType, verified)}</td>
      <td>
        {lastMesurementDateCell(
          meterStatusById[id] ?
            meterStatusById[id].originalLastMeasurementDate
          : 'loading',
          siteTimezone,
        )}
      </td>
      <td>{labeledTotal}</td>
      <td>{meterType}</td>
      <td>{model}</td>
      <td>{capitalCase(resource) || '-'}</td>
      <td>
        {renderHealthStatus(siteHealthRequestStatus, healthStatusLastMonth)}
      </td>
      <td>
        {renderHealthStatus(siteHealthRequestStatus, healthStatusCurrentMonth)}
      </td>
    </tr>
  )

  return (
    <DeprecatedEntityListPane
      striped
      entity={meterListEntity}
      flagResetters={[resetMeterStatusAPIFlag]}
      loadEntity={(args) => dispatch(meterActions.fetchMeterList(args))}
      loadEntityProps={{
        orderBy: {
          field: 'name',
          sort: 'ASC',
        },
        siteId,
      }}
      tableHeaders={getMetersTableHeaders(siteHealthRequestStatus)}
      renderTableRow={renderTableRow}
      showMetersFilter
      filterParam='resource'
      hideAllPagination
      paginationSize={[50, 20]}
    />
  )
}

export default Meters
