import Banner from './Banner'
import { CLOSE } from './labels'
import Styled from './styles'
import { UPDATE_SALESFORCE_STATES } from '../../../utils/constants'
import Modal from '../Modal'

type Props = {
  type: string
  heading: string
  text: string
  onSuccess: () => void
  showFooter: boolean
}
const { SUCCESS } = UPDATE_SALESFORCE_STATES
export default function UpdateSalesforceAlerts({
  type,
  heading,
  text,
  onSuccess,
  showFooter,
}: Props) {
  return (
    <Modal modalWidth='462px'>
      <Banner type={type} heading={heading} text={text} />
      {showFooter && (
        <Styled.Footer>
          {type === SUCCESS && (
            <Styled.SuccessButton type='primary' onClick={onSuccess}>
              {CLOSE}
            </Styled.SuccessButton>
          )}
        </Styled.Footer>
      )}
    </Modal>
  )
}
