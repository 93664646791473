import { makeListSelectorItem } from '../../utils'

const defaultThemeId = 'REDAPTIVE'
const idNameMap = {
  REDAPTIVE: 'Redaptive Theme',
  ELECTRON: 'Electron Theme',
  PROLOGIS: 'Prologis LightSmart Theme',
  IE_DUKE_REALTY: 'IE - Duke Realty Theme',
  IE_EMC: 'IE - EMC Theme',
  IE_ENERGY_CX: 'IE - Energy CX Theme',
  IE_ENERGY_NETWORK: 'IE - Energy Network Theme',
  IE_FSG: 'IE - FSG Theme',
  IE_IO_ENERGY: 'IE - IO Energy Theme',
  IE_JCI: 'IE - JCI Theme',
  IE_LANE_VALENTE: 'IE - Lane Valente Theme',
  IE_LUMENANT: 'IE - Lumenant Theme',
  IE_MID_AMERICAN_GROUP: 'IE - Mid American Group Theme',
  IE_NUWAVE: 'IE - NuWave Theme',
  IE_OZARK_LED: 'IE - Ozark LED Theme',
  IE_PROLOGIS: 'IE - Prologis, Inc. Theme',
  PROSTAR: 'IE - ProStar Theme',
}
const allThemes = Object.keys(idNameMap)

const getListSelectorItems = () =>
  Object.entries(idNameMap).map(([id, name]) => {
    const label = id === defaultThemeId ? `${name} (Default)` : name
    return makeListSelectorItem(id, label)
  })

const getThemeNameById = (id: string) => idNameMap[id] || 'Not Set'

const getListSelectorItemById = (id: string) =>
  makeListSelectorItem(id, getThemeNameById(id))

export default {
  idNameMap,
  defaultThemeId,
  allThemes,
  getListSelectorItemById,
  getListSelectorItems,
  getThemeNameById,
}
