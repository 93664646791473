import { combineReducers } from 'redux'
// Action Types
const types = {
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  HIDE_ALL_MESSAGES: 'HIDE_ALL_MESSAGES',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
}
type FTMessageIcon = {
  className: string
  title: string
  backgroundColor: string
}
type FTMessageIconType = 'info' | 'error' | 'success' | 'warning'
export type FTMessageIconLookup = Record<FTMessageIconType, FTMessageIcon>
export type FTMessageInput = {
  title: string
  description?: string
  type?: FTMessageIconType
  messageId?: string
}
type FTMessage = {
  title: string
  description: string
  type: FTMessageIconType
  messageId: string
  enableTimeout?: boolean
}
export type FTMessages = Array<FTMessage>
type FTMessageFlasherState = {
  messages: FTMessages
}
// Reducers
export const initialState: FTMessageFlasherState = {
  messages: [],
}

const getMessageDefaults = (): FTMessage => ({
  title: '',
  description: '',
  type: 'info',
  messageId: Date.now().toString(),
})

function messages(state = initialState.messages, action) {
  switch (action.type) {
    case types.HIDE_MESSAGE:
      return state.filter(
        (message: FTMessage) => action.value !== message.messageId,
      )

    case types.HIDE_ALL_MESSAGES:
      return []

    case types.SHOW_MESSAGE:
      return [...state, { ...getMessageDefaults(), ...action.value }]

    default:
      return state
  }
}

export default combineReducers({
  messages,
}) // Action Creators

export const actions = {
  hideMessage: (value: string) => ({
    type: types.HIDE_MESSAGE,
    value,
  }),
  hideMessages: () => ({
    type: types.HIDE_ALL_MESSAGES,
  }),
  showMessage: (value: FTMessageInput) => ({
    type: types.SHOW_MESSAGE,
    value,
  }),
}
