import { useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Redirect, Route, Switch } from 'react-router-dom'
import 'react-table-v6/react-table.css'

import AccountManagement from './AccountManagement'
import AuthorizationPlaygroundPage from './AuthorizationPlaygroundPage'
import Billing from './Billing'
import DesignLibrary from './DesignLibrary'
import FileContext from './FileContext'
import IntegrationSubmissions from './IntegrationSubmissions'
import LoginPage from './LoginPage'
import NotAuthorizedPage from './NotAuthorizedPage'
import NotFoundPage from './NotFoundPage'
import ProposalOperations from './ProposalOperations'
import SwaggerPage from './SwaggerPage'
import ToolsAndReports from './ToolsAndReports'
import Ernesto from './ToolsAndReports/Ernesto'
import HvacAssetManagerPage from './ToolsAndReports/HvacAssetManagerPage'
import LightingAsBuiltsManagerPage from './ToolsAndReports/LightingAsBuiltsManagerPage'
import VariantsPage from './VariantsPage'
import VersionPage from './VersionPage'
import UnauthorizedRedirect from '../authorization/components/UnauthorizedRedirect'
import UserPermissionsLoader from '../authorization/components/UserPermissionsLoader'
import AuthorizedFeatures from '../authorization/features'
import Analytics from '../components/Analytics'
import ModalContainer from '../containers/ModalContainer'
import AuthenticatedRoutes from '../routes/AuthenticatedRoutes'
import LoginRoute from '../routes/LoginRoute'
import PrivateRoute from '../routes/PrivateRoute'

const App = () => {
  const [vendorIngestedFile, setVendorIngestedFile] = useState(null)

  return (
    <FileContext.Provider
      value={{
        vendorIngestedFile,
        setVendorIngestedFile,
      }}
    >
      <div className='App'>
        <Route component={Analytics} />
        <AuthenticatedRoutes>
          <ModalContainer />
          <UserPermissionsLoader />

          <UnauthorizedRedirect
            from='/proposal-operations(.*)'
            to='/account-management'
            feature={AuthorizedFeatures.proposalOperations}
          />
          <UnauthorizedRedirect
            from='/account-management(.*)'
            to='/reports'
            feature={AuthorizedFeatures.accountManagement}
          />
          <UnauthorizedRedirect
            from='/reports(.*)'
            to='/not-authorized'
            feature={AuthorizedFeatures.toolsAndReports}
          />
          <UnauthorizedRedirect
            from='/integration/energy-star/submissions(.*)'
            to='/not-authorized'
            feature={AuthorizedFeatures.energyStarIntegration}
          />
        </AuthenticatedRoutes>

        <Switch>
          <LoginRoute path='/login' component={LoginPage} />
          <Route exact path='/help' component={LoginPage} />
          <Route exact path='/forgot' component={LoginPage} />
          <PrivateRoute
            path='/account-management'
            component={AccountManagement}
          />
          <PrivateRoute path='/reports' component={ToolsAndReports} />
          <PrivateRoute
            path='/proposal-operations'
            component={ProposalOperations}
          />
          <PrivateRoute path='/billing' component={Billing} />
          <PrivateRoute
            path='/integration/energy-star/:id'
            component={IntegrationSubmissions}
          />
          <PrivateRoute path='/design-library' component={DesignLibrary} />

          <PrivateRoute
            exact
            path='/authorization-playground'
            component={AuthorizationPlaygroundPage}
          />
          <PrivateRoute exact path='/swagger' component={SwaggerPage} />
          <PrivateRoute
            exact
            path='/tools/hvac-asset-manager'
            component={HvacAssetManagerPage}
          />
          <PrivateRoute
            exact
            path='/tools/lighting-as-builts-manager'
            component={LightingAsBuiltsManagerPage}
          />
          <PrivateRoute path='/ernesto' component={Ernesto} />
          <PrivateRoute exact path='/variants' component={VariantsPage} />

          <PrivateRoute
            exact
            path='/not-authorized'
            component={NotAuthorizedPage}
          />

          <Route exact path='/version' component={VersionPage} />

          <Redirect from='/' to='/account-management' />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </FileContext.Provider>
  )
}

export default App
