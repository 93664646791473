import styled from 'styled-components'

import PendingIcon from './Icons/PendingIcon'

export default styled(PendingIcon)`
  font-size: 16px;
  line-height: 17px;
  display: inline;
  vertical-align: middle;
`
