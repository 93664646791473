type FTProps = {
  color?: string
}
export default ({ color = '#DFAB56' }: FTProps) => (
  <svg fill='none' height='16' viewBox='0 0 14 14' width='16'>
    <path
      d='M1 1.25L7 6.75L13 1.25'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
