/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  ButtonsStyles,
  SuccessArrowIconStyles,
  UploadSuccessStyles,
} from './UploadModal'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import features from '../../../authorization/features'
import Button from '../../../components/Button'
import FormSection from '../../../components/FormSection'
import ArrowUpwardIcon from '../../../components/Icons/ArrowUpwardIcon'
import SuccessArrowIcon from '../../../components/Icons/SuccessArrowIcon'
import Spinner from '../../../components/Spinner'
import {
  actions as uploadUtilityProfileDataActions,
  selectUtilityProfileDataEntity,
} from '../../../ducks/utilityData/uploadUtilityProfileData'
import usePrevious from '../../../hooks/usePrevious'
import sampleProfileData from '../../../mockData/utilityData/sampleProfileFile'
import {
  ArrowUpwardIconStyles,
  Col1Styles,
  DropzoneStyles,
  ErrorMessagesHeadingStyles,
  ErrorMessagesListHeadingStyles,
  ErrorMessagesListStyles,
  FilePathStyled,
  FormSectionInnerStyles,
  ReportProblemIconStyled,
  TryAgainStyles,
  UploadAssetRecordsListStyled,
} from '../LightingAsBuiltsManagerPage'

const UtilityAccountsComponent = (props) => {
  const { actions, isUploading, error, uploadResponse, onClose } = props
  const downloadRef = React.useRef()
  const downloadSampleProfileDataRef = React.useRef()
  const [fileName, setFileName] = React.useState('')
  const prevValue = usePrevious({
    isUploading,
  })
  const handleUtilityProfileDataDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (file.name.endsWith('.csv')) {
          setFileName(file.name)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {
    acceptedFiles,
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleUtilityProfileDataDrop,
  })
  React.useEffect(() => {
    actions.resetUtilityProfileDataState()
  }, [])

  const resetForm = () => {
    setFileName('')
    actions.resetUtilityProfileDataState()
  }

  const downloadFileData = () => downloadRef.current.link.click()

  const downloadSampleProfileDataFile = () =>
    downloadSampleProfileDataRef.current.link.click()

  useEffect(() => {
    if (prevValue && prevValue.isUploading && !isUploading && !error) {
      onClose({
        refetchList: true,
      })
    }
  }, [prevValue])
  return (
    <FeatureValidator feature={features.uploadProfileData}>
      <Col1Styles>
        <FormSection>
          <FormSectionInnerStyles>
            {!!error && (
              <>
                <ErrorMessagesHeadingStyles>
                  <ReportProblemIconStyled color='#C70D08' inline />
                  <b>
                    {fileName}
                    {' upload failed.'}
                  </b>
                </ErrorMessagesHeadingStyles>
                <ErrorMessagesListHeadingStyles>
                  No records were stored. Please fix the following errors and
                  try again:
                </ErrorMessagesListHeadingStyles>
                <ErrorMessagesListStyles>
                  <div>{error}</div>
                </ErrorMessagesListStyles>
                <TryAgainStyles>
                  <Button onClick={resetForm} primary>
                    Reset
                  </Button>
                </TryAgainStyles>
              </>
            )}
            {!error && (
              <>
                <b>Upload Utility Accounts</b>
                <UploadAssetRecordsListStyled>
                  <li>
                    This upload will assign a utility account and meter to a
                    customer site.
                  </li>
                  <li>
                    Download the sample file
                    <Link to='#' onClick={downloadSampleProfileDataFile}>
                      {' '}
                      here
                    </Link>
                  </li>
                  <CSVLink
                    data={sampleProfileData}
                    ref={downloadSampleProfileDataRef}
                    filename='new-profile-upload.csv'
                  />
                  <li>The upload file must be a .csv.</li>
                  <li>
                    Any rows with missing required fields will be skipped.
                  </li>
                  <li>
                    DO NOT CLOSE THIS WINDOW while upload is in progress,
                    otherwise you will lose access to the upload status.
                  </li>
                </UploadAssetRecordsListStyled>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <DropzoneStyles
                  {...getRootProps({
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  })}
                >
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input name='file' {...getInputProps()} />
                  {isUploading && (
                    <div>
                      <Spinner inline size='micro' />{' '}
                      <b>Upload in progress...</b>
                    </div>
                  )}
                  {!isUploading && (
                    <ArrowUpwardIconStyles>
                      <ArrowUpwardIcon />
                    </ArrowUpwardIconStyles>
                  )}
                  <FilePathStyled>
                    {fileName || 'Drag file to upload'}
                  </FilePathStyled>
                  {!!uploadResponse && !isUploading && (
                    <UploadSuccessStyles>
                      <SuccessArrowIconStyles>
                        <SuccessArrowIcon />
                        <b>Upload Complete</b>
                      </SuccessArrowIconStyles>
                      <ButtonsStyles>
                        <Button primary onClick={resetForm}>
                          Upload another file
                        </Button>
                        <Button primary onClick={downloadFileData}>
                          Download Result
                        </Button>
                        <CSVLink
                          data={uploadResponse}
                          ref={downloadRef}
                          filename={`${fileName.split('.')[0]}-result.csv`}
                        />
                      </ButtonsStyles>
                    </UploadSuccessStyles>
                  )}
                  {!fileName && (
                    <Button primary onClick={open}>
                      Choose File
                    </Button>
                  )}
                  {fileName && !isUploading && !uploadResponse && (
                    <ButtonsStyles>
                      <Button onClick={resetForm}>Cancel</Button>
                      <Button
                        primary
                        onClick={() => {
                          actions.postUtilityProfileData({
                            file: acceptedFiles[0],
                          })
                        }}
                      >
                        Upload
                      </Button>
                    </ButtonsStyles>
                  )}
                </DropzoneStyles>
              </>
            )}
          </FormSectionInnerStyles>
        </FormSection>
      </Col1Styles>
    </FeatureValidator>
  )
}

const mapStateToProps = (state) => {
  const utilityProfileDataEntity = selectUtilityProfileDataEntity(state)
  const {
    uploadResponse,
    meta: { error, isUploading },
  } = utilityProfileDataEntity
  return {
    isUploading,
    error,
    uploadResponse,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(uploadUtilityProfileDataActions, dispatch) },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UtilityAccountsComponent),
)
