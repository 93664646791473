import styled from 'styled-components'

import Icons from './index'

const InActiveFieldIcon = styled(Icons.MaterialIcon).attrs({
  children: 'warning',
})`
  color: #c80a08;
`
export default InActiveFieldIcon
