import { ComponentProps, ComponentType, ReactElement } from 'react'
import {
  Link,
  Route,
  RouteComponentProps,
  useLocation,
  withRouter,
} from 'react-router-dom'
import styled, { css } from 'styled-components'

import TabContent2, { TabContentStyled } from './TabContent2'
import type { FTRouterMatch, FTWithRouter } from '../../types'
import { colors } from '../../utils/themes'

export type FTTab = {
  actionLink?: {
    href: string
    text: string
    withDefaultTab?: boolean
  }
  tabId: string
  text: string
  TextSuffix?: (arg0: { tab: FTTab }) => ReactElement<ComponentProps<any>, any>
  title?: string
  meta?: string
  render?: () => ReactElement<ComponentProps<any>, any>
}
type FTProps = {
  tabs: Array<FTTab>
  attachQueryParamsToLinks?: boolean
} & FTWithRouter
const ActionLinkStyled = styled.div`
  float: right;

  a {
    padding: 10px 0 10px 20px;
  }
`
export const TabPaneStyled = styled.div`
  padding-bottom: 50px;
  width: 100%;

  ${TabContentStyled} {
    clear: both;
    margin-top: 30px;
  }
`
export const TabPaneSelectorStyled = styled.div`
  border-bottom: 1px solid #c7c7c7;
  box-sizing: border-box;
  font-size: 14px;
  height: 42px;
  user-select: none;

  a {
    color: #337ab7;
  }
`
const TabPaneItemActiveStyles = css`
  border: 1px solid #c7c7c7;
  border-bottom-color: #fff;
  color: ${colors.blue5};
  font-weight: 600;
  letter-spacing: 0.2px;
`
const TabPaneTabStyled: ComponentType<{
  active: boolean
}> = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${colors.blue};
  cursor: pointer;
  font-family: 'Public Sans', sans-serif;
  float: left;
  padding: 10px 20px;

  a:hover {
    text-decoration: underline;
  }

  ${({ active }) => active && TabPaneItemActiveStyles};
`

const TabPane = (props: FTProps & RouteComponentProps) => {
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const {
    history,
    match: { path },
    attachQueryParamsToLinks,
  } = props

  const renderActionLink = (tabId, activeTabId, link) => {
    if (!link) {
      return null
    }

    const { href, text, withDefaultTab } = link

    if ((!activeTabId && !withDefaultTab) || tabId !== activeTabId) {
      return null
    }

    return (
      <ActionLinkStyled>
        <Link href={href} to={href}>
          {text}
        </Link>
      </ActionLinkStyled>
    )
  }

  const renderPane = (paneProps: { match: FTRouterMatch }) => {
    const {
      match: {
        params: { tabId: currentTabId },
      },
    } = paneProps
    const {
      match: { url },
      tabs,
    } = props
    const activeTab =
      (currentTabId && tabs.find(({ tabId }) => tabId === currentTabId)) ||
      tabs[0]
    return (
      <TabPaneStyled>
        <TabPaneSelectorStyled>
          {tabs.map((tab) => {
            const { actionLink, tabId, text, TextSuffix } = tab
            const active = tabId === activeTab.tabId
            let href = `${url}/${tabId}`
            if (attachQueryParamsToLinks) {
              href += `?${queryParams.toString()}`
            }
            return (
              <div key={tabId}>
                <TabPaneTabStyled
                  data-testid={`tab-${tabId}`}
                  active={active}
                  onClick={() => history.push(href)}
                >
                  {!active && <Link to={href}>{text}</Link>}
                  {active && text}
                  {TextSuffix && <TextSuffix tab={tab} />}
                </TabPaneTabStyled>
                {renderActionLink(tab, activeTab.tabId, actionLink)}
              </div>
            )
          })}
        </TabPaneSelectorStyled>
        <TabContent2 title={activeTab.title} meta={activeTab.meta}>
          {!!activeTab.render && activeTab.render()}
        </TabContent2>
      </TabPaneStyled>
    )
  }

  return <Route path={`${path}/:tabId?`} render={renderPane} />
}

export default withRouter(TabPane)
