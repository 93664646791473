import { buffers, END, eventChannel } from 'redux-saga'
import { fork, put, race, take } from 'redux-saga/effects'

import { logoutUser, sessionTimeout as actionTimeout } from '../actions/login'
import { consoleApiUrl, loginToken } from '../api'
import { STORAGE_LOGIN_TOKEN } from '../constants'
import * as types from '../constants/actionTypes'
import * as errors from '../constants/error'
import * as statuses from '../constants/status'
import { SOMETHING_WENT_WRONG } from '../constants/strings'
import { actions as modalActions } from '../ducks/modal'

const getErrorMessage = (error) => {
  const { message } = error

  switch (message) {
    case errors.ERR_REQUEST_TIMEOUT:
      return 'The request took too long. Please try again.'

    case errors.ERR_SESSION_TIMEOUT:
    case errors.ERR_SERVER_ERROR:
    case errors.ERR_REQUEST_ERROR:
    case errors.ERR_OTHER:
      return SOMETHING_WENT_WRONG

    case errors.ERR_UNAUTHORIZED:
      return 'You are not authorized to perform this action.'

    default:
      return message
  }
}

// handleSagaError accepts an error action to emit the error with. If the Error
// is a Session Timeout or Server Error, we display the appropriate modal.
export function* handleSagaError(
  action: string | ((...args: Array<any>) => any),
  error: any,
  customizedErrorBody?: string,
  noErrorAction?: boolean,
  props?: Record<string, any>,
): any {
  const message = getErrorMessage(error)

  if (typeof action === 'string') {
    yield put({
      type: action,
      error: message,
      ...props,
    })
  } else {
    yield put(
      action({
        error: message,
      }),
    )
  }

  if (
    error.message === errors.ERR_SESSION_TIMEOUT &&
    localStorage.getItem(STORAGE_LOGIN_TOKEN)
  ) {
    yield put(actionTimeout())
  } else if (error.message === errors.ERR_SERVER_ERROR) {
    yield put(
      modalActions.showServerError({ customizedErrorBody, noErrorAction }),
    )
  }
}
// Deprecated
export function* handleError(
  collection: string,
  error: Error,
): Generator<any, void, any> {
  const message = getErrorMessage(error)
  yield put({
    type: types.UPDATE_STATUS,
    [collection]: {
      status: statuses.ERROR,
      error: message,
    },
  })

  if (error.message === errors.ERR_SESSION_TIMEOUT) {
    yield put(actionTimeout())
  } else if (error.message === errors.ERR_SERVER_ERROR) {
    yield put(modalActions.showServerError())
  }
}
export function* sessionTimeout(): Generator<any, void, any> {
  yield put(logoutUser())
}
export function* watchSessionTimeout(): Generator<any, void, any> {
  while (true) {
    const r = yield race({
      session: take(types.SESSION_TIMEOUT),
      logout: take(types.LOGOUT_SUCCESS),
    })

    if (r.session && localStorage.getItem(STORAGE_LOGIN_TOKEN)) {
      yield fork(sessionTimeout)
    }
  }
}

/* https://decembersoft.com/posts/file-upload-progress-with-redux-saga/ */
export function createUploadFileChannel(endpoint: string, file: File) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest()

    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total
        emitter({
          progress,
        })
      }
    }

    const onFailure: (...args: Array<any>) => any = (err) => {
      emitter({
        err,
      })
      emitter(END)
    }

    xhr.upload.addEventListener('progress', onProgress, false)
    xhr.upload.addEventListener('error', onFailure)
    xhr.upload.addEventListener('abort', onFailure)

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr

      if (readyState === 4) {
        if (status > 199 && status < 300) {
          emitter({
            success: true,
          })
          emitter(END)
        } else {
          let message = 'Something went wrong'

          try {
            const resp = JSON.parse(xhr.responseText)

            if (resp && resp.message) {
              const { message: respMsg } = resp
              message = respMsg
            }
          } catch (e) {
            message = SOMETHING_WENT_WRONG
          }

          onFailure(message)
        }
      }
    }

    xhr.open('POST', `${consoleApiUrl()}${endpoint}`, true)
    xhr.setRequestHeader('Authorization', `Token ${loginToken()}`)
    const formData = new FormData()
    formData.append('file', file)
    xhr.send(formData)
    return () => {
      xhr.upload.removeEventListener('progress', onProgress)
      xhr.upload.removeEventListener('error', onFailure)
      xhr.upload.removeEventListener('abort', onFailure)

      xhr.onreadystatechange = () => {}

      xhr.abort()
    }
  }, buffers.sliding(2))
}
