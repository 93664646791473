import Feature from './models/feature'
// eslint-disable-next-line
import {
  AdminResource,
  CreatableResource,
  EditableResource,
  InspectableResource,
  ManageableResource,
} from './models/resourceAbility'
/*
  AuthorizedFeatures serves as a centralized location for a collection of
  Features that can be referenced throughout the application. It is effectively
  config defined in code.

  In the future, we may wish to extract this into a config file, perhaps in
  YAML.

  TODO: At a later time consider evaluating all features against permissions
  when the UserPermissions are loaded from the server. This could happen in the
  success state of the reducer.
*/

export default class AuthorizedFeatures {
  static accountManagement = new Feature([
    new InspectableResource('/admin/customers'),
    new InspectableResource('/admin/sites'),
    new InspectableResource('/meters'),
    new InspectableResource('/admin/usersummaries'),
    new InspectableResource('/meters/unassigned'),
  ])

  static editMeters = new Feature([
    new EditableResource('/meters/unassigned'),
    new EditableResource('/circuits'),
    new AdminResource('/panels'),
    new AdminResource('/equipment'),
  ])

  static inspectMeters = new Feature([new InspectableResource('/meters')])

  static createMeters = new Feature([new CreatableResource('/meters/assign')])

  static editMeterConfig = new Feature([new EditableResource('/meters')])

  static manageDashboardUserPermissions = new Feature([
    new ManageableResource('/admin/users'),
    new ManageableResource('/admin/user-associations'),
  ])

  static manageAllUserPermissions = new Feature([
    new ManageableResource('/admin/users'),
  ])

  static createUsers = new Feature([new CreatableResource('/signups')])

  static updateUsers = new Feature([new EditableResource('/admin/users')])

  static toolsAndReports = new Feature([
    new InspectableResource('/meter/status/last-report-date'),
    new InspectableResource('/meter/status'),
    new InspectableResource('/reports'),
    new CreatableResource('/admin/csv/ltg-as-built'),
    new CreatableResource('/admin/csv/ltg-control-type'),
    new CreatableResource('/admin/csv/ltg-measure-code'),
    new CreatableResource('/admin/hvac'),
  ])

  static uploadHvac = new Feature([new CreatableResource('/admin/hvac')])

  static lightingAsBuiltsManager = new Feature([
    new CreatableResource('/admin/csv/ltg-as-built'),
    new CreatableResource('/admin/csv/ltg-control-type'),
    new CreatableResource('/admin/csv/ltg-measure-code'),
  ])

  static uploadLightingAsBuilts = new Feature([
    new CreatableResource('/admin/csv/ltg-as-built'),
  ])

  static uploadLightingControlsTypeGuide = new Feature([
    new CreatableResource('/admin/csv/ltg-control-type'),
  ])

  static uploadMeasureCodeGuideForm = new Feature([
    new CreatableResource('/admin/csv/ltg-measure-code'),
  ])

  static manageUtilityData = new Feature([
    new CreatableResource('/admin/accounts'),
  ])

  static uploadInvoiceData = new Feature([
    new AdminResource('/invoice-usage/csv/upload'),
  ])

  static uploadProfileData = new Feature([
    new AdminResource('/utility-profile/csv/upload'),
  ])

  static uploadUtilityData = new Feature([new AdminResource('/csv')])

  static uploadAuditVendor = new Feature([new InspectableResource('/xlsx')])

  static installedMeters = new Feature([new InspectableResource('/reports')])

  static rtMeterStatus = new Feature([
    new InspectableResource('/meter/status/last-report-date'),
  ])

  static offlineMeters = new Feature([new InspectableResource('/meter/status')])

  static meterExport = new Feature([new CreatableResource('/admin/jobs')])

  static meterInstallReport = new Feature([
    new CreatableResource('/admin/reports/meter-installation'),
  ])

  static meterInstallSubmissions = new Feature([
    new InspectableResource('/meter/install'),
  ])

  static meterDetail = new Feature([new InspectableResource('/meters')])

  static proposalOperations = new Feature([
    new InspectableResource('/customer/proposal'),
    new InspectableResource('/spec-sheets/list'),
    new InspectableResource('/spec-sheets/upload-request'),
  ])

  static editProposalFieldMappings = new Feature([
    new EditableResource('/opportunity/field-mappings'),
  ])

  static editProposalScenario = new Feature([
    new EditableResource('/opportunity/scenario'),
  ])

  static createProposalScenario = new Feature([
    new CreatableResource('/opportunity/scenario'),
  ])

  static createProposalBatch = new Feature([
    new CreatableResource('/opportunity/batch-analysis'),
  ])

  static specSheets = new Feature([
    new InspectableResource('/spec-sheets/list'),
    new InspectableResource('/spec-sheets/upload-request'),
  ])

  static proposalsEngine = new Feature([
    new InspectableResource('/customer/proposal'),
  ])

  static billing = new Feature([
    new InspectableResource('/billing/variable-bills'),
  ])

  static billingThisMonthReadOnly = new Feature([
    new InspectableResource('/billing/variable-bills'),
    new InspectableResource('/billing/variable-bills/.*/comments'),
  ])

  static managePanels = new Feature([
    new ManageableResource('/admin/sites'),
    new ManageableResource('/panels'),
  ])

  static billingThisMonthIssueInvestigator = new Feature([
    new EditableResource('/billing/savings-orc/manual'),
    new CreatableResource('/billing/variable-bills/investigate/.*'),
    new CreatableResource('/billing/variable-bills/resolve/.*'),
    new CreatableResource('/billing/variable-bills/.*/comment'),
    new InspectableResource('/billing/variable-bills/reasons'),
  ])

  static billingThisMonthPerformanceReviewer = new Feature([
    new EditableResource('/billing/savings-orc/manual'),
    new CreatableResource('/billing/variable-bills/investigate/.*'),
    new CreatableResource('/billing/variable-bills/resolve/.*'),
    new CreatableResource('/billing/variable-bills/return'),
    new EditableResource('/billing/variable-bills/review'),
    new EditableResource('/billing/variable-bills/review/.*'),
    new CreatableResource('/billing/variable-bills/.*/comment'),
    new InspectableResource('/billing/variable-bills/reasons'),
    new InspectableResource('/billing/accruals/revenue-excel'),
  ])

  static billingThisMonthInvoiceApprover = new Feature([
    new EditableResource('/billing/savings-orc/manual'),
    new CreatableResource('/billing/variable-bills/investigate/.*'),
    new CreatableResource('/billing/variable-bills/return'),
    new CreatableResource('/billing/variable-bills/resolve/.*'),
    new EditableResource('/billing/variable-bills/review'),
    new EditableResource('/billing/variable-bills/review/.*'),
    new CreatableResource('/billing/variable-bills/approve'),
    new CreatableResource('/billing/variable-bills/.*/comment'),
    new InspectableResource('/billing/variable-bills/reasons'),
    new InspectableResource('/billing/accruals/revenue-excel'),
  ])

  static energyStarIntegration = new Feature([
    new InspectableResource('/energy-star/customers'),
    new CreatableResource('/energy-star/customers'),
    new InspectableResource('/energy-star/sites'),
    new CreatableResource('/energy-star/sites'),
    new InspectableResource('/energy-star/submissions'),
    new CreatableResource('/energy-star/submissions'),
  ])

  static isrFileProcessor = new Feature([
    new CreatableResource('/billing/isr/isr-files'),
  ])
}
