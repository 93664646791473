import { ReactNode } from 'react'
import styled from 'styled-components'

const WrapperStyles = styled.div`
  height: 100%;
  padding-bottom: 120px;
  user-select: none;
`
const InnerStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  width: 1200px;
`

const ActionCardsPage = (props: { children: ReactNode | undefined }) => {
  const { children = null } = props

  return (
    <WrapperStyles>
      <InnerStyles>{children}</InnerStyles>
    </WrapperStyles>
  )
}

export default ActionCardsPage
