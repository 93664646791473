import { ComponentType, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import ListSelector, { SelectStyles } from './ListSelector'
import { colors } from '../utils/themes'

type FTProps = {
  hideDropdownPagination: boolean
  canNextPage: boolean
  canPreviousPage: boolean
  gotoPage: (...args: Array<any>) => any
  nextPage: (...args: Array<any>) => any
  pageIndex: number
  pageOptions: {
    length: number
  }
  pageSize: number
  pageSizeChoices?: Array<number>
  pageWindow?: number
  previousPage: (...args: Array<any>) => any
  setPageSize: (...args: Array<any>) => any
}
const Paginator2Styled = styled.div`
  margin: 20px 0;
`
const MainContentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`
const PageSizeSelectorWrapperStyled = styled.div`
  float: right;

  ${SelectStyles} {
    margin-left: 8px;
    width: 120px;
  }
`
const PageSizeSelectorInnerStyled = styled.div`
  align-items: center;
  display: flex;
`
const PrevNextButtonDisabledStyles = css`
  background-color: #f5f5f5;
  color: ${colors.blue13};
`
const PrevNextButtonStyled: ComponentType<{
  disabled: boolean
}> = styled.button`
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  height: 28px;
  margin: 0 12px;
  width: 28px;

  ${({ disabled }) => disabled && PrevNextButtonDisabledStyles};
`
const PageNumberActiveStyles = css`
  background-color: ${colors.orange2};
  color: #fff;
  font-weight: 600;
`
const PageNumbersStyled = styled.div`
  display: flex;
`
const PageNumberStyled: ComponentType<{
  active: boolean
}> = styled.div`
  align-items: center;
  border-radius: 100px;
  color: ${colors.blue12};
  cursor: pointer;
  display: flex;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  justify-content: center;
  padding: 0 6px;
  margin: 0 4px;
  height: 24px;
  min-width: 24px;

  ${({ active }) => active && PageNumberActiveStyles};

  &:last-child {
    margin-right: 0;
  }
`
const EllipsisStyled = styled.div`
  display: inline-block;
  margin: 0 8px;
`
export default (props: FTProps) => {
  const {
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageIndex,
    pageOptions: { length: total },
    pageSize,
    pageSizeChoices = [10, 20, 50, 100, 500, 1000],
    pageWindow = 5,
    previousPage,
    setPageSize,
    hideDropdownPagination,
  } = props
  const halfWindow = Math.floor(pageWindow / 2)
  let start
  let end

  if (total >= pageWindow && total <= pageWindow + 2) {
    start = 1
    end = total
  } else if (pageIndex > total - pageWindow) {
    start = Math.max(1, total - pageWindow + 1)
    end = total
  } else {
    start = pageIndex + 1 < pageWindow ? 1 : pageIndex - halfWindow + 1
    end =
      pageIndex + 1 < pageWindow ?
        Math.min(pageWindow, total)
      : Math.min(pageIndex + halfWindow + 1, total)
  }

  const showFirstPage = pageIndex + 1 >= pageWindow && total !== pageWindow
  const showLastPage =
    pageIndex + 1 <= total - pageWindow + 1 && total !== pageWindow
  const MainPageNumbers = useCallback(
    () => (
      <PageNumbersStyled>
        {new Array(end - start + 1).fill(null).map((_, index) => {
          const pageNumber = index + start - 1
          return (
            <PageNumberStyled
              data-testid={`page-number-${pageNumber + 1}`}
              active={pageNumber === pageIndex}
              key={pageNumber}
              onClick={() => gotoPage(pageNumber)}
            >
              {pageNumber + 1}
            </PageNumberStyled>
          )
        })}
      </PageNumbersStyled>
    ),
    [start, end, pageIndex],
  )
  const pageSizeItems = useMemo(
    () =>
      pageSizeChoices.map((pageSizeChoice) => ({
        id: `${pageSizeChoice}`,
        name: `${pageSizeChoice} Rows`,
      })),
    [pageSizeChoices],
  )
  const selectedPageSizeItem = useMemo(
    () =>
      pageSizeItems.find(
        (pageSizeItem) => Number(pageSizeItem.id) === pageSize,
      ),
    [pageSize, pageSizeChoices],
  )
  const handleUpdateSelectedPage = useCallback(
    ({ value }: { value: string }) => {
      setPageSize(Number(value))
    },
    [],
  )
  return (
    <Paginator2Styled data-testid='pagination'>
      {!hideDropdownPagination && (
        <PageSizeSelectorWrapperStyled data-testid='page-size-selector'>
          <PageSizeSelectorInnerStyled>
            Show:
            <ListSelector
              directionUp
              items={pageSizeItems}
              updateValue={handleUpdateSelectedPage}
              selectedItem={selectedPageSizeItem}
              unsettable={false}
            />
          </PageSizeSelectorInnerStyled>
        </PageSizeSelectorWrapperStyled>
      )}
      <MainContentStyled data-testid='page-number-selector'>
        <PrevNextButtonStyled
          data-testid='previous-page'
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          {'<'}
        </PrevNextButtonStyled>
        {showFirstPage && (
          <>
            <PageNumberStyled
              onClick={() => gotoPage(0)}
              active={pageIndex === 0}
            >
              1
            </PageNumberStyled>
            <EllipsisStyled>...</EllipsisStyled>
          </>
        )}
        <MainPageNumbers />
        {showLastPage && (
          <>
            <EllipsisStyled>...</EllipsisStyled>
            <PageNumberStyled
              onClick={() => gotoPage(total - 1)}
              active={pageIndex === total - 1}
            >
              {total}
            </PageNumberStyled>
          </>
        )}
        <PrevNextButtonStyled
          data-testid='next-page'
          disabled={!canNextPage}
          onClick={() => nextPage()}
        >
          {'>'}
        </PrevNextButtonStyled>
      </MainContentStyled>
    </Paginator2Styled>
  )
}
