import axios from 'axios'
import FileSaver from 'file-saver'
import { call, delay, put, takeEvery } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError, queryStringify } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { isVariantActive } from '../utils'

export type FTMeterInstallReportState = {
  error: string
  loading: boolean
}
export type FTGetInstallReportParams = {
  filename: string
  fromDate: string
  toDate: string
}
// Action Types
export const types = {
  GET_INSTALL_REPORT: 'GET_INSTALL_REPORT',
  GET_INSTALL_REPORT_SUCCESS: 'GET_INSTALL_REPORT_SUCCESS',
  GET_INSTALL_REPORT_ERROR: 'GET_INSTALL_REPORT_ERROR',
  RESET_INSTALL_REPORT_STATE: 'RESET_INSTALL_REPORT_STATE',
}
export const initialState = {
  error: '',
  loading: false,
}
export const actions = {
  getInstallReport: (payload: FTGetInstallReportParams) => ({
    type: types.GET_INSTALL_REPORT,
    ...payload,
  }),
  getInstallReportSuccess: (payload: any) => ({
    type: types.GET_INSTALL_REPORT_SUCCESS,
    csv: payload,
  }),
  getInstallReportError: (payload: { message: string }) => ({
    type: types.GET_INSTALL_REPORT_ERROR,
    error: payload.message,
  }),
}

const reducer = (
  state: FTMeterInstallReportState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case types.GET_INSTALL_REPORT:
      return { ...state, error: '', loading: true }

    case types.GET_INSTALL_REPORT_SUCCESS:
      return { ...state, loading: false }

    case types.GET_INSTALL_REPORT_ERROR:
      return { ...state, error: action.error, loading: false }

    default:
      return state
  }
}

export default reducer // API

export const API = {
  getInstallReport({ fromDate, toDate, filename }: FTGetInstallReportParams) {
    if (isVariantActive('1636-mock')) {
      const blob = new Blob(['"mockField1","mockfield2"\n"value1","value2"'], {
        type: 'text/csv',
      })
      return Promise.resolve(blob).then((response) =>
        FileSaver.saveAs(response, filename),
      )
    }

    const baseUrl = `${consoleApiUrl()}/admin/reports/meter-installation`
    const query = queryStringify({
      fromDate,
      toDate,
    })
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => FileSaver.saveAs(response.data, filename))
      .catch(handleAxiosError)
  },
}

function* getInstallReportSaga(payload: FTGetInstallReportParams) {
  try {
    if (isVariantActive('1636-mock')) {
      yield delay(1000)
    }

    const response = yield call(API.getInstallReport, payload)
    yield put(actions.getInstallReportSuccess(response))
  } catch (error) {
    yield handleSagaError(actions.getInstallReportError, error)
  }
}

export const sagas = [takeEvery(types.GET_INSTALL_REPORT, getInstallReportSaga)]
