import axios from 'axios'
import { capitalCase } from 'capital-case'
import { schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeEvery } from 'redux-saga/effects'

import type { FTFetchEntityList } from './types'
import {
  addMetaToResponse,
  initialEntityState,
  makeActionTypes,
  makeApiQueryString,
  makeSagaPayload,
  renderTimestamp,
} from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import { naturallySort } from '../utils'

export type FTUserSummary = {
  userId: string | null | undefined
  signupId: string | null | undefined
  lastActivity: string | null | undefined
  email: string
  username: string
  status: 'ACTIVE' | 'PENDING' | 'INACTIVE'
  groups: Array<string>
}
type FTUserSummaryEntityState = {
  byId: Record<string, FTUserSummary>
  allIds: Array<string>
  meta: {
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
    loading: boolean
  }
}
type FTState = {
  entities: {
    userSummaries: FTUserSummaryEntityState
  }
}
// Action Types
export const types = {
  ...makeActionTypes('FETCH_USER_SUMMARIES'),
  ...makeActionTypes('FETCH_USER_SUMMARY'),
  CLEAR_USER_SUMMARIES: 'CLEAR_USER_SUMMARIES',
}
// Utils
export const utils = {
  getUserType: (groups: Array<string> = []) => {
    if (
      !groups ||
      groups.length < 1 ||
      (groups.length === 1 && groups[0] === 'user')
    ) {
      return 'Standard User'
    }

    const groupNames = {
      admin: 'Administrator',
      // eslint-disable-next-line no-useless-computed-key
      ['denali-user']: 'Redaptive ONE User',
      // eslint-disable-next-line no-useless-computed-key
      ['denali-admin']: 'Redaptive ONE Admin',
      // eslint-disable-next-line no-useless-computed-key
      ['isr-processor-tool']: 'ISR Processor Tool',
    }
    return groups
      .filter((group) => group !== 'user')
      .map(
        (group) => groupNames[group] || capitalCase(group).replace(/-_/g, ' '),
      )
      .sort(naturallySort)
      .join(', ')
  },
  isAdmin: (user: FTUserSummary) =>
    user && user.groups && user.groups.includes('admin'),
  enhanceUserSummary: (userSummary: FTUserSummary) => ({
    ...userSummary,
    id: userSummary.userId || userSummary.signupId,
    status: capitalCase(userSummary.status),
    userType: utils.getUserType(userSummary.groups),
    lastLogin: renderTimestamp(userSummary.lastActivity),
  }),
}
// REDUCERS
export const userSummarySchema = new schema.Entity('userSummaries')

function userSummaryById(action) {
  return action.payload.entities.userSummaries || {}
}

function userSummaryAllIds(action) {
  return [...new Set(action.payload.result)]
}

function byId(state = initialEntityState.byId, action) {
  switch (action.type) {
    case types.CLEAR_USER_SUMMARIES:
      return {}

    case types.FETCH_USER_SUMMARIES_SUCCESS:
    case types.FETCH_USER_SUMMARY_SUCCESS:
      return userSummaryById(action)

    default:
      return state
  }
}

function allIds(state = initialEntityState.allIds, action) {
  switch (action.type) {
    case types.CLEAR_USER_SUMMARIES:
      return []

    case types.FETCH_USER_SUMMARIES_SUCCESS:
    case types.FETCH_USER_SUMMARY_SUCCESS:
      return userSummaryAllIds(action)

    default:
      return state
  }
}

function meta(state = initialEntityState.meta, action) {
  switch (action.type) {
    case types.FETCH_USER_SUMMARIES:
    case types.FETCH_USER_SUMMARY:
      return { ...state, loading: true }

    case types.FETCH_USER_SUMMARIES_ERROR:
    case types.FETCH_USER_SUMMARY_ERROR:
      return { ...state, loading: false }

    case types.FETCH_USER_SUMMARIES_SUCCESS:
    case types.FETCH_USER_SUMMARY_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    case types.CLEAR_USER_SUMMARIES:
      return initialEntityState.meta

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
}) // Action Creators

export const actions = {
  fetchUserSummaries: (params: {
    customerId?: string
    siteId?: string
    pageNumber?: number
    pageSize?: number
  }) => ({
    type: types.FETCH_USER_SUMMARIES,
    ...params,
  }),
  fetchUserSummary: (id: string) => ({
    type: types.FETCH_USER_SUMMARY,
    id,
  }),
  clearUserSummaries: () => ({
    type: types.CLEAR_USER_SUMMARIES,
  }),
}
// SELECTORS
export const getUserSummaries = (state: FTState): Array<FTUserSummary> =>
  state.entities.userSummaries.allIds.map(
    (id) => state.entities.userSummaries.byId[id],
  )
export const getUserSummary = (state: FTState, id: string) =>
  state.entities.userSummaries.byId[id]
export const getUserSummaryListEntity = (state: FTState) => ({
  items: getUserSummaries(state),
  meta: state.entities.userSummaries.meta,
})
export const getUserEntity = (state: FTState, id: string) => ({
  item: getUserSummary(state, id),
  meta: state.entities.userSummaries.meta,
})
// API
export class API {
  static fetchUserSummaries(params: FTFetchEntityList) {
    const { customerId, siteId } = params
    const query = makeApiQueryString(params, {
      customerId,
      siteId,
    })
    const url = `${consoleApiUrl()}/admin/usersummaries/list?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }
}

// Sagas
// NOTE: Implement the rest of fetchUserSummary flow if it gets implemented in API
function* fetchUserSummariesSaga(
  params: FTFetchEntityList,
): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchUserSummaries, params)
    const payload = makeSagaPayload(
      response,
      userSummarySchema,
      utils.enhanceUserSummary,
    )
    yield put({
      type: types.FETCH_USER_SUMMARIES_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_USER_SUMMARIES_ERROR, e)
  }
}

export const sagas = [
  takeEvery(types.FETCH_USER_SUMMARIES, fetchUserSummariesSaga),
]
