import { withRouter } from 'react-router-dom/cjs/react-router-dom'

import UpdateFormikForm from './UpdateMeterUtility'
import InfoSection from '../InfoSection'

const MeterUtilityAccount = ({
  meter,
  loading,
  location,
  handleUpdate,
  history,
  match: { url },
}) => {
  if (loading) return <div>Loading...</div>
  const { pathname } = location
  const utilityAccountResponseDto =
    meter?.meterDetailResponse?.utilityAccountResponseDto
  const fields =
    utilityAccountResponseDto ?
      [
        {
          label: 'Utility Name',
          value: utilityAccountResponseDto?.utilityName || '',
        },
        {
          label: 'Utility Account ID',
          value: utilityAccountResponseDto?.utilityAccountId || '',
        },
        {
          label: 'Utility Meter Make',
          value: utilityAccountResponseDto?.utilityMeterMake || '',
        },
        {
          label: 'Utility Meter Model',
          value: utilityAccountResponseDto?.utilityMeterModel || '',
        },
        {
          label: 'Utility Meter Serial Number',
          value: utilityAccountResponseDto?.utilityMeterSerialNumber || '',
        },
      ]
    : [
        {
          label: 'Utility Name',
          value: '',
        },
        {
          label: 'Utility Account ID',
          value: '',
        },
        {
          label: 'Utility Meter Make',
          value: '',
        },
        {
          label: 'Utility Meter Model',
          value: '',
        },
        {
          label: 'Utility Meter Serial Number',
          value: '',
        },
      ]
  const info = [
    {
      header: null,
      values: fields,
    },
  ]

  const goBackFromForm = () => {
    history.push(url)
  }

  const updateAndGoBack = (body) => {
    handleUpdate(body)
    goBackFromForm()
  }

  if (pathname.endsWith('/utility-account/edit')) {
    return (
      <UpdateFormikForm
        loading={loading}
        utilityAccountResponseDto={utilityAccountResponseDto}
        cancelHandler={goBackFromForm}
        handleUpdate={updateAndGoBack}
      />
    )
  }

  return <InfoSection info={info} />
}

export default withRouter(MeterUtilityAccount)
