import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import energyStarApis from './apis'
import { SubmitSubmissionsPayload } from './types'
import { selectors as authSelectors } from '../../ducks/auth'
import {
  getDefaultMonth,
  TAB_URL_ENUM_VALUES,
  TabUrlEnum,
} from '../../pages/IntegrationSubmissions/helpers/utils'
import { GROUPS } from '../../utils/constants'

const energyStarQueryKey = (queryNames: string[]) => [
  'energy-star',
  ...queryNames,
]

const sharedOptions = ({ queryName }: { queryName: string[] }) => ({
  queryKey: energyStarQueryKey(queryName),
  staleTime: 1000 * 20, // Cache for 20 sec
  refetchOnWindowFocus: false,
})

export const useEnergyStarCommonData = () => {
  const { pathname, search } = useLocation()

  const queryParams = new URLSearchParams(search)

  const activeYearMonthFromQuery = queryParams.get('activeMonth')
  const isActiveMonthValid = moment(
    activeYearMonthFromQuery,
    'YYYY-MM',
    true,
  ).isValid()
  const activeYearMonth = getDefaultMonth(activeYearMonthFromQuery)

  const tabNameFromPath = pathname.split('/')[4] as TabUrlEnum

  const activeSubmissionTab =
    TAB_URL_ENUM_VALUES.includes(tabNameFromPath) ? tabNameFromPath : (
      TabUrlEnum.UNDER_MAINTENANCE
    )

  const groups = useSelector(authSelectors.selectGroups)
  const isEnergyStarAdmin =
    groups ? groups.includes(GROUPS.ENERGY_STAR_ADMIN) : false

  return {
    isEnergyStarAdmin,
    queryClient: useQueryClient(),
    activeSubmissionTab,
    isActiveMonthValid,
    activeYearMonth,
  }
}

export const useSubmissionsDataQuery = () => {
  const { isEnergyStarAdmin, activeYearMonth, isActiveMonthValid } =
    useEnergyStarCommonData()
  return useQuery({
    queryFn: () =>
      energyStarApis.fetchSubmissions({
        yearMonth: activeYearMonth,
      }),
    ...sharedOptions({
      queryName: ['submissions', activeYearMonth],
    }),
    staleTime: 1000 * 60 * 60,
    enabled: !!isEnergyStarAdmin && !!isActiveMonthValid,
  })
}

export const useSubmitSubmissionsMutation = () => {
  const { queryClient } = useEnergyStarCommonData()
  return useMutation({
    mutationFn: (reqData: SubmitSubmissionsPayload) =>
      energyStarApis.uploadSubmissions(reqData),
    onSettled: () => {
      queryClient.invalidateQueries(energyStarQueryKey(['submissions']))
    },
  })
}

export const useReSyncSubmissionsMutation = () => {
  const { queryClient } = useEnergyStarCommonData()
  return useMutation({
    mutationFn: (reqData: SubmitSubmissionsPayload) =>
      energyStarApis.reSyncSubmissions(reqData),
    onSettled: () => {
      queryClient.invalidateQueries(energyStarQueryKey(['submissions']))
    },
  })
}
