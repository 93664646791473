type FTProps = {
  color?: string
}
export default ({ color = '#DFAB56' }: FTProps) => (
  <svg fill='none' height='16' viewBox='0 0 14 14' width='16'>
    <path
      d='M14.6511 6.82873L8.02866 0.861877L1.40625 6.82873'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
