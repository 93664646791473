import { STORAGE_LOGIN_TOKEN } from '../constants'
import { API_SOURCE } from '../utils/constants'

const QA_ENV = 'qa'
const DEV_ENV = 'dev'
const DEV2_ENV = 'dev2'
const STAGE_ENV = 'stage'
export const PROD_ENV = 'prod'
const LOCAL_ENV = 'local'
const LOCAL_DASHBOARD_PORT = import.meta.env.VITE_LOCAL_DASHBOARD_PORT || '3000'
const LOCAL_DASHBOARD_PROTOCOL =
  import.meta.env.VITE_LOCAL_DASHBOARD_PROTOCOL || 'http:'
const {
  VITE_DASHBOARD_URL_DEV,
  VITE_DASHBOARD_URL_DEV2,
  VITE_DASHBOARD_URL_PROD,
  VITE_DASHBOARD_URL_QA,
  VITE_DASHBOARD_URL_STAGE,
} = import.meta.env
export const APP_ENV = import.meta.env.VITE_DEPLOY_ENV || LOCAL_ENV
const DASHBOARD_URLS = {
  [DEV_ENV]: VITE_DASHBOARD_URL_DEV,
  [DEV2_ENV]: VITE_DASHBOARD_URL_DEV2,
  [LOCAL_ENV]: `${LOCAL_DASHBOARD_PROTOCOL}//localhost:${LOCAL_DASHBOARD_PORT}`,
  [PROD_ENV]: VITE_DASHBOARD_URL_PROD,
  [QA_ENV]: VITE_DASHBOARD_URL_QA,
  [STAGE_ENV]: VITE_DASHBOARD_URL_STAGE,
}
export const getDashboardUrl = () => DASHBOARD_URLS[APP_ENV]
export const getLogoUrl = () => {
  const { VITE_DEPLOY_ENV } = import.meta.env

  switch (VITE_DEPLOY_ENV) {
    case 'dev':
      return 'https://logo.dev.redaptive.com'

    case 'qa':
      return 'https://logo.qa.redaptive.com'

    case 'stage':
      return 'https://logo.stage.redaptive.com'

    case 'prod':
      return 'https://logo.redaptive.com'

    default:
      return 'https://logo.dev.redaptive.com'
  }
}
export const getDenaliUrl = () => {
  const { VITE_DEPLOY_ENV } = import.meta.env

  switch (VITE_DEPLOY_ENV) {
    case 'stage':
      return 'https://one.stage.redaptive.com'

    case 'qa':
      return 'https://one.qa.redaptive.com'

    case 'prod':
      return 'https://one.redaptive.com'

    case 'dev':
      return 'https://one.dev.redaptive.com'

    default:
      return `${LOCAL_DASHBOARD_PROTOCOL}//localhost:${LOCAL_DASHBOARD_PORT}`
  }
}
export const getEnergyUrl = () => {
  const { VITE_DEPLOY_ENV } = import.meta.env

  switch (VITE_DEPLOY_ENV) {
    case 'stage':
      return 'https://api-stage.redaptiveinc.com'

    case 'qa':
      return 'https://api-qa.redaptiveinc.com'

    case 'prod':
      return 'https://api.redaptiveinc.com'

    case 'dev':
      return 'https://api-dev.redaptiveinc.com'

    default:
      return `${LOCAL_DASHBOARD_PROTOCOL}//localhost:${LOCAL_DASHBOARD_PORT}`
  }
}
export function consoleApiUrl() {
  const {
    VITE_DEPLOY_ENV,
    VITE_GC_API_HOST,
    VITE_GC_API_HOST_DEV,
    VITE_GC_API_HOST_DEV2,
    VITE_GC_API_HOST_LOCAL,
    VITE_GC_API_HOST_PROD,
    VITE_GC_API_HOST_QA,
    VITE_GC_API_HOST_STAGE,
    VITE_GC_PREVIEW_API_HOST_DEV,
    VITE_GC_PREVIEW_API_HOST_DEV2,
    VITE_GC_PREVIEW_API_HOST_PROD,
    VITE_GC_PREVIEW_API_HOST_QA,
    VITE_GC_PREVIEW_API_HOST_STAGE,
  } = import.meta.env

  if (VITE_GC_API_HOST) {
    return VITE_GC_API_HOST
  }

  const GC_API_HOST_DEV = 'https://gc-api-dev.redaptiveinc.com/api'

  /* eslint-disable max-len */
  switch (VITE_DEPLOY_ENV) {
    case STAGE_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_HOST_STAGE !==
              'https://<jira ticket number>.preview-gc-api-stage.redaptiveinc.com/api'
        ) ?
          VITE_GC_PREVIEW_API_HOST_STAGE || GC_API_HOST_DEV
        : VITE_GC_API_HOST_STAGE || 'https://gc-api-stage.redaptiveinc.com/api'

    case QA_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_HOST_QA !==
              'https://<jira ticket number>.preview-gc-api-qa.redaptiveinc.com/api'
        ) ?
          VITE_GC_PREVIEW_API_HOST_QA || GC_API_HOST_DEV
        : VITE_GC_API_HOST_QA || 'https://gc-api-qa.redaptiveinc.com/api'

    case PROD_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_HOST_PROD !==
              'https://<jira ticket number>.preview-gc-api.redaptiveinc.com/api'
        ) ?
          VITE_GC_PREVIEW_API_HOST_PROD || GC_API_HOST_DEV
        : VITE_GC_API_HOST_PROD || 'https://gc-api.redaptiveinc.com/api'

    case LOCAL_ENV:
      return VITE_GC_API_HOST_LOCAL || '//localhost:8088/api'

    case DEV2_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_HOST_DEV2 !==
              'https://<jira ticket number>.preview-gc-api-dev2.redaptiveinc.com/api'
        ) ?
          VITE_GC_PREVIEW_API_HOST_DEV2 || GC_API_HOST_DEV
        : VITE_GC_API_HOST_DEV2 || 'https://gc-api-dev2.redaptiveinc.com/api'

    default:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_HOST_DEV !==
              'https://<jira ticket number>.preview-gc-api-dev.redaptiveinc.com/api'
        ) ?
          VITE_GC_PREVIEW_API_HOST_DEV || GC_API_HOST_DEV
        : VITE_GC_API_HOST_DEV || GC_API_HOST_DEV
  }
}
export function consoleBaseUrl() {
  const {
    VITE_DEPLOY_ENV,
    VITE_GC_PREVIEW_API_BASE_HOST_DEV,
    VITE_GC_PREVIEW_API_BASE_HOST_DEV2,
    VITE_GC_PREVIEW_API_BASE_HOST_PROD,
    VITE_GC_PREVIEW_API_BASE_HOST_QA,
    VITE_GC_PREVIEW_API_BASE_HOST_STAGE,
  } = import.meta.env
  const GC_API_BASE_HOST_DEV = 'https://gc-api-dev.redaptiveinc.com'

  switch (VITE_DEPLOY_ENV) {
    case STAGE_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_BASE_HOST_STAGE !==
              'https://<jira ticket number>.preview-gc-api-stage.redaptiveinc.com'
        ) ?
          VITE_GC_PREVIEW_API_BASE_HOST_STAGE || GC_API_BASE_HOST_DEV
        : 'https://gc-api-stage.redaptiveinc.com'

    case QA_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_BASE_HOST_QA !==
              'https://<jira ticket number>.preview-gc-api-qa.redaptiveinc.com'
        ) ?
          VITE_GC_PREVIEW_API_BASE_HOST_QA || GC_API_BASE_HOST_DEV
        : 'https://gc-api-qa.redaptiveinc.com'

    case PROD_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_BASE_HOST_PROD !==
              'https://<jira ticket number>.preview-gc-api.redaptiveinc.com'
        ) ?
          VITE_GC_PREVIEW_API_BASE_HOST_PROD || GC_API_BASE_HOST_DEV
        : 'https://gc-api.redaptiveinc.com'

    case LOCAL_ENV:
      return '//localhost:8088'

    case DEV2_ENV:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_BASE_HOST_DEV2 !==
              'https://<jira ticket number>.preview-gc-api-dev2.redaptiveinc.com'
        ) ?
          VITE_GC_PREVIEW_API_BASE_HOST_DEV2 || GC_API_BASE_HOST_DEV
        : 'https://gc-api-dev2.redaptiveinc.com'

    default:
      return (
          import.meta.env.VITE_PR_BASE_BRANCH &&
            VITE_GC_PREVIEW_API_BASE_HOST_DEV !==
              'https://<jira ticket number>.preview-gc-api-dev.redaptiveinc.com'
        ) ?
          VITE_GC_PREVIEW_API_BASE_HOST_DEV || GC_API_BASE_HOST_DEV
        : 'https://gc-api-dev.redaptiveinc.com'
  }
}
export function apiGatewayUrl() {
  const {
    VITE_DEPLOY_ENV,
    VITE_GC_API_GATEWAY_HOST,
    VITE_GC_API_GATEWAY_HOST_DEV,
    VITE_GC_API_GATEWAY_HOST_DEV2,
    VITE_GC_API_GATEWAY_HOST_LOCAL,
    VITE_GC_API_GATEWAY_HOST_PROD,
    VITE_GC_API_GATEWAY_HOST_QA,
    VITE_GC_API_GATEWAY_HOST_STAGE,
  } = import.meta.env

  if (VITE_GC_API_GATEWAY_HOST) {
    return VITE_GC_API_GATEWAY_HOST
  }

  switch (VITE_DEPLOY_ENV) {
    case STAGE_ENV:
      return (
        VITE_GC_API_GATEWAY_HOST_STAGE ||
        'https://api.stage.redaptivegroup.com/grandcentral'
      )

    case QA_ENV:
      return (
        VITE_GC_API_GATEWAY_HOST_QA ||
        'https://api.qa.redaptivegroup.com/grandcentral'
      )

    case PROD_ENV:
      return (
        VITE_GC_API_GATEWAY_HOST_PROD ||
        'https://api.prod.redaptivegroup.com/grandcentral'
      )

    case LOCAL_ENV:
      return VITE_GC_API_GATEWAY_HOST_LOCAL || '//localhost:8088/grandcentral'

    case DEV2_ENV:
      return (
        VITE_GC_API_GATEWAY_HOST_DEV2 ||
        'https://api.dev2.redaptivegroup.com/grandcentral'
      )

    default:
      return (
        VITE_GC_API_GATEWAY_HOST_DEV ||
        'https://api.dev.redaptivegroup.com/grandcentral'
      )
  }
}
export function financeApiUrl() {
  const { VITE_DEPLOY_ENV } = import.meta.env

  switch (VITE_DEPLOY_ENV) {
    case STAGE_ENV:
      return 'https://api.stage.redaptivegroup.com/finance'

    case QA_ENV:
      return 'https://api.qa.redaptivegroup.com/finance'

    case PROD_ENV:
      return 'https://api.prod.redaptivegroup.com/finance'

    case LOCAL_ENV:
      return '//localhost:8088/finance'

    case DEV2_ENV:
      return 'https://api.dev2.redaptivegroup.com/finance'

    default:
      return 'https://api.dev.redaptivegroup.com/finance'
  }
}
export function loginToken() {
  return localStorage.getItem(STORAGE_LOGIN_TOKEN) || ''
}
export function defaultHeaders(apiSource?: string) {
  const headers: {
    'Content-Type': string
    Authorization: string
  } = {
    'Content-Type':
      apiSource === API_SOURCE.scenario ?
        'multipart/form-data'
      : 'application/json',
    Authorization: '',
  }
  const token = loginToken()

  if (token) {
    headers.Authorization = `Token ${token}`
  }

  return headers
}
