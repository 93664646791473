import { ADD_SCENARIO_STATES } from '../../../utils/constants'
import { colors } from '../../../utils/themes'

const { SUCCESS, DANGER, WARNING, LOADING } = ADD_SCENARIO_STATES
const OPACITY = '16'
export default {
  [DANGER]: {
    border: colors.red,
    background: `${colors.red}${OPACITY}`,
  },
  [SUCCESS]: {
    border: colors.green,
    background: `${colors.green}${OPACITY}`,
  },
  [WARNING]: {
    border: colors.orange2,
    background: `${colors.orange2}${OPACITY}`,
  },
  [LOADING]: {
    border: 'none',
    background: 'none',
  },
}
