export type FTTsortItems = Record<string, Array<string>>
/**
 * Topological Sort.
 *
 * @see https://stackoverflow.com/a/54346588/562186
 */

export default (
  items: FTTsortItems,
): {
  circle: Array<string>
  result: Array<string>
} => {
  const keys = Object.keys(items)
  const used = new Set()
  const result = []
  let i
  let item
  let length

  do {
    length = keys.length
    i = 0

    while (i < keys.length) {
      if (items[keys[i]].every(Set.prototype.has, used)) {
        ;[item] = keys.splice(i, 1)
        result.push(item)
        used.add(item)
      } else {
        i += 1
      }
    }
  } while (keys.length && keys.length !== length)

  const circle = [...keys]
  return {
    circle,
    result,
  }
}
