import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs from '../../../components/Breadcrumbs'
import SearchBar from '../../../components/EntityList/SearchBar'
import { ErrorMessageStyles } from '../../../components/ErrorMessage'
import RedaptiveReactTable from '../../../components/RedaptiveReactTable'
import Spinner from '../../../components/Spinner'
import StyledLink from '../../../components/StyledLink'
import Title from '../../../components/Title'
import type { FTSearchBy } from '../../../ducks/types'
import type {
  FTDownloadCustomerSitesAction,
  FTUtilityCustomer,
} from '../../../ducks/utilityData/utilityCustomerSites'
import {
  actions as downloadActions,
  selectUtilityCustomerListEntity,
} from '../../../ducks/utilityData/utilityCustomerSites'

type FTProps = {
  actions: {
    fetchCustomerSitesList: (params: FTSearchBy) => null
    downloadCustomerSites: (params: FTDownloadCustomerSitesAction) => null
  }
  customersList: Array<FTUtilityCustomer>
  customersListLoading: boolean
  customersListError: string
  downloadCustomerSitesLoading: boolean
  downloadCustomerSitesError: string
  previous: FTSearchBy
}
const MainContainerStyled = styled.div`
  width: 100%;
`
const TableContainerStyled = styled.div`
  margin-top: 10px;
`

const UtilityCustomerSitesPage = (props: FTProps) => {
  const {
    actions,
    customersList,
    customersListLoading,
    customersListError,
    downloadCustomerSitesError,
    // eslint-disable-next-line no-unused-vars
    downloadCustomerSitesLoading,
    previous,
  } = props
  useEffect(() => {
    actions.fetchCustomerSitesList()
  }, [])

  const handleDownloadSiteData = (id, name) => {
    actions.downloadCustomerSites({
      customerId: id,
      customerName: name,
    })
  }

  const columns = [
    {
      accessor: 'name',
      Cell: ({ original: { id, name } }: any) => (
        <StyledLink to='#' onClick={() => handleDownloadSiteData(id, name)}>
          {name || '-'}
        </StyledLink>
      ),
      Header: 'Customer Name',
      id: 'name',
    },
  ]

  const onSearch = (searchBy: FTSearchBy) => {
    actions.fetchCustomerSitesList(searchBy)
  }

  const renderCustomers = () => (
    <TableContainerStyled>
      <RedaptiveReactTable
        data={customersList}
        columns={columns}
        filterable={false}
        sortable={false}
      />
    </TableContainerStyled>
  )

  return (
    <MainContainerStyled>
      <Breadcrumbs
        items={[
          {
            href: '/reports',
            text: 'Tools & Reports',
          },
          {
            href: '/reports/manage-utility-data',
            text: 'Manage Utility Data',
          },
          {
            href: '/reports/manage-utility-data/customer-sites',
            text: 'Customer Sites',
          },
        ]}
      />
      <Title>Access Customer Site List</Title>
      {!!downloadCustomerSitesError && (
        <ErrorMessageStyles>{downloadCustomerSitesError}</ErrorMessageStyles>
      )}
      <SearchBar
        onSearch={onSearch}
        filterField='filterName'
        previous={previous}
      />
      {customersListLoading && <Spinner />}
      {customersListError && (
        <ErrorMessageStyles>{customersListError}</ErrorMessageStyles>
      )}
      {!!customersList && !!customersList.length && (
        <>
          <div>Click on the customer name to download the list of sites. </div>
          {renderCustomers()}
        </>
      )}
    </MainContainerStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(downloadActions, dispatch) },
})

const mapStateToProps = (state) => {
  const {
    items: customersList,
    meta: {
      loading: customersListLoading,
      error: customersListError,
      searchBy: previous,
    },
    downloadMeta: {
      loading: downloadCustomerSitesLoading,
      error: downloadCustomerSitesError,
    },
  } = selectUtilityCustomerListEntity(state)
  return {
    customersList,
    customersListLoading,
    customersListError,
    downloadCustomerSitesLoading,
    downloadCustomerSitesError,
    previous,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UtilityCustomerSitesPage),
)
