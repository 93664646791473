import styled from 'styled-components'

import Button2 from '../../Button2'

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #e0e0e0;
  background: #e0e0e032;
  height: 56px;
  padding: 0 5px;
`
const CancelButton = styled(Button2)`
  width: 100px;
`
const SuccessButton = styled(Button2)`
  width: 144px;
`
export default {
  Footer,
  CancelButton,
  SuccessButton,
}
