import styled from 'styled-components'

import { CenteredContainerStyled, ClearBtnStyled } from './commons'
import Button2 from '../../Button2'
import Spinner from '../../Spinner'

const CemteredCoontentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px;
  height: 100%;
`
const ConfirmationMessageStyled = styled.p`
  color: #162447;
  text-align: center;
  font-family: Public Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`
const HelperTextStyled = styled.p`
  margin-top: 16px;
  color: #162447;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`
const ButtonContainerStyled = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const ErrorMessageStyled = styled.p`
  color: #e02020;
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
`
type FTConfirmModalProps = {
  handleSaveKFactor: (...args: Array<any>) => any
  handleCancel: (...args: Array<any>) => any
  loading: boolean
  error: string
}

const ConfirmationMessage = (props: FTConfirmModalProps) => {
  const { handleCancel, handleSaveKFactor, loading, error } = props
  return (
    <CemteredCoontentContainerStyled>
      <ConfirmationMessageStyled>
        Are you sure you want to replace existing K-Factor with the New
        K-Factor?
      </ConfirmationMessageStyled>
      <HelperTextStyled>
        The new K-Factor will be used from this point onwards
      </HelperTextStyled>
      <ButtonContainerStyled>
        <ClearBtnStyled onClick={handleCancel}>
          <span>Cancel</span>
        </ClearBtnStyled>
        <Button2 type='primary' disabled={loading} onClick={handleSaveKFactor}>
          Yes, Use New K-Factor
        </Button2>
      </ButtonContainerStyled>
      {loading && (
        <CenteredContainerStyled>
          <Spinner size='tiny' />
        </CenteredContainerStyled>
      )}
      <ErrorMessageStyled>{error}</ErrorMessageStyled>
    </CemteredCoontentContainerStyled>
  )
}

export default ConfirmationMessage
