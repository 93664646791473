import { withFormik } from 'formik'

import SiteForm from './SiteForm'
import validationSchema from './validationSchema'
import { themes } from '../../ducks/sites'
import type { FTSite } from '../../types'
import { isValueSet, valueOrDefault } from '../../utils'

const FormikSiteForm = withFormik({
  validationSchema,

  handleSubmit(values, { props }) {
    const { submitAction } = props
    // Removing extra fields from values
    // eslint-disable-next-line no-undef
    const filteredValues = structuredClone(values)
    filteredValues.contracts = filteredValues?.contracts
      ?.filter(({ opportunityName }) => opportunityName)
      .map((contract) => {
        const tempContract = { ...contract }
        delete tempContract.hasContract
        delete tempContract.newOpportunityIndex
        // delete tempContract.searchOpportunityId
        return tempContract
      })
    submitAction(filteredValues)
  },

  mapPropsToValues({
    site,
    customer,
  }: {
    site: FTSite
    customer: Record<string, any>
  }) {
    const { customerId: siteCustomerId } = site
    let customerId = siteCustomerId

    if (!siteCustomerId && customer && customer.id) {
      customerId = customer.id
    }

    const { contracts = [] } = site
    const hydropointSiteId = site?.siteExternalResources?.find(
      (obj) => obj.resourceProvider === 'HYDROPOINT',
    )?.externalId

    const getContractsInitialValues = () => {
      if (contracts.length) {
        return contracts
      }

      return [
        {
          contractLength: '',
          energyCommitment: '',
          energyRate: '',
          gasSavings: '',
          id: '',
          maintenanceSavings: '',
          monthlyBlock: '',
          opportunityId: '',
          procurementSavings: '',
          type: 'LIGHTING',
          utilityRate: '',
        },
      ]
    }

    return {
      display: valueOrDefault(site.display || site.validName),
      customerId: valueOrDefault(customerId),
      theme: valueOrDefault(site.theme, themes.defaultThemeId),
      active: valueOrDefault(site.active, false),
      enableEquipmentView: valueOrDefault(site.enableEquipmentView, false),
      address1: valueOrDefault(site.address1),
      address2: valueOrDefault(site.address2),
      city: valueOrDefault(site.city),
      locale: valueOrDefault(site.locale),
      state: valueOrDefault(site.state),
      postalCode: valueOrDefault(site.postalCode),
      country: valueOrDefault(site.country),
      latLng: valueOrDefault(site.latLng, null),
      currencyCode: valueOrDefault(site.currencyCode),
      timezone: valueOrDefault(site.timezone),
      hydropointSiteId: valueOrDefault(hydropointSiteId, ''),
      // Numerical Values
      electricUtilityRate:
        isValueSet(site.electricUtilityRate) ?
          site.electricUtilityRate
        : '0.11',
      squareFootage: isValueSet(site.squareFootage) ? site.squareFootage : '',
      source: valueOrDefault(site.source, 'REDAPTIVE'),
      ingestionDataStartElectricity: site.ingestionDataStartElectricity,
      ingestionDataStartWater: site.ingestionDataStartWater,
      ingestionDataStartNaturalGas: site.ingestionDataStartNaturalGas,
      contracts: getContractsInitialValues(),
    }
  },
})(SiteForm)
export default FormikSiteForm
