export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 1024 768'
    width='150'
    height='100'
    preserveAspectRatio='xMidYMid meet'
  >
    <g clipPath='url(#__lottie_element_2)'>
      <g
        clipPath='url(#__lottie_element_4)'
        transform='matrix(1,0,0,1,0,40)'
        opacity='1'
        style={{ display: 'block' }}
      >
        <g
          transform='matrix(1,0,0,1,508.0159912109375,318)'
          opacity='1'
          style={{ display: 'block' }}
        >
          <g opacity='1' transform='matrix(1,0,0,1,0,0)'>
            <path
              fill='rgb(72,92,160)'
              fillOpacity='1'
              d=' M-158.0850067138672,126.5 C-158.0850067138672,126.5 157.67999267578125,126.5 157.67999267578125,126.5 C189.82899475097656,126.5 217.70899963378906,103.552001953125 223.093994140625,71.85700225830078 C224.05999755859375,66.16899871826172 224.531005859375,60.31399917602539 224.46099853515625,54.334999084472656 C223.85299682617188,2.5390000343322754 182.01499938964844,-40.013999938964844 130.23500061035156,-41.46099853515625 C125.12699890136719,-41.604000091552734 120.10600280761719,-41.349998474121094 115.1989974975586,-40.72999954223633 C92.83399963378906,-91.2490005493164 42.270999908447266,-126.5 -16.531999588012695,-126.5 C-83.23600006103516,-126.5 -139.33900451660156,-81.14199829101562 -155.7010040283203,-19.58799934387207 C-194.66200256347656,-13.717000007629395 -224.53199768066406,19.900999069213867 -224.53199768066406,60.5 C-224.53199768066406,61.25699996948242 -224.52200317382812,62.0099983215332 -224.50100708007812,62.762001037597656 C-223.5229949951172,98.39600372314453 -193.73300170898438,126.5 -158.0850067138672,126.5z'
            />
          </g>
        </g>
        <g
          transform='matrix(1.159999966621399,0,0,1.159999966621399,517.2640380859375,262.14654541015625)'
          opacity='1'
          style={{ display: 'block' }}
        >
          <g opacity='1' transform='matrix(1,0,0,1,0,0)'>
            <path
              fill='rgb(72,92,160)'
              fillOpacity='1'
              d=' M55.2859992980957,254.7449951171875 C55.2859992980957,254.7449951171875 0.8389999866485596,200.29800415039062 0.8389999866485596,200.29800415039062 C-4.043000221252441,195.41600036621094 -11.956999778747559,195.41600036621094 -16.839000701904297,200.29800415039062 C-16.839000701904297,200.29800415039062 -71.28600311279297,254.7449951171875 -71.28600311279297,254.7449951171875 C-76.16699981689453,259.62701416015625 -76.16699981689453,267.5419921875 -71.28600311279297,272.4230041503906 C-66.40399932861328,277.30499267578125 -58.49100112915039,277.30499267578125 -53.60900115966797,272.4230041503906 C-53.60900115966797,272.4230041503906 -20.5,239.31399536132812 -20.5,239.31399536132812 C-20.5,239.31399536132812 -20.5,324.0840148925781 -20.5,324.0840148925781 C-20.5,330.98699951171875 -14.902999877929688,336.5840148925781 -8,336.5840148925781 C-1.097000002861023,336.5840148925781 4.5,330.98699951171875 4.5,324.0840148925781 C4.5,324.0840148925781 4.5,239.31399536132812 4.5,239.31399536132812 C4.5,239.31399536132812 37.608001708984375,272.4230041503906 37.608001708984375,272.4230041503906 C40.04800033569336,274.864013671875 43.24800109863281,276.0840148925781 46.446998596191406,276.0840148925781 C49.645999908447266,276.0840148925781 52.84600067138672,274.864013671875 55.2859992980957,272.4230041503906 C60.167999267578125,267.5419921875 60.167999267578125,259.62701416015625 55.2859992980957,254.7449951171875z'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
