import * as React from 'react'
import styled from 'styled-components'

import Description from './Description'
import ErrorMessage from './ErrorMessage'
import type {
  FTRenderHeaderActionFunc,
  FTRenderTableRowFunc,
  FTTableHeaderType,
} from './Table'
import Table from './Table'
import type { FTEntity, FTFilterBy } from '../ducks/types'
import '../ducks/types'
import { getPageSizes } from '../ducks/utils'
import MetersDropdown from './EntityList/MetersDropdown'

type FTProps = {
  entity: FTEntity
  loadEntity: (...args: Array<any>) => any
  loadEntityProps?: Record<string, any>
  tableHeaders: Array<FTTableHeaderType>
  renderTableRow: FTRenderTableRowFunc
  renderHeaderAction?: FTRenderHeaderActionFunc
  renderHeader?: () => React.ReactNode
  renderNoResults?: () => React.ReactNode
  filterParam?: string
  showMetersFilter?: boolean
  showPageSizer?: boolean
  showHeader?: boolean
  description?: string
  striped?: boolean
  stripeColor?: string
  flagResetters?: Array<(...args: Array<any>) => any>
  hideAllPagination?: boolean
  paginationSize?: Array<number>
}
const StyledEntityList = styled.div`
  & a[href] {
    color: #337ab7;
  }

  & a[href]:hover {
    text-decoration: underline;
  }

  & .Table {
    border-spacing: ${({ striped }) => striped && '0px'};
  }

  & .Table tbody tr {
    height: ${({ striped }) => striped && '40px'};
    line-height: ${({ striped }) => striped && '40px'};
  }

  & .Table tbody tr:nth-child(odd) {
    background-color: ${({ striped, stripeColor }) =>
      stripeColor || (striped && '#f5f5f5')};
  }

  & .Table tbody tr.edited:nth-child(odd) {
    background-color: ${({ striped }) => striped && '#b4dbfa'};
  }

  & .Table tbody tr.edited:nth-child(even) {
    background-color: ${({ striped }) => striped && '#e2f1fd'};
  }
`
const DropdownContainerStyled = styled.div`
  position: relative;
`
const SearchBar = styled.div`
  margin-bottom: 30px;
  width: 240px;
  display: none;
  input {
    width: 240px;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    background-color: #ffffff;
    color: #4a4a4a;
    font: 14px 'Avenir Next';
    line-height: 19px;
    padding: 9px 11px 8px 11px;
    visibility: ${({ hidden }) => hidden && 'hidden'};
  }
`
const PageSizeSelector = styled.div`
  position: absolute;
  top: 3px;
  right: 0;
  user-select: none;
`
const PageSizeItem = styled.span`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? '600' : '300')};
  color: #337ab7;
  text-decoration: underline;
  padding-left: 10px;

  &:not(:last-child) {
    padding-right: 10px;
  }

  &:not(:first-child) {
    border-left: 1px solid #979797;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: ${(props) => (props.showPageSizer === true ? '44px' : 0)};
  position: relative;
`
/*
 * This component is deprecated. Use components/EntityList instead.
 */

export default class DeprecatedEntityListPane extends React.PureComponent<FTProps> {
  static defaultProps = {
    loadEntityProps: {},
    showPageSizer: true,
    showHeader: true,
    renderHeaderAction: undefined,
    renderHeader: undefined,
    renderNoResults: undefined,
    description: '',
    filterParam: '',
    showMetersFilter: false,
    striped: false,
    stripeColor: '',
    hideAllPagination: false,
  }

  renderSearch() {
    const {
      loadEntity,
      entity: { meta },
      loadEntityProps,
      flagResetters,
    } = this.props
    return (
      <SearchBar hidden>
        <input
          placeholder='Search'
          onKeyUp={(e) => {
            loadEntity({
              ...loadEntityProps,
              query: e.target.value,
              pageNumber: 1,
              pageSize: meta.pageSize,
            })
            if (flagResetters && flagResetters.length > 0) {
              flagResetters.forEach((resetter) => resetter())
            }
          }}
        />
      </SearchBar>
    )
  }

  onFilter = (filterBy: FTFilterBy) => {
    const {
      loadEntity,
      loadEntityProps,
      entity: { meta },
      flagResetters,
    } = this.props
    loadEntity({ ...loadEntityProps, ...meta, filterBy, pageNumber: 1 })
    if (flagResetters && flagResetters.length > 0) {
      flagResetters.forEach((resetter) => resetter())
    }
  }

  render() {
    const {
      entity: { items, meta },
      loadEntity,
      tableHeaders,
      renderTableRow,
      renderNoResults,
      loadEntityProps,
      description,
      striped,
      stripeColor,
      filterParam,
      showMetersFilter,
      showPageSizer,
      showHeader,
      renderHeaderAction,
      renderHeader,
      flagResetters,
      hideAllPagination,
      paginationSize,
    } = this.props
    const {
      error,
      totalCount = 0,
      totalPages,
      pageNumber,
      pageSize = 0,
      loading,
      next,
      previous,
      prev,
      orderBy,
      filterBy,
    } = meta
    const pageSizes = getPageSizes(
      totalCount,
      hideAllPagination,
      paginationSize,
    )
    return (
      <StyledEntityList
        className='EntityListPane'
        striped={striped}
        stripeColor={stripeColor}
      >
        {showMetersFilter && (
          <DropdownContainerStyled>
            <MetersDropdown
              onSelect={this.onFilter}
              filterField={filterParam || ''}
              previous={filterBy}
            />
          </DropdownContainerStyled>
        )}
        {!renderHeader && totalCount > 0 && showHeader && (
          <Header
            className='EntityListPane-header'
            showPageSizer={showPageSizer && pageSizes.length > 0}
          >
            {loadEntity && this.renderSearch()}
            {showPageSizer && pageSizes.length > 0 && (
              <PageSizeSelector>
                View
                {pageSizes.map(({ label, value }) => (
                  <PageSizeItem
                    active={
                      value === pageSize ||
                      (label === 'All' && pageSize >= totalCount)
                    }
                    key={label}
                    onClick={() => {
                      if (value !== pageSize) {
                        loadEntity({ ...loadEntityProps, pageSize: value })
                        if (flagResetters && flagResetters.length > 0) {
                          flagResetters.forEach((resetter) => resetter())
                        }
                      }
                    }}
                  >
                    {label}
                  </PageSizeItem>
                ))}
              </PageSizeSelector>
            )}
          </Header>
        )}
        {renderHeader && renderHeader()}
        {description && (
          <Description className='EntityListPane-description'>
            {description}
          </Description>
        )}
        {error && <ErrorMessage message={error} />}
        <div className='EntityListPane-body'>
          {((items && items.length > 0) || !renderNoResults) && (
            <Table
              totalCount={totalCount}
              items={items}
              totalPages={totalPages}
              loading={loading}
              page={pageNumber}
              perPage={pageSize}
              next={next}
              prev={previous || prev}
              browseToPage={(number) => {
                loadEntity({
                  ...loadEntityProps,
                  pageNumber: number,
                  filterBy,
                  pageSize,
                })
                if (flagResetters && flagResetters.length > 0) {
                  flagResetters.forEach((resetter) => resetter())
                }
              }}
              currentSort={orderBy}
              updateSort={(field, sort) => {
                loadEntity({
                  ...loadEntityProps,
                  filterBy,
                  orderBy: {
                    field,
                    sort,
                  },
                })
                if (flagResetters && flagResetters.length > 0) {
                  flagResetters.forEach((resetter) => resetter())
                }
              }}
              tableHeaders={tableHeaders}
              renderTableRow={renderTableRow}
              renderHeaderAction={renderHeaderAction}
            />
          )}
          {!loading &&
            (!items || items.length < 1) &&
            renderNoResults &&
            renderNoResults()}
        </div>
      </StyledEntityList>
    )
  }
}
