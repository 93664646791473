import styled, { css } from 'styled-components'

import type { ComponentType } from 'react'

type FTProps = {
  color: string
  type?: 'square' | 'line'
}
const ColorIndicatorLineStyles = css`
  height: 20px;
  width: 3px;
`
const ColorIndicatorSquareStyles = css`
  height: 8px;
  width: 8px;
`
const ColorIndicator: ComponentType<FTProps> = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 2px;
  display: inline-block;
  ${({ type }) =>
    type === 'line' ? ColorIndicatorLineStyles : ColorIndicatorSquareStyles};
`
export default ColorIndicator
