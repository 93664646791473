import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import type { FTModalKfactorProps } from './commons'
import {
  BorderedContainerStyled,
  CALCULATION_TYPE,
  ClearBtnStyled,
  CloseIconStyled,
  FieldContainerStyled,
  HeaderLabelStyled,
  HeaderStyled,
  InputStyled,
  KFactorContainer,
  LabelStyled,
  ManualToggleContainerStyled,
  ModalBodyStyled,
  ModalContentStyled,
  ModalFooterStyled,
  RequiredMarkStyled,
  TextAreaStyled,
} from './commons'
import ConfirmationMessage from './ConfirmationMessage'
import {
  actions as meterActions,
  selectMeterEntity,
  selectUtilityMeterModelsItems,
} from '../../../ducks/meters'
import Button2 from '../../Button2'
import SyncFilled from '../../Icons/svg/SyncFilled'
import ListSelector from '../../ListSelector'

const AddKFactorForm = (props: FTModalKfactorProps) => {
  const {
    meterId,
    closeModal,
    setCalculationMode,
    setManualMode,
    utilityMeterModels,
    meterEntity,
    actions: { fetchUtilityMeterModels, addKFactor },
  } = props
  useEffect(() => {
    fetchUtilityMeterModels()
  }, [])
  const utilityMetersSelectList = [
    ...(utilityMeterModels || []).map((item) => ({
      ...item,
      name: item.model,
    })),
    {
      id: 'Unknown',
      name: 'Unknown',
    },
  ]
  const [utilityGasMeterModelId, setUtilityGasMeterModelId] = useState('')
  const [kfactor, setKFactor] = useState('')
  const [comments, setComments] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)
  const selectedMeterModel =
    utilityGasMeterModelId === 'Unknown' ?
      {
        id: 'Unknown',
        name: 'Unknown',
      }
    : (utilityMeterModels || []).find(
        (item) => item.id === utilityGasMeterModelId,
      )
  const loading = meterEntity?.meta?.loading
  const errorMsg = meterEntity?.meta?.error

  const submitKfactor = () => {
    addKFactor({
      meterId,
      utilityMeterModel: utilityGasMeterModelId,
      calculationType: CALCULATION_TYPE.UTILITY_METER,
      kFactor: kfactor,
      comment: comments,
    })
    closeModal()
  }

  const isFormValid = utilityGasMeterModelId && kfactor
  const isFormDirty = utilityGasMeterModelId || kfactor || comments

  const resetForm = () => {
    setUtilityGasMeterModelId('')
    setKFactor('')
    setComments('')
  }

  return (
    <ModalContentStyled>
      <CloseIconStyled onClick={closeModal}>
        <span className='ion-android-close' />
      </CloseIconStyled>
      {!isConfirming && (
        <>
          <ModalBodyStyled>
            <HeaderStyled>
              <div>
                <HeaderLabelStyled>
                  Select Manufacturer K-Factor
                </HeaderLabelStyled>
              </div>
            </HeaderStyled>
            <BorderedContainerStyled>
              <FieldContainerStyled>
                <LabelStyled>
                  Utility gas meter model
                  <RequiredMarkStyled>*</RequiredMarkStyled>
                </LabelStyled>
                <ListSelector
                  selectedItem={selectedMeterModel}
                  notSetLabelText='Select utility gas meter model'
                  items={utilityMetersSelectList || []}
                  fieldName='utilityGasMeterModel'
                  searchable={false}
                  unsettable={false}
                  updateValue={({ value: updateValue }) => {
                    if (updateValue === 'Unknown') {
                      setUtilityGasMeterModelId('Unknown')
                      setKFactor('')
                      return
                    }

                    setUtilityGasMeterModelId(updateValue)
                    const { kFactor } = utilityMeterModels.find(
                      (item) => item.id === updateValue,
                    )
                    setKFactor(kFactor)
                  }}
                />
              </FieldContainerStyled>
              {utilityGasMeterModelId === 'Unknown' && (
                <ManualToggleContainerStyled>
                  <p>Manufacturer k-factor not available?</p>
                  <Button2
                    onClick={() => setCalculationMode(true)}
                    type='outlined'
                  >
                    Add A Calculated K-Factor
                  </Button2>
                </ManualToggleContainerStyled>
              )}
              {utilityGasMeterModelId === 'Unknown' && (
                <KFactorContainer>
                  <p>Already have a K-FACTOR?</p>
                  <Button2 onClick={() => setManualMode(true)} type='outlined'>
                    Enter K-Factor Manually
                  </Button2>
                </KFactorContainer>
              )}
              {utilityGasMeterModelId &&
                utilityGasMeterModelId !== 'Unknown' && (
                  <>
                    <FieldContainerStyled>
                      <LabelStyled>
                        K-Factor (revolutions per CCF)
                        <RequiredMarkStyled>*</RequiredMarkStyled>
                      </LabelStyled>
                      <InputStyled type='text' value={kfactor} disabled />
                    </FieldContainerStyled>
                    <FieldContainerStyled>
                      <LabelStyled>Comments</LabelStyled>
                      <TextAreaStyled
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </FieldContainerStyled>
                    <ManualToggleContainerStyled>
                      <p>Manufacturer k-factor is not accurate?</p>
                      <Button2
                        onClick={() => setCalculationMode(true)}
                        type='outlined'
                      >
                        Add A Calculated K-Factor
                      </Button2>
                    </ManualToggleContainerStyled>
                  </>
                )}
            </BorderedContainerStyled>
          </ModalBodyStyled>
          <ModalFooterStyled>
            <ClearBtnStyled disabled={!isFormDirty} onClick={resetForm}>
              <SyncFilled />
              <span>Reset</span>
            </ClearBtnStyled>
            <Button2
              disabled={!isFormValid}
              type='primary'
              onClick={submitKfactor}
            >
              Save & Mark as active
            </Button2>
          </ModalFooterStyled>
        </>
      )}
      {isConfirming && (
        <ConfirmationMessage
          handleSaveKFactor={submitKfactor}
          handleCancel={() => setIsConfirming(false)}
          loading={loading}
          error={errorMsg}
        />
      )}
    </ModalContentStyled>
  )
}

const mapStateToProps = (state, props) => {
  const entity = selectUtilityMeterModelsItems(state)
  return {
    utilityMeterModels: entity,
    meterEntity: selectMeterEntity(state, props.meterId),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(meterActions, dispatch) },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddKFactorForm)
