import Tippy from '@tippyjs/react'
import {
  ComponentType,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { $Shape } from 'utility-types'

import type {
  FTCreateProposalCustomerGlobalInputsAction,
  FTProposalCustomerGlobalInputs,
  FTPutProposalCustomerGlobalInputsAction,
} from '../../../../ducks/proposals/customerGlobalInputs'
import type { FTWithFormik } from '../../../../types'
import Button from '../../../Button'
import FormField, {
  FormFieldStyles,
  FormFieldTitle,
  FormFieldTitleSection,
} from '../../../FormField'
import FormFieldInteger from '../../../FormFieldInteger'
import { InputComponent, InputErrorMessageStyles } from '../../../Input'
import { inputNumberWithPrecisionOnInput } from '../../../InputNumberWithPrecision'
import ListSelector, { FTItem } from '../../../ListSelector'

export type FTGlobalInputsFormProps = {
  createProposalCustomerGlobalInputs: (
    params: FTCreateProposalCustomerGlobalInputsAction,
  ) => null
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  error: string
  loading: boolean
  putProposalCustomerGlobalInputs: (
    props: FTPutProposalCustomerGlobalInputsAction,
  ) => null
  salesforceCustomerId: string
  setEditMode: () => boolean
}
type FTProps = FTWithFormik &
  FTGlobalInputsFormProps & {
    values: $Shape<FTProposalCustomerGlobalInputs>
  }
const FormFieldsStyled = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  margin-bottom: 20px;

  ${FormFieldStyles} {
    margin-bottom: 20px;
  }

  ${FormFieldTitle} {
    font-size: 12px;
    font-weight: normal;
  }

  ${FormFieldTitleSection} {
    margin-bottom: 4px;
  }

  ${InputErrorMessageStyles} {
    bottom: -18px;
    font-size: 12px;
    left: unset;
    right: 0;
    margin: 0;
    top: unset;
  }

  ${InputComponent} {
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
const FormFieldErrorCss = css`
  ${InputComponent} {
    border: 1px solid #d0021b;
  }
`
const FormFieldCalculatedCss = css`
  ${InputComponent} {
    background-color: #e0e5f1;
  }
`
const RequiredIndicatorStyled = styled.span`
  color: #c02f2e;
`

const RequiredTitle = ({ title }: { title: string }) => (
  <>
    {`${title}\u00A0`}
    <RequiredIndicatorStyled>*</RequiredIndicatorStyled>
  </>
)

const FormFieldIntegerStyled: ComponentType<{
  error?: string
}> = styled(FormFieldInteger)`
  ${({ error }) => error && FormFieldErrorCss};
`
const FormFieldStyled: ComponentType<{
  error?: string
  calculated?: boolean
}> = styled(FormField)`
  ${({ error }) => error && FormFieldErrorCss};
  ${({ calculated }) => calculated && FormFieldCalculatedCss};
`
const ButtonsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`
const ButtonStyled = styled(Button)`
  font-size: 12px;
  margin-right: 14px;
  min-width: 0;
  padding: 0 5px;
  width: 151px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #9dbd79;
  background: #9dbd79;
  text-transform: none;
  &:last-child {
    margin-right: 0;
  }
`
const CancelButton = styled.div`
  display: grid;
  place-items: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  margin-right: 15px;
  cursor: pointer;
`

const tooltipMenuItem = (name) => (
  <Tippy content={name}>
    <span>{name}</span>
  </Tippy>
)

const operationsAndMaintenanceBasisItems: Array<FTItem> = [
  {
    id: 'NPV_REVENUE',
    name: tooltipMenuItem('NPV Revenue'),
  },
  {
    id: 'PRE_TAX_GROSS_COST',
    name: tooltipMenuItem('Pre-tax Gross Cost'),
  },
]

const preventMinus = (e) => {
  if (e.code === 'Minus') {
    e.preventDefault()
  }
}

const contractTypeItems: Array<FTItem> = [
  {
    id: 'EAAS',
    name: 'EAAS',
  },
  {
    id: 'CAPEX',
    name: 'CAPEX',
  },
]
const maintenanceObligationItems: Array<FTItem> = [
  {
    id: 'No Maintenance',
    name: tooltipMenuItem('No Maintenance'),
  },
  {
    id: 'Parts Only',
    name: tooltipMenuItem('Parts Only'),
  },
  {
    id: 'Parts and Labor',
    name: tooltipMenuItem('Parts and Labor'),
  },
  {
    id: 'Parts Only + Emergency Carveout',
    name: tooltipMenuItem('Parts Only + Emergency Carveout'),
  },
  {
    id: 'Warranty Administration Only',
    name: tooltipMenuItem('Warranty Administration Only'),
  },
  {
    id: 'Full Maintenance Coverage 24/7',
    name: tooltipMenuItem('Full Maintenance Coverage 24/7'),
  },
  {
    id: 'Preventative Maintenance',
    name: tooltipMenuItem('Preventative Maintenance'),
  },
  {
    id: 'Parts Only + 1st year Labor',
    name: tooltipMenuItem('Parts Only + 1st year Labor'),
  },
  {
    id: 'Parts Only + 1st year Labor + Emergency Carveout',
    name: tooltipMenuItem('Parts Only + 1st year Labor + Emergency Carveout'),
  },
  {
    id: 'Parts + Labor through vendor',
    name: tooltipMenuItem('Parts + Labor through vendor'),
  },
]

const referralFeeBasisItems = [
  {
    id: 'CONTRACT_VALUE',
    name: tooltipMenuItem('Pre-Tax Contract Value'),
  },
  {
    id: 'PRE_TAX_GROSS_COST',
    name: tooltipMenuItem('Base Cost'),
  },
]

const GlobalInputsForm = (props: FTProps) => {
  const {
    createProposalCustomerGlobalInputs,
    customerGlobalInputs,
    dirty,
    error,
    errors,
    isValid,
    loading,
    salesforceCustomerId,
    setEditMode,
    setFieldValue,
    putProposalCustomerGlobalInputs,
    values,
  } = props
  const { id } = customerGlobalInputs
  const [isSubmitting, setIsSubmitting]: [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ] = useState(false)
  useEffect(() => {
    if (isSubmitting && !loading) {
      setIsSubmitting(false)

      if (!error) {
        setEditMode(false)
      }
    }
  }, [loading])

  const operationsAndMaintenanceBasisSelectedItem =
    operationsAndMaintenanceBasisItems.find(
      (item) => item.id === values.operationsAndMaintenanceBasis,
    )

  const referralFeeBasisSelectedItem = referralFeeBasisItems.find(
    (item) => item.id === values.referralFeeBasis,
  )

  const contractTypeSelectedItem = contractTypeItems.find(
    (item) => item.id === values.contractType,
  )

  const maintenanceObligationSelectedItem = maintenanceObligationItems.find(
    (item) => item.id === values.maintenanceObligation,
  )

  const handleSubmit = () => {
    setIsSubmitting(true)

    if (id) {
      putProposalCustomerGlobalInputs({
        id,
        salesforceCustomerId,
        ...values,
      })
    } else {
      createProposalCustomerGlobalInputs({
        salesforceCustomerId,
        ...values,
      })
    }
  }

  return (
    <>
      <FormFieldsStyled>
        <FormFieldIntegerStyled
          error={errors.term}
          name='term'
          required
          title={<RequiredTitle title='Contract Term Months' />}
          type='number'
          min='0'
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <FormFieldStyled
          error={errors.contractType}
          renderField={() => (
            <ListSelector
              items={contractTypeItems}
              selectedItem={contractTypeSelectedItem}
              updateValue={({ value }) => {
                setFieldValue('contractType', value)
              }}
              unsettable={false}
            />
          )}
          title={<RequiredTitle title='Contract Type' />}
        />
        <FormFieldStyled
          error={errors.annualTransferRate}
          name='annualTransferRate'
          required
          title={<RequiredTitle title='Transfer Rate %' />}
          type='number'
          min='0'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <FormFieldStyled
          error={errors.projectManagementLaborAndFee}
          name='projectManagementLaborAndFee'
          title={<RequiredTitle title='Partner Fee %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
        <FormFieldStyled
          error={errors.operationsAndMaintenanceRate}
          name='operationsAndMaintenanceRate'
          title={<RequiredTitle title='O&M %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
        <FormFieldStyled
          error={errors.operationsAndMaintenanceBasis}
          renderField={() => (
            <ListSelector
              items={operationsAndMaintenanceBasisItems}
              selectedItem={operationsAndMaintenanceBasisSelectedItem}
              updateValue={({ value }) => {
                setFieldValue('operationsAndMaintenanceBasis', value)
              }}
              unsettable={false}
            />
          )}
          title={<RequiredTitle title='O&M Basis' />}
        />
        <FormFieldStyled
          error={errors.referralFeeInPercentage}
          name='referralFeeInPercentage'
          title={<RequiredTitle title='Referral Fee %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
        <FormFieldStyled
          error={errors.referralFeeBasis}
          renderField={() => (
            <ListSelector
              items={referralFeeBasisItems}
              selectedItem={referralFeeBasisSelectedItem}
              updateValue={({ value }) => {
                setFieldValue('referralFeeBasis', value)
              }}
              unsettable={false}
            />
          )}
          title={<RequiredTitle title='Referral Fee Basis' />}
        />
        <FormFieldStyled
          error={errors.energyRetainedSavings}
          name='energyRetainedSavings'
          title={<RequiredTitle title='Energy Retained Savings %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <FormFieldStyled
          error={errors.maintenanceRetainedSavings}
          name='maintenanceRetainedSavings'
          title={<RequiredTitle title='Maintenance Retained Savings %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(4)}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <FormFieldStyled
          error={errors.maintenanceObligation}
          renderField={() => (
            <ListSelector
              items={maintenanceObligationItems}
              selectedItem={maintenanceObligationSelectedItem}
              updateValue={({ value }) => {
                setFieldValue('maintenanceObligation', value)
              }}
              unsettable={false}
            />
          )}
          title={<RequiredTitle title='Maintenance Obligation' />}
        />
        <FormFieldStyled
          error={errors.measurementAndVerificationCostPerMeter}
          name='measurementAndVerificationCostPerMeter'
          title={<RequiredTitle title='Cost Per Meter $' />}
          required
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
        <FormFieldStyled
          error={
            errors.measurementAndVerificationCostAsPerProjectCostsInPercentage
          }
          name='measurementAndVerificationCostAsPerProjectCostsInPercentage'
          title={<RequiredTitle title='M&V Cost Estimate %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
        <FormFieldStyled
          error={errors.contingency}
          name='contingency'
          title={<RequiredTitle title='Contingency %' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
        <FormFieldStyled
          error={errors.annualInsurancePremium}
          name='annualInsurancePremium'
          title={<RequiredTitle title='Insurance Fee % of Base Cost' />}
          type='number'
          onInput={inputNumberWithPrecisionOnInput(2)}
          onKeyPress={preventMinus}
          onWheel={(e) => e.currentTarget.blur()}
          min='0'
        />
      </FormFieldsStyled>
      <ButtonsStyled>
        <CancelButton onClick={() => setEditMode(false)}>Cancel</CancelButton>
        <ButtonStyled
          disabled={!isValid || !dirty || isSubmitting || loading}
          loading={loading}
          onClick={handleSubmit}
          primary
        >
          Update Global Inputs
        </ButtonStyled>
      </ButtonsStyled>
    </>
  )
}

export default GlobalInputsForm
