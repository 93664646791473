import Tippy from '@tippyjs/react'
import debounce from 'debounce'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  breadcrumbs,
  defaultMeterInstallHeaders,
  defaultMeterInstallWidths,
  dropdownItems,
} from './constants'
import MeterTable from './MeterTable'
import {
  ArrowUpStyles,
  BreadcrumbsStyles,
  Cell,
  FilterContainerStyled,
  JobStatusStyles,
  MainStyles,
  NoDataWrapper,
  OpportunityStyles,
  PaginatorStyles,
  TableStyles,
  TitleStyles,
  PageSizeSelectorInnerStyled,
  PageSizeSelectorWrapperStyled,
} from './styles'
import Breadcrumbs from '../../components/Breadcrumbs'
import DatePicker from '../../components/DatePicker'
import SearchBar from '../../components/EntityList/SearchBar'
import ChevronDown from '../../components/Icons/svg/ChevronDown'
import ChevronUp from '../../components/Icons/svg/ChevronUp'
import ListSelector from '../../components/ListSelector'
import Paginator from '../../components/Paginator'
import RedaptiveReactTable7 from '../../components/RedaptiveReactTable7'
import TableHead from '../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../components/Spinner'
import * as consts from '../../constants'
import {
  actions,
  actions as meterInstallActions,
  selectMeterGroupedSumissions,
} from '../../ducks/meterInstallSubmissions/meterInstallGroupSubmissions'
import { getPageSizes } from '../../ducks/utils'
import {
  baseOptions,
  format,
  getProgramStartDate,
} from '../EvDashboard/constants'
import Search from './SearchBar'
import { DatePickerContainerStyled, SpinnerStyles } from '../EvDashboard/styles'

export default function MeterInstallGroupedSubmissions() {
  const dispatch = useDispatch()
  const { items: submissions, meta } = useSelector((state) =>
    selectMeterGroupedSumissions(state),
  )
  const [jobCompleteDate, setJobCompleteDate] = useState('PROGRAM_TO_DATE')
  const [jobCompleteRange, setJobCompleteRange] = useState({
    from: moment('2022-01-01').format(consts.DATE_FORMAT_DATA_API_REQUEST),
    to: moment().format(consts.DATE_FORMAT_DATA_API_REQUEST),
  })

  const {
    loading,
    orderBy,
    searchParams,
    previous,
    next,
    totalPages,
    totalCount,
    pageSize,
    pageNumber,
  } = meta
  const [orderParams, setOrderParams] = useState(orderBy)

  const pageSizes = getPageSizes(totalCount, true, [10, 20])
  const pageSizeItems = pageSizes.map(({ label }) => ({
    id: label,
    name: `${label} Rows`,
  }))

  useEffect(() => {
    dispatch(
      meterInstallActions.fetchInstallSubmissions({
        ...searchParams,
        orderBy: orderParams,
      }),
    )
  }, [orderParams])

  const handleSortClick = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    if (orderParams.field) {
      if (orderParams.field !== id || orderParams.sort === 'DESC') {
        setOrderParams({
          field: id,
          sort: 'ASC',
        })
      } else {
        setOrderParams({
          field: id,
          sort: 'DESC',
        })
      }
    }
  }

  const browseToPage = (number) => {
    dispatch(
      actions.fetchInstallSubmissions({
        orderBy: orderParams,
        pageSize,
        pageNumber: Number(number),
        ...searchParams,
      }),
    )
  }

  const handleUpdateSelectedPage = ({ value }) => {
    dispatch(
      actions.fetchInstallSubmissions({
        orderBy: orderParams,
        pageSize: value,
        ...searchParams,
      }),
    )
  }

  const handleSelector = (field, value) => {
    dispatch(
      actions.fetchInstallSubmissions({
        orderBy: orderParams,
        ...searchParams,
        [field]: value,
      }),
    )
  }

  const search = (searchBy: { field: string; term: string }) => {
    dispatch(
      actions.fetchInstallSubmissions({
        ...searchParams,
        pageSize,
        [searchBy.field]: searchBy.term,
        orderBy: orderParams,
      }),
    )
  }

  const handleSearch = debounce(search, 500)

  const renderSubmissionDateLabel = useCallback(() => {
    const { start, end } = {
      start: jobCompleteRange.from,
      end: jobCompleteRange.to,
    }
    const startDate = moment(start, consts.URL_DATE_FORMAT)
    const startText = startDate.format(format)
    let text = startText
    const endText =
      end ? moment(end, consts.URL_DATE_FORMAT).format(format) : ''

    if (end && endText !== startText) {
      text = `${text} - ${endText}`
    }

    return text
  }, [jobCompleteRange])

  const handleUpdateSubmissionDateRange = useCallback(
    ({ endDate, startDate, selectedOptionDate }) => {
      if (selectedOptionDate) {
        setJobCompleteDate(selectedOptionDate)
      } else {
        setJobCompleteDate('')
      }
      const updatedSearchParams = {
        from: moment(startDate)
          .utc()
          .startOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
        to: moment(endDate)
          .utc()
          .endOf('day')
          .format(`${consts.DATE_FORMAT_DATA_API_REQUEST}Z`),
      }
      setJobCompleteRange({ ...updatedSearchParams })
      dispatch(
        actions.fetchInstallSubmissions({
          orderBy,
          pageSize,
          ...searchParams,
          ...updatedSearchParams,
        }),
      )
    },
    [orderBy, searchParams],
  )

  const selectFilter = (id) => {
    if (id === 'allMetersEvVerified' || id === 'jobStatus') {
      return (
        <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
          <ListSelector
            items={dropdownItems[id]}
            updateValue={({ value }) => handleSelector(id, value)}
            selectedItem={{
              id: searchParams[id],
              name: searchParams?.[id],
            }}
            unsettable={false}
          />
        </FilterContainerStyled>
      )
    }
    if (id === 'jobCompletionDate') {
      return (
        <FilterContainerStyled width={defaultMeterInstallWidths[id].minWidth}>
          <DatePickerContainerStyled key={id}>
            <DatePicker
              end={moment(jobCompleteRange.to)}
              options={baseOptions}
              period={jobCompleteDate}
              getProgramStartDate={getProgramStartDate}
              start={moment(jobCompleteRange.from)}
              text={renderSubmissionDateLabel()}
              updateDateRange={handleUpdateSubmissionDateRange}
            />
          </DatePickerContainerStyled>
        </FilterContainerStyled>
      )
    }

    return (
      <FilterContainerStyled
        width={defaultMeterInstallWidths[id]?.minWidth}
        key={id}
      >
        <SearchBar
          onSearch={handleSearch}
          filterField={id}
          previous={{
            field: id,
            term: searchParams[id] ? searchParams[id] : '',
          }}
        />
      </FilterContainerStyled>
    )
  }

  const tableHeaderRows = () => {
    const headerRows = defaultMeterInstallHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      handleSortClick:
        item.id === 'opportunityId' || item.id === 'jobCompletionDate' ?
          handleSortClick
        : () => {},
      Filter: () =>
        item.id === 'meterCount' || item.id === 'allMetersAssigned' ?
          null
        : selectFilter(item.id),
      sortable: item.id === 'opportunityId' || item.id === 'jobCompletionDate',
      sorted: orderBy.field === item.id,
      sortDesc: orderBy.sort === 'DESC',
    }))
    return [
      {
        id: 'row1',
        headers: headerRows,
      },
    ]
  }

  const TableHeadComponent = () => <TableHead rows={tableHeaderRows()} />

  const getColumnCell = useCallback((cellProps) => {
    const {
      row,
      column: { id },
      value,
    } = cellProps
    switch (id) {
      case 'opportunityId':
        return (
          <OpportunityStyles {...row.getToggleRowExpandedProps()}>
            <Tippy content={value}>
              <Cell maxWidth={defaultMeterInstallWidths[id]?.maxWidth}>
                {value}
              </Cell>
            </Tippy>
            <ArrowUpStyles>
              {!row.isExpanded ?
                <ChevronDown />
              : <ChevronUp />}
            </ArrowUpStyles>
          </OpportunityStyles>
        )
      case 'jobStatus':
        return (
          <JobStatusStyles inProgress={value === 'In Progress'}>
            {value}
          </JobStatusStyles>
        )
      case 'jobCompletionDate':
        return value ?
            `${moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
          : null
    }
    return (
      (
        <Tippy content={value}>
          <Cell maxWidth={defaultMeterInstallWidths[id]?.maxWidth}>
            {value}
          </Cell>
        </Tippy>
      ) || '-'
    )
  }, [])

  const columns = useMemo(
    () =>
      defaultMeterInstallHeaders.map((item) => ({
        accessor: item.id,
        Cell: getColumnCell,
        Header: item.label,
        minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
        maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
      })),
    [getColumnCell],
  )

  const subTable = (cell) => {
    const { row } = cell
    const { opportunityId } = row.original
    return <MeterTable opportunityId={opportunityId} />
  }
  const getMacAddress = (macAddress) => {
    dispatch(
      actions.fetchInstallSubmissions({
        orderBy,
        pageSize,
        ...searchParams,
        macAddress,
      }),
    )
  }
  return (
    <div>
      <MainStyles>
        <BreadcrumbsStyles>
          <Breadcrumbs items={breadcrumbs} />
        </BreadcrumbsStyles>
        <TitleStyles>Meter Install Submissions</TitleStyles>
        <Search
          getInputValue={getMacAddress}
          input={searchParams['macAddress']}
        />
        <TableStyles>
          <RedaptiveReactTable7
            columns={columns}
            data={submissions}
            TableHead={TableHeadComponent}
            renderRowSubComponent={subTable}
            filterable
            globalFilterable={false}
            showTableScrollArrows={false}
            showRowsExpanded={
              submissions.length === 1 && searchParams['macAddress']
            }
            expandedRows={[true]}
          />
          {!loading && submissions.length === 0 && (
            <NoDataWrapper>No Data Available</NoDataWrapper>
          )}
          {loading ?
            <SpinnerStyles>
              <Spinner />
            </SpinnerStyles>
          : <PaginatorStyles>
              <Paginator
                total={totalPages}
                current={pageNumber || 1}
                next={next}
                prev={previous}
                browseToPage={browseToPage}
                showArrows
              />
              {submissions.length > 0 && pageSizeItems.length > 0 && (
                <PageSizeSelectorWrapperStyled>
                  <PageSizeSelectorInnerStyled>
                    Show:
                    <ListSelector
                      directionUp
                      items={pageSizeItems}
                      updateValue={handleUpdateSelectedPage}
                      selectedItem={{ id: pageSize, name: pageSize }}
                      unsettable={false}
                    />
                  </PageSizeSelectorInnerStyled>
                </PageSizeSelectorWrapperStyled>
              )}
            </PaginatorStyles>
          }
        </TableStyles>
      </MainStyles>
    </div>
  )
}
