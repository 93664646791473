import types from './constants'
import { Heading, Icon, Text, Wrapper } from './styles'
import { ADD_SCENARIO_STATES } from '../../../utils/constants'
import CheckOutline from '../../Icons/svg/CheckOutline'
import ExclamationOutline from '../../Icons/svg/ExclamationOutline'
import Upload from '../../Icons/svg/Upload'

const { SUCCESS, LOADING } = ADD_SCENARIO_STATES
type BannerProps = {
  text: string
  type: string
  heading: string
  fileParsingError: string
}
export default function Banner({
  type,
  text,
  heading,
  fileParsingError,
}: BannerProps) {
  const { border, background } = types[type]
  const icon =
    type === SUCCESS ?
      <CheckOutline fill={border} />
    : <ExclamationOutline fill={border} />
  return (
    <Wrapper border={border} background={background}>
      {type === LOADING && <Upload />}
      {type !== LOADING && <Icon>{icon}</Icon>}
      <Heading>{heading}</Heading>
      {type !== LOADING && <Text>{fileParsingError || text}</Text>}
    </Wrapper>
  )
}
