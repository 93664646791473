import { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import EntityList, { NoResults } from './EntityList'
import '../ducks/types'
import RequestStateFieldLabel from './RequestStateFieldLabel'
import StyledLink from './StyledLink'
import * as requestStatusStates from '../constants/status'
import {
  actions as siteActions,
  selectSiteListEntityWithHealth,
  selectSitesHealthRequestStatus,
  selectSitesRequestStatus,
} from '../ducks/sites'
import type { FTEntity } from '../ducks/types'
import type { FTRequestStatus, FTRouterMatch } from '../types'
import { naturallySort, renderHealthStatus } from '../utils'

type FTProps = {
  actions: Record<string, any>
  match: FTRouterMatch
  sitesRequestStatus: FTRequestStatus
  sitesHealthRequestStatus: FTRequestStatus
  siteListEntity: FTEntity
}

class SiteTable extends Component<FTProps> {
  componentDidMount() {
    this.props.actions.fetchAllSites(this.getFetchProps())
  }

  componentDidUpdate(prev) {
    const { sitesRequestStatus: sitesRequestStatusPrev } = prev
    const { actions, sitesRequestStatus, siteListEntity } = this.props
    const { items } = siteListEntity

    if (
      sitesRequestStatusPrev === requestStatusStates.LOADING &&
      sitesRequestStatus === requestStatusStates.LOADED &&
      items.length !== 0
    ) {
      const siteIds = items.map((item) => item.id)
      actions.fetchSitesHealth({
        siteIds,
      })
    }
  }

  getFetchProps = () => ({
    orderBy: {
      field: 'active',
      sort: 'DESC',
    },
    customerId: this.props.match.params.customerId,
  })

  getTableHeaders = () => [
    {
      fieldName: 'name',
      displayName: 'Site Name',
    },
    {
      fieldName: 'opportunityId',
      displayName: 'Opportunity ID',
      sortable: false,
    },
    {
      fieldName: 'address1',
      displayName: 'Address',
    },
    {
      fieldName: 'city',
      displayName: 'City',
    },
    {
      fieldName: 'state',
      displayName: 'State',
    },
    {
      fieldName: 'active',
      displayName: 'Visible in Dashboard',
    },
    {
      fieldName: 'healthStatusElectricMetersLastMonth',
      // eslint-disable-next-line
      displayName: (
        <RequestStateFieldLabel
          fieldLabel='Electric Site Data Availability: Last Month'
          requestStatus={this.props.sitesHealthRequestStatus}
        />
      ),
      sortable: false,
    },
    {
      fieldName: 'healthStatusElectricMetersCurrentMonth',
      // eslint-disable-next-line
      displayName: (
        <RequestStateFieldLabel
          fieldLabel='Electric Site Data Availability: Month to Date'
          requestStatus={this.props.sitesHealthRequestStatus}
        />
      ),
      sortable: false,
    },
    {
      fieldName: 'healthStatusGasMetersLastMonth',
      // eslint-disable-next-line
      displayName: (
        <RequestStateFieldLabel
          fieldLabel='Natural Gas Site Data Availability: Last Month'
          requestStatus={this.props.sitesHealthRequestStatus}
        />
      ),
      sortable: false,
    },
    {
      fieldName: 'healthStatusGasMetersCurrentMonth',
      // eslint-disable-next-line
      displayName: (
        <RequestStateFieldLabel
          fieldLabel='Natural Gas Site Data Availability: Month to Date'
          requestStatus={this.props.sitesHealthRequestStatus}
        />
      ),
      sortable: false,
    },
  ]

  deprecatedGetSiteUrlPrefix = () => {
    const {
      match: { url },
    } = this.props
    return url.includes('/sites') ? `${url}` : `${url}/sites`
  }

  renderTableRow = ({
    active,
    address1,
    city,
    contracts = [],
    healthStatusElectricMetersLastMonth,
    healthStatusElectricMetersCurrentMonth,
    healthStatusGasMetersLastMonth,
    healthStatusGasMetersCurrentMonth,
    id,
    state,
    validName,
  }) => {
    const siteDetailUrl = `${this.deprecatedGetSiteUrlPrefix()}/${id}`
    const opportunityIds = contracts
      .map(({ opportunityId }) => opportunityId)
      .sort(naturallySort)
      .join(', ')
    return (
      <tr key={id}>
        <td>
          <Link to={siteDetailUrl}>{validName}</Link>
        </td>
        <td>{opportunityIds || '--'}</td>
        <td>{address1}</td>
        <td>{city || 'Unavailable'}</td>
        <td>{state || 'Unavailable'}</td>
        <td>{active ? 'Yes' : 'No'}</td>
        <td>
          {renderHealthStatus(
            this.props.sitesHealthRequestStatus,
            healthStatusElectricMetersLastMonth,
          )}
        </td>
        <td>
          {renderHealthStatus(
            this.props.sitesHealthRequestStatus,
            healthStatusElectricMetersCurrentMonth,
          )}
        </td>
        <td>
          {renderHealthStatus(
            this.props.sitesHealthRequestStatus,
            healthStatusGasMetersLastMonth,
          )}
        </td>
        <td>
          {renderHealthStatus(
            this.props.sitesHealthRequestStatus,
            healthStatusGasMetersCurrentMonth,
          )}
        </td>
      </tr>
    )
  }

  render() {
    const { actions, siteListEntity } = this.props
    const {
      meta: { filterBy },
    } = siteListEntity
    return (
      <EntityList
        striped
        entity={siteListEntity}
        loadEntity={actions.fetchAllSites}
        loadEntityProps={this.getFetchProps()}
        tableHeaders={this.getTableHeaders()}
        renderTableRow={this.renderTableRow}
        renderNoResults={() => (
          <NoResults>
            <div>No sites found.</div>
            {!filterBy && (
              <StyledLink href={`${this.deprecatedGetSiteUrlPrefix()}/create`}>
                Add a site
              </StyledLink>
            )}
          </NoResults>
        )}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(siteActions, dispatch) },
})

const mapStateToProps = (state) => ({
  sitesRequestStatus: selectSitesRequestStatus(state),
  sitesHealthRequestStatus: selectSitesHealthRequestStatus(state),
  siteListEntity: selectSiteListEntityWithHealth(state),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteTable),
)
