import styled from 'styled-components'

import Icons from './index'

const PendingIcon = styled(Icons.MaterialIcon).attrs({
  children: 'access_time',
})`
  color: #0193d7;
`
export default PendingIcon
