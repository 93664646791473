import { PureComponent } from 'react'
import { connect } from 'react-redux'

import Modal from './Modal'
import { actions as modalActions } from '../ducks/modal'

type FTProps = {
  dispatch: (...args: Array<any>) => any
  title: string
  content: Array<string>
  acceptText: string
  declineText: string
  onAccept: (...args: Array<any>) => any
  onCancel: (...args: Array<any>) => any
  isLoading?: boolean
  hideClose?: boolean
}
export class SimpleModal extends PureComponent<FTProps> {
  static defaultProps = {
    isLoading: false,
    hideClose: false,
  }

  hideModal = () => {
    const { dispatch } = this.props
    dispatch(modalActions.hideModal())
  }

  onPrimaryAction = () => {
    const { onAccept } = this.props

    if (onAccept) {
      onAccept()
    }

    this.hideModal()
  }

  onSecondaryAction = () => {
    const { onCancel } = this.props

    if (onCancel) {
      onCancel()
    }

    this.hideModal()
  }

  render() {
    return (
      <Modal
        {...this.props}
        onAccept={this.onPrimaryAction}
        onDecline={this.onSecondaryAction}
        onClose={this.onSecondaryAction}
      />
    )
  }
}
export default connect(() => ({}))(SimpleModal)
