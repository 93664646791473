import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs2 from '../../../components/Breadcrumbs2'
import RedaptiveReactTable7, {
  GlobalFilterWrapper,
  ReactTableTopSectionStyles,
} from '../../../components/RedaptiveReactTable7'
import Spinner from '../../../components/Spinner'
import StyledLink from '../../../components/StyledLink'
import Title from '../../../components/Title'
import type {
  FTProposalCustomerSummariesEntityState,
  FTProposalCustomerSummary,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as customerSummariesActions,
  selectProposalCustomerSummariesEntity,
} from '../../../ducks/proposals/customerSummaries'
import { colors } from '../../../utils/themes'

type FTProps = {
  actions: {
    fetchProposalCustomerSummaries: () => null
  }
  customerSummariesEntity: FTProposalCustomerSummariesEntityState
}
type FTRow = {
  row: {
    original: FTProposalCustomerSummary
  }
}
const CustomerListingStyles = styled.div`
  font-family: 'Public Sans', sans-serif;
  margin-top: 56px;
  padding-bottom: 80px;
  position: relative;

  ${Title} {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 6px;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }
`
const TitleStyles = styled(Title)`
  margin: 0 32px;
`
const RedaptiveReactTableWrapperStyled = styled.div`
  position: relative;

  ${ReactTableTopSectionStyles} {
    position: relative;
  }

  ${GlobalFilterWrapper} {
    position: absolute;
    top: -36px;
  }
`
export const SpinnerStyles = styled.div`
  display: flex;
  justify-content: center;
`
const HeaderStyles = styled.div`
  color: #162447;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin: 0 32px 8px;
`
export const NameLinkStyled = styled(StyledLink)`
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`
const breadcrumbs = [
  {
    href: '/proposal-operations/proposal-engine',
    text: 'Proposal Engine',
  },
  {
    href: '/proposal-operations/proposal-engine/',
    text: 'Customers',
  },
]
const columns = [
  {
    accessor: 'name',
    Cell: ({
      row: {
        original: { id, name },
      },
    }: FTRow) => (
      <NameLinkStyled href={`/proposal-operations/proposals-engine/${id}`}>
        {name}
      </NameLinkStyled>
    ),
    Header: 'Customer Name',
  },
  {
    accessor: 'scopingOpportunitiesCount',
    Header: '# Scoping Opportunities',
  },
  {
    accessor: 'installingOpportunitiesCount',
    Header: '# Installing Opportunities',
  },
  {
    accessor: 'billingAndMonitoringOpportunitiesCount',
    Header: '# Billing and Monitoring opportunities',
  },
  {
    accessor: 'batchAnalysesInProgressCount',
    Header: '# Batch Analyses in Progress',
  },
  {
    accessor: 'accountOwner',
    Header: 'Account Owner',
  },
  {
    accessor: 'lastModified',
    Cell: ({
      row: {
        original: { lastModifiedFormatted },
      },
    }: FTRow) => lastModifiedFormatted || '-',
    Header: 'Last modified (UTC)',
  },
]

const CustomerListingPage = (props: FTProps) => {
  const { actions, customerSummariesEntity } = props
  const { items: customerSummaries } = customerSummariesEntity
  useEffect(() => {
    actions.fetchProposalCustomerSummaries()
  }, [])
  return (
    <>
      <Breadcrumbs2 items={breadcrumbs} />
      <CustomerListingStyles>
        <TitleStyles>Customers</TitleStyles>
        {!customerSummaries.length && (
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        )}
        {!!customerSummaries.length && (
          <>
            <HeaderStyles>
              Select a customer to create, edit, or view a scenario or batch
              analysis. The list of customers is sourced from Salesforce.
            </HeaderStyles>
            <RedaptiveReactTableWrapperStyled>
              <RedaptiveReactTable7
                columns={columns}
                data={customerSummaries}
                defaultSort={[
                  {
                    id: 'name',
                    desc: false,
                  },
                ]}
              />
            </RedaptiveReactTableWrapperStyled>
          </>
        )}
      </CustomerListingStyles>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerSummariesActions, dispatch),
  },
})

const mapStateToProps = (state) => ({
  customerSummariesEntity: selectProposalCustomerSummariesEntity(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListingPage)
