import './SimpleFooter.css'
import { COMPANY_URL, PRIVACY_URL, TERMS_URL } from '../constants'
import { FOOTER_HOME, FOOTER_PRIVACY, FOOTER_TERMS } from '../constants/strings'

const SimpleFooter = () => (
  <div className='SimpleFooter dark'>
    <div className='links'>
      <a target='_blank' rel='noopener noreferrer' href={COMPANY_URL}>
        {FOOTER_HOME}
      </a>
      <a target='_blank' rel='noopener noreferrer' href={TERMS_URL}>
        {FOOTER_TERMS}
      </a>
      <a target='_blank' rel='noopener noreferrer' href={PRIVACY_URL}>
        {FOOTER_PRIVACY}
      </a>
    </div>
  </div>
)

export default SimpleFooter
