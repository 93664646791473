import styled from 'styled-components'

import Button from '../../../components/Button2'
import { zIndices } from '../../../utils'
import { colors } from '../../../utils/themes'

const ModalOuterStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
`
const ModalInnerStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  max-width: 500px;
`
const TextBoxStyled = styled.div`
  padding: 30px;
`
const ModalHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colors.blue5};
  margin-bottom: 20px;
`
const BottomButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.32),
      rgba(224, 224, 224, 0.32)
    ),
    #ffffff;
`

const SubmissionDoneModal = ({
  closeModal,
}: {
  closeModal: (...args: Array<any>) => any
}) => (
  <ModalOuterStyled>
    <ModalInnerStyled>
      <TextBoxStyled>
        <ModalHeading>
          The selected records have been sent to energy star portfolio manager.
          Please check the updated status of these submitted records in
          sometime.
        </ModalHeading>
      </TextBoxStyled>
      <BottomButtonBar>
        <Button type='primary' onClick={closeModal}>
          Close
        </Button>
      </BottomButtonBar>
    </ModalInnerStyled>
  </ModalOuterStyled>
)

export default SubmissionDoneModal
