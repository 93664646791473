import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { actions as meterStatusActions } from './meterStatus'
import { makeApiQueryString } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { getTotalPages, handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
// Action Types
const types = {
  FETCH_METER_ELECTON_VERIFICATION_HISTORY:
    'FETCH_METER_ELECTON_VERIFICATION_HISTORY',
  FETCH_METER_ELECTON_VERIFICATION_HISTORY_SUCCESS:
    'FETCH_METER_ELECTON_VERIFICATION_HISTORY_SUCCESS',
  FETCH_METER_ELECTON_VERIFICATION_HISTORY_ERROR:
    'FETCH_METER_ELECTON_VERIFICATION_HISTORY_ERROR',
}

function addMetaToResponse(params, response: Record<string, any>) {
  const { pageNumber = 1, pageSize = 20, orderBy, filterBy } = params
  const { data } = response
  const { totalCount = 0 } = data
  const { searchParams } = params
  return {
    ...data,
    totalPages: getTotalPages(totalCount, pageSize),
    pageNumber,
    pageSize,
    orderBy,
    filterBy,
    searchParams,
  }
}

// API
const API = {
  fetchMeterElectronVerificationHistory: ({ params }) => {
    const baseUrl = `${consoleApiUrl()}/meter/tests/electron-verifications/dashboard/list`
    const {
      roid = '',
      primaryPanelName = '',
      from = '',
      to = '',
      siteAddress = '',
      customerName = '',
      testRunBy = '',
      macAddress = '',
      labelCheckStatus = '',
      dataCheckStatus = '',
    } = params
    const searchParams = {
      roid,
      primaryPanelName,
      from,
      to,
      siteAddress,
      testRunBy,
      customerName,
      labelCheckStatus,
      macAddress,
      dataCheckStatus,
    }
    const query = makeApiQueryString(params, searchParams)
    const url = `${baseUrl}?${query}`

    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) =>
        addMetaToResponse({ ...params, searchParams }, response),
      )
      .catch(handleAxiosError)
  },
}
// Actions
// eslint-disable-next-line import/no-unused-modules
export const actions = {
  fetchMeterElectronVerificationHistory: (params) => ({
    type: types.FETCH_METER_ELECTON_VERIFICATION_HISTORY,
    params,
  }),
}

// Sagas
function* fetchMeterElectronHistory(payload): Generator<any, void, any> {
  try {
    const response = yield call(
      API.fetchMeterElectronVerificationHistory,
      payload,
    )
    yield put({
      type: types.FETCH_METER_ELECTON_VERIFICATION_HISTORY_SUCCESS,
      payload: response,
    })
    yield put(
      meterStatusActions.getRealTimeMeterStatus({
        ids: [...new Set(response.results.map((item) => item.macAddress))],
      }),
    )
  } catch (e) {
    yield handleSagaError(
      types.FETCH_METER_ELECTON_VERIFICATION_HISTORY_ERROR,
      e,
    )
  }
}

// eslint-disable-next-line import/no-unused-modules
export const sagas = [
  takeLatest(
    types.FETCH_METER_ELECTON_VERIFICATION_HISTORY,
    fetchMeterElectronHistory,
  ),
]

// State
const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    loading: false,
    orderBy: {
      field: 'evTestDate',
      sort: 'DESC',
    },
    searchParams: {
      opportunityId: '',
      from: '',
      to: '',
      siteAddress: '',
      customerName: '',
      testRunBy: '',
      macAddress: '',
      labelCheckStatus: '',
      dataCheckStatus: '',
    },
  },
}

// Reducers
function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY:
      return initialState.items

    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY_SUCCESS:
      return action.payload.results

    default:
      return state
  }
}

function entityById(action, state) {
  return { ...state, ...action.payload.results }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY:
      return initialState.byId

    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY:
      return { ...state, error: '', loading: true }

    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_METER_ELECTON_VERIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        ...action.payload,
        // Making sure to over-ride the old values to null
        next: action.payload?.next || null,
        previous: action.payload?.previous || null,
      }

    default:
      return state
  }
}

// Selectors
export const selectMeterElectronVerificationHistory = (state) =>
  state.entities?.meterElectronVerificationHistory

export default combineReducers({
  byId,
  items,
  meta,
})
