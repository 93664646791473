import { Parser } from '@json2csv/plainjs'
import FileSaver from 'file-saver'
import moment from 'moment'

import getTableColumns from './getTableColumns'
import { TSExportData } from '../../../../ducks/energyStarIntegration/types'
import { TabUrlEnum, TabUrlWithSubmissionStage } from '../../helpers/utils'

export const getExportFileFields = (activeTab: TabUrlEnum) => {
  const fields = getTableColumns(activeTab).map((column) => ({
    label: column.Header as string,
    value: column.accessor as string,
  }))
  fields.push({
    label: 'ESPM Meter Id',
    value: 'espmMeterId',
  })
  fields.push({
    label: 'ESPM Start Date',
    value: 'espmStartdate',
  })
  return fields
}

const getESGCSVData = ({
  exportData,
  activeTab,
}: {
  exportData: TSExportData
  activeTab: TabUrlEnum
}) => {
  const fields = getExportFileFields(activeTab)
  const json2csvParser = new Parser({ fields })
  return json2csvParser.parse(
    exportData?.[activeTab as keyof typeof exportData],
  )
}

const downloadCSV = ({
  exportData,
  activeTab,
  selectedYearMonth,
}: {
  exportData: TSExportData
  activeTab: TabUrlEnum
  selectedYearMonth: string
}) => {
  const file = new Blob([getESGCSVData({ exportData, activeTab })], {
    type: 'text/csv',
  })
  const date = moment(selectedYearMonth, 'YYYY-MM').format('MMYYYY')
  const fileName = `ESPM_${TabUrlWithSubmissionStage[activeTab]}_SUBMISSIONS_${date}.csv`
  return FileSaver.saveAs(file, fileName)
}

export default downloadCSV
