import {
  ComponentType,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTBillingSendToVerificationModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import { colors } from '../../utils/themes'
import Button2 from '../Button2'
import KeyboardDoubleArrowDownIcon from '../Icons/KeyboardDoubleArrowDownIcon'
import KeyboardDoubleArrowUpIcon from '../Icons/KeyboardDoubleArrowUpIcon'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import RedaptiveReactTable7, {
  ReactTableWrapperStyles,
  RedaptiveReactTableStyles,
} from '../RedaptiveReactTable7'
import TextArea, { TextAreaInnerStyled } from '../TextArea'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
} & FTBillingSendToVerificationModal
const ModalBody = styled.div`
  padding: 20px;

  ${TextAreaInnerStyled} {
    max-width: 100%;
  }
`
const tableMarginBottom = 130
const itemsToggleHeight = 43
const TableWrapperStyled = styled.div`
  font-size: 12px;

  ${RedaptiveReactTableStyles} {
    margin: 0 20px;
  }
  ${ReactTableWrapperStyles} {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    max-height: 195px;
  }
`
const ItemsToggleStyled: ComponentType<{
  itemsVisible: boolean
}> = styled.div`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    #ffffff 100%
  );
  bottom: ${({ itemsVisible }) =>
    itemsVisible ?
      `${tableMarginBottom - itemsToggleHeight}px`
    : `${tableMarginBottom}px`};
  color: ${colors.blue2};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${itemsToggleHeight}px;
  justify-content: center;
  letter-spacing: 0.02em;
  width: 100%;
`
const ItemsToggleIconStyled = styled.div`
  margin-right: 6px;
  position: relative;
  top: 2px;
`

const billingSendToVerificationModal = ({
  actions,
  billId,
  billingSites,
  loading,
  setSendToVerificationSubmitted,
}: FTProps) => {
  const [comment, setComment] = useState('')
  const [isToggleItemsActive, setIsToggleItemsActive] = useState(true)

  const handleSendToVerificationCommentBlur = (event: SyntheticEvent) => {
    const {
      target: { value },
    } = event
    setComment(value)
  }

  const handleConfirm = () => {
    actions.sendToVerification({
      billId,
      comment,
    })
    setSendToVerificationSubmitted(true)
  }

  const columns = [
    {
      accessor: 'opportunityId',
      Header: 'Opportunity ID',
    },
    {
      accessor: 'name',
      Header: 'Opportunity Name',
    },
  ]
  const tableData = useMemo(
    () => (isToggleItemsActive ? billingSites : billingSites.slice(0, 3)),
    [isToggleItemsActive],
  )
  const handleItemsToggleClick = useCallback(() => {
    setIsToggleItemsActive(!isToggleItemsActive)
  }, [isToggleItemsActive])
  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled>
        <ModalConfirmTitleStyled>
          {!!billingSites &&
            `The M&V Group will be moved to Performance Verification as a whole.
          Are you sure you want to proceed with moving all opportunities in the M&V Group?`}
          {!billingSites &&
            'Are you sure you want to move these Opportunities to Performance Verification?'}
        </ModalConfirmTitleStyled>
        <ModalConfirmBodyStyled>
          {!!billingSites && (
            <TableWrapperStyled>
              <RedaptiveReactTable7
                columns={columns}
                data={tableData}
                globalFilterable={false}
              />
              {billingSites.length > 2 && (
                <ItemsToggleStyled
                  itemsVisible={isToggleItemsActive}
                  onClick={handleItemsToggleClick}
                >
                  {isToggleItemsActive ?
                    <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowUpIcon />
                      </ItemsToggleIconStyled>
                      Hide
                    </>
                  : <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowDownIcon />
                      </ItemsToggleIconStyled>
                      Show All
                    </>
                  }
                </ItemsToggleStyled>
              )}
            </TableWrapperStyled>
          )}
          <ModalBody>
            <TextArea
              onBlur={handleSendToVerificationCommentBlur}
              placeholder='Comment'
            />
          </ModalBody>
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2 onClick={actions.hideModal} type='transparent'>
            Cancel
          </Button2>
          <Button2 onClick={handleConfirm} loading={loading} type='primary'>
            Confirm
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const {
    sendToVerificationMeta: { loading },
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(billingSendToVerificationModal)
