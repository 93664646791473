import { Route, Switch } from 'react-router-dom'

import ProposalOperationsMainPage from './ProposalOperationsMainPage'
import ProposalsEngine from './ProposalsEngine'
import SpecSheetsListPage from './SpecSheetsListPage'
import SpecSheetsUploadPage from './SpecSheetsUploadPage'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import features from '../../authorization/features'
import type { FTRouterMatch } from '../../types'

type FTProps = {
  match: FTRouterMatch
}

const ProposalOperations = (props: FTProps) => {
  const {
    match: { path, url },
  } = props
  return (
    <>
      <UnauthorizedRedirect
        feature={features.specSheets}
        requireAll
        from={`${path}/spec-sheets`}
        to={url}
      />
      <UnauthorizedRedirect
        feature={features.proposalsEngine}
        requireAll
        from={`${path}/proposals-engine`}
        to={url}
      />
      <UnauthorizedRedirect
        feature={features.createProposalScenario}
        requireAll
        from={`${path}/proposals-engine/:salesforceCustomerId/:opportunityId/add-scenario`}
        to={url}
      />
      <Switch>
        <Route
          path={`${path}/spec-sheets/upload`}
          component={SpecSheetsUploadPage}
        />
        <Route path={`${path}/spec-sheets`} component={SpecSheetsListPage} />
        <Route path={`${path}/proposals-engine`} component={ProposalsEngine} />
        <Route component={ProposalOperationsMainPage} />
      </Switch>
    </>
  )
}

export default ProposalOperations
