import { Component } from 'react'
import styled from 'styled-components'

import Modals from './Modals'
import type { FTMeter } from '../../ducks/meters'
import type { FTConfirmModal } from '../../ducks/modal'
import type { FTPanel } from '../../ducks/panels'
import type { FTWithFormik } from '../../types'
import { getOddEvenSplitNumber } from '../../utils'

const AutoFillOption = styled.span`
  color: #337ab7;
  cursor: pointer;
  padding: 0 8px;

  &:hover {
    text-decoration: underline;
  }

  & + & {
    border-left: 1px solid #4a4a4a;
  }
`
const StyledAutoFillOptions = styled.div`
  margin-bottom: 8px;
`
const presetsNebula = {
  reverse3Phase: {
    channelCtType: 30,
    channelInUse: true,
    channelPhases: ['Phase 3', 'Phase 2', 'Phase 1'],
    vtapL1PhaseA: '1',
    vtapL2PhaseB: '3',
    vtapL3PhaseC: '5',
  },
  splitPhase: {
    channelCtType: 30,
    channelInUse: true,
    channelPhases: ['Phase 1', 'Phase 2'],
    vtapL1PhaseA: '1',
    vtapL2PhaseB: '3',
    vtapL3PhaseC: null,
  },
  threePhase: {
    channelCtType: 30,
    channelInUse: true,
    channelPhases: ['Phase 1', 'Phase 2', 'Phase 3'],
    vtapL1PhaseA: '1',
    vtapL2PhaseB: '3',
    vtapL3PhaseC: '5',
  },
}
const presetsBigBang = {
  reverse3Phase: {
    channelCtType: 80,
    channelInUse: true,
    channelPhases: ['Phase 3', 'Phase 2', 'Phase 1'],
    vtapL1PhaseA: '1',
  },
  splitPhase: {
    channelCtType: 80,
    channelInUse: true,
    channelPhases: ['Phase 1', 'Phase 2'],
    vtapL1PhaseA: '1',
  },
  threePhase: {
    channelCtType: 80,
    channelInUse: true,
    channelPhases: ['Phase 1', 'Phase 2', 'Phase 3'],
    vtapL1PhaseA: '1',
  },
}

const getPresetsForConfigNebula = (
  presetConfig,
  currentChannels = [],
  values,
) => {
  const {
    channelCtType,
    channelInUse,
    channelPhases,
    vtapL1PhaseA,
    vtapL2PhaseB,
    vtapL3PhaseC,
  } = presetConfig
  const { length } = currentChannels
  const channels = currentChannels
    .map((channel, i) => {
      const breakerNumber = getOddEvenSplitNumber(i, length).toString()
      const { circuitId } = channel
      const { panelId } = values.channels[circuitId] || {}
      return {
        ...channel,
        breakerNumber,
        ctType: channelCtType,
        inUse: channelInUse,
        panelId,
        phase: channelPhases[i % channelPhases.length],
      }
    })
    .reduce((accumulator, current) => {
      const { circuitId } = current
      return { ...accumulator, [circuitId]: current }
    }, {})
  return {
    channels,
    vtapL1PhaseA,
    vtapL2PhaseB,
    vtapL3PhaseC,
  }
}

const getPresetsForConfigBigBang = (
  presetConfig,
  currentChannels = [],
  values,
) => {
  const { channelCtType, channelInUse, channelPhases, vtapL1PhaseA } =
    presetConfig
  const { length } = currentChannels
  const channels = currentChannels
    .map((channel, i) => {
      const breakerNumber = getOddEvenSplitNumber(i, length).toString()
      const { circuitId } = channel
      const { panelId } = values.channels[circuitId] || {}
      return {
        ...channel,
        breakerNumber,
        inUse: channelInUse,
        ctType: channelCtType,
        panelId,
        phase: channelPhases[i % channelPhases.length],
      }
    })
    .reduce((accumulator, current) => {
      const { circuitId } = current
      return { ...accumulator, [circuitId]: current }
    }, {})
  return {
    channels,
    vtapL1PhaseA,
  }
}

const autoFillOptionNames = {
  splitPhase: 'Top',
  threePhase: 'Top',
  reverse3Phase: 'Bottom',
}
type FTProps = {
  meter: FTMeter
  panelsById: Record<string, FTPanel>
  showConfirmModal: (arg0: FTConfirmModal) => void
} & FTWithFormik

class AutoFillOptions extends Component<FTProps> {
  getOrderedChannels = () => {
    const { meter } = this.props
    const {
      currentConfig: { circuitConfigurations: channels },
    } = meter
    return channels
  }

  confirmChanges = (onConfirm) => {
    const { showConfirmModal } = this.props
    showConfirmModal({
      modalWidth: '470px',
      onPrimaryAction: undefined,
      onSecondaryAction: onConfirm,
      primaryActionText: 'No',
      renderBody: Modals.renderAutoFillConfirm,
      secondaryActionText: 'Yes',
    })
  }

  applyConfig = (config) => () => {
    const {
      dirty,
      meter: { isNebula },
      setValues,
      values,
    } = this.props
    const channels = this.getOrderedChannels()
    const newValues =
      isNebula ?
        getPresetsForConfigNebula(config, channels, values)
      : getPresetsForConfigBigBang(config, channels, values)

    const applyChanges = () => setValues({ ...values, ...newValues })

    if (dirty) {
      this.confirmChanges(applyChanges)
    } else {
      applyChanges()
    }
  }

  resetConfig = () => this.props.resetForm()

  render() {
    const {
      meter: { isNebula },
      panelsById,
      values: { panelId: meterPanelId },
    } = this.props
    const { type = '' } = panelsById[meterPanelId] || {}
    const presets = isNebula ? presetsNebula : presetsBigBang
    return (
      <StyledAutoFillOptions>
        {type === 'THREE_PHASE_4_WIRE_WYE' && (
          <>
            <AutoFillOption onClick={this.applyConfig(presets.threePhase)}>
              {autoFillOptionNames.threePhase}
            </AutoFillOption>
            <AutoFillOption onClick={this.applyConfig(presets.reverse3Phase)}>
              {autoFillOptionNames.reverse3Phase}
            </AutoFillOption>
          </>
        )}

        {type !== 'THREE_PHASE_4_WIRE_WYE' && (
          <AutoFillOption onClick={this.applyConfig(presets.splitPhase)}>
            {autoFillOptionNames.splitPhase}
          </AutoFillOption>
        )}
        <AutoFillOption onClick={this.resetConfig}>Reset</AutoFillOption>
      </StyledAutoFillOptions>
    )
  }
}

export default AutoFillOptions
