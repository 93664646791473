export default [
  {
    auditVendor: null,
    batchAnalysisTotalCount: 0,
    currencyCode: 'USD',
    externalId: '0060v000009ExHaAAK',
    latestContractId: null,
    name: 'LIGHT - 004 - 19th Oct BA Test',
    opportunityStatus: null,
    opportunityType: 'Lighting',
    programOpportunity: '0060v000009DUjzAAG',
    redaptiveOpportunityId: 'R00010795',
    salesforceSiteId: '0010v00000knNetAAE',
    scenarios: [
      {
        annualEnergyPayment: 0.0,
        insuranceForEveryHundredDollarsOfProjectCost: 0.0,
        transferRate: 0.0,
        baseCost: 0.0,
        capexMarginSeek: 0.0,
        contingencyCost: 0.0,
        contingencyInPercentage: 0.0,
        contractRate: 0.0,
        costReduction: 0.0,
        dealType: 'EAAS',
        materialCosts: 0.0,
        estimatedSalesTax: null,
        estimatedSalesTaxInPercentage: null,
        utilityRate: 0.0,
        grossMargin: 400,
        grossMarginInPercentage: 4,
        id: '87430616-700e-4adf-be7a-7076ff9624a8',
        incentives: 0.0,
        laborCosts: 0.0,
        insuranceFee: 0.0,
        internalRateOfReturn: 0.0,
        lightingAddFixtureCount: 0,
        totalProposedFixtureCount: 50,
        lightingNoChange: 0,
        annualPostBurnHours: 0.0,
        annualPreBurnHours: 0.0,
        lightingRelampBypassBallastCount: 0,
        lightingRelampCount: 0,
        lightingRemoveFixtures: 0,
        lightingReplaceFixtureCount: 0,
        lightingRetrofitKitCount: 0,
        lightingSensorsOnly: 0,
        maintenanceLightingSavings: 0.0,
        maintenanceObligation: 'No Maintenance',
        maintenanceRepairAndOperationsAnnualPayment: 0.0,
        maintenanceSavingsPerFixture: 0.0,
        maintenanceRetainedSavingsInPercentage: 0.0,
        maintenanceSavingsContemplated: true,
        measurementAndVerificationCostAsPerProjectCosts: 0.0,
        costPerMeter: 0.0,
        measurementAndVerificationSource: 'ASSET_BASED',
        meterCount: 1,
        name: 'testScenarioAtul',
        netCost: 0.0,
        netMarginInDollars: 0.0,
        netMarginInPercentage: 0.0,
        npvNetRevenue: 0.0,
        npvOngoingCosts: 0.0,
        npvRevenue: 0.0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        operationsAndMaintenance: 0.0,
        opportunityId: '0060v000009ExHaAAK',
        annualEnergySavingsInDollars: 0,
        otherCost: 0.0,
        postKw: 10.0,
        annualPostKwh: 0.0,
        preKw: 12.5,
        annualPreKwh: 0.0,
        partnerFee: 0.0,
        partnerFeeInPercentage: 0.0,
        initialRebateAmount: 40,
        rebateHcInPercentage: 0.0,
        finalRebateAmount: 0.0,
        referralCost: 0.0,
        referralFeeBasis: 'CONTRACT_VALUE',
        referralFeeInPercentage: 0.0,
        salesforceCustomerId: '0010v00000kmI2kAAE',
        salesforceSiteId: '0010v00000knNetAAE',
        vendorSalesTax: 0.0,
        energyRetainedSavingsInPercentage: 3,
        annualSavedKwh: 0.0,
        simplePaybackEnergyAndMaintenance: null,
        status: 'APPROVED',
        contractTermMonths: 20,
        totalNte: 0.0,
        preTaxContractValue: 0.0,
        totalContractValueWithSalesTax: null,
        totalOngoingContractValue: 0.0,
        ttmUtilityUsage: 0.0,
        trailing12MonthsUtilityBill: 0.0,
        ttmBillReductionInPercentage: 0.0,
        upFrontPayment: 0.0,
        upFrontMeasurementAndVerification: 0.0,
        referral2Cost: null,
      },
    ],
    scenarioTotalCount: 1,
    selectedInBatchAnalysis: false,
    stageName: 'Scoping',
  },
  {
    externalId: '0060v000009ExOqAAP',
    redaptiveOpportunityId: 'R00010798',
    name: 'LIGHT - SSA - 005 - 19th Oct BA Test',
    opportunityType: 'Lighting',
    stageName: 'Scoping',
    programOpportunity: '0060v000009DUjzAAG',
    scenarioTotalCount: 2,
    opportunityStatus: null,
    batchAnalysisTotalCount: 0,
    salesforceSiteId: '0010v00000knNetAAE',
    currencyCode: 'USD',
    selectedInBatchAnalysis: false,
    scenarios: [
      {
        annualEnergyPayment: 13424.8,
        insuranceForEveryHundredDollarsOfProjectCost: 0,
        transferRate: 10,
        baseCost: 100000.46,
        capexMarginSeek: 0,
        contingencyCost: 1600.27,
        contingencyInPercentage: 2,
        contractRate: 0.11,
        costReduction: 0,
        dealType: 'EAAS',
        materialCosts: 50000.89,
        estimatedSalesTax: 0,
        utilityRate: 0.155,
        grossMargin: 18971.7,
        grossMarginInPercentage: 18.03,
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        incentives: 0,
        laborCosts: 20813.62,
        insuranceFee: 0,
        internalRateOfReturn: 2.88,
        lightingAddFixtureCount: 18,
        totalProposedFixtureCount: 138,
        lightingNoChange: 0,
        annualPostBurnHours: 2638,
        annualPreBurnHours: 3388,
        lightingRelampBypassBallastCount: 18,
        lightingRelampCount: 8,
        lightingRemoveFixtures: 0,
        lightingReplaceFixtureCount: 48,
        lightingRetrofitKitCount: 58,
        lightingSensorsOnly: 0,
        maintenanceLightingSavings: 0,
        maintenanceRepairAndOperationsAnnualPayment: 0,
        maintenanceSavingsPerFixture: 0,
        maintenanceRetainedSavingsInPercentage: 13,
        maintenanceSavingsContemplated: true,
        measurementAndVerificationCostAsPerProjectCosts: 3,
        costPerMeter: 1880.0,
        measurementAndVerificationSource: 'ASSET_BASED',
        name: 'LIGHT - Wesco - N59 W13855 Manhardt Drive - Menomonee Falls - WI',
        netCost: 91928.3,
        netMarginInDollars: 12809.02,
        netMarginInPercentage: 12.83,
        npvNetRevenue: 104731.82,
        npvOngoingCosts: 5512.87,
        npvRevenue: 110248.5,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        operationsAndMaintenance: 6,
        opportunityId: 'R00010259',
        annualEnergySavingsInDollars: 12580.68,
        otherCost: 26988.95,
        annualPostKwh: 60578.57,
        annualPreKwh: 161298.02,
        partnerFee: 7918.35,
        partnerFeeInPercentage: 10,
        initialRebateAmount: 4528,
        rebateHcInPercentage: 5,
        finalRebateAmount: 4298.95,
        reduction: 0,
        referralCost: 3988.17,
        referralFeeBasis: 'PRE_TAX_GROSS_COST',
        referralFeeInPercentage: 5,
        salesforceSiteId: '0014o00001nJw3oAAC',
        vendorSalesTax: 0,
        energyRetainedSavingsInPercentage: 12,
        annualSavedKwh: 100728.45,
        status: 'PENDING',
        contractTermMonths: 120,
        totalNte: 79183,
        preTaxContractValue: 110898,
        totalOngoingContractValue: 110898,
        ttmUtilityUsage: 69882,
        trailing12MonthsUtilityBill: 10148.45,
        ttmBillReductionInPercentage: 148.12,
        upFrontPayment: 0,
        upFrontMeasurementAndVerification: 3800,
        referral2Cost: 0,
      },
      {
        annualEnergyPayment: 0.0,
        insuranceForEveryHundredDollarsOfProjectCost: 0.0,
        transferRate: 0.0,
        baseCost: 0.0,
        capexMarginSeek: 0.0,
        contingencyCost: 0.0,
        contingencyInPercentage: 0.0,
        contractRate: 0.0,
        costReduction: 0.0,
        dealType: 'EAAS',
        materialCosts: 0.0,
        estimatedSalesTax: null,
        estimatedSalesTaxInPercentage: null,
        utilityRate: 0.0,
        grossMargin: 1000,
        grossMarginInPercentage: 10,
        id: '341a1b0c-b5d1-4fd5-a96a-bf8c2ae57ed6',
        incentives: 0.0,
        laborCosts: 0.0,
        insuranceFee: 0.0,
        internalRateOfReturn: 0.0,
        lightingAddFixtureCount: 0,
        totalProposedFixtureCount: 20,
        lightingNoChange: 0,
        annualPostBurnHours: 10.0,
        annualPreBurnHours: 110.0,
        lightingRelampBypassBallastCount: 0,
        lightingRelampCount: 0,
        lightingRemoveFixtures: 0,
        lightingReplaceFixtureCount: 0,
        lightingRetrofitKitCount: 0,
        lightingSensorsOnly: 0,
        maintenanceLightingSavings: 0.0,
        maintenanceObligation: 'No Maintenance',
        maintenanceRepairAndOperationsAnnualPayment: 0.0,
        maintenanceSavingsPerFixture: 0.0,
        maintenanceRetainedSavingsInPercentage: 0.0,
        maintenanceSavingsContemplated: true,
        measurementAndVerificationCostAsPerProjectCosts: 0.0,
        costPerMeter: 0.0,
        measurementAndVerificationSource: 'ASSET_BASED',
        meterCount: 1,
        name: 'testScenarioAtul2',
        netCost: 0.0,
        netMarginInDollars: 0.0,
        netMarginInPercentage: 0.0,
        npvNetRevenue: 0.0,
        npvOngoingCosts: 0.0,
        npvRevenue: 0.0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        operationsAndMaintenance: 0.0,
        opportunityId: '0060v000009ExOqAAK',
        annualEnergySavingsInDollars: 0,
        otherCost: 0.0,
        postKw: 10.0,
        annualPostKwh: 0.0,
        preKw: 12.5,
        annualPreKwh: 0.0,
        partnerFee: 0.0,
        partnerFeeInPercentage: 0.0,
        initialRebateAmount: 250,
        rebateHcInPercentage: 0.0,
        finalRebateAmount: 0.0,
        referralCost: 0.0,
        referralFeeBasis: 'CONTRACT_VALUE',
        referralFeeInPercentage: 0.0,
        salesforceCustomerId: '0010v00000kmI2kAAE',
        salesforceSiteId: '0010v00000knNetAAE',
        vendorSalesTax: 0.0,
        energyRetainedSavingsInPercentage: 5,
        annualSavedKwh: 0.0,
        simplePaybackEnergyAndMaintenance: null,
        status: 'APPROVED',
        contractTermMonths: 70,
        totalNte: 0.0,
        preTaxContractValue: 0.0,
        totalContractValueWithSalesTax: null,
        totalOngoingContractValue: 0.0,
        ttmUtilityUsage: 0.0,
        trailing12MonthsUtilityBill: 0.0,
        ttmBillReductionInPercentage: 0.0,
        upFrontPayment: 0.0,
        upFrontMeasurementAndVerification: 0.0,
        referral2Cost: null,
      },
      {
        annualEnergyPayment: 0.0,
        insuranceForEveryHundredDollarsOfProjectCost: 0.0,
        transferRate: 8.5,
        baseCost: 0.0,
        capexMarginSeek: 0.0,
        contingencyCost: 0.0,
        contingencyInPercentage: 0.0,
        contractRate: 0.0,
        costReduction: 0.0,
        dealType: 'EAAS',
        materialCosts: 0.0,
        estimatedSalesTax: null,
        estimatedSalesTaxInPercentage: null,
        utilityRate: 0.0,
        grossMargin: 0.0,
        grossMarginInPercentage: 0.0,
        id: '683ee73e-0200-4bf0-8588-4abea5253855',
        incentives: 0.0,
        laborCosts: 0.0,
        insuranceFee: 0.0,
        internalRateOfReturn: 0.0,
        lightingAddFixtureCount: 0,
        totalProposedFixtureCount: 30,
        lightingNoChange: 0,
        annualPostBurnHours: 170.0,
        annualPreBurnHours: 1890.0,
        lightingRelampBypassBallastCount: 0,
        lightingRelampCount: 0,
        lightingRemoveFixtures: 0,
        lightingReplaceFixtureCount: 0,
        lightingRetrofitKitCount: 0,
        lightingSensorsOnly: 0,
        maintenanceLightingSavings: 0.0,
        maintenanceObligation: 'No Maintenance',
        maintenanceRepairAndOperationsAnnualPayment: 0.0,
        maintenanceSavingsPerFixture: 0.0,
        maintenanceRetainedSavingsInPercentage: 0.0,
        maintenanceSavingsContemplated: true,
        measurementAndVerificationCostAsPerProjectCosts: 0.0,
        costPerMeter: 0.0,
        measurementAndVerificationSource: 'ASSET_BASED',
        meterCount: 1,
        name: 'testScenarioAtul23',
        netCost: 0.0,
        netMarginInDollars: 0.0,
        netMarginInPercentage: 0.0,
        npvNetRevenue: 0.0,
        npvOngoingCosts: 0.0,
        npvRevenue: 0.0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        operationsAndMaintenance: 0.0,
        opportunityId: '0060v000009ExOqAAK',
        annualEnergySavingsInDollars: 0,
        otherCost: 0.0,
        postKw: 10.0,
        annualPostKwh: 0.0,
        preKw: 12.5,
        annualPreKwh: 0.0,
        partnerFee: 0.0,
        partnerFeeInPercentage: 0.0,
        initialRebateAmount: 120,
        rebateHcInPercentage: 0.0,
        finalRebateAmount: 0.0,
        referralCost: 0.0,
        referralFeeBasis: 'CONTRACT_VALUE',
        referralFeeInPercentage: 0.0,
        salesforceCustomerId: '0010v00000kmI2kAAE',
        salesforceSiteId: '0010v00000knNetAAE',
        vendorSalesTax: 0.0,
        energyRetainedSavingsInPercentage: 0.0,
        annualSavedKwh: 0.0,
        simplePaybackEnergyAndMaintenance: null,
        status: 'PENDING',
        contractTermMonths: 180,
        totalNte: 0.0,
        preTaxContractValue: 0.0,
        totalContractValueWithSalesTax: null,
        totalOngoingContractValue: 0.0,
        ttmUtilityUsage: 0.0,
        trailing12MonthsUtilityBill: 0.0,
        ttmBillReductionInPercentage: 0.0,
        upFrontPayment: 0.0,
        upFrontMeasurementAndVerification: 0.0,
        referral2Cost: null,
      },
    ],
    auditVendor: null,
    latestContractId: null,
  },
  {
    externalId: '0064o00000PkaAzAAJ',
    redaptiveOpportunityId: 'R00010883',
    name: 'Lighting - Berry Global - 463 Harding Industrial Drive - Nashville - TN CHANGE ORDER (Bldg 9 + CO)',
    opportunityType: 'Lighting',
    stageName: 'Evaluating',
    programOpportunity: '0064600000DAJhGAAX',
    scenarioTotalCount: 1,
    opportunityStatus: 'SCOPING',
    batchAnalysisTotalCount: 0,
    salesforceSiteId: '0014600001TrLv2AAF',
    currencyCode: 'USD',
    selectedInBatchAnalysis: true,
    scenarios: [
      {
        id: '98512c88-5d41-4347-be15-00ab5bb8bb29',
        opportunityId: '0064o00000PkaAzAAJ',
        salesforceSiteId: '0014600001TrLv2AAF',
        name: 'Test1',
        contractTermMonths: 3,
        dealType: 'EAAS',
        status: 'APPROVED',
        transferRate: 6,
        operationsAndMaintenance: 0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        partnerFeeInPercentage: 0,
        referralFeeInPercentage: 0,
        referralFeeBasis: 'CONTRACT_VALUE',
        contingencyInPercentage: 0,
        costPerMeter: 0,
        measurementAndVerificationSource: 'ASSET_BASED',
        measurementAndVerificationCostAsPerProjectCosts: 0,
        maintenanceSavingsContemplated: true,
        maintenanceLightingSavings: 8,
        insuranceForEveryHundredDollarsOfProjectCost: 0,
        capexMarginSeek: 0,
        totalProposedFixtureCount: 180,
        lightingAddFixtureCount: 0,
        lightingRelampBypassBallastCount: 0,
        lightingReplaceFixtureCount: 0,
        lightingRelampCount: 0,
        lightingRetrofitKitCount: 0,
        lightingNoChange: 0,
        lightingSensorsOnly: 0,
        lightingRemoveFixtures: 0,
        materialCosts: 5.6,
        laborCosts: 0,
        otherCost: 0,
        baseCost: 0,
        partnerFee: 0,
        vendorSalesTax: 0,
        contingencyCost: 0,
        upFrontMeasurementAndVerification: 0,
        insuranceFee: 0,
        referralCost: 0,
        initialRebateAmount: 10,
        rebateHcInPercentage: 0,
        finalRebateAmount: 0,
        incentives: 0,
        netCost: 0,
        totalNte: 56,
        ttmUtilityUsage: 0,
        trailing12MonthsUtilityBill: 0,
        utilityRate: 0,
        contractRate: 0,
        energyRetainedSavingsInPercentage: 10,
        annualPreKwh: 0,
        annualPostKwh: 0,
        annualSavedKwh: 0,
        annualEnergyPayment: 0,
        annualPreBurnHours: 190,
        annualPostBurnHours: 120,
        maintenanceSavingsPerFixture: 0,
        maintenanceRetainedSavingsInPercentage: 0,
        maintenanceRepairAndOperationsAnnualPayment: 0,
        annualEnergySavingsInDollars: 0,
        totalOngoingContractValue: 0,
        upFrontPayment: 0,
        preTaxContractValue: 110,
        npvRevenue: 0,
        npvOngoingCosts: 0,
        npvNetRevenue: 0,
        netMarginInDollars: 0,
        netMarginInPercentage: 0,
        internalRateOfReturn: 0,
        grossMarginInPercentage: 10,
        grossMargin: 500,
        ttmBillReductionInPercentage: 0,
        costReduction: null,
        auditVendor: '',
      },
      {
        id: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd',
        opportunityId: '0064o0PkaAzAAJ',
        salesforceSiteId: '0014600001TrLv2AAF',
        name: 'Test2',
        contractTermMonths: 3,
        dealType: 'EAAS',
        status: 'PENDING',
        transferRate: 6,
        operationsAndMaintenance: 0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        partnerFeeInPercentage: 0,
        referralFeeInPercentage: 0,
        referralFeeBasis: 'CONTRACT_VALUE',
        contingencyInPercentage: 0,
        costPerMeter: 0,
        measurementAndVerificationSource: 'ASSET_BASED',
        measurementAndVerificationCostAsPerProjectCosts: 0,
        maintenanceSavingsContemplated: true,
        maintenanceLightingSavings: 8,
        insuranceForEveryHundredDollarsOfProjectCost: 0,
        capexMarginSeek: 0,
        totalProposedFixtureCount: 0,
        lightingAddFixtureCount: 0,
        lightingRelampBypassBallastCount: 0,
        lightingReplaceFixtureCount: 0,
        lightingRelampCount: 0,
        lightingRetrofitKitCount: 0,
        lightingNoChange: 0,
        lightingSensorsOnly: 0,
        lightingRemoveFixtures: 0,
        materialCosts: 5.6,
        laborCosts: 0,
        otherCost: 0,
        baseCost: 0,
        partnerFee: 0,
        vendorSalesTax: 0,
        contingencyCost: 0,
        upFrontMeasurementAndVerification: 0,
        insuranceFee: 0,
        referralCost: 0,
        initialRebateAmount: 0,
        rebateHcInPercentage: 0,
        finalRebateAmount: 0,
        incentives: 0,
        netCost: 0,
        totalNte: 56,
        ttmUtilityUsage: 0,
        trailing12MonthsUtilityBill: 0,
        utilityRate: 0,
        contractRate: 0,
        energyRetainedSavingsInPercentage: 0,
        annualPreKwh: 0,
        annualPostKwh: 0,
        annualSavedKwh: 0,
        annualEnergyPayment: 0,
        annualPreBurnHours: 190,
        annualPostBurnHours: 180,
        maintenanceSavingsPerFixture: 0,
        maintenanceRetainedSavingsInPercentage: 0,
        maintenanceRepairAndOperationsAnnualPayment: 0,
        annualEnergySavingsInDollars: 0,
        totalOngoingContractValue: 0,
        upFrontPayment: 0,
        preTaxContractValue: 220,
        npvRevenue: 0,
        npvOngoingCosts: 0,
        npvNetRevenue: 0,
        netMarginInDollarsInDollarsInDollars: 0,
        netMarginInPercentage: 0,
        internalRateOfReturn: 0,
        grossMarginInPercentage: 0,
        grossMargin: 0,
        ttmBillReductionInPercentage: 0,
        costReduction: null,
        auditVendor: '',
      },
    ],
  },
  {
    externalId: '0064600000FgnRjAAJ',
    redaptiveOpportunityId: 'R00002651',
    name: 'Lighting - Berry Global - 349 Steven Taylor Boulevard - McAlester - OK',
    opportunityType: 'Lighting',
    stageName: 'Evaluating',
    programOpportunity: '0064600000PLL16AAH',
    scenarioTotalCount: 0,
    opportunityStatus: 'SCOPING',
    batchAnalysisTotalCount: 0,
    salesforceSiteId: '0014600001LOpEaAAL',
    currencyCode: 'USD',
    selectedInBatchAnalysis: true,
    scenarios: [
      {
        id: '98512c88-5d41-4347-be15-00ab5bb8bb291',
        opportunityId: '0064o00000PkaAzAAJ',
        salesforceSiteId: '0014600001TrLv2AAF',
        name: 'Test1',
        contractTermMonths: 3,
        dealType: 'EAAS',
        status: 'APPROVED',
        transferRate: 6,
        operationsAndMaintenance: 0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        partnerFeeInPercentage: 0,
        referralFeeInPercentage: 0,
        referralFeeBasis: 'CONTRACT_VALUE',
        contingencyInPercentage: 0,
        costPerMeter: 0,
        measurementAndVerificationSource: 'ASSET_BASED',
        measurementAndVerificationCostAsPerProjectCosts: 0,
        maintenanceSavingsContemplated: true,
        maintenanceLightingSavings: 8,
        insuranceForEveryHundredDollarsOfProjectCost: 0,
        capexMarginSeek: 0,
        totalProposedFixtureCount: 180,
        lightingAddFixtureCount: 0,
        lightingRelampBypassBallastCount: 0,
        lightingReplaceFixtureCount: 0,
        lightingRelampCount: 0,
        lightingRetrofitKitCount: 0,
        lightingNoChange: 0,
        lightingSensorsOnly: 0,
        lightingRemoveFixtures: 0,
        materialCosts: 5.6,
        laborCosts: 0,
        otherCost: 0,
        baseCost: 0,
        partnerFee: 0,
        vendorSalesTax: 0,
        contingencyCost: 0,
        upFrontMeasurementAndVerification: 0,
        insuranceFee: 0,
        referralCost: 0,
        initialRebateAmount: 10,
        rebateHcInPercentage: 0,
        finalRebateAmount: 0,
        incentives: 0,
        netCost: 0,
        totalNte: 56,
        ttmUtilityUsage: 0,
        trailing12MonthsUtilityBill: 0,
        utilityRate: 0,
        contractRate: 0,
        energyRetainedSavingsInPercentage: 10,
        annualPreKwh: 0,
        annualPostKwh: 0,
        annualSavedKwh: 0,
        annualEnergyPayment: 0,
        annualPreBurnHours: 1980,
        annualPostBurnHours: 1900,
        maintenanceSavingsPerFixture: 0,
        maintenanceRetainedSavingsInPercentage: 0,
        maintenanceRepairAndOperationsAnnualPayment: 0,
        annualEnergySavingsInDollars: 0,
        totalOngoingContractValue: 0,
        upFrontPayment: 0,
        preTaxContractValue: 2220,
        npvRevenue: 0,
        npvOngoingCosts: 0,
        npvNetRevenue: 0,
        netMarginInDollars: 0,
        netMarginInPercentage: 0,
        internalRateOfReturn: 0,
        grossMarginInPercentage: 10,
        grossMargin: 500,
        ttmBillReductionInPercentage: 0,
        costReduction: null,
        auditVendor: '',
      },
      {
        id: '98512c88-5d41-4347-be15-00ab5bb8bb2sfsd1',
        opportunityId: '0064o0PkaAzAAJ',
        salesforceSiteId: '0014600001TrLv2AAF',
        name: 'Test2',
        contractTermMonths: 3,
        dealType: 'EAAS',
        status: 'PENDING',
        transferRate: 6,
        operationsAndMaintenance: 0,
        operationsAndMaintenanceBasis: 'NPV_REVENUE',
        partnerFeeInPercentage: 0,
        referralFeeInPercentage: 0,
        referralFeeBasis: 'CONTRACT_VALUE',
        contingencyInPercentage: 0,
        costPerMeter: 0,
        measurementAndVerificationSource: 'ASSET_BASED',
        measurementAndVerificationCostAsPerProjectCosts: 0,
        maintenanceSavingsContemplated: true,
        maintenanceLightingSavings: 8,
        insuranceForEveryHundredDollarsOfProjectCost: 0,
        capexMarginSeek: 0,
        totalProposedFixtureCount: 0,
        lightingAddFixtureCount: 0,
        lightingRelampBypassBallastCount: 0,
        lightingReplaceFixtureCount: 0,
        lightingRelampCount: 0,
        lightingRetrofitKitCount: 0,
        lightingNoChange: 0,
        lightingSensorsOnly: 0,
        lightingRemoveFixtures: 0,
        materialCosts: 5.6,
        laborCosts: 0,
        otherCost: 0,
        baseCost: 0,
        partnerFee: 0,
        vendorSalesTax: 0,
        contingencyCost: 0,
        upFrontMeasurementAndVerification: 0,
        insuranceFee: 0,
        referralCost: 0,
        initialRebateAmount: 0,
        rebateHcInPercentage: 0,
        finalRebateAmount: 0,
        incentives: 0,
        netCost: 0,
        totalNte: 56,
        ttmUtilityUsage: 0,
        trailing12MonthsUtilityBill: 0,
        utilityRate: 0,
        contractRate: 0,
        energyRetainedSavingsInPercentage: 0,
        annualPreKwh: 0,
        annualPostKwh: 0,
        annualSavedKwh: 0,
        annualEnergyPayment: 0,
        annualPreBurnHours: 100,
        annualPostBurnHours: 10,
        maintenanceSavingsPerFixture: 0,
        maintenanceRetainedSavingsInPercentage: 0,
        maintenanceRepairAndOperationsAnnualPayment: 0,
        annualEnergySavingsInDollars: 0,
        totalOngoingContractValue: 0,
        upFrontPayment: 0,
        preTaxContractValue: 300,
        npvRevenue: 0,
        npvOngoingCosts: 0,
        npvNetRevenue: 0,
        netMarginInDollars: 0,
        netMarginInPercentage: 0,
        internalRateOfReturn: 0,
        grossMarginInPercentage: 0,
        grossMargin: 0,
        ttmBillReductionInPercentage: 0,
        costReduction: null,
        auditVendor: '',
      },
    ],
  },
]
