import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CreateLinkForm from '../../components/CreateLinkForm'
import OneTimeLink from '../../components/OneTimeLink'
import * as status from '../../constants/status'
import { actions as customerActions } from '../../ducks/customers'
import {
  actions as userActions,
  selectSignup,
  SIGNUP_REQUEST_KEY,
} from '../../ducks/users'

type FTProps = {
  error: Record<string, any>
  loading?: boolean
  notice: string
  customer: Record<string, any> | null | undefined
  customerId: string | null | undefined
  actions: Record<string, any>
  signupId: string | null | undefined
  signupEmail: string | null | undefined
}

class SignupFormPage extends Component<FTProps> {
  static defaultProps = {
    loading: false,
  }

  componentDidMount() {
    const { customerId } = this.props

    if (customerId) {
      this.props.actions.fetchCustomer({
        customerId,
      })
    }

    this.props.actions.clearUserResetForm()
  }

  clearSignup = () => {
    this.props.actions.clearSignup()
  }

  handleSubmit = (body) => {
    this.props.actions.signupUser({
      ...body,
      customerId: this.props.customerId,
    })
  }

  render() {
    const { error, loading, notice, customer, signupId, signupEmail } =
      this.props

    if (signupId && signupEmail) {
      return (
        <div className='old-ui'>
          <div className='SignupFormPage'>
            <OneTimeLink
              email={signupEmail}
              resetId={signupId}
              goBack={this.clearSignup}
              isSignup
            />
          </div>
        </div>
      )
    }

    return (
      <div className='old-ui'>
        <div className='SignupFormPage'>
          {!signupId && (
            <CreateLinkForm
              customer={customer}
              notice={notice}
              onSubmit={this.handleSubmit}
              errorMsg={error}
              loading={loading}
              isSignup
            />
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
  },
})

const mapStateToProps = (state, props) => {
  const { requestStatus } = state
  const { status: formStatus, error } = requestStatus[SIGNUP_REQUEST_KEY]
  const { customerId } = props.match.params
  let customer

  if (customerId) {
    customer = state.entities.customers.byId[customerId]
  }

  const { signupId, email: signupEmail } = selectSignup(state)
  return {
    error,
    customerId,
    customer,
    signupId,
    signupEmail,
    loading: formStatus === status.LOADING,
    success: formStatus === status.LOADED,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupFormPage)
