import Tippy from '@tippyjs/react'
import moment from 'moment'
import { ReactElement } from 'react'
import { CellProps } from 'react-table'

import RenderMeterListCell from './RenderMeterListCell'
import { TSEnergyStarSubmissions } from '../../../../ducks/energyStarIntegration/types'
import { useEnergyStarCommonData } from '../../../../queries/energyStar'
import {
  formatHealthStatus,
  getNumberFormatMaxFractionDigits2,
  isValueSet,
} from '../../../../utils'
import {
  columnIds,
  DataSourceValueMap,
  ResourceValueMap,
  StatusColorMap,
  statusEnum,
  StatusViewValueMap,
  TabUrlEnum,
} from '../../helpers/utils'
import {
  DataCaptureStatusStatusIndicatorStyled,
  StatusColor,
  TableCellColumn,
  TableCellRow,
} from '../../styles'
import getColorForDataAvailability from '../helpers/getColorForDataAvailabilty'
import getColorForDelta from '../helpers/getColorForDelta'
import getFormattedCaseStartDate from '../helpers/getFormattedCaseStartDate'
import renderErrorToolTip from '../helpers/renderErrorTooltip'

const RenderCustomCell = ({
  value,
  row: { original },
  column: { id },
}: CellProps<TSEnergyStarSubmissions>) => {
  const { activeSubmissionTab } = useEnergyStarCommonData()

  if (value === null) return '-'
  if (id === columnIds.Status) {
    let statusViewValue: string | ReactElement =
      StatusViewValueMap[value as keyof typeof StatusViewValueMap]
    const color = StatusColorMap[value as keyof typeof StatusColorMap]

    if (value === statusEnum.FAILED) {
      statusViewValue = (
        <>
          {StatusViewValueMap.FAILED}
          {original.errorStatusCode !== 201 &&
            renderErrorToolTip(original.errorStatusCode)}
        </>
      )
    }

    return <StatusColor color={color}>{statusViewValue}</StatusColor>
  }

  if (id === columnIds.ResourceType) {
    return ResourceValueMap[value] ?? value
  }

  if (id === columnIds.DataSource) {
    return DataSourceValueMap[value] ?? value
  }

  if (id === columnIds.DeltaEnergyConsumption) {
    return (
      <>
        <DataCaptureStatusStatusIndicatorStyled
          color={getColorForDelta(value)}
          type='line'
        />
        {typeof value !== 'undefined' && value != null ?
          `${Math.round(value)}%`
        : '-'}
      </>
    )
  }

  if (id === columnIds.DataAvailability) {
    return (
      <>
        <DataCaptureStatusStatusIndicatorStyled
          color={getColorForDataAvailability(
            isValueSet(value) ? Math.floor(value * 100) : 0,
          )}
          type='line'
        />
        {formatHealthStatus(value)}
      </>
    )
  }

  if (id === columnIds.MeterList) {
    if (!value || value?.length === 0) return '-'
    return (
      <TableCellColumn>
        {value?.map((item: string[]) => (
          <TableCellRow>
            <RenderMeterListCell list={item} />
          </TableCellRow>
        ))}
      </TableCellColumn>
    )
  }

  if (
    [
      columnIds.CaseId,
      columnIds.CaseStatus,
      columnIds.CaseStartDate,
      columnIds.CaseAssignedTo,
      columnIds.OpportunityId,
    ].includes(id)
  ) {
    return (
      <TableCellColumn>
        {value?.map((item: string) => (
          <TableCellRow>
            {id === columnIds.CaseStartDate ?
              getFormattedCaseStartDate(item)
            : item}
          </TableCellRow>
        ))}
      </TableCellColumn>
    )
  }

  if (columnIds.ModifiedBy === id && !value) {
    return <>-</>
  }

  if (
    id === columnIds.CurrentMonthEnergyConsumption ||
    id === columnIds.PreviousMonthEnergyConsumption
  ) {
    if (value === null || value === undefined) return <>-</>
    const formattedValue =
      getNumberFormatMaxFractionDigits2('en-US').format(value)
    if (
      id === columnIds.PreviousMonthEnergyConsumption ||
      activeSubmissionTab === TabUrlEnum.SUBMITTED
    ) {
      return formattedValue
    }

    const date = original.modified ?? original.created
    const formattedDate =
      date ?
        moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSS').format(
          '[Last synched on] MM/DD/YYYY [at] hh:mm A',
        )
      : 'NA'

    return (
      <Tippy content={formattedDate} delay={500}>
        <span>{formattedValue}</span>
      </Tippy>
    )
  }

  return value ?? '-'
}

export default RenderCustomCell
