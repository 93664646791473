import axios from 'axios'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError, queryStringify } from '../../api/utils'
import customerSummariesMockData from '../../mockData/proposals/customerSummaries'
import { handleSagaError } from '../../sagas/utils'
import { isVariantActive } from '../../utils'
import { DATE_FORMAT_DATA_API_RESPONSE, DATE_FORMAT_TIMESTAMP } from '../utils'

type FTProposalCustomerSummaryResponse = {
  accountOwner: string
  batchAnalysesInProgressCount: number
  id: string
  lastModified: string
  name: string
  pendingOpportunities: number
}
type FTProposalCustomerSummariesResponse = {
  content: Array<FTProposalCustomerSummaryResponse>
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    paged: boolean
    pageNumber: number
    pageSize: number
    unpaged: boolean
  }
  size: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  totalElements: number
  totalPages: number
}
export type FTProposalCustomerSummary = {
  lastModifiedFormatted: string
} & FTProposalCustomerSummaryResponse
export type FTFetchProposalCustomerSummariesAction = {
  filterName?: string
  opportunityType?: string
  page?: number
  size?: number
  sort?: string
}
export type FTProposalCustomerSummariesMetaState = {
  error: string
  loading: boolean
}
export type FTProposalCustomerSummariesEntityState = {
  byId: Record<string, FTProposalCustomerSummary>
  items: Array<FTProposalCustomerSummary>
  meta: FTProposalCustomerSummariesMetaState
}
type FTState = {
  entities: {
    proposalCustomerSummaries: FTProposalCustomerSummariesEntityState
  }
}
export const types = {
  FETCH_PROPOSAL_CUSTOMER_SUMMARIES: 'FETCH_PROPOSAL_CUSTOMER_SUMMARIES',
  FETCH_PROPOSAL_CUSTOMER_SUMMARIES_SUCCESS:
    'FETCH_PROPOSAL_CUSTOMER_SUMMARIES_SUCCESS',
  FETCH_PROPOSAL_CUSTOMER_SUMMARIES_ERROR:
    'FETCH_PROPOSAL_CUSTOMER_SUMMARIES_ERROR',
}
export const actions = {
  fetchProposalCustomerSummaries: (
    props: FTFetchProposalCustomerSummariesAction,
  ) => ({
    type: types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES,
    ...props,
  }),
}
export const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
  },
}
export const entitySchema = new schema.Entity('proposalCustomerSummaries')

function entityById(action, state) {
  return { ...state, ...action.payload.entities.proposalCustomerSummaries }
}

function entityItems(action, state) {
  const newItems // $FlowFixMe
  : Array<FTProposalCustomerSummary> = Object.values(
    action.payload.entities.proposalCustomerSummaries,
  )
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES:
      return initialState.byId

    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES:
      return initialState.items

    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES_SUCCESS:
      return entityItems(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES:
      return { ...state, error: '', loading: true }

    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES_SUCCESS:
      return { ...state, error: '', loading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectProposalCustomerSummariesEntity = (
  state: FTState,
): FTProposalCustomerSummariesEntityState =>
  state.entities.proposalCustomerSummaries
const utils = {
  enhanceCustomerSummary: (
    customerSummary: FTProposalCustomerSummaryResponse,
  ): FTProposalCustomerSummary => ({
    ...customerSummary,
    lastModifiedFormatted:
      customerSummary.lastModified ?
        moment(
          customerSummary.lastModified,
          DATE_FORMAT_DATA_API_RESPONSE,
        ).format(DATE_FORMAT_TIMESTAMP)
      : '',
  }),
}
export const API = {
  fetchProposalCustomerSummaries: (
    params: FTFetchProposalCustomerSummariesAction,
  ) => {
    if (isVariantActive('3300mock')) {
      return Promise.resolve(customerSummariesMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const queryParams = {
      page: 0,
      size: 1000,
      ...params,
    }
    const query = queryStringify(queryParams)
    const baseUrl = `${consoleBaseUrl()}/proposal/api/customer/proposal`
    const url = query ? `${baseUrl}?${query}` : baseUrl
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTProposalCustomerSummariesResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchProposalCustomerSummariesSaga({
  type,
  ...params
}: {
  type: string
} & FTFetchProposalCustomerSummariesAction): Generator<any, void, any> {
  try {
    const response: FTProposalCustomerSummariesResponse = yield call(
      API.fetchProposalCustomerSummaries,
      params,
    )
    const { content } = response
    const normalized = normalize(content.map(utils.enhanceCustomerSummary), [
      entitySchema,
    ])
    yield put({
      type: types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES_SUCCESS,
      payload: normalized,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES_ERROR, e)
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_PROPOSAL_CUSTOMER_SUMMARIES,
    fetchProposalCustomerSummariesSaga,
  ),
]
