export default [
  {
    id: '5a4c8e3d-56e3-4ef3-9f24-d3eb660e352c',
    reason: 'Site Coverage',
  },
  {
    id: '5a4c8e3d-56i3-4ef3-9f24-d3ec660e352c',
    reason: 'Data Capture',
  },
  {
    id: '5a4fge3d-56e3-4ef3-9f24-d3eb660e352c',
    reason: 'Analysis Needed',
  },
  {
    id: '5a4c8e3d-56e3-4ef3-9f24-d3ai660e352c',
    reason: 'Investigate ISR',
  },
  {
    id: '5a4c8e3d-77e3-4ef3-9f24-d3eb660e352c',
    reason: 'Meter Issues',
  },
  {
    id: '5a4c8e3d-56e3-4ef3-9f24-d3kl660e352c',
    reason: 'First Actual Bill Analysis needed',
  },
  {
    id: '5a4c8e3d-56e3-4ef3-9f24-d3e3460e352c',
    reason: 'Coding/Billing algorithm issue',
  },
  {
    id: '5b6c8e3d-56e3-4ef3-9f24-d3eb660e352c',
    reason: 'Meter Not Installed',
  },
  {
    id: '5a4c8e3d-98e3-4ef3-9f24-d3eb660e352c',
    reason: 'Meter Offline',
  },
  {
    id: '5a4c8e4h-56e3-4ef3-9f24-d3eb660e352c',
    reason: 'Contract numbers misaligned',
  },
  {
    id: '5a4c8e3d-56e3-4ef3-9g74-d3eb660e352c',
    reason: 'As-Built Unavailable',
  },
  {
    id: '5a4b0e3d-56e3-4ef3-9f24-d3eb660e352c',
    reason: 'Proposal-Installation mismatch',
  },
]
