import { utils } from '../../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'

export const defaultSwitchColumns = [
  {
    accessor: 'breakerNumber',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Breaker',
    width: 60,
  },
  {
    accessor: 'breakerType',
    Cell: ({ value }: { value: string }) =>
      utils.breakerTypes[value as keyof typeof utils.breakerTypes] ||
      value ||
      'Invalid',
    Header: 'Type/Name',
  },
  {
    accessor: 'phase',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Phase',
    width: 60,
  },
  {
    accessor: 'ctSize',
    Cell: ({ value }: { value: string }) => (value ? `${value}A` : '-'),
    Header: 'CT Size',
    width: 70,
  },
  {
    accessor: 'meterChannel',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Meter Channel',
    width: 100,
  },
  {
    accessor: 'phaseGroup',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Phase Group',
  },
]
