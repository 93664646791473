import type { FTInputProps } from './Input'
import Input from './Input'

export const inputNumberOnInput = (event: any) => {
  const { target } = event

  if (target.value && parseFloat(target.value) !== 0) {
    target.value = parseFloat(target.value) || ''
  }
}
export const inputNumberOnKeyDown = (event: KeyboardEvent) => {
  if (event.key.toLowerCase() === 'e') {
    event.preventDefault()
  }
}
export default (props: FTInputProps) => (
  <Input
    onInput={inputNumberOnInput}
    onKeyDown={inputNumberOnKeyDown}
    {...props}
  />
)
